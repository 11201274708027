import React, { Fragment, useEffect, useState } from 'react';
import { Nav, Image, Navbar, Dropdown, Container,Col,ListGroup,Row } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle, faBell, faCog, faEnvelopeOpen, faSearch, faSignOutAlt, faUserShield, faBriefcase, faInfo, faHome, faIdBadge, faBook, faBoxOpen, faChartPie, faFileAlt, faHandHoldingUsd, faTable, faTimes, faCalendarAlt, faMapPin, faInbox, faRocket, faUser, faComment, faUsers, faCompass } from "@fortawesome/free-solid-svg-icons";
import Profile from './../../src/assets/img/landing-page/testimonials-img3.png'
import { useHistory } from 'react-router-dom';
import CommonServices from '../common-components/CommonServices';

//Redux state and actions
import { useSelector, useDispatch } from 'react-redux';
import { setIsLoginFalse, setIsLoginTrue } from '../reducers/LoginReducer';

//Local imports
import '../assets/global-css/Index.css';
import AirMigrateLogo from '../assets/img/landing-page/logo-img.png'
import exploreIcon from '../assets/img/icons/Airmigrate icons/512px/explore.png'
import aboutUsIcon from '../assets/img/icons/Airmigrate icons/512px/aboutus.png'
import reviewIcon from '../assets/img/icons/Airmigrate icons/512px/review.png'
import loginIcon from '../assets/img/icons/Airmigrate icons/512px/login.png'
import logOutIcon from '../assets/img/icons/Airmigrate icons/512px/logout.png'
import ProfileIconsModal from '../components/air-migrate/ProfileIconsModal';
import { GetNotificationsCountApi,MarkNotificationAsReadByIdApi,GetNotificationsApi } from '../actions/CommonActions';
import { SOCKET_SERVER_URL } from '../config.tsx';
import io from 'socket.io-client'
import { Capitalize,CapitalizeAllWords } from '../common-components/StringConversions.jsx';
const Header = ({ isUserLogin, setIsUserLogin, isheaderInChat = false }) => {

    let immigrationRegistrationFeatureFlag = CommonServices.GetUnleashFeatureFlag('Create-Consultant-Post');
    let brokerRegistrationFeatureFlag = CommonServices.GetUnleashFeatureFlag('Create-Broker-Post');
    const history = useHistory();
    const [userName, setUserName] = useState('');
    const [userId, setUserId] = useState('')
    const [profilePath, setProfilePath] = useState();
    const [roleId, setRoleId] = useState(localStorage.getItem('roleId'));
    const [notificationCounts, setNotificationCounts] = useState();
    // class change const on mobile header
    const backgroundClass = isUserLogin || isheaderInChat ? 'login-background' : 'logout-header-background';
    const paddingClass = isheaderInChat ? 'p-2' : '';

    //redux state and actions;
    const path = useSelector((state) => state.myProfileModule.myProfile);
    // const isLogin = useSelector((state) => state.loginModule.isLogin);

    /* ----------------------------notification functionality here----------------------------  */
    /* consts for notification */
    const [notifications, setNotifications] = useState([]);
    const [copiedNotifications, setCopiedNotifications] = useState([]);
    const [isViewAllClicked, setIsViewAllClicked] = useState(false);
    const [loggedInUserId, setLoggedInUserId] = useState();
    // const areNotificationsRead = notifications.reduce((acc, notif) => acc && notif.read, true);
    const [areNotificationsRead, setAreNotificationsRead] = useState(false);
    const [socket, setSocket] = useState('')
    const [newNotification, setNewNotification] = useState([]);
    /* ------------------------------------- */
    /* --------notification functionality -------- */
    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('userName'));
        const role = JSON.parse(localStorage.getItem('roleId'));
        const userId = localStorage.getItem('amUserId');
        const profilePath = JSON.parse(localStorage.getItem('profilePath'));
        // console.log('path and profilePath', path, " ***", profilePath);

        setUserName(user);
        setRoleId(role);
        setLoggedInUserId(userId);
        setProfilePath(profilePath);
        // setProfilePath(path);

        // Create Socket
        const newSocket = io(SOCKET_SERVER_URL)
        setSocket(newSocket)

        newSocket.on('connect', () => {
            newSocket.emit('connect_on_notification', userId)
        })

        newSocket.on('new_notification', (response) => {
            setNotificationCounts(response.length)
            setAreNotificationsRead(false);
            setNewNotification(response)
        })

    }, [])

    useEffect(() => {
        if (loggedInUserId) {
            GetAllNotifications();
            GetAllNotificationCounts();
        }
    }, [loggedInUserId])

    // Update New Notification on Top
    useEffect(() => {

        if (newNotification.length) {

            setCopiedNotifications(newNotification.slice(0, 4))
            setNotifications(newNotification)
        }
    }, [newNotification])

    const markNotificationsAsRead = async () => {
        // setTimeout(() => {
        //   setNotifications(notifications.map(n => ({ ...n, read: true })));
        // }, 300);
        setAreNotificationsRead(true);
        // const response = await MarkAllNotificationAsReadApi();
        // console.log('response', response);

    };

    const markSingleNotificationAsRead = async (id, notification_type, redirct_link, users_json, from_user_uuid) => {
        const redirectLink = redirct_link.split('/');
        console.log('redirectLink, notification', redirectLink, notification_type, from_user_uuid);
        if (notification_type === "chat") {
            console.log('chat');
            history.push(`/chat`, { userId: redirectLink[2], caseId: redirectLink[3], caseName: users_json.case_name });
        }
        else if (notification_type === "agent-requested-access-profile") {
            console.log('/agent-requested-access-profile');
            history.push('/admin/clients/edit-client', { userId: from_user_uuid });
        }
        else if (notification_type === "agent-request-profile-column-name") {
            console.log('agent-request-profile-column-name');
            history.push('/admin/clients/edit-client', { userId: from_user_uuid });
        }
        else if (notification_type === "agent-invite-request") {
            console.log('agent-invite-request');
            history.push('/customer/requests/received');
        }
        else if (notification_type === "cleint-requested-documents") {
            console.log('acleint-requested-documents');
            history.push('/customer/requests/recieved');
        }
        else if (notification_type === "client-uploaded-document") {
            console.log('client-uploaded-document');
            history.push('/cases/case-details', { userId: loggedInUserId, caseId: users_json.case_id, caseName: CapitalizeAllWords(users_json.case_name) });
        }

        else if (["agent-uploaded-document", 'agent-registered-request', 'create-agent', "agent-request"].includes(notification_type)) {
            history.push('/admin/agents');
        }
        else if (notification_type === "view-myprofile") {
            history.push('/myprofile');
        }
        else {
            history.push('/');
        }
        const response = await MarkNotificationAsReadByIdApi(id);
        GetAllNotifications();
        GetAllNotificationCounts();
    };

    const GetAllNotifications = async () => {
        const response = await GetNotificationsApi();
        if (response?.status_code == 1) {
            const notifications = response.data
            setNotifications(notifications);
            setCopiedNotifications(notifications.slice(0, 4))
            if (notifications.length > 0) {
                setAreNotificationsRead(false);
            }
            else setAreNotificationsRead(true);
        }
    }

    const Notification = (props) => {
        const { id, notification, read, redirct_link, notification_timestamp, users_json, created_at,
            from_user_uuid, notification_type, to_user_uuid, updated_at,
        } = props;
        // const readClassName = read ? "" : "text-danger";
        const readClassName = "";

        var senderName = 'User'
        if (users_json) {
            if (users_json.first_name) {
                senderName = users_json.last_name ? (users_json.first_name + ' ' + users_json.last_name) : users_json.first_name
            }
        }

        return (
            <ListGroup.Item
                action
                // href={link} 
                onClick={() => { markSingleNotificationAsRead(id, notification_type, redirct_link, users_json, from_user_uuid); GetAllNotificationCounts() }}
                className="border-bottom border-light">
                <Row className="align-items-center">
                    <Col className="col-auto">
                        {/* {users_json?.profile_path ?
              <Image src={users_json?.profile_path} className="user-avatar lg-avatar rounded-circle" /> :
              (
                <div className={`bg-light rounded-circle d-flex justify-content-center align-items-center`} style={{ minHeight: 56, minWidth: 56, width: 56, height: 56 }} >
                  <p className=" fw-bolder m-0">{CommonServices.getNameCharByFullName(users_json?.first_name + ' ' + users_json?.last_name)}</p>
                </div>
              )
              // <Image src={profilePath} className="user-avatar lg-avatar rounded-circle" />
            } */}
                        <ProfileIconsModal isNotification={true} userDetails={users_json} />
                    </Col>
                    <Col className="ps-0 ms--2">
                        <div className="d-flex justify-content-between align-items-center">
                            <div>
                                <h4 className="h6 mb-0 text-small">{Capitalize(senderName)}</h4>
                            </div>
                            <div className="text-end">
                                <small className={readClassName}>{
                                    CommonServices.getDateTimeByTimestampForList(notification_timestamp)
                                }</small>
                            </div>
                        </div>
                        <p className="font-small mt-1 mb-0" style={{ whiteSpace: "nowrap", overflow: "hidden", width: "180px", textOverflow: "ellipsis" }}>{notification}</p>
                    </Col>
                </Row>
            </ListGroup.Item>
        );
    };


    /* --------------------------------------------------------------------------------- */

    useEffect(() => {
        let amUserId = localStorage.getItem('amUserId')
        if (amUserId) {
            setUserId(amUserId)
        }
    }, [isUserLogin])

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('userName'));
        const profilePath = localStorage.getItem('profilePath') ? JSON.parse(localStorage.getItem('profilePath')) : "";
        const amUserId = localStorage.getItem('amUserId');
        // console.log('user details at Header', user);
        setUserName(user);
        setProfilePath(profilePath);

        if (amUserId) {
            GetAllNotificationCounts();
            const newSocket = io(SOCKET_SERVER_URL)
            newSocket.on('connect', () => {
                newSocket.emit('connect_on_notification', amUserId)
            })
            newSocket.on('new_notification', (response) => {
                setNotificationCounts(response.length)
            })
        }
        // Add event listener when the component mounts
        document.addEventListener('mousedown', closeNav);
        // Clean up the event listener when the component unmounts
        return () => {
            document.removeEventListener('mousedown', closeNav);
        };
    }, [])

    const handleLogout = () => {
        localStorage.clear();
        history.push('/', { state: null });
        setIsUserLogin(false);
    };

    function openNav() {
        document.getElementById("mySidenav").style.width = "200px";
        document.getElementById("main").style.marginLeft = "200px";
        GetAllNotificationCounts();
    }

    function closeNav() {
        document.getElementById("mySidenav").style.width = "0";
        document.getElementById("main").style.marginLeft = "0";
    }

    /* UNLEASH FLAG CHECK  */
    /* Check Unleash feature flag for My profile is enabled or not */
    let myProfileFeatureFlag = CommonServices.GetUnleashFeatureFlag('My-Profile-GET');
    /* Check Unleash feature flag for Chat */
    let chatFeatureFlag = CommonServices.GetUnleashFeatureFlag('my-case-chats');
    /* end */

    /* function to get notification counts */
    const GetAllNotificationCounts = async () => {
        const response = await GetNotificationsCountApi();
        if (response?.status_code == 1) {
            const notifications = response.data
            setNotificationCounts(notifications);
        }
    }

    /* redirection function here */
    const redirectionByRoleID=()=>{
        if(isUserLogin){
            if (roleId == 1) {
                history.push('/admin/agents');
              } else{
                history.push('/chat', {userId:userId});
              } 
        }
    }

    return (
        <Fragment>
            <header>
                {(isUserLogin === false) &&
                    <div className={`header-top-block ${isheaderInChat ? 'top-block-heightForChat' : ''}`}>
                        {(immigrationRegistrationFeatureFlag || brokerRegistrationFeatureFlag) &&
                            <span className='registraion-btn' onClick={() => history.push('/registration')}>Airmigrate for Business</span>
                        }
                    </div>
                }
                {/* old header class condition */}
                {/* className={`header-area-block ${isUserLogin ? 'login-background ' : 'logout-header-background'}  ${isheaderInChat ? 'area-block-heightForChat' : ''} `} */}
                <div className={`header-area-block login-background   ${isheaderInChat ? 'area-block-heightForChat' : ''} `}>
                    <div className="logo-block" >
                        <a>
                            <img src={AirMigrateLogo} alt="logo image" onClick={() => history.push('/')} />
                        </a>
                    </div>

                    <div className="top-nav-block">

                        <ul>

                            {/* <li><a onClick={() => history.push('/review')}>Review</a></li> */}
                            {isUserLogin ? (
                                <>
                                    {roleId != 1 ?
                                        <>
                                            {chatFeatureFlag &&
                                                <li><a onClick={() => history.push('/chat', { userId })}>My Chats</a></li>
                                            }
                                        </>
                                        :
                                        <li><a onClick={() => history.push('/admin/agents')}>Agents</a></li>

                                    }
                                </>
                                // <li><a onClick={() => history.push('/dashboard', { userId })}>Dashboard</a></li>
                            ) : ''}
                            { //used for agents
                                isUserLogin && [2, 4, '2', '4'].includes(roleId) &&
                                <>
                                    <li><a onClick={() => history.push('/admin/clients')}>Clients</a></li>
                                    <li><a onClick={() => history.push('/myprofile')}>My Profile</a></li>
                                </>
                            }




                            { //Used for admin and client
                                (!isUserLogin || [3, '3', 1, '1'].includes(roleId)) &&
                                <>
                                    <li><a onClick={() => history.push('/explore')}>Explore</a></li>
                                    <li><a onClick={() => history.push('/about-us')}>About Us</a></li>
                                </>
                            }

                        </ul>
                    </div>
                    <div className='search-and-profile-block'>
                        {/* {!isheaderInChat ?
                            (<div className="top-nav-block header-search-block">
                                <div className='header-search-container'>
                                    <i className="fa-solid fa-magnifying-glass search-icon"></i>
                                    <input type="search" name="search" className='header-search' placeholder='Search' />
                                </div>
                            </div>) : ''
                        } */}

                        {!isUserLogin ? (
                            <div className="sign-in-btn-block">
                                <span className="sign-in-with-border">
                                    <a onClick={() => history.push('/login')}>Sign In <span className="user-icon-block"><i className="fa-solid fa-user"></i>{/* <img src={loginIcon} alt="login" height={20} width={20}/> */}</span></a>
                                </span>
                            </div>
                        ) : (
                            <Navbar variant="light" expanded className="p-0 m-0">
                                <Container fluid className="px-0">
                                    <Nav className="align-items-center">
                                        <Dropdown as={Nav.Item} onToggle={markNotificationsAsRead} >
                                            <Dropdown.Toggle as={Nav.Link} className="text-dark icon-notifications me-lg-3">
                                                <span className="icon icon-sm">

                                                    {/* <span className="icon-badge rounded-circle unread-notifications" >5</span> */}
                                                    {/* {(notifications.length > 0) &&<> */}
                                                    {/* {areNotificationsRead ? null :  */}
                                                    <div className='d-flex align-items-center justify-content-center'><FontAwesomeIcon icon={faBell} className="m-0 text-white" /> <p className='mobile-menu-notification-count text-white mb-3'>{notificationCounts}</p></div>
                                                    {/* //   } */}
                                                    {/* // </> */}
                                                    {/* } */}
                                                </span>
                                            </Dropdown.Toggle>
                                            {(notifications.length > 0) ?
                                                <Dropdown.Menu className="dashboard-dropdown notifications-dropdown dropdown-menu-lg dropdown-menu-center mt-2 py-0">
                                                    <ListGroup className="list-group-flush"
                                                        style={{ maxHeight: "400px", Height: "auto", overflowY: "scroll" }}
                                                    >
                                                        <Nav.Link href="#" className="text-center text-primary fw-bold border-bottom border-light py-3">
                                                            Notifications
                                                        </Nav.Link>

                                                        {isViewAllClicked === true ?
                                                            <>
                                                                {notifications.map((n, index) => <Notification key={`notification-${index}`} {...n} />)}
                                                            </>
                                                            :
                                                            <>
                                                                {copiedNotifications.map((n, index) => <Notification key={`notification-${index}`} {...n} />)}
                                                            </>
                                                        }

                                                        {(((notifications.length > 4) || (copiedNotifications > 4)) && (isViewAllClicked === false)) &&
                                                            <Dropdown.Item className="text-center text-primary fw-bold py-3" onClick={() => setIsViewAllClicked(true)}>
                                                                View all
                                                            </Dropdown.Item>
                                                        }
                                                    </ListGroup>
                                                </Dropdown.Menu> : null
                                            }
                                        </Dropdown>
                                        <Dropdown as={Nav.Item}>
                                            <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
                                                <div className="media d-flex align-items-center">
                                                    {/* <FontAwesomeIcon icon={faUserCircle} className="fs-1" /> */}
                                                    <div className="media-body align-items-center d-none d-lg-block text-white profile-welcome-text">
                                                        <p style={{ margin: 0 }}>Welcome</p>
                                                        <span className="mt-2 fs-5 fw-bold  text-white text-capitalize">{userName ? userName : 'User'}</span>
                                                    </div>
                                                    {/* {profilePath ? (
                                                        <Image src={profilePath} className="user-avatar md-avatar rounded-circle profile-border" />
                                                    ) : (
                                                        <div className={`bg-light rounded-circle d-flex justify-content-center align-items-center ms-2`} style={{ minHeight: 50, minWidth: 50, width: 50, height: 50 }} >
                                                            <p className=" fw-bolder m-0 text-black">{CommonServices.getNameCharByFullName(userName)}</p>
                                                        </div>
                                                    )} */}
                                                    {/* {(path === '') ?
                                                        <>
                                                            {((profilePath != '') || (profilePath != null) || (profilePath != undefined))
                                                                ?
                                                                (<Image src={profilePath} className="user-avatar md-avatar rounded-circle profile-border" />)
                                                                :
                                                                (<div className={`bg-light rounded-circle d-flex justify-content-center align-items-center`} style={{ minHeight: 50, minWidth: 50, width: 50, height: 50 }} >
                                                                    <p className=" fw-bolder m-0 " style={{ fontSize: "16px", color: "#4A5073" }}>{CommonServices.getNameCharByFullName(userName)}</p>
                                                                </div>)
                                                            }
                                                        </> :
                                                        <Image src={path} className="user-avatar md-avatar rounded-circle profile-border" />
                                                    } */}


                                                    {(!path) ?
                                                        <div className='ms-2'>
                                                            <ProfileIconsModal userDetails={{ profile_path: profilePath, first_name: userName }} />
                                                            {/* {((profilePath === '') || (profilePath === null) || (profilePath === undefined))
                                                                ?
                                                                <div className={`bg-light rounded-circle d-flex justify-content-center align-items-center`} style={{ minHeight: 50, minWidth: 50, width: 50, height: 50 }}
                                                                >
                                                                    <p className=" fw-bolder m-0 " style={{ fontSize: "16px", color: "#4A5073" }}>
                                                                        {userName === 'User' ?
                                                                            " U" :
                                                                            CommonServices.getNameCharByFullName(userName)
                                                                        }
                                                                    </p>
                                                                </div>
                                                                :
                                                                <Image alt="profile" src={profilePath} className="user-avatar md-avatar rounded-circle profile-border" />

                                                            } */}
                                                        </div> :
                                                        <div className='ms-2'>
                                                            <ProfileIconsModal userDetails={{ profile_path: path, first_name: userName }} />
                                                            {/* // <Image alt="paath" src={path} className="user-avatar md-avatar rounded-circle profile-border" /> */}
                                                        </div>
                                                    }



                                                    {/* Name letter image */}
                                                    {/* <div className="user-image-block">
                                                    <span className='user-image-info'>{UserProfile?.first_name?.toUpperCase().charAt(0)}</span>
                                                </div> */}

                                                </div>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2 inner-dropdown-menu">

                                                {(roleId == 2 || roleId == 4 || roleId == 3) ?
                                                    <>
                                                        <Dropdown.Item className="fw-bold f-16" onClick={() => history.push('/chat', { userId: userId })}>
                                                            <FontAwesomeIcon icon={faHome} className="me-2" /> Home
                                                        </Dropdown.Item>
                                                    </>
                                                    :
                                                    <>
                                                        <Dropdown.Item className="fw-bold f-16" onClick={() => history.push('/')}>
                                                            <FontAwesomeIcon icon={faHome} className="me-2" /> Home
                                                        </Dropdown.Item>
                                                    </>
                                                }

                                                {/* <Dropdown.Divider /> */}

                                                {myProfileFeatureFlag &&
                                                    <>
                                                        {roleId != 1 &&
                                                            <>
                                                                <Dropdown.Item className="fw-bold f-16" onClick={() => history.push('/myprofile')}>
                                                                    <FontAwesomeIcon icon={faUserCircle} className="me-2" /> My Profile
                                                                </Dropdown.Item>
                                                                {/* <Dropdown.Divider /> */}
                                                            </>

                                                        }
                                                    </>
                                                }

                                                {roleId == 3 &&
                                                    <>
                                                        <Dropdown.Item className="fw-bold f-16" onClick={() => history.push('/cases/immigration-cases')}>
                                                            <FontAwesomeIcon icon={faBriefcase} className="me-2" /> Cases
                                                        </Dropdown.Item>
                                                        {/* <Dropdown.Divider /> */}
                                                    </>
                                                }

                                                {roleId != 1 && chatFeatureFlag &&
                                                    <>
                                                        <Dropdown.Item className="fw-bold f-16" onClick={() => history.push('/chat', { userId: userId })}>
                                                            <FontAwesomeIcon icon={faComment} className="me-2" /> Chats
                                                        </Dropdown.Item>
                                                        {/* <Dropdown.Divider /> */}
                                                    </>
                                                }

                                                {(roleId == 2 || roleId == 4) &&
                                                    <>
                                                        <Dropdown.Item className="fw-bold f-16" onClick={() => history.push('/admin/clients')}>
                                                            <FontAwesomeIcon icon={faUser} className="me-2" /> Clients
                                                        </Dropdown.Item>
                                                        {/* <Dropdown.Divider /> */}
                                                    </>
                                                }
                                                {(roleId == 1) &&
                                                    <>
                                                        <Dropdown.Item className="fw-bold f-16" onClick={() => history.push('/admin/agents')}>
                                                            <FontAwesomeIcon icon={faIdBadge} className="me-2" /> Agents
                                                        </Dropdown.Item>
                                                        {/* <Dropdown.Divider /> */}

                                                        <Dropdown.Item className="fw-bold f-16" onClick={() => history.push('/admin/customers')}>
                                                            <FontAwesomeIcon icon={faIdBadge} className="me-2" /> Customers
                                                        </Dropdown.Item>
                                                        {/* <Dropdown.Divider /> */}
                                                    </>
                                                }

                                                {roleId == 3 &&
                                                    <>
                                                        <Dropdown.Item className="fw-bold f-16" onClick={() => history.push('/customer/requests/recieved')}>
                                                            <FontAwesomeIcon icon={faInfo} className="me-2" /> Requests
                                                        </Dropdown.Item>
                                                        {/* <Dropdown.Divider /> */}
                                                    </>
                                                }

                                                {roleId == 4 &&
                                                    <>
                                                        <Dropdown.Item className="fw-bold f-16" onClick={() => history.push('/broker-request-list')}>
                                                            <FontAwesomeIcon icon={faInfo} className="me-2" /> Requests
                                                        </Dropdown.Item>
                                                        {/* <Dropdown.Divider /> */}
                                                    </>
                                                }
                                                {roleId == 3 &&
                                                    <>
                                                        <Dropdown.Item className="fw-bold f-16" onClick={() => history.push('/all-document-list', { userId: userId, userName: userName, isEdit: false })}>
                                                            <FontAwesomeIcon icon={faFileAlt} className="me-2" /> Documents
                                                        </Dropdown.Item>
                                                        {/* <Dropdown.Divider /> */}
                                                    </>
                                                }
                                                <Dropdown.Item className="fw-bold f-16" onClick={handleLogout}>
                                                    <FontAwesomeIcon icon={faSignOutAlt} className="text-danger me-2" /> Sign out
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Nav>
                                </Container>
                            </Navbar>

                            // <div className="sign-in-btn-block">
                            //     <span className="sign-in-button">
                            //         <a onClick={handleLogout}>Log out<span className="user-icon-block"><i className="fa-solid fa-user"></i></span></a>
                            //     </span>
                            // </div>
                        )}
                    </div>
                </div>
                {/* Mobile Header Menu */}
                <div className={`mobile-menu-section login-background ${paddingClass}`}>
                    <div className="logo-block">
                        <img src={AirMigrateLogo} alt="logo image" onClick={() => history.push('/')} />
                    </div>
                    {/* <div className='header-search-container'>
                        <i className="fa-solid fa-magnifying-glass mx-2 text-black"></i>
                       <input type="search" name="search" className='header-search' placeholder='Search'/>
                    </div> */}

                    <div id="mySidenav" className="sidenav">
                        <p className="closebtn" onClick={closeNav}>&times;</p>

                        {!isUserLogin ? (<div className="sign-in-btn-block">

                            <a className='text-white' onClick={() => { history.push('/explore'); closeNav() }}><img src={exploreIcon} alt="" height={20} width={20} className='me-2' /> Explore</a>
                            <Dropdown.Divider />
                            {/* <a className='text-white' onClick={() => { history.push('/review'); closeNav() }}><img src={reviewIcon} alt="" height={20} width={20} className='me-2' /> Review</a>
                            <Dropdown.Divider /> */}
                            <a className='text-white' onClick={() => { history.push('/about-us'); closeNav() }}><img src={aboutUsIcon} alt="" height={20} width={20} className='me-2' /> About Us</a>
                            <Dropdown.Divider />
                            <a className='text-white' onClick={() => { history.push('/login'); closeNav() }}><img src={loginIcon} alt="" height={20} width={20} className='me-2' /> Sign In </a>
                            <Dropdown.Divider />
                        </div>)
                            :
                            (
                                <>
                                    <div className='mobile-menu-profile-block-wrapper'>
                                        <div className='mobile-menu-profile-image'>
                                            <ProfileIconsModal userDetails={{ profile_path: profilePath, first_name: userName }} />
                                        </div>
                                        <div className='mobile-menu-profile-name'>
                                            <p >{userName ? userName : 'User'}</p>
                                        </div>
                                    </div>





                                    {(roleId == 2 || roleId == 4 || roleId == 3) ?
                                        <>
                                            {/* <Dropdown.Item className="fw-bold f-16" onClick={() => history.push('/chat', { userId: userId })}>
                                                        <FontAwesomeIcon icon={faHome} className="me-2" /> Home
                                                    </Dropdown.Item> */}
                                            <Dropdown.Item className="fw-bold" onClick={() => { history.push('/chat', { userId: userId }); closeNav() }}>
                                                <div className='d-flex' >
                                                    <div className='icon-div-width'><FontAwesomeIcon icon={faHome} className="" /></div>
                                                    <div>Home</div>
                                                </div>
                                            </Dropdown.Item>
                                        </>
                                        :
                                        <>
                                            <Dropdown.Item className="fw-bold" onClick={() => { history.push('/'); closeNav() }}>
                                                <div className='d-flex' >
                                                    <div className='icon-div-width'><FontAwesomeIcon icon={faHome} className="" /></div>
                                                    <div>Home</div>
                                                </div>
                                            </Dropdown.Item>
                                        </>
                                    }







                                    <Dropdown.Divider />

                                    <Dropdown.Item className="fw-bold" onClick={() => { history.push('/explore'); closeNav() }}>
                                        {/* <FontAwesomeIcon icon={faHome} className="me-2" /> Home */}
                                        {/* <span><FontAwesomeIcon icon={faHome} className="me-2" /> </span><span>Home</span> */}
                                        <div className='d-flex' >
                                            <div className='icon-div-width'><FontAwesomeIcon icon={faCompass} className="" /></div>
                                            <div>Explore</div>
                                        </div>
                                    </Dropdown.Item>
                                    <Dropdown.Divider />

                                    {myProfileFeatureFlag && roleId != 1 &&
                                        <>
                                            <Dropdown.Item className="fw-bold" onClick={() => { history.push('/myprofile'); closeNav() }}>
                                                {/* <FontAwesomeIcon icon={faUserCircle} className="me-2" /> My Profile */}
                                                <div className='d-flex' >
                                                    <div className='icon-div-width'><FontAwesomeIcon icon={faUserCircle} className="" /></div>
                                                    <div>My Profile</div>
                                                </div>
                                            </Dropdown.Item>
                                            <Dropdown.Divider />
                                        </>
                                    }

                                    {roleId == 3 &&
                                        <>
                                            <Dropdown.Item className="fw-bold" onClick={() => { history.push('/cases/immigration-cases'); closeNav() }}>
                                                {/* <FontAwesomeIcon icon={faBriefcase} className="me-2" /> Cases */}
                                                <div className='d-flex' >
                                                    <div className='icon-div-width'><FontAwesomeIcon icon={faBriefcase} className="" /></div>
                                                    <div>Cases</div>
                                                </div>
                                            </Dropdown.Item>
                                            <Dropdown.Divider />
                                        </>
                                    }

                                    {roleId != 1 &&
                                        <>
                                            <Dropdown.Item className="fw-bold" onClick={() => { history.push('/chat', { userId: userId }); closeNav() }}>
                                                {/* <FontAwesomeIcon icon={faComment} className="me-2" /> Chats */}
                                                <div className='d-flex' >
                                                    <div className='icon-div-width'><FontAwesomeIcon icon={faComment} className="" /></div>
                                                    <div>Chats</div>
                                                </div>
                                            </Dropdown.Item>
                                            <Dropdown.Divider />
                                        </>
                                    }

                                    {(roleId == 2 || roleId == 4) &&
                                        <>
                                            <Dropdown.Item className="fw-bold" onClick={() => { history.push('/admin/clients'); closeNav() }}>
                                                {/* <FontAwesomeIcon icon={faUser} className="me-2" /> Clients */}
                                                <div className='d-flex' >
                                                    <div className='icon-div-width'><FontAwesomeIcon icon={faUser} className="" /></div>
                                                    <div>Clients</div>
                                                </div>
                                            </Dropdown.Item>
                                            <Dropdown.Divider />
                                        </>
                                    }
                                    {(roleId == 1) &&
                                        <>
                                            <Dropdown.Item className="fw-bold" onClick={() => { history.push('/admin/agents'); closeNav() }}>
                                                {/* <FontAwesomeIcon icon={faIdBadge} className="me-2" /> Agents */}
                                                <div className='d-flex' >
                                                    <div className='icon-div-width'><FontAwesomeIcon icon={faIdBadge} className="" /></div>
                                                    <div>Agents</div>
                                                </div>
                                            </Dropdown.Item>
                                            <Dropdown.Divider />

                                            <Dropdown.Item className="fw-bold" onClick={() => { history.push('/admin/customers'); closeNav() }}>
                                                {/* <FontAwesomeIcon icon={faIdBadge} className="me-2" /> Customers */}
                                                <div className='d-flex' >
                                                    <div className='icon-div-width'><FontAwesomeIcon icon={faIdBadge} className="" /></div>
                                                    <div>Customers</div>
                                                </div>
                                            </Dropdown.Item>
                                            <Dropdown.Divider />
                                        </>
                                    }

                                    {roleId == 3 &&
                                        <>
                                            <Dropdown.Item className="fw-bold" onClick={() => { history.push('/customer/requests/recieved'); closeNav() }}>
                                                {/* <FontAwesomeIcon icon={faInfo} className="me-3" /> Requests */}
                                                <div className='d-flex' >
                                                    <div className='icon-div-width'><FontAwesomeIcon icon={faInfo} className="" /></div>
                                                    <div>Requests</div>
                                                </div>
                                            </Dropdown.Item>
                                            <Dropdown.Divider />
                                        </>
                                    }

                                    {roleId == 4 &&
                                        <>
                                            <Dropdown.Item className="fw-bold" onClick={() => { history.push('/broker-request-list'); closeNav() }}>
                                                {/* <FontAwesomeIcon icon={faInfo} className="me-3" /> Requests */}
                                                <div className='d-flex' >
                                                    <div className='icon-div-width'><FontAwesomeIcon icon={faInfo} className="" /></div>
                                                    <div>Requests</div>
                                                </div>
                                            </Dropdown.Item>
                                            <Dropdown.Divider />
                                        </>
                                    }
                                    {roleId == 3 &&
                                        <>
                                            <Dropdown.Item className="fw-bold" onClick={() => { history.push('/all-document-list', { userId: userId, userName: userName, isEdit: false }); closeNav() }}>
                                                {/* <FontAwesomeIcon icon={faFileAlt} className="me-2" /> Documents */}
                                                <div className='d-flex' >
                                                    <div className='icon-div-width'><FontAwesomeIcon icon={faFileAlt} className="" /></div>
                                                    <div>Documents</div>
                                                </div>
                                            </Dropdown.Item>
                                            <Dropdown.Divider />
                                        </>
                                    }
                                    {/* Phone Notifications */}
                                    <>
                                        <Dropdown.Item className="fw-bold" onClick={() => { history.push('/mobile-notifications', { userId: userId }); closeNav() }}>
                                            {/* <FontAwesomeIcon icon={faBell} className="me-2" /> Notifications */}
                                            <div className='d-flex'>
                                                <div className='icon-div-width'><FontAwesomeIcon icon={faBell} className="" /></div>
                                                <div>
                                                    Notifications
                                                </div>
                                                <div>
                                                    <p className='ms-2 mobile-menu-notification-count'>{notificationCounts}</p>
                                                </div>
                                            </div>

                                        </Dropdown.Item>
                                        <Dropdown.Divider />
                                    </>

                                    <Dropdown.Item className="fw-bold" onClick={() => { handleLogout(); closeNav() }}>
                                        {/* <FontAwesomeIcon icon={faSignOutAlt} className="me-2" /> Sign Out */}
                                        <div className='d-flex' >
                                            <div className='icon-div-width'><FontAwesomeIcon icon={faSignOutAlt} className="" /></div>
                                            <div>Sign Out</div>
                                        </div>
                                    </Dropdown.Item>
                                    <Dropdown.Divider />

                                    {/*  <span className="sign-in-button">
                                        <a href="" onClick={() => { handleLogout(); closeNav() }} className='ms-2'><span className="user-icon-block me-2">
                                            <i className="fa-solid fa-user"></i> <img src={logOutIcon} alt="login" height={20} width={20}/><i className='fa-solid  fa-sign-out'></i> </span>Logout </a>
                                    </span> */}

                                    {/* {roleId == 1 ?
                                        <a onClick={() => history.push('/admin/agents')}>Agents</a> :
                                        <>
                                            <a onClick={() => history.push('/myprofile')}>My Profile</a>
                                            <a onClick={() => history.push('/chat', { userId })}>My Chats</a>
                                        </>
                                    } */}
                                    {/* <a onClick={() => history.push('/chat', { userId })}>My Chats</a> */}
                                </>
                            )

                        }
                        {/* <a className='text-white' onClick={() => history.push('/explore')}>Explore</a>
                        <a className='text-white' onClick={() => history.push('/review')}>Review</a>
                        <a className='text-white' onClick={() => history.push('/about-us')}>About Us</a> */}

                        {/* <Dropdown.Item className="fw-bold" onClick={() => history.push('/explore')}>
                            <FontAwesomeIcon icon={faSearch} className="me-2" /> Explore
                        </Dropdown.Item>
                        <Dropdown.Divider /> */}



                    </div>
                    <div id="main">
                        <span className="toggle-icon" style={{ cursor: 'pointer' }} onClick={openNav}>&#9776;</span>
                    </div>
                </div>
            </header>
        </Fragment>
    )
}
export default Header;