import React, { Fragment, useState, useEffect } from "react";
import "./Index.css";
import Header from "../../layout/Header.jsx";
import Footer from "../../layout/Footer.jsx";
import { GetNewAgentDetailByIdApi } from "../../actions/RealEstateActions.jsx";
import { useLocation } from "react-router";
import { Container, Row, Col } from '@themesberg/react-bootstrap';
import QueryModal from "../../components/air-migrate/QueryModal.jsx";
import QuestionAnswerRounded from "@mui/icons-material/QuestionAnswerRounded";
import CommonAlert from "../../common-components/CommonAlert.jsx";
import PlaceIcon from "@mui/icons-material/Place";
import WorkIcon from '@mui/icons-material/Work';
import WorkspacePremiumOutlinedIcon from '@mui/icons-material/WorkspacePremiumOutlined';
import { GOOGLE_MAP_API_KEY, PROFILE_PATH } from "../../config.tsx";
import Map from "../../components/air-migrate/ServiceArea.jsx";
import CommonServices from "../../common-components/CommonServices.jsx";

const AgentDetails = () => {
    const location = useLocation();
    const [user_uuid ,setUser_uuid] = useState(location.state.userId);
    const [isUserLogin, setIsUserLogin] = useState(false);
    const [showQueryModal, setShowQueryModal] = useState(false);
    const [brokerDetails, setBrokerDetails] = useState({});
    const [userDataObject, setUserDataObject] = useState({});
    const [serviceLocation, setserviceLocation] = useState();
    const [latLongArray, setlatLongArray] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [queryText, setQueryText] = useState('');
    const [title, setTitle] = useState('');
    const [errorMessage, setErrorMessage] = useState({
        isShowTitleError: false,
        isShowQueryError: false
    });
    //Alert states and functions
    const [alertsArray, setAlertsArray] = useState([]);
    const [count, setCount] = useState(0);
    const handleAlert = (id) => {
        let array = [];
        for (let i = 0; i < alertsArray.length; i++) {
            if ((alertsArray[i].id) == id) {
                array = alertsArray.pop();
            }
        }
        setCount(Math.floor(Math.random() * 1000))
    }

    // useEffect #region
    useEffect(() => {
        getAgentDetails(user_uuid);
        setIsUserLogin(false)
        var userDetails = localStorage.getItem("accessToken");
        if (userDetails) {
            setIsUserLogin(true)
        }
    }, [user_uuid, count]);

    // get  userdetails api call here 
    const getAgentDetails = async (user_uuid) => {
        try {
            setIsLoading(true);
            const agentDetails = await GetNewAgentDetailByIdApi(user_uuid);
            setIsLoading(false);
            setBrokerDetails(agentDetails.data[0]);
            getMetaDetails(agentDetails.data[0].locations_data);
        } catch (error) {
            console.error("Error fetching agent details:", error);
        }
    };

    // send query modal functions
    const openQueryModal = () => {
        setShowQueryModal(true);
    };
    const closeQueryModal = () => {
        setShowQueryModal(false);
    };

    //   extracting  metadetails values here  
    const getMetaDetails = (userData) => {
        const userDataObject = userData.reduce((acc, item) => {
            const key = Object.keys(item)[0];
            acc[key] = item[key];
            return acc;
        }, {});
        setUserDataObject(userDataObject);
        /* extrating servie location and lat long */
        const serviceLocations = {};
        const latLong = [];
        Object.keys(userDataObject).forEach(key => {
            if (key.startsWith('service_location')) {
                const value = userDataObject[key];
                const parsedValue = JSON.parse(value);
                serviceLocations[key] = parsedValue.service_location || parsedValue.service_location2 || parsedValue.service_location3;
                latLong.push({ lat: parsedValue.lattitude, long: parsedValue.longitude });
            }
        });
        let locationName = Object.values(serviceLocations);
        let cityNames = locationName.map(city => city.split(', ')[0]);
        setserviceLocation(cityNames);
        setlatLongArray(latLong);
    }
    /* function to get response from query modal for alert */
    const getQueryResponse = (response) => {
        setAlertsArray([...alertsArray, { id: `${'send_query'}${alertsArray.length}`, type: 'success', message: response?.message }]);
    }
    //#region render start
    return (
        <Fragment>
            <Header isUserLogin={isUserLogin} setIsUserLogin={setIsUserLogin} />
            <Container className="agent-outer-container mt-5" fluid>
                <Row>
                    {(alertsArray.length > 0) &&
                        alertsArray.reverse().map((item, index) => {
                            return (
                                <div className='mb-3'>
                                    <CommonAlert key={index} id={item.id} type={item.type} message={item.message} handleAlert={handleAlert} />
                                </div>
                            )
                        })
                    }
                </Row>
                <Row className="new-agent-outer-block">
                    <Col className="new-agent-inner-image-block" sm={12} md={12} lg={4} >
                        <Row>
                            <Col sm={12} md={12} lg={12} className="p-1 ">
                                <img src={brokerDetails?.profile_path ? brokerDetails?.profile_path : PROFILE_PATH} alt="" className="new-agent-image" />
                            </Col>
                            <Col xs={4} sm={4} md={4} lg={4} className="p-1 new-agent-small-img">
                                <img src={userDataObject?.about_image_1 ? userDataObject?.about_image_1 : PROFILE_PATH} alt="" className="new-agent-image new-agent-image-bottom" />
                            </Col>
                            <Col xs={4} sm={4} md={4} lg={4} className="p-1 new-agent-small-img">
                                <img src={userDataObject?.about_image_2 ? userDataObject?.about_image_2 : PROFILE_PATH} alt="" className="new-agent-image new-agent-image-bottom" />
                            </Col>
                            <Col xs={4} sm={4} md={4} lg={4} className="p-1 new-agent-small-img">
                                <img src={userDataObject?.about_image_3 ? userDataObject?.about_image_3 : PROFILE_PATH} alt="" className="new-agent-image new-agent-image-bottom" />
                            </Col>
                        </Row>
                    </Col>
                    <Col className="new-agent-inner-details-block" sm={12} md={12} lg={8} >
                        <Row>
                            <Col sm={12}>
                                <ul className="new-agent-details-list">
                                    <li >
                                        <div className="d-flex align-bottom">
                                            <span className="h5 fw-bolder mb-0">{CommonServices.getUserFullName(brokerDetails)}<span className="small fw-light">{brokerDetails?.gender && brokerDetails?.gender == 1 ? '(M)' : '(F)'}</span></span>
                                            <span>
                                                <button type="button" className="ms-2 new-agent-query-btn" onClick={openQueryModal}>
                                                    Connect<QuestionAnswerRounded
                                                        className="fs-small " style={{ color: "#ffffff", fontSize: "20px", marginLeft: 10 }}
                                                    />
                                                </button>
                                            </span>
                                        </div>
                                        <div>
                                            <p className=" text-dark"><PlaceIcon className="  " style={{ color: "", fontSize: "23px", marginRight: 8 }} /> {(brokerDetails.address?.city || brokerDetails.address?.country) ? (brokerDetails.address.city + ', ' + brokerDetails.address.country) : 'Not Available'}</p>
                                        </div>
                                    </li>
                                    <li><span>Phone:</span><span className="ms-2">{brokerDetails?.primary_country_code ? ('+'+brokerDetails.primary_country_code +brokerDetails.primary_phone) : 'Not Available'}</span></li>
                                    <li><span>Email:</span><span className="ms-2">{brokerDetails?.email ? brokerDetails.email : 'Not Available'}</span></li>
                                    <li className="fw-bolder">
                                        <WorkIcon className="fs-small " style={{ color: "", fontSize: "20px", marginRight: 10 }} />
                                            <span className="agent-meta-details-width">License Authority</span>
                                            <span className="ms-3  fw-normal">
                                                <span className="me-3 dot-text">:</span>
                                                <span className="contact-details-info-text-small">
                                                {userDataObject.license_authority ? userDataObject.license_authority : 'Not Available'}</span>
                                            </span>
                                    </li>
                                    <li className="fw-bolder"><WorkspacePremiumOutlinedIcon className="fs-small " style={{ color: "", fontSize: "20px", marginRight: 10 }} />
                                        <span className="agent-meta-details-width">Agent License</span>
                                        <span className="ms-3  fw-normal">
                                            <span className="me-3 dot-text">:</span>
                                            <span className="contact-details-info-text-small">
                                                {userDataObject.estate_license_number ? userDataObject.estate_license_number : 'Not Available'}
                                                </span>
                                            </span>
                                    </li>
                                    <li className="fw-bolder"><PlaceIcon className="fs-small " style={{ color: "", fontSize: "20px", marginRight: 10 }} /><span className="agent-meta-details-width">Service Area</span><span className="ms-3  fw-normal"><span className="me-3 dot-text">:</span>
                                    <span className="contact-details-info-text-small">
                                      {serviceLocation && serviceLocation.length > 0 ? <span>{serviceLocation.join(', ')}</span> : "Not Available"}</span></span></li>
                                    <li>
                                        <div className="ms-1">
                                            {brokerDetails?.description != "<p><br></p>" ? (
                                                <p dangerouslySetInnerHTML={{ __html: brokerDetails?.description }}></p>
                                            ) : (
                                                <p>No Description Available</p>
                                            )}
                                        </div>
                                    </li>
                                </ul>
                            </Col>
                            <Col sm={12} className="ms-2 new-agent-map-column">
                                <p className="fw-bolder fs-5">Service Area</p>
                                <div className="new-agent-details-map">
                                    <Map
                                        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}&v=3.exp&libraries=geometry,drawing,places`}
                                        loadingElement={<div style={{ height: '100%' }} />}
                                        containerElement={<div style={{ height: '100%', width: '100%' }} />}
                                        mapElement={<div style={{ height: '100%', width: '100%' }} />}
                                        locations={latLongArray}
                                        showRangeCircle={true}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {/* Query modal */}
                <QueryModal
                    errorMessage={errorMessage}
                    setErrorMessage={setErrorMessage}
                    queryText={queryText}
                    setQueryText={setQueryText}
                    title={title}
                    setTitle={setTitle}
                    show={showQueryModal}
                    handleClose={closeQueryModal}
                    setIsLoading={setIsLoading}
                    isLoading={isLoading}
                    user_uuid={user_uuid}
                    getQueryResponse={getQueryResponse}
                />
            </Container>
            <Footer isUserLogin={isUserLogin} setIsUserLogin={setIsUserLogin} />
        </Fragment>
    );
};
//#endregion render end
export default AgentDetails;
