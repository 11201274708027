import { Fragment, useEffect, useState } from "react"
import { Row } from "@themesberg/react-bootstrap";
import { Toaster } from "react-hot-toast";
import { DataLoading } from "../../components/air-migrate/Loader";
import "./Index.css"
import Header from "../../layout/Header";
import Footer from "../../layout/Footer";

const AboutUs = () => {
    //#region state variables
    const [isUserLogin, setIsUserLogin] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    //#endregion state variables
    
    useEffect(() => {
        setTimeout(() => setIsLoading(false), 100)
    }, []);

    useEffect(() => {
        setIsUserLogin(false)
        var userDetails = localStorage.getItem("accessToken");
        if (userDetails) {
            setIsUserLogin(true)
        }
    },[])

    //#region render start 
    return (
        <Fragment>
            <Header isUserLogin={isUserLogin} setIsUserLogin={setIsUserLogin} />
            <section className="banking-page container-fluid p-0 background-outer-container mt-3 position-relative">
                <div className="banner-card ">
                    <section className={`about-us-banner row mb-2 ${isUserLogin ? '' : 'logout-banner-margin-about-us'}`}>
                        <div className="col-12 m-0 p-0">
                            <div className="terms-banner-text ">
                                <div className="about-us-banner-inner-block">
                                    <h2 className="banner-head">
                                        <span className="heading-blue-text"> AirMigrate</span>
                                        <span className=""> Empowering Your Journey to New Beginnings</span>
                                    </h2>
                                    <p className="text-white">
                                        Welcome to AirMigrate, where we simplify your transition to a new country. With a comprehensive suite of services including immigration consulting, real estate, banking, insurance, and telecom, we are dedicated to making your move as smooth and secure as possible.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            
            <section className="about-us-text-section">
                <Row className="about-us-row">
                        <div className="about-us-text">
                            <h2 className="">About Us</h2>
                            <p>
                                <p className=" fw-bolder text-decoration-underline">AirMigrate: Empowering Your Journey to New Beginnings</p>

                                <p>Welcome to AirMigrate, where we simplify your transition to a new country. With a comprehensive suite of services including immigration consulting, real estate, banking, insurance, and telecom, we are dedicated to making your move as smooth and secure as possible.</p>

                                <p className=" fw-bolder text-decoration-underline"> Our Mission</p>

                                <p> To redefine the immigration experience by providing a seamless, secure, and integrated platform that connects newcomers with the essential services needed for a successful transition. Our mission is to remove barriers for immigrants and travelers with expert-driven, customized solutions that ensure you start your new life on the right foot.</p>

                                <p className=" fw-bolder text-decoration-underline"> What We Do?</p>

                                <p className=" fw-bolder">Immigration Consulting:</p>
                                <p> Avoid scams and navigate the complexities of immigration laws with our certified consultants. We handle the details, so you can focus on your future.</p>

                                <p className=" fw-bolder"> Real Estate Services:</p>
                                <p> Find your new home abroad with ease. Our real estate experts specialize in helping immigrants locate the perfect residence that meets their needs and budget.</p>

                                <p className=" fw-bolder">Banking Solutions:</p>
                                <p> Don't settle for less than the best. Our platform offers tailored banking products from top financial institutions, ensuring you receive the optimal deals to manage your finances effectively.</p>

                                <p className=" fw-bolder">Insurance Services:</p>
                                <p> Secure comprehensive coverage for health, travel, and family. Whether you’re seeking visitor visa medical protection or robust travel insurance, our offerings are designed to provide extensive protection from the moment you land.</p>

                                <p className=" fw-bolder">Telecom Services:</p>
                                <p> Stay connected from the moment you arrive with our tailored telecom solutions. Whether you need mobile, internet, or landline services, we help you find the best plans and deals, ensuring you stay connected with loved ones and essential services without hassle.</p>

                                <p className=" fw-bolder text-decoration-underline"> Our Promise</p>

                                <p> At AirMigrate, your security and privacy are paramount. We use state-of-the-art security measures to protect your personal information and ensure your data is handled with the highest standards of confidentiality.</p>

                                <p className=" fw-bolder text-decoration-underline"> Why Choose AirMigrate?</p>

                                <p>Expertise: Our team is composed of industry professionals who bring years of experience and specialized knowledge to assist you.</p>

                                <p>Comprehensive Support: From finding a home to setting up your banking and telecom abroad, we guide you through every step of the process.</p>

                                <p>Personalized Services: We understand that each individual’s needs are unique. At AirMigrate, we tailor our services to meet your specific requirements, ensuring a personalized approach to your new life.</p>

                                <p className=" fw-bolder text-decoration-underline">Join Us on Your Journey</p>

                                <p>Whether you are just starting to plan your move or are already navigating your new environment, AirMigrate is here to help every step of the way. Log In securely and start your journey with confidence, backed by the comprehensive support and expert care of AirMigrate.</p>

                                <p className=" fw-bolder text-decoration-underline"> Your Trust, Our Commitment</p>

                                <p>At AirMigrate, we don't just facilitate your move—we ensure you thrive in your new environment. Unlock a new way the world immigrates; unlock your potential with AirMigrate.</p>
                            </p>

                        </div>
                </Row>
            </section>
            </section>
            <Toaster position="top-right" reverseOrder={false} />
            <Footer isUserLogin={isUserLogin} setIsUserLogin={setIsUserLogin} />
            {isLoading === true && <DataLoading />}
        </Fragment >
    );

}
//#endregion render end
export default AboutUs;