import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';

//Local imports
import '../assets/global-css/Index.css';
import AirMigrateFooterLogo from '../assets/img/landing-page/logo-img.png';
import AppleStorelogo from '../assets/img/landing-page/appleAppStore.svg'
import PlayStorelogo from '../assets/img/landing-page/googlePlayStore.svg'
import { newsLaterEmailApi } from '../actions/ClientActions';
import CommonServices from '../common-components/CommonServices';

const Footer = ({ isUserLogin }) => {
    const [email, setEmail] = useState();
    const [message, setMessage] = useState('');
    const history = useHistory();
    const emailInput = useRef();
    /* subsrcibe newslater fuction call on send button click */
    const handleNewsletterSubscription = () => {
        setMessage('');
        setEmail('');

        if (CommonServices.isValidEmail(email)) {
            const dataToSend = { email };
            newsLaterEmailApi(dataToSend)
                .then((response) => {
                    if (response.status_code === 1) {
                        setMessage("You have successfully subscribed");
                    } else {
                        setMessage(response.message);
                    }
                })
                .catch((error) => {
                    console.error("Error subscribing to newsletter:", error);
                    setMessage("An error occurred. Please try again.");
                });
        } else {
            setMessage("Please enter a valid email address");
        }
    };
    // /* email validation function */
    // const isValidEmail = (email) => {
    //     const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    //     return emailRegex.test(email);
    // };

    return (
        <Fragment>
            <footer>
                {/* old footer bg class condition */}
                {/* className={ `footer-section-block  ${isUserLogin ? 'login-background' : 'logout-footer-background'}`} */}
                <div className={`footer-section-block   mt-2`}>
                    <div className="container">
                        <div className="row footer-row">
                            <div className="col-sm-12 col-md-4 col-lg-4 p-1">
                                <div className="footer-block-inner custom-footer-logo">
                                    <div className="footer-logo-block cursor-pointer" onClick={() => history.push('/')}>
                                        <img src={AirMigrateFooterLogo} alt="logo image " />
                                        {/* <div className='footer-app-store-block'>
                                            <img src={AppleStorelogo} alt="Apple Store image" />
                                            <img src={PlayStorelogo} alt="Play Store image" />
                                        </div> */}
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-12 col-md-4 col-lg-4">
                                <div className="footer-block-inner link-block">
                                    <h3>Quick Links</h3>
                                    <div className="footer-nav-link">
                                        <ul>
                                            <li><a className='f-18' onClick={() => history.push('/terms-condition')}>Terms & Conditions</a></li>
                                            <li><a className='f-18' onClick={() => history.push('/about-us')}>About Us</a></li>
                                            <li><a className='f-18' onClick={() => history.push('/privacy-policy')}>Privacy Policy</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            {/* <div className="col-sm-12 col-md-5 col-lg-5 ">
                                <div className="footer-block-inner ms-md-5 ms-0">
                                    <h3 className='footer-subscribe-text'>Subscribe</h3>
                                    <p className='footer-subscribe-text'>Sign up for our monthly blogletter to stay informed about our services.</p>
                                </div>
                            </div> */}
                            <div className="col-sm-12 col-md-4 col-lg-4 ">
                                <div className="footer-block-inner subscribe-block">
                                    <div className="footer-block-inner ">
                                        <h3 className='footer-subscribe-text'>Subscribe</h3>
                                        <p className='footer-subscribe-text'>Sign up for our monthly blogletter to stay informed about our services.</p>
                                    </div>
                                    <div className="subscribe-input-block">
                                        <input type="email" placeholder="Email Address" value={email} onChange={(e) => { setEmail(e.target.value) }} />
                                        <span className="subscribe-send-btn cursor-pointer" onClick={handleNewsletterSubscription}>Send</span>
                                    </div>
                                    <p className='text-dark'>{message ? message : ''}</p>

                                </div>
                            </div>
                            <div className="copyright-block">Copyright © Airmigrate {new Date().getFullYear()}</div>
                        </div>
                    </div>
                </div>
            </footer>
        </Fragment>
    )
}
export default Footer;