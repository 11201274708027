import React, { useState, useEffect } from 'react';
import { Route, Switch, Redirect, useHistory, useLocation } from "react-router-dom";
import { Routes } from "../routes";

//air migrate imports
import DirectLogin from '../views/direct-login/Index.jsx'; 
import HomePage from '../views/home/Index';
import ChatGPTLandingPage from '../views/chat-gpt-landing-page/index';
import LandingPage from '../views/landing-page/Index';
import VerifyOtp from '../views/verify-otp/Index';
import Login from '../views/login/Index';
import Telecom from '../views/telecom/Index';
import Banking from '../views/banking/Index';
import ClientList from '../views/clients/Index';
import AddClient from '../views/clients/add-client/Index';
import EditClient from '../views/clients/edit-client/Index';
import InviteCaseVerify from '../views/case-invite-verify/Index'
import ChatVeirfy from '../views/chat-link-verify/Index.jsx'
import MyChatComponents from '../views/my-chats/Index';
import Explore from '../views/explore-page/Index';
// import Review from '../views/review-page/Index';
import AboutUs from '../views/about-us/Index';
import ESign from '../views/e-sign/Index';
import CaseList from '../views/cases/Index';
import CaseDetails from '../views/cases/case-details/Index';
import RequestList from '../views/requests/Index';
import AllDocumentList from '../views/view-all-documents/Index';
import QueryRequests from '../views/customers/customer-requests/query-requests/Index';
import RecievedRequests from '../views/customers/customer-requests/recieved-requests/Index';
import MobileNotification from '../views/mobile-notifications/Index'
import DefaultMessagePage from '../components/air-migrate/DefaultPageAfterSignup';
import ChatUsingGemini from '../views/chat-using-gemini/Index.jsx';
import AllDocumentListDemo from '../views/document-compress-demo/Index.jsx';
// pages
import Presentation from "./Presentation";
import Upgrade from "./Upgrade";
import DashboardOverview from "./dashboard/DashboardOverview";
import Transactions from "./Transactions";
import Settings from "./Settings";
import BootstrapTables from "./tables/BootstrapTables";
import Signin from "./examples/Signin";
import Signup from "./examples/Signup";
import ForgotPassword from "./examples/ForgotPassword";
import ResetPassword from "./examples/ResetPassword";
import Lock from "./examples/Lock";
import NotFoundPage from "./examples/NotFound";
import ServerError from "./examples/ServerError";


// documentation pages
import DocsOverview from "./documentation/DocsOverview";
import DocsDownload from "./documentation/DocsDownload";
import DocsQuickStart from "./documentation/DocsQuickStart";
import DocsLicense from "./documentation/DocsLicense";
import DocsFolderStructure from "./documentation/DocsFolderStructure";
import DocsBuild from "./documentation/DocsBuild";
import DocsChangelog from "./documentation/DocsChangelog";

// components
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Preloader from "../components/Preloader";

import Accordion from "./components/Accordion";
import Alerts from "./components/Alerts";
import Badges from "./components/Badges";
import Breadcrumbs from "./components/Breadcrumbs";
import Buttons from "./components/Buttons";
import Forms from "./components/Forms";
import Modals from "./components/Modals";
import Navs from "./components/Navs";
import Navbars from "./components/Navbars";
import Pagination from "./components/Pagination";
import Popovers from "./components/Popovers";
import Progress from "./components/Progress";
import Tables from "./components/Tables";
import Tabs from "./components/Tabs";
import Tooltips from "./components/Tooltips";
import Toasts from "./components/Toasts";
import EditPayment from '../views/clients/edit-payment/Index';
import AgentDetails from '../views/agent-details-page/Index'
import RealEstate from '../views/real-estate/Index';
import Registration from '../views/user-registration/Index';
import RegistrationVerifyOtp from '../views/registration-verify-otp/Index';
import ImmigrationCaseList from "../views/cases/immigration-case-list/Index";
import BrokerCaseList from "../views/cases/broker-case-list/Index";
import MyProfile from '../views/my-profile/Index';
import AgentList from '../views/agents/agent-list/Index';
import AgentRequestList from '../views/agents/agent-request-list/Index';
import CustomerList from '../../src/views/customers/Index'
import BrokerRequestList from '../../src/views/requests/broker-request/Index';
import Header from '../layout/Header';
import { SOCKET_SERVER_URL } from '../config.tsx';
import io from 'socket.io-client'
// import NewRealEstate from '../views/new-real-estate/Index';
import CustomerType from '../views/customer-type/Index.jsx';
import CustomerTypeRequest from '../views/customers/customer-type-request/Index.jsx';
import TermsCondition from '../views/terms-condition/Index.jsx';
import { setUserStatus, setIsVisaApproved, setVisaOption, setIsVisaUploaded} from '../reducers/VisaUploadReducer.jsx';
import { useDispatch, useSelector } from 'react-redux';
import PrivacyPolicy from '../views/privacy-policy/Index.jsx';

const allowedPaths = ['/', '/explore', '/review', '/about-us', '/real-estate', '/telecom', '/banking', '/quick-chat', '/registration', '/login', '/verify-otp', '/registration-verify-otp', '/customer-type','terms-condition'];


const RouteWithLoader = ({ component: Component, ...rest }) => {
  const userToken = localStorage.getItem('accessToken');
  const [loaded, setLoaded] = useState(false);

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const currentPath = location.pathname;
  const amUserId = localStorage.getItem('amUserId');
  const newSocket = io(SOCKET_SERVER_URL);

  const roleId = localStorage.getItem('roleId');
  const visaUploadModule = useSelector(state => state.visaUploadModule);
  // console.log('isVisaUploaded', visaUploadModule, roleId)

  useEffect(() => {
    // console.log("RouteWithLoader", currentPath)
    const timer = setTimeout(() => setLoaded(true), 1000);
    if (visaUploadModule.isUserLogin) {
      // Customer Case
      if (roleId == 3) {
        if (visaUploadModule.visaOption == 0) {
          // console.log('option', 0)
          if (!(allowedPaths.includes(currentPath))) {
            history.push('/customer-type');
          }
        }
        else if (visaUploadModule.visaOption == 1) {
          // console.log('option', 1)
        }
        else if (visaUploadModule.visaOption == 2) {
          // console.log('option', 2)
          // Uploaded & Approved
          if (visaUploadModule.isVisaUploaded && visaUploadModule.isVisaApproved) {
            // console.log('Uploaded & Approved')
          }
          // Uploaded & not Approved
          else if (visaUploadModule.isVisaUploaded && !visaUploadModule.isVisaApproved) {
            // console.log('Uploaded & not Approved')
            if (!(allowedPaths.includes(currentPath))) {
              history.push('/status-pending-page');
            }
          }
          // not Uploaded 
          else if (!visaUploadModule.isVisaUploaded) {
            // console.log('not Uploaded ')
            if (!(allowedPaths.includes(currentPath))) {
              history.push('/customer-type');
            }
          } else {
            // console.log('Need to change 1')
          }
        }
        else {
          // console.log('Need to change 2')
        }
      }

      // Agents Case
      else if (visaUploadModule.userStatus != 1) {
        // console.log('regi: load', visaUploadModule.userStatus)

        if (!(allowedPaths.includes(currentPath))) {
          history.push(Routes.DefaultMessagePage.path);
        }
      }
      else {
        // console.log('Need to change 3')
      }

    }
    // setUserStatus(tempUserStatus);
    return () => clearTimeout(timer);
  }, [Component, roleId]);

  useEffect(() => {
    if (amUserId) {
      newSocket.on('connect', () => {
        newSocket.emit('connect_on_login_status', amUserId)
      })
      newSocket.on('connect_on_login_status_check', (response) => {
        console.log('connect_on_login_status_check', response)
        // Visa Case for Customer
        if (roleId == 3 && response?.visa_approve) {

          const isVisaApproved = response?.visa_approve == 1 ? true : false;
          localStorage.setItem("isVisaApproved", isVisaApproved);
          dispatch(setIsVisaApproved(isVisaApproved))

          localStorage.setItem("visaOption", response?.visa_option);
          dispatch(setVisaOption(response?.visa_option))

          // Reject Case
          if(response?.visa_approve  == 2){
            localStorage.setItem("isVisaUploaded", false);
            dispatch(setIsVisaUploaded(false))
          }
        }
        // Agents Case
        else if (response.status == 1) {
          localStorage.setItem('status', response.status);
          dispatch(setUserStatus(response.status));
          history.push('/chat', { userId: amUserId });

        }


      })
    }
    return () => { newSocket.disconnect(); }
  }, [newSocket])



  return (
    <Route {...rest} render={props => (<> <Component {...props} /> </>)} />
  );
};

const RouteWithSidebar = ({ component: Component, ...rest }) => {
  const userToken = localStorage.getItem('accessToken');
  const history = useHistory();
  const location = useLocation();
  const currentPath = location.pathname;
  // seting header sending props
  const [isUserLogin, setIsUserLogin] = useState(userToken ? true : false);
  const [singleScreen, setSingleScreen] = useState(false)
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  /* paths array that are accesible for all without status required */
  const [loaded, setLoaded] = useState(false);
  /* get user status from local storage */
  // const statusOfUser = localStorage.getItem('status') == 1 ? true : false;
  // const [tempUserStatus, setTempUserStatus] = useState(statusOfUser);
  // const [userStatus, setUserStatus] = useState(statusOfUser);
  const amUserId = localStorage.getItem('amUserId');
  const newSocket = io(SOCKET_SERVER_URL);
  const roleId = localStorage.getItem('roleId');
  const visaUploadModule = useSelector(state => state.visaUploadModule);
  const dispatch = useDispatch()
  // console.log('isVisaUploaded', visaUploadModule, roleId)

  useEffect(() => {
    // console.log("RouteWithSidebar", currentPath)
    if (visaUploadModule.isUserLogin) {
      // Customer Case
      if (roleId == 3) {
        if (visaUploadModule.visaOption == 0) {
          // console.log('option', 0)
          if (!(allowedPaths.includes(currentPath))) {
            history.push('/customer-type');
          }
        }
        else if (visaUploadModule.visaOption == 1) {
          // console.log('option', 1)
        }
        else if (visaUploadModule.visaOption == 2) {
          // console.log('option', 2)
          // Uploaded & Approved
          if (visaUploadModule.isVisaUploaded && visaUploadModule.isVisaApproved) {
            // console.log('Uploaded & Approved')
          }
          // Uploaded & not Approved
          else if (visaUploadModule.isVisaUploaded && !visaUploadModule.isVisaApproved) {
            // console.log('Uploaded & not Approved')
            if (!(allowedPaths.includes(currentPath))) {
              history.push('/status-pending-page');
            }
          }
          // not Uploaded 
          else if (!visaUploadModule.isVisaUploaded) {
            // console.log('not Uploaded ')
            if (!(allowedPaths.includes(currentPath))) {
              history.push('/customer-type');
            }
          } else {
            // console.log('Need to change 1')
          }
        }
        else {
          // console.log('Need to change 2')
        }
      }

      // Agents Case
      else if (visaUploadModule.userStatus != 1) {
        // console.log('regi: side', visaUploadModule.userStatus)

        if (!(allowedPaths.includes(currentPath))) {
          history.push(Routes.DefaultMessagePage.path);
        }
      }
      else {
        // console.log('Need to change 3')
      }

    }
    // const timer = setTimeout(() => setLoaded(true), 1000);
    // return () => clearTimeout(timer);
  }, [Component, roleId]);

  /* useEffect for Socket */
  useEffect(() => {
    if (amUserId) {
      newSocket.on('connect', () => {
        newSocket.emit('connect_on_login_status', amUserId)
      })
      newSocket.on('connect_on_login_status_check', (response) => {
        // Visa Case for Customer
        if (roleId == 3 && response?.visa_approve) {

          const isVisaApproved = response?.visa_approve == 1 ? true : false;
          localStorage.setItem("isVisaApproved", isVisaApproved);
          dispatch(setIsVisaApproved(isVisaApproved))

          localStorage.setItem("visaOption", response?.visa_option);
          dispatch(setVisaOption(response?.visa_option))
          // Reject Case
          if(response?.visa_approve  == 2){
            localStorage.setItem("isVisaUploaded", false);
            dispatch(setIsVisaUploaded(false))
          }
        }
        // Agents Case
        else if (response.status == 1) {
          localStorage.setItem('status', response.status);
          dispatch(setUserStatus(response.status));
          history.push('/chat', { userId: amUserId });

        }

      })
    }
    return () => { newSocket.disconnect(); }
  }, [newSocket])

  const localStorageIsSettingsVisible = () => {
    return localStorage.getItem('settingsVisible') === 'false' ? false : true
  }

  const [showSettings, setShowSettings] = useState(localStorageIsSettingsVisible);

  const toggleSettings = () => {
    setShowSettings(!showSettings);
    localStorage.setItem('settingsVisible', !showSettings);
  }

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight
    }
  }

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension())
    }
    window.addEventListener('resize', updateDimension);

    // set a variable true when screen width reaches <768px
    if (screenSize.width <= 767) {
      setSingleScreen(true)
    } else {
      setSingleScreen(false)
    }
    // removing eventlistener for no furhter effect after 
    return (() => {
      window.removeEventListener('resize', updateDimension);
    })

  }, [screenSize])


  return (
    <Route {...rest} render={props => (
      <>
        {/* <Preloader show={loaded ? false : true} /> */}

        {singleScreen ? <Header isUserLogin={isUserLogin} setIsUserLogin={setIsUserLogin} /> : ""}


        <main >
          {/* <Navbar /> */}
          <Header isUserLogin={isUserLogin} setIsUserLogin={setIsUserLogin} />
          <div className="content">
          <Component {...props} />
          <Footer toggleSettings={toggleSettings} showSettings={showSettings} />
          </div>
          
        </main>
      </>
    )}
    />
  );
};

export default () => (
  <>
    <Switch>
      {/*AirMigrate*/}
      {/* <RouteWithLoader exact path={Routes.NewHomePage.path} component={HomePage} /> */}
      <RouteWithSidebar exact path={Routes.DirectLogin.path} component={DirectLogin} />
      <RouteWithLoader exact path={Routes.LandingPage.path} component={HomePage} />
      <RouteWithLoader exact path={Routes.ChatUsingGemini.path} component={ChatUsingGemini} />

      <RouteWithLoader exact path={Routes.ChatGPTLandingPage.path} component={ChatGPTLandingPage} />
      <RouteWithLoader exact path={Routes.Login.path} component={Login} />
      <RouteWithLoader exact path={Routes.VerifyOtp.path} component={VerifyOtp} />
      <RouteWithLoader exact path={Routes.CustomerType.path} component={CustomerType} />
      <RouteWithLoader exact path={Routes.RegistrationVerifyOtp.path} component={RegistrationVerifyOtp} />
      <RouteWithLoader exact path={Routes.Registration.path} component={Registration} />
      <RouteWithLoader exact path={Routes.Telecom.path} component={Telecom} />
      <RouteWithLoader exact path={Routes.Banking.path} component={Banking} />
      <RouteWithSidebar exact path={Routes.ClientList.path} component={ClientList} />
      <RouteWithSidebar exact path={Routes.AddClient.path} component={AddClient} />
      <RouteWithSidebar exact path={Routes.EditClient.path} component={EditClient} />
      <RouteWithSidebar exact path={Routes.EditPayment.path} component={EditPayment} />
      <RouteWithSidebar exact path={Routes.DashboardOverview.path} component={DashboardOverview} />
      <RouteWithSidebar exact path={Routes.RequestList.path} component={RequestList} />
      <RouteWithSidebar exact path={Routes.CaseList.path} component={CaseList} />
      <RouteWithSidebar exact path={Routes.ImmigrationCaseList.path} component={ImmigrationCaseList} />
      <RouteWithSidebar exact path={Routes.BrokerCaseList.path} component={BrokerCaseList} />
      <RouteWithSidebar exact path={Routes.CaseDetails.path} component={CaseDetails} />
      <RouteWithSidebar exact path={Routes.CustomerQueryRequest.path} component={QueryRequests} />
      <RouteWithSidebar exact path={Routes.CustomerRecievedRequest.path} component={RecievedRequests} />
      <RouteWithSidebar exact path={Routes.BrokerRequestList.path} component={BrokerRequestList} />

      {/* Phone Notification Route */}
      <RouteWithSidebar exact path={Routes.MobileNotification.path} component={MobileNotification} />


      {/* Chat Routes */}
      <RouteWithLoader exact path={Routes.ESign.path} component={ESign} />
      <RouteWithLoader exact path={Routes.MyChatComponents.path} component={MyChatComponents} />
      <RouteWithLoader exact path={Routes.ChatVeirfy.path} component={ChatVeirfy} />
      <RouteWithLoader exact path={Routes.InviteCaseVerify.path} component={InviteCaseVerify} />

      {/* Admin Routes */}
      <RouteWithSidebar exact path={Routes.AgentList.path} component={AgentList} />
      <RouteWithSidebar exact path={Routes.AgentRequestList.path} component={AgentRequestList} />
      <RouteWithSidebar exact path={Routes.CustomerList.path} component={CustomerList} />
      <RouteWithSidebar exact path={Routes.CustomerTypeRequest.path} component={CustomerTypeRequest} />
      <RouteWithLoader exact path={Routes.RealEstate.path} component={RealEstate} /> 
      <RouteWithLoader exact path={Routes.AgentDetails.path} component={AgentDetails} />
      <RouteWithLoader exact path={Routes.Presentation.path} component={Presentation} />
      <RouteWithLoader exact path={Routes.Signin.path} component={Signin} />
      <RouteWithLoader exact path={Routes.Signup.path} component={Signup} />
      <RouteWithLoader exact path={Routes.ForgotPassword.path} component={ForgotPassword} />
      <RouteWithLoader exact path={Routes.ResetPassword.path} component={ResetPassword} />
      <RouteWithLoader exact path={Routes.Lock.path} component={Lock} />
      <RouteWithLoader exact path={Routes.NotFound.path} component={NotFoundPage} />
      <RouteWithLoader exact path={Routes.ServerError.path} component={ServerError} />
      <RouteWithLoader exact path={Routes.Explore.path} component={LandingPage} />
      <RouteWithLoader exact path={Routes.AboutUs.path} component={AboutUs} />x
      <RouteWithSidebar exact path={Routes.AllDocumentList.path} component={AllDocumentList} />
      <RouteWithSidebar exact path={Routes.MyProfile.path} component={MyProfile} />
      <RouteWithSidebar exact path={Routes.AgentProfile.path} component={MyProfile} />
      <RouteWithSidebar exact path={Routes.CustomerProfile.path} component={MyProfile} />
      <RouteWithLoader exact path={Routes.DefaultMessagePage.path} component={DefaultMessagePage} />
      <RouteWithLoader exact path={Routes.TermsCondition.path} component={TermsCondition} />
      <RouteWithLoader exact path={Routes.PrivacyPolicy.path} component={PrivacyPolicy} />
      <RouteWithLoader exact path={Routes.AllDocumentListDemo.path} component={AllDocumentListDemo} />
      


      {/* pages */}

      <RouteWithSidebar exact path={Routes.Upgrade.path} component={Upgrade} />
      <RouteWithSidebar exact path={Routes.Transactions.path} component={Transactions} />
      <RouteWithSidebar exact path={Routes.Settings.path} component={Settings} />
      <RouteWithSidebar exact path={Routes.BootstrapTables.path} component={BootstrapTables} />

      {/* components */}
      <RouteWithSidebar exact path={Routes.Accordions.path} component={Accordion} />
      <RouteWithSidebar exact path={Routes.Alerts.path} component={Alerts} />
      <RouteWithSidebar exact path={Routes.Badges.path} component={Badges} />
      <RouteWithSidebar exact path={Routes.Breadcrumbs.path} component={Breadcrumbs} />
      <RouteWithSidebar exact path={Routes.Buttons.path} component={Buttons} />
      <RouteWithSidebar exact path={Routes.Forms.path} component={Forms} />
      <RouteWithSidebar exact path={Routes.Modals.path} component={Modals} />
      <RouteWithSidebar exact path={Routes.Navs.path} component={Navs} />
      <RouteWithSidebar exact path={Routes.Navbars.path} component={Navbars} />
      <RouteWithSidebar exact path={Routes.Pagination.path} component={Pagination} />
      <RouteWithSidebar exact path={Routes.Popovers.path} component={Popovers} />
      <RouteWithSidebar exact path={Routes.Progress.path} component={Progress} />
      <RouteWithSidebar exact path={Routes.Tables.path} component={Tables} />
      <RouteWithSidebar exact path={Routes.Tabs.path} component={Tabs} />
      <RouteWithSidebar exact path={Routes.Tooltips.path} component={Tooltips} />
      <RouteWithSidebar exact path={Routes.Toasts.path} component={Toasts} />

      {/* documentation */}
      <RouteWithSidebar exact path={Routes.DocsOverview.path} component={DocsOverview} />
      <RouteWithSidebar exact path={Routes.DocsDownload.path} component={DocsDownload} />
      <RouteWithSidebar exact path={Routes.DocsQuickStart.path} component={DocsQuickStart} />
      <RouteWithSidebar exact path={Routes.DocsLicense.path} component={DocsLicense} />
      <RouteWithSidebar exact path={Routes.DocsFolderStructure.path} component={DocsFolderStructure} />
      <RouteWithSidebar exact path={Routes.DocsBuild.path} component={DocsBuild} />
      <RouteWithSidebar exact path={Routes.DocsChangelog.path} component={DocsChangelog} />

      <Redirect to={Routes.NotFound.path} />
    </Switch>
  </>
);
