import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useHistory } from 'react-router-dom';
import { Card, ListGroup, Button, Row, Col, Form, Breadcrumb, Image, Modal } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faMinusCircle, faPlus, faRemoveFormat } from "@fortawesome/free-solid-svg-icons";
import { toast, Toaster } from 'react-hot-toast';
import { useFormik } from 'formik';
import * as Yup from 'yup';
// import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

import { CreatePaymentIsVisibleApi } from "../../../actions/PaymentActions";
//Local imports
import "./Index.css";
import { CreatePaymentApi, GetPaymentDetailsByIdApi, GetPaymentListApi, UpdatePaymentApi, DeletePaymentInstallmentApi } from "../../../actions/PaymentActions";
import { DataLoading } from "../../../components/air-migrate/Loader";
import { Capitalize } from "../../../common-components/StringConversions";
import Delete from '../../../assets/img/icons/Airmigrate icons/16px/Trash.png';
import Cross from '../../../assets/img/icons/Airmigrate icons/16px/Cross.png';
import Tick from '../../../assets/img/icons/Airmigrate icons/16px/Tick.png';
import { useDispatch } from "react-redux";
import { setInstallment } from "../../../reducers/PaymentReducer";
import CaseCompeteInfoModal from "../../../components/air-migrate/CaseCompleteMessageModal";

const EditPayment = ({ userUuid, caseId, userRoleId, alertsArray, setAlertsArray, handleAlert, caseStatus }) => {

  //#region State and Variables
  const history = useHistory();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  // const [userUuid, setUserUuid] = useState(location.state?.userId);
  const [paymentDetails, setPaymentDetails] = useState({});
  const [totalPayment, setTotalPayment] = useState('');
  const [copyPaymentDetails, setCopyPaymentDetails] = useState({});
  const [paymentInitialFields, setPaymentInitialFields] = useState({});
  const [isAddMoreClicked, setIsAddMoreClicked] = useState(false);
  const [paymentFields, setPaymentFields] = useState({
    installmentName: '',
    installmentAmount: ''
  });
  //state created to render the page again to show updated data
  const [count, setCount] = useState(2);
  const [isCheckboxVisible, setIsCheckboxVisible] = useState(false);
  const [showErrors, setShowErrors] = useState({ showNameError: false, showAmountError: false });
  const dispatch = useDispatch();
  const [showCaseCompleteInfoModal, setshowCaseCompleteInfoModal] = useState(false);
  const [addMoreCount, setAddMoreCount] = useState(0);
  //#endregion

  //#region  useEffect
  useEffect(() => {
    GetPaymentDetails();
  }, [count])
  //#endregion

  //#region Formik section

  const PaymentSchema = async (formField) => {
    const validateObj = {};
    formField?.length && formField?.map(field => {
      {
        const name = field.label_text?.toLowerCase().replaceAll(" ", "_")
        Object.assign(validateObj, {
          [name]:
            Yup.string().required('Field is required').when(name, {
              is: name > Number(totalPayment),
              then: Yup.string().max('Invalid input')
            }),
        })
      };
    });
    return validateObj;
  }


  //#endregion 

  //#region Functions
  const GetPaymentDetails = async () => {

    let fields = paymentInitialFields;
    // setIsLoading(true);
    const response = await GetPaymentListApi(caseId);

    if (response) {
      setTotalPayment(response[1]);
      setIsCheckboxVisible(response[2]);

      for (let i = 0; i < response[0]?.length; i++) {
        fields = {
          ...fields,
          [response[0][i]?.label_text?.toLowerCase().replaceAll(" ", "_") + "-" + response[0][i]?.id]: response[0][i]?.payment_amount,
          [response[0][i]?.label_text?.toLowerCase().replaceAll(" ", "_") + "-value" + response[0][i]?.id]: response[0][i]?.label_text
        }
      }

      setPaymentInitialFields(fields);
      setPaymentDetails(response[0]);

      if (response[0]?.length > 0) {
        dispatch(setInstallment(true));
      }
      else {
        dispatch(setInstallment(false));
      }
    }

    // setIsLoading(false);


    // setCopyPaymentDetails(response[0]);
  }

  const UpdatePayment = async (name, value, item) => {
    setTouchPaymentIndex(null)
    formik.handleBlur(name)
    console.log("name and id", name, value, item.id);
    const requestBody = {
      id: item.id,
      label_text: formik.values[value],
      payment_amount: formik.values[name]
    }
    if (formik.values[value] && formik.values[name]) {
      console.log("within if", formik.values[value], formik.values[name])
      const response = await UpdatePaymentApi(requestBody, caseId);
      if (response.status_code === 1) {
        setAlertsArray([...alertsArray, { id: `update-payment${alertsArray.length}`, type: 'success', message: response.message }]);
      }
      else {
        setAlertsArray([...alertsArray, { id: `update-payment${alertsArray.length}`, type: 'danger', message: response.message }]);
      }
      setCount(Math.floor(Math.random() * 10));
    }
  }

  const DeletePaymentInstallment = async (item) => {
    setTouchPaymentIndex(null)
    const requestBody = {
      id: item.id,
      payments_id: item.payments_id
    }
    const response = await DeletePaymentInstallmentApi(requestBody, caseId);

    if (response.status_code === 1) {
      setAlertsArray([...alertsArray, { id: `delete-payment${alertsArray.length}`, type: 'success', message: response.message }]);
    }
    else {
      setAlertsArray([...alertsArray, { id: `delete-payment${alertsArray.length}`, type: 'danger', message: response.message }]);
    }
    setCount(Math.floor(Math.random() * 10));
  }

  const OnIsAddMoreClicked = async () => {
    console.log("Is add more clicked");

    if (((paymentFields.installmentAmount == '') && (paymentFields.installmentName == '') && (isAddMoreClicked == true))) {
      setShowErrors({ ...showErrors, showAmountError: true, showNameError: true })
    }

    else if (((paymentFields.installmentAmount == '') && (paymentFields.installmentName != '') && (isAddMoreClicked == true))) {
      setShowErrors({ ...showErrors, showAmountError: true, showNameError: false })

    }
    else if (((paymentFields.installmentAmount != '') && (paymentFields.installmentName == '') && (isAddMoreClicked == true))) {
      setShowErrors({ ...showErrors, showAmountError: false, showNameError: true })
    }
    else if (((paymentFields.installmentAmount != '') && (paymentFields.installmentName != '') && (isAddMoreClicked == true))) {
      setShowErrors({ ...showErrors, showAmountError: false, showNameError: false })

      console.log("point came");
      setIsAddMoreClicked(false);
      // setCount(Math.floor(Math.random() * 100));

    setIsAddMoreClicked(true);
    }
    else {
      console.log("else appears!", paymentFields.installmentAmount, paymentFields.installmentName, isAddMoreClicked);
      setIsAddMoreClicked(true);
      setShowErrors({ ...showErrors, showNameError: false, showAmountError: false })
    }
  }

  const OldCreatePaymentInstallment = async (isSaveClicked) => {
    if (paymentFields.installmentAmount == '' && paymentFields.installmentName == '' && isAddMoreClicked == true) {
      setShowErrors({ ...showErrors, showAmountError: true, showNameError: true })
    }
    else if (paymentFields.installmentAmount == '' && isAddMoreClicked == true) {
      setShowErrors({ ...showErrors, showAmountError: true })
    }
    else if (paymentFields.installmentName == '' && isAddMoreClicked == true) {
      setShowErrors({ ...showErrors, showNameError: true })
    }
    else if (paymentFields.installmentAmount != '' && paymentFields.installmentName != '' && isSaveClicked == true) {
      const requestBody = {
        // payments_id: Number(paymentDetails.am_payment_id),
        payment_amount: Number(paymentFields.installmentAmount),
        label_text: paymentFields.installmentName
      }
      setIsLoading(true);
      const response = await CreatePaymentApi(requestBody, caseId);
      setIsLoading(false);

      if (response.status_code === 1) {
        setAlertsArray([...alertsArray, { id: `create-payment${alertsArray.length}`, type: 'success', message: response.message }]);
      }
      else {
        setAlertsArray([...alertsArray, { id: `create-payment${alertsArray.length}`, type: 'danger', message: response.message }]);
      }
      setIsAddMoreClicked(false);
      setPaymentFields({ ...paymentFields, installmentAmount: '', installmentName: '' })
      setCount(Math.floor(Math.random() * 100));
    }
  }

  const CreatePaymentInstallment = async (isSaveClicked, fieldName) => {
    if (fieldName == 'installmentAmount') {
      if (paymentFields.installmentAmount == '' && isAddMoreClicked == true) {
        setShowErrors({ ...showErrors, showAmountError: false, showNameError: false })
      }
      else if (((paymentFields.installmentAmount != '') && (paymentFields.installmentName == '') && (isSaveClicked == true)) ||
        ((paymentFields.installmentAmount != '') && (paymentFields.installmentName != '') && (isSaveClicked == true)) ||
        ((paymentFields.installmentAmount != '') && (paymentFields.installmentName != '') && (isSaveClicked == true))
      ) {
      }
      else {
        setShowErrors({ ...showErrors, showAmountError: true, showNameError: true })
      }
    }
    else {
      if (paymentFields.installmentName == '' && isAddMoreClicked == true) {
        setShowErrors({ ...showErrors, showNameError: false, showAmountError: false })
      }
      else if (((paymentFields.installmentName != '') && (paymentFields.installmentAmount == '') && (isSaveClicked == true)) ||
        ((paymentFields.installmentName != '') && (paymentFields.installmentAmount != '') && (isSaveClicked == true)) ||
        ((paymentFields.installmentName != '') && (paymentFields.installmentAmount != '') && (isSaveClicked == true))
      ) {
      }
      else {
        setShowErrors({ ...showErrors, showAmountError: true, showNameError: true })

      }
    }
    await CreatePayment();
  }

  const CreatePayment = async () => {
    if (paymentFields.installmentAmount && paymentFields.installmentName) {
      setIsLoading(true);
      const requestBody = {
        payment_amount: Number(paymentFields.installmentAmount),
        label_text: paymentFields.installmentName
      }
      const response = await CreatePaymentApi(requestBody, caseId);
      if (response.status_code === 1) {
        setAlertsArray([...alertsArray, { id: `create-payment${alertsArray.length}`, type: 'success', message: response.message }]);
        setShowErrors({ ...showErrors, showAmountError: false, showNameError: false });
      }
      else {
        setAlertsArray([...alertsArray, { id: `create-payment${alertsArray.length}`, type: 'danger', message: response.message }]);
      }
      setIsAddMoreClicked(false);
      setPaymentFields({ ...paymentFields, installmentAmount: '', installmentName: '' })
      setShowErrors({ ...showErrors, showNameError: false, showAmountError: false })

      setCount(Math.floor(Math.random() * 100));
      setIsLoading(false);
    }
    console.log("create payment");

    // if (addMoreCount > 1 ) {
    //   OnIsAddMoreClicked();
    // }

  }

  const CreatePaymentIsVisible = async (value) => {
    console.log("Craete payment terms", value, "paymentFields.installmentAmount", paymentFields.installmentAmount, "paymentFields.installmentName", paymentFields.installmentName);
    if (((paymentFields.installmentAmount == '') && (paymentFields.installmentName == ''))) {
      setShowErrors({ ...showErrors, showAmountError: true, showNameError: true })
    }
    else if (((paymentFields.installmentAmount != '') && (paymentFields.installmentName == ''))) {
      setShowErrors({ ...showErrors, showAmountError: false, showNameError: true })
    }
    else if (((paymentFields.installmentAmount == '') && (paymentFields.installmentName != ''))) {
      setShowErrors({ ...showErrors, showAmountError: true, showNameError: false })
    }
    else if (((paymentFields.installmentAmount != '') && (paymentFields.installmentName != ''))) {
      setShowErrors({ ...showErrors, showAmountError: false, showNameError: false })
      await CreatePayment();
    }

    if (value == true) {

      console.log("second condition appears");
      setIsLoading(true);
      const response = await CreatePaymentIsVisibleApi(caseId);
      setIsLoading(false);

      if (response.status_code === 1) {
        setAlertsArray([...alertsArray, { id: `is-visible${alertsArray.length}`, type: 'success', message: response.message }]);
      }
      else {
        setAlertsArray([...alertsArray, { id: `is-visible${alertsArray.length}`, type: 'danger', message: response.message }]);
      }
      setCount(Math.floor(Math.random() * 10));
    }

  }

  //Define and initialze formik
  const formik = useFormik({
    initialValues: paymentInitialFields,
    enableReinitialize: true,
    onSubmit: values => {
    },
    // validationSchema: Yup.object().shape(PaymentSchema(paymentDetails))
  })
  //#endregion

  const [touchPaymentIndex, setTouchPaymentIndex] = useState(null);

  //#region JSX
  return (
    <Fragment>
      {(userRoleId == 2 || userRoleId == 3 || userRoleId == 1) &&
        <Row className="mt-3">
          <Col md={12} sm={12}>
            <Card >
              <Card.Body className="payment-details-body">
                <div className="payment-details-text">
                  <div className="payemt-edit-block-title heading-block">
                    {/* <h2 style={{ fontSize: "16px", fontWeight: "600px", color: "#4A5073" }}>Create payment terms:</h2> */}
                    <h3 className="f-18 c-lg ff-nunito f-w-b mb-0" >{userRoleId == 2 ? 'Create ' : ''}Payment Terms</h3>
                  </div>
                  {(paymentDetails?.length > 0) &&

                    <h2 className="f-18 c-lg mt-3 text-center mb-0" >Total Payment : ${totalPayment}</h2>

                  }
                  <div className="payment-edit-details pt-3">
                    {paymentDetails?.length ?
                      <>
                        {
                          paymentDetails?.map((item, index) => {
                            let name = item?.label_text?.toLowerCase().replaceAll(' ', "_") + '-' + item?.id;
                            let value = item?.label_text?.toLowerCase().replaceAll(' ', "_") + "-value" + item?.id
                            return (
                              <div className="installament-details pt-3" key={index}>
                                <Row className="installament-details-block-row">
                                  <div
                                    // className={`${userRoleId == 3 ? 'user-installment-block' : 'installament-block'}`}
                                    className='user-installment-block'
                                  >
                                    <div className="installament-input-block-label">
                                      <Form.Control
                                        id={value}
                                        name={value}
                                        value={formik.values[value]}
                                        disabled={(userRoleId == 2) ? false : true}
                                        onChange={(e) => {
                                          formik.setFieldValue(`${value}`, e.target.value)
                                          setTouchPaymentIndex(index)
                                        }}
                                        onBlur={() => { UpdatePayment(name, value, item) }}
                                        type="text"
                                        placeholder=''
                                        style={{ fontSize: "16px", color: "#4A5073", fontWeight: "400px" }}
                                      />
                                    </div>

                                    <div className={`${userRoleId == 3 ? 'width-only' : 'installament-input-block'}`}>
                                      <Form.Control
                                        id={name}
                                        name={name}
                                        value={formik.values[name]}
                                        disabled={(userRoleId == 2) ? false : true}
                                        onChange={(e) => {
                                          formik.setFieldValue(`${name}`, e.target.value)
                                          setTouchPaymentIndex(index)
                                        }}
                                        onBlur={() => { UpdatePayment(name, value, item) }}
                                        type="text"
                                        placeholder=''
                                        style={{ fontSize: "16px", color: "#4A5073", fontWeight: "400px" }}
                                      />
                                    </div>

                                    {(userRoleId == 2) &&

                                      <div className="delete-button-block">
                                        {/* {touchPaymentIndex == index &&
                                          <Image src={Tick} style={{ color: "#FA5252", fontSize: "25px", cursor: "pointer", paddingRight: '10px' }}
                                            onClick={() => { UpdatePayment(name, value, item) }}
                                          />
                                        } */}
                                        <Image src={Delete} style={{ color: "#FA5252", fontSize: "25px", cursor: "pointer" }}
                                          onClick={() => { DeletePaymentInstallment(item) }}
                                        />
                                        {/* <DeleteIcon onClick={() => DeletePaymentInstallment(item)} className="c-r" /> */}
                                      </div>

                                    }
                                  </div>

                                </Row>
                              </div>
                            )
                          })
                        }
                      </> : (isAddMoreClicked == false) &&
                      <h2 className="no-data-found-block mt-3 text-center" style={{ fontSize: "16px", color: " #4A5073" }}> Installment Not Yet Created </h2>
                    }
                  </div>

                  {isAddMoreClicked &&
                    <div className="installament-details-block-row">
                      <div
                        // className="installament-block add-installament"
                        className='user-installment-block add-installament installament-block'
                      >

                        <div className="installament-input-block-label">
                          <Form.Group >
                            <Form.Label style={{ fontSize: "16px", color: "#4A5073", fontWeight: "400px" }}>Name</Form.Label>
                            <Form.Control
                              style={{ fontSize: "16px", color: "#4A5073", fontWeight: "400px" }}
                              id="installmentName"
                              name="installmentName"
                              type="text"
                              placeholder="Enter installment name"
                              value={paymentFields.installmentName}
                              onChange={(e) => {
                                if (e.target.value == '') { setShowErrors({ ...showErrors, showNameError: true, showAmountError: false }) }
                                else { setShowErrors({ ...showErrors, showNameError: false, showAmountError: false }) }
                                setPaymentFields({ ...paymentFields, installmentName: e.target.value });
                              }}
                              onBlur={() => CreatePaymentInstallment(true, 'installmentName')}
                            />
                            {((isAddMoreClicked == true) && (showErrors.showNameError == true)) &&
                              <p style={{ color: "red" }}>Please enter name</p>
                            }
                          </Form.Group>
                        </div>


                        <div className={`${userRoleId == 3 ? 'width-only' : 'installament-input-block'}`}>
                          <Form.Group >
                            <Form.Label style={{ fontSize: "16px", color: "#4A5073", fontWeight: "400px" }}>Amount</Form.Label>
                            <Form.Control
                              style={{ fontSize: "16px", color: "#4A5073", fontWeight: "400px" }}
                              id="installmentAmount"
                              name="installmentAmount"
                              type="text"
                              placeholder="Amount"
                              value={paymentFields.installmentAmount}
                              onChange={(e) => {
                                if (e.target.value == '') { setShowErrors({ ...showErrors, showAmountError: true, showNameError: false }) }
                                else { setShowErrors({ ...showErrors, showAmountError: false, showNameError: false }) }
                                setPaymentFields({ ...paymentFields, installmentAmount: e.target.value });
                              }}
                              onBlur={() => CreatePaymentInstallment(true, 'installmentAmount')}
                            />
                            {((isAddMoreClicked == true) && (showErrors.showAmountError == true)) &&
                              <p style={{ color: "red" }}>Please enter amount</p>
                            }

                          </Form.Group>
                        </div>

                        <div className="delete-button-block">
                          {/* <div className="add-delete-alignment"> */}
                          {/* <Image src={Tick} style={{ color: "#FA5252", fontSize: "25px", cursor: "pointer", paddingRight: '10px' }}
                            onClick={() => { CreatePaymentInstallment(true) }}
                          /> */}
                          <Image src={Delete} style={{ color: "#FA5252", fontSize: "25px", cursor: "pointer" }}
                            onClick={() => {
                              setIsAddMoreClicked(false); setPaymentFields({ ...paymentFields, installmentName: '', installmentAmount: '' });
                              setShowErrors({ ...showErrors, showAmountError: false, showNameError: false })
                            }}
                          />
                          {/* <CheckCircleRoundedIcon
                            onClick={() => { CreatePaymentInstallment(true) }}
                            className="c-gt" />
                          <DeleteIcon
                            onClick={() => { setIsAddMoreClicked(false); setPaymentFields({ ...paymentFields, installmentName: '', installmentAmount: '' }) }}
                            className="c-r" /> */}
                          {/* </div> */}
                        </div>



                      </div>
                    </div>
                  }
                </div>
                {(paymentDetails?.length > 0) &&
                  <div className="texas-include-fee-text">
                    {/* {(isCheckboxVisible == false || isCheckboxVisible != "") &&
                      <>
                        {userRoleId == 2 &&
                          <>
                            <input type="checkbox" onChange={(e) => CreatePaymentIsVisible(e.target.checked)}></input>
                            <label>Visible to customer</label>
                          </>
                        }

                      </>
                    } */}

                    <p className="f-16 mb-0">Include taxes, service fee</p>
                  </div>
                }
                {userRoleId == 2 &&
                  <>
                  
                   <div className={`${isAddMoreClicked ? 'd-flex ' : 'd-flex justify-content-center'}`}>

                   <Button
                     className="f-16"
                     style={{ backgroundColor: '#0184fe', border: 'none', width: '100%', borderRadius: '25px', cursor: "pointer" }}
                     onClick={async () => {
                       console.log("is add more clicked at main");
                       if (caseStatus == 3) {
                         setshowCaseCompleteInfoModal(true);
                       }
                      
                       setAddMoreCount(addMoreCount => addMoreCount + 1)
                       await OnIsAddMoreClicked();
                       
                     }}
                   >Add Installment{" "}
                     <FontAwesomeIcon icon={faPlus} className="me-2" />
                   </Button>
                   {/* )
                 } */}
                 </div> 
                  
                    {(paymentDetails?.length > 0) &&
                      <div className="payment-share-button">
                        <Button className="bg-c-db f-16 share-btn border-0" onClick={() => CreatePaymentIsVisible(true)}>Share</Button>
                      </div>
                    }
                  </>
                }
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}></Col>
          <Col md={4}></Col>
          {/* Case Completed Warning pop up modal */}
          <CaseCompeteInfoModal showCaseCompleteInfoModal={showCaseCompleteInfoModal} setshowCaseCompleteInfoModal={setshowCaseCompleteInfoModal} />
          {isLoading === true && <DataLoading />}
        </Row>
      }
    </Fragment >
  );
  //#endregion
}
export default EditPayment;
