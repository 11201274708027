import React, { Fragment, useState } from "react";
import { useHistory } from "react-router-dom";
import { Breadcrumb, Dropdown, ButtonGroup, Col, Form, InputGroup, Button, Row } from "@themesberg/react-bootstrap";
import { faHome, faCheck, faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CaseListTable from "../../components/air-migrate/CaseListTable";
import styles from '../../views/cases/Index.module.css';
import CommonBreadcrumb from "../../common-components/CommonBreadcrumb";
import { HomepageUrl } from "../../utilities/helpers/Constant";

const CaseList = () => {
    //#region States and variables
    const history = useHistory();
    const [searchText, setSearchText] = useState('');
    const [userRoleId, setUserRoleId] = useState(localStorage.getItem('roleId'));

    //Breadcrumb state
    const [breadcrumbArray, setBreadcrumbArray] = useState([
        { name: 'Home', url: HomepageUrl },
        { name: 'Cases', url: '' }
    ]);
    //#endregion
    return (
        <Fragment>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-md-0">
                    {/* <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
                        <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
                        <Breadcrumb.Item active>Cases</Breadcrumb.Item>
                    </Breadcrumb> */}

                    <CommonBreadcrumb breadcrumbArray={breadcrumbArray} />
                    {/* <h4>Case list</h4> */}
                </div>
            </div>

            <div className="table-settings mb-4">
                <Row className="justify-content-between align-items-center">
                    <Col xs={12} md={12} lg={4} xl={4} >
                        <div>
                            <h4>Cases</h4>
                        </div>
                    </Col>
                    <Col xs={6} md={6} lg={3} xl={3} >
                        <Form >
                            <Form.Group>
                                {/* <Form.Label>Example select</Form.Label> */}
                                <Form.Select className={[styles.caselistSearchBox, "px-3", "py-0"]}>
                                    <option defaultValue>Open this select menu</option>
                                    <option>Immigration Consultant</option>
                                    <option>Insurance Agent</option>
                                    <option>Broker</option>
                                </Form.Select>
                            </Form.Group>
                        </Form>
                    </Col>
                    <Col xs={6} md={6} lg={5} xl={5} >
                        <InputGroup >
                            <InputGroup.Text className={styles.caselistSearchBox}>
                                <FontAwesomeIcon icon={faSearch} />
                            </InputGroup.Text>
                            <Form.Control type="text" placeholder="Search" value={searchText} onChange={(e) => setSearchText(e.target.value)} className={styles.caselistSearchBox} />
                        </InputGroup>
                    </Col>
                    {/* <Col xs={4} md={2} xl={1} className="ps-md-0 text-end">
                        <Dropdown as={ButtonGroup}>
                            <Dropdown.Menu className="dropdown-menu-xs dropdown-menu-right">
                                <Dropdown.Item className="fw-bold text-dark">Show</Dropdown.Item>
                                <Dropdown.Item className="d-flex fw-bold">
                                    10 <span className="icon icon-small ms-auto"><FontAwesomeIcon icon={faCheck} /></span>
                                </Dropdown.Item>
                                <Dropdown.Item className="fw-bold">20</Dropdown.Item>
                                <Dropdown.Item className="fw-bold">30</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col> */}
                </Row>
            </div>

            <CaseListTable search={searchText} />
        </Fragment>
    )
}
export default CaseList;