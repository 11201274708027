
// common message helper
const messageHelper = (key) => {
    if(!key){
        return ''
    }

    // define your messages here. You can add as many messages as you want.
    const messagesObject = {
        // common messages
        "error" : "Something went wrong, please try again later!",
        
        // chat messages
        "link-expired": "Oops! It seems like the link you clicked on is not valid or has expired!!",
        "mark-as-completed": "Are you sure do you want to Mark As Completed?",
        "login-to-view-and-upload-doc":"Please login to View & Upload your documents",
        "login-to-accept-reject":"Please login to Accept/Reject Request",
        "login-to-view-doc":"Please login view documents",
        "help-text":"Help Text",
        "no-help-text":"No Help Text Found",

        // file upload messages
        "file-type-error":"Invalid file type. Please upload a JPEG, PNG or PDF document",
        "file-size-error":"File size exceeds the limit(10MB). Please upload a smaller file(less than 10MB)",
        
        //my profile page
        "profile-error":"Error fetching profile details.Please try again later!!",
        // case completed message
        "case-completed":"Sorry! case is completed so can't perform acticon.",
        
    }

    return messagesObject[key]
}

export default messageHelper;