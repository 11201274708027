import styles from "./Index.module.css"
import { Fragment, useState, useEffect } from "react";

import { Breadcrumb, Row, InputGroup, Col, Form, Card, Table, Image, Badge, Dropdown, ButtonGroup, Button } from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faSearch } from "@fortawesome/free-solid-svg-icons";

import { useHistory, useLocation } from 'react-router-dom';
import { CapitalizeAllWords } from "../../../../common-components/StringConversions";
import docStyle from '../../../view-all-documents/document-table/Index.module.css';

import { Capitalize } from "../../../../common-components/StringConversions";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import { DataLoading } from "../../../../components/air-migrate/Loader";
import { CreateDocumentRequestApi } from "../../../../actions/CaseActions";
// import { GetClientDocumentsListWithPaginationApi } from "../../../../actions/ClientActions";
import { GetCustomerSentRequestsApi } from "../../../../actions/RequestActions";
import { Toaster } from "react-hot-toast";
import NoDataFound from "../../../../components/air-migrate/NoDataFound";
import CustomPagination from "../../../../common-components/CustomPagination";
import { GetRoleNames } from "../../../../common-components/StringConversions";
import { profilePath } from "../../../../config.tsx";
import CommonServices from "../../../../common-components/CommonServices.jsx";
import Group from '../../../../assets/img/icons/Airmigrate icons/16px/Group.png';
import Accept from '../../../../assets/img/icons/Airmigrate icons/16px/Accept.png';
import Reject from '../../../../assets/img/icons/Airmigrate icons/16px/Reject.png';
import { HomepageUrl } from "../../../../utilities/helpers/Constant.jsx";
import ProfileIconsModal from "../../../../components/air-migrate/ProfileIconsModal.jsx";
import CommonAlert from "../../../../common-components/CommonAlert.jsx";
import CommonBreadcrumb from "../../../../common-components/CommonBreadcrumb.jsx";
const QueryRequests = () => {
    const [searchText, setSearchText] = useState('');
    const location = useLocation();
    const history = useHistory();
    const [isSearchChange, setIsSearchChange] = useState(false);
    const [userRoleId, setUserRoleId] = useState('roleId');
    const HeadingsWitoutAction = ['Document Type', 'Document Title', 'File Size', 'Date']
    const HeadingsWithAction = ['Document Type', 'Document Title', 'File Size', 'Date', 'Action']

    //Common breadcrumb array
    const [breadcrumbArray, setBreadcrumbArray] = useState([
        { name: 'Home', url:HomepageUrl },
        { name: 'Query Requests', url: '' }
    
    ]);

    //States to manage mobile ui
    const [screenSize, setScreenSize] = useState(getCurrentDimension());
    const [singleScreen, setSingleScreen] = useState(false);

    useEffect(() => {
        //functionality to set collapsible table 
        window.addEventListener('resize', updateDimension);
        updateDimension();

        // removing eventlistener for no furhter effect after 
        return (() => {
            window.removeEventListener('resize', updateDimension);
        })
    }, [])

    //#region Functions
    const updateDimension = () => {
        setScreenSize(getCurrentDimension());
        var value = getCurrentDimension();
        // set a variable true when screen width reaches <768px
        if (value.width <= 767) {
            setSingleScreen(true)
        } else {
            setSingleScreen(false)
        }
    }

    function getCurrentDimension() {
        return {
            width: window.innerWidth,
            height: window.innerHeight
        }
    }

    /* Function and const for alert */
    //Alert states
    const [alertsArray, setAlertsArray] = useState([]);
    const [count, setCount] = useState(0);
    const handleAlert = (id) => {
        let array = [];
        for (let i = 0; i < alertsArray.length; i++) {
            if ((alertsArray[i].id) == id) {
                array = alertsArray.pop();

            }
        }
        setCount(Math.floor(Math.random() * 1000))
    }
    //#endregion

    return (
        <Fragment>
            <div className="">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap  pt-0 pb-2">
                    <div className="d-block mb-4 mb-md-0">
                        {/* <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
                            <Breadcrumb.Item className="pe-auto" onClick={() => history.push(HomepageUrl)}><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
                            <Breadcrumb.Item active>Query Requests</Breadcrumb.Item>
                        </Breadcrumb> */}

                        <CommonBreadcrumb breadcrumbArray={breadcrumbArray}/>
                    </div>
                    {/* Received Requests redirect button */}
                    <div className={`${singleScreen === false ? 'mt-5' : ''}`}>
                        <Button variant="primary" className={`mb-md-4 mb-0 text-wrap ${singleScreen ? 'f-18' : ''}`} onClick={() => history.push("/customer/requests/recieved")}> Pending Actions</Button>
                    </div>
                </div>

                <div className="table-settings mb-4">
                    <Row className="justify-content-space-between align-items-center">
                        <Col xs={12} md={12} lg={4} xl={7} className="align-item-end">
                            <h4 className={`${singleScreen ? 'f-18' : ''}`}>Sent Requests</h4>
                        </Col>
                        <Col xs={12} md={12} lg={8} xl={5} className="align-item-end">
                            <InputGroup>
                                <InputGroup.Text>
                                    <FontAwesomeIcon icon={faSearch} />
                                </InputGroup.Text>
                                <Form.Control className={`${singleScreen && 'f-16' + ' '} ${styles.customSearchDocument}  `} type="text" placeholder="Search" value={searchText} onChange={(e) => { setIsSearchChange(true); setSearchText(e.target.value) }} />
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row className="p-0 ">
                    <Col lg={12} sm={12} md={12} xs={12} xl={12}>
                        {(alertsArray.length > 0) &&
                            alertsArray.reverse().map((item, index) => {
                                return (
                                    <CommonAlert key={index} id={item.id} type={item.type} message={item.message} handleAlert={handleAlert} />
                                )
                            })
                        }
                    </Col>
                </Row>
                </div>
                <QueryTable
                    search={searchText}
                    isSearchChange={isSearchChange}
                    singleScreen={singleScreen}
                    alertsArray={alertsArray}
                    setAlertsArray={setAlertsArray} 
                    count={ count}
                    setCount= {setCount}/>
            </div>
        </Fragment>
    )
}
export default QueryRequests;

export const QueryTable = ({ search, isSearchChange, singleScreen,alertsArray,setAlertsArray }) => {
    //#region States and variables
    const history = useHistory();
    const [requestList, setRequestList] = useState([]);
    const [startingIndex, setStartingIndex] = useState(1);
    const [pageLimit, setPageLimit] = useState(10);
    const [isLoading, setIsLoading] = useState();

    //pagination relates states
    const [activeItem, setActiveItem] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalRequestCount, setTotalRequestCount] = useState(0);
    const [count, setCount] = useState(0);
    const [userRoleId, setUserRoleId] = useState(localStorage.getItem('roleId'));
    const [previousActiveItemValue, setPreviousItemActiveValue] = useState(1);

    //#endregion

    //#region useEffect
    useEffect(() => {
        GetSentQueryList();
    }, [search, count, activeItem])

    //#endregion

    //#region style
    const hideChildTable = {
        display: 'none'
    }
    //#endregion


    //#region Functions (all document list table body)
    const handleTableCollapse = (parentId, childId) => {
        const childElement = document.getElementById(childId);
        const parentElement = document.getElementById(parentId);
        // console.log("childElement, parentElement and content", parentId, childId, childElement, parentElement);
        if (parentElement.innerHTML === '+') {
            childElement.style.display = 'table-row';
            parentElement.innerHTML = '-';
        }
        else {
            childElement.style.display = 'none';
            parentElement.innerHTML = '+';
        }
    }

    const GetStatusNames = (status) => {
        switch (status) {
            case 0:
                return "Pending";
            case 1:
                return "Accepted";
            case 2:
                return "Rejected";

            default:
                return "Not available"

        }
    }

    const TableBodyrequestList = (props) => {
        const { case_name, case_status, created_at, email, first_name, last_name, profile_path, role } = props;

        return (
            <tr className={docStyle.requestListRow}>
                <td className="pl-5" >
                    <div className={[docStyle.commonTypographyForRow]}>
                        <p>{Capitalize(case_name)}</p>
                    </div>
                </td>
                <td>
                    <Row className="align-items-center pe-none" >
                        <Col className="col-auto imageLeftLess ">
                            {/* <a href="user-avatar pe-none" >
                                {(profile_path == '' || profile_path == undefined || profile_path == null) ?
                                    <div className={` bg-light rounded-circle d-flex justify-content-center align-items-center img-d-60`} >
                                        {((first_name != null) && (last_name != null)) ?
                                            <p className=" fw-bolder pt-2 f-14 c-lg" >{CommonServices.getNameCharByFullName(first_name + ' ' + last_name)}</p> :
                                            (first_name != null) ?
                                                <p className=" fw-bolder pt-2 f-14 c-lg" >{CommonServices.getNameCharByFullName(first_name + ' ' + last_name)}</p> :
                                                <p className=" fw-bolder pt-2 f-14 c-lg" >U</p>
                                        }
                                    </div>
                                    :
                                    <Image src={profile_path} className="rounded-circle img-d-60" />
                                }
                            </a> */}
                            <ProfileIconsModal userDetails={props} />
                        </Col>
                        <Col className="ms--2">
                            <h4 className="h6 mb-0 f-16 c-lg" >

                                {Capitalize(first_name) + " " + Capitalize(last_name)}
                            </h4>

                            {email && <>
                                <span className="f-16">{email}</span><br />
                            </>}

                            <button
                                className={`pe-none border-0 f-12 text-white rounded-1 bg-c-r `}>
                                {GetRoleNames(role)}</button>
                        </Col>

                    </Row>
                </td>
                <td>
                    <div className={docStyle.commonTypographyForRow}>
                        <p>{created_at?.split(' ')[0]}</p>
                    </div>
                </td>

                <td>
                    <Dropdown as={ButtonGroup}>
                        <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
                            <span className="icon icon-sm">
                                <Image className="pe-auto" src={Group} />
                            </span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-action-show">

                            <Dropdown.Item >
                                {case_status == 1 ?
                                    <Image className="pa-r-5 pe-auto" src={Accept} /> :
                                    (case_status == 2) &&
                                    <Image src={Reject} className="pa-r-5 pe-auto" />
                                }
                                {GetStatusNames(case_status)}
                            </Dropdown.Item>

                        </Dropdown.Menu>
                    </Dropdown>
                </td>
            </tr>
        );
    };

    const MobileTableQueryList = (props) => {
        return (
            <table className="table">
                <thead className="mobile-thead">
                    <tr>
                        <th ></th>
                        <th className="text-wrap f-14">Requested To</th>
                        <th className="mobile-table-action-th f-14" >Action</th>
                    </tr>
                </thead>
                {
                    requestList.map(props => {
                        const { case_id, case_name, case_status, created_at, email, first_name, last_name, profile_path, role } = props;
                        return (
                            <tbody key={`client-${created_at}`}>
                                <tr>
                                    <td className=""><span className="plus-icon" id={`expandChildTable-${case_id}`}
                                        onClick={() => handleTableCollapse(`expandChildTable-${case_id}`, `childTableRow-${case_id}`)}
                                    >+</span></td>
                                    <td>
                                        <Row className="align-items-center pe-none text-wrap img-block" >
                                            <Col className="col-auto imageLeftLess ">
                                                <a className="user-avatar pe-none" >
                                                    {(profile_path == '' || profile_path == undefined || profile_path == null) ?
                                                        <div className={` bg-light rounded-circle d-flex justify-content-center align-items-center img-d-60`} >
                                                            {((first_name != null) && (last_name != null)) ?
                                                                <p className=" fw-bolder pt-2 c-lg f-16" >{CommonServices.getNameCharByFullName(first_name + ' ' + last_name)}</p> :
                                                                (first_name != null) ?
                                                                    <p className=" fw-bolder pt-2 c-lg f-16" >{CommonServices.getNameCharByFullName(first_name + ' ' + last_name)}</p> :
                                                                    <p className=" fw-bolder pt-2 c-lg f-16">U</p>
                                                            }
                                                        </div>
                                                        :
                                                        <Image src={profile_path} className="rounded-circle img-d-60" />
                                                    }
                                                </a>
                                            </Col>
                                            <Col className="ms--2">
                                                <h4 className=" mb-0 f-16 c-lg text-wrap" >
                                                    {first_name !== null && last_name !== null ?
                                                        Capitalize(first_name) + " " + Capitalize(last_name) :
                                                        (first_name !== null) ? Capitalize(first_name) : 'User'
                                                    }
                                                </h4>
                                                <span className="f-16 c-lg">
                                                    {email && <>
                                                        <span className="text-break"
                                                        >{email}</span><br />
                                                    </>}
                                                </span>
                                                <button
                                                    className={`pe-none border-0 f-12 text-white rounded-1  ${(role == 4) ? 'bg-c-r' : (role == 3) ? 'bg-c-db' : (role == 2) ? 'bg-c-g' : ''}`}
                                                >{GetRoleNames(role)}</button>
                                            </Col>
                                        </Row>
                                    </td>
                                    <td className="mobile-table-action-td">

                                        <Dropdown as={ButtonGroup}>
                                            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
                                                <span className="icon icon-sm">
                                                    <Image className="pe-none" src={Group} />
                                                </span>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className="dropdown-action-show">
                                                <Dropdown.Item className="f-16">
                                                    {case_status == 1 ?
                                                        <Image className="pe-auto pa-r-5" src={Accept} /> :
                                                        (case_status == 2) &&
                                                        <Image className="pe-auto pa-r-5" src={Reject} />
                                                    }
                                                    {GetStatusNames(case_status)}
                                                </Dropdown.Item>

                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </td>
                                </tr>
                                <tr id={`childTableRow-${case_id}`} style={hideChildTable}>
                                    <td colSpan="3">
                                        <div className="text-wrap ">
                                            <h6 className="f-16 c-dg">Title : {" "}
                                                <span className="c-lg f-16 fw-normal">{((case_name == null) || (case_name == '')) ? 'Not available' : Capitalize(case_name)}</span>
                                            </h6>
                                        </div>
                                        <div className="text-wrap ">
                                            <h6 className="f-16 c-dg">Requested Date : {" "}
                                                <span className="f-16 c-lg fw-normal">{created_at.includes(' ') === true ? created_at.split(' ')[0] : created_at.split('T')[0]}</span></h6>

                                        </div>
                                    </td>
                                </tr>

                            </tbody>
                        )
                    }
                    )
                }

            </table>
        )
    }

    const CreateDocumentRequest = async (id) => {
        const requestBody = {
            documents_id: id
        }
        const response = await CreateDocumentRequestApi(requestBody);
        console.log('request response', response);
        setCount(Math.floor(Math.random() * 100));
    }

    const GetSentQueryList = async () => {
        if (((search == '') && (isSearchChange == false)) || (activeItem != previousActiveItemValue)) {
            setIsLoading(true);
        }

        var requestList;
        requestList = await GetCustomerSentRequestsApi(startingIndex, pageLimit, search);

        // console.log("documentsList", requestList, " count", requestList[0]?.count, " pageLimit", pageLimit, "documentsList", requestList);
        setRequestList(requestList[0]?.results);

        setTotalRequestCount(requestList[0]?.count);
        const pages = Math.ceil(requestList[0]?.count / pageLimit);
        // console.log(' total pages', pages);
        setTotalPages(pages);

        setIsLoading(false);
    }
    //#endregion

    //#region JSX
    return (
        <Fragment>
            {isLoading === true ? <DataLoading /> :
                ((requestList?.length == null) || (requestList?.length == undefined) || (requestList?.length == 0)) ?
                    <NoDataFound /> :
                    <>
                        {singleScreen === false ?
                            <Card border="light" className="shadow-sm mb-4 ">
                                <Card.Body className="pb-0">
                                    <Table responsive className="table-centered custom-table-responsive table-nowrap rounded mb-0">
                                        <thead className="thead-light border-bottom custom-table-heading">
                                            <tr>
                                                <th className="border-0  c-lg f-14" >Title</th>
                                                <th className="border-0  c-lg f-14" >Requested To</th>
                                                <th className="border-0  c-lg f-14" >Requested Date</th>
                                                <th className="border-0  c-lg f-14" >Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {requestList.map(item => (
                                                <TableBodyrequestList key={item.id} {...item} />
                                            ))
                                            }
                                        </tbody>
                                    </Table>

                                    <div className="pagenation-custom-block">
                                        <div className="pagination-msg">
                                            {`Showing ${startingIndex} to ${Math.min(
                                                startingIndex + pageLimit - 1,
                                                totalRequestCount
                                            )} of ${totalRequestCount} entries`}
                                        </div>
                                        <CustomPagination isSingleScreen={false} activeItem={activeItem} setActiveItem={setActiveItem} totalPages={totalPages} setStartingIndex={setStartingIndex} />
                                    </div>


                                </Card.Body>

                                {/* <CustomPagination activeItem={activeItem} setActiveItem={setActiveItem} totalPages={totalPages} setStartingIndex={setStartingIndex} /> */}
                            </Card> :

                            <div className="bg-white"> <MobileTableQueryList />
                                <div className="pagenation-custom-block pagination-padding">
                                    <div className="pagination-msg f-16">
                                        {`Showing ${startingIndex} to ${Math.min(
                                            startingIndex + pageLimit - 1,
                                            totalRequestCount
                                        )} of ${totalRequestCount} entries`}
                                    </div>
                                    <CustomPagination isSingleScreen={true} activeItem={activeItem} setActiveItem={setActiveItem} totalPages={totalPages} setStartingIndex={setStartingIndex} />
                                </div>

                            </div>
                        }
                    </>
            }
            {/* <Toaster position="top-right" reverseOrder={false} /> */}
        </Fragment>
    )
    //#endregion


}