import React, { Fragment, useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import QuestionAnswerRoundedIcon from '@mui/icons-material/QuestionAnswerRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import CalendarTodayRoundedIcon from '@mui/icons-material/CalendarTodayRounded';
import PictureAsPdfRoundedIcon from '@mui/icons-material/PictureAsPdfRounded';
import { Col, Row, Card, Image, OverlayTrigger, Tooltip, Table, Badge } from '@themesberg/react-bootstrap';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-hot-toast';

//Local imports, core styles, vendor styles
import { GetClientListApi } from "../../actions/ClientActions";
import { DataLoading } from "./Loader";
import { Capitalize } from '../../common-components/StringConversions';
import NoDataFound from '../../components/air-migrate/NoDataFound';
import CustomPagination from "../../common-components/CustomPagination";

import styles from '../../views/requests/Index.module.css';
import '../../assets/global-css/Index.css';
import "../../scss/volt.scss";
import "react-datetime/css/react-datetime.css";
import { PROFILE_PATH } from "../../config.tsx";
import PictureAsPdfRounded from "@mui/icons-material/PictureAsPdfRounded";
import PdfIcon from "../../assets/img/allDocumentList/pdfIcon.svg";
import docStyle from "../../views/view-all-documents/Index.module.css"

const RequestListTable = ({ search, tableHeadings, dataToShow }) => {
    // console.log("tableHeadings", tableHeadings)
    // console.log("dataToShow", dataToShow)


    //#region States and variables
    const history = useHistory();
    const [clientList, setClientList] = useState([]);
    const [documentList, setDocumentList] = useState([]);
    const [copiedClientList, setCopiedClientList] = useState([]);
    const [startingIndex, setStartingIndex] = useState(1);
    const [pageLimit, setPageLimit] = useState(10);
    const [isLoading, setIsLoading] = useState();
    const [tableBody, setTableBody] = useState('')
    //pagination relates states
    const [activeItem, setActiveItem] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalClientCount, setTotalClientCount] = useState(0);
    const [count, setCount] = useState(0);
    const [consultantName, setConsultantName] = useState(Capitalize(localStorage.getItem('userName')));

    // Login user uuid for chat link
    const [loginUserId, setLoginUserId] = useState('')
    //#endregion

    //#region Styles
    const filterStyle = {
        marginRight: '2px',
        marginLeft: '3px',
        // color: '#0184fe'
    }
    //#endregion

    //#region useEffect
    useEffect(() => {
        // console.log("useEffect r")
        setTableBody(dataToShow)
        let amUserId = localStorage.getItem('amUserId')
        if (amUserId) {
            setLoginUserId(amUserId)
        }
        if (dataToShow === 'requestList') {
            GetClientList();
        }
        if (dataToShow === 'DocumentList') {
            // GetDocumentList();
            let dummyArray = [1, 2, 3, 4]
            setDocumentList(dummyArray)
            // console.log("documentList", documentList)
        }
    }, [search, activeItem, dataToShow])
    //#region Functions
    const TableRow = (props) => {
        const { consultant_uuid, chat_link_verify_status, created_at, email, profile_path,
            email_verified, first_name, last_name, phone_is_verify, primary_phone, user_uuid, case_keyword } = props;

        // const PROFILE_PATH = 'https://air-migrate-public-bucket.s3.ca-central-1.amazonaws.com/default-broker-image.png';
        return (
            <tr>
                <td><div className={styles.casesprofilecontentblock}>
                    {/* <div className={styles.tablecasesprofileimg}> */}
                    <div>
                        {/* <a href="#top" className="user-avatar"> */}
                        <PictureAsPdfRoundedIcon />
                        {/* </a> */}
                    </div>

                    {/* </div> */}
                    <div className={styles.tablecasesprofilename}><a href="">3 Month Salary slip</a>
                        <p>200 KB</p></div>
                </div></td>
                <td>
                    <div className={styles.casesprofilecontentblock}>
                        <p>3 Month Salary Slip</p>
                    </div>


                </td>
                <td>
                    <div className={styles.casesprofilecontentblock}>
                        <div >
                            <a href="#top" className="user-avatar">
                                <Image src={PROFILE_PATH} className="rounded-circle" />
                            </a>
                        </div>
                        <div className={styles.tablecasesprofilename}>
                            <a href="">Yogesh Harle</a>
                            <Badge bg="warning" text="dark" className="me-1 request-profile-btn">Broker</Badge>
                        </div>
                    </div>

                </td>
                <td>
                    <div className={styles.casesprofilecontentblock}>
                        <div >
                            <a href="#top" className="user-avatar">
                                <Image src={PROFILE_PATH} className="rounded-circle" />
                            </a>
                        </div>
                        <div className={styles.tablecasesprofilename}><a href="">Manisha</a></div>
                    </div>
                </td>
                <td>
                    <div className={styles.casesprofilecontentblock}>
                        01.02.2024
                    </div>
                </td>
                <td>
                    <div className={styles.actionbuttongroup}>
                        <div className={styles.chatbtn}><button>Chat</button></div>
                        <div className={styles.viewbtn}
                        // onClick={() => { history.push('/case-details') }}
                        ><button>Upload</button></div>
                    </div>
                </td>

            </tr>
        );
    };

    //#region Functions (all document list table body)
    const TableBodyDocumentList = (props) => {
        const { consultant_uuid, chat_link_verify_status, created_at, email, profile_path,
            email_verified, first_name, last_name, phone_is_verify, primary_phone, user_uuid, case_keyword } = props;

        // const PROFILE_PATH = 'https://air-migrate-public-bucket.s3.ca-central-1.amazonaws.com/default-broker-image.png';
        return (
            <tr className={docStyle.documentListRow}>
                <td style={{paddingLeft:10}}>
                    <div className={styles.casesprofilecontentblock} >
                        <div>
                            {/* <PictureAsPdfRoundedIcon /> */}
                            <img src={PdfIcon} alt="pdfLogo" height={45} width={45} style={{minWidth: 45, minHeight: 45 ,marginRight:5}}/>
                        </div>
                        <div className={[docStyle.commonTypographyForRow]}>
                            <p>3 Month Salary slip</p>
                            <small style={{ color: '#B9B9B9' }}>200 KB</small></div>
                    </div></td>
                <td>
                    <div className={[docStyle.commonTypographyForRow]}>
                        <p>3 Month Salary Slip</p>
                    </div>
                </td>
                <td>
                    <div className={docStyle.commonTypographyForRow}>
                        <div >
                            <p>200  KB</p>
                        </div>
                    </div>

                </td>
                <td>
                    <div className={docStyle.commonTypographyForRow}>
                       <p> 01.02.2024</p>
                    </div>
                </td>
                <td>
                    <div className={styles.actionbuttongroup}>
                        <div className={styles.chatbtn} style={{fontSize:16}}><button>Request Access</button></div>
                    </div>
                </td>

            </tr>
        );
    };

    const GetClientList = async () => {
        setIsLoading(true);
        const client = await GetClientListApi(startingIndex, pageLimit, search);
        setIsLoading(false);

        if (client.status_code === 1) {
            setClientList(client.data[0]?.results);
            setCopiedClientList(client.data[0]?.results);
            setTotalClientCount(client.data[0]?.count);
            const pages = Math.ceil(client.data[0]?.count / pageLimit);
            console.log(' total pages', pages);
            setTotalPages(pages);

        } else {
            toast.error(client.message, { duration: 4000, },);
        }
        console.log("client list", client);

    }

    const SortingAsc = (data, key) => {
        console.log("data and key", data, key);
        let sortedData;

        if (key == 'first_name') {
            sortedData = data.sort(function (a, b) {
                let x = a.first_name?.toLowerCase();
                let y = b.first_name?.toLowerCase();

                //compare the word which is comes first
                if (x > y) { return 1; }
                if (x < y) { return -1; }
                return 0;
            });

        }
        else if (key == 'case_keyword') {
            sortedData = data.sort(function (a, b) {
                let x = a.case_keyword?.toLowerCase();
                let y = b.case_keyword?.toLowerCase();

                //compare the word which is comes first
                if (x > y) { return 1; }
                if (x < y) { return -1; }
                return 0;
            });
        }
        else if (key == 'created_at') {
            sortedData = data.sort(function (a, b) {
                var keyA = new Date(a.created_at),
                    keyB = new Date(b.created_at);
                // Compare the 2 dates
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
            });

        }
        console.log("sorted data", sortedData);
        setCopiedClientList(sortedData);
        setCount(count => count + 1);
    }

    const SortingDes = (data, key) => {
        console.log("data and key", data, key);
        let sortedData;

        if (key == 'first_name') {
            sortedData = data.sort(function (a, b) {
                let x = a.first_name?.toLowerCase();
                let y = b.first_name?.toLowerCase();

                //compare the word which is comes first
                if (x < y) { return 1; }
                if (x > y) { return -1; }
                return 0;
            });

        }
        else if (key == 'case_keyword') {
            sortedData = data.sort(function (a, b) {
                let x = a.case_keyword?.toLowerCase();
                let y = b.case_keyword?.toLowerCase();

                //compare the word which is comes first
                if (x < y) { return 1; }
                if (x > y) { return -1; }
                return 0;
            });
        }
        else if (key == 'created_at') {
            sortedData = data.sort(function (a, b) {
                var keyA = new Date(a.created_at),
                    keyB = new Date(b.created_at);
                // Compare the 2 dates
                if (keyA > keyB) return -1;
                if (keyA < keyB) return 1;
                return 0;
            });

        }
        console.log("sorted data", sortedData);
        setCopiedClientList(sortedData);
        setCount(count => count + 1);
    }

    const DummyContentRow = async () => {
        return (
            <tr>
                <td>Permanent Residency Case</td>
                <td>
                    <div className={styles.casesprofilecontentblock}>
                        {/* <div className={styles.tablecasesprofileimg}> */}
                        <div>
                            <a href="#top" className="user-avatar">
                                <Image src={PROFILE_PATH} className="rounded-circle" />
                            </a>
                        </div>

                        {/* </div> */}
                        <div className={styles.tablecasesprofilename}><a href="">Akash Gour</a></div>
                    </div>
                    <div className={styles.casesprofilecontentblock}>
                        <div >
                            <a href="#top" className="user-avatar">
                                <Image src={PROFILE_PATH} className="rounded-circle" />
                            </a>
                        </div>
                        <div className={styles.tablecasesprofilename}><a href="">Manisha</a></div>
                    </div>
                </td>
                <td>
                    <div className={styles.casesprofilecontentblock}>
                        <div >
                            <a href="#top" className="user-avatar">
                                <Image src={PROFILE_PATH} className="rounded-circle" />
                            </a>
                        </div>
                        <div className={styles.tablecasesprofilename}><a href="">Yogesh Harle</a></div>
                    </div>
                    <div className={styles.casesprofilecontentblock}>
                        <div >
                            <a href="#top" className="user-avatar">
                                <Image src={PROFILE_PATH} className="rounded-circle" />
                            </a>
                        </div>
                        <div className={styles.tablecasesprofilename}><a href="">Muskan</a></div>
                    </div>
                    <div className={styles.casesprofilecontentblock}>
                        <div >
                            <a href="#top" className="user-avatar">
                                <Image src={PROFILE_PATH} className="rounded-circle" />
                            </a>
                        </div>
                        <div className={styles.tablecasesprofilename}><a href="">Nikita Rathore</a></div>
                    </div>
                    <div className={styles.casesprofilecontentblock}>
                        <div >
                            <a href="#top" className="user-avatar">
                                <Image src={PROFILE_PATH} className="rounded-circle" />
                            </a>
                        </div>
                        <div className={styles.tablecasesprofilename}><a href="">Urvashi</a></div>
                    </div>
                </td>
                <td>
                    <div className={styles.statusbutton}>
                        <button>In Progress</button>
                    </div>
                </td>
                <td>
                    <div className={styles.actionbuttongroup}>
                        <div className={styles.chatbtn}><button>Chat</button></div>
                        <div className={styles.viewbtn}><button>View Details</button></div>
                    </div>
                </td>


            </tr>
        )
    }
    //#endregion

    //#region JSX
    return (
        <Fragment>
            {isLoading === true ? <DataLoading /> :
                (((documentList.length || clientList.length) == 0) || ((documentList.length || clientList.length) == undefined) || ((documentList.length || clientList.length) == null)) ?
                    <NoDataFound /> :
                    <Card border="light" className="shadow-sm mb-4 ">
                        <Card.Body className="pb-0">
                            <Table responsive className="table-centered custom-table-responsive table-nowrap rounded mb-0">
                                <thead className="thead-light border-bottom custom-table-heading">
                                    <tr>
                                        {tableHeadings.map((heading, index) => {
                                            return <th className="border-0 custom-heads" style={{ color: "#4A5073" }}>{heading}
                                            </th>
                                        })}
                                        {/* <th className="border-0" style={{ color: "#262b40" }}>Document List
                                        </th>
            
                                        <th className="border-0" style={{ color: "#262b40" }}>Document Title
                                        </th>
                                        <th className="border-0" style={{ color: "#262b40" }}>Requested By

                                        </th>
                                        <th className="border-0" style={{ color: "#262b40" }}>Requested To
                                        </th>
                                        <th className="border-0" style={{ color: "#262b40" }}>Requested Date</th>
                                        <th className="border-0" style={{ color: "#262b40" }}>Actions</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {tableBody === 'requestList' ? (
                                        copiedClientList.map(client => (
                                            <TableRow key={`client-${client.consultant_uuid}`} {...client} />
                                        ))
                                    ) : (
                                        <TableBodyDocumentList />
                                    )}
                                    {/* <tr>
                                        <td>Permanent Residency Case</td>
                                        <td>
                                            <div className={styles.casesprofilecontentblock}>
                                                
                                                <div>
                                                    <a href="#top" className="user-avatar">
                                                        <Image src={PROFILE_PATH} className="rounded-circle" />
                                                    </a>
                                                </div>

                                         
                                                <div className={styles.tablecasesprofilename}><a href="">Akash Gour</a></div>
                                            </div>
                                            <div className={styles.casesprofilecontentblock}>
                                                <div >
                                                    <a href="#top" className="user-avatar">
                                                        <Image src={PROFILE_PATH} className="rounded-circle" />
                                                    </a>
                                                </div>
                                                <div className={styles.tablecasesprofilename}><a href="">Manisha</a></div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className={styles.casesprofilecontentblock}>
                                                <div >
                                                    <a href="#top" className="user-avatar">
                                                        <Image src={PROFILE_PATH} className="rounded-circle" />
                                                    </a>
                                                </div>
                                                <div className={styles.tablecasesprofilename}><a href="">Yogesh Harle</a></div>
                                            </div>
                                            <div className={styles.casesprofilecontentblock}>
                                                <div >
                                                    <a href="#top" className="user-avatar">
                                                        <Image src={PROFILE_PATH} className="rounded-circle" />
                                                    </a>
                                                </div>
                                                <div className={styles.tablecasesprofilename}><a href="">Muskan</a></div>
                                            </div>
                                            <div className={styles.casesprofilecontentblock}>
                                                <div >
                                                    <a href="#top" className="user-avatar">
                                                        <Image src={PROFILE_PATH} className="rounded-circle" />
                                                    </a>
                                                </div>
                                                <div className={styles.tablecasesprofilename}><a href="">Nikita Rathore</a></div>
                                            </div>
                                            <div className={styles.casesprofilecontentblock}>
                                                <div >
                                                    <a href="#top" className="user-avatar">
                                                        <Image src={PROFILE_PATH} className="rounded-circle" />
                                                    </a>
                                                </div>
                                                <div className={styles.tablecasesprofilename}><a href="">Urvashi</a></div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className={styles.statusbutton}>
                                                <button>In Progress</button>
                                            </div>
                                        </td>
                                        <td>
                                            <div className={styles.actionbuttongroup}>
                                                <div className={styles.chatbtn}><button>Chat</button></div>
                                                <div className={styles.viewbtn}><button>View Details</button></div>
                                            </div>
                                        </td>


                                    </tr> */}
                                </tbody>
                            </Table>
                            <CustomPagination activeItem={activeItem} setActiveItem={setActiveItem} totalPages={totalPages} setStartingIndex={setStartingIndex} />
                        </Card.Body>
                    </Card>
            }
        </Fragment>
    )
    //#endregion

}
export default RequestListTable;

