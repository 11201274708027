import React, { useEffect, useState } from 'react';
import { Nav, Tab } from '@themesberg/react-bootstrap';
import { Col, Row, Form } from '@themesberg/react-bootstrap';

//Local imports
import { CapitalizeAllWords } from '../../common-components/StringConversions';

const SelectedTemplateView = ({ selectedId, templateDetails }) => {

    //#region State and Variables
    const [isCheckBoxSelected, setIsCheckBoxSelected] = useState(false);
    const [templateObject, setTemplateObject] = useState({});
    const [applicantsDoc, setApplicantsDoc] = useState([]);
    const [sponsorsDoc, setSponsorsDoc] = useState([]);
    //#endregion

    //#region useEffect
    useEffect(() => {
        getTemplateDetails();
    }, [selectedId])
    //#endregion

    //#region Functions
    const getTemplateDetails = () => {

        console.log("selectedId, templateDetails", selectedId, templateDetails);
        //get selected template details
        for (let i = 0; i < templateDetails.length; i++) {
            if (templateDetails[i].id == selectedId) {
                setTemplateObject(templateDetails[i]);
                console.log('templateObject', templateDetails[i]);

                //get applicants doc
                let applicants = templateDetails[i].applicants_doc
                console.log('applicants doc', applicants, typeof (applicants),
                   typeof (eval(applicants))
                );
                setApplicantsDoc(eval(applicants));

                //get sponsors doc
                let sponsors = templateDetails[i].sponser_doc
                console.log('sponsors doc', sponsors, typeof (sponsors),
                    typeof (eval(sponsors))
                );
                setSponsorsDoc(eval(sponsors));
            }
        }

    }
    //#endregion

    //#region JSX
    return (
        <>
            <Row className="mt-5">
                <Col md={12} className="mb-3 p-0" >
                    <Form.Group >
                        <Form.Label>Template Name</Form.Label>
                        <Form.Control
                            id="templateName"
                            name="templateName"
                            type="text"
                            placeholder="Enter template name"
                            disabled={true}
                            value={templateObject.name}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col md={12} className="mb-3 p-0" >
                    <Form.Group id="description">
                        <Form.Label>Template Description</Form.Label>
                        <Form.Control
                            id="description"
                            name="description"
                            as="textarea"
                            rows="3"
                            type="text"
                            placeholder="Enter template description"
                            disabled={true}
                            value={templateObject.descripiton}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col md={12} className="select-documents-tab-block p-0" >
                    <input
                        className="p-0"
                        type="checkbox"
                        id="repeatSetofDocumentsForSponsors"
                        name="repeatSetofDocumentsForSponsors"
                        value={templateObject.repeat_docs
                        }
                        disabled={true}

                    />
                    <label for="repeatSetofDocumentsForSponsors" style={{ marginLeft: '10px' }}>   Repeat same set for sponsors</label><br></br>


                    {/* Tabs Section*/}
                    <h5 className="my-4">Select Documents</h5>
                    <Tab.Container defaultActiveKey="applicants">
                        <Nav fill variant={`pills`} className="flex-column flex-sm-row m-0">
                            <Nav.Item>
                                <Nav.Link eventKey="applicants" className="mb-sm-3 mb-md-0 pt-2 pb-3">
                                    Applicant's
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="sponsors" className="mb-sm-3 mb-md-0 pt-2 pb-3">
                                    Sponsor's
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <Tab.Content>
                            <Tab.Pane eventKey="applicants" className="">
                                {applicantsDoc.map((field, index) => {

                                    return (
                                        <Row key={index}>
                                            <Col md={12} >
                                                <Form.Group as={Col} className="mt-2">
                                                    <Form.Label>{CapitalizeAllWords(field.label)}</Form.Label>
                                                    <Form.Control
                                                        id={`${field.key}-for-applicants`}
                                                        name={`${field.key}-for-applicants`}
                                                        type="text"
                                                        placeholder="Enter template name"
                                                        disabled={true}
                                                        value={field.option[0].key}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    )
                                })}
                            </Tab.Pane>
                            <Tab.Pane eventKey="sponsors" className="">
                                {isCheckBoxSelected === false &&
                                    <>
                                        {sponsorsDoc.map((field, index) => {
                                            return (
                                                <Row key={index}>
                                                    <Col md={12} >
                                                        <Form.Group as={Col} className="mt-2">
                                                            <Form.Label>{CapitalizeAllWords(field.label)}</Form.Label>
                                                            <Form.Control
                                                                id={`${field.key}-for-sponsors`}
                                                                name={`${field.key}-for-sponsors`}
                                                                type="text"
                                                                placeholder="Enter template name"
                                                                disabled={true}
                                                                value={field.option[0].key}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            )
                                        })}
                                    </>
                                }

                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </Col>
            </Row>
            <Row>
                <Col md={12} className="p-0">
                    <Form.Group className="mb-3 mt-2">
                        <Form.Label>Document not in list ? No problem, add them here comma separated</Form.Label>
                        <Form.Control
                            name="otherDocuments"
                            id="otherDocuments"
                            as="textarea"
                            rows="3"
                            type="text"
                            placeholder="Aadhar card, PAN card"
                            value={templateObject.other}
                            disabled={true}
                        />
                    </Form.Group>
                </Col>
            </Row>
        </>
    )
    //#endregion
}
export default SelectedTemplateView;