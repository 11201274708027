import React, { Fragment, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Toaster } from "react-hot-toast";
import {
  Col,
  Row,
  Card,
  Image,
  OverlayTrigger,
  Tooltip,
  Table,
  Dropdown
} from "@themesberg/react-bootstrap";
import { useHistory } from "react-router-dom";
import { toast } from "react-hot-toast";
import {
  Breadcrumb,
  Form,
  InputGroup,
  ButtonGroup,
  Button,
} from "@themesberg/react-bootstrap";
import { faHome, faSearch, faPlus } from "@fortawesome/free-solid-svg-icons";
import styles from "../../../views/agents/agent-list/Index.module.css";
//Local imports, core styles, vendor styles
import {
  GetAgentRequestsApi,
  CreateAgentRequestApi,
} from "../../../actions/AgentAction.jsx";
import { DataLoading } from "../../../components/air-migrate/Loader.jsx";
import {
  Capitalize,
  CapitalizeAllWords,
} from "../../../common-components/StringConversions";
import NoDataFound from "../../../components/air-migrate/NoDataFound";
import CustomPagination from "../../../common-components/CustomPagination";
import "../../../scss/volt.scss";
import "react-datetime/css/react-datetime.css";
import { profilePath } from "../../../config.tsx";

import Group from '../../../assets/img/icons/Airmigrate icons/16px/Group.png';
import Tick from '../../../assets/img/icons/Airmigrate icons/16px/Tick.png';
import Reject from '../../../assets/img/icons/Airmigrate icons/16px/Reject.png';
import CommonAlert from "../../../common-components/CommonAlert.jsx";
import { HomepageUrl } from "../../../utilities/helpers/Constant.jsx";
import CommonBreadcrumb from "../../../common-components/CommonBreadcrumb.jsx";

const AgentRequestList = ({ search, isSearchChange }) => {
  //#region States and variables
  const history = useHistory();
  const [agentList, setAgentList] = useState([]);
  const [copiedClientList, setCopiedClientList] = useState([]);
  const [startingIndex, setStartingIndex] = useState(1);
  const [role, setRole] = useState("");
  const [pageLimit, setPageLimit] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [searchText, setSearchText] = useState("");

  //pagination relates states
  const [activeItem, setActiveItem] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalClientCount, setTotalClientCount] = useState(0);
  const [count, setCount] = useState(0);
  const [previousActiveItemValue, setPreviousItemActiveValue] = useState(1);
  const [consultantName, setConsultantName] = useState(
    Capitalize(localStorage.getItem("userName"))
  );
  const [selectedRole, setSelectedRole] = useState("All");
  // Login user uuid for chat link
  const [loginUserId, setLoginUserId] = useState("");

  //States to manage mobile ui
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const [singleScreen, setSingleScreen] = useState(false);

   //Breadcrumb state
   const [breadcrumbArray, setBreadcrumbArray] = useState([
    { name: 'Home', url: HomepageUrl },
    { name: 'Agent Requests', url: '' }
  ]);

  //#endregion

  //#region Styles
  const filterStyle = {
    marginRight: "2px",
    marginLeft: "3px",
  };

  /* Function and const for alert */
  //Alert states
  const [alertsArray, setAlertsArray] = useState([]);
  // const [count, setCount] = useState(0);
  const handleAlert = (id) => {
    let array = [];
    for (let i = 0; i < alertsArray.length; i++) {
      if ((alertsArray[i].id) == id) {
        array = alertsArray.pop();

      }
    }
    setCount(Math.floor(Math.random() * 1000))
  }
  //#endregion

  //#region useEffect

  useEffect(() => {
    let amUserId = localStorage.getItem("amUserId");
    if (amUserId) {
      setLoginUserId(amUserId);
    }
    GetAgentRequests();

    //functionality to set collapsible table 
    window.addEventListener('resize', updateDimension);
    updateDimension();

    // removing eventlistener for no furhter effect after 
    return (() => {
      window.removeEventListener('resize', updateDimension);
    })
  }, [searchText, activeItem, selectedRole,count]);
  //#endregion

  const hideChildTable = {
    display: 'none'
  }



  const updateDimension = () => {
    setScreenSize(getCurrentDimension());
    var value = getCurrentDimension();
    // set a variable true when screen width reaches <768px
    if (value.width <= 767) {
      setSingleScreen(true)
    } else {
      setSingleScreen(false)
    }
  }

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight
    }
  }

  const handleTableCollapse = (parentId, childId) => {
    const childElement = document.getElementById(childId);
    const parentElement = document.getElementById(parentId);
    console.log("childElement, parentElement and content", parentId, childId, childElement, parentElement);
    if (parentElement.innerHTML === '+') {
      childElement.style.display = 'table-row';
      parentElement.innerHTML = '-';
    }
    else {
      childElement.style.display = 'none';
      parentElement.innerHTML = '+';
    }
  }

  const getLicenseNumber = (metaValue) => {
    if (metaValue === null) {
      return "";
    }

    const rcicNumberMeta = metaValue.find((meta) => meta.key === "rcic_number");
    const estateLicenseNumberMeta = metaValue.find(
      (meta) => meta.key === "estate_license_number"
    );

    if (rcicNumberMeta) {
      return rcicNumberMeta.value;
    } else if (estateLicenseNumberMeta) {
      return estateLicenseNumberMeta.value;
    } else {
      return "";
    }
  };

  const TableRow = ({
    first_name,
    created_at,
    meta_value,
    last_name,
    licence_number,
    role,
    email,
    primary_phone,
    primary_country_code,
    user_uuid,
    key,
  }) => {
    const getYearFromTimestamp = (timestamp) => {
      return timestamp.split(" ")[0];
    };
    return (
      <tr>
        <td>
          <div className="agent-name-td agent-td">
            <div className="case-content-block">
              <h4 className="case-name-content" style={{ color: "#4A5073" }}>
                {first_name !== null && last_name !== null ?
                  `${Capitalize(first_name + " " + last_name)}` :
                  (first_name != null) ?
                    `${Capitalize(first_name)}` :
                    'User'
                }
              </h4>
            </div>
          </div>
        </td>
        <td>
          <div className="case-name-content">
              {role == 4 ? "Broker" : role == 2 ? "Immigration consultant" : ""}
            {/* <button
              style={{
                backgroundColor:
                  role === 4 ? "#FFB136" : role === 2 ? "#32CD32" : "#FFB136",
              }}
            >
             
            </button> */}
          </div>
        </td>
        <td>
          <div className="case-name-content">
            {getLicenseNumber(meta_value, role) || ""}
          </div>
        </td>
        <td>
          <div className="case-name-content">
            {getYearFromTimestamp(created_at) || ""}
          </div>
        </td>
        <td>
          <div className="case-name-content">{primary_phone}</div>
        </td>
        <td>
          <div className="case-name-content">
            {email}
          </div>
        </td>
        <td>
          {/* <div className={styles.actionbuttongroup}>
            <div className={styles.chatbtn}>
              <button onClick={() => handleAcceptRejectRequest(user_uuid, 1)}>
                Accept Request
              </button>
            </div>
            <div className={styles.viewbtn}>
              <button onClick={() => handleAcceptRejectRequest(user_uuid, 2)}>
                Reject Request
              </button>
            </div>
          </div> */}

          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <span className="icon icon-sm">

                <Image src={Group} style={{ cursor: "pointer" }} />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-action-show">

              <Dropdown.Item
                onClick={() => handleAcceptRejectRequest(user_uuid, 1)}
              >
                <Image src={Tick} style={{ cursor: "pointer" }} /> Accept
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => handleAcceptRejectRequest(user_uuid, 2)}
              >

                <Image src={Reject} style={{ cursor: "pointer" }} /> Reject
              </Dropdown.Item>



            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
    );
  };

  const MobileAgentRequestList = () => {

    return (
      <table className="table">
        <thead className="mobile-thead">
          <tr>
            <th ></th>
            <th className="f-14">Name</th>
            <th className="mobile-table-action-th f-14" >Action</th>
          </tr>
        </thead>
        {
          agentList.map(props => {
            const { first_name, created_at, meta_value, last_name, licence_number, role, email, primary_phone, primary_country_code, user_uuid,
              key } = props;
            return (
              <tbody key={`client-${user_uuid}`}>
                <tr>
                  <td className=""><span className="plus-icon" id={`expandChildTable-${user_uuid}`}
                    onClick={() => handleTableCollapse(`expandChildTable-${user_uuid}`, `childTableRow-${user_uuid}`)}
                  >+</span></td>
                  <td>
                    <h4 className="mb-0 f-16 c-lg text-wrap" >
                      {first_name !== null && last_name !== null ?
                        Capitalize(first_name) + " " + Capitalize(last_name) :
                        (first_name !== null) ? Capitalize(first_name) : 'User'
                      }
                    </h4>
                  </td>
                  <td className="mobile-table-action-td">

                    <Dropdown as={ButtonGroup}>
                      <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
                        <span className="icon icon-sm">
                          <Image className="pe-none" src={Group} />
                        </span>
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dropdown-action-show pe-auto">
                        <Dropdown.Item className="f-16"
                          onClick={() => handleAcceptRejectRequest(user_uuid, 1)}
                        >
                          <Image className="pe-auto pa-r-5" src={Tick} /> Accept
                        </Dropdown.Item>

                        <Dropdown.Item className="f-16" onClick={() => handleAcceptRejectRequest(user_uuid, 2)}>
                          <Image className="pe-auto pa-r-5" src={Reject} /> Reject
                        </Dropdown.Item>

                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
                <tr id={`childTableRow-${user_uuid}`} style={hideChildTable}>
                  <td colSpan="3">
                    <div className="text-wrap ">
                      <h6 className="f-16 c-dg">Phone : {" "}
                        <span className="f-16 c-lg text-wrap fw-normal">{primary_phone ? primary_phone : 'Not available'}</span>
                      </h6>
                    </div>
                    <div className="text-wrap ">
                      <h6 className="f-16 c-dg">Email : {" "}
                        <span className="f-16 c-lg text-wrap fw-normal">{email ? email : 'Not available'}</span>
                      </h6>
                    </div>
                    <div className="text-wrap ">
                      <h6 className="f-16 c-dg">License number : {" "}
                        <span className="f-16 c-lg text-wrap fw-normal">{getLicenseNumber(meta_value, role) || ""}</span>
                      </h6>
                    </div>
                    <div className="text-wrap ">
                      <h6 className="f-16 c-dg">Agent Type : {" "}
                        <span className="f-16 c-lg text-wrap fw-normal">{role === 4 ? "Broker" : role === 2 ? "Immigration consultant" : ""}</span>
                      </h6>
                    </div>
                    <div className="text-wrap ">
                      <h6 className="f-16 c-lg">Requested Date : {" "}
                        <span className="f-16 c-lg text-wrap fw-normal">{created_at.includes(' ') === true ? created_at.split(' ')[0] : created_at.split('T')[0]}</span></h6>

                    </div>
                  </td>
                </tr>

              </tbody>
            )
          }
          )
        }

      </table>
    )
  }

  const GetAgentRequests = async () => {
    if (((search == '') && (isSearchChange == false)) || (activeItem != previousActiveItemValue)) {
      setIsLoading(true);
    }

    const agent = await GetAgentRequestsApi(
      startingIndex,
      pageLimit,
      searchText,
      selectedRole === "Immigration Consultant"
        ? 2
        : selectedRole === "Broker"
          ? 4
          : ""
    );
    const agentData = [];
    if (agent?.status_code === 1) {
      const agentData = agent.data[0].results;
      setAgentList(agentData);

      setTotalClientCount(agent.data[0]?.count);
      const pages = Math.ceil(agent.data[0]?.count / pageLimit);
      //  console.log(" total pages", pages);
      setTotalPages(pages);
    } else {
      setAgentList(agentData)
      // toast.error(agent.message, { duration: 4000 });
    }
    setIsLoading(false);
  };


  const handleAcceptRejectRequest = async (user_uuid, status) => {
    const response = await CreateAgentRequestApi(user_uuid, status);
    if (response?.status_code == 1) {
      setAlertsArray([...alertsArray, { id: `admin_customer_table${alertsArray.length}`, type: 'success', message: response?.message }]);
      GetAgentRequests();
    }
    else {
      setAlertsArray([...alertsArray, { id: `admin_customer_table${alertsArray.length}`, type: 'danger', message: response?.message }]);
    }
    setCount(Math.floor(Math.random() * 1000));
  }
  // console.log("agentrequest", agentList);

  //#endregion
  return (
    <Fragment>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap ">
        <div className="d-block mb-md-0">
          {/* <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item onClick={() => history.push(HomepageUrl)}>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Agents</Breadcrumb.Item>
          </Breadcrumb> */}

          <CommonBreadcrumb breadcrumbArray={breadcrumbArray}/>
        </div>
        <div className={`${singleScreen === false ? 'mt-5' : ''}`}>
          <Button variant="primary" className={`mb-md-4 mb-0 text-wrap ${singleScreen ? 'f-16' : ''}`} onClick={() => history.push("/admin/agents")}> Agents</Button>
        </div>
      </div>

      <div className="table-settings mb-4">
        {/* <Row className="justify-content-end">
          <Col xs={12} sm={4} md={4} lg={2} xl={2}>
            <div className="add-client-button-block agent-btn mb-2 mb-md-0 ">
              <ButtonGroup>
                <Button
                  style={{
                    backgroundColor: "#1F2937",
                    borderWidth: "0px",
                    color: "#fff",
                    marginBottom: "10px"
                  }}
                  onClick={() => history.push("/admin/agents")}
                >
                  Agents
                </Button>
              </ButtonGroup>
            </div>
          </Col>
        </Row> */}
        <Row >
          <Col xs={12} sm={6} md={4} lg={2} xl={2}>
            <div className="pt-1">
              <h4 className={`${(singleScreen === true) ? 'f-18' :  '' }`}>Requests</h4>
            </div>
          </Col>
          <Col xs={12} sm={6} md={4} lg={2} xl={2}></Col>
          <Col xs={12} sm={6} md={4} lg={3} xl={3}>
            <Form>
              <Form.Group>
                {/* <Form.Label>Example select</Form.Label> */}
                <Form.Select
                  className={[styles.caselistSearchBox, "px-3", "py-0", singleScreen && "f-16"]}
                  onChange={(e) => setSelectedRole(e.target.value)}
                  value={selectedRole}
                >
                  <option value="All">All</option>
                  <option value="Immigration Consultant">
                    Immigration Consultant
                  </option>
                  <option value="Broker">Broker</option>
                </Form.Select>
              </Form.Group>
            </Form>
          </Col>
          <Col xs={12} sm={6} md={4} lg={5} xl={5}>
            <InputGroup>
              <InputGroup.Text className={styles.caselistSearchBox}>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <Form.Control
                type="text"
                placeholder="Search"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                className={[styles.caselistSearchBox, "f-16"]}
              />
            </InputGroup>
          </Col>
        </Row>
        <Row className="p-0 mt-2">
          <Col lg={12} sm={12} md={12} xs={12} xl={12}>
            {(alertsArray.length > 0) &&
              alertsArray.reverse().map((item, index) => {
                return (
                  <CommonAlert key={index} id={item.id} type={item.type} message={item.message} handleAlert={handleAlert} />
                )
              })
            }
          </Col>
        </Row>
      </div>
      {isLoading === true ? (
        <DataLoading />
      ) : agentList.length == 0 ||
        agentList.length == undefined ||
        agentList.length == null ? (
        <NoDataFound />
      ) :
        <>
          {singleScreen === false ?
            <Card border="light" className="shadow-sm mb-4 ">
              <Card.Body className="pb-0 custom-table-body">
                <Table className="table-centered custom-table-responsive agent-requests-table  rounded mb-0">
                  <thead className="thead-light border-bottom">
                    <tr>
                      <th className="border-0 c-lg f-14" >
                        Name{" "}
                      </th>
                      <th className="border-0 action-block-agent-type" >
                        Agent Type
                      </th>
                      <th className="border-0 c-lg f-14" >
                        Licence Number
                      </th>
                      <th className="border-0 c-lg f-14" >
                        Requested Date
                      </th>
                      <th className="border-0 c-lg f-14" >
                        Phone
                      </th>
                      <th className="border-0 c-lg f-14" >
                        Email
                      </th>
                      <th
                        className="border-0 action-block-agent-requests c-lg f-14"
                       
                      >
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {agentList.map((agent) => (
                      <TableRow key={`agent-${agent.user_uuid}`} {...agent} />
                    ))}
                  </tbody>
                </Table>
                <div className="pagenation-custom-block">
                  <div className="pagination-msg">
                    {`Showing ${startingIndex} to ${Math.min(startingIndex + pageLimit - 1, totalClientCount)} of ${totalClientCount} entries`}
                  </div>
                  <CustomPagination
                    isSingleScreen={false}
                    activeItem={activeItem}
                    setActiveItem={setActiveItem}
                    totalPages={totalPages}
                    setStartingIndex={setStartingIndex}
                  // handlePaginationChange={handlePaginationChange}
                  />
                </div>
              </Card.Body>
            </Card>
            :
            <div className="bg-white">
              <MobileAgentRequestList />
              <div className="pagenation-custom-block pagination-padding">
                <div className="pagination-msg f-16">
                  {`Showing ${startingIndex} to ${Math.min(startingIndex + pageLimit - 1, totalClientCount)} of ${totalClientCount} entries`}
                </div>
                <CustomPagination isSingleScreen={true} activeItem={activeItem} setActiveItem={setActiveItem} totalPages={totalPages} setStartingIndex={setStartingIndex} />
              </div>

            </div>
          }
        </>
      }
      {/* <Toaster position="top-right" reverseOrder={false} /> */}
    </Fragment>
  );
  //#endregion
};
export default AgentRequestList;
