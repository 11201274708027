import React, { Fragment } from "react";
import Logo from "../../assets/img/landing-page/logo-img.png";
import { InputGroup, Input } from "reactstrap";
import toast, { Toaster } from "react-hot-toast";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { GetCountriesApi, SignInApi } from "../../actions/LoginAction";
import "./Index.css";
import { DataLoading } from "../../components/air-migrate/Loader";
import {
  Col,
  Row,
  Card,
  Form,
  Button,
  Container,
  FormGroup,
  Tab,
  Tabs,
} from "@themesberg/react-bootstrap";
import CommonRegistraionForm from "../../components/air-migrate/CommonRegistrationForm";
import CommonBrokerRegistraionForm from "../../components/air-migrate/CommonBrokerRegistrationFrom";
import CommonServices from "../../common-components/CommonServices";
import CommonAlert from "../../common-components/CommonAlert";

const Registration = () => {
  let immigrationRegistrationFeatureFlag = CommonServices.GetUnleashFeatureFlag(
    "Create-Consultant-Post"
  );

  let brokerRegistrationFeatureFlag =
    CommonServices.GetUnleashFeatureFlag("Create-Broker-Post");

  const [activeTab, setActiveTab] = useState((immigrationRegistrationFeatureFlag ? "Immigration Consultant" : "Brokers"));
  const history = useHistory();
  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  //Alert states and functions
  const [alertsArray, setAlertsArray] = useState([]);
  const [count, setCount] = useState(0);

  const handleAlert = (id) => {
    let array = [];
    for (let i = 0; i < alertsArray.length; i++) {
      if ((alertsArray[i].id) == id) {
        array = alertsArray.pop();
      }
    }
    setCount(Math.floor(Math.random() * 1000))
  }

  useEffect(() => {
    const isUserLogedIn = localStorage.getItem('amUserId');
    // console.log("amUserId", isUserLogedIn, "typeOf", typeof (isUserLogedIn));
    if (isUserLogedIn === null) {
    }
    else {
      history.push('/');
    }
  }, []);

  return (
    <Fragment>
      <Container className="registration-main-container">
        <Row className="registration-main-row">
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={7}
            className="d-none d-md-flex registration-side-text-block"
          >
            <div className="login-side-text">
              <h1 className="login-side-head-text">Airmigrate</h1>
              <p> Redefining how the world immigrates. </p>
              <p>
                <span className="fw-bolder">Register to start your journey.</span> We ensure the utmost privacy and protection of your information as you navigate your new path.
              </p>
            </div>
          </Col>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={5}
            className="registration-form-block flex-column"
          >
            <div className="inputContainer registrationInputBlock">
              <div className=" regi-alert-container mt-2">
                {(alertsArray.length > 0) &&
                  alertsArray.reverse().map((item, index) => {
                    return (
                      <div className="text-center">
                        <CommonAlert key={index} id={item.id} type={item.type} message={item.message} handleAlert={handleAlert} />
                      </div>
                    )
                  })
                }
              </div>
              <div className="welcomeContainer mb-0">
                <img src={Logo} onClick={() => history.push('/')} alt="" className="regi-login-logo"/>
                <div className="registrationMessageContainer">
                  <p className="registrationMessageText">
                    Join us now! Create your account and unlock endless
                    possibilities.
                  </p>
                  <div>
                    <Tabs
                      activeKey={activeTab}
                      onSelect={(tab) => toggleTab(tab)}
                      className="registration-custom-tab"
                    >
                      {immigrationRegistrationFeatureFlag && (
                        <Tab
                          eventKey="Immigration Consultant"
                          title="Immigration Consultant"
                        >
                          <CommonRegistraionForm setAlertsArray={setAlertsArray} alertsArray={alertsArray} />
                        </Tab>
                      )}
                      {brokerRegistrationFeatureFlag && (
                        <Tab eventKey="Brokers" title="Brokers">
                          <CommonBrokerRegistraionForm setAlertsArray={setAlertsArray} alertsArray={alertsArray} />
                        </Tab>
                      )}
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      {/* <Toaster position="top-right" reverseOrder={false} /> */}
    </Fragment>
  );
};

export default Registration;
