import React, { useState, useEffect } from 'react';
import { Breadcrumb } from '@themesberg/react-bootstrap';
import { HomepageUrl } from '../utilities/helpers/Constant';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';

const CommonBreadcrumb = ({ breadcrumbArray, pageName }) => {
    const history = useHistory();
    const [userId, setUserId] = useState(localStorage.getItem('amUserId'));
    const [userRoleId, setUserRoleId] = useState(localStorage.getItem('roleId'));
    const [caseRoleId, setCaseRoleId] = useState(localStorage.getItem("role"));

    return (
        <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            {breadcrumbArray.map((item, index) => {
                if (item.url == '') {
                    return (
                        <Breadcrumb.Item key={index} active>
                            {item.name == undefined ?
                                caseRoleId == 3 ? "Customer Profile" : "Agent Profile"
                                : item.name}
                        </Breadcrumb.Item>
                    )
                }
                else {
                    return (
                        <Breadcrumb.Item key={index} className={`pe-auto`} onClick={() => {

                            //Condition added to redirect agents to chat page else landing page
                            if ((userRoleId == 2 || userRoleId == 4) && (index == 0)) {
                                history.push('/chat', { userId })
                            }
                            else if (item.name == 'Cases') {
                                history.push(item.url, { caseRoleId: caseRoleId });
                            }
                            else {
                                history.push(item.url);
                            }
                        }}>
                            {item.name == 'Home' ? <FontAwesomeIcon icon={faHome} /> : item.name}
                        </Breadcrumb.Item>
                    )
                }

            })}
        </Breadcrumb>
    )
}
export default CommonBreadcrumb;