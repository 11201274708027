import { Container } from '@themesberg/react-bootstrap';
import React, { Fragment, useEffect, useState } from 'react';
import '../../assets/global-css/Index.css';
import '../../assets/mobile-custom-css/Index.css';

const NoDataFound = () => {

    //States to manage mobile ui
    const [screenSize, setScreenSize] = useState(getCurrentDimension());
    const [singleScreen, setSingleScreen] = useState(false);

    useEffect(() => {


        //functionality to set collapsible table 
        window.addEventListener('resize', updateDimension);
        updateDimension();

        // removing eventlistener for no furhter effect after 
        return (() => {
            window.removeEventListener('resize', updateDimension);
        })
    }, []);

    const updateDimension = () => {
        setScreenSize(getCurrentDimension());
        var value = getCurrentDimension();
        // set a variable true when screen width reaches <768px
        if (value.width <= 767) {
            setSingleScreen(true)
        } else {
            setSingleScreen(false)
        }
    }

    function getCurrentDimension() {
        return {
            width: window.innerWidth,
            height: window.innerHeight
        }
    }

    return (
        <Fragment>
            <Container className='d-flex justify-content-center align-items-center'>
                <div className='text-center no-data-found-block'>
                    <h2 className={`c-lg  ${singleScreen  === true ? 'f-16' : 'f-18'}`} >No Data Found!</h2>
                </div>
            </Container>
        </Fragment>
    )
}
export default NoDataFound;