// Library import
import { Fragment, useState, useEffect } from "react"
import { Modal } from '@themesberg/react-bootstrap'
import { useHistory } from "react-router";

// Local import
import { getAgentDetailsByCaseIdAPI } from "../../actions/ClientActions.jsx";
import CommonServices from "../../common-components/CommonServices.jsx";
import ProfileIconsModal from "./ProfileIconsModal.jsx";
import ChatHistoryComponent from "../../views/my-chats/ChatHistoryComponent.jsx";

// Case Chat Popup(used in case edit)
const CaseChatPopup = (props) => {

    const { caseId, isArchivedList } = props

    const history = useHistory()
    const [caseAgentDetails, setCaseAgentDetails] = useState({})

    const [userRoleId, setUserRoleId] = useState(localStorage.getItem('roleId'));
    const [userId, setUserId] = useState(localStorage.getItem('amUserId'));
    const [userName, setUserName] = useState(JSON.parse(localStorage.getItem('userName')));
    //Case Chat Popup
    const [showPopup, setShowPopup] = useState(false)

    const [isMiniScreen, setIsMiniScreen] = useState(false)
    const [currentScreenSize, setCurrentScreenSize] = useState(getWindowDimensions());

    const [showOnlyHeader, setShowOnlyHeader] = useState(false)
    const [caseName, setCaseName] = useState('')

    // get current window dimenstions
    function getWindowDimensions() {
        return {
            width: window.innerWidth,
            height: window.innerHeight
        }
    }

    // Used when window dimensions change
    useEffect(() => {
        const updateDimension = () => {
            setCurrentScreenSize(getWindowDimensions())
        }
        window.addEventListener('resize', updateDimension);

        // set a variable true when screen width reaches <768px
        if (currentScreenSize.width <= 767) {
            setIsMiniScreen(true)
        } else {
            setIsMiniScreen(false)
        }
        // removing eventlistener for no furhter effect after 
        return (() => {
            window.removeEventListener('resize', updateDimension);
        })
    }, [currentScreenSize])

    // Initial UseEffect
    useEffect(() => {
        if (caseId && userId) {
            getAgentDetailsByCaseId()
        }
    }, [caseId, userId])

    // get agent details by case id
    const getAgentDetailsByCaseId = async () => {
        const response = await getAgentDetailsByCaseIdAPI(caseId, userId)
        if (response?.status_code == 1) {
            let agentDetails = response.data
            agentDetails.full_name = CommonServices.getUserFullName(agentDetails)
            setCaseName(`${CommonServices.getUserFullName(agentDetails)} (${agentDetails.case_name})`)
            setCaseAgentDetails(agentDetails)
        }
    }

    // go to main chat page 
    const goToMainChat = () => {
        history.push('/chat', { userId, caseId, caseName, userName })
    }

    // open popup/chat page
    const goToCaseChatPopup = () => {
        // in mobile case
        if (isMiniScreen) {
            goToMainChat()
        } else {
            setShowPopup(true)
        }
    }

    // Popup header
    const PopupHeader = () => (
        <div className="case-chat-popup">
            <div className='chat-header-profile'>
                <div className='chat-named-profile-header'>
                    <ProfileIconsModal userDetails={caseAgentDetails} />
                </div>
                <div className="profile-name-heading-text">
                    <h6 className='profile-name text-capital-chat '>{caseName}</h6>
                </div>

                <div className={'caseChatPopupModelIcons'}>
                    <div onClick={() => { setShowOnlyHeader(!showOnlyHeader) }}>
                        <i class={showOnlyHeader ? "fa-solid fa-angle-up pe-3" : "fa-solid fa-chevron-down pe-3"}></i>
                    </div>
                    <div onClick={goToMainChat}>
                        <i class="fa-solid fa-up-right-and-down-left-from-center fa-sm pe-3"></i>
                    </div>
                    <div onClick={() => { setShowPopup(false); setShowOnlyHeader(false) }}>
                        <i class="fa-solid fa-xmark pe-1"></i>
                    </div>
                </div>
            </div>
        </div>
    )

    // Login Main Popup
    const CaseChatPopupModal = () => {
        return (
            <Modal show={showPopup} onHide={setShowPopup} className={'case-chat-popup-model'} >
                <Modal.Header>
                    <PopupHeader />
                </Modal.Header>
                <Modal.Body >
                    <ChatHistoryComponent
                        userId={userId}
                        caseId={caseId}
                        caseName={caseName}
                        fromUserName={userName}
                        setFromUserName={setUserName}
                        userRoleId={userRoleId}
                        roleIdThree={userRoleId == 3}
                        consultantProfile={caseAgentDetails?.profile_path}
                        consultantName={caseAgentDetails?.full_name}
                        isPopupChat={true}
                        isArchivedList={isArchivedList}
                    />
                </Modal.Body>
            </Modal>
        )
    }

    return (
        <Fragment >
            { //Chat Popup
                !showOnlyHeader ?
                    !showPopup && < div className={'caseChatPopupIconBlock'} >
                        <div onClick={() => { goToCaseChatPopup() }} className={'caseChatPopupIcon'}>
                            <i class="fa-regular fa-comment-dots message-icon"></i>
                        </div>
                    </div >
                    :
                    <div className="show-only-header">
                        <PopupHeader />
                    </div>
            }
            { //mini header
                !showOnlyHeader && <CaseChatPopupModal />

            }
        </Fragment>
    )
}
export default CaseChatPopup;