import React, { useState, Fragment, useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import { OtpVerifyApi, SignInApi } from '../../actions/LoginAction';
import OtpInput from 'react-otp-input';
import { useLocation } from 'react-router-dom';
import LogoImage from '../../assets/img/landing-page/logo-img.png';
import { DataLoading } from '../../components/air-migrate/Loader'
import {
  Col,
  Row,
  Container,
} from "@themesberg/react-bootstrap";
import './Index.css';
import CommonServices from '../../common-components/CommonServices';
import { useDispatch } from 'react-redux';
import { setMyProfile } from '../../reducers/ProfileImageReducer';
import CommonAlert from '../../common-components/CommonAlert';
import { setIsVisaUploaded, setIsVisaApproved, setVisaOption, setUserStatus, setIsUserLogin } from '../../reducers/VisaUploadReducer.jsx';

const VerifyOtp = () => {
  //#region conts states
  const [otp, setOtp] = useState('');
  const [isSubmitting, setSubmitting] = useState(false);
  const [userDetails, setUserDetails] = useState('');
  const [showloader, setShowLoader] = useState(false);
  const [isLOginThroughPhone, setIsLoginThroughPhone] = useState(true);
  const dispatch = useDispatch();
  const history = useHistory();
  const { loginMethod, ...rest } = {}
  const location = useLocation();
  //Alert states and functions
  const [alertsArray, setAlertsArray] = useState([]);
  const [count, setCount] = useState(0);
  //#endregion conts states 
  const handleAlert = (id) => {
    let array = [];
    for (let i = 0; i < alertsArray.length; i++) {
      if ((alertsArray[i].id) == id) {
        array = alertsArray.pop();
      }
    }
    setCount(Math.floor(Math.random() * 1000))
  }
  //#region useEffect start
  useEffect(() => {
    const data = Object.keys(location.state.state);
    if (data[0] === "phone") {
      setIsLoginThroughPhone(true);
    }
    else if (data[0] === "email") {
      setIsLoginThroughPhone(false);
    }
  }, [count, location.state.state])
  //#endregion useEffect end
  
  // fucntion to call on press submit button 
  const handleOtpVerification = async () => {
    const enteredOtp = otp
    setSubmitting(true);
    const userDetails = localStorage.getItem("userDetails");
    setUserDetails(userDetails)
    let requestPayload;
    if (isLOginThroughPhone === false) {
      requestPayload = {
        email: location.state.state.email,
        otp: enteredOtp,

      };
    } else if (isLOginThroughPhone == true) {
      requestPayload = {
        phone: location.state.state.phone,
        primary_country_code: location.state.state.primary_country_code,
        otp: enteredOtp,

      };
    }
    setShowLoader(true);
    const response = await OtpVerifyApi(requestPayload);
    if (response?.status_code === 1) {
      dispatch(setIsUserLogin(true))
      const profilePath = response.data[0].user_details.profile_path
      localStorage.setItem("profilePath", JSON.stringify(profilePath));
      dispatch(setMyProfile(profilePath));
      localStorage.setItem("status", response.data[0].status);
      dispatch(setUserStatus(response.data[0].status))
      localStorage.setItem("accessToken", response.data[0].token);
      localStorage.setItem("roleId", response.data[0].role);
      // First Name + Last Name
      localStorage.setItem("userName", JSON.stringify(response.data[0].user_details.full_name));
      localStorage.removeItem("userDetails");
      localStorage.setItem('amUserId', response.data[0].user_details.user_uuid)
      setShowLoader(false);
      const roleId = response.data[0].role;
      var paramsObject = { userId: response.data[0].user_details.user_uuid }
      const linkCaseId = localStorage.getItem('linkCaseId')
      if (linkCaseId) {
        paramsObject['caseId'] = linkCaseId
        localStorage.removeItem('linkCaseId')
      }
      const linkCaseName = localStorage.getItem('linkCaseName')
      if (linkCaseId) {
        paramsObject['caseName'] = linkCaseName
        localStorage.removeItem('linkCaseName')
      }
      // Customer Case
      if (roleId === 3) {
        const isVisaUploaded = response.data[0]?.user_details.visa_document ? true : false;
        localStorage.setItem("isVisaUploaded", isVisaUploaded);
        dispatch(setIsVisaUploaded(isVisaUploaded))
        const isVisaApproved = response.data[0]?.user_details.visa_approve == 1 ? true : false;
        localStorage.setItem("isVisaApproved", isVisaApproved);
        dispatch(setIsVisaApproved(isVisaApproved))
        const visaOption = response.data[0]?.user_details.visa_option
        localStorage.setItem("visaOption", visaOption);
        dispatch(setVisaOption(visaOption))
        // Used for send a query case
        var goToLink = ''
        // toLink
        const toLink = localStorage.getItem('toLink')
        if (toLink) {
          goToLink = toLink
          localStorage.removeItem('toLink')
        }
        // Used for send a query case
        if (goToLink) {
          history.push(goToLink);
        } else {
          history.push('/chat', paramsObject);
        }
      }
      else if (roleId === 2 || roleId === 4) {
        // Link Params
        history.push('/chat', paramsObject);
      } else if (roleId === 1) {
        history.push('/admin/agents');
      }
    } else {
      setAlertsArray([...alertsArray, { id: `${'otp_verify'}${alertsArray.length}`, type: 'danger', message: response?.message }]);
      setShowLoader(false);
    }
    // setOtp(['']);
    setSubmitting(false);
  };

  // resend otp function call
  const resendOTPFunction = async () => {
    setShowLoader(true);
    const response = await SignInApi(location.state.state);
    setShowLoader(false);
    if (response?.status_code == 1) {
      setAlertsArray([...alertsArray, { id: `${'otp_verify'}${alertsArray.length}`, type: 'success', message: response?.message ? response?.message : '' }]);
    } else {
      setAlertsArray([...alertsArray, { id: `${'otp_verify'}${alertsArray.length}`, type: 'danger', message: response?.message }]);
    }
  };

  function handleChange(otp) {
    setOtp(otp);
  }

  /* Check Unleash feature is enabled or not to show/hide client list and add client button*/
  let verifyOtpFlag = CommonServices.GetUnleashFeatureFlag('signin-otp');

  //#region render start
  return (
    <Fragment>
      <>
        <Container className="login-main-container" fluid>
          <Row className='login-main-row'>
            <Col xs={12} sm={12} md={12} lg={7} className="d-none d-md-flex login-side-text-block">
              <div className="login-side-text">
                <h1 className="login-side-head-text">Airmigrate</h1>
                <p>To ensure the security of your account, please enter the OTP sent to your registered phone number or email</p>
              </div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={5} className="login-form-block">
              <div className="main-container flex-column">
                <div className=" alert-container mt-2">
                  {(alertsArray.length > 0) &&
                    alertsArray.reverse().map((item, index) => {
                      return (
                        <div className="text-center">
                          <CommonAlert key={index} id={item.id} type={item.type} message={item.message} handleAlert={handleAlert} />
                        </div>
                      )
                    })
                  }
                </div>
                <div className="inner-container">
                  <div className="px-3 pb-3">
                    <img src={LogoImage} alt="Logo" className="cursor-pointer" onClick={() => { history.push('/') }} />
                  </div>
                  <div className="text-container">
                    <h2 className="heading">Enter Verification Code</h2>
                    <p className="inner-text">
                      Please check your Mail or Message For Received OTP to Verify
                    </p>
                  </div>
                  <form>
                    <div className="otp-input-fields">
                      <OtpInput
                        onChange={handleChange}
                        value={otp}
                        inputStyle="inputStyle"
                        numInputs={4}
                        inputType="number"
                        separator={<span></span>}
                        renderInput={(props) => <input {...props} />}
                        shouldAutoFocus={true}
                      />
                    </div>
                    {verifyOtpFlag &&
                      <div className="lower-container">
                        <button className="verify-btn" disabled={showloader} type='submit' onClick={handleOtpVerification}>
                          {isSubmitting ? 'Verifying...' : 'Verify OTP'}
                        </button>
                        <p disabled={showloader} className="lower-text">Didn’t Receive the OTP ? <span className="resend-text" onClick={resendOTPFunction}>RESEND OTP</span></p>
                      </div>
                    }
                  </form>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </>
      {showloader === true && <DataLoading />}
    </Fragment>
  );
};
//#endregion render end
export default VerifyOtp;
