/*
A common  actions file , which contains all the api's which will be used in all upload , download and view files actions in app.
*/

import { authApi } from '../utilities/helpers/ApiHelper';
import { toast } from 'react-hot-toast';

// function for document upload 
async function uploadFilesApi(URL, formData) {
    try {
        const response = await authApi.postFileAPI(URL, formData);
        return response;
    }
    catch (err) {
        toast.error("Something went wrong while uploading file!", { duration: 4000 });
        console.error("Something went wrong!", err);
        return {'status_code' : 2, 'message': err , 'data':[]}

    }
}

// function to call api to get link for viewing document
async function GetLinkToViewDocumentApi(URL, id) {
    let data = {
        "documents_id": id,
        "headerTag": ''
    }
    try {
        const response = await authApi.postAPI(URL, data);
        // console.log("view document response",response)
        // if (response.status_code == 1) {
            return response;
        // }
    }
    catch (e) {
        toast.error("Something went wrong!", { duration: 3000 });
        console.error(e);
    }
}
// function to call api to get link for downloadaing document
async function GetLinkToDownloadDocumentApi(URL, id) {
    let data = {
        "documents_id": id,
        "headerTag": 'attachment'
    }
    try {
        const response = await authApi.postAPI(URL, data);
        if (response.status_code == 1) {
            return response.data[0].url;
        } else {
        }
    }
    catch (e) {
        console.error(e);
    }
}

// function to call api to get link for downloadaing document for alert
async function GetLinkToDownloadDocumentWithAlertApi(URL, id) {
    // console.log("GetLinkToDownloadDocumentWithAlertApi called in action")
    let data = {
        "documents_id": id,
        "headerTag": 'attachment'
    }
    try {
        const response = await authApi.postAPI(URL, data);
        return response;
    }
    catch (e) {
        console.error(e);
    }
}



// function for image upload for profile  
async function uploadMyprofileImagesApi(formData) {
    let url = `profile-upload`;
    try {
        const response = await authApi.postFileAPI(url, formData);
        return response;
    }
    catch (err) {
        // toast.error("Something went wrong while uploading image!", { duration: 4000 });
        console.error("Something went wrong while uploading image!", err);
    }
}
export { uploadFilesApi, GetLinkToViewDocumentApi, uploadMyprofileImagesApi, GetLinkToDownloadDocumentApi,
    GetLinkToDownloadDocumentWithAlertApi }