import ReactLoading from 'react-loading';
import { ThreeDots } from 'react-loader-spinner';
import { Loading } from 'react-loading-dot';
import  Loader  from 'react-js-loader';
import '../../assets/global-css/Index.css';


export const OldSpinnerLoader = () => {
    return (
        <div className='loding-container'>
            <div className="loading">
                <div style={{ height: '100%', width: '100%' }}></div>
                <ReactLoading type={'bubbles'} color={'#0184fe'} height={'100%'} width={'100%'} className='loader-customize' />
                <span style={{ left: "44%", top: "54%", position: 'fixed', color: '#ffff', fontSize: 25 }}>Please wait...</span>
            </div>
        </div>
    );
}

export const DataLoading = () => {
    return (
        <div className='loding-container'>
            <div className='loading' style={{ justifyContent: 'center' }}>
                <ThreeDots
                    visible={true}
                    height="100"
                    width="100"
                    color="#0184fe"
                    radius="9"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                />
                {/* <span style={{left:"44%", top:"50%",position:'fixed',color:'#ffff',fontSize:25}}>Please wait...</span> */}
                {/* <span 
            style={{ 
                // left: "49%", top: "53%", 
                position: 'fixed' }}
            >Please wait...</span> */}
            </div>
        </div>
    )
}

export const TypingLoader = () => {
    return (
        // <Loading className="typing-loader" size='10px' margin='5px' background='#47c7f4' />
        <div className={"item"}>
            <Loader type="bubble-top" bgColor='#47c7f4' color='#47c7f4' size={30} />
        </div>
    )
}