//#region Imports
import styles from "./Index.module.css"
import { Fragment, useState, useEffect } from "react";
import { Breadcrumb, Row, InputGroup, Col, Form, Card, Table, Image, Badge, Dropdown, ButtonGroup, Button } from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faSearch } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from 'react-router-dom';
import docStyle from '../view-all-documents/document-table/Index.module.css';
import { Capitalize } from "../../common-components/StringConversions";
import { DataLoading } from "../../components/air-migrate/Loader";
import { GetUsersApi, CreateAccessRequestApi } from "../../actions/DirectLoginActions.jsx";
import NoDataFound from "../../components/air-migrate/NoDataFound";
import CustomPagination from "../../common-components/CustomPagination";
import { PROFILE_PATH } from "../../config.tsx";
import CommonServices from "../../common-components/CommonServices.jsx";
import Group from '../../assets/img/icons/Airmigrate icons/16px/Group.png';
import { HomepageUrl } from "../../utilities/helpers/Constant.jsx";
import ProfileIconsModal from "../../components/air-migrate/ProfileIconsModal.jsx";
import CommonAlert from "../../common-components/CommonAlert.jsx";
import CommonBreadcrumb from "../../common-components/CommonBreadcrumb.jsx";
import { DIRECT_LOGIN_ID } from "../../config.tsx";
import { useDispatch } from 'react-redux';
import { setIsVisaUploaded, setIsVisaApproved, setVisaOption, setUserStatus, setIsUserLogin } from '../../reducers/VisaUploadReducer.jsx';
import { setMyProfile } from '../../reducers/ProfileImageReducer';
//#endregion

//#region Main component
const DirectLogin = () => {
    const [searchText, setSearchText] = useState('');
    const history = useHistory();
    const [isSearchChange, setIsSearchChange] = useState(false);

    //Common breadcrumb array
    const [breadcrumbArray, setBreadcrumbArray] = useState([
        { name: 'Home', url: HomepageUrl },
        { name: 'Direct login', url: '' }

    ]);

    //States to manage mobile ui
    const [screenSize, setScreenSize] = useState(getCurrentDimension());
    const [singleScreen, setSingleScreen] = useState(false);

    useEffect(() => {
        var amUserId = localStorage.getItem("amUserId");
        console.log("am jserid", amUserId, "typeof", typeof (amUserId))


        // console.log("DirectAccessFeatureFlag", checkForUnleash());
        if ((amUserId !== null)) {
            if (DIRECT_LOGIN_ID == amUserId) {
                //functionality to set collapsible table 
                window.addEventListener('resize', updateDimension);
                updateDimension();

                // removing eventlistener for no furhter effect after 
                return (() => {
                    window.removeEventListener('resize', updateDimension);
                })
            }
            else {
                history.push("/examples/404");
            }
        }
        else {
            history.push('/');
        }


    }, [])
    //#region Functions
    const updateDimension = () => {
        setScreenSize(getCurrentDimension());
        var value = getCurrentDimension();
        // set a variable true when screen width reaches <768px
        if (value.width <= 767) {
            setSingleScreen(true)
        } else {
            setSingleScreen(false)
        }
    }

    function getCurrentDimension() {
        return {
            width: window.innerWidth,
            height: window.innerHeight
        }
    }

    //Get unleash service access details
    //Used for to get unleash Toggle Information


    /* Function and const for alert */
    //Alert states
    const [alertsArray, setAlertsArray] = useState([]);
    const [count, setCount] = useState(0);

    const handleAlert = (id) => {
        let array = [];
        for (let i = 0; i < alertsArray.length; i++) {
            if ((alertsArray[i].id) == id) {
                array = alertsArray.pop();

            }
        }
        setCount(Math.floor(Math.random() * 1000))
    }
    //#endregion

    return (
        <Fragment>
            <div className="">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap  pt-0 pb-2">
                    <div className="d-block mb-4 mb-md-0">
                        <CommonBreadcrumb breadcrumbArray={breadcrumbArray} />
                    </div>

                    {/* Received Requests redirect button */}
                    {/* <div className={`${singleScreen === false ? 'mt-5' : ''}`}>
                        <Button variant="primary" className={`mb-md-4 mb-0 text-wrap ${singleScreen ? 'f-18' : ''}`} onClick={() => history.push("/customer/requests/recieved")}> Pending Actions</Button>
                    </div> */}
                </div>

                <div className="table-settings mb-4">
                    <Row className="justify-content-space-between align-items-center">
                        <Col xs={12} md={12} lg={4} xl={7} className="align-item-end">
                            <h4 className={`${singleScreen ? 'f-18' : ''}`}>Direct Login</h4>
                        </Col>
                        <Col xs={12} md={12} lg={8} xl={5} className="align-item-end">
                            <InputGroup>
                                <InputGroup.Text>
                                    <FontAwesomeIcon icon={faSearch} />
                                </InputGroup.Text>
                                <Form.Control className={`${singleScreen && 'f-16' + ' '} ${styles.customSearchDocument}  `} type="text" placeholder="Search" value={searchText} onChange={(e) => { setIsSearchChange(true); setSearchText(e.target.value) }} />
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row className="p-0 ">
                        <Col lg={12} sm={12} md={12} xs={12} xl={12}>
                            {(alertsArray.length > 0) &&
                                alertsArray.reverse().map((item, index) => {
                                    return (
                                        <CommonAlert key={index} id={item.id} type={item.type} message={item.message} handleAlert={handleAlert} />
                                    )
                                })
                            }
                        </Col>
                    </Row>
                </div>
                <DirectLoginTable
                    search={searchText}
                    isSearchChange={isSearchChange}
                    singleScreen={singleScreen}
                    alertsArray={alertsArray}
                    setAlertsArray={setAlertsArray}
                    count={count}
                    setCount={setCount} />
            </div>
        </Fragment>
    )
}
export default DirectLogin;
//#endregion

//#region  Sub component
export const DirectLoginTable = ({ search, isSearchChange, singleScreen, alertsArray, setAlertsArray }) => {
    //#region States and variables
    const history = useHistory();
    const [requestList, setRequestList] = useState([]);
    const [startingIndex, setStartingIndex] = useState(1);
    const [pageLimit, setPageLimit] = useState(10);
    const [isLoading, setIsLoading] = useState();

    //pagination relates states
    const [activeItem, setActiveItem] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalRequestCount, setTotalRequestCount] = useState(0);
    const [count, setCount] = useState(0);
    const [userRoleId, setUserRoleId] = useState(localStorage.getItem('roleId'));
    const [previousActiveItemValue, setPreviousItemActiveValue] = useState(1);
    const dispatch = useDispatch();
    //#endregion

    //#region useEffect
    useEffect(() => {
        GetUsersList();
    }, [search, count, activeItem])

    //#endregion

    //#region style
    const hideChildTable = {
        display: 'none'
    }
    //#endregion


    //#region Functions (all document list table body)
    const checkForUnleash = () => {
        const DirectAccessFeatureFlag = CommonServices.GetUnleashFeatureFlag('user-direct-login')
        return DirectAccessFeatureFlag;
    }

    const handleTableCollapse = (parentId, childId) => {
        const childElement = document.getElementById(childId);
        const parentElement = document.getElementById(parentId);
        // console.log("childElement, parentElement and content", parentId, childId, childElement, parentElement);
        if (parentElement.innerHTML === '+') {
            childElement.style.display = 'table-row';
            parentElement.innerHTML = '-';
        }
        else {
            childElement.style.display = 'none';
            parentElement.innerHTML = '+';
        }
    }

    const GetStatusNames = (status) => {
        switch (status) {
            case 0:
                return "Pending";
            case 1:
                return "Active";
            case 2:
                return "Inactive";

            default:
                return "Deleted"

        }
    }

    const TableBodyrequestList = (props) => {
        const { case_name, status, created_at, email, first_name, last_name, user_uuid, primary_country_code, primary_phone, profile_path, role } = props;

        return (
            <tr className={docStyle.requestListRow}>

                <td>
                    <Row className="align-items-center">
                        <Col className="col-auto imageLeftLess custom-user-image">
                            <ProfileIconsModal userDetails={props} />
                        </Col>
                        <Col className="ms--2">
                            <h4 className="h6 mb-0">
                                <a href="" className="pe-none f-1r work-break c-lg" >
                                    {(first_name == null) ? 'User' : ((first_name != null) && ((last_name != null))) ? Capitalize(first_name) + " " + Capitalize(last_name) : Capitalize(first_name)}
                                </a>
                            </h4>
                            {email &&
                                <>
                                    <small className="pe-none f-1r word-break" >
                                        {email}
                                    </small><br />
                                </>
                            }
                            {primary_phone &&
                                <small className="pe-none f-1r work-break" >
                                    {primary_phone}
                                </small>
                            }
                        </Col>

                    </Row>
                </td>
                <td>
                    <div className={docStyle.commonTypographyForRow}>
                        {/* <p>{created_at?.split(' ')[0]}</p> */}
                        <p>{GetStatusNames(status)}</p>
                    </div>
                </td>

                <td>
                    <Dropdown as={ButtonGroup}>
                        <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
                            <span className="icon icon-sm">
                                <Image className="pe-auto" src={Group} />
                            </span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-action-show">

                            <Dropdown.Item onClick={() => { CreateAccessRequest(user_uuid) }}>
                                {/* {status == 1 ?
                                    <Image className="pa-r-5 pe-auto" src={Accept} /> :
                                    (status == 2) &&
                                    <Image src={Reject} className="pa-r-5 pe-auto" />
                                }
                                {GetStatusNames(status)} */}
                                Get Access
                            </Dropdown.Item>

                        </Dropdown.Menu>
                    </Dropdown>
                </td>
            </tr>
        );
    };

    const MobileTableUserList = (props) => {
        return (
            <table className="table">
                <thead className="mobile-thead">
                    <tr>

                        <th className="text-wrap f-14">Name</th>
                        <th className="mobile-table-action-th f-14" >Action</th>
                    </tr>
                </thead>
                {
                    requestList.map(props => {
                        const { case_name, status, created_at, email, first_name, last_name, user_uuid, primary_country_code, primary_phone, profile_path, role } = props;

                        return (
                            <tbody key={`client-${created_at}`}>
                                <tr>
                                    {/* <td className=""><span className="plus-icon" id={`expandChildTable-${user_uuid}`}
                                        onClick={() => handleTableCollapse(`expandChildTable-${user_uuid}`, `childTableRow-${user_uuid}`)}
                                    >+</span></td> */}
                                    <td>
                                        <Row className="align-items-center pe-none text-wrap img-block" >
                                            <Col className="col-auto imageLeftLess ">
                                                <a className="user-avatar pe-none" >
                                                    {(profile_path == '' || profile_path == undefined || profile_path == null) ?
                                                        <div className={` bg-light rounded-circle d-flex justify-content-center align-items-center img-d-60`} >
                                                            {((first_name != null) && (last_name != null)) ?
                                                                <p className=" fw-bolder pt-2 c-lg f-16" >{CommonServices.getNameCharByFullName(first_name + ' ' + last_name)}</p> :
                                                                (first_name != null) ?
                                                                    <p className=" fw-bolder pt-2 c-lg f-16" >{CommonServices.getNameCharByFullName(first_name + ' ' + last_name)}</p> :
                                                                    <p className=" fw-bolder pt-2 c-lg f-16">U</p>
                                                            }
                                                        </div>
                                                        :
                                                        <Image src={PROFILE_PATH} className="rounded-circle img-d-60" />
                                                    }
                                                </a>
                                            </Col>
                                            <Col className="ms--2">
                                                <h4 className=" mb-0 f-16 c-lg text-wrap" >
                                                    {first_name !== null && last_name !== null ?
                                                        Capitalize(first_name) + " " + Capitalize(last_name) :
                                                        (first_name !== null) ? Capitalize(first_name) : 'User'
                                                    }
                                                </h4>
                                                <span className="f-16 c-lg">
                                                    {email && <>
                                                        <span className="text-break"
                                                        >{email}</span><br />
                                                    </>}
                                                </span>
                                                <span className="f-16 c-lg">
                                                    {primary_phone && <>
                                                        <span className="text-break"
                                                        >{primary_phone}</span><br />
                                                    </>}
                                                </span>
                                            </Col>
                                        </Row>
                                    </td>
                                    <td className="mobile-table-action-td">

                                        <Dropdown as={ButtonGroup}>
                                            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
                                                <span className="icon icon-sm">
                                                    <Image className="pe-none" src={Group} />
                                                </span>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className="dropdown-action-show">
                                                <Dropdown.Item className="f-16" onClick={() => { CreateAccessRequest(user_uuid) }}>
                                                    {/* {status == 1 ?
                                                        <Image className="pe-auto pa-r-5" src={Accept} /> :
                                                        (status == 2) &&
                                                        <Image className="pe-auto pa-r-5" src={Reject} />
                                                    }
                                                    {GetStatusNames(status)} */}
                                                    Get Access
                                                </Dropdown.Item>

                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </td>
                                </tr>
                                {/* <tr id={`childTableRow-${user_uuid}`} style={hideChildTable}>
                                    <td colSpan="3">
                                        <div className="text-wrap ">
                                            <h6 className="f-16 c-dg">Title : {" "}
                                                <span className="c-lg f-16 fw-normal">{((case_name == null) || (case_name == '')) ? 'Not available' : Capitalize(case_name)}</span>
                                            </h6>
                                        </div>
                                        <div className="text-wrap ">
                                            <h6 className="f-16 c-dg">Requested Date : {" "}
                                                <span className="f-16 c-lg fw-normal">{created_at.includes(' ') === true ? created_at.split(' ')[0] : created_at.split('T')[0]}</span></h6>

                                        </div>
                                    </td>
                                </tr> */}

                            </tbody>
                        )
                    }
                    )
                }

            </table>
        )
    }

    const CreateAccessRequest = async (id) => {
        const requestBody = {
            user_uuid: id
        }
        setIsLoading(true);
        const response = await CreateAccessRequestApi(requestBody);
        console.log('request response', response);
        // setCount(Math.floor(Math.random() * 100));

        if (response?.status_code === 1) {
            dispatch(setIsUserLogin(true))
            const profilePath = response.data[0].user_details.profile_path
            localStorage.setItem("profilePath", JSON.stringify(profilePath));
            dispatch(setMyProfile(profilePath));

            localStorage.setItem("status", response.data[0].status);
            dispatch(setUserStatus(response.data[0].status))
            localStorage.setItem("accessToken", response.data[0].token);
            localStorage.setItem("roleId", response.data[0].role);
            // First Name + Last Name
            localStorage.setItem("userName", JSON.stringify(response.data[0].user_details.full_name));
            localStorage.removeItem("userDetails");
            localStorage.setItem('amUserId', response.data[0].user_details.user_uuid)
            setIsLoading(false);
            // setAlertsArray([...alertsArray, { id: `${'otp_verify'}${alertsArray.length}`, type: 'success', message: response?.message }]);
            // toast.success(response.message);
            const roleId = response.data[0].role;
            var paramsObject = { userId: response.data[0].user_details.user_uuid }
            const linkCaseId = localStorage.getItem('linkCaseId')
            if (linkCaseId) {
                paramsObject['caseId'] = linkCaseId
                localStorage.removeItem('linkCaseId')
            }

            const linkCaseName = localStorage.getItem('linkCaseName')
            if (linkCaseId) {
                paramsObject['caseName'] = linkCaseName
                localStorage.removeItem('linkCaseName')
            }

            // Customer Case
            if (roleId === 3) {

                const isVisaUploaded = response.data[0]?.user_details.visa_document ? true : false;
                localStorage.setItem("isVisaUploaded", isVisaUploaded);
                dispatch(setIsVisaUploaded(isVisaUploaded))

                const isVisaApproved = response.data[0]?.user_details.visa_approve == 1 ? true : false;
                localStorage.setItem("isVisaApproved", isVisaApproved);
                dispatch(setIsVisaApproved(isVisaApproved))

                const visaOption = response.data[0]?.user_details.visa_option
                localStorage.setItem("visaOption", visaOption);
                dispatch(setVisaOption(visaOption))

                // Used for send a query case
                var goToLink = ''
                // toLink
                const toLink = localStorage.getItem('toLink')
                if (toLink) {
                    goToLink = toLink
                    localStorage.removeItem('toLink')
                }

                // Used for send a query case
                if (goToLink) {
                    history.push(goToLink);
                } else {
                    history.push('/chat', paramsObject);
                }
            }
            else if (roleId === 2 || roleId === 4) {
                // Link Params

                // setTimeout(() => {
                history.push('/chat', paramsObject);
                // }, ALERT_SHOW_TIME);

            } else if (roleId === 1) {
                // setTimeout(() => {
                history.push('/admin/agents');
                // }, ALERT_SHOW_TIME);
            }

            // setTimeout(() => {
            // history.push('/dashboard', {state:true});
            // }, 2000);
            // return response;
        } else {
            setAlertsArray([...alertsArray, { id: `${'otp_verify'}${alertsArray.length}`, type: 'danger', message: response?.message }]);
            // toast.error(response?.message ? response.message : 'Something Went Wrong, Try Again Later!!');
            setIsLoading(false);

        }
    }

    const GetUsersList = async () => {
        if (((search == '') && (isSearchChange == false)) || (activeItem != previousActiveItemValue)) {
            setIsLoading(true);
        }

        var requestList;
        requestList = await GetUsersApi(startingIndex, pageLimit, search);
        console.log("documentsList", requestList, " count", requestList.data[0]?.count, " pageLimit", pageLimit, "documentsList", requestList);
        setRequestList(requestList.data[0]?.results);
        setTotalRequestCount(requestList.data[0]?.count);
        const pages = Math.ceil(requestList.data[0]?.count / pageLimit);
        // console.log(' total pages', pages);
        setTotalPages(pages);
        setIsLoading(false);
    }
    //#endregion

    //#region JSX
    return (
        <Fragment>
            {checkForUnleash() == true ?
                <>
                    {isLoading === true ? <DataLoading /> :
                        ((requestList?.length == null) || (requestList?.length == undefined) || (requestList?.length == 0)) ?
                            <NoDataFound /> :
                            <>
                                {singleScreen === false ?
                                    <Card border="light" className="shadow-sm mb-4 ">
                                        <Card.Body className="pb-0">
                                            <Table responsive className="table-centered custom-table-responsive table-nowrap rounded mb-0">
                                                <thead className="thead-light border-bottom custom-table-heading">
                                                    <tr>
                                                        <th className="border-0  c-lg f-14" >Name</th>
                                                        <th className="border-0  c-lg f-14" >Status</th>
                                                        <th className="border-0  c-lg f-14" >Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {requestList.map(item => (
                                                        <TableBodyrequestList key={item.id} {...item} />
                                                    ))
                                                    }
                                                </tbody>
                                            </Table>

                                            <div className="pagenation-custom-block">
                                                <div className="pagination-msg">
                                                    {`Showing ${startingIndex} to ${Math.min(
                                                        startingIndex + pageLimit - 1,
                                                        totalRequestCount
                                                    )} of ${totalRequestCount} entries`}
                                                </div>
                                                <CustomPagination isSingleScreen={false} activeItem={activeItem} setActiveItem={setActiveItem} totalPages={totalPages} setStartingIndex={setStartingIndex} />
                                            </div>


                                        </Card.Body>

                                        {/* <CustomPagination activeItem={activeItem} setActiveItem={setActiveItem} totalPages={totalPages} setStartingIndex={setStartingIndex} /> */}
                                    </Card> :

                                    <div className="bg-white"> <MobileTableUserList />
                                        <div className="pagenation-custom-block pagination-padding">
                                            <div className="pagination-msg f-16">
                                                {`Showing ${startingIndex} to ${Math.min(
                                                    startingIndex + pageLimit - 1,
                                                    totalRequestCount
                                                )} of ${totalRequestCount} entries`}
                                            </div>
                                            <CustomPagination isSingleScreen={true} activeItem={activeItem} setActiveItem={setActiveItem} totalPages={totalPages} setStartingIndex={setStartingIndex} />
                                        </div>

                                    </div>
                                }
                            </>
                    }
                </> :

                <NoDataFound />
            }
            {/* <Toaster position="top-right" reverseOrder={false} /> */}
        </Fragment>
    )
    //#endregion


}
//#endregion
