// Library import 
import { useState, useEffect, useRef } from "react";
import { DocusealForm } from '@docuseal/react'
import { Button, Dropdown, ButtonGroup, Modal, Col, Row } from "@themesberg/react-bootstrap";
import { Input } from 'reactstrap';
import { useHistory } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import io from 'socket.io-client'
import ReactLoading from 'react-loading';
import OtpInput from 'react-otp-input';
import PhoneInput from 'react-phone-input-2'

// Local imports
import { CapitalizeAllWords } from "../../common-components/StringConversions.jsx";
import { GetLinkToViewDocumentApi } from "../../actions/FileUploadActions.jsx";
import { AcceptOrRejectRequestAPI, downloadESignDocumentAPI, getCaseDocumentsCountAPI, getUsersCaseDocumentsCountAPI, uploadDocumentListAPI, getCaseTotalDocumentCountAPI, noDocumentAPI, getAllChatAPI, getChatUserMetaStartCaseFormallyAPI, updateChatUserMetaStartCaseFormallyAPI, getChatCaseCompleteStatusByCaseIdAPI, updateChatCaseCompletedStatus } from "../../actions/ChatsActions.jsx";
import { SOCKET_SERVER_URL, Default_Country, DOCUSEAL_BASE_URL } from "../../config.tsx";
import { CreateInviteUserStatusChangeApi } from "../../actions/RequestActions.jsx";
import CommonServices from '../../common-components/CommonServices.jsx';
import messageHelper from "../../utilities/helpers/messageHelper.js";

// Icons & Logo
import DownloadIcon from '../../assets/img/icons/Airmigrate icons/512px/Download document.png';
import ViewIcon from '../../assets/img/icons/Airmigrate icons/512px/View.png';
import InfoIcon from '../../assets/img/icons/Airmigrate icons/512px/Info.png'
import DownloadIconWhite from '../../assets/img/icons/Airmigrate icons/512px/Download document(White).png';
import ViewIconWhite from '../../assets/img/icons/Airmigrate icons/512px/View(White).png';
import chatEditProfileIcon from "../../assets/img/chats/edit-profile-chat.png"
import chatUploadDocumentIcon from "../../assets/img/chats/upload-document-icon.png"
import AirMigrateShortLogo from '../../assets/img/chats/AirMigrateShortLogo.png'
import startIcon from "../../assets/img/icons/Airmigrate icons/512px/start.png"
import logo from '../../assets/img/landing-page/logo-img.png'

// Popup Modals
import ProfileIconsModal from "../../components/air-migrate/ProfileIconsModal.jsx";
import CommonConfirmBox from "../../common-components/CommonConfirmBox.jsx";
import FileUploadModal from "../../components/air-migrate/FileUploadModal.jsx";
import DocumentViewModal from "../../components/air-migrate/DocumentViewModal.jsx";

// Case Chat History Component (Right Side)
const ChatHistoryComponent = (props) => {

    const { userId, userRoleId, caseName, caseId, setCaseId, fromUserName, setFromUserName,
        consultantProfile, consultantName, roleIdThree, isUserLogin = true, isMiniScreen = true, roleIdNull = false, isPopupChat = false, isArchivedList = false, setIsArchivedList, callCaseListAPI } = props;

    const history = useHistory()

    // chat form input value
    const [formValue, setFormValue] = useState({});

    const [showESignPopup, setShowESignPopup] = useState(false);

    // Accept/Reject Buttons
    const [isChatEnabledToUser, setIsChatEnabledToUser] = useState(false);
    const [isButtonDisable, setIsButtonDisable] = useState(false)


    const [showLoginPopup, setShowLoginPopup] = useState(false);
    const [showFileUploadPopup, setShowFileUploadPopup] = useState(false);
    // Upload Popup
    const [showUploadPopup, setShowUploadPopup] = useState(false)

    // Chat History Array
    const [chatHistoryArray, setChatHistoryArray] = useState([])
    const chatHistoryArrayLenght = chatHistoryArray.length - 1

    // Welcome screen
    const [defultWelcomeScreen, setDefultWelcomeScreen] = useState(true);
    // Update New Message Object
    const [updateNewMessage, setUpdateNewMessage] = useState({})

    // case document count 
    const defaultDocCount = [{ uploaded_doc_count: 0, total_doc_count: 0 }]
    const [caseDocumentCountObject, setCaseDocumentCountObject] = useState(defaultDocCount)
    const [caseDocCountObjectBySocket, setCaseDocCountObjectBySocket] = useState(defaultDocCount)
    // Document Document screen state 
    const [showDocumentUploadScreen, setShowDocumentUploadScreen] = useState(false)

    // Use for Chat Scroll
    const messagesEndRef = useRef(null)

    // Socket IO Instance
    const [socket, setSocket] = useState(null)
    // Main Messsage
    const [message, setMessage] = useState('')

    //Alert states and functions
    const [alertsArray, setAlertsArray] = useState([]);

    // View Document
    const [showViewDocumentModal, setShowViewDocumentModal] = useState(false)
    const [viewDocumentId, setViewDocumentId] = useState()
    const handleViewDocumentModal = () => setShowViewDocumentModal(!showViewDocumentModal)

    // used for show button start case formally in action
    const [startCaseFormally, setStartCaseFormally] = useState(false)

    // case completed: True, case not completed: False
    const [caseCurrentStatus, setCaseCurrentStatus] = useState(false)

    // Create a reference to the hidden file input element
    const hiddenFileInput = useRef(null);

    // ESign completed response
    const [eSignCompletedResponse, setESignCompletedResponse] = useState({})

    // Socket for Chats (Case)
    useEffect(() => {
        const newSocket = io(SOCKET_SERVER_URL)
        setSocket(newSocket)
        setShowDocumentUploadScreen(false)

        if (caseId) {
            newSocket.on('connect', () => {
                newSocket.emit('userJoinCaseChatRoom', caseId)
            })

            newSocket.on('disconnect', () => {
                newSocket.emit('leaveRoom', caseId)
            })

            // New Message Recive from server
            newSocket.on('receive_server_message', (updateMessageObject) => {
                setUpdateNewMessage(updateMessageObject)
                scrollToBottom()
            })

            // Update Document Count
            newSocket.on('update-document-count', (updateMessageObject) => {
                setCaseDocCountObjectBySocket(updateMessageObject)
            })

            // ESign Completed response 
            newSocket.on('esign-completed', (response) => {
                setESignCompletedResponse(response)
            })
        }

        return () => { newSocket.disconnect(); }
    }, [caseId])

    // Update ESign Complated in ChatHistoryArray
    useEffect(() => {
        if (eSignCompletedResponse) {
            var newArray = []
            for (let index = 0; index < chatHistoryArray.length; index++) {
                const element = chatHistoryArray[index];
                if (element.chat_id == eSignCompletedResponse.chat_id) {
                    element.message = [{ ...element.message[0], ...eSignCompletedResponse }]
                }
                newArray.push(element)
            }
            setChatHistoryArray(newArray)
        }
    }, [eSignCompletedResponse])

    // When user click to chat users list
    useEffect(() => {
        let roleId = localStorage.getItem('roleId')
        if (roleId) {
            roleId = JSON.parse(roleId);
        }

        if (userId && caseId) {
            setMessage('');
            getAllUsersChatHistory()
            getChatUserMetaStartCaseFormally()
            if (roleId != 3) {
                getChatCaseCompleteStatusByCase()
            }
            setDefultWelcomeScreen(false)

            // Update message seen in DB
            updateMessageSeenSocket()
        } else {
            setDefultWelcomeScreen(true)
        }

    }, [userId, caseId])

    // Update Message Seen 
    const updateMessageSeenSocket = () => {
        if (socket && caseId && userId) {
            socket.emit('update_message_seen', userId, caseId)
        }
    }

    // Handle New Message Send
    const sendMessageHandler = () => {
        if (socket && message.trim() != "" && userId && caseId) {
            let newMessageObj = { from_user_id: userId, from_user_name: fromUserName, case_id: caseId, message: [{ message: message }] }
            socket.emit('send_message', newMessageObj);
            setMessage('');
        }
    }

    // Get all chat history
    const getAllUsersChatHistory = async () => {
        const response = await getAllChatAPI(userId, caseId)
        if (response?.status_code == 1) {
            setChatHistoryArray(response.data)
            scrollToBottom();
        } else {
            setChatHistoryArray([])
            const message = response?.message ? response.message : messageHelper('error')
            toast.error(message, { duration: 4000 });
        }
    }

    // Chat User Meta for start case formally button
    const getChatUserMetaStartCaseFormally = async () => {
        const response = await getChatUserMetaStartCaseFormallyAPI(userId, caseId)
        if (response?.status_code == 1) {
            setStartCaseFormally(response.data)
        } else {
            const message = response?.message ? response.message : messageHelper('error')
            toast.error(message, { duration: 4000 });
        }
    }

    // used for case current status in action
    const getChatCaseCompleteStatusByCase = async () => {
        const response = await getChatCaseCompleteStatusByCaseIdAPI(caseId)
        if (response?.status_code == 1) {
            setCaseCurrentStatus(response.data)
        } else {
            const message = response?.message ? response.message : messageHelper('error')
            toast.error(message, { duration: 4000 });
        }
    }

    // Update New Message in ChatHistoryArray
    useEffect(() => {
        if (caseId && updateNewMessage.id == caseId && !isChatEnabledToUser) {
            setChatHistoryArray((prevData) => [...prevData, updateNewMessage.data])
            updateMessageSeenSocket()
        }
    }, [updateNewMessage])

    // Close Documents List
    const closeUploadPopup = () => {
        setShowUploadPopup(false)
        // Update Document Count in Chat
        // getCaseTotalDocumentCount();
    }
    const [documentId, setDocumentId] = useState(null)
    const [documentName, setDocumentName] = useState('')

    // Update document count by Socket
    useEffect(() => {
        setCaseDocumentCountObject(caseDocCountObjectBySocket)
    }, [caseDocCountObjectBySocket])

    const handleCloseESignPopup = () => setShowESignPopup(false);
    const handleCloseLoginPopup = () => setShowLoginPopup(false);
    const handleCloseFileUploadPopup = () => setShowFileUploadPopup(false);

    // useEffect to get case total document count
    useEffect(() => {
        setShowESignPopup(false);
        getCaseTotalDocumentCount();
    }, [caseId])

    // get case document count
    const getCaseTotalDocumentCount = async () => {
        if (caseId && !roleIdNull) {
            const response = await getCaseTotalDocumentCountAPI(caseId)
            if (response?.status_code == 1) {
                setCaseDocumentCountObject(response?.data)
            } else {
                const message = response?.message ? response.message : messageHelper('error')
                toast.error(message, { duration: 4000 });
            }
        }
    }

    // useEffect for enable/disable accept/reject button
    useEffect(() => {
        setIsChatEnabledToUser(false)
        setIsButtonDisable(false)
        const messageObject = chatHistoryArray[chatHistoryArrayLenght]
        if (messageObject) {
            const messageItem = messageObject?.message[0]
            const messageItemKeys = Object.keys(messageItem);
            if (messageItemKeys.includes('request_accepted')) {
                setIsChatEnabledToUser(!messageItem.request_accepted);
                setIsButtonDisable(!messageItem.is_button_disabled)
            }
        }
    }, [chatHistoryArray])


    // handle enter press
    const handleKeyPress = (event) => {
        // When user enter, send message
        if (event.key === 'Enter') {
            sendMessageHandler();
        }
    };

    // handle form input changes
    const handleInputChange = (key, value) => {
        setFormValue({ ...formValue, [key]: value })
    }

    // handle form phone input changes
    const handleContryAndPhoneChange = (value, country) => {
        const IsCountryCode = country.dialCode;
        const IsPhoneValue = value.slice(country.dialCode.length)
        if (IsCountryCode && IsPhoneValue) {
            setFormValue({ ...formValue, primary_phone: [Number(IsCountryCode), Number(IsPhoneValue)] })
        }
    }

    // Submit Question & Answer Form
    const handleFormSubmit = async (arrayLen, chatId) => {
        let isValueEmpty = false
        let valuesArray = Object.values(formValue)
        valuesArray.filter((value) => {
            if (value == '') {
                isValueEmpty = true
            }
        })

        if (arrayLen == valuesArray.length && !isValueEmpty) {
            // Add full name in local storage
            if (formValue?.first_name || formValue?.last_name) {
                var fullName = formValue?.first_name
                if (formValue?.last_name) {
                    fullName = fullName + ' ' + formValue?.last_name
                }
                setFromUserName(fullName)
                localStorage.setItem("userName", JSON.stringify(fullName));
                socket.emit('form_submit', userId, caseId, formValue, fullName, chatId)
            } else {
                socket.emit('form_submit', userId, caseId, formValue, fromUserName, chatId)
            }
            setFormValue({})
        }
    }

    // Accept/Reject Button click chandan
    const [loginPopupTitle, setLoginPopupTitle] = useState(messageHelper('login-to-view-and-upload-doc'))

    // handle send a query request(For Broker)
    const handleSendAQeuryRequest = async (statusValue, item) => {
        if (roleIdNull) {
            setLoginPopupTitle(messageHelper('login-to-accept-reject'))
            setShowLoginPopup(true)
            return
        }
        var requestBody = {
            from_user_uuid: item.from_user_id,
            status: statusValue,
            chat_id: item.id,
            case_id: item.case_id,
            reject_message: null
        }
        setIsButtonDisable(!statusValue)
        setIsChatEnabledToUser(!statusValue);
        const response = await AcceptOrRejectRequestAPI(requestBody);
    }

    // handle case invitation request (For Client)
    const handleCaseInvitationRequest = async (statusValue, item) => {
        if (roleIdNull) {
            setLoginPopupTitle(messageHelper('login-to-accept-reject'))
            setShowLoginPopup(true)
            return
        }

        const requestBody = {
            id: Number(item.case_id),
            status: statusValue ? 2 : 3,
            chat_case: true
        }

        const response = await CreateInviteUserStatusChangeApi(requestBody);
        if (response?.status_code == 1) {
            if (!statusValue) {
                // reject case
                callCaseListAPI()
                setCaseId(null)
            }

            setIsButtonDisable(!statusValue)
            setIsChatEnabledToUser(!statusValue);
            setChatHistoryArray([])
            getAllUsersChatHistory()
        }
    }

    // Handle E-Sign Submit
    const handleESignComplete = (data, chatId, chatIndex, isAgentSign) => {
        socket.emit('form_submit', userId, caseId, { 'eSign': data }, fromUserName, chatId)
        setShowESignPopup(false)

        // Update signed_by_agent or signed_by_client in chatHistoryArray
        // if (isAgentSign) {
        //     updateValueInChatArray(chatIndex, 'signed_by_agent', true)
        // } else {
        //     updateValueInChatArray(chatIndex, 'signed_by_client', true)
        // }
    }

    // ESign Document Loader
    const CustomLoaderFuntion = () => {
        return (
            <div className='loding-container-in-chat'>
                <div className='loading-block'>
                    <div className="loading-in-chat">
                        <ReactLoading type={'bubbles'} color={'#0184fe'} height={'10%'} width={'10%'} className='loader-customize' />
                        <span className="loding-text" style={{ color: '#0184fe', fontSize: 25 }}>Please wait ...</span>
                    </div>
                </div>
            </div>
        );
    }

    const [isLoading, setIsLoading] = useState(false)
    // useEffect for start loder in ESign Popup
    useEffect(() => {
        if (showESignPopup) {
            setIsLoading(true)
        }

    }, [showESignPopup])


    const [eSignValueArray, setESignValueArray] = useState(false)
    // ESign Popup Model
    const ESignPopupModel = () => {
        if (!eSignValueArray) {
            return
        }

        const slug = eSignValueArray[0]
        const chatId = eSignValueArray[1]
        const chatIndex = eSignValueArray[2]

        // without login or rollIdThree: userSlug
        const isAgentSign = (roleIdNull || roleIdThree) ? false : true;
        const mySlug = (roleIdNull || roleIdThree) ? slug[0] : slug[1]
        return (
            <Modal show={showESignPopup} onHide={handleCloseESignPopup} size="xl" className={isLoading ? 'e-sign-model-body' : 'e-sign-model-body-default'}>
                <Modal.Header closeButton>
                    <Modal.Title>E-Sign Document</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="app">
                        <DocusealForm
                            src={`${DOCUSEAL_BASE_URL}/${mySlug}`}
                            onComplete={(event) => handleESignComplete(event, chatId, chatIndex, isAgentSign)}
                            logo={AirMigrateShortLogo}
                            onLoad={(data) => { setIsLoading(false) }}
                            customCss={'#submit_form_button { background-color: #0184fe; color: #fff;} #expand_form_button { background-color: #0184fe; color: #fff;}'}
                        />
                        {isLoading && <CustomLoaderFuntion />}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button style={{ borderRadius: 20, borderColor: '#0184FE', borderWidth: 0, backgroundColor: '#0184FE', marginTop: 15, width: 100 }} onClick={handleCloseESignPopup}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    const [downloadLoader, setDownloadLoader] = useState(false)
    // Download ESign Document Funtion
    const downloadESignDocument = async (submissionID, userId) => {
        if (downloadLoader) {
            return
        }
        setDownloadLoader(true)
        const response = await downloadESignDocumentAPI(userId, submissionID, caseId)
        if (response?.status_code == 1) {
            const responseData = response?.data
            const documentURL = responseData?.url
            if (documentURL) {
                CommonServices.downloadFileService(documentURL, 'E-Sign Document', 'pdf')
            }
            setDownloadLoader(false)
        } else {
            const message = response?.message ? response.message : messageHelper('error')
            toast.error(message, { duration: 4000 });
        }
        setDownloadLoader(false)
    }

    // Handle accept payment(on click proceed button)
    const clickPaymentTermsButton = (caseId, chatId, chatIndex) => {
        // socket.emit('proceedToPayment', userId, caseId, chatId)
        // updateValueInChatArray(chatIndex, 'isProceed', true)

        socket.emit('form_submit', userId, caseId, { 'paymentTems': true }, fromUserName, chatId)
        // Update isProceed in chatHistoryArray
        updateValueInChatArray(chatIndex, 'isProceed', true)
    }

    // Update Message Key's Value in ChatHistoryArray
    const updateValueInChatArray = (indexOfChat, key, value) => {

        const updatedChatHistoryArray = [
            ...chatHistoryArray.slice(0, indexOfChat), // Copy elements before the updated one
            {
                ...chatHistoryArray[indexOfChat], // Copy the original chat object
                message: [
                    {
                        ...chatHistoryArray[indexOfChat].message[0], // Copy the original message object
                        [key]: value // Update the key: value
                    }
                ]
            },
            ...chatHistoryArray.slice(indexOfChat + 1) // Copy elements after the updated one
        ];
        setChatHistoryArray(updatedChatHistoryArray)
    }

    // Click to needed document(To show upload screen)
    const onClickNeededDocument = (clickedUserId = userId) => {

        // login user
        if (!roleIdNull) {
            setShowingDocumentUserId(clickedUserId)
            setShowDocumentUploadScreen(true)
        } else {
            // Used for without login user
            setShowLoginPopup(true)
        }
    }

    // Download Document
    const downloadDocumentById = async (item) => {
        let documentId = item.message[0]?.document_id
        if (documentId) {
            let url = `view-document`;
            const response = await GetLinkToViewDocumentApi(url, documentId)
            if (response?.status_code == 1) {
                const documentURL = response?.data[0]?.url
                if (documentURL) {
                    const link = document.getElementById("download-doc");
                    link.href = String(documentURL);
                    link.target = '_blank';
                    link.click();
                }
            } else {
                const message = response?.message ? response.message : messageHelper('error')
                toast.error(message, { duration: 4000 });
            }
        }

    }
    // View Document
    const viewDocumentFuntion = (item) => {
        if (roleIdNull) {
            setLoginPopupTitle(messageHelper('login-to-view-doc'))
            setShowLoginPopup(true)
        }
        else if (item.message[0]?.document_id) {
            setViewDocumentId(item.message[0]?.document_id)
            handleViewDocumentModal()
        } else {
            console.log('No Document ID')
        }

    }

    // handle click button: I don't have document
    const noDocumentAPICall = async () => {
        if (documentId) {
            const response = await noDocumentAPI(documentId)
            if (response?.status_code == 1) {
                getUploadDocumentList()
                setShowFileUploadPopup(false)
                const message = response.message ? response.message : ''
                toast.success(message, { duration: 4000 });

            } else {
                const message = response?.message ? response.message : messageHelper('error')
                toast.error(message, { duration: 4000 });
            }
        }
    }

    /* functions to selct file on clicking button */
    const [mFiles, setMfiles] = useState([]);
    const [mUploadProgress, setMuploadProgress] = useState(0);
    const [mErrorMessages, setMerrorMessages] = useState(['']);
    const [isChatUpload, setIsChatupload] = useState(false);
    const [refState, setRefState] = useState(false);
    // function to call when file is  selected in the UI
    const handleFileChange = (e) => {
        const selectedFiles = Array.from(e.target.files);
        if (selectedFiles.length) {
            setShowUploadPopup(true);
            setIsChatupload(true);
            setMfiles(selectedFiles);
            setRefState(false);
        } else {
            setShowUploadPopup(false);
        }
    };

    // when the Button component is clicked to open file input
    const handleClick = (event) => {
        callInputOnChange();
        setMfiles([]);
        setMuploadProgress(0);
        setMerrorMessages([]);
    };

    // handle when upload document
    const callInputOnChange = () => {
        if (hiddenFileInput) {
            hiddenFileInput.current.click();
        }
    }

    // Upload Popup Service
    const UploadPopupService = () => {
        return (
            <Modal show={showFileUploadPopup} onHide={handleCloseFileUploadPopup} size="m" className={isPopupChat ? 'e-sign-model-body-default document-upload-model1' : 'e-sign-model-body-default'} centered>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <div className={'app upload-popup-service-class'}>
                        <div style={{ paddingBottom: 15 }}>
                            <h5 className="chat-documnt-upload-name">Upload {documentName}</h5>
                        </div>
                        <Button onClick={(e) => { handleClick(e); setRefState(true); handleCloseFileUploadPopup(false) }} style={{ borderRadius: 20, borderColor: '#0184FE', borderWidth: 0, backgroundColor: '#0184FE', width: '100%', marginBottom: 15 }}>
                            Upload From Device
                        </Button>
                        <Button onClick={() => { noDocumentAPICall() }} style={{ borderRadius: 20, borderColor: '#0184FE', borderWidth: 0, backgroundColor: '#0184FE', width: '100%', marginBottom: 15 }}>
                            I don't have this document
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }

    // Document Upload Screen Variables
    const [userType, setUserType] = useState('')
    // Document upload screen
    const [isUserListVisible, setIsUserListVisible] = useState(true) //
    const [showDocumentsList, setShowDocumentsList] = useState(isUserListVisible)

    // is client 
    const [isClient, setIsClient] = useState(userRoleId == 3)

    // applicants/sponsor count array
    const [documentCountArray, setDocumentCountArray] = useState([])
    const [userDocumentCountArray, setUserDocumentCountArray] = useState([])
    const [userDataArray, setUserDataArray] = useState([])

    // Document list
    const [userUUID, setUserUUID] = useState(userId)
    const [uploadDocumentList, setUploadeDocumentList] = useState([])

    // Document suggestion Popup modal
    const [showSuggestionPopupModal, setShowSuggestionPopupModal] = useState(false);
    const [suggestionValue, setSuggestionValue] = useState('');
    const handleShowSuggestionPopupModal = () => setShowSuggestionPopupModal(!showSuggestionPopupModal);

    // showing document list user's id(when click user document needed button)
    const [showingDocumentUserId, setShowingDocumentUserId] = useState('')

    // useEffect for get request documents list
    useEffect(() => {
        if (showDocumentUploadScreen) {
            getCaseDocumentsCount()
            getUsersCaseDocumentsCount()
            getUploadDocumentList()
        }
    }, [showDocumentUploadScreen])

    // get case document count
    const getCaseDocumentsCount = async () => {
        const response = await getCaseDocumentsCountAPI(caseId)
        if (response?.status_code == 1) {
            setDocumentCountArray(response?.data)
        } else {
            const message = response?.message ? response.message : messageHelper('error')
            toast.error(message, { duration: 4000 });
        }
    }

    // get user document count
    const getUsersCaseDocumentsCount = async () => {
        const response = await getUsersCaseDocumentsCountAPI(caseId)
        if (response?.status_code == 1) {
            setUserDocumentCountArray(response?.data)
        } else {
            const message = response?.message ? response.message : messageHelper('error')
            toast.error(message, { duration: 4000 });
        }
    }

    // Scroll Chat Screen, When receive new messages
    const scrollToBottom = () => {
        setTimeout(() => {
            messagesEndRef.current?.scrollIntoView(false)
            // messagesEndRef.current?.scrollIntoView({ behavior: "smooth", block: 'end' })
        }, 0);
    }

    // document upload page to chat page
    const backToChat = () => {
        setShowDocumentUploadScreen(false);
        scrollToBottom();
    }

    // on click user category list(Applicants, Sponsor)
    const onClickUserCategory = (text) => {
        setUserType(text)
        setUserDataArray([])
        if (userDocumentCountArray && userDocumentCountArray.length && userDocumentCountArray[0] && userDocumentCountArray[0][text]) {
            setUserDataArray(userDocumentCountArray[0][text])
        }
    }

    // get all requested document list
    useEffect(() => {
        if (userUUID && caseId && showDocumentsList) {
            getUploadDocumentList()
        }
    }, [userUUID, caseId, showDocumentsList])

    // get case document count
    const getUploadDocumentList = async () => {
        const response = await uploadDocumentListAPI(caseId, userUUID)
        if (response?.status_code == 1) {
            var filteredObject = {}
            const dataArray = response?.data
            Object.keys(dataArray).map((item, index) => {
                const itemArray = dataArray[item]
                const innerDataArray = itemArray.filter((innerItem) => {
                    return innerItem.data.sort((a, b) => {
                        return a.document_option.localeCompare(b.document_option)
                    })
                })
                filteredObject[item] = innerDataArray
            })
            setUploadeDocumentList(filteredObject)
        } else {
            const message = response?.message ? response.message : messageHelper('error')
            toast.error(message, { duration: 4000 });
        }
    }

    // open upload document popup
    const openUploadPopUp = (item, document_type) => {
        // Upload document by client
        if (isUserListVisible) {
            setDocumentId(item.id)
            // setShowUploadPopup(true)
            setShowFileUploadPopup(true)
            setDocumentName(document_type + ': ' + item.document_option)
        }
    }

    // Show Document Suggestion Popup
    const DocumentSuggestionModal = () => {
        return (
            <Modal show={showSuggestionPopupModal} onHide={handleShowSuggestionPopupModal} size="sm" className={'e-sign-model-body-default'} centered>
                {/* <Modal.Header closeButton>
                    <h5 className="">Help Text</h5>
                </Modal.Header> */}
                <Modal.Body >
                    <div className="app" >
                        <div>
                            {suggestionValue ? suggestionValue : 'No help text found'}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }

    // Document Upload Screen
    const DocumentUploadScreen = () => {
        return (
            <>
                <div className={isUserLogin ? 'airmigrate-block-inner airmigrate-block-inner-login' : 'airmigrate-block-inner'}>
                    {!isPopupChat && <ChatProfileHeader key={'document-header'} />}

                    <div className='chatbox-padding-bottom' ref={messagesEndRef}>

                        <div className="airmigrate-chat-inner-block">

                            {/* Buttons */}
                            <div className="back-btn-block">
                                <button onClick={backToChat}>Back To Chat </button>
                                {   //showDocumentsList && 
                                    !isUserListVisible ? showDocumentsList &&
                                        <button onClick={() => { setShowDocumentsList(false) }}>Back to Applicant</button>
                                        : null
                                }
                            </div>
                            {/* Case Category Card */}
                            {!showDocumentsList &&
                                <div className="uploading-documents-section">
                                    <div className="uploading-documents-block">
                                        <h3>Select person for uploading documents</h3>
                                        {documentCountArray.map((item, index) => (
                                            <div className="for-sponsor-button-block" key={`select-documents-category-${index}`}>
                                                <button onClick={() => { onClickUserCategory(item.text) }}>
                                                    <span className="sponsor-button-text">{item.user_type}</span>
                                                    <span className="sponsor-button-text">{`${item.uploaded_doc_count}/${item.total_doc_count}`}</span>
                                                </button>
                                            </div>
                                        ))

                                        }

                                    </div>
                                </div>
                            }
                            {/* Case User Card */}
                            {!showDocumentsList ?
                                userType &&
                                <div className="uploading-documents-section">
                                    <div className="uploading-documents-block select-sponsor">
                                        <h3 className="text-capital-chat">Select {userType}</h3>
                                        {
                                            userDataArray.length ?
                                                userDataArray.map((item, index) => (
                                                    <div className="for-sponsor-button-block" key={`select-select-category-${index}`} >
                                                        <button onClick={() => { setShowDocumentsList(true); setUserUUID(item.user_uuid) }}>
                                                            <span className="sponsor-button-text text-capital-chat">{`${item.first_name} ${item.last_name}`}</span>
                                                            {
                                                                item.doc_count ?
                                                                    <span className="sponsor-button-text">{`${item.doc_count.uploaded_doc_count}/${item.doc_count.total_doc_count}`}</span>
                                                                    :
                                                                    <span className="sponsor-button-text">0/0</span>
                                                            }
                                                        </button>
                                                    </div>
                                                ))
                                                : <div className="sponsor-button-text text-capital-chat"> No {userType} </div>
                                        }
                                    </div>
                                </div>
                                : null
                            }

                            {/* Document upload popup */}
                            {
                                showUploadPopup &&
                                <FileUploadModal show={showUploadPopup} handleClose={closeUploadPopup} documentId={documentId} FunctionToRefreshAfterUpload={getUploadDocumentList} isPopupChat={isPopupChat} modalAlertsArray={alertsArray} setModalAlertsArray={setAlertsArray} setIsChatupload={setIsChatupload} isChatUpload={isChatUpload} mFiles={mFiles} setMfiles={setMfiles} />
                            }

                            {/* User Document Card */}
                            {showDocumentsList && uploadDocumentList[showingDocumentUserId] &&
                                <Row>
                                    {uploadDocumentList[showingDocumentUserId]?.map((item, index) => (
                                        <Col md={12} lg={isPopupChat ? 12 : 6} xl={isPopupChat ? 12 : 4} key={`user-document-card-${index}`}>
                                            <div className="statement-details-section">
                                                <div className="statement-details">
                                                    <h3>{item.document_type}</h3>
                                                    {
                                                        item?.data.map((documentItem, documentIndex) => (
                                                            <div className="statement-block" key={`document-type-${documentIndex}`}>
                                                                <div className="statement-month-details">
                                                                    {/* Document Name & I icon */}
                                                                    <span className="statement-month-details"><p className="statement-month-margin">{documentItem.document_option} <span> <a onClick={() => { documentItem?.suggestion && handleShowSuggestionPopupModal(); setSuggestionValue(documentItem?.suggestion); }} className=""><img src={InfoIcon} title={`${documentItem?.suggestion ? messageHelper('help-text') : messageHelper('no-help-text')}`} className='chat-i-icon me-2' /></a> </span></p> </span>
                                                                    {/* Uploaded Icon */}
                                                                    <span className="statement-dtails-info-btn">
                                                                        <div className="icon-blocked-chat-upload cursor-pointer" style={{ background: documentItem?.no_docs ? 'red' : (documentItem?.uploaded ? 'green' : '') }} onClick={() => { isClient && openUploadPopUp(documentItem, item.document_type) }}><i style={{ color: documentItem?.no_docs ? 'white' : (documentItem?.uploaded ? 'white' : '') }} className={documentItem?.no_docs ? 'fa-solid fa-xmark white' : (documentItem?.uploaded ? 'fa-solid fa-check white' : 'fa-solid fa-ellipsis')}></i>
                                                                        </div>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </Col>
                                    ))}
                                </Row>
                            }
                        </div>
                    </div>
                </div>
            </>
        )
    }

    // on click Login Button
    const clickOnLoginButton = () => {
        localStorage.setItem('linkCaseId', caseId)
        localStorage.setItem('linkCaseName', caseName)
        history.push("/login")
    }

    // Login Popup
    const LoginPopupModal = () => {
        return (
            <Modal show={showLoginPopup} onHide={handleCloseLoginPopup} size="sm" className={'e-sign-model-body-default'} centered>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body >
                    <div className="app" style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ margin: 10, alignSelf: 'center', fontSize: isMiniScreen ? 14 : 17 }}>
                            {loginPopupTitle}
                        </div>
                        <Button onClick={() => { clickOnLoginButton() }} style={{ margin: 20, alignSelf: 'center', borderRadius: 20, borderColor: '#0184FE', borderWidth: 0, backgroundColor: '#0184FE', width: 100, paddingTop: 5, paddingBottom: 5 }}>
                            Login
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }

    // Start case formally
    const handleStartCaseFormally = () => {
        setStartCaseFormally(false)
        updateChatUserMetaStartCaseFormallyAPI(userId, caseId)
    }

    // Variable for confirm box
    const [openConfirmBox, setOpenConfirmBox] = useState(false)
    const [confirmBoxValue, setConfirmBoxValue] = useState(false)
    const handleConfirmBox = () => setOpenConfirmBox(!openConfirmBox)
    // useEffect for confirm box value
    useEffect(() => {
        if (confirmBoxValue) {
            handleConfirmBox()
            handleCaseCompleted()
        }
    }, [confirmBoxValue])

    // Mars As Completed
    const handleCaseCompleted = () => {
        setConfirmBoxValue(false)
        setCaseCurrentStatus(true)
        setCaseId(null)
        updateChatCaseCompletedStatus(caseId)
    }

    // Chat Header
    const ChatProfileHeader = () => (
        <div className={isUserLogin ? 'top-chat-header top-chat-header-login' : 'top-chat-header'}>
            <div className='chat-header-profile'>

                <div className={`back-to-chat-list-button ${isMiniScreen ? 'd-block' : 'd-none'} `}>
                    <i className="fa-solid fa-arrow-left" onClick={() => { setCaseId(null) }}></i>
                </div>
                <div className={'ms-2 me-2'}>
                    <ProfileIconsModal userDetails={{ profile_path: consultantProfile, first_name: consultantName }} />
                </div>
                <div className="profile-name-heading-text">
                    <h6 className='profile-name text-capital-chat  chat-profile-header-in-phone chat-custome-header-width'>{caseName}</h6>
                </div>
            </div>
            {/* chat header  menu button */}
            {!(roleIdNull) ?
                <div className='d-flex chat-header-menu-button'>
                    {!isMiniScreen && [2, 4].includes(userRoleId) && (!caseCurrentStatus) &&
                        <Button onClick={handleConfirmBox} className="edit-action-button mark-as-completed-button" style={{ fontSize: 13 }}>
                            Mark As Completed
                        </Button>
                    }
                    <Dropdown as={ButtonGroup} drop='down' alignRight>
                        <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
                            <div className="icon icon-sm">
                                <div className="edit-action-button">Action</div>
                            </div>
                        </Dropdown.Toggle>
                        {/* If case Rejected by agent, so dont show actions */}
                        {!(isChatEnabledToUser || isArchivedList) &&
                            <Dropdown.Menu>

                                <Dropdown.Item onClick={() => { history.push('/cases/case-details', { userId: userId, caseId: caseId, caseName: CapitalizeAllWords(caseName) }) }}>
                                    <img className="me-2 edit-case-button" src={chatEditProfileIcon} />Edit Case
                                </Dropdown.Item>

                                {
                                    roleIdThree &&
                                    <Dropdown.Item onClick={() => onClickNeededDocument()}>
                                        <img className="me-2 edit-case-button" src={chatUploadDocumentIcon} /> Upload Document
                                    </Dropdown.Item>
                                }

                                {  //Only for consutant
                                    userRoleId == 2 && startCaseFormally &&
                                    <Dropdown.Item onClick={handleStartCaseFormally}>
                                        <img className="edit-case-button start-icon-size" src={startIcon} /> Start Case Formally
                                    </Dropdown.Item>
                                }

                                {  //Only for agents
                                    isMiniScreen && [2, 4].includes(userRoleId) && (!caseCurrentStatus) &&
                                    <Dropdown.Item onClick={handleConfirmBox}>
                                        <img className="edit-case-button start-icon-size" src={startIcon} /> Mark As Completed
                                    </Dropdown.Item>
                                }

                            </Dropdown.Menu>
                        }
                    </Dropdown>
                </div>
                : null}
        </div>
    )

    // useEffect for filter first name and last name value from chatHistoryArray
    useEffect(() => {
        const data = chatHistoryArray[chatHistoryArrayLenght]?.message
        if (data && data[0]?.name == "first_name") {
            setFormValue({ first_name: data[0]?.value, last_name: data[1]?.value })
        }
    }, [chatHistoryArrayLenght])

    // Change Email or Number Function
    const changeEmailOrNumber = (question_id, otpType) => {
        setFormValue({})
        socket.emit('changeEmailOrPhoneNumber', userId, caseId, question_id, otpType)
    }

    return (
        <div className='airmigrate-chat-section-block' key={'airmigrate-chat-section-block'}>
            <div className="airmigrate-block-section">
                <a id="download-doc" target='_blank'> </a>
                {/* Welcome Screen */}
                {(defultWelcomeScreen && !isMiniScreen) ?
                    (<div className='default-chat-bg'>
                        <h3>Welcome to</h3>
                        <img src={logo} alt="AirMigrate Logo" width="40%" />
                    </div>)
                    : (showDocumentUploadScreen ? <DocumentUploadScreen /> :
                        <>
                            {/* Message Input Box */}
                            { !caseCurrentStatus &&
                                <div className={`chat-input-box ${isMiniScreen ? 'chat-input-box-position' : ''}`}>
                                    <input type="text" className='chat-message-box'
                                        value={message}
                                        onChange={(e) => { setMessage(e.target.value) }}
                                        onKeyDown={handleKeyPress}
                                        disabled={isChatEnabledToUser || isArchivedList}
                                        style={{ cursor: `${(isChatEnabledToUser || isArchivedList) ? 'not-allowed' : ''}` }}
                                        autoFocus={true}
                                    />
                                    <button type='submit' className='chat-send-button'
                                        disabled={isChatEnabledToUser || isArchivedList}
                                        style={{ cursor: `${(isChatEnabledToUser || isArchivedList) ? 'not-allowed' : ''}` }}
                                    >
                                        <span onClick={sendMessageHandler}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="22" viewBox="0 0 24 22" fill="none">
                                                <path d="M0 21.2853L24 10.9996L0 0.713867V8.71387L17.1429 10.9996L0 13.2853V21.2853Z" fill="#47C7F4" />
                                            </svg>
                                        </span>
                                    </button>
                                </div>}

                            <div className={isUserLogin ? 'airmigrate-block-inner airmigrate-block-inner-login' : 'airmigrate-block-inner'}>

                                {!isPopupChat && <ChatProfileHeader key={'chat-header'} />}
                                {/* Chat Section */}
                                <div className='chatbox-padding-bottom' ref={messagesEndRef}>
                                    {chatHistoryArray.map((item, index) => {
                                        return (
                                            <div key={`chat-history-${index}`}>
                                                {/* Right Side Messages */}
                                                {
                                                    item.from_user_id == userId && (item.message[0]?.name != 'chat-status') &&
                                                    <>
                                                        <div className='message-send-block-info'>

                                                            <div className="message-send-block">
                                                                <div className="user-dtails-info message-send-right">
                                                                    <div className="user-name-text-block">
                                                                        <ProfileIconsModal userDetails={{ profile_path: item?.profile_path, first_name: item?.from_user_name }} />
                                                                    </div>

                                                                    <span className="user-name-info-message text-capital-chat">{item.from_user_name}</span>
                                                                </div>
                                                                <div className="message-send-content-block">
                                                                    <p>{item?.message[0] && (['payment-terms', 'html', 'chat-status', 'upload-document', 'eSign'].includes(item.message[0]?.name)) ? '' : item.message[0].message}</p>

                                                                    { //HTML
                                                                        item.message[0]?.name == 'html' &&
                                                                        <p className="myClassFIrst" dangerouslySetInnerHTML={{ __html: item.message[0].message }}>{ }</p>
                                                                    }

                                                                    {  //ESign Message(Waiting, Download )
                                                                        item.message[0]?.name == 'eSign' &&
                                                                        <p >{item?.message[0]?.is_completed ? 'This is your E-Signed document' : (!item?.message[0]?.signed_by_client ? 'Please wait - Agreement is being signed by the customer' : item.message[0]?.message)}</p>
                                                                    }

                                                                    {  //E-Signed Document Download Button (agent)
                                                                        item.message[0] && item.message[0]?.name == 'eSign' &&

                                                                        (item.message[0]?.is_completed ?
                                                                            // Download ESign Document
                                                                            <Button
                                                                                onClick={() => { downloadESignDocument(item.message[0]?.submission_id, item.message[0]?.user_uuid) }}
                                                                                style={{ borderRadius: 20, borderColor: '#0184FE', borderWidth: 0, marginTop: 15, backgroundColor: '#fff', color: '#0184FE' }}>
                                                                                {downloadLoader ? 'Please wait ...' : 'Download'}
                                                                            </Button>
                                                                            :
                                                                            ((index == chatHistoryArrayLenght || index == chatHistoryArrayLenght - 1) && item.message[0]?.signed_by_client &&
                                                                                <Button onClick={() => (
                                                                                    item.message[0]?.signed_by_client ?
                                                                                        (setShowESignPopup(true),
                                                                                            setESignValueArray([item.message[0]?.slug, item?.chat_id, index]))
                                                                                        : console.log('HELLO')
                                                                                )} style={{ borderRadius: 20, borderColor: '#fff', borderWidth: 0, backgroundColor: '#fff', color: '#0184FE', marginTop: 15, Width: 200, fontSize: 14 }}> View & E-Sign Document</Button>
                                                                            )
                                                                        )
                                                                    }
                                                                    {  //Document Needed Button (Document count) for agent
                                                                        item.message[0] && item.message[0].name == 'user-document' &&
                                                                        caseDocumentCountObject.map((docItem, docIndex) => {
                                                                            if (docItem?.user_uuid == item.message[0]?.data?.user_uuid) {
                                                                                return (
                                                                                    <Button
                                                                                        key={`chat-doc-key ${docIndex}`}
                                                                                        onClick={() => onClickNeededDocument(docItem?.user_uuid)}
                                                                                        style={{ display: 'flex', borderRadius: 20, borderColor: '#0184FE', borderWidth: 0, backgroundColor: '#fff', marginTop: 15, justifyContent: 'space-around', color: '#0184FE', fontSize: isPopupChat ? 14 : 16 }}>
                                                                                        <div>Document Needed</div>
                                                                                        <div style={{ paddingLeft: 2 }}>{`${docItem?.uploaded_doc_count}/${docItem?.total_doc_count}`}</div>
                                                                                    </Button>
                                                                                )
                                                                            }
                                                                        })
                                                                    }
                                                                    { //Payment Terms
                                                                        item?.message[0] && item.message[0].name == 'payment-terms' &&
                                                                        <div className="text-center">
                                                                            <div className="my-3 text-center payment-terms-padding"><p>Here are your payment terms: </p></div>
                                                                            <h4 className="payment-temps-heading text-white">Total Payment: ${item.message[0]?.total_amount}</h4>

                                                                            {
                                                                                item.message[0].data.map((paymentItem, paymentIndex) => (
                                                                                    <div className="row" key={`payment-terms-${paymentIndex}`}>
                                                                                        <div className={`${isMiniScreen ? 'col-8' : 'offset-1 col-5'}   f-16`}>
                                                                                            <div className="row">
                                                                                                <span className="chat-payment-text col-9 text-white">{paymentItem.label_text}</span><span className="chat-payment-amount col-3 text-white">:</span>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className={`chat-payment-amount  text-white ${isMiniScreen ? 'col-4' : 'col-4'}   f-16`}>${paymentItem.payment_amount}</div>
                                                                                        {!isMiniScreen &&
                                                                                            <div className={`col-1`}></div>
                                                                                        }
                                                                                    </div>
                                                                                ))
                                                                            }
                                                                            <p className="my-3 text-center pt-4">Includes taxes, service fees.</p>

                                                                        </div>
                                                                    }
                                                                    {  //Download Document (Uploaded Document)
                                                                        item.message[0] && item.message[0].name == 'upload-document' &&
                                                                        <div>
                                                                            <p>Document has been uploaded<br></br>{item.message[0].message}</p>
                                                                            <img src={ViewIconWhite} title="View" onClick={() => { viewDocumentFuntion(item) }} className='chat-download-icon me-2' />
                                                                            <img src={DownloadIconWhite} title="Download" onClick={() => { downloadDocumentById(item) }} className='chat-download-icon' />
                                                                        </div>
                                                                    }
                                                                </div>
                                                                <div className="messege-send-time-info">{CommonServices.getDateTimeByTimestampForChat(item.chat_timestamp)}</div>
                                                            </div>
                                                        </div>
                                                    </>
                                                }

                                                {/* Left Side Messages*/}
                                                {
                                                    item.from_user_id != userId && (item.message[0]?.name != 'chat-status') &&
                                                    <div className='message-receive-info-block'>
                                                        <div className={item.is_form && chatHistoryArrayLenght == index ? "message-receive-custom-form  message-receive-block" : 'message-receive-block'}>
                                                            <div className="user-dtails-info">
                                                                <div className="user-name-text-block">

                                                                    <ProfileIconsModal userDetails={{ profile_path: item?.profile_path, first_name: item?.from_user_name }} />
                                                                </div>
                                                                <span className="user-name-info-message text-capital-chat">{item.from_user_name}</span>
                                                            </div>
                                                            <div className="user-massege-block">
                                                                {item.is_form && chatHistoryArrayLenght == index ?

                                                                    item.message.map((messageItem, messageIndex) => {

                                                                        return (
                                                                            <div className="" key={messageIndex + "_message"}>
                                                                                {/* Main Message with form */}
                                                                                {(!['payment-terms', 'eSign'].includes(messageItem.type)) && <p className='chat-form-p'>{messageItem.message}
                                                                                    { //Change Email or Phone Case 
                                                                                        messageItem.type == 'otp' &&
                                                                                        <a onClick={() => changeEmailOrNumber(item.message[0]?.question_id, item.message[0]?.name)} className="chat-change-number-or-mail-button">change {item.message[0]?.name == 'phone_verify_otp' ? 'number' : 'email'}</a>
                                                                                    }
                                                                                </p>}
                                                                                {/* OTP Section */}
                                                                                {messageItem.type == 'otp' &&
                                                                                    <div className='m-4 chat-otp-block otp-input-fields'>
                                                                                        <OtpInput className='chat-otp-input-field '
                                                                                            onChange={(e) => { handleInputChange(messageItem.name, e) }}
                                                                                            name={chatHistoryArrayLenght == index ? messageItem.name : ''}
                                                                                            value={chatHistoryArrayLenght == index ? formValue[messageItem.name] : ''}
                                                                                            inputStyle="main-chat-otp-input"
                                                                                            numInputs={4}
                                                                                            inputType="number"
                                                                                            separator={<span></span>}
                                                                                            renderInput={(props) => <input {...props} />}
                                                                                        />
                                                                                    </div>
                                                                                }
                                                                                {
                                                                                    //Condition added for broker module
                                                                                    (messageItem.type === 'radio' && messageItem.name === "accept_reject_user") &&
                                                                                    <>

                                                                                        <div className="custome-submit-button custom-button-block">
                                                                                            <Button
                                                                                                className="submit-button"
                                                                                                color="#fff"
                                                                                                onClick={() => handleSendAQeuryRequest(true, item)}
                                                                                                disabled={isButtonDisable}
                                                                                            >
                                                                                                Accept
                                                                                            </Button>
                                                                                            <Button
                                                                                                className="submit-button"
                                                                                                color="#FA5252"
                                                                                                onClick={() => handleSendAQeuryRequest(false, item)}
                                                                                                disabled={isButtonDisable}
                                                                                            >
                                                                                                Reject
                                                                                            </Button>
                                                                                        </div>

                                                                                    </>
                                                                                }
                                                                                {  //ESign Waiting Or Completed Message
                                                                                    item.message[0]?.name == 'eSign' &&
                                                                                    <p >{item?.message[0]?.is_completed ? 'This is your E-Signed document' : (item?.message[0]?.signed_by_client ? 'Thanks - please wait for consultant to sign the document.' : item.message[0]?.message)}</p>
                                                                                }

                                                                                {messageItem.type == 'eSign' && (!messageItem.signed_by_client) &&
                                                                                    <>
                                                                                        <Button onClick={() => (
                                                                                            (!item.message[0]?.signed_by_client) ?
                                                                                                (setShowESignPopup(true),
                                                                                                    setESignValueArray([item.message[0]?.slug, item?.chat_id, index]))
                                                                                                : console.log('Please Wait')
                                                                                        )} style={{ borderRadius: 20, borderColor: '#0184FE', borderWidth: 0, backgroundColor: '#0184FE', marginTop: 15, Width: 200, fontSize: 14 }}> View & E-Sign Document</Button>
                                                                                    </>
                                                                                }
                                                                                {['number'].includes(messageItem.type) &&
                                                                                    <PhoneInput
                                                                                        country={Default_Country}
                                                                                        preferredCountries={['in', 'ca', 'us']}
                                                                                        autoFormat
                                                                                        containerClass={`mt-4`}
                                                                                        inputClass={`chat-phone-input `}
                                                                                        buttonClass={'country-dropdown'}
                                                                                        dropdownClass={'country-dropdown-container'}
                                                                                        enableSearch
                                                                                        countryCodeEditable={false}
                                                                                        onChange={handleContryAndPhoneChange}
                                                                                        placeholder="Phone Number"
                                                                                        autocompleteSearch={true}
                                                                                        inputProps={{
                                                                                            name: 'phone',
                                                                                            required: true,
                                                                                        }}

                                                                                    />
                                                                                }
                                                                                {['text', 'email'].includes(messageItem.type) &&
                                                                                    <Input
                                                                                        className="form-control custom-input chat-form-input"
                                                                                        placeholder={messageItem.placeholder}
                                                                                        // name={chatHistoryArrayLenght == index ? messageItem.name : ''}
                                                                                        // value={chatHistoryArrayLenght == index ? formValue[messageItem.name] : ''}
                                                                                        // type="text"
                                                                                        name={messageItem.name}
                                                                                        value={formValue[messageItem.name]}
                                                                                        onChange={(e) => { handleInputChange(messageItem.name, e.target.value) }}
                                                                                    />
                                                                                }
                                                                                { //Payment Terms
                                                                                    messageItem.type == 'payment-terms' &&
                                                                                    <div className="text-center">
                                                                                        <div className="my-3 text-center payment-terms-padding"><p>Here are your payment terms: </p></div>
                                                                                        <h4 className="payment-temps-heading">Total Payment: ${item.message[0]?.total_amount}</h4>
                                                                                        {
                                                                                            item.message[0]?.data.map((paymentItem, paymentIndex) => (
                                                                                                <div className="row" key={`payment-terms-index${paymentIndex}`}>
                                                                                                    <div className={`${isMiniScreen ? 'col-8' : 'offset-1 col-5'}   f-16`}>
                                                                                                        <div className="row">
                                                                                                            <span className="chat-payment-text col-9">{paymentItem.label_text}</span><span className="chat-payment-amount col-3">:</span>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                    <div className={`chat-payment-amount  ${isMiniScreen ? 'col-4' : 'col-4'}   f-16`}>${paymentItem.payment_amount}</div>
                                                                                                    {!isMiniScreen &&
                                                                                                        <div className={`col-1`}></div>
                                                                                                    }
                                                                                                </div>
                                                                                            ))
                                                                                        }
                                                                                        <p className="my-3 text-center pt-4">Includes taxes and service fees.</p>
                                                                                        {
                                                                                            item.message[0] && (!item.message[0].isProceed) &&
                                                                                            <button onClick={() => { clickPaymentTermsButton(item.case_id, item.chat_id, index) }} className="payment-proceed-btn">Proceed</button>
                                                                                        }
                                                                                    </div>
                                                                                }
                                                                                {
                                                                                    //Case Invitation Accept/Reject Buttons for User
                                                                                    messageItem.name == "case-invitation" &&
                                                                                    <div className="custome-submit-button custom-button-block">
                                                                                        <Button
                                                                                            className="submit-button"
                                                                                            color="#fff"
                                                                                            onClick={() => handleCaseInvitationRequest(true, item)}
                                                                                            disabled={isButtonDisable}
                                                                                        >
                                                                                            Accept
                                                                                        </Button>
                                                                                        <Button
                                                                                            className="submit-button"
                                                                                            color="#FA5252"
                                                                                            onClick={() => handleCaseInvitationRequest(false, item)}
                                                                                            disabled={isButtonDisable}
                                                                                        >
                                                                                            Reject
                                                                                        </Button>
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        )
                                                                    })
                                                                    :
                                                                    <>
                                                                        {/* Main Message*/}
                                                                        <p>{item.message[0] && (['payment-terms', 'html', 'chat-status', 'upload-document', 'eSign'].includes(item.message[0]?.name)) ? '' : item.message[0].message}</p>

                                                                        {  //HTML
                                                                            item.message[0]?.name == 'html' &&
                                                                            <p className="myClassFIrst" dangerouslySetInnerHTML={{ __html: item.message[0].message }}>{ }</p>
                                                                        }

                                                                        {  //ESign Waiting Or Completed Message
                                                                            item.message[0]?.name == 'eSign' &&
                                                                            <p >{item?.message[0]?.is_completed ? 'This is your E-Signed document' : (item?.message[0]?.signed_by_client ? 'Thanks - please wait for consultant to sign the document.' : item.message[0]?.message)}</p>
                                                                        }

                                                                        {  //E-Signed Document Download Button (User)
                                                                            item.message[0] && item.message[0]?.name == 'eSign' && item.message[0]?.is_completed &&
                                                                            <Button
                                                                                onClick={() => { downloadESignDocument(item.message[0].submission_id, item.message[0]?.user_uuid) }}
                                                                                style={{ borderRadius: 20, borderColor: '#0184FE', borderWidth: 0, backgroundColor: '#0184FE', marginTop: 15 }}>
                                                                                {downloadLoader ? 'Please wait ...' : 'Download'}
                                                                            </Button>
                                                                        }
                                                                        { //Payment Terms
                                                                            item.message[0] && item.message[0].name == 'payment-terms' &&
                                                                            <div className="text-center">
                                                                                <div className="my-3 text-center payment-terms-padding"><p>Here are your payment terms: </p></div>
                                                                                <h4 className="payment-temps-heading">Total Payment: ${item.message[0]?.total_amount}</h4>
                                                                                {
                                                                                    item.message[0].data.map((paymentItem, paymentIndex) => (
                                                                                        <div className="row" key={`payment-terms-index${paymentIndex}`}>
                                                                                            <div className={`${isMiniScreen ? 'col-8' : 'offset-1 col-5'}   f-16`}>
                                                                                                <div className="row">
                                                                                                    <span className="chat-payment-text col-9">{paymentItem.label_text}</span><span className="chat-payment-amount col-3">:</span>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className={`chat-payment-amount  ${isMiniScreen ? 'col-4' : 'col-4'}   f-16`}>${paymentItem.payment_amount}</div>
                                                                                            {!isMiniScreen &&
                                                                                                <div className={`col-1`}></div>
                                                                                            }
                                                                                        </div>
                                                                                    ))
                                                                                }
                                                                                <p className="my-3 text-center pt-4">Includes taxes and service fees.</p>
                                                                                {/* {
                                                                                    item.message[0] && (!item.message[0].isProceed) &&
                                                                                    <button onClick={() => { clickPaymentTermsButton(item.case_id, item.chat_id, index) }} className="payment-proceed-btn">Proceed</button>
                                                                                } */}
                                                                            </div>
                                                                        }
                                                                        {  //Document Needed Button (Document count)
                                                                            item.message[0] && item.message[0].name == 'user-document' &&
                                                                            caseDocumentCountObject.map((docItem, docIndex) => {
                                                                                if (docItem?.user_uuid == item.message[0]?.data?.user_uuid) {
                                                                                    return (
                                                                                        <Button
                                                                                            key={`chat-doc-key ${docIndex}`}
                                                                                            onClick={() => onClickNeededDocument(docItem?.user_uuid)}
                                                                                            style={{ display: 'flex', borderRadius: 20, borderColor: '#0184FE', borderWidth: 0, backgroundColor: '#0184FE', marginTop: 15, justifyContent: 'space-around', fontSize: isPopupChat ? 14 : 16 }}>
                                                                                            <div>Document Needed</div>
                                                                                            <div style={{ paddingLeft: 2 }}>{`${docItem?.uploaded_doc_count}/${docItem?.total_doc_count}`}</div>
                                                                                        </Button>
                                                                                    )
                                                                                }
                                                                            })
                                                                        }
                                                                        {  //Download Document (Uploaded Document)
                                                                            item.message[0] && item.message[0].name == 'upload-document' &&
                                                                            // <Button className="download-button"
                                                                            //     onClick={() => { downloadDocumentById(item) }}
                                                                            //     style={{ borderRadius: 20, borderColor: '#0184FE', backgroundColor: '#0184FE', marginTop: 15, paddingLeft: 25, paddingRight: 25, minWidth: 150, maxWidth: 150 }}>
                                                                            //     <div>Download1</div>
                                                                            // </Button>
                                                                            <div>
                                                                                <p>Document has been uploaded<br></br>{item.message[0].message}</p>
                                                                                <img src={ViewIcon} title="View" onClick={() => { viewDocumentFuntion(item) }} className='chat-download-icon me-2' />
                                                                                <img src={DownloadIcon} title="Download" onClick={() => { downloadDocumentById(item) }} className='chat-download-icon' />
                                                                            </div>

                                                                        }
                                                                    </>
                                                                }
                                                                { //Submit Button
                                                                    item?.is_form && ['accept_reject_user', 'payment-terms', 'case-invitation'].includes(item.message[0].name) ? null :
                                                                        (item.is_form && chatHistoryArrayLenght == index && item.message[0].type != 'eSign') &&
                                                                        <div className="custome-submit-button">

                                                                            <Button className="submit-button" color="#fff" onClick={() => handleFormSubmit(item.message.length, item?.chat_id)}>
                                                                                Submit
                                                                            </Button>
                                                                        </div>
                                                                }
                                                            </div>
                                                            <div className="messege-receive-time-info">{CommonServices.getDateTimeByTimestampForChat(item.chat_timestamp)}</div>
                                                        </div>
                                                    </div>
                                                }

                                                {/* Case Status UI */}
                                                {(item.message[0]?.name == 'chat-status') &&
                                                    <div className="chat-status-background">
                                                        <div className="chat-status-background1">{item.message[0]?.message}</div>
                                                    </div>
                                                }
                                            </div >
                                        )
                                    })}
                                </div>
                            </div>
                        </>)
                }
            </div>
            {   //toaster
                !isPopupChat &&
                <Toaster position="top-right" reverseOrder={false} />
            }

            {/* Popups */}
            <LoginPopupModal />
            <UploadPopupService />
            <DocumentSuggestionModal />

            {/* Client and Agent ESign Modal */}
            {
                showESignPopup && <ESignPopupModel />
            }

            {/* Document View Modal */}
            {showViewDocumentModal &&
                <DocumentViewModal show={showViewDocumentModal} handleClose={handleViewDocumentModal} documentId={viewDocumentId} />
            }

            { // Confirm box for Mark as Completed 
                openConfirmBox &&
                <CommonConfirmBox openConfirmBox={openConfirmBox} handleConfirmBox={handleConfirmBox} setConfirmBoxValue={setConfirmBoxValue} />
            }

            <input type="file" onChange={handleFileChange} style={{ display: 'none' }} ref={hiddenFileInput} multiple={true} accept=".png,.jpg,.jpeg,.pdf" />
        </div>
    )

}

export default ChatHistoryComponent;