/*
A common agent actions file , which contains all the api's which will be used in agents module.
*/

import { authApi } from '../utilities/helpers/ApiHelper';

// registration Api for immig. consultant
async function RegistrationConsultantApi(registrationDetails) {

    try {
        const response = await authApi.postAPI('create-consultant', registrationDetails);
        return response;
    }
    catch (e) {
        console.error(e);
    }
}
// registration Api for Broker
async function RegistrationBrokerApi(registrationBrokerDetails) {

    try {
        const response = await authApi.postAPI('create-broker', registrationBrokerDetails);
        return response;
    }
    catch (e) {
        console.error(e);
    }
}

//Get Countries Api
async function GetCountriesApi() {
    let url = 'country-codes';
    const response = await authApi.getAPI(url);
    // console.log("response at country code", response);
    // if (response.status_code === 1) {
        // return response.data;
    // } else {
    //     toast.error(response.message);
    // }
    return response;
}

//Otp Verify Api
async function OtpVerifyApi(otp) {

    try {
        const response = await authApi.postAPI('register-otp-verify', otp);
        // if (response.status_code === 1) {
        //     toast.success(response.message);
        //     return response;
        // } else {
        //     toast.error(response.message);
        // }
        return response;
    }
    catch (e) {
        console.error(e);
    }
}

export { OtpVerifyApi, GetCountriesApi, RegistrationConsultantApi ,RegistrationBrokerApi}