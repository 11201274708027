import React, { Fragment, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import QuestionAnswerRoundedIcon from "@mui/icons-material/QuestionAnswerRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import CalendarTodayRoundedIcon from "@mui/icons-material/CalendarTodayRounded";
import { Toaster } from "react-hot-toast";
import {
  Col,
  Row,
  Card,
  Image,
  OverlayTrigger,
  Tooltip,
  Table,
  Dropdown,

} from "@themesberg/react-bootstrap";
import { useHistory } from "react-router-dom";
import toast from "react-hot-toast";
import {
  Breadcrumb,
  Form,
  InputGroup,
  ButtonGroup,
  Button,
} from "@themesberg/react-bootstrap";
import { faHome, faSearch, faPlus } from "@fortawesome/free-solid-svg-icons";

// import CaseListTable from "../../../components/air-migrate/CaseListTable";
import styles from "../../views/agents/agent-list/Index.module.css";

//Local imports, core styles, vendor styles
import { GetCustomerListApi, DeleteCustomerApi } from "../../actions/CustomerAction.jsx";
import { DataLoading } from "../../components/air-migrate/Loader.jsx";
import {
  Capitalize,
  CapitalizeAllWords,
} from "../../common-components/StringConversions";
import NoDataFound from "../../components/air-migrate/NoDataFound";
import CustomPagination from "../../common-components/CustomPagination";
// import styles from "../../../views/cases/immigration-case-list/Index.css";
// import "../../../assets/global-css/Index.css";
import "../../scss/volt.scss";
import "react-datetime/css/react-datetime.css";
import { profilePath } from "../../config.tsx";
import CommonServices from "../../common-components/CommonServices.jsx";
import Trash from '../../assets/img/icons/Airmigrate icons/16px/Trash.png';
import Eye from '../../assets/img/icons/Airmigrate icons/16px/View.png';
import Group from '../../assets/img/icons/Airmigrate icons/16px/Group.png';
import ProfileIconsModal from "../../components/air-migrate/ProfileIconsModal.jsx";
import CommonAlert from "../../common-components/CommonAlert.jsx";
import { HomepageUrl } from "../../utilities/helpers/Constant.jsx";

const CustomerList = ({ search, isSearchChange }) => {
  //#region States and variables
  const history = useHistory();
  const [customerList, setCustomerList] = useState([]);
  const [copiedClientList, setCopiedClientList] = useState([]);
  const [startingIndex, setStartingIndex] = useState(1);
  const [role, setRole] = useState("");
  const [pageLimit, setPageLimit] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [isToastVisible, setIsToastVisible] = useState(false);

  //pagination relates states
  const [activeItem, setActiveItem] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalClientCount, setTotalClientCount] = useState(0);
  const [count, setCount] = useState(0);
  const [previousActiveItemValue, setPreviousItemActiveValue] = useState(1);
  const [consultantName, setConsultantName] = useState(
    Capitalize(localStorage.getItem("userName"))
  );
  const [selectedRole, setSelectedRole] = useState("All");
  // Login user uuid for chat link
  const [loginUserId, setLoginUserId] = useState("");

  //States to manage mobile ui
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const [singleScreen, setSingleScreen] = useState(false);

  /* Function and const for alert */
  //Alert states
  const [alertsArray, setAlertsArray] = useState([]);
  // const [count, setCount] = useState(0);
  const handleAlert = (id) => {
    let array = [];
    for (let i = 0; i < alertsArray.length; i++) {
      if ((alertsArray[i].id) == id) {
        array = alertsArray.pop();

      }
    }
    setCount(Math.floor(Math.random() * 1000))
  }
  //#endregion

  //#region Styles
  const filterStyle = {
    marginRight: "2px",
    marginLeft: "3px",
  };
  //#endregion

  //#region useEffect

  useEffect(() => {

    let amUserId = localStorage.getItem("amUserId");
    if (amUserId) {
      setLoginUserId(amUserId);
    }
    GetCustomerList();

    //functionality to set collapsible table 
    window.addEventListener('resize', updateDimension);
    updateDimension();

    // removing eventlistener for no furhter effect after 
    return (() => {
      window.removeEventListener('resize', updateDimension);
    })
  }, [searchText, activeItem, selectedRole, count]);

  //#endregion

  const hideChildTable = {
    display: 'none'
  }


  //#region Functions (all document list table body)
  const updateDimension = () => {
    setScreenSize(getCurrentDimension());
    var value = getCurrentDimension();
    // set a variable true when screen width reaches <768px
    if (value.width <= 767) {
      setSingleScreen(true)
    } else {
      setSingleScreen(false)
    }
  }

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight
    }
  }

  const handleTableCollapse = (parentId, childId) => {
    const childElement = document.getElementById(childId);
    const parentElement = document.getElementById(parentId);
    console.log("childElement, parentElement and content", parentId, childId, childElement, parentElement);
    if (parentElement.innerHTML === '+') {
      childElement.style.display = 'table-row';
      parentElement.innerHTML = '-';
    }
    else {
      childElement.style.display = 'none';
      parentElement.innerHTML = '+';
    }
  }


  // console.log("selectedRole", selectedRole);
  const TableRow = ({
    first_name,
    created_at,
    meta_value,
    last_name,
    profile_path,
    role,
    email,
    primary_phone,
    user_uuid,
    status,

  }) => {

    return (
      <tr className="pt-5">
        <td>
          <div className="agent-name-td agent-td">
            <div className="col-auto imageLeftLess custom-user-image">
              {/* <a className="user-avatar img-case-cursor">
                {(profile_path == '' || profile_path == undefined || profile_path == null) ?
                  <div className={` bg-light rounded-circle d-flex justify-content-center align-items-center`} style={{ minHeight: 60, minWidth: 60, width: 60, height: 60 }} >
                    {((first_name != null) && (last_name != null)) ?
                      <p className=" fw-bolder ">{CommonServices.getNameCharByFullName(first_name + ' ' + last_name)}</p> :
                      (first_name != null) ?
                        <p className=" fw-bolder ">{CommonServices.getNameCharByFullName(first_name + ' ' + last_name)}</p> :
                        <p className=" fw-bolder ">U</p>
                    }
                  </div>
                  :
                  <Image src={profile_path} className="rounded-circle" style={{ minHeight: 60, minWidth: 60, width: 60, height: 60 }} />
                }
              </a> */}

              <ProfileIconsModal userDetails={{ first_name: first_name, last_name: last_name, profile_path: profile_path }} />
            </div>
            <div className="case-content-block img-case-cursor ms-2 f-16">


              <h4 className="case-name-content c-lg" >
                {(first_name !== null && last_name != null) ? Capitalize(first_name) + " " + Capitalize(last_name) : first_name !== null ? Capitalize(first_name) : 'User'}
              </h4>
            </div>
          </div>
        </td>
        <td>
          <div className="f-16">
            {((email == null) || (email == '') || (email == undefined)) ?
              <p>Not available</p> :
              <p>{email}</p>
            }
          </div>
        </td>
        <td>
          {((primary_phone == null) || (primary_phone == '') || (primary_phone == undefined)) ?
            <div className=""><p>Not available</p></div> :
            <div className=""><p>{primary_phone}</p></div>
          }
        </td>
        <td>
          {/* <div className={styles.statusbutton}> */}
          <div className="case-name-content f-16"
          // style={{
          //   backgroundColor:
          //     status === 0
          //       ? "#FFB136"
          //       : status === 1
          //         ? "#05A677"
          //         : status === 2
          //           ? "#0184FE"
          //           : status === 3
          //             ? "#FA5252"
          //             : "#0184FE",
          // }}

          >
            {status === 0
              ? "Pending"
              : status === 1
                ? "Active"
                : status === 2
                  ? "Inactive"
                  : status === 3
                    ? "Deleted"
                    : ""}
          </div>
          {/* </div> */}
        </td>
        <td>
          <div className={styles.actionbuttongroup}>
            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
                <span className="icon icon-sm">
                  <Image src={Group} style={{ cursor: "pointer" }} />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-action-show">

                <Dropdown.Item
                  onClick={() => {history.push(`/customer-profile`, { userId: user_uuid, isFromAdmin:true})}}
                >
                  <Image src={Eye} style={{ cursor: "pointer" }} /> View
                </Dropdown.Item>
                {status != 3 &&
                  <Dropdown.Item onClick={() => handleDeletecustomer(user_uuid)}>
                    <Image src={Trash} style={{ cursor: "pointer" }} /> Delete
                  </Dropdown.Item>
                }
              </Dropdown.Menu>
            </Dropdown>
            {/* <div className={styles.chatbtn}>
              <button
                onClick={() => {
                  history.push(`/admin/clients/edit-client`, {
                    userId: user_uuid,
                  });
                }}
              >
                View Details
              </button>
            </div> */}

            {/* <div
              className={styles.viewbtn}
            >
              <button onClick={() => handleDeletecustomer(user_uuid)}>
                Delete
              </button>
            </div> */}
          </div>
        </td>
      </tr>
    );
  };

  const MobileCustomerList = () => {

    return (
      <table className="table">
        <thead className="mobile-thead">
          <tr>
            <th ></th>
            <th className="f-14">Name</th>
            <th className="mobile-table-action-th f-14" >Action</th>
          </tr>
        </thead>
        {
          customerList.map(props => {
            const { first_name, created_at, meta_value, last_name, profile_path, role, email, primary_phone, user_uuid, status, } = props;
            return (
              <tbody key={`client-${user_uuid}`}>
                <tr>
                  <td className=""><span className="plus-icon" id={`expandChildTable-${user_uuid}`}
                    onClick={() => handleTableCollapse(`expandChildTable-${user_uuid}`, `childTableRow-${user_uuid}`)}
                  >+</span></td>
                  <td>
                    <Row className="align-items-center pe-none text-wrap img-block" >
                      <Col className="col-auto imageLeftLess ">

                        <ProfileIconsModal userDetails={props} />
                      </Col>
                      <Col className="ms--2">
                        <h4 className=" mb-0 f-16 c-lg" >
                          {(first_name !== null && last_name != null) ? Capitalize(first_name) + " " + Capitalize(last_name) : first_name !== null ? Capitalize(first_name) : 'User'}
                        </h4>
                        <span className="f-16 c-lg">
                          {email && <>
                            <span className="text-break"
                            >{email}</span><br />
                          </>}
                        </span>
                        {/* <span className="f-16 c-lg">
                          {primary_phone && <>
                            <span className="text-break"
                            >{primary_phone}</span>
                          </>}
                        </span> */}
                      </Col>
                    </Row>
                  </td>
                  <td className="mobile-table-action-td">

                    <Dropdown as={ButtonGroup}>
                      <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
                        <span className="icon icon-sm">
                          <Image className="pe-none" src={Group} />
                        </span>
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dropdown-action-show pe-auto">
                        <Dropdown.Item className="f-16" onClick={() => {history.push(`/customer-profile`, { userId: user_uuid, isFromAdmin:true})}}>
                          <Image className="pe-auto pa-r-5" src={Eye} /> View
                        </Dropdown.Item>
                        {status != 3 &&
                          <Dropdown.Item className="f-16" onClick={() => handleDeletecustomer(user_uuid)}>
                            <Image className="pe-auto pa-r-5" src={Trash} /> Delete
                          </Dropdown.Item>
                        }
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
                <tr id={`childTableRow-${user_uuid}`} style={hideChildTable}>
                  <td colSpan="3">
                    {/* <div className="text-wrap ">
                      <h6 className="f-14 c-dg">Email : {" "}
                        <span className="c-lg f-14">{email ? email : 'Not available'}</span>
                      </h6>
                    </div>*/}
                    <div className="text-wrap ">
                      <h6 className="f-16 c-dg">Phone : {" "}
                        <span className="c-lg f-16">{primary_phone ? primary_phone : 'Not available'}</span>
                      </h6>
                    </div>
                    <div className="text-wrap ">
                      <h6 className="f-16 c-dg">Status : {" "}
                        <span className="f-16 c-lg text-wrap fw-normal">{status === 0 ? "Pending" : status === 1 ? "Active" : status === 2 ? "Inactive"
                          : status === 3 ? "Deleted" : ""}</span>
                      </h6>
                    </div>
                  </td>
                </tr>

              </tbody>
            )
          }
          )
        }

      </table>
    )
  }

  const GetCustomerList = async () => {
    if (((search == '') && (isSearchChange == false)) || (activeItem != previousActiveItemValue)) {
      setIsLoading(true);
    }
    const customer = await GetCustomerListApi(startingIndex, pageLimit, searchText);
    setIsLoading(false);

    const customerData = []
    if (customer?.status_code === 1) {
      const customerData = customer.data[0]?.results
      setCustomerList(customerData)
      // setCopiedClientList(client.data[0]?.results);

      setTotalClientCount(customer.data[0]?.count);
      const pages = Math.ceil(customer.data[0]?.count / pageLimit);
      setTotalPages(pages);

    } else {
      setCustomerList(customerData)
    }

  }


  const DeleteCustomer = async (user_uuid) => {
    try {
      setIsLoading(true);
      const response = await DeleteCustomerApi(user_uuid);
      if (response?.status_code == 1) {
        setAlertsArray([...alertsArray, { id: `admin_customer_table${alertsArray.length}`, type: 'success', message: response?.message }]);
      }
      else {
        setAlertsArray([...alertsArray, { id: `admin_customer_table${alertsArray.length}`, type: 'danger', message: response?.message }]);
      }
      setCount(Math.floor(Math.random() * 1000));
      setIsToastVisible(true);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeletecustomer = (user_uuid) => {
    DeleteCustomer(user_uuid);
    GetCustomerList();
  };


  // console.log("agentrequest", customerList);

  //#endregion
  return (
    <Fragment>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap pt-0 pb-2">
        <div className="d-block  mb-md-0" >
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item onClick={() => history.push(HomepageUrl)}>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Customers</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className={`${singleScreen === false ? 'mt-5' : ''}`}>
          <Button variant="primary" className={`mb-md-4 mb-0 text-wrap ${singleScreen ? 'f-16' : ''}`} onClick={() => history.push("/admin/customer-requests")}>Customer Requests</Button>
        </div>
      </div>

      <div className="table-settings mb-4">
        <Row >
          <Col xs={12} md={12} lg={2} xl={2}>
            <div className="pt-1">
              <h4 className={`${(singleScreen === true) ? 'f-18' : ''}`}>Customers</h4>
            </div>
          </Col>

          <Col className="offset-4"></Col>
          <Col xs={12} sm={12} md={6} lg={5} xl={5}>
            <InputGroup>
              <InputGroup.Text className={styles.caselistSearchBox}>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <Form.Control
                type="text"
                placeholder="Search"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                className={[styles.caselistSearchBox, singleScreen && "f-14"]}
              />
            </InputGroup>
          </Col>

        </Row>
        <Row className="p-0 mt-2">
          <Col lg={12} sm={12} md={12} xs={12} xl={12}>
            {(alertsArray.length > 0) &&
              alertsArray.reverse().map((item, index) => {
                return (
                  <CommonAlert key={index} id={item.id} type={item.type} message={item.message} handleAlert={handleAlert} />
                )
              })
            }
          </Col>
        </Row>
      </div>
      {isLoading === true ? (
        <DataLoading />
      ) : customerList.length == 0 ||
        customerList.length == undefined ||
        customerList.length == null ? (
        <NoDataFound />
      ) : (

        <>
          {singleScreen === false ?
            <Card border="light" className="shadow-sm mb-4 ">
              <Card.Body className="pb-0 custom-table-body">
                <Table className="table-centered custom-table-responsive agent-requests-table  rounded mb-0">
                  <thead className="thead-light border-bottom">
                    <tr>
                      <th className="border-0 c-lg f-14" >
                        Name{" "}
                      </th>
                      <th className="border-0 c-lg f-14" >
                        Email
                      </th>

                      <th className="border-0 c-lg f-14" >
                        Phone
                      </th>
                      <th className="border-0 c-lg f-14" >
                        Status
                      </th>
                      <th
                        className="border-0 action-block-agent-requests c-lg f-14"

                      >
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {customerList.map((customer) => (
                      <TableRow key={`agent-${customer.user_uuid}`} {...customer} />
                    ))}
                  </tbody>
                </Table>
                <div className="pagenation-custom-block">
                  <div className="pagination-msg">
                    {`Showing ${startingIndex} to ${Math.min(startingIndex + pageLimit - 1, totalClientCount)} of ${totalClientCount} entries`}
                  </div>
                  <CustomPagination
                    activeItem={activeItem}
                    setActiveItem={setActiveItem}
                    totalPages={totalPages}
                    setStartingIndex={setStartingIndex}
                    isSingleScreen={false}
                  />
                </div>
              </Card.Body>
            </Card>
            :
            <div className="bg-white">
              <MobileCustomerList />
              <div className="pagenation-custom-block pagination-padding">
                <div className="pagination-msg f-14">
                  {`Showing ${startingIndex} to ${Math.min(startingIndex + pageLimit - 1, totalClientCount)} of ${totalClientCount} entries`}
                </div>
                <CustomPagination isSingleScreen={true} activeItem={activeItem} setActiveItem={setActiveItem} totalPages={totalPages} setStartingIndex={setStartingIndex} />
              </div>

            </div>

          }
        </>

      )}
      <Toaster position="top-right" reverseOrder={false} toastOptions={{
        style: {
          display: isToastVisible ? 'auto' : 'none'
        },
      }} />
    </Fragment>
  );
  //#endregion
};
export default CustomerList;
