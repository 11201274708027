/*
A common agent actions file , which contains all the api's which will be used in agents module.
*/

import { authApi } from '../utilities/helpers/ApiHelper';
import { toast } from 'react-hot-toast';

//#region  Get Api
//#endregion

//Create agent form submition api call
async function CreateClientApi(clientDetails, userId='') {
    // const history = useHistory();
    try {
        var URL = 'client'
        if(userId){
            URL += `/${userId}`
        }
        const response = await authApi.postAPI(URL, clientDetails);
        return response;
    }
    catch (e) {
        toast.error("Something went wrong!", { duration: 4000 });
    }
}

//Update agent form api call
async function UpdateClientApi(clientDetails, id) {

    try {
        const response = await authApi.updateAPI(`client/${id}`, clientDetails);
        if (response.status_code === 1) {
            toast.success(response.message, { duration: 4000 });
        }
        else toast.error("Something went wrong!", { duration: 4000 });
    }
    catch (e) {
        toast.error("Something went wrong!", { duration: 4000 });
    }

}

//Get agent details by id api call
async function GetClientDetailsByIdApi(id) {

    let url = `access-client-personal-details/${id}`;
    try {
        const response = await authApi.getAPI(url);
        if (response.status_code === 1) {
            return response.data;
        } else {
            toast.error(response.message, { duration: 4000, },);
        }
        return response.data;
    }
    catch (e) {
        toast.error("Something went wrong!", { duration: 4000 });
    }
}

async function GetClientListApi(startingIndex, pageLimit, searchText) {

    let url = ``;

    if (startingIndex && pageLimit && searchText) {
        url = `client?start=${startingIndex}&limit=${pageLimit}&search=${searchText}`
    }
    else if (startingIndex && pageLimit) {
        url = `client?start=${startingIndex}&limit=${pageLimit}`
    }
    try {
        const response = await authApi.getAPI(url);
        return response;
    }
    catch (e) {
        console.error(e)
    }
}

//Add client notes api
async function CreateClientNotesApi(clientDetails, id, case_id) {
    let url = `consultant-notes/${id}/${case_id}`;
    try {
        const response = await authApi.postAPI(url, clientDetails, case_id);
        console.log("createnotes", response)
        return response;
    }
    catch (e) {
        toast.error("Something went wrong!", { duration: 4000 });
    }
}

//Get client notes by id api
//Get client notes by id api
async function GetClientNotesByIdApi(id, case_id) {

    let url = `consultant-notes/${id}/${case_id}`;
    try {
        const response = await authApi.getAPI(url);
        console.log("getnotes", response)
        if (response.status_code === 1) {
            return response.data;
        } else {
            toast.error(response.message, { duration: 4000, },);
        }
        return response.data;
    }
    catch (e) {
        toast.error("Something went wrong!", { duration: 4000 });
    }
}

async function GetClientDocumentCountApi(id) {

    let url = `get-client-template-details/${id}`;
    try {
        const response = await authApi.getAPI(url);
        console.log("CVBCVCVCB", response);
        if (response.status_code === 1) {
            return response.data;
        } else {
            toast.error(response.message, { duration: 3000, },);
        }
        return response.data;
    }
    catch (e) {
        toast.error("Something went wrong!", { duration: 3000 });
    }
}

// Edit client notes api 
async function EditClientNotesApi(clientDetails, userId, caseId) {
    let url = `consultant-notes/${userId}/${caseId}`;
    try {
        console.log('model', clientDetails, userId, caseId, url);
        const response = await authApi.updateAPI(url, clientDetails);
        console.log('response for update', response);
        // if (response.status_code === 1) {
        //     toast.success(response.message, { duration: 3000 });
        //     return response.data;
        // }
        // else {
        //     toast.error(response.message, { duration: 3000 });
        // }
        return response;
    }
    catch (e) {
        toast.error("Something went wrong!", { duration: 4000 });
    }
}
// Delete client notes api 

async function DeleteNotesApi(notesDetails, userId, caseId) {
    let url = `consultant-notes/${userId}/${caseId}`;
    try {
        const response = await authApi.deleteAPI(url, notesDetails);
        console.log("deletenotes", response)
        return response;
    }
    catch (e) {
        toast.error("Something went wrong!", { duration: 4000 });
    }
}
//Create Client Info access request
async function CreateClientInfoRequestApi(requestBody, id) {
    let url = `access-client-personal-info-request/${id}`;
    try {
        const response = await authApi.postAPI(url, requestBody);
        return response;
    }
    catch (e) {
        toast.error("Something went wrong!", { duration: 4000 });
    }
}

async function GetCaseListForClientApi(id) {
    let url = `get-client-cases/${id}`;
    try {
        const response = await authApi.getAPI(url);
        if (response.status_code == 1) {
            return response.data;
        }
        else {
            toast.error(response.message, { duration: 3000 });
        }
    }
    catch (e) {
        toast.error("Something went wrong!", { duration: 4000 });
    }
}

async function GetAssociatedMemberListForClientApi(id) {
    let url = `get-client-cases-associated-member/${id}?primary=true`;
    try {
        const response = await authApi.getAPI(url);
        if (response.status_code == 1) {
            return response.data;
        }
        else {
            toast.error(response.message, { duration: 3000 });
        }
    }
    catch (e) {
        toast.error("Something went wrong!", { duration: 4000 });
    }
}
//#region Functions created specifically for broker

//Get client list for broker
async function GetClientListForBrokerApi(startingIndex, pageLimit, searchText) {

    let url = ``;

    if (startingIndex && pageLimit && searchText) {
        url = `broker-clients?start=${startingIndex}&limit=${pageLimit}&search=${searchText}`
    }
    else if (startingIndex && pageLimit) {
        url = `broker-clients?start=${startingIndex}&limit=${pageLimit}`
    }
    try {
        const response = await authApi.getAPI(url);
        // if (response.status_code === 1) {
        //     return response.data;
        // } else {
        //     toast.error(response.message, { duration: 4000, },);
        // }
        return response;
    }
    catch (e) {
        toast.error("Something went wrong!", { duration: 4000 });
    }
}

//Get client details by id for broker
async function GetClientDetailsByIdForBrokerApi(id) {

    let url = `broker-access-client-details/${id}`;
    try {
        const response = await authApi.getAPI(url);
        if (response.status_code === 1) {
            return response.data;
        } else {
            toast.error(response.message, { duration: 4000, },);
        }
        return response.data;
    }
    catch (e) {
        toast.error("Something went wrong!", { duration: 4000 });
    }
}

//Get client documents by id for broker
async function GetClientDocumentsListByIdForBrokerApi(id, caseId) {
    var url = '';
    if (caseId) {
        url = `get-case-documents/${id}/${caseId}`;
    }
    else {
        url = `get-case-documents/${id}?upload=true`;
        // url = `get-case-documents/${id}`;
    }
    try {
        console.log('url at case', url);
        const response = await authApi.getAPI(url);
        console.log('response at case', response);
        if (response.status_code === 1) {
            return response.data;
        } else {
            toast.error(response.message, { duration: 4000, });
        }
        return response.data;
    }
    catch (e) {
        toast.error("Something went wrong!", { duration: 4000 });
    }
}

async function GetClientDocumentsListWithPaginationApi(id, startingIndex, pageLimit, searchText ) {
    let url = ``;

    if (startingIndex && pageLimit && searchText) {
        url = `get-case-documents/${id}?pagination=true&start=${startingIndex}&limit=${pageLimit}&search=${searchText}`
    }
    else if (startingIndex && pageLimit) {
        url = `get-case-documents/${id}?pagination=true&start=${startingIndex}&limit=${pageLimit}`
    }
    try {
        // console.log('url at case', url);
        const response = await authApi.getAPI(url);
        // console.log('response at case', response);
        if (response.status_code === 1) {
            // toast.success(response.message, { duration: 4000, });
            return response.data;
        } else {
            toast.error(response.message, { duration: 4000, });
        }
        return response.data;
    }
    catch (e) {
        toast.error("Something went wrong!", { duration: 4000 });
    }
}

// document list for client api 
async function DocumentsListForClientOnlyApi(startingIndex, pageLimit, searchText ) {
    let url = ``;

    if (startingIndex && pageLimit && searchText) {
        url = `my-all-docs-list?pagination=true&start=${startingIndex}&limit=${pageLimit}&uploaded=true&search=${searchText}`
    }
    else if (startingIndex && pageLimit) {
        url = `my-all-docs-list?pagination=true&start=${startingIndex}&limit=${pageLimit}&uploaded=true`
    }
    try {
        // console.log('url at case', url);
        const response = await authApi.getAPI(url);
        // console.log('response at DocumentsListForClientOnlyApi', response);
        if (response.status_code === 1) {
            return response.data;
        } else {
            toast.error(response.message, { duration: 4000, });
        }
        return response.data;
    }
    catch (e) {
        toast.error("Something went wrong!", { duration: 4000 });
    }
}

// remove client's document access for api call 
async function DeleteUserFromDocumentApi(data) {
    let url = `create-documents-direct-access`;
    try {
        const response = await authApi.deleteAPI(url,data);
        return response;
    }
    catch (e) {
        console.error(e);
    }
}

// Api call to give access of documents by client 
async function CreateDirectAccessToDocuments(data) { 
    let url = `create-documents-direct-access`;
    try {
        // console.log('url at case', url);
        const response = await authApi.postAPI(url,data);
        return response;
    }
    catch (e) {
        console.error(e);
    }
}


// get agent details by case id(For Chat)
const getAgentDetailsByCaseIdAPI = async (caseId, userId) => {
    let URL = `case-agent-details/${caseId}/${userId}`;
    try {
        const response = await authApi.getAPI(URL);
        return response;
    }
    catch (e) {
        toast.error("Something went wrong!", { duration: 4000 });
    }
}
/* news latter api for footer email field*/
const newsLaterEmailApi = async(data)=>{
    console.log("newsLaterEmailApi call in service file",data)
    let URL = 'create-news-letter';
    try {
        const response = await authApi.postAPI(URL,data);
        return response;
    }
    catch (e) {
        console.error("Something went wrong!",e);
    }

}
//#endregion

export {
    CreateClientApi, UpdateClientApi, GetClientDetailsByIdApi, GetClientListApi,
    CreateClientNotesApi, GetClientNotesByIdApi, GetClientDocumentCountApi, GetClientListForBrokerApi,
    GetClientDetailsByIdForBrokerApi, GetClientDocumentsListByIdForBrokerApi, EditClientNotesApi,
    GetClientDocumentsListWithPaginationApi, CreateClientInfoRequestApi, GetCaseListForClientApi, GetAssociatedMemberListForClientApi, DeleteNotesApi,DocumentsListForClientOnlyApi,DeleteUserFromDocumentApi,CreateDirectAccessToDocuments, getAgentDetailsByCaseIdAPI,newsLaterEmailApi
}