// Library import
import React, { useState, Fragment, useEffect, useRef } from 'react';
import { Button } from "@themesberg/react-bootstrap";
import { Col, Row, Container, Tab } from "@themesberg/react-bootstrap";
import toast from "react-hot-toast";
import io from 'socket.io-client'
import Header from "../../layout/Header";

// import css file
import './newChat.css';


// local import
import { SOCKET_SERVER_URL } from "../../config.tsx";
import { getAllChatUsersListAPI } from '../../actions/ChatsActions.jsx';
import { useHistory, useLocation } from "react-router-dom";
import { DataLoading } from '../../components/air-migrate/Loader.jsx';

import CommonServices from '../../common-components/CommonServices.jsx';
import UnleashMessage from '../../common-components/UnleashMessage.jsx';
import messageHelper from '../../utilities/helpers/messageHelper.js';

// csae step info component
import CaseInformationStep from '../../components/air-migrate/CaseInformationStep.jsx';

// Chat Case List(left side)
import ChatCasesListComponent from './ChatCasesListComponent.jsx';

// Chat History(right side)
import ChatHistoryComponent from './ChatHistoryComponent.jsx';

// My chats main component
const MyChatComponents = (props) => {
    const history = useHistory()
    const location = useLocation();
    const searchBoxRef = useRef(null)

    const [userId, setUserId] = useState(location.state?.userId)
    const [caseId, setCaseId] = useState(Number(location.state?.caseId))
    const [caseName, setCaseName] = useState(location.state?.caseName)
    const [userName, setUserName] = useState(location.state?.userName)

    const [consultantProfile, setConsultantProfile] = useState('')
    const [consultantName, setConsultantName] = useState('')

    // const for role Id 3 to show chat list top icons
    const [roleIdThree, setroleIdThree] = useState(false);
    const [roleIdNull, setroleIdNull] = useState(false);

    //For getAllChatUsersList
    const [roleId, setRoleId] = useState(2);
    const [userRoleId, setUserRoleId] = useState(3);

    // Socket IO Instance
    const [socket, setSocket] = useState(null)

    // Set User Verify For Chat 
    const [isUserVerified, setIsUserVerified] = useState(false)

    // Chat List Data
    const [chatUsersArray, setChatUsersArray] = useState([])
    // Chat List Loader
    const [chatListLoader, setChatListLoader] = useState(false)

    const [searchString, setSearchString] = useState("")

    /* Use effect for sending prop to header for login and height change of header */
    const [isUserLogin, setIsUserLogin] = useState(false);

    // # Use for Update Unseen Message Count, Last Message and Time in ChatUsersArray
    const [updateUnseenMessageCount, setUpdateUnseenMessageCount] = useState({})

    /* Getting screen dimensions with updates start */
    /* Setting  variable true when screen size width <= 768px for chat single screen*/
    const [isMiniScreen, setIsMiniScreen] = useState(false)
    const [currentScreenSize, setCurrentScreenSize] = useState(getWindowDimensions());

    /* Check Unleash feature flag for Chat */
    let chatFeatureFlag = CommonServices.GetUnleashFeatureFlag('my-case-chats');

    // Initial Use Effect
    useEffect(() => {
        // Check User ID
        if (userId) {
            setIsUserVerified(true)
        } else {
            history.push('/exmaple/404')
        }

        // Use For Header
        setIsUserLogin(false)
        var userDetails = localStorage.getItem("accessToken");
        if (userDetails) {
            setIsUserLogin(true)
        }

        // For Login User Name, so in Left Hand Top
        let userName = localStorage.getItem('userName')
        if (userName) {
            setUserName(JSON.parse(userName))
        }

        let roleId = localStorage.getItem('roleId')
        roleId = JSON.parse(roleId);
        setUserRoleId(roleId);
        if (roleId === 3) {
            setroleIdThree(true)
        }
        if (roleId === null) {
            setroleIdNull(true)
        }

    }, [])

    // get current window dimenstions
    function getWindowDimensions() {
        return {
            width: window.innerWidth,
            height: window.innerHeight
        }
    }

    // Used when window dimensions change
    useEffect(() => {
        const updateDimension = () => {
            setCurrentScreenSize(getWindowDimensions())
        }
        window.addEventListener('resize', updateDimension);

        // set a variable true when screen width reaches <768px
        if (currentScreenSize.width <= 767) {
            setIsMiniScreen(true)
        } else {
            setIsMiniScreen(false)
        }
        // removing eventlistener for no furhter effect after 
        return (() => {
            window.removeEventListener('resize', updateDimension);
        })
    }, [currentScreenSize])

    // UseEffcet for scorll to top
    useEffect(() => {
        scrollToTop()
    }, [caseId])

    // Scroll User List
    const scrollToTop = () => {
        setTimeout(() => {
            searchBoxRef.current?.scrollIntoView(false)
        }, 0);
    }

    // Socket for Case list
    useEffect(() => {
        // create Socket IO 
        const newSocket = io(SOCKET_SERVER_URL)
        if (userId) {
            newSocket.on('connect', () => {
                newSocket.emit('userJoinChat', userId)
            })

            newSocket.on('disconnect', () => {
                newSocket.emit('leaveRoom', userId)
            })

            // Update Unseen Message Count, Last Message and Time in ChatUsersArray
            newSocket.on('update_unseen_message_count', (updateData) => {
                setUpdateUnseenMessageCount(updateData)
            })
        }
        return () => { newSocket.disconnect(); }

    }, [userId])


    // Filter Consultant Details
    useEffect(() => {
        if (caseId && chatUsersArray.length) {
            filterConsultantDetails()
        }
    }, [caseId, chatUsersArray])

    const filterConsultantDetails = () => {
        const consultantData = chatUsersArray.filter((item) => item.case_id == caseId)
        if (consultantData.length) {
            // Name for Chat Header(Initials)
            const tempDetails = { first_name: consultantData[0].first_name, last_name: consultantData[0].last_name }
            setConsultantName(CommonServices.getUserFullName(tempDetails))
            setConsultantProfile(consultantData[0].profile_path)
        }
    }


    // Update Unseen Message Count, Last Message and Time in ChatUsersArray
    useEffect(() => {
        let indexOfUserId = null
        let newMessageKeys = Object.keys(updateUnseenMessageCount)
        if (newMessageKeys.length) {
            let interactingUserId = updateUnseenMessageCount.id
            const updatedUsers = chatUsersArray.map((user, index) => {
                if (user.case_id == interactingUserId) {
                    indexOfUserId = index
                    let updateData = updateUnseenMessageCount.data
                    // If user Chat is showing, so not update unseen message count
                    if (interactingUserId == caseId || updateData.from_user_id == userId) {
                        // Update in DB
                        updateMessageSeenSocket()
                        updateData.unseen_message_count = null
                    } else {
                        // Update in Array
                        updateData.unseen_message_count = user.unseen_message_count + updateData.unseen_message_count
                    }
                    return { ...user, ...updateData }
                }
                return user
            })
            if (indexOfUserId) {
                // Store the user object
                const objectToMove = updatedUsers[indexOfUserId];

                // Remove the object
                updatedUsers.splice(indexOfUserId, 1);

                // Insert the object at the 1st index
                updatedUsers.splice(0, 0, objectToMove);
            }
            setChatUsersArray(updatedUsers)
        }
    }, [updateUnseenMessageCount])


    // Get all chat users list(Case List)
    const getAllChatUsersList = async (usersRoleId, search, tabId, isArchivedList) => {
        setChatListLoader(true)
        setChatUsersArray([]);
        let role = '';
        if (usersRoleId === 3) {
            role = tabId
        }

        const response = await getAllChatUsersListAPI(userId, search, role, isArchivedList)
        setChatListLoader(false)
        if (response?.status_code == 1) {
            setChatUsersArray(response.data)
        } else {
            setChatUsersArray([])
            const message = response?.message ? response.message : messageHelper('error');
            toast.error(message, { duration: 4000 });
        }
    }


    // Update Message Seen By User in DB
    const updateMessageSeenSocket = () => {
        if (socket && caseId && userId) {
            socket.emit('update_message_seen', userId, caseId)
        }
    }

    // Update Message Seen Count in chatUsersArray
    const updateMessageSeenInUsersChatListArray = (case_id) => {
        const updatedUsers = chatUsersArray.map((user) => {
            if (user.case_id == case_id) {
                return { ...user, unseen_message_count: null }
            }
            return user
        })
        setChatUsersArray(updatedUsers)
    }

    // archived list
    const [isArchivedList, setIsArchivedList] = useState(false)

    // call case list API
    const callCaseListAPI = () => {
        // get user roll id form localstorage
        let currentUserRoleId = localStorage.getItem('roleId')
        currentUserRoleId = JSON.parse(currentUserRoleId);

        getAllChatUsersList(currentUserRoleId, searchString, roleId, isArchivedList);
    }

    // Use Effect for Search 
    useEffect(() => {
        callCaseListAPI()
    }, [searchString, roleId, isArchivedList])

    //Handle on click Archived Cases/Case button
    const handleArchivedCaseList = () => {
        setIsArchivedList(!isArchivedList)
        setCaseId(null)
    }

    return (
        isUserVerified == '' ? <DataLoading /> :
            <Fragment>
                {chatFeatureFlag ?
                    (<><Header isUserLogin={isUserLogin} setIsUserLogin={setIsUserLogin} isheaderInChat={true} /><Container className={isUserLogin ? 'chat-outer-container-block login-chat-top' : 'chat-outer-container-block '}>
                        <Container className='main-chat-container' fluid>
                            <Row className="main-chat-row">
                                {/* Chat Case List(left side) */}
                                <Col lg={4} md={5} className={`outer-chat-list-container ${isMiniScreen ? (!caseId) ? 'd-block' : 'd-none' : 'd-block'}`}>
                                    <Tab.Container defaultActiveKey="visual_design" className=''>
                                        <Row className='p-0 m-0'>
                                            <Col lg={12} className='chat-list-column'>
                                                <div className='chat-list-sticky-top-bar'>
                                                    {
                                                        userRoleId == 2 && !roleIdNull &&
                                                        <Button variant='quaternary' color="default" onClick={() => { history.push('admin/clients/add-client') }} style={{ fontSize: 14, borderRadius: 20, borderColor: '#0184FE', borderWidth: 0, marginTop: 10, paddingTop: 5, paddingBottom: 5, backgroundColor: '#0184FE', marginLeft: 20 }}>
                                                            Add New Client
                                                        </Button>
                                                    }

                                                    <Button variant='quaternary' color="default" onClick={() => { handleArchivedCaseList() }} style={{ fontSize: 14, borderRadius: 20, borderColor: '#0184FE', borderWidth: 0, marginTop: 10, paddingTop: 5, paddingBottom: 5, backgroundColor: '#0184FE', marginLeft: 20 }}>
                                                        {isArchivedList ? 'Cases' : 'Archived Cases'}
                                                    </Button>

                                                    {/* chat search box */}
                                                    <div className='chat-search-box mb-3' ref={searchBoxRef}>
                                                        <div className='chat-inner-block'>
                                                            <i className="fa-solid fa-magnifying-glass chat-search-icon"></i>
                                                            <input type="search" name="search" className='chat-search-input' placeholder='Search case name...' onChange={(event) => { setSearchString(event.target.value); }} autoFocus={true} />
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* inner chat list with details */}
                                                <Tab.Content>
                                                    <Tab.Pane eventKey="visual_design" className={isUserLogin ? "chat-list-container chat-list-container-login" : "chat-list-container"}>
                                                        {<ChatCasesListComponent chatUsersArray={chatUsersArray} setCaseId={setCaseId} setCaseName={setCaseName} userName={userName} updateMessageSeenInUsersChatListArray={updateMessageSeenInUsersChatListArray} isMiniScreen={isMiniScreen} userRoleId={userRoleId} history={history} chatListLoader={chatListLoader} />}
                                                    </Tab.Pane>
                                                </Tab.Content>
                                            </Col>
                                        </Row>
                                    </Tab.Container>
                                </Col>
                                {/* Chat History(right side) */}
                                <Col lg={8} md={7} className={`outer-chat-box  ${isMiniScreen ? (caseId) ? 'd-block' : 'd-none' : 'd-block'} `}>
                                    <div className='main-chat-box'>
                                        <ChatHistoryComponent
                                            userId={userId}
                                            caseId={caseId}
                                            caseName={caseName}
                                            isMiniScreen={isMiniScreen}
                                            roleIdThree={roleIdThree}
                                            setCaseId={setCaseId}
                                            roleIdNull={roleIdNull}
                                            fromUserName={userName}
                                            setFromUserName={setUserName}
                                            userRoleId={userRoleId}
                                            consultantProfile={consultantProfile}
                                            consultantName={consultantName}
                                            isUserLogin={isUserLogin}
                                            isArchivedList={isArchivedList}
                                            setIsArchivedList={setIsArchivedList}
                                            callCaseListAPI={callCaseListAPI}
                                        />

                                        { // Case Step Information
                                            (!isMiniScreen && caseId && !roleIdThree && !roleIdNull) ?
                                                <CaseInformationStep
                                                    verticalView={true}
                                                    userId={userId}
                                                    caseId={caseId}
                                                    userRoleId={userRoleId}
                                                    caseName={caseName}
                                                />
                                                : null
                                        }
                                    </div>

                                </Col>
                            </Row>
                        </Container>
                    </Container></>) : (
                        <UnleashMessage />
                    )
                }
            </Fragment>
    );
};
export default MyChatComponents;