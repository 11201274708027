import React, { useState, useEffect } from "react";
import {
    Card,
    ListGroup,
    ListGroupItem,
    Button,
    ButtonGroup,
    OverlayTrigger,
    Tooltip,
} from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faEye } from "@fortawesome/free-solid-svg-icons";
import styles from "../Index.module.css";
import EsignedDocumentViewModal from "./EsignedDocumentViewModal.jsx";
import { GetEsignedDocumentListApi } from "../../../actions/MyProfileActions.jsx";
import CommonServices from "../../../common-components/CommonServices.jsx";

const EsignedDocumentList = ({ isEditProfile, UserId }) => {
    //#region state conts
    const [userId, setUserId] = useState(UserId ? UserId : localStorage.getItem('amUserId'))
    // Documents list const here
    const [documentList, setDocumentList] = useState();
    // doc id state
    const [currenDocUrl, setCurrenDocUrl] = useState('');
    // consts for document modal here 
    const [showEsignDocModal, setShowEsignDocModal] = useState(false);
    const viewHandleClose = () => setShowEsignDocModal(false);
    const esignViewHandle = (docUrl) => { setShowEsignDocModal(true); setCurrenDocUrl(docUrl) };
    //#endregion state conts
    // #region UseEffect
    useEffect(() => {
        getProfileDocumentList();
    },[])
    //#endregion useEffect

    // #region handeler functions
    // getting asscociated member list here 
    const getProfileDocumentList = async () => {
        const response = await GetEsignedDocumentListApi(userId);
        if (response.status_code === 1) {
            setDocumentList(response.data);
        } else {
            console.error(response.message)
        }
    }

    // function to download document
    const downloadDocument = async (documentURL) => {
        if (documentURL) {
            CommonServices.downloadFileService(documentURL, 'E-Sign Document', 'pdf')
        }
    }
    //#endregion handeler functions

    //#region  render start
    return (
        <Card>
            <Card.Header>
                <div className={styles.documentCardHeader}>
                    <h4 className="f-16 c-lg">E-Signed Agreement</h4>
                </div>
            </Card.Header>
            <Card.Body className="p-2">
                {/* Document View Modal here */}
                {showEsignDocModal &&
                    <EsignedDocumentViewModal show={showEsignDocModal} handleClose={viewHandleClose} docUrl={currenDocUrl} />
                }
                <ListGroup>
                    {documentList && documentList.length > 0 ? (
                        <>
                            {documentList.map((doc, index) => {
                                return (
                                    <ListGroupItem key={index}>
                                        <div
                                            className={`client-block1-details ${styles.listItem} ${styles.docType}`}
                                        >
                                            <p className="f-16">{doc.case_name}</p>
                                            <div className={`${styles.listItem}`}>
                                                <ButtonGroup className={styles.esignActionBtnGrp}>
                                                    <>
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={<Tooltip id="view">View</Tooltip>}
                                                        >
                                                            <Button
                                                                variant="outline-primary"
                                                                onClick={() => esignViewHandle(doc.value)}
                                                            >
                                                                <FontAwesomeIcon icon={faEye} className="" />
                                                            </Button>
                                                        </OverlayTrigger>
                                                        {isEditProfile &&
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={<Tooltip id="upload">Download</Tooltip>}
                                                            >
                                                                <Button
                                                                    variant="outline-primary"
                                                                    onClick={() => { downloadDocument(doc.value) }}
                                                                >
                                                                    <FontAwesomeIcon icon={faDownload} className="" />
                                                                </Button>
                                                            </OverlayTrigger>
                                                        }
                                                    </>
                                                </ButtonGroup>
                                            </div>
                                        </div>
                                    </ListGroupItem>
                                )
                            })
                            }
                        </>) : (<>
                            <h4 className="text-center f-16 c-lg">No Documents Found!</h4>
                        </>)
                    }
                </ListGroup>
            </Card.Body>
        </Card>
    );
};
//#endregion render end
export default EsignedDocumentList;