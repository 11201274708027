import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isVisaUploaded: localStorage.getItem('isVisaUploaded') ? JSON.parse(localStorage.getItem('isVisaUploaded')) : false,
    isVisaApproved: localStorage.getItem('isVisaApproved') ? JSON.parse(localStorage.getItem('isVisaApproved')) : false,
    visaOption: localStorage.getItem('visaOption') ? JSON.parse(localStorage.getItem('visaOption')) : 0,
    userStatus: localStorage.getItem('status') ? JSON.parse(localStorage.getItem('status')) : 0,
    isUserLogin: localStorage.getItem('accessToken') ? true : false,
}

export const VisaUploadSlice = createSlice({
    name: 'visaUploadModule',
    initialState,
    reducers: {
        // Visa upload status
        setIsVisaUploaded: (state, value) => {
            state.isVisaUploaded = value.payload;
        },

        // after upload, check approve status
        setIsVisaApproved: (state, value) => {
            state.isVisaApproved = value.payload;
        },

        // visa option
        setVisaOption: (state, value) => {
            state.visaOption = value.payload;
        },

        // user status
        setUserStatus: (state, value) => {
            state.userStatus = value.payload;
        },

        // user status
        setIsUserLogin: (state, value) => {
            state.isUserLogin = value.payload;
        },

    },
})

// Action creators are generated for each case reducer function
export const { setIsVisaUploaded, setIsVisaApproved, setVisaOption, setUserStatus, setIsUserLogin } = VisaUploadSlice.actions

export default VisaUploadSlice.reducer