import React, { useState, Fragment, useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import { toast } from 'react-hot-toast'
import { Toaster } from 'react-hot-toast';
import { OtpVerifyApi, SignInApi } from '../../actions/LoginAction';
// import { Container } from 'reactstrap';
import OtpInput from 'react-otp-input';
import { useLocation } from 'react-router-dom';
import LogoImage from '../../assets/img/landing-page/logo-img.png';
import { DataLoading } from '../../components/air-migrate/Loader'
import {
  Col,
  Row,
  Card,
  Form,
  Button,
  Container,
  FormGroup,
} from "@themesberg/react-bootstrap";
import './Index.css';
import CommonServices from '../../common-components/CommonServices';
import CommonAlert from '../../common-components/CommonAlert';
import { useDispatch } from 'react-redux';
import { setIsUserLogin, setUserStatus } from '../../reducers/VisaUploadReducer';
import { setMyProfile } from '../../reducers/ProfileImageReducer';

const VerifyOtp = () => {
  const [otp, setOtp] = useState('');
  const [isSubmitting, setSubmitting] = useState(false);
  const [showloader, setShowLoader] = useState(false);
  const [isLOginThroughprimaryphone, setIsLoginThroughprimaryphone] = useState(true);

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch()

  //Alert states and functions
  const [alertsArray, setAlertsArray] = useState([]);
  const [count, setCount] = useState(0);

  useEffect(() => {
    // console.log("vai", location.state)
    // Check if the necessary state data is present
    if (location.state && location.state.phone && location.state.primary_country_code) {
      setIsLoginThroughprimaryphone(true);
    } else {
      // Redirect to an error page or handle the lack of state data
    }
  }, [location.state]);

  const handleAlert = (id) => {
    let array = [];
    for (let i = 0; i < alertsArray.length; i++) {
      if ((alertsArray[i].id) == id) {
        array = alertsArray.pop();
      }
    }
    setCount(Math.floor(Math.random() * 1000))
  }

  const handleOtpVerification = async () => {
    const enteredOtp = otp;
    setSubmitting(true);

    let requestPayload;
    if (isLOginThroughprimaryphone) {
      requestPayload = {
        phone: location.state.phone,
        primary_country_code: location.state.primary_country_code,
        otp: enteredOtp,
      };
    }

    setShowLoader(true);
    const response = await OtpVerifyApi(requestPayload);
    setShowLoader(false);

    if (response?.status_code === 1) {
      let status = response.data[0].status;
      dispatch(setIsUserLogin(true))
      dispatch(setUserStatus(status))
      // set profile
      const profilePath = response.data[0]?.user_details?.profile_path; 
      profilePath?localStorage.setItem("profilePath", JSON.stringify(profilePath)):localStorage.setItem("profilePath", null);
      dispatch(setMyProfile(profilePath));

      localStorage.setItem("status", response.data[0].status);
      localStorage.setItem("accessToken", response.data[0].token);
      localStorage.setItem("roleId", response.data[0].role);
      // First Name + Last Name
      localStorage.setItem("userName", JSON.stringify(response.data[0].user_details.full_name));
      localStorage.removeItem("userDetails");
      localStorage.setItem('amUserId', response.data[0].user_details.user_uuid)
      // toast.success("Thanks for signup we will inform you as soon as your account will be approved ");
      const roleId = response.data[0].role;
      // setTimeout(() => {
        if (status == 1) {
          history.push('/');
        } else {
          history.push('status-pending-page');
        }
      // }, 2000);

      // setTimeout(() => {
      // history.push('/dashboard', {state:true});
      // }, 2000);
      // return response;
    } else {
      setAlertsArray([...alertsArray, { id: `${'otp_verify'}${alertsArray.length}`, type: 'danger', message: response?.message }]);
      console.error(response?.message ? response.message : 'Something Went Wrong, Try Again Later!!')
      // toast.error(response?.message ? response.message : 'Something Went Wrong, Try Again Later!!');
      setShowLoader(false);

    }

    // setOtp(['']);
    setSubmitting(false);
  };

  const resendOTPFunction = async () => {
    setShowLoader(true);
    const response = await SignInApi(location.state);
    setShowLoader(false);
    toast.success(response.message, { duration: 4000 });
    if (response?.status_code == 1) {
      // toast.success(response.message, { duration: 4000 });
      setAlertsArray([...alertsArray, { id: `${'otp_verify'}${alertsArray.length}`, type: 'success', message: response?.message ? response?.message : '' }]);
      // toast.success('OTP Resent: A new code has been successfully sent to your registered mobile number. Please check and enter the new code');
    } else {
      // toast.error(response.message, { duration: 4000 });
      setAlertsArray([...alertsArray, { id: `${'otp_verify'}${alertsArray.length}`, type: 'danger', message: response?.message }]);
      // toast.error('Failed to Resend OTP: We encountered an issue while attempting to resend the code. Please check your network connection or try again later.');
    }
  };

  function handleChange(otp) {
    setOtp(otp);
  }

  let verifyOtpFlag = CommonServices.GetUnleashFeatureFlag('signin-otp');
  return (
    <Fragment>
      <>
        <Container className="login-main-container" fluid>
          <Row className='login-main-row'>
            <Col xs={12} sm={12} md={12} lg={7} className="d-none d-md-flex login-side-text-block">
              <div className="login-side-text">
                <h1 className="login-side-head-text">Airmigrate</h1>
                {/* <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam
                  mollis, dui in tempor dignissim, dolor ligula convallis
                </p>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam
                  mollis, dui in tempor dignissim, dolor ligula convallis
                </p>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam
                  mollis, dui in tempor dignissim, dolor ligula convallis
                </p> */}
                <p>To ensure the security of your account, please enter the OTP sent to your registered phone number or email</p>
              </div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={5} className="login-form-block">
              <div className="main-container flex-column">
                <div className=" alert-container mt-2">
                  {(alertsArray.length > 0) &&
                    alertsArray.reverse().map((item, index) => {
                      return (
                        <div className="text-center">
                          <CommonAlert key={index} id={item.id} type={item.type} message={item.message} handleAlert={handleAlert} />
                        </div>
                      )
                    })
                  }
                </div>
                <div className="inner-container">
                  {/* <div className="otp-container"> */}
                  <div className="px-3 pb-3">
                    <img src={LogoImage} alt="Logo" className="cursor-pointer"  onClick={()=>{history.push('/')}}/>
                  </div>
                  <div className="text-container">
                    <h2 className="heading">Enter Verification Code</h2>
                    <p className="inner-text">
                      Please check your Mail or Message For Received OTP to Verify
                    </p>
                  </div>
                  <form>
                    <div className="otp-input-fields">
                      <OtpInput
                        onChange={handleChange}
                        value={otp}
                        inputStyle="inputStyle"
                        numInputs={4}
                        inputType="number"
                        separator={<span></span>}
                        renderInput={(props) => <input {...props} />}
                        shouldAutoFocus={true}
                      // onKeypress={handleKeyPress}
                      />
                    </div>
                    {verifyOtpFlag &&
                      <div className="lower-container">
                        <button className="verify-btn" disabled={showloader} type='submit' onClick={handleOtpVerification}>
                          {isSubmitting ? 'Verifying...' : 'Verify OTP'}
                        </button>
                        <p disabled={showloader} className="lower-text">Didn’t Receive the OTP ? <span className="resend-text" onClick={resendOTPFunction}>RESEND OTP</span></p>
                      </div>
                    }
                  </form>
                </div>

              </div>

            </Col>
          </Row>
        </Container>
      </>
      {/* <Toaster position="top-right" reverseOrder={false} /> */}
      {showloader === true && <DataLoading />}
    </Fragment>

  );
};

export default VerifyOtp;
