import React, { Fragment, useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown, faTimesCircle, faCrosshairs, faUpload } from "@fortawesome/free-solid-svg-icons";
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import QuestionAnswerRoundedIcon from '@mui/icons-material/QuestionAnswerRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import CalendarTodayRoundedIcon from '@mui/icons-material/CalendarTodayRounded';
import PictureAsPdfRoundedIcon from '@mui/icons-material/PictureAsPdfRounded';
import { Col, Row, Card, Image, OverlayTrigger, Tooltip, Table, Badge, Button, Modal, Form, Dropdown, ButtonGroup } from '@themesberg/react-bootstrap';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { CreateDocumentRequestApi } from "../../../actions/CaseActions.jsx";
import { GetClientDocumentsListWithPaginationApi, DocumentsListForClientnlyApi } from "../../../actions/ClientActions";
import { Toaster } from "react-hot-toast";
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import "react-datetime/css/react-datetime.css";
import DownloadDocument from "../../../common-components/DownloadDocument.jsx";
import Eye from '../../../assets/img/icons/Airmigrate icons/16px/View.png';
import Upload from '../../../assets/img/icons/Airmigrate icons/16px/Upload document.png';
import Accept from '../../../assets/img/icons/Airmigrate icons/16px/Accept.png';
import Rejected from '../../../assets/img/icons/Airmigrate icons/16px/Reject.png';
import Group from '../../../assets/img/icons/Airmigrate icons/16px/Group.png';
import Download from '../../../assets/img/icons/Airmigrate icons/16px/Download document.png';
import Requested from '../../../assets/img/icons/Airmigrate icons/16px/Requested.png';
import RequestAccess from '../../../assets/img/icons/Airmigrate icons/16px/Request access.png';
//Local imports, core styles, vendor styles
import { GetClientListApi } from "../../../actions/ClientActions";
import { DataLoading } from "../../../components/air-migrate/Loader.jsx";
import { Capitalize } from "../../../common-components/StringConversions.jsx";
import NoDataFound from '../../../components/air-migrate/NoDataFound';
import CustomPagination from "../../../common-components/CustomPagination";
import styles from '../../../views/requests/Index.module.css';
import '../../../assets/global-css/Index.css';
import "../../../scss/volt.scss";
import { profilePath } from "../../../config.tsx";
import PictureAsPdfRounded from "@mui/icons-material/PictureAsPdfRounded";
import PdfIcon from "../../../assets/img/allDocumentList/pdfIcon.svg";
import docStyle from "../../../views/view-all-documents/Index.module.css";
import UsersImg from "../../../assets/img/real-state/consultant1.png"
import GiveAccessModal from "../components/GiveAccessModal.jsx";
import tableStyle from './Index.module.css'
import FileUploadModal from "../../../components/air-migrate/FileUploadModal.jsx";
import { GetLinkToViewDocumentApi } from "../../../actions/FileUploadActions.jsx";
import CommonServices from "../../../common-components/CommonServices.jsx";
import { GetLinkToDownloadDocumentWithAlertApi } from "../../../actions/FileUploadActions.jsx";

const DocumentTable = ({ search, userId, isSearchChange, singleScreen, handleAlert, alertsArray, setAlertsArray }) => {

    //#region States and variables
    const history = useHistory();

    const [clientList, setClientList] = useState([]);
    const [documentList, setDocumentList] = useState([]);
    const [copiedClientList, setCopiedClientList] = useState([]);
    const [startingIndex, setStartingIndex] = useState(1);
    const [pageLimit, setPageLimit] = useState(10);
    const [isLoading, setIsLoading] = useState();
    const [tableBody, setTableBody] = useState('')
    //pagination relates states
    const [activeItem, setActiveItem] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalDocumentCount, setTotalDocumentCount] = useState(0);
    const [count, setCount] = useState(0);
    const [consultantName, setConsultantName] = useState(Capitalize(localStorage.getItem('userName')));
    const [userRoleId, setUserRoleId] = useState(localStorage.getItem('roleId'));
    const [previousActiveItemValue, setPreviousItemActiveValue] = useState(1);
    // Login user uuid for chat link
    const [loginUserId, setLoginUserId] = useState('');
    const [showDownload, setShowDownload] = useState(false);
    //#endregion

    //#region useEffect
    useEffect(() => {
        GetDocumentsListById();
    }, [search, count, activeItem])

    //#region style
    const hideChildTable = {
        display: 'none'
    }
    //#endregion

    //#region Functions (all document list table body)
    const handleTableCollapse = (parentId, childId) => {
        const childElement = document.getElementById(childId);
        const parentElement = document.getElementById(parentId);
        console.log("childElement, parentElement and content", parentId, childId, childElement, parentElement);
        if (parentElement.innerHTML === '+') {
            childElement.style.display = 'table-row';
            parentElement.innerHTML = '-';
        }
        else {
            childElement.style.display = 'none';
            parentElement.innerHTML = '+';
        }
    }

    const TableBodyDocumentList = (props) => {
        const { document_option, document_type, id, required, status, uploaded, created_at, size, url } = props;

        return (
            <tr className={docStyle.documentListRow}>
                <td style={{ paddingLeft: 10 }}>
                    <div className={styles.casesprofilecontentblock} >
                        <div>
                            {/* <PictureAsPdfRoundedIcon /> */}
                            <img src={PdfIcon} alt="pdfLogo" height={45} width={45} style={{ minWidth: 45, minHeight: 45, marginRight: 5 }} />
                        </div>
                        <div
                            className={``}
                        >
                            <p className="f-16 c-lg">{Capitalize(document_type)}</p>
                            <small className="f-14" style={{ color: '#B9B9B9' }}>{(size === null || size === undefined || size === '') ? 'Not available' : Number(size).toFixed(3) + ' MB'}</small></div>
                    </div>
                </td>
                <td>
                    <div className={``}>
                        <p className="f-16 fw-normal">{Capitalize(document_option)}</p>
                    </div>
                </td>


                <td>
                    <div className={``}>
                        {created_at.includes("T") ?
                            <p>{created_at?.split('T')[0]}</p> :
                            created_at.includes(" ") &&
                            <p>{created_at?.split(' ')[0]}</p>}
                    </div>
                </td>
                {(userRoleId == 1 || userRoleId == 2 || userRoleId == 4) &&
                    <td>
                        <Dropdown as={ButtonGroup}>
                            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
                                <span className="icon icon-sm">
                                    <Image src={Group} className="pe-auto" />
                                </span>
                            </Dropdown.Toggle>
                            {(status.current_staus == null) ?
                                <Dropdown.Menu className="dropdown-action-show">
                                    <Dropdown.Item className="text-wrap"
                                        onClick={() => CreateDocumentRequest(id)}
                                    >
                                        <Image src={RequestAccess} className="pe-auto space-bw-dr" />
                                        Request Access
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                                :
                                (status.current_staus == 1 && status.url != null) ?

                                    <Dropdown.Menu className="dropdown-action-show">
                                        <Dropdown.Item className="text-wrap" onClick={() => { DownloadDocument(id) }}>
                                            <Image src={Download} className="pe-auto space-bw-dr" /> Download

                                        </Dropdown.Item>
                                    </Dropdown.Menu>

                                    : (status.current_staus == 0) ?
                                        <Dropdown.Menu className="dropdown-action-show ">
                                            <Dropdown.Item className="text-wrap"
                                            >
                                                <Image src={Requested} className="pe-auto space-bw-dr" />
                                                Requested
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                        :
                                        (status.current_staus == 2) ?
                                            <Dropdown.Menu className="dropdown-action-show ">
                                                <Dropdown.Item className="text-wrap"
                                                >
                                                    <Image src={Rejected} className="pe-auto space-bw-dr" />
                                                    Rejected
                                                </Dropdown.Item>
                                            </Dropdown.Menu> :
                                            (status.current_staus == 1 && status.url == null && uploaded == false) &&
                                            <Dropdown.Menu className="dropdown-action-show ">
                                                <Dropdown.Item className="text-wrap space-bw-dr"
                                                >
                                                    <Image src={Requested} className="pe-auto" />
                                                    Requested
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                            }

                        </Dropdown>
                        {(showDownload == true) &&
                            <DownloadDocument docId={id} setIsLoading={setIsLoading} isTable={true} />
                        }
                        {/* <div className={styles.actionbuttongroup}>
                            <div className={styles.chatbtn} style={{ fontSize: 16 }} >
                                {(status.current_staus == null) ?
                                    <button
                                       
                                        onClick={() => CreateDocumentRequest(id)}
                                    >
                                        Request Access
                                    </button>
                                    : (status.current_staus == 1 && status.url != null) ?
                                        <DownloadDocument setIsLoading={setIsLoading} docId={id} setCount={setCount} />
                                        :
                                        (status.current_staus == 0) ?
                                            <button
                                                className='mb-2' style={{ color: "#6d747d", backgroundColor: "#61dafb", borderColor: " #61dafb" }}
                                                onClick={() => { }}
                                            >
                                                Requested
                                            </button> :

                                            (status.current_staus == 2) ?
                                                <button
                                                    className='mb-2' style={{ backgroundColor: '#FA5252', border: 'none', }}
                                                    onClick={() => { }}
                                                >
                                                    Rejected
                                                </button> :
                                                (status.current_staus == 1 && status.url == null && uploaded == false) &&
                                                <button
                                                    style={{ color: "#6d747d", backgroundColor: "#61dafb", borderColor: " #61dafb" }}
                                                    onClick={() => { }}
                                                >
                                                   
                                                    Requested
                                                </button>
                                }


                            </div>
                        </div> */}
                    </td>
                }
            </tr>
        );
    };

    const MobileTableDocumentList = (props) => {
        return (
            <table className="table">
                <thead className="mobile-thead">
                    <tr>
                        <th ></th>
                        <th className="text-wrap f-14">Document Type</th>
                        {userRoleId !== 3 &&
                            <th className="mobile-table-action-th text-wrap f-14" >Action</th>
                            // :
                            // <th className="mobile-table-action-th text-wrap f-14" >Document Title</th>
                        }
                    </tr>
                </thead>
                {
                    documentList.map(props => {
                        const { document_option, document_type, id, required, status, uploaded, created_at, size, url } = props;
                        return (
                            <tbody key={`client-${id}`}>
                                <tr>
                                    <td className=""><span className="plus-icon" id={`expandChildTable-${id}`}
                                        onClick={() => handleTableCollapse(`expandChildTable-${id}`, `childTableRow-${id}`)}
                                    >+</span></td>
                                    <td>
                                        <div className={styles.casesprofilecontentblock} >
                                            <div>
                                                <img src={PdfIcon} alt="pdfLogo" height={45} width={45} style={{ minWidth: 45, minHeight: 45, marginRight: 5 }} />
                                            </div>
                                            <div className={[docStyle.commonTypographyForRow]}>
                                                <p className="c-lg f-16">{document_type ? Capitalize(document_type) : "Not available"}</p>
                                                <small className="f-12" style={{ color: '#B9B9B9' }}>{(size == null || size == undefined || size == '') ? '' : Number(size).toFixed(3) + ' MB'}</small>
                                            </div>
                                        </div>
                                    </td>
                                    {(userRoleId == 1 || userRoleId == 2 || userRoleId == 4) ?
                                        <td className="mobile-table-action-td">
                                            <Dropdown as={ButtonGroup}>
                                                <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
                                                    <span className="icon icon-sm">
                                                        <Image src={Group} style={{ cursor: "pointer" }} />
                                                    </span>
                                                </Dropdown.Toggle>
                                                {(status.current_staus == null) ?
                                                    <Dropdown.Menu className="dropdown-action-show">
                                                        <Dropdown.Item className="f-16 text-wrap"
                                                            onClick={() => CreateDocumentRequest(id)}
                                                        >
                                                            <Image src={RequestAccess} className="pe-auto space-bw-dr" />
                                                            Request Access
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                    :
                                                    (status.current_staus == 1 && status.url != null) ?

                                                        <Dropdown.Menu className="dropdown-action-show">
                                                            <Dropdown.Item className="f-16 text-wrap" onClick={() => { DownloadDocument(id) }}>
                                                                <Image src={Download} className="pe-auto space-bw-dr" /> Download
                                                            </Dropdown.Item>
                                                        </Dropdown.Menu>

                                                        : (status.current_staus == 0) ?
                                                            <Dropdown.Menu className="dropdown-action-show">
                                                                <Dropdown.Item className="f-16 text-wrap"
                                                                >
                                                                    <Image src={Requested} className="pe-auto space-bw-dr" />
                                                                    Requested
                                                                </Dropdown.Item>
                                                            </Dropdown.Menu>
                                                            :
                                                            (status.current_staus == 2) ?
                                                                <Dropdown.Menu className="dropdown-action-show">
                                                                    <Dropdown.Item className="f-16 text-wrap"
                                                                    >
                                                                        <Image src={Rejected} className="pe-auto space-bw-dr" />
                                                                        Rejected
                                                                    </Dropdown.Item>
                                                                </Dropdown.Menu> :
                                                                (status.current_staus == 1 && status.url == null && uploaded == false) &&
                                                                <Dropdown.Menu className="dropdown-action-show">
                                                                    <Dropdown.Item className="f-16 text-wrap"
                                                                    >
                                                                        <Image src={Requested} className="pe-auto space-bw-dr" />
                                                                        Requested
                                                                    </Dropdown.Item>
                                                                </Dropdown.Menu>
                                                }
                                            </Dropdown>
                                        </td> :
                                        <td className="f-16">
                                            {document_option ? Capitalize(document_option) : "Not available"}
                                        </td>
                                    }
                                </tr>
                                <tr id={`childTableRow-${id}`} style={hideChildTable}>
                                    <td colSpan="3">
                                        {/* {userRoleId != 3 && */}
                                        <div className="text-wrap ">
                                            <h6 className="f-16 c-dg text-wrap">Document Title : {" "}
                                                <span className="c-lg f-16">{document_option ? document_option : " Not available"}</span>
                                            </h6>
                                        </div>
                                        {/* } */}
                                        <div className="text-wrap ">
                                            <h6 className="f-16 c-dg text-wrap">Date : {" "}
                                                <span className="c-lg f-16">{created_at.includes(' ') === true ? created_at.split(' ')[0] : created_at.split('T')[0]}</span>
                                            </h6>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        )
                    }
                    )
                }
            </table>
        )
    }

    const CreateDocumentRequest = async (id) => {
        const requestBody = {
            documents_id: id
        }
        const response = await CreateDocumentRequestApi(requestBody);
        if (response?.status_code == 1) {
            setAlertsArray([...alertsArray, { id: `create-document-request${alertsArray.length}`, type: 'success', message: response?.message }]);
        }
        else {
            setAlertsArray([...alertsArray, { id: `create-document-request${alertsArray.length}`, type: 'danger', message: response?.message }]);
        }
        setCount(Math.floor(Math.random() * 1000));
    }

    const GetDocumentsListById = async () => {
        if (((search == '') && (isSearchChange == false)) || (activeItem != previousActiveItemValue)) {
            setIsLoading(true);
        }
        var documentsList;
        documentsList = await GetClientDocumentsListWithPaginationApi(userId, startingIndex, pageLimit, search);
        // console.log("documentsList", documentsList, " count", documentsList[0]?.count, " pageLimit", pageLimit, "documentsList", documentsList);
        setDocumentList(documentsList[0]?.results);


        setTotalDocumentCount(documentsList[0]?.count);
        const pages = Math.ceil(documentsList[0]?.count / pageLimit);
        console.log(' total pages', pages);
        setTotalPages(pages);

        setIsLoading(false);
    }

    const DownloadDocument = async (docId) => {
        let url = `view-document`;
        const response = await GetLinkToDownloadDocumentWithAlertApi(url, docId);
        console.log('download api response', response);
        if (response.status_code == 1) {
            setAlertsArray([...alertsArray, { id: `download-document${alertsArray.length}`, type: 'success', message: response.message }]);
        }
        else {
            setAlertsArray([...alertsArray, { id: `download-document${alertsArray.length}`, type: 'danger', message: response.message }]);
        }
        const downloadUrl = response?.data[0].url;
        let alink = document.createElement("a");
        alink.href = downloadUrl;
        alink.click();
        setCount(Math.floor(Math.random() * 1000));
    }

    //#endregion

    //#region JSX

    return (
        <Fragment>
            {isLoading === true ? <DataLoading /> :
                ((documentList?.length == null) || (documentList?.length == undefined) || (documentList?.length == null) || (documentList?.length === 0)) ?
                    <NoDataFound /> :
                    <>
                        {singleScreen === false ?
                            <Card border="light" className="shadow-sm mb-4 ">
                                <Card.Body className="pb-0">
                                    <Table responsive className="table-centered custom-table-responsive table-nowrap rounded mb-0">
                                        <thead className="thead-light border-bottom custom-table-heading">
                                            <tr>
                                                <th className="border-0  c-lg f-14 " >Document Type</th>
                                                <th className="border-0  c-lg f-14 " >Document Title</th>

                                                <th className="border-0  c-lg f-14 " >Date</th>
                                                {userRoleId != 3 &&
                                                    <th className="border-0  c-lg f-14 " >Action</th>
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {documentList.map(item => (
                                                <TableBodyDocumentList key={item.id} {...item} />
                                            ))
                                            }
                                        </tbody>
                                    </Table>
                                    <CustomPagination activeItem={activeItem} setActiveItem={setActiveItem} totalPages={totalPages} setStartingIndex={setStartingIndex} />
                                </Card.Body>
                            </Card> :
                            <div className="bg-white"> <MobileTableDocumentList />
                                <div className="pagenation-custom-block pagination-padding">
                                    <div className="pagination-msg f-14">
                                        {`Showing ${startingIndex} to ${Math.min(
                                            startingIndex + pageLimit - 1,
                                            totalDocumentCount
                                        )} of ${totalDocumentCount} entries`}
                                    </div>
                                    <CustomPagination isSingleScreen={true} activeItem={activeItem} setActiveItem={setActiveItem} totalPages={totalPages} setStartingIndex={setStartingIndex} />
                                </div>

                            </div>
                        }
                    </>
            }
            {/* <Toaster position="top-right" reverseOrder={false} /> */}
        </Fragment>
    )
    //#endregion

}
export default DocumentTable;

