import React, { useState, useEffect } from "react";
import {
    Card,
    ListGroup,
    ListGroupItem,
} from "@themesberg/react-bootstrap";
import { Capitalize } from "../../../common-components/StringConversions";
import styles from "../Index.module.css";
import { useHistory } from "react-router-dom";
import { GetAssociatedMemberListForCustomerApi } from "../../../actions/MyProfileActions.jsx";
import ProfileIconsModal from "../../../components/air-migrate/ProfileIconsModal.jsx";

const AssociatedPeopleList = ({ isEditProfile, UserId }) => {
    const history = useHistory();
    // user Id state here
    const [amUserId, setAmUserId] = useState(UserId ? UserId : localStorage.getItem('amUserId'));
    // state  for showing the list of associated people or not
    const [associatedMemeberList, setAssociatedMemberList] = useState([]);
    //#region useEffect
    useEffect(() => {
        GetAssociatedMemberList();
    })
    //#endregion useEffect
    
    // #region Functions
    // getting asscociated member list api call here 
    const GetAssociatedMemberList = async () => {
        const response = await GetAssociatedMemberListForCustomerApi(amUserId);
        if (response.status_code == 1) {
            setAssociatedMemberList(response.data);
        } else {
            console.error(response.message)
        }
    }
    // #endregion

    //#region render start
    return (
        <>
            {associatedMemeberList && associatedMemeberList.length > 0 ? (
                <Card>
                    <Card.Header>
                        <div className={styles.documentCardHeader}>
                            <h3 className="c-lg">List of people associated</h3>
                        </div>
                    </Card.Header>
                    <Card.Body className="p-2">
                        <ListGroup>
                            {associatedMemeberList.map((member, index) => {
                                return (
                                    <ListGroupItem key={index}>
                                        <div className={styles.associatedMemberBlock}>
                                            {/* Profile image component */}
                                            <ProfileIconsModal userDetails={member} />
                                            <div className={styles.associatedMemberTextBlock}>
                                                <p className={styles.profileDetailHeading}
                                                    onClick={() => {
                                                        if (isEditProfile) {
                                                            history.push("/admin/clients/edit-client", { userId: member.user_uuid })
                                                        }
                                                    }}
                                                    style={{ cursor: 'pointer' }}>
                                                    {member?.first_name == null ? 'User' : Capitalize(member.first_name)}</p>
                                                <p className={styles.profileText}>{member?.primary_phone ? `+91 ${member.primary_phone}` : ''}</p>
                                                <p className={styles.profileText}>{member?.email ? member?.email : ''}</p>
                                            </div>
                                        </div>
                                    </ListGroupItem>
                                )
                            })}
                        </ListGroup>
                    </Card.Body>
                </Card>
            ) : (
                ' '
            )
            }
        </>
    );
};
//#endregion render end
export default AssociatedPeopleList;