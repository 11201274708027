import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isLogin: false,
}

export const loginReducerSlice = createSlice({
    name: 'loginModule',
    initialState,
    reducers: {
        setIsLoginFalse: (state) => {
            state.isLogin = false
        },
        setIsLoginTrue: (state) => {
            state.isLogin = true
        },
    },
})

// Action creators are generated for each case reducer function
export const { setIsLoginFalse, setIsLoginTrue} = loginReducerSlice.actions

export default loginReducerSlice.reducer