import { authApi } from '../utilities/helpers/ApiHelper';
import { toast } from 'react-hot-toast';

//#region GET API's

//Get list of sent requests, sent by customer
async function GetCustomerSentRequestsApi(startingIndex, pageLimit, searchText) {

    let url = ``;
    if (startingIndex && pageLimit && searchText) {
        url = `my-sends-query?start=${startingIndex}&limit=${pageLimit}&search=${searchText}`
    }
    else if (startingIndex && pageLimit) {
        url = `my-sends-query?start=${startingIndex}&limit=${pageLimit}`
    }
    try {
        const response = await authApi.getAPI(url);
        if (response.status_code === 1) {
            return response.data;
        } else {
            toast.error(response.message, { duration: 4000, },);
        }
        return response.data;

    }
    catch (e) {
        toast.error("Something went wrong!", { duration: 4000 });
    }
}

//Get list of doc requests, sent by broker/immigration consultant/admin
async function GetCustomerRecievedDocRequestsApi(startingIndex, pageLimit, searchText) {

    let url = ``;
    if (startingIndex && pageLimit && searchText) {
        url = `my-documents-access-request?start=${startingIndex}&limit=${pageLimit}&search=${searchText}`
    }
    else if (startingIndex && pageLimit) {
        url = `my-documents-access-request?start=${startingIndex}&limit=${pageLimit}`
    }
    try {
        const response = await authApi.getAPI(url);
        if (response.status_code === 1) {
            return response.data;
        } else {
            toast.error(response.message, { duration: 4000, },);
        }
        return response.data;

    }
    catch (e) {
        toast.error("Something went wrong!", { duration: 4000 });
    }
}
//Get list of doc access requests, sent by broker/immigration consultant/admin
async function GetCustomerRecievedDocAccessRequestsApi(startingIndex, pageLimit, searchText) {

    let url = ``;
    if (startingIndex && pageLimit && searchText) {
        url = `my-documents-access-request-new?start=${startingIndex}&limit=${pageLimit}&search=${searchText}`
    }
    else if (startingIndex && pageLimit) {
        url = `my-documents-access-request-new?start=${startingIndex}&limit=${pageLimit}`
    }
    try {
        const response = await authApi.getAPI(url);
        if (response.status_code === 1) {
            return response.data;
        } else {
            toast.error(response.message, { duration: 4000, },);
        }
        return response.data;

    }
    catch (e) {
        toast.error("Something went wrong!", { duration: 4000 });
    }
}

//Get list of profile requests, sent by broker/immigration consultant/admin
async function GetCustomerRecievedProfileRequestsApi(startingIndex, pageLimit, searchText) {

    let url = ``;
    if (startingIndex && pageLimit && searchText) {
        url = `get-all-personal-info-request?start=${startingIndex}&limit=${pageLimit}&search=${searchText}`
    }
    else if (startingIndex && pageLimit) {
        url = `get-all-personal-info-request?start=${startingIndex}&limit=${pageLimit}`
    }
    try {
        const response = await authApi.getAPI(url);
        if (response.status_code === 1) {
            return response.data;
        } else {
            toast.error(response.message, { duration: 4000, });
        }
        return response.data;

    }
    catch (e) {
        toast.error("Something went wrong!", { duration: 4000 });
    }
}

//Get list of invite user in case requests, sent by broker/immigration consultant/admin
async function GetCustomerRecievedInviteUserRequestsApi(startingIndex, pageLimit, searchText) {

    let url = ``;
    if (startingIndex && pageLimit && searchText) {
        url = `invite-user-case-list?start=${startingIndex}&limit=${pageLimit}&search=${searchText}`
    }
    else if (startingIndex && pageLimit) {
        url = `invite-user-case-list?start=${startingIndex}&limit=${pageLimit}`
    }
    try {
        const response = await authApi.getAPI(url);
        if (response.status_code === 1) {
            return response.data;
        } else {
            // toast.error(response.message, { duration: 4000, });
        }
        return response.data;

    }
    catch (e) {
        toast.error("Something went wrong!", { duration: 4000 });
    }
}

//Brokers request section
async function GetBrokerRequestListApi(startingIndex, pageLimit, searchText) {

    let url = ``;

    if (startingIndex && pageLimit && searchText) {
        url = `broker-pending-query-list?start=${startingIndex}&limit=${pageLimit}&search=${searchText}`
    }
    else if (startingIndex && pageLimit) {
        url = `broker-pending-query-list?start=${startingIndex}&limit=${pageLimit}`
    }
    try {
        const response = await authApi.getAPI(url);
        return response;
    }
    catch (e) {
        toast.error("Something went wrong!", { duration: 4000 });
    }
}

//#endregion

//#region Post API's

//Accept or reject doc access request
async function CreateDocStatusChangeApi(docDetails, doc_id, id) {
    let url = `update-documents-access-request/${id}/${doc_id}`;
    try {
        const response = await authApi.postAPI(url, docDetails);
            return response;
    }
    catch (e) {
        console.error("Something went wrong! ", e);
    }
}

//Accept or reject profile access request
async function CreateProfileStatusChangeApi(docDetails) {
    let url = `update-access-client-personal-info-request`;
    try {
        const response = await authApi.postAPI(url, docDetails);
        return response;
    }
    catch (e) {
        console.error("Something went wrong! ", e);
    }
}

//ACcept or reject invite user request
async function CreateInviteUserStatusChangeApi(docDetails) {
    let url = `update-invite-request`;
    try {
        const response = await authApi.postAPI(url, docDetails);
        return response;
    }
    catch (e) {
        console.error("Something went wrong! ", e);
    }
}


//#endregion

export {
    GetCustomerSentRequestsApi, GetCustomerRecievedDocRequestsApi, GetCustomerRecievedProfileRequestsApi,
    GetCustomerRecievedInviteUserRequestsApi, CreateDocStatusChangeApi, CreateProfileStatusChangeApi,
    CreateInviteUserStatusChangeApi, GetBrokerRequestListApi,GetCustomerRecievedDocAccessRequestsApi
};