// Library import
import { useEffect, useState } from "react";
import { io } from 'socket.io-client'
import { useHistory } from "react-router-dom";

// Local import
import { getCaseInformationStepAPI } from "../../actions/ChatsActions";
import { SOCKET_SERVER_URL } from "../../config.tsx";
import messageHelper from "../../utilities/helpers/messageHelper.js";

// Case Step Info Status
const CaseInformationStep = (props) => {
    const { verticalView = false, caseId, userRoleId, userId, caseName } = props;

    const history = useHistory()
    // previous step data array
    const [stepInfoArray, setStepInfoArray] = useState([])
    // case primary user id
    const [primaryUserId, setPrimaryUserId] = useState(null)

    // All name array of step
    const [caseStepNameArray, setCaseStepNameArray] = useState([])

    // store socket response
    const [caseStepInformationBySocket, setCaseStepInformationBySocket] = useState(null);

    // store socket response
    const [lastStepStatus, setLastStepStatus] = useState(null);

    // initializ step names
    useEffect(() => {
        // Start step array (1,2,3)
        const startStepArray = [
            { id: 1, name: 'Add Client', url: '' },
            { id: 2, name: 'Client Chat Approval', url: '' },
            { id: 3, name: 'Client info', url: 'editClient' },
        ]

        var newMidDatam = []
        // Consultant Case
        if (userRoleId == 2) {
            newMidDatam = [
            { id: 4, name: 'Add Payment Terms', url: 'caseDetails' },
            { id: 5, name: 'Share Payment Terms', url: 'caseDetails' },
            { id: 6, name: 'Client Payment Approval', url: 'chat' },
            { id: 7, name: 'Client Agreement Sign', url: 'chat' },
            { id: 8, name: 'Counsultant Agreement Sign', url: 'chat' },]
        }

        // End step array (9, 10)
        const endStepArray = [
            { id: 9, name: 'Request Document', url: 'caseDetails' },
            { id: 10, name: 'Case Completed', url: '' },
        ]

        setCaseStepNameArray([...startStepArray, ...newMidDatam, ...endStepArray])
    }, [])

    // filter array 
    const filterData = (dataArray) => {
        // broker case: add step 4,5,6,7,8
        if(userRoleId == 4 && dataArray.includes(3)){
            dataArray = [...dataArray, 4, 5, 6, 7, 8]
        }

        const filterdArray = dataArray.filter((item, index) => (dataArray.includes(item - 1) || index == 0) && item)
        // special condition for payment share(remove 5 if not array exist 4)
        if(filterdArray.includes(5) && !filterdArray[4]){
            let index = filterdArray.indexOf(5); 
            if (index !== -1) {
                filterdArray.splice(index, 1); 
            }
        }
        // find last status
        var lenghtOfStatusArray = filterdArray.length
        if (lenghtOfStatusArray) {
            setLastStepStatus(filterdArray[lenghtOfStatusArray - 1] + 1)
        } else {
            setLastStepStatus(1)
        }
        return filterdArray
    }

    // get previous step status data
    const getCaseStepData = async () => {
        const response = await getCaseInformationStepAPI(caseId)
        if (response?.status_code == 1) {
            const dataObject = response?.data
            // Case Primary User Id
            setPrimaryUserId(dataObject?.primary_user_id)
            // Main Status Array
            const tempStatusArray = dataObject?.status
            const filteredDataArray = filterData(tempStatusArray)
            setStepInfoArray([...filteredDataArray])
        } else {
            let message = response?.message ? response.message : messageHelper('errro')
        }
    }

    // Initial & Socket useEffect
    useEffect(() => {
        const newSocket = io(SOCKET_SERVER_URL)

        if (caseId && newSocket) {
            // create new socket connetion
            newSocket.on('connect', () => {
                newSocket.emit('joinForCaseStepInfo', caseId)
            })

            // handle case step info status
            newSocket.on('case-step-status', (response) => {
                setCaseStepInformationBySocket(response)
            })
        }

        getCaseStepData()

        return () => { newSocket.disconnect() }
    }, [caseId])


    // When new step
    useEffect(() => {
        if (caseStepInformationBySocket) {
            const filteredDataArray = filterData(caseStepInformationBySocket.id)
            setStepInfoArray([...filteredDataArray])
        }
    }, [caseStepInformationBySocket])

    // handle on click
    const handleOnClick = (item) => {
        if (item.id > lastStepStatus) {
            return
        } else if (item.url == 'chat') {
            history.push(`/chat`, { userId: userId, caseId: caseId, caseName: caseName });
        } else if (item.url == 'caseDetails') {
            history.push('/cases/case-details', { userId: userId, caseId: caseId, caseName: caseName });
        } else if (item.url == 'editClient' && primaryUserId) {
            history.push('/admin/clients/edit-client', { userId: primaryUserId });
        }
    }

    return (
        <div className={`pr-case-block ${verticalView && 'verticle-pr-case'}`}>
            <div className={`pr-case-content-block ${verticalView && 'verticle-pr-case-content-block'}`}>
                {caseStepNameArray.map((item, index) => (
                    <div key={index} className={`pr-check-block  ${(index == caseStepNameArray.length - 1) && 'pr-check-block-custom-height'}`}>
                        <div onClick={() => handleOnClick(item)} className={`${stepInfoArray.includes(item.id) ? 'pr-check-circle' : 'pr-uncheck-circle'} ${(index == caseStepNameArray.length - 1) && 'after-border-none'}`}>
                            { // Icon
                                stepInfoArray.includes(item.id) &&
                                <span className="pr-check-icon "><i className="fa-solid fa-check"></i></span>
                            }
                        </div>
                        <div className={`pr-check-title   ${item.id > lastStepStatus && 'pr-case-title-opacity'}  ${item.id == 8 && lastStepStatus == 8 && 'red-color'}`} onClick={() => handleOnClick(item)}>{item.name}</div>
                    </div>
                )
                )}
            </div>
        </div>

    )
}

export default CaseInformationStep;