import styles from "./Index.module.css"
import { Fragment, useState, useEffect } from "react";
import RequestListTable from "../../components/air-migrate/RequestListTable";
import { Breadcrumb, Row, InputGroup, Col, Form } from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faSearch } from "@fortawesome/free-solid-svg-icons";
import DocumentTable from "./document-table/Index";
import { useHistory, useLocation } from 'react-router-dom';
import { CapitalizeAllWords } from "../../common-components/StringConversions";
import UserDocumentTable from "./user-document-table/Index";
import { HomepageUrl } from "../../utilities/helpers/Constant";
import CommonAlert from "../../common-components/CommonAlert";
import CommonBreadcrumb from "../../common-components/CommonBreadcrumb";


const AllDocumentList = () => {
    const [searchText, setSearchText] = useState('');
    const location = useLocation();
    const history = useHistory();
    const [isSearchChange, setIsSearchChange] = useState(false);
    const [userRoleId, setUserRoleId] = useState('roleId');
    const HeadingsWitoutAction = ['Document Type', 'Document Title', 'File Size', 'Date']
    const HeadingsWithAction = ['Document Type', 'Document Title', 'File Size', 'Date', 'Action']

    //States to manage mobile ui
    const [screenSize, setScreenSize] = useState(getCurrentDimension());
    const [singleScreen, setSingleScreen] = useState(false);

    //Alert states
    const [alertsArray, setAlertsArray] = useState([]);
    const [count, setCount] = useState(0);

    //Breadcrumb state
    const [breadcrumbArray, setBreadcrumbArray] = useState([
        { name: 'Home', url: HomepageUrl },
        { name: 'All Documents', url: '' }

    ]);

    //#region useEffect 
    useEffect(() => {
        //functionality to set collapsible table 
        window.addEventListener('resize', updateDimension);
        updateDimension();

        // removing eventlistener for no furhter effect after 
        return (() => {
            window.removeEventListener('resize', updateDimension);
        })
    }, [count])
    //#endregion

    //#region Functions
    const handleAlert = (id) => {
        let array = [];
        for (let i = 0; i < alertsArray.length; i++) {
            if ((alertsArray[i].id) == id) {
                array = alertsArray.pop();

            }
        }
        setCount(Math.floor(Math.random() * 1000))
    }

    const updateDimension = () => {
        setScreenSize(getCurrentDimension());
        var value = getCurrentDimension();
        // set a variable true when screen width reaches <768px
        if (value.width <= 767) {
            setSingleScreen(true)
        } else {
            setSingleScreen(false)
        }
    }

    function getCurrentDimension() {
        return {
            width: window.innerWidth,
            height: window.innerHeight
        }
    }

    //#endregion

    return (
        <Fragment>
            <div className="">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-0 pb-2">
                    <div className="d-block mb-md-0">
                        {/* <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
                            <Breadcrumb.Item className="pe-auto" onClick={() => history.push(HomepageUrl)}><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
                            <Breadcrumb.Item active>All documents</Breadcrumb.Item>
                        </Breadcrumb> */}

                        <CommonBreadcrumb breadcrumbArray={breadcrumbArray} />
                    </div>
                </div>

                <div className="table-settings mb-4">
                    <Row className="justify-content-space-between align-items-center search-header-block">
                        <Col xs={12} md={12} lg={4} xl={7} className="align-item-end search-header-text-info">
                            {((location.state?.userName.includes(null) == true) || (location.state?.userName == '') || (location.state?.userName == undefined)) ?
                                <h4 className={`${singleScreen ? 'f-16' : ''}`}>{`User's Document List`}</h4> :
                                <h4 className={`${singleScreen ? 'f-16' : ''}`}>{`${CapitalizeAllWords(location.state?.userName)}'s Document List`}</h4>
                            }
                        </Col>
                        <Col sm={12} xs={12} md={12} lg={8} xl={5} className="align-item-end">
                            <InputGroup>
                                <InputGroup.Text>
                                    <FontAwesomeIcon icon={faSearch} />
                                </InputGroup.Text>
                                <Form.Control className={`${singleScreen && 'f-14'} ${styles.customSearchDocument}`} type="text" placeholder="Search" value={searchText} onChange={(e) => { setIsSearchChange(true); setSearchText(e.target.value) }} />
                            </InputGroup>
                        </Col>
                    </Row>
                </div>

                <Row className="p-0 ">
                    <Col lg={12} sm={12} md={12} xs={12} xl={12}>
                        {(alertsArray.length > 0) &&
                            alertsArray.reverse().map((item, index) => {
                                return (
                                    <CommonAlert key={index} id={item.id} type={item.type} message={item.message} handleAlert={handleAlert} />
                                )
                            })
                        }
                    </Col>
                </Row>

                {(location.state.isEdit == true) ?
                    <DocumentTable
                        alertsArray={alertsArray}
                        setAlertsArray={setAlertsArray}
                        handleAlert={handleAlert}
                        search={searchText}
                        userId={location.state.userId}
                        isSearchChange={isSearchChange}
                        singleScreen={singleScreen} />
                    :
                    <UserDocumentTable search={searchText}
                        userId={location.state.userId}
                        isSearchChange={isSearchChange}
                        singleScreen={singleScreen}
                        alertsArray={alertsArray}
                        setAlertsArray={setAlertsArray}
                        setCount={setCount}
                        count={count} />
                }

            </div>
        </Fragment>
    )
}
export default AllDocumentList;