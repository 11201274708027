import React from 'react';
import GoogleAutocomplete from 'react-google-autocomplete';
import { GOOGLE_MAP_API_KEY } from '../../config.tsx';

const LocationSearchBox = ({ onLocationSearchHandler, className, name, value, onChange, onBlur }) => {
  // Function to handle the selection of a location
  const handleSelect = async (locationData) => {
    if (locationData) {
      if (!locationData.geometry.location || !locationData.geometry.location) {
        console.error('Invalid place selected');
        return;
      }
      let loactionData = {
        "location": locationData?.formatted_address,
        "latitude": locationData?.geometry.location.lat(),
        "longitude": locationData?.geometry.location.lng()
      };

      // sending location data to  parent(My profile) component
      onLocationSearchHandler(loactionData);
    } else {
      console.error('Invalid place selected');
      return;
    }
  }
  //#region  render
  return (
    <div>
      <GoogleAutocomplete
        className={className}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        apiKey={GOOGLE_MAP_API_KEY}
        onPlaceSelected={(place) => {
          handleSelect(place);
        }}
        options={{
          types: ["geocode"],
        }}
      />
    </div>
  );
};
//#endregion render
export default LocationSearchBox;