import React, { Fragment, useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row, Card, Image, OverlayTrigger, Tooltip, Table, Badge, Button, Modal, Form } from '@themesberg/react-bootstrap';
import { useFormik } from "formik";
import * as Yup from "yup";
import PhoneInput from "react-phone-input-2";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

/* Local imports */
import tableStyle from '../document-table/Index.module.css';
import docTableStyle from '../user-document-table/Index.module.css'
import { Capitalize } from "../../../common-components/StringConversions.jsx";
import myprofileStyle from '../../my-profile/Index.module.css'
import { PROFILE_PATH } from "../../../config.tsx";
import { DeleteUserFromDocumentApi, CreateDirectAccessToDocuments } from "../../../actions/ClientActions.jsx";
import CommonServices from "../../../common-components/CommonServices.jsx";
import TrashIcon from "../../../assets/img/icons/Airmigrate icons/512px/Cross512.png"
import { Default_Country ,Default_Country_Code} from "../../../config.tsx";
import CommonAlert from "../../../common-components/CommonAlert.jsx";
import { ALERT_SHOW_TIME } from "../../../config.tsx";
import addClientStyle from "../../../views/cases/add-client-modal/Index.module.css"
import { InviteClientFormAutoCompleteApi } from "../../../actions/CommonActions.jsx";

const GiveAccessModal = ({ giveAccesDetailsArray, show, handleClose, documentId, FunctionToRefresh }) => {
    /* ##############################Functionalities for Fields visible to Customer starts here######################## */
    /* Functions and const for Give Access Column and Actions Buttons for customer */
    const [code, setCode] = useState(Default_Country_Code);
    const [phone, setPhone] = useState('');

    // state to check for mail and phone touced
    const [isEmailEntered, setIsEmailEntered] = useState(false);
    const [isPhoneEntered, setIsPhoneEntered] = useState(false);
    //Alert states and functions
    const [alertsArray, setAlertsArray] = useState([]);
    const [count, setCount] = useState(0);
    const [searchContact, setSearchContact] = useState('searchemail');
    const [searchValue, setSearchValue] = useState();

    const handleAlert = (id) => {
        let array = [];
        for (let i = 0; i < alertsArray.length; i++) {
            if ((alertsArray[i].id) == id) {
                array = alertsArray.pop();
            }
        }
        setCount(Math.floor(Math.random() * 1000))
    }

    useEffect(() => {

    }, [count])

    // Validation schema for formik 
    const emailValidationSchema = Yup.object().shape({
        email: Yup.string()
            // .email("Invalid Email format")
            .required("Email is required")
    });
    const phonValidationSchema = Yup.object().shape({
        primary_phone_temp: Yup.number()
            .required("Phone number is required"),
        email: Yup.string()
        // .email("Invalid Email format")
    });
    const AllBlankValidationSchema = Yup.object().shape({
        email: Yup.string()
            // .email("Invalid Email format")
            .required("Email is required"),
        primary_phone_temp: Yup.number()
            .required("Primary Phone number is required"),
    });

    // changing valdataion schema on codition
    const finalValidationSchema = () => {
        if (isPhoneEntered && isEmailEntered) {
            return AllBlankValidationSchema;
        } else if (isEmailEntered) {
            return emailValidationSchema;
        } else if (isPhoneEntered) {
            return phonValidationSchema;
        } else {
            return AllBlankValidationSchema;
        }
    }

    //define formik flow
    const formik = useFormik({
        initialValues: {
            email: '',
            primary_phone_temp: ''
        },
        enableReinitialize: true,
        validationSchema: finalValidationSchema(),
        // validationSchema: Yup.object().shape({
        //     email: Yup.string()
        //         .email('Invalid email')
        //         .test('emailOrPhoneRequired', 'Email or phone number is required', function (value) {
        //             const { primary_phone_temp } = this.parent;
        //             return value || primary_phone_temp;
        //         }),
        //     primary_phone_temp: Yup.string()
        //         .test('emailOrPhoneRequired', 'Email or phone number is required', function (value) {
        //             const { email } = this.parent;
        //             return value || email;
        //         }),
        // }),
        onSubmit: (values) => {
            handleFormSubmit(values);
        },

    })

    // oncahnage handel for primary phone input
    const HandlePInputChange = (value, country) => {
        formik.setFieldValue("primary_phone_temp", value);
        formik.setFieldValue("email", '')
        if (country.dialCode) {
            setCode(country.dialCode);
            // setPhoneEntered(true);
        }

        var phone = value.slice(country.dialCode.length);
        if (phone) {
            setSearchValue(phone);
        }
        if (phone.length > 8) {
            setPhone(phone);
            formik.setFieldValue("primary_phone_temp", value);
            setIsPhoneEntered(true);
        } else {
            formik.setFieldError("primary_phone_temp", "Phone Number is too short!")
        }
    }


    // function call on form  submit
    const handleFormSubmit = async (formData) => {
        var sendData = {
            email: formData.email,
            primary_country_code: "",
            primary_phone: "",
            documents_id: documentId
        }
        if (formData.primary_phone_temp) {
            if (formData.primary_phone_temp.length > 10) {
                sendData.primary_phone = phone;
                sendData.primary_country_code = code;
            } else {
                formik.setFieldError("primary_phone_temp", "Phone number is Invalid")
                return
            }
        } else {
            sendData = { ...sendData };
        }
        // console.log("final send data", sendData)
        // final form data submission to api
        try {
            var response = await CreateDirectAccessToDocuments(sendData);
            if (response.status_code == 1) {
                setAlertsArray([...alertsArray, { id: `${'doc-access'}${alertsArray.length}`, type: 'success', message: response?.message }]);
                FunctionToRefresh();
            } else {
                setAlertsArray([...alertsArray, { id: `${'doc-access'}${alertsArray.length}`, type: 'danger', message: response?.message }]);
            }
            // setTimeout(() => {
            //     FunctionToRefresh();
            //     // handleClose();
            // }, ALERT_SHOW_TIME);
            setCount(Math.floor(Math.random() * 1000))
        } catch (err) {
            console.error("Error giving access to user!", err);
        }
        console.error("form errors", formik.errors)

    }

    /* Remove access function here */
    const removeMemberAccess = async (memberData) => {
        let data = {
            request_user_uuid: memberData.user_uuid,
            documents_id: documentId,
            id: memberData.access_id
        };
        try {
            const response = await DeleteUserFromDocumentApi(data);
            if (response.status_code == 1) {
                setAlertsArray([...alertsArray, { id: `${'doc-access'}${alertsArray.length}`, type: 'success', message: response?.message }]);
                FunctionToRefresh();
            } else {
                setAlertsArray([...alertsArray, { id: `${'doc-access'}${alertsArray.length}`, type: 'danger', message: response?.message }]);
            }
            // setTimeout(() => {
            //     FunctionToRefresh();
            //     // handleClose();
            // }, ALERT_SHOW_TIME);
            setCount(Math.floor(Math.random() * 1000))
        } catch {
            console.error("Error Occurred while removing the user from document.");
        }
    }
    const onContactChange = (value) => {
        setSearchContact(value);
        if (value == "searchphone") {
            setIsPhoneEntered(true);
            setIsEmailEntered(false);
            formik.setFieldValue("email", '');
            formik.setFieldTouched("email", false, false)

        } else if (value == "searchemail") {
            setIsPhoneEntered(false);
            setIsEmailEntered(true);
            setPhone('');
            setCode('');
            formik.setFieldValue("primary_phone_temp", '');
            formik.setFieldTouched("primary_phone_temp", false, false);
        }
    }

    //#region  JSX  Code Starts Here
    /* Give Access Pop Up Modal here */

    return (
        <Row className=" overflow-scroll">
            <Col xs={12} lg={12} md={12} sm={12}>
                <Modal as={Modal.Dialog} show={show} onHide={handleClose} centered className="px-2">
                    <Modal.Header closeButton>
                        <Modal.Title> Document Access</Modal.Title>
                    </Modal.Header>
                    <div className="m-2">
                        {(alertsArray.length > 0) &&
                            alertsArray.map((item, index) => {
                                return (
                                    <CommonAlert key={index} id={item.id} type={item.type} message={item.message} handleAlert={handleAlert} />
                                )
                            })
                        }
                    </div>

                    <Modal.Body>
                        <Row style={{ maxHeight: 245, overflow: 'auto' }}>
                            {giveAccesDetailsArray && giveAccesDetailsArray?.map((member, index) => {
                                return (
                                    <Col xs={12} md={12} lg={12} xl={12} key={index}>
                                        {/* {member.docs_access_status === 1 && */}
                                        <div className={`border border-light justify-content-between ${myprofileStyle.associatedMemberBlock}`}>
                                            {member.profile_path ? (
                                                <img src={member.profile_path ? member.profile_path : PROFILE_PATH} alt="" className={`mx-3 ${docTableStyle.giveAccessProfileImg}`} />
                                            ) : (
                                                <div className={`bg-light rounded-circle d-flex justify-content-center align-items-center mx-3 ${docTableStyle.giveAccessNameProfile}`} >
                                                    <p className=" fw-bolder">{CommonServices.getNameCharByFullName((member.first_name ? member?.first_name : 'user') + ' ' + (member.last_name ? member?.last_name : ''))}</p>
                                                </div>
                                            )}
                                            <div className={`w-75 ${myprofileStyle.associatedMemberTextBlock}`}>
                                                <p className={myprofileStyle.profileDetailHeading}>
                                                    {member?.first_name == null ? 'User' : Capitalize(member.first_name)} {member?.last_name == null ? ' ' : Capitalize(member.last_name)}</p>
                                                <p className={myprofileStyle.profileText}>{member?.primary_phone ? `+91 ${member.primary_phone}` : ''}</p>
                                                <p className={myprofileStyle.profileText} title={member?.email} >{member?.email ? member?.email : ''} </p>
                                            </div>

                                            <div className={`mx-3 ${tableStyle.aceesModalCrossBtnBlock}`} onClick={() => { removeMemberAccess(member) }} title="Remove user access">
                                                {/* <FontAwesomeIcon icon={faTimesCircle} size="lg" /> */}
                                                <img src={TrashIcon} alt="Remove" className='' style={{ width: 20, minWidth: 20, maxWidth: 20 }} />
                                            </div>
                                        </div>
                                        {/* }  */}
                                    </Col>
                                )
                            })}
                        </Row>
                        <Row className=''>
                            <Form.Label className="mt-3">Give Access By Phone Or Email <span className="c-r">*</span></Form.Label>
                            <div className="d-flex">
                                <Form.Check
                                    inline
                                    label="Email"
                                    name="search"
                                    type="radio"
                                    id={`inline-radio-11`}
                                    value={toString("email")}
                                    checked={searchContact === "searchemail"}
                                    onChange={() => { onContactChange("searchemail", 2) }}

                                />
                                <Form.Check
                                    inline
                                    label="Mobile Number"
                                    name="search"
                                    type="radio"
                                    id={`inline-radio-21`}
                                    value={toString("phone")}
                                    checked={searchContact === "searchphone"}
                                    onChange={() => { onContactChange("searchphone", 2) }}

                                />
                            </div>
                        </Row>
                        <Row>
                            <Form onSubmit={formik.handleSubmit} className='mt-3 '>
                                {searchContact == "searchemail" &&
                                    <Col xs={12} className="mb-3">
                                        <Form.Group >
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control
                                                id="email"
                                                name="email"
                                                value={formik.values.email}
                                                placeholder="Enter email"
                                                onChange={(e) => {
                                                    formik.setFieldValue("email", e.target.value);
                                                    formik.setFieldValue("primary_phone_temp", '')
                                                    if (e.target.value == '') {
                                                        setIsEmailEntered(false);
                                                    }
                                                    else setIsEmailEntered(true);
                                                }}
                                                onBlur={formik.handleBlur}
                                                className={`${formik.touched.email && formik.errors.email ? 'is-invalid' : ''}`}
                                            />
                                            {formik.touched.email && formik.errors.email ? (
                                                <small className="text-danger">{formik.errors.email}</small>
                                            ) : null}
                                        </Form.Group>
                                    </Col>
                                }
                                {searchContact == "searchphone" &&
                                    <Col xs={12} className="mb-3">
                                        <Form.Group >
                                            <div className="inputRow">
                                                <Form.Label className="mobile-number-text">Primary Mobile Number</Form.Label>
                                                <PhoneInput
                                                    country={Default_Country}
                                                    preferredCountries={['in', 'ca', 'us']}
                                                    autoFormat
                                                    containerClass={`${myprofileStyle.editProfilePhoneContainer} ${formik.touched.primary_phone_temp && formik.errors.primary_phone_temp ? 'is-invalid' : ''}`}
                                                    inputClass={`${myprofileStyle.editProfilePhoneInput} ${formik.touched.primary_phone_temp && formik.errors.primary_phone_temp ? 'is-invalid' : ''}`}
                                                    buttonClass={myprofileStyle.editProfileCountryDropDown}
                                                    dropdownClass={'country-dropdown-container'}
                                                    enableSearch
                                                    countryCodeEditable={false}
                                                    style={{ paddingRight: '0px' }}
                                                    value={formik.values.primary_phone_temp}
                                                    onBlur={formik.handleBlur}
                                                    onChange={(value, country) => {
                                                        HandlePInputChange(value, country)
                                                    }}
                                                    placeholder="Phone Number"
                                                    autocompleteSearch={true}
                                                    inputProps={{
                                                        name: 'primary_phone_temp',

                                                    }}
                                                />
                                                {formik.touched.primary_phone_temp && formik.errors.primary_phone_temp ? (
                                                    <small className="text-danger">{formik.errors.primary_phone_temp}</small>
                                                ) : null}
                                            </div>
                                        </Form.Group>
                                    </Col>
                                }
                                <Col className='invite-btn-block mt-4' sm={12} md={12} lg={12}>
                                <div className='d-flex justify-content-between'>
                                    <Button type="reset" onClick={handleClose} className="text-white invite-btn" variant="danger"> Close </Button>
                                    <Button type="submit" className="text-white invite-btn me-2" variant="success"> Give Access </Button>
                                </div>
                                </Col>
                            </Form>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        {/* <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button> */}
                    </Modal.Footer>
                </Modal >
            </Col>
        </Row>
    )
}
export default GiveAccessModal;
