import styles from './Index.module.css';
import React, { Fragment, useState } from "react";
import { useHistory } from "react-router-dom";
import { Breadcrumb, Dropdown, ButtonGroup, Col, Form, InputGroup, Button, Row } from "@themesberg/react-bootstrap";
import { faHome, faCheck, faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RequestListTable from '../../components/air-migrate/RequestListTable';
import { HomepageUrl } from '../../utilities/helpers/Constant';
import CommonBreadcrumb from '../../common-components/CommonBreadcrumb';

const RequestList = () => {

    //#region States and variables
    const history = useHistory();
    const [searchText, setSearchText] = useState('');
    const [userRoleId, setUserRoleId] = useState(localStorage.getItem('roleId'));

    //Breadcrumb state
    const [breadcrumbArray, setBreadcrumbArray] = useState([
        { name: 'Home', url: HomepageUrl },
        { name: 'Cases', url: '' }
    ]);
    //#endregion

    return (
        <Fragment>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-md-0">
                    {/* <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
                        <Breadcrumb.Item style={{ cursor: "pointer" }} onClick={() => { history.push(HomepageUrl) }}><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
                        <Breadcrumb.Item active>Cases</Breadcrumb.Item>
                    </Breadcrumb> */}

                    <CommonBreadcrumb breadcrumbArray={breadcrumbArray}/>
                    <h4>Case list</h4>

                </div>

            </div>

            <div className="table-settings mb-4">
                <Row className="justify-content-between align-items-center">
                    <Col xs={8} md={6} lg={3} xl={4} >
                        <InputGroup>
                            <InputGroup.Text>
                                <FontAwesomeIcon icon={faSearch} />
                            </InputGroup.Text>
                            <Form.Control type="text" placeholder="Search" value={searchText} onChange={(e) => setSearchText(e.target.value)} />
                        </InputGroup>
                    </Col>
                    <Col xs={4} md={2} xl={1} className="ps-md-0 text-end">
                        <Dropdown as={ButtonGroup}>
                            <Dropdown.Menu className="dropdown-menu-xs dropdown-menu-right">
                                <Dropdown.Item className="fw-bold text-dark">Show</Dropdown.Item>
                                <Dropdown.Item className="d-flex fw-bold">
                                    10 <span className="icon icon-small ms-auto"><FontAwesomeIcon icon={faCheck} /></span>
                                </Dropdown.Item>
                                <Dropdown.Item className="fw-bold">20</Dropdown.Item>
                                <Dropdown.Item className="fw-bold">30</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                </Row>
            </div>

            <RequestListTable search={searchText} tableHeadings={['Document List', 'Document Title', 'Requested By', 'Requested To', 'Requested Date', 'Actions']} dataToShow={'requestList'} />
        </Fragment>
    )
}
export default RequestList;