import React, { useState, Fragment, useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import { toast } from 'react-hot-toast'
import { Toaster } from 'react-hot-toast';
import { OtpVerifyApi, SignInApi, ClientSelectOptionAPI } from '../../actions/LoginAction.jsx';
// import { Container } from 'reactstrap';
import OtpInput from 'react-otp-input';
import { useLocation } from 'react-router-dom';
import LogoImage from '../../assets/img/landing-page/logo-img.png';
import { DataLoading } from '../../components/air-migrate/Loader.jsx'
import {
  Col,
  Row,
  Card,
  Form,
  Button,
  Container,
  FormGroup,
} from "@themesberg/react-bootstrap";
import './Index.css';
import CommonServices from '../../common-components/CommonServices.jsx';
import { useDispatch } from 'react-redux';
import { setMyProfile } from '../../reducers/ProfileImageReducer.jsx';
import CommonAlert from '../../common-components/CommonAlert.jsx';
import { ALERT_SHOW_TIME } from '../../config.tsx';
import FileUploadModal from '../../components/air-migrate/FileUploadModal.jsx';
import { setIsVisaUploaded, setVisaOption } from '../../reducers/VisaUploadReducer.jsx';
import Visaimg1 from '../../assets/img/CustomerType/want-visa.png';
import Visaimg2 from '../../assets/img/CustomerType/visa.png';



const CustomerType = () => {
  const [otp, setOtp] = useState('');
  const [isSubmitting, setSubmitting] = useState(false);
  const [userDetails, setUserDetails] = useState('');
  const [showloader, setShowLoader] = useState(false);
  const [isLOginThroughPhone, setIsLoginThroughPhone] = useState(true);
  // Upload Popup
  const [showUploadPopup, setShowUploadPopup] = useState(false)
  const [userId, setUserId] = useState(localStorage.getItem('amUserId'))

  const dispatch = useDispatch();
  const history = useHistory();
  const { loginMethod, ...rest } = {}
  const location = useLocation();
  //Alert states and functions
  const [alertsArray, setAlertsArray] = useState([]);
  const [count, setCount] = useState(0);

  const handleAlert = (id) => {
    let array = [];
    for (let i = 0; i < alertsArray.length; i++) {
      if ((alertsArray[i].id) == id) {
        array = alertsArray.pop();
      }
    }
    setCount(Math.floor(Math.random() * 1000))
  }

  useEffect(() => {

  }, [])


  // Update user click
  const handleClick = async (visaStatus) => {
    localStorage.setItem("visaOption", visaStatus);
    dispatch(setVisaOption(visaStatus))

    const data = { 'visa_status': visaStatus }
    const response = ClientSelectOptionAPI(data)

    if (visaStatus == 1) {
      history.push('/chat', { userId })
    } else {
      // visaStatus == 2
      handleUploadPopup()
    }
  }

  const handleUploadPopup = () => setShowUploadPopup(!showUploadPopup);

  // After visa upload
  const afterUploadSuccess = () => {
    localStorage.setItem("isVisaUploaded", true);
    dispatch(setIsVisaUploaded(true))
    history.push('/status-pending-page')
  }


  return (
    <Fragment>
      <>
        <Container className="login-main-container" fluid>
          <Row className='login-main-row'>
            <Col xs={12} sm={12} md={12} lg={7} className="d-none d-md-flex login-side-text-block">
              <div className="login-side-text">
                <h1 className="login-side-head-text">Airmigrate</h1>
                <p>
                  Redefining how the world immigrates.
                </p>
                <p>
                  Log In securely - We ensure the utmost privacy and protection of your information as you navigate your new path.
                </p>
              </div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={5} className="login-form-block">

              {/*Immigrants Section*/}
              <section className="immigrant-block-area visa-info-area">
                <div className="">
                  <div className="visa-info-block">
                    <div className="immigrant-section-content-block customer-type-style-block">
                      <div onClick={() => handleClick(1)} className="immigrant-section-block-inner cursor-pointer">
                        <div className="immigrant-content-block">
                          <div className='immigrant-img-block'>
                            <img src={Visaimg1} alt='visa img1' />
                          </div>
                          <span className="visa-info-heading">Apply for a <span class="heading-blue-text">Visa</span></span>
                          <p className="visa-info-text">
                            Get assistance with your visa application and find the right immigration consultant for your needs.
                          </p>
                        </div>
                      </div>
                      <div onClick={() => handleClick(2)} className="immigrant-section-block-inner cursor-pointer">
                        <div className="immigrant-content-block">
                          <div className='immigrant-img-block'>
                            <img src={Visaimg2} alt='visa img2' className='visa2-img' />
                          </div>
                          <span className="visa-info-heading">Already Have a <span class="heading-blue-text">Visa</span></span>
                          <p className="visa-info-text">
                            Discover great deals and get support to help you settle down smoothly in Canada.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              {/* <div className="main-container flex-column">
                <div className=" alert-container mt-2">
                  {(alertsArray.length > 0) &&
                    alertsArray.reverse().map((item, index) => {
                      return (
                        <div className="text-center">
                          <CommonAlert key={index} id={item.id} type={item.type} message={item.message} handleAlert={handleAlert} />
                        </div>
                      )
                    })
                  }
                </div> */}
              {/* <div className="inner-container"> */}
              {/* <div className="text-container">
                    <h2 className="heading">Choose Customer Type</h2>
                    <p className="inner-text">
                      Please check your Mail or Message For Received OTP to Verify
                    </p>
                  </div> */}
              {/* <form>
                    <div className="otp-input-fields">
                      <OtpInput
                        onChange={handleChange}
                        value={otp}
                        inputStyle="inputStyle"
                        numInputs={4}
                        inputType="number"
                        separator={<span></span>}
                        renderInput={(props) => <input {...props} />}
                        shouldAutoFocus={true}
                      // onKeypress={handleKeyPress}
                      />
                    </div>
                    {verifyOtpFlag &&
                      <div className="lower-container">
                        <button className="verify-btn" disabled={showloader} type='submit' onClick={handleOtpVerification}>
                          {isSubmitting ? 'Verifying...' : 'Verify OTP'}
                        </button>
                        <p disabled={showloader} className="lower-text">Didn’t Receive the OTP ? <span className="resend-text" onClick={resendOTPFunction}>RESEND OTP</span></p>
                      </div>
                    }
                  </form> */}
              {/* </div> */}
              {/* <Toaster position="top-right" reverseOrder={false} /> */}
              {/* </div> */}
            </Col>
          </Row>
        </Container>
      </>

      {/* Document upload popup */}
      <FileUploadModal show={showUploadPopup} handleClose={handleUploadPopup} visaUpload={true} FunctionToRefreshAfterUpload={afterUploadSuccess} modalAlertsArray={alertsArray} setModalAlertsArray={setAlertsArray} popupTitle='Visa Upload' />

      {showloader === true && <DataLoading />}
    </Fragment>

  );
};

export default CustomerType;
