import { authApi } from '../utilities/helpers/ApiHelper';
import { toast } from 'react-hot-toast';

async function GetCustomerTypeListApi(startingIndex, pageLimit, searchText) {

    let url = ``;

    if (startingIndex && pageLimit && searchText) {
        url = `get-client-visa-request-list?start=${startingIndex = 1}&limit=${pageLimit}&search=${searchText}`
    }
    else if (startingIndex && pageLimit) {
        url = `get-client-visa-request-list?start=${startingIndex}&limit=${pageLimit}`
    }
    try {
        const response = await authApi.getAPI(url);
        return response;
    }
    catch (e) {
        toast.error("Something went wrong!", { duration: 4000 });
    }
}

async function GetCustomerListApi(startingIndex, pageLimit, searchText) {

    let url = ``;

    if (startingIndex && pageLimit && searchText) {
        url = `clients-list?start=${startingIndex = 1}&limit=${pageLimit}&search=${searchText}`
    }
    else if (startingIndex && pageLimit) {
        url = `clients-list?start=${startingIndex}&limit=${pageLimit}`
    }
    try {
        const response = await authApi.getAPI(url);
        return response;
    }
    catch (e) {
        toast.error("Something went wrong!", { duration: 4000 });
    }
}




async function DeleteCustomerApi(user_uuid) {
    let url = `agent-delete/${user_uuid}`;
    try {
        const response = await authApi.deleteAPI(url);
        // console.log("deleteagent", response)
        return response;
    }
    catch (e) {
        toast.error("Something went wrong!", { duration: 4000 });
    }
}

//Accept or reject profile access request
async function CreateCustomerRequestStatusChangeApi(docDetails) {
    let url = `agent-update-visa-approve`;
    try {
        const response = await authApi.postAPI(url, docDetails);
        return response;
    }
    catch (e) {
        console.error("Something went wrong! ", e);
    }
}

async function GetLinkToViewDocumentForAgentApi(URL, id) {
    let data = {
        "user_uuid": id,
        "headerTag": ''
    }
    try {
        const response = await authApi.postAPI(URL, data);
        // console.log("view document response",response)
        // if (response.status_code == 1) {
            return response;
        // }
    }
    catch (e) {
        console.error(e);
    }
}

// Update chat user meta
const checkMyCurrentStatusAPI = async (userId, caseId) => {
    let URL = `my-current-status`;
    try {
        const response = await authApi.getAPI(URL);
        return response;
    }
    catch (e) {
        toast.error("Something went wrong!", { duration: 4000 });
    }
}

export {
    GetCustomerListApi,GetCustomerTypeListApi, DeleteCustomerApi, CreateCustomerRequestStatusChangeApi, GetLinkToViewDocumentForAgentApi,
    checkMyCurrentStatusAPI
}