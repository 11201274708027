import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isToastVisible: false,
    defaultLanguage:'English'
}

export const GlobalSlice = createSlice({
    name: 'globalModule',
    initialState,
    reducers: {
        setIsToastVisible: (state, value) => {
            console.log('toast and value', value);
            state.isToastVisible = value.payload;
        },
        setDefaultLanguage :(state, value) =>{
            console.log('defaultLanguage and value', value);
            state.defaultLanguage = value.payload;
        }
    },
})

// Action creators are generated for each case reducer function
export const { setIsToastVisible, setDefaultLanguage } = GlobalSlice.actions

export default GlobalSlice.reducer