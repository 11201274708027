import React, { Fragment, useState, useEffect } from "react";
import "./Index.css";
import Header from "../../layout/Header";
import Footer from "../../layout/Footer";
// imorting images
import Banner from "../../assets/img/banking/banner.png";
import Bankingimg1 from "../../assets/img/banking/bankingimg1.png";
import Bankingimg2 from "../../assets/img/banking/bankingimg2.png";
import Bankingimg3 from "../../assets/img/banking/bankingimg3.png";
import Tag1 from "../../assets/img/banking/toptag1.png";
import Tag2 from "../../assets/img/banking/toptag2.png";
import RightIcon from '../../assets/img/landing-page/right-icon.png';

// craete a banking page
const Banking = () => {
  const [isUserLogin, setIsUserLogin] = useState(false);

  useEffect(() => {
    setIsUserLogin(false)
    var userDetails = localStorage.getItem("accessToken");
    if (userDetails) {
      setIsUserLogin(true)
    }
  })
  return (
    <Fragment>
      <Header isUserLogin={isUserLogin} setIsUserLogin={setIsUserLogin} />
      <section className="banking-page container-fluid p-0">
        <section className={`banking-banner row ${isUserLogin ? '' : 'logout-banner-margin'}`}>
          <div className="col-12 m-0 p-0">
            <div className="explore-banner-text">
              <div className="real-estate-banner-inner-block">
                <h2 className="banner-head">
                  <span className="heading-blue-text">Airmigrate Banking Services </span> Optimize Your Financial Start
                </h2>
                {/* <p className="slider-text-block">
                  
                </p> */}
                <p className="slider-text-block">
                  Finding the best banking deals is an uphill task? You're not alone—95% of immigrants feel the same.

                  Airmigrate simplifies this by offering a user-friendly platform to compare and select banking products tailored for newcomers, with exclusive deals from leading financial institutions.
                </p>

                <p className="mobile-slider-text-block">Moving to a new country can be overwhelming, especially when it comes to managing finances in a new banking system.</p>
              </div>
            </div>
          </div>
        </section>
        {/* <section className="banking-banner row">
          <div className="col-12 m-0 p-0">
            <div className="banner-img-block">
              <img
                src={Banner}
                alt="banner Image"
                className="img-fluid banner-image banking-banner-image"
              />
            </div>

            <div className="banking-banner-text">
              <h2 className="banner-head">
                <span className="heading-blue-text">Banking</span> For A Better
                Tomorrow
              </h2>
              <p>
                Moving to a new country can be overwhelming, especially when it comes to managing finances in a new banking system. Airmigrate simplifies this crucial step by offering a comprehensive and user-friendly platform where you can compare and find banking products that cater directly to the needs of immigrants and newcomers.
                Understanding the financial challenges faced by newcomers, we partner with leading Canadian banks and financial institutions to bring you exclusive deals and packages that are tailored to ease your transition into your new life. Whether you're looking for a checking account with low fees, a high-interest savings account, or credit options to build your Canadian credit history, Airmigrate is your trusted guide.
              </p>
            </div>
          </div>
        </section> */}
        <section className="banking-inner-content">
          <div className="row my-4 box-shadow">
            <div className="col-md-7 col-sm-12 cibc-bank-img-block">
              <img
                src={Bankingimg1}
                alt="BankingImg1"
                className="img-fluid banking-img1"
              />
            </div>
            <div className="col-md-5 col-sm-12 cibc-bank-text-block">
              <div className="tag-image-block">
                <img src={Tag1} alt="" className="tag-image" />
              </div>
              <div className="cibc-text">
                <h2 className="cibc-head">
                  <span className="heading-blue-text">CIBC</span> Bank
                </h2>
                <p className="cibc-para my-4">
                Airmigrate Banking Services: Optimize Your Financial Start Finding the best banking deals is an uphill task? You're not alone—95% of immigrants feel the same. Airmigrate simplifies this by offering a user-friendly platform to compare and select banking products tailored for newcomers, with exclusive deals from leading financial institutions.
                </p>
                {/* <a href="javascript:void(0)" className="learn-more-button">
                  Learn More{" "}
                  <span>
                    <i class="fa-solid fa-arrow-right pl-2"></i>
                  </span>
                </a> */}
                {/* <button className="learn-more-button">
                  Learn More{" "}
                  <span>
                    <i className="fa-solid fa-arrow-right pl-2"></i>
                  </span>
                </button> */}
                 <div className="learn-more-btn">
                  <a >Learn More <span className="right-icon-block"><img src={RightIcon}
                    alt="right-icon" /></span></a>
                </div>
              </div>
            </div>
          </div>
          <div className="row my-4 box-shadow">
            <div className="col-md-5 col-sm-12 cibc-bank-text-block">
              <div className="tag-image-block1">
                <img src={Tag2} alt="" className="tag-image" />
              </div>
              <div className="cibc-text">
                <h2 className="cibc-head">
                  <span className="heading-blue-text">TD</span> Bank
                </h2>
                <p className="cibc-para my-4">
                Airmigrate Banking Services: Optimize Your Financial Start Finding the best banking deals is an uphill task? You're not alone—95% of immigrants feel the same. Airmigrate simplifies this by offering a user-friendly platform to compare and select banking products tailored for newcomers, with exclusive deals from leading financial institutions.
                </p>
                {/* <a href="javascript:void(0)" className="learn-more-button">
                  Learn More{" "}
                  <span>
                    <i class="fa-solid fa-arrow-right pl-2"></i>
                  </span>
                </a> */}
                {/* <button className="learn-more-button">
                  Learn More{" "}
                  <span>
                    <i className="fa-solid fa-arrow-right pl-2"></i>
                  </span>
                </button> */}
                 <div className="learn-more-btn">
                  <a >Learn More <span className="right-icon-block"><img src={RightIcon}
                    alt="right-icon" /></span></a>
                </div>
              </div>
            </div>
            <div className="col-md-7 col-sm-12 cibc-bank-img-block">
              <img
                src={Bankingimg2}
                alt="BankingImg1"
                className="img-fluid banking-img1"
              />
            </div>
          </div>
          <div className="row my-4 box-shadow">
            <div className="col-md-7 col-sm-12 cibc-bank-img-block">
              <img
                src={Bankingimg3}
                alt="BankingImg1"
                className="img-fluid banking-img1"
              />
            </div>
            <div className="col-md-5 col-sm-12 cibc-bank-text-block">
              <div className="tag-image-block">
                <img src={Tag1} alt="" className="tag-image" />
              </div>
              <div className="cibc-text">
                <h2 className="cibc-head">
                  <span className="heading-blue-text">Royal</span> Bank
                </h2>
                <p className="cibc-para my-4">
                Airmigrate Banking Services: Optimize Your Financial Start Finding the best banking deals is an uphill task? You're not alone—95% of immigrants feel the same. Airmigrate simplifies this by offering a user-friendly platform to compare and select banking products tailored for newcomers, with exclusive deals from leading financial institutions.
                </p>
                {/* <a href="javascript:void(0)" className="learn-more-button">
                  Learn More{" "}
                  <span>
                    <i class="fa-solid fa-arrow-right pl-2"></i>
                  </span>
                </a> */}
                {/* <button className="learn-more-button">
                  Learn More{" "}
                  <span>
                    <i className="fa-solid fa-arrow-right pl-2"></i>
                  </span>
                </button> */}
                 <div className="learn-more-btn">
                  <a >Learn More <span className="right-icon-block"><img src={RightIcon}
                    alt="right-icon" /></span></a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
      <Footer isUserLogin={isUserLogin} setIsUserLogin={setIsUserLogin} />
    </Fragment>
  );
};

export default Banking;
