import React, { Fragment, useEffect, useState } from "react";
import { Card, Form, Row, Col } from "@themesberg/react-bootstrap";
import styles from "../Index.module.css"
import { updateMyprofileApi } from "../../../actions/MyProfileActions.jsx";
import MyProfileUploadModal from "./MyProfileUploadModal.jsx";
import AddProfile from "../../../assets/img/client/addImage.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import MyStatefulEditor from "../../../components/air-migrate/TextEditor.jsx";

// About Me Component
// Main Component Function
const AboutMe = ({ myProfileDetails, GetMyProfileData, sendDataToParent, isEditProfile }) => {
    // consts for getting form fields to show
    const [clickedField, setClickedField] = useState('');
    // description useState
    const [aboutDescription, setAboutDescription] = useState('');
    // About image object state
    const [aboutImagesObj, setAboutImageObj] = useState({});
    // file Uplaod Modal const here
    const [aboutImage, setAboutImage] = useState('');
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
    }
    // state for reset form fields 
    const [resetForm, setResetForm] = useState(false);
    // text editor consts
    const onChange = (value) => {
        setAboutDescription(value);
    };
    //#region useEffect
    useEffect(() => {
        setAboutImageObjFunction(myProfileDetails);
    }, [myProfileDetails, resetForm])
    //#endregion useEfect
    // get images to show on page
    const setAboutImageObjFunction = (data) => {
        // set about description to formfield
        setAboutDescription(data?.description);
        let otherDetails = data?.other_details;
        // Making about Images object
        const addKeyValuePair = (key, value) => {
            setAboutImageObj(prevObject => ({
                ...prevObject,
                [key]: value
            }));
        };
        // separating about images form meta details and sending to object making function
        if (otherDetails) {
            otherDetails.forEach(element => {
                if (element.key === 'about_image_1') {
                    addKeyValuePair('about_image_1', element.value);
                }
                if (element.key === 'about_image_2') {
                    addKeyValuePair('about_image_2', element.value);
                }
                if (element.key === 'about_image_3') {
                    addKeyValuePair('about_image_3', element.value);
                }
            });
        }
    }

    // Function to call api to update profile fields
    const ApiCallForProfileUpdate = async (event) => {
        if (event) {
            event.preventDefault();
        }
        let submitData = { description: aboutDescription }
        let response = await updateMyprofileApi(submitData);
        sendDataToParent(response, 'alert');
        GetMyProfileData();
        setClickedField('');
    }

    // set about me phostos directly after uploaded  files
    const getUploadedFile = (file, uploadFor, modalResponse) => {
        sendDataToParent(modalResponse, 'alert');
        switch (uploadFor) {
            case 'about_image_1':
                setAboutImageObj({
                    ...aboutImagesObj,
                    about_image_1: file.url,
                })
                break;
            case 'about_image_2':
                setAboutImageObj({
                    ...aboutImagesObj,
                    about_image_2: file.url,
                })
                break;
            case 'about_image_3':
                setAboutImageObj({
                    ...aboutImagesObj,
                    about_image_3: file.url
                });
                break;
            default:
                return null;
        }
    }
    // function to call onclick on image
    const onClickImage = (aboutText) => {
        if (isEditProfile) {
            setAboutImage(aboutText);
            handleShow();
        }
    }
//#region render
    return (
        <Fragment>
            {/* Image Upload Modal */}
            {show &&
                <MyProfileUploadModal show={show} handleClose={handleClose} uploadFor={aboutImage} GetMyProfileData={GetMyProfileData} getFile={getUploadedFile} />
            }
            <Card>
                <Card.Header>
                    <div className={` ${styles.documentCardHeader}`}>
                        <h3 className="c-lg d-inline-block">About Me</h3>
                        {isEditProfile &&
                            <div className="d-inline-block float-end me-2">
                                <FontAwesomeIcon icon={faPen} className={` ${styles.editIconStyle}`} onClick={() => setClickedField('description')} title="Edit Description" />
                            </div>
                        }
                    </div>
                </Card.Header>
                <Card.Body className="px-4">
                    {clickedField === 'description' ? (
                        // Edit About Description Field
                        <Form onSubmit={ApiCallForProfileUpdate}>
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="my-custom-editor">
                                    <Form.Group>
                                        {/* Html Editor for About description */}
                                        <MyStatefulEditor markup={aboutDescription ? aboutDescription : ''} onChange={onChange} onBlur={ApiCallForProfileUpdate} value={aboutDescription ? aboutDescription : ''} className="" placeholder='Write something about you...' />
                                    </Form.Group>
                                </Col>
                                {/* Save and Cancle button here */}
                                <div className={`my-3 ${styles.formBtnContainer}`}>
                                </div>
                            </Row>
                        </Form>
                    ) : (
                        <>{/* View About Description  */}
                            <div className={styles.aboutMeText}>
                                {!["<p><br></p>", "", null, undefined].includes(myProfileDetails?.description) ? (
                                    <div dangerouslySetInnerHTML={{ __html: myProfileDetails?.description }} />
                                ) : (
                                    <><p className="c-lg text-center mb-0">No Data Available</p></>
                                )}
                            </div>
                        </>
                    )}
                </Card.Body>
            </Card>
            {/*About Images here*/}
            <Card className="mt-4">
                <Card.Header>
                    <div className={styles.documentCardHeader}>
                        <h3 className="c-lg">Photos</h3>
                    </div>
                </Card.Header>
                <Card.Body>
                    <div className="pb-4">
                        <div className="d-flex  flex-row justify-content-center align-items-start  flex-wrap">
                            {isEditProfile ? (<>{/* Images shows in editable mode */}
                                <img src={aboutImagesObj.about_image_1 ? aboutImagesObj.about_image_1 : AddProfile} alt="image1" className={styles.aboutMePhotos} onClick={() => { onClickImage('about_image_1') }} />
                                <img src={aboutImagesObj.about_image_2 ? aboutImagesObj.about_image_2 : AddProfile} alt="image2" className={styles.aboutMePhotos} onClick={() => { onClickImage('about_image_2') }} />
                                <img src={aboutImagesObj.about_image_3 ? aboutImagesObj.about_image_3 : AddProfile} alt="image3" className={styles.aboutMePhotos} onClick={() => { onClickImage('about_image_3') }} />
                            </>
                            ) : (<>{/* Images shows to admin while view profile (view only)*/}
                                {(aboutImagesObj.about_image_1 || aboutImagesObj.about_image_2 || aboutImagesObj.about_image_3) ? (
                                    <>
                                        {aboutImagesObj.about_image_1 &&
                                            <img src={aboutImagesObj.about_image_1} alt="image1" className={styles.aboutMePhotos} />
                                        }{aboutImagesObj.about_image_2 &&
                                            <img src={aboutImagesObj.about_image_2} alt="image2" className={styles.aboutMePhotos} />
                                        }{aboutImagesObj.about_image_3 &&
                                            <img src={aboutImagesObj.about_image_3} alt="image3" className={styles.aboutMePhotos} />
                                        }
                                    </>
                                ) : (
                                    <p className="c-lg text-center mb-0">Not Available</p>
                                )}
                            </>
                            )}

                        </div>
                    </div>
                </Card.Body>
            </Card>
        </Fragment>

    );
};
//#endregion render
export default AboutMe;