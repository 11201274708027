import React, { useState } from 'react';
import { Pagination } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleRight, faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons'
import '../assets/global-css/Index.css';

const CustomPagination = (props) => {
  // const [activeItem, setActiveItem] = useState(2);
  const { isSingleScreen, setPreviousItemActiveValue, setStartingIndex, totalPages, size = "md", withIcons = false, disablePrev = false, activeItem, setActiveItem } = props;

  const onPrevItem = () => {
    const prevActiveItem = activeItem === 1 ? activeItem : activeItem - 1;
    setStartingIndex((prevActiveItem * 10) - 9);
    if (setPreviousItemActiveValue != undefined) {
      console.log('here');
      setPreviousItemActiveValue(activeItem);
    }
    setActiveItem(prevActiveItem);
  };

  const onNextItem = (totalPages) => {
    const nextActiveItem = activeItem === totalPages ? activeItem : activeItem + 1;
    setStartingIndex((nextActiveItem * 10) - 9);
    if (setPreviousItemActiveValue != undefined) {
      console.log('here');
      setPreviousItemActiveValue(activeItem);
    }
    setActiveItem(nextActiveItem);
  };

  const items = [];
  for (let number = 1; number <= totalPages; number++) {
    const isItemActive = activeItem === number;

    const handlePaginationChange = () => {
      setStartingIndex((number * 10) - 9);
      setActiveItem(number);
    };

    items.push(
      <Pagination.Item className={`${(isSingleScreen === true) && 'f-16'}`} active={isItemActive} key={number} onClick={handlePaginationChange}>
        {number}
      </Pagination.Item>
    );
  };

  return (
    <Pagination size={size} className={`mt-3 justify-content-end `}>
      <Pagination.Prev className={`${(isSingleScreen === true) && 'f-16'}`} disabled={disablePrev} onClick={onPrevItem}>
        {withIcons ? <FontAwesomeIcon icon={faAngleDoubleLeft} /> : "Previous"}
      </Pagination.Prev>
      {items}
      <Pagination.Next className={`${(isSingleScreen === true) && 'f-16'}`} onClick={() => onNextItem(totalPages)}>
        {withIcons ? <FontAwesomeIcon icon={faAngleDoubleRight} /> : "Next"}
      </Pagination.Next>
    </Pagination>
  );
};

export default CustomPagination;