
// Library import
import React, { useEffect } from 'react';

// Local import
import { DataLoading } from '../../components/air-migrate/Loader.jsx';
import { verifyChatUserAPI } from '../../actions/ChatsActions.jsx';
import { useHistory } from "react-router-dom";
import { toast } from 'react-hot-toast'
import messageHelper from '../../utilities/helpers/messageHelper.js';

// Chat link verify
const ChatLinkVerify = (props) => {
    const history = useHistory()
    const { userId, caseId } = props?.match?.params

    // First Check, User verified or not
    useEffect(() => {
        verifyUser()
    }, [])

    // verify user by API 
    const verifyUser = async () => {
        const response = await verifyChatUserAPI(userId, caseId)
        if (response?.status_code == 1) {
            let caseName = response.data[0].caseName
            let userName = response.data[0].userName
            history.push('/chat', { userId, caseId, caseName, userName })
        }
        else {
            toast.error(messageHelper('link-expired'));
            history.push('/examples/404')
        }
    }

    return (
        <DataLoading />
    )

}

export default ChatLinkVerify