/*
A common case actions file , which contains all the api's which will be used in agents module.
*/

import { authApi } from '../utilities/helpers/ApiHelper';
import { toast } from 'react-hot-toast';


async function CreateClientApi(clientDetails) {
    try {
        const response = await authApi.postAPI('client', clientDetails);
        return response;
    }
    catch (e) {
        toast.error("Something went wrong!", { duration: 4000 });
    }
}

//Update agent form api call
async function UpdateClientApi(clientDetails, id) {

    try {
        const response = await authApi.updateAPI(`client/${id}`, clientDetails);
        if (response.status_code === 1) {
            toast.success(response.message, { duration: 4000 });
        }
        else toast.error("Something went wrong!", { duration: 4000 });
    }
    catch (e) {
        toast.error("Something went wrong!", { duration: 4000 });
    }

}
//Update case name 
async function UpdateCaseNameApi(caseDetails, id) {
    try {
        const response = await authApi.updateAPI(`update-cases-name/${id}`, caseDetails);
        if (response.status_code === 1) {
            toast.success(response.message, { duration: 4000 });

        }
        else toast.error(response.message, { duration: 4000 });
        return response;
    }
    catch (e) {
        toast.error("Something went wrong!", { duration: 4000 });
    }

}

//Get agent details by id api call
async function GetAssociatedMemberListForClientAndCaseApi(userId, caseId) {
    let url = `get-client-cases-associated-member/${userId}/${caseId}?primary=true`;
    try {
        const response = await authApi.getAPI(url);
        if (response.status_code == 1) {
            return response.data;
        }
        else {
            toast.error(response.message, { duration: 3000 });
        }
    }
    catch (e) {
        toast.error("Something went wrong!", { duration: 4000 });
    }
}

//Get agent details by id api call
async function GetCaseNameApi(caseId) {
    let url = `get-case-name/${caseId}`;
    try {
        const response = await authApi.getAPI(url);
        if (response.status_code == 1) {
            return response.data;
        }
        else {
            toast.error(response.message, { duration: 3000 });
        }
    }
    catch (e) {
        toast.error("Something went wrong!", { duration: 4000 });
    }
}

async function GetClientListApi(startingIndex, pageLimit, searchText) {

    let url = ``;

    if (startingIndex && pageLimit && searchText) {
        url = `client?start=${startingIndex}&limit=${pageLimit}&search=${searchText}`
    }
    else if (startingIndex && pageLimit) {
        url = `client?start=${startingIndex}&limit=${pageLimit}`
    }
    try {
        const response = await authApi.getAPI(url);
        // if (response.status_code === 1) {
        //     return response.data;
        // } else {
        //     toast.error(response.message, { duration: 4000, },);
        // }
        return response;
    }
    catch (e) {
        toast.error("Something went wrong!", { duration: 4000 });
    }
}

async function CreateDocumentRequestApi(DocumentModal) {
    try {
        const response = await authApi.postAPI('create-documents-access-request', DocumentModal);
        return response;
        // if (response.status_code == 1) {
        //     toast.success(response.message, { duration: 3000 })
        //     return response.data;
        // }
        // else toast.error(response.message, { duration: 3000 })
    }
    catch (e) {
        toast.error("Something went wrong!", { duration: 4000 });
    }
}

async function CreateClientForCaseApi(id, clientDetails) {
    let url = `invite-user-in-case/${id}`;
    try {
        const response = await authApi.postAPI(url, clientDetails);
        return response;
        //    if(response.status_code == 1){
        //     toast.success(response.message,{duration: 3000})
        //     return response.data;
        //    }
        //    else   toast.error(response.message,{duration: 3000})
    }
    catch (e) {
        toast.error("Something went wrong!", { duration: 4000 });
    }
}
// async function  GetConsultantBrokerCaseListApi(startingIndex, pageLimit, searchText,role_id) {

//     // let url = `get-my-cases-with-members/${role_id}`;
//     var url = ``;
//     if (searchText !== '' && searchText!== undefined && searchText !== null) {
//         url = `get-my-cases-with-members/${role_id}?search=${searchText}`;
//     }
//     else  {
//         url = `get-my-cases-with-members/${role_id}`;
//     }
//     try {
//         const response = await authApi.getAPI(url);
//         console.log("immi. consultant",response)
//         // if (response.status_code === 1) {
//         //     return response.data;
//         // } else {
//         //     toast.error(response.message, { duration: 4000, },);
//         // }
//         return response;
//     }
//     catch (e) {
//         toast.error("Something went wrong!", { duration: 4000 });
//     }
// }

async function GetConsultantBrokerCaseListApi(startingIndex, pageLimit, searchText, role_id) {
    let url = `get-my-cases-with-members/${role_id}`;

    if (searchText !== '' && searchText !== undefined && searchText !== null) {
        url += `?search=${searchText}`;
    }

    if (startingIndex !== undefined && pageLimit !== undefined) {
        url += `${searchText ? '&' : '?'}start=${startingIndex}&limit=${pageLimit}`;
    }

    try {
        const response = await authApi.getAPI(url);
        console.log("immi. consultant", response);
        // if (response.status_code === 1) {
        //     return response.data;
        // } else {
        //     toast.error(response.message, { duration: 4000 });
        // }
        return response;
    } catch (e) {
        toast.error("Something went wrong!", { duration: 4000 });
    }
}

// remove requested document
const RemoveRequestedDocumentsAPI = async(Url,data) => {
    // let url = Url;
    // let url = `document-request-remove`;
    try {
        const response = await authApi.deleteAPI(Url, data);
        return response;
    }
    catch (e) {
        toast.error("Something went wrong!", { duration: 4000 });
    }
}

export {
    CreateClientApi, UpdateClientApi, GetClientListApi, GetAssociatedMemberListForClientAndCaseApi
    , CreateDocumentRequestApi, CreateClientForCaseApi, GetConsultantBrokerCaseListApi, UpdateCaseNameApi,
    GetCaseNameApi, RemoveRequestedDocumentsAPI
}