/*
A common payment actions file , which contains all the api's which will be used in agents module.
*/

import { authApi } from '../utilities/helpers/ApiHelper';
import { toast } from 'react-hot-toast';


//Create agent form submition api call
async function CreatePaymentApi(paymentDetails, id) {
    let url = `client-payment-details/${id}`;
    try {
        const response = await authApi.postAPI(url, paymentDetails);
        return response;
    }
    catch (e) {
        toast.error("Something went wrong!", { duration: 4000 });
    }
}

async function CreatePaymentIsVisibleApi( id, paymentDetails) {
    let url = `payment-is-visible/${id}`;
    try {
        const response = await authApi.postAPI(url, paymentDetails);
        return response;
    }
    catch (e) {
        toast.error("Something went wrong!", { duration: 4000 });
    }
}

//Update payment form api call
async function UpdatePaymentApi(paymentDetails, id) {

    let url = `client-payment-details/${id}`
    try {
        const response = await authApi.updateAPI(url, paymentDetails);
        return response;
        // if (response.status_code === 1) {
        //     toast.success(response.message, { duration: 4000 });
        // }
        // else toast.error(response.message, { duration: 4000 });
    }
    catch (e) {
        toast.error("Something went wrong!", { duration: 4000 });
    }
}

//Get payment details by id api call
async function GetPaymentDetailsByIdApi(id) {
    let url = `client-payment-details/${id}`;
    try {
        const response = await authApi.getAPI(url);
        if (response.status_code === 1) {
            return response.data;
        } else {
            toast.error(response.message, { duration: 4000, },);
        }
        return response.data;
    }
    catch (e) {
        toast.error("Something went wrong!", { duration: 4000 });
    }
}

//Get payment list api
async function GetPaymentListApi(id) {
console.log("hello payment");
    let url = `client-payment-details/${id}`;
    try {
        const response = await authApi.getAPI(url);
        if (response.status_code === 1) {
            return response.data;
        } else {
            toast.error(response.message, { duration: 4000 });
        }
    }
    catch (e) {
        toast.error("Something went wrong!", { duration: 4000 });
    }
}

//Delete payment installment api
async function DeletePaymentInstallmentApi(paymentDetails, id) {
    let url = `client-payment-details/${id}`;
    try {
        const response = await authApi.deleteAPI(url, paymentDetails);
        return response;
    }
    catch (e) {
        toast.error("Something went wrong!", { duration: 4000 });
    }
}

export { CreatePaymentApi, UpdatePaymentApi, GetPaymentDetailsByIdApi, 
    GetPaymentListApi, DeletePaymentInstallmentApi, CreatePaymentIsVisibleApi }