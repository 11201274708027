import React, { Fragment, useEffect, useState } from "react";
import { Card, ListGroup, Button, Row, Col, Form, Breadcrumb, Image, Alert } from '@themesberg/react-bootstrap'
import { Link, useHistory, useLocation } from 'react-router-dom';
import { faHome, faMapMarked, faMapMarker, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast, Toaster } from "react-hot-toast";
import RoomIcon from '@mui/icons-material/Place';

//Local imports
import ViewClientDocumentModel from "../../../components/air-migrate/ViewClientDocumentsModel";
import Profile from "../../../assets/img/client/client_profile.jpg";
import Camera from "../../../assets/img/client/Camera.svg";
import Verify from "../../../assets/img/client/verifyIcon.svg";
import RightArrow from "../../../assets/img/client/rightarrow.svg";
import { Capitalize, CapitalizeAllWords } from "../../../common-components/StringConversions";
import {
  GetClientDetailsByIdApi, GetClientDetailsByIdForBrokerApi, CreateClientNotesApi, GetClientNotesByIdApi,
  GetClientDocumentCountApi, EditClientNotesApi, CreateClientInfoRequestApi,
  GetCaseListForClientApi, GetAssociatedMemberListForClientApi,
  CreateClientApi
} from "../../../actions/ClientActions";
import { GetPaymentListApi } from "../../../actions/PaymentActions";
import "./Index.css";
import '../../../assets/global-css/Index.css';
import { DataLoading } from "../../../components/air-migrate/Loader";
import EditPayment from "../edit-payment/Index";
import TemplateForm from "../../templates/common-component/TemplateForm";
import AccordionComponent from "../../../components/AccordionComponent";
import { Accordion, Dropdown } from '@themesberg/react-bootstrap';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import QuestionAnswerRoundedIcon from '@mui/icons-material/QuestionAnswerRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import styles from '../../cases/case-details/Index.module.css';
import { profilePath } from "../../../config.tsx";
import NoDataFound from "../../../components/air-migrate/NoDataFound.jsx";
import MyProfileUploadModal from "../../my-profile/components/MyProfileUploadModal.jsx";
import CommonServices from "../../../common-components/CommonServices.jsx";
import Edit from '../../../assets/img/icons/Airmigrate icons/16px/Edit.png';
import Chat from '../../../assets/img/icons/Airmigrate icons/16px/Chat.png';
import { HomepageUrl } from "../../../utilities/helpers/Constant.jsx";
import '../../../assets/mobile-custom-css/Index.css';
import ProfileIconsModal from "../../../components/air-migrate/ProfileIconsModal.jsx";
import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import CommonAlert from "../../../common-components/CommonAlert.jsx";
import CommonBreadcrumb from "../../../common-components/CommonBreadcrumb.jsx";

const EditClient = () => {

  //#region State and variables
  const history = useHistory();
  const location = useLocation();
  const [userDetails, setUserDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [caseList, setCaseList] = useState([]);
  const [associatedMemberList, setAssociatedMemberList] = useState([]);

  // Get logged in user role id, logged in user uuid
  const [userRoleId, setUserRoleId] = useState(localStorage.getItem('roleId'));
  const [userId, setUserId] = useState(location?.state.userId);
  const [loginUserId, setLoginUserId] = useState(localStorage.getItem('amUserId'));

  //States to manage mobile ui
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const [singleScreen, setSingleScreen] = useState(false);

  const [isToastVisible, setIsToastVisible] = useState(false);

  //Alert states
  const [alertsArray, setAlertsArray] = useState([]);
  // add new case button
  const [isAddNewCase, setIsAddNewCase] = useState(false);
  const [newCaseName, setNewCaseName] = useState('');


  //#endregion

  //#region useEffect
  useEffect(() => {
    GetClientDetails();
    GetCaseListForClient();
    GetAssociatedMemberListForClient();

    //functionality to set collapsible table 
    window.addEventListener('resize', updateDimension);
    updateDimension();

    // removing eventlistener for no furhter effect after 
    return (() => {
      window.removeEventListener('resize', updateDimension);
    })
  }, [count])
  //#endregion

  //#region Functions

  const updateDimension = () => {
    setScreenSize(getCurrentDimension());
    var value = getCurrentDimension();
    // set a variable true when screen width reaches <768px
    if (value.width <= 767) {
      setSingleScreen(true)
    } else {
      setSingleScreen(false)
    }
  }

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight
    }
  }
  const handleAlert = (id) => {
    let array = [];
    for (let i = 0; i < alertsArray.length; i++) {
      if ((alertsArray[i].id) == id) {
        array = alertsArray.pop();

      }
    }
    console.log('alertsArray', alertsArray);
    setCount(Math.floor(Math.random() * 1000))
  }

  const GetClientDetails = async () => {
    setIsLoading(true);
    const clientDetails = await GetClientDetailsByIdApi(userId);
    if (clientDetails) {
      setUserDetails(clientDetails[0]);
    }
    setIsLoading(false);
  }

  const GetCaseListForClient = async () => {
    setIsLoading(true);
    const response = await GetCaseListForClientApi(userId);
    if (response) {
      setCaseList(response);
    }
    setIsLoading(false);
  }

  const GetAssociatedMemberListForClient = async () => {
    setIsLoading(true);
    const response = await GetAssociatedMemberListForClientApi(userId);

    let array = [];
    if (response && response.length) {
      console.log()
      for (let i = 0; i < response.length; i++) {
        console.log("response[i].user_uuid", response[i].user_uuid, userId)
        if (response[i].user_uuid !== userId) {
          array.push(response[i]);

        }
      }

      console.log("array 2", array);
      setAssociatedMemberList(array);
    }

    // if (response) {
    //   setAssociatedMemberList(response);
    // }
    setIsLoading(false);
  }

  const CreateClientInfoRequest = async (id, fieldName) => {
    setIsToastVisible(true);
    setIsLoading(true);
    const requestBody = {
      column_name: fieldName
    }
    const response = await CreateClientInfoRequestApi(requestBody, id);

    if (response?.status_code == 1) {
      setAlertsArray([...alertsArray, { id: `create-${fieldName}-request${alertsArray.length}`, type: 'success', message: response.message }]);

    }
    else {
      setAlertsArray([...alertsArray, { id: `create-${fieldName}-request${alertsArray.length}`, type: 'danger', message: response.message }]);

    }

    //Call details api to update request status
    if (fieldName == 'profile') {
      GetAssociatedMemberListForClient();

    }
    else {
      GetClientDetails();
    }
    setIsLoading(false);
  }

  /* unleash check for associated member list  */
  const getClientCaseAssociateMemberFeatureFlag = CommonServices.GetUnleashFeatureFlag('frontend-Get-client-cases-associated-member');
  // add new case
  const handleAddNewCase = async () => {
    if(newCaseName.length > 50 || newCaseName.length == 0){
      return
    }

    const response = await CreateClientApi({'case_keyword': newCaseName}, userId)
    if (response?.status_code == 1) {
      setAlertsArray([...alertsArray, { id: `create-request${alertsArray.length}`, type: 'success', message: response.message }]);
      setIsAddNewCase(false)
      setNewCaseName('')
      GetCaseListForClient()
    }
    else {
      setAlertsArray([...alertsArray, { id: `create-request${alertsArray.length}`, type: 'danger', message: response.message }]);
    }
  }

  const [breadcrumbArrayForAgents, setBreadcrumbArrayForAgents] = useState([
    { name: 'Home', url:HomepageUrl },
    { name: 'Clients', url: '/admin/clients' },
    { name: 'Edit Client', url: '' }
]);

const [breadcrumbArray, setBreadcrumbArray] = useState([
  { name: 'Home', url:HomepageUrl },
  { name: 'Edit Client', url: '' }
]);

  //#endregion

  //#region JSX
  return (
    <Fragment>
      {/* Breadcrumbs */}
      <div className="d-block mb-4 mb-md-0">

        <CommonBreadcrumb breadcrumbArray={(userRoleId == 2 || userRoleId == 4) ? breadcrumbArrayForAgents : breadcrumbArray}/>

        <div className={`edit-case-heading-details-blcok`}>
          <h4 className={`${singleScreen === true ? 'f-18 ' : 'mt-2'}`}>Edit Client</h4>

          {/* Add new client for consultant */}
          { userRoleId == 2 &&
           ( (!isAddNewCase) ?
              <button onClick={() => { setIsAddNewCase(true) }} className={`add-new-case-details-button ${singleScreen === true ? 'f-16 pl-1' : 'mt-2'} `}>Add New Case </button>
              :
              <div className="d-flex justify-start mb-2 mt-2">
                <Form.Group className="mt-0 me-2">
                  <Form.Control
                    id="case_name"
                    name="case_name"
                    value={newCaseName}
                    type="text"
                    placeholder=""
                    onChange={(e) => setNewCaseName(e.target.value)}
                  />
                  {newCaseName.length > 50 &&
                    <p style={{ color: "red" }}>Case name max length is 50 </p>
                  }
                </Form.Group>
                <Button variant="success" className="mt-0 " type="submit" style={{ maxHeight: "42px" }} onClick={handleAddNewCase}>Save</Button>
              </div>)
          }
        </div>
      </div>
      <Row className="p-0 ">
        <Col lg={12} sm={12} md={12} xs={12} xl={12}>
          {(alertsArray.length > 0) &&
            alertsArray.reverse().map((item, index) => {
              return (
                <CommonAlert key={index} id={item.id} type={item.type} message={item.message} handleAlert={handleAlert} />
              )
            })
          }
        </Col>
      </Row>
      <Row className="">
        {/* Client personal Details section*/}
        <Col lg={4} md={12} xl={4} sm={12}>
          <Row>
            <Col xl={12} lg={12} md={12} sm={12} >

              <Card className="client-profile-block1">

                <div className="client-profile-custom-block">
                  {/* <div className="block1-img-container">
                    {userDetails?.profile_path ?
                      <Card.Img
                        src={userDetails?.profile_path}
                        className="client-profile-img"
                      /> :
                      <div className={` bg-light rounded-circle d-flex justify-content-center align-items-center img-d-100`}  >
                        {((userDetails.first_name != null) && (userDetails.last_name != null)) ?
                          <p className=" fw-bolder pt-2 c-lg f-18" >{CommonServices.getNameCharByFullName(userDetails?.first_name + ' ' + userDetails?.last_name)}</p> :
                          (userDetails.first_name != null) ?
                            <p className=" fw-bolder pt-2 c-lg f-18" >{CommonServices.getNameCharByFullName(userDetails?.first_name + ' ' + userDetails?.last_name)}</p> :
                            <p className=" fw-bolder pt-2 c-lg f-18" >U</p>
                        }
                      </div>
                    }
                    
                  </div>

                  <div className="client-profile-text-block">
                    <h5 className="fw-bold c-lg" >
                      {userDetails?.first_name == null ? 'User' : Capitalize(userDetails?.first_name) + " "}
                      {userDetails?.first_name == null ? '' : userDetails?.last_name}
                    </h5>
                    {userDetails?.email === null ?
                      <p className="mb-0" >
                        Email not found
                      </p> :
                      <p className="user-email-info mb-0" >
                        {userDetails?.email == null ? '' : userDetails?.email}
                      </p>
                    }
                    {(userDetails?.location == '' || userDetails?.location == null) ?
                      <p className="client-footer-text ml-0"> {''}</p> :
                      <p className="client-footer-text ml-0"> <FontAwesomeIcon icon={faMapMarkerAlt} className="pr-2" />{userDetails?.location}</p>
                    }
                  </div> */}




                  <Row className="align-items-center pe-none text-wrap img-block" >
                    <Col className="col-auto imageLeftLess ">
                      <ProfileIconsModal userDetails={userDetails} />
                    </Col>
                    <Col className="ms--2">
                      <h4 className=" mb-0 f-16 c-lg text-wrap " >
                        {userDetails?.first_name !== null && userDetails?.last_name !== null ?
                          Capitalize(userDetails?.first_name) + " " + Capitalize(userDetails?.last_name) :
                          (userDetails?.first_name !== null) ? Capitalize(userDetails?.first_name) : 'User'
                        }
                      </h4>
                      <span className="f-16 c-lg">
                        {userDetails?.email && <>
                          <span className="text-break c-lg"
                          >{userDetails?.email}</span><br />
                        </>}
                      </span>
                      <span className="f-16 c-lg  text-wrap">
                        {(userDetails?.location == '' || userDetails?.location == null) ?
                          <span className=""> {''}</span> :
                          <span className="ml-0 pl-0 c-db">

                            <FontAwesomeIcon icon={faMapMarkerAlt} className="pr-2" />
                            <span className="ml-2 ma-le-5">{userDetails?.location}</span>
                          </span>
                        }
                      </span>

                    </Col>
                  </Row>
                </div>


                <Card.Body className="profile-block1-body">
                  <Card.Text className="client-profile-block1-text">
                    <div className="client-profile-block2">

                      {/* Other details starts here */}
                      <ListGroup>

                        {/*Primary Phone*/}
                        <ListGroup.Item>
                          <div className="client-block1-details">
                            <div >
                              <p className="fw-bold f-16" variant="primary">Phone</p>
                              <p className={`fs-small ${((userRoleId == 4 || userRoleId == 1) && (userDetails?.primary_phone == null || userDetails?.primary_phone == undefined || userDetails?.primary_phone == 'Requested' || userDetails?.primary_phone == '')) ? 'blurred-name' : ''}`}>
                                {userDetails?.primary_country_code !== null && userDetails?.primary_country_code}{" "}
                                {(userDetails?.primary_phone == null || userDetails?.primary_phone == '' || userDetails?.primary_phone == 'Requested') ? 'Not available' : userDetails?.primary_phone}
                              </p>
                            </div>
                            <div className="details-block1-icon">
                              {(userRoleId == 4 || userRoleId == 1) ?
                                <div className={styles.associatedbuttonblock} >
                                  {userDetails?.primary_phone == 'Requested' ?
                                    <button className="pe-auto bg-c-lb f-16 btn-req" >Requested </button>
                                    : ((userDetails?.primary_phone == null || userDetails?.primary_phone == '')) &&
                                    <button className="pe-auto bg-c-dg f-16 btn-req"
                                      onClick={() => { CreateClientInfoRequest(userDetails?.user_uuid, "primary_phone") }}
                                    > Request </button>
                                  }
                                </div> :
                                <>
                                  {(userDetails?.phone_is_verify == true) &&
                                    <img src={Verify} alt="verified" className="me-2" />
                                  }
                                  <img src={RightArrow} alt="" />
                                </>
                              }
                            </div>
                          </div>
                        </ListGroup.Item>

                        {/*Secondary Phone*/}
                        <ListGroup.Item>
                          <div className="client-block1-details d-flex">
                            <div className="">
                              <p className="fw-bold f-16" variant="primary">Secondary Phone</p>
                              <p className={`fs-small ${((userRoleId == 4 || userRoleId == 1) && (userDetails?.secondry_phone == null || userDetails?.secondry_phone == '' || userDetails?.secondry_phone == 'Requested')) ? 'blurred-name' : ''}`}>{(userDetails?.secondry_phone == null || userDetails?.secondry_phone == '' || userDetails?.secondry_phone == 'Requested') ? 'Not available' : `${userDetails?.secondry_phone} `}  </p>
                            </div>
                            {(userRoleId == 4 || userRoleId == 1) ?
                              <div className={`${styles.associatedbuttonblock} `} >
                                {userDetails?.secondry_phone == 'Requested' ?
                                  <button className="pe-auto bg-c-lb f-16 btn-req">Requested </button>
                                  : ((userDetails?.secondry_phone == null || userDetails?.secondry_phone == '')) &&
                                  <button
                                    className="pe-auto bg-c-dg f-16 btn-req"

                                    onClick={() => { CreateClientInfoRequest(userDetails?.user_uuid, "secondry_phone") }}
                                  > Request</button>
                                }

                              </div> :
                              <div className="details-block1-icon">
                                {(userDetails?.secondry_phone_verified == true) &&
                                  <img src={Verify} alt="verified" className="me-2" />
                                }
                                <img src={RightArrow} alt="" />
                              </div>
                            }

                          </div>

                        </ListGroup.Item>

                        {/*Gender*/}
                        <ListGroup.Item>
                          <div className="client-block1-details d-flex">
                            <div className="">
                              <p className="fw-bold" style={{ fontSize: "16px" }} variant="primary">Gender</p>
                              {(userDetails?.gender == null || userDetails?.gender == '' || userDetails?.gender == undefined || userDetails?.gender == 'Requested') ?
                                <p className={`fs-small ${(userRoleId == 4 || userRoleId == 1) ? 'blurred-name' : ''}`}>Not available</p> :
                                <p className={`fs-small `}>{userDetails?.gender}</p>

                              }
                            </div>
                            {(userRoleId == 4 || userRoleId == 1) &&
                              <div className={styles.associatedbuttonblock} >

                                {userDetails?.gender == 'Requested' ?
                                  <button className="btn-req f-16 bg-c-lb" >Requested </button>
                                  : ((userDetails?.gender == null || userDetails?.gender == '')) &&
                                  <button

                                    className="pe-auto bg-c-dg f-16 btn-req"
                                    onClick={() => { CreateClientInfoRequest(userDetails?.user_uuid, "gender") }}
                                  > Request </button>
                                }
                              </div>
                            }
                          </div>
                        </ListGroup.Item>

                        {/* Parmanent address */}
                        <ListGroup.Item>
                          <div className="client-block1-details d-flex">
                            <div className="">
                              <p className="fw-bold" style={{ fontSize: "16px" }} variant="primary">Parmanent address</p>

                              {/*Manage address view*/}
                              {((userDetails?.primary_address !== null) && (userDetails?.primary_address != undefined) && (userDetails?.primary_address != "Requested")) ?
                                <>
                                  {Object.keys(userDetails?.primary_address).length != 0 ?
                                    <p className={`fs-small 
                                  
                                    `}>{userDetails?.primary_address?.address}, {userDetails?.primary_address?.city}, {userDetails?.primary_address?.country} {" "} {userDetails?.primary_address?.postal_code}</p>
                                    :
                                    <p className={`fs-small ${(userRoleId == 4 || userRoleId == 1) ? 'blurred-name' : ''}`}> Not available</p>
                                  }
                                </>
                                :
                                <p className={`fs-small ${(userRoleId == 4 || userRoleId == 1) ? 'blurred-name' : ''}`}> Not available</p>
                              }
                            </div>
                            {(userRoleId == 4 || userRoleId == 1) &&
                              <div className={styles.associatedbuttonblock} >

                                {userDetails?.primary_address == 'Requested' ?
                                  <button className="btn-req f-16 bg-c-lb">Requested </button>
                                  : ((userDetails?.primary_address == null || Object.keys(userDetails?.primary_address).length === 0)) &&
                                  <button
                                    className="pe-auto bg-c-dg f-16 btn-req"
                                    onClick={() => { CreateClientInfoRequest(userDetails?.user_uuid, "primary_address") }}
                                  > Request</button>
                                }
                              </div>
                            }
                          </div>
                        </ListGroup.Item>

                        {/*Communication address*/}
                        <ListGroup.Item>
                          <div className="client-block1-details">
                            <div className="">
                              <p className="fw-bold" style={{ fontSize: "16px" }} variant="primary">Communication address</p>
                              {/*Manage address view*/}
                              {(userDetails?.communcation_address !== null && userDetails?.communcation_address != undefined && userDetails?.communcation_address != 'Requested') ?
                                <>
                                  {Object.keys(userDetails?.communcation_address).length != 0 ?
                                    <p className={`fs-small `}>{userDetails?.communcation_address?.address}, {userDetails?.communcation_address?.city}, {userDetails?.communcation_address?.country} {" "} {userDetails?.communcation_address?.postal_code}</p>
                                    :
                                    <p className={`fs-small ${(userRoleId == 4 || userRoleId == 1) ? 'blurred-name' : ''}`}> Not available</p>
                                  }
                                </>
                                :
                                <p className={`fs-small ${(userRoleId == 4 || userRoleId == 1) ? 'blurred-name' : ''}`}>Not available </p>
                              }
                            </div>
                            {(userRoleId == 4 || userRoleId == 1) &&
                              <div className={styles.associatedbuttonblock} >


                                {userDetails?.communcation_address == 'Requested' ?
                                  <button className="btn-req f-16 bg-c-lb" >Requested </button>
                                  : ((userDetails?.communcation_address == null || Object.keys(userDetails?.communcation_address).length === 0)) &&
                                  <button
                                    className="pe-auto bg-c-dg f-16 btn-req"
                                    onClick={() => { CreateClientInfoRequest(userDetails?.user_uuid, "communcation_address") }}
                                  > Request </button>
                                }
                              </div>
                            }
                          </div>
                        </ListGroup.Item>

                        {/*Date of birth*/}
                        <ListGroup.Item>
                          <div className="client-block1-details d-flex">
                            <div className="">
                              <p className="fw-bold" style={{ fontSize: "16px" }} variant="primary">Date of Birth</p>
                              <p className={`fs-small ${((userRoleId == 4 || userRoleId == 1) && (userDetails?.date_of_birth == null || userDetails?.date_of_birth == '' || userDetails?.date_of_birth == 'Requested')) ? 'blurred-name' : ''}`}>
                                {(userDetails?.date_of_birth == null || userDetails?.date_of_birth == '' || userDetails?.date_of_birth == 'Requested') ? 'Not available' : userDetails?.date_of_birth}
                              </p>
                            </div>
                            {(userRoleId == 4 || userRoleId == 1) &&
                              <div className={styles.associatedbuttonblock} >
                                {userDetails?.date_of_birth == 'Requested' ?
                                  <button className="btn-req f-16 bg-c-lb">Requested </button>
                                  : ((userDetails?.date_of_birth == null || userDetails?.date_of_birth == '')) &&
                                  <button
                                    className="pe-auto bg-c-dg f-16 btn-req"
                                    onClick={() => { CreateClientInfoRequest(userDetails?.user_uuid, "date_of_birth") }}
                                  > Request </button>
                                }
                              </div>
                            }
                          </div>
                        </ListGroup.Item>

                        {/* Marital status*/}
                        <ListGroup.Item>
                          <div className="client-block1-details d-flex">
                            <div className="">
                              <p className="fw-bold" style={{ fontSize: "16px" }} variant="primary">Marital Status</p>
                              {

                                (userDetails?.married === null || userDetails?.married === '' || userDetails?.married === 'Requested') ?
                                  <p className={`fs-small ${(userRoleId == 4 || userRoleId == 1) ? 'blurred-name' : ''}`}>Not available</p> :
                                  <p className={`fs-small `}>{(userDetails?.married === true ? 'Married' : 'unmarried')}</p>

                              }
                            </div>
                            {(userRoleId == 4 || userRoleId == 1) &&
                              <div className={styles.associatedbuttonblock} >
                                {userDetails?.married == 'Requested' ?
                                  <button className="btn-req f-16 bg-c-lb">Requested </button>
                                  : ((userDetails?.married == null || userDetails?.married === "")) &&
                                  <button
                                    className="pe-auto bg-c-dg f-16 btn-req"
                                    onClick={() => { CreateClientInfoRequest(userDetails?.user_uuid, "married") }}
                                  > Request
                                  </button>
                                }
                              </div>
                            }
                          </div>
                        </ListGroup.Item>

                      </ListGroup>
                    </div>
                  </Card.Text>
                </Card.Body>
              </Card>

            </Col>
          </Row>
        </Col>


        {/* Documents section */}
        <Col lg={6} md={12} xl={4} sm={12} className="document-block-area">
          <ViewClientDocumentModel
            alertsArray={alertsArray}
            setAlertsArray={setAlertsArray}
            handleAlert={handleAlert}
            isEditProfile={true} userId={userId} setCount={setCount} count={count} userName={userDetails?.first_name + " " + userDetails?.last_name} />
        </Col>


        {/*Associated member and assiciated cases*/}
        <Col lg={6} md={12} xl={4} sm={12}>
          <Row className="row">
            <Col xl={12} lg={12} md={12} sm={12} >
              <Card className="client-profile-block1">
                <Card.Body className={styles.casesListblock}>
                  <Card.Text className="client-profile-block1-text">
                    <div className={styles.casesHeadingBlock} >
                      <h3 className="f-18 c-lg ff-nunito" >
                        List of cases for this client
                      </h3></div>
                    {caseList?.length > 0 ?
                      caseList?.map((item, index) => {
                        var isAriaDisabled = false;
                        if (item.case_status === 0 || item.case_status === 2) {
                          isAriaDisabled = true;
                        }
                        else if (item.case_status === 1) {
                          isAriaDisabled = false;
                        }
                        var originalCaseName = CapitalizeAllWords(item.case_name);
                        var editedCaseName = '';
                        if (originalCaseName.length > 20) {
                          editedCaseName = originalCaseName.slice(0, 19);
                        }
                        return (
                          <div className={`${styles.casescontentrow} ${(index < (caseList?.length - 1)) ? 'border-sep' : 'border-none'}`} key={index}
                          // style={{
                          //   borderBottom: `${(index < (caseList?.length - 1)) ? '1px solid #D1D7E0' : 'none'}`
                          // }}
                          >
                            <div className={`${styles.casestextinfo} f-16 c-lg`} >{originalCaseName.length > 20 ? editedCaseName + '...' : originalCaseName}</div>
                            <div className={[styles.casesiconblock]} >
                              <span className={[styles.editicon]}>
                                <Image
                                  className={`${isAriaDisabled ? 'cu-no' : 'cu-po'}`}
                                  src={Edit}
                                  onClick={() => {
                                    if (isAriaDisabled != true) {
                                      history.push('/cases/case-details', { userId: loginUserId, caseId: item.case_id, caseName: CapitalizeAllWords(item.case_name) });
                                    }

                                  }}
                                />

                              </span>
                              <span
                                className={[styles.viewicon]}
                              >
                                <Image
                                  className={`ma-le-5 ${item.case_status === 2 ? 'cu-no' : 'cu-po'}`}
                                  src={Chat}
                                  onClick={() => {
                                    if (item.case_status != 2) {
                                      history.push(`/chat`, { userId: loginUserId, caseId: item.case_id, caseName: `${CommonServices.getUserFullName(item)} (${item.case_name})` });
                                    }
                                  }}
                                />
                              </span>
                            </div>
                          </div>
                        )
                      })
                      : <NoDataFound />
                    }

                  </Card.Text>
                </Card.Body>
              </Card>

              {/*Associated Member*/}
              {getClientCaseAssociateMemberFeatureFlag &&
                <Card className={styles.peopleassociatedblock}>
                  <Card.Body className={[styles.peopleassociated]}>
                    <div className={styles.casesHeadingBlock} >
                      <h3 className="f-18 c-lg ff-nunito" >List of people associated
                        with this client</h3></div>
                    {associatedMemberList?.length > 0 ?
                      associatedMemberList?.map((item, index) => {
                        return (
                          <div className={`${styles.peopleassociatedcontent} ${(index < (associatedMemberList?.length - 1)) ? 'border-sep' : 'border-none'}`} key={index}

                          >
                            <div className={styles.associatedcontentleft}>
                              <div className="me-2" >
                                {/* {item.profile_path ?
                                <Image src={item.profile_path} alt="profile image" className="rounded-circle img-d-50 mr-2 ma-ri-10" />
                                :
                                <div className={`me-3 bg-light rounded-circle d-flex justify-content-center align-items-center img-d-50`} >
                                  {((item.first_name != null) && (item.last_name != null)) ?
                                    <p className=" fw-bolder pt-2 f-16" >{CommonServices.getNameCharByFullName(item?.first_name + ' ' + item?.last_name)}</p> :
                                    (item.first_name != null) ?
                                      <p className=" fw-bolder pt-2 f-16" >{CommonServices.getNameCharByFullName(item?.first_name + ' ' + item?.last_name)}</p> :
                                      <p className=" fw-bolder pt-2 f-16" >U</p>
                                  }
                                </div>

                              } */}
                                <ProfileIconsModal userDetails={item} />
                              </div>
                              <div className={styles.profiletextinfo}>
                                <p className={`fw-bold d-flex member-block-alignment  responsive-button f-16 ${((item.profile_access === true) || (item?.is_primary === true)) ? 'cu-po' : 'pe-none'}`}
                                  variant="primary"
                                >
                                  <span
                                    style={{ marginRight: '10px ' }}
                                    onClick={() => {
                                      setIsToastVisible(true);
                                      if (item?.profile_access == true || item?.is_primary == true) {
                                        history.push("/admin/clients/edit-client", { userId: item.user_uuid });
                                        setUserId(item.user_uuid);
                                        setCount(Math.floor(Math.random() * 100));
                                      }
                                      else {
                                        console.log('is false')
                                      }
                                    }}
                                  >
                                    {item?.first_name == null ? 'User' : Capitalize(item.first_name)}
                                  </span>
                                  {(userRoleId != 3 && item?.is_primary == false) &&
                                    <div className={styles.associatedbuttonblock} >
                                      {item?.access_id == null ?
                                        <button
                                          className="pe-auto bg-c-dg f-16"

                                          onClick={() => { CreateClientInfoRequest(item?.user_uuid, "profile") }}
                                        > Request </button> :
                                        ((item?.profile_access == false) || (item?.profile_access == null)) &&
                                        <button
                                          className="bg-c-lb f-16"

                                          onClick={() => { }}
                                        > Requested</button>
                                      }

                                    </div>
                                  }
                                </p>

                                {item.email &&
                                  <p
                                    className="f-16"

                                  >{item?.email ? item?.email : ''}</p>
                                }
                              </div>
                            </div>

                            {/* <Row className="align-items-center pe-none text-wrap img-block" >
                            <Col className={`col-auto imageLeftLess ${(item.profile_access == true || item?.is_primary == true) ? 'pe-auto' : 'pe-none'}`}
                              onClick={() => {
                                if (item?.profile_access == true || item?.is_primary == true) {
                                  history.push("/admin/clients/edit-client", { userId: item.user_uuid });
                                  setUserId(item.user_uuid);
                                  setCount(Math.floor(Math.random() * 100));
                                }
                                else {
                                  console.log('is false')
                                }
                              }}
                            >
                              <a className={` ${((item.profile_access === true) || (item?.is_primary === true)) ? 'cu-po' : 'pe-none'}`} >
                                {(item.profile_path == '' || item.profile_path == undefined || item.profile_path == null) ?
                                  <div className={` bg-light  d-flex justify-content-center align-items-center img-d-60-border`} >
                                    {((item.first_name != null) && (item.last_name != null)) ?
                                      <p className=" fw-bolder pt-2 c-lg f-14" >{CommonServices.getNameCharByFullName(item.first_name + ' ' + item.last_name)}</p> :
                                      (item.first_name != null) ?
                                        <p className=" fw-bolder pt-2 c-lg f-14" >{CommonServices.getNameCharByFullName(item.first_name + ' ' + item.last_name)}</p> :
                                        <p className=" fw-bolder pt-2 c-lg f-14">U</p>
                                    }
                                  </div>
                                  :
                                  <Image src={item.profile_path} className="img-d-60-border " />
                                }
                              </a>
                            </Col>
                            <Col className="ms--2">
                              <h4 className="mb-0  c-lg text-wrap f-16" >
                                {item.first_name !== null && item.last_name !== null ?
                                  Capitalize(item.first_name) + " " + Capitalize(item.last_name) :
                                  (item.first_name !== null) ? Capitalize(item.first_name) : 'User'
                                }
                              </h4>
                              <span className=" c-lg f-16">
                                {item.email && <>
                                  <span className="text-break f-16"
                                  >{item.email}</span><br />
                                </>}
                              </span>
                              
                            </Col>
                            {(userRoleId != 3 && item?.is_primary == false) &&
                              <Col className="">

                                <div className={styles.associatedbuttonblock} >
                                  {item?.access_id == null ?
                                    <button
                                      className="pe-auto btn-req bg-c-dg f-16 request-btn"

                                      onClick={() => { CreateClientInfoRequest(item?.user_uuid, "profile") }}
                                    > Request </button> :
                                    ((item?.profile_access == false) || (item?.profile_access == null)) &&
                                    <button
                                      className="btn-req f-16 requested-btn"

                                      onClick={() => { }}
                                    > Requested</button>
                                  }

                                </div>

                              </Col>
                            }
                          </Row> */}

                          </div>
                        )
                      })
                      : <NoDataFound />
                    }
                  </Card.Body>
                </Card>
              }
            </Col>
          </Row>
        </Col>
      </Row>
      {/* <Toaster position="top-right" reverseOrder={false} /> */}
      {/* <Toaster position="top-right" reverseOrder={false} toastOptions={{
        style: {
          display: isToastVisible ? 'auto' : 'none'
        },
      }} /> */}
      {isLoading === true && <DataLoading />}
    </Fragment>
  );
  //#endregion
};
export default EditClient;
