/*
A common direct login actions file , which contains all the api's which will be used in agents module.
*/

import { authApi } from '../utilities/helpers/ApiHelper';
import { toast } from 'react-hot-toast';

//#region  Get Api
//#endregion

//Create agent form submition api call
async function CreateAccessRequestApi(clientDetails) {
   console.log("post");
    try {
        var url = 'user-create-token'
        
        const response = await authApi.postAPI(url, clientDetails);
        return response;
    }
    catch (e) {
        toast.error("Something went wrong!", { duration: 4000 });
    }
}

//Update agent form api call
async function GetUsersApi(startingIndex, pageLimit, searchText) {

    let url = ``;

    if (startingIndex && pageLimit && searchText) {
        url = `user-create-token?start=${startingIndex}&limit=${pageLimit}&search=${searchText}`
    }
    else if (startingIndex && pageLimit) {
        url = `user-create-token?start=${startingIndex}&limit=${pageLimit}`
    }
    try {
        const response = await authApi.getAPI(url);
        return response;
    }
    catch (e) {
        console.error(e)
    }
}

//#endregion

export {
    CreateAccessRequestApi,  GetUsersApi
}