import { authApi } from '../utilities/helpers/ApiHelper';
import { toast } from 'react-hot-toast';


//Verify User For Chat
const verifyChatUserAPI = async (userId, consultantId) => {
    let URL = `verify_user_for_chat/${userId}/${consultantId}`;
    try {
        const response = await authApi.getAPI(URL);
        return response;
    }
    catch (e) {
        toast.error("Something went wrong!", { duration: 4000 });
    }
}

// Get all User Chat History
const getAllChatAPI = async (userId, caseId) => {
    let URL = `case-chat-history/${userId}/${caseId}`;
    try {
        const response = await authApi.getAPI(URL);
        return response;
    }
    catch (e) {
        toast.error("Something went wrong!", { duration: 4000 });
    }
}

// Get chat user meta
const getChatUserMetaStartCaseFormallyAPI = async (userId, caseId) => {
    let URL = `start-case-formally/${userId}/${caseId}`;
    try {
        const response = await authApi.getAPI(URL);
        return response;
    }
    catch (e) {
        toast.error("Something went wrong!", { duration: 4000 });
    }
}

// Get chat user meta
const getChatCaseCompleteStatusByCaseIdAPI = async (caseId) => {
    let URL = `chat-case-complete-status/${caseId}`;
    try {
        const response = await authApi.getAPI(URL);
        return response;
    }
    catch (e) {
        toast.error("Something went wrong!", { duration: 4000 });
    }
}

// Update chat case comleted status
const updateChatCaseCompletedStatus = async (caseId) => {
    let URL = `case-completed`;
    try {
        const response = await authApi.postAPI(URL, {'case_id':caseId});
        return response;
    }
    catch (e) {
        toast.error("Something went wrong!", { duration: 4000 });
    }
}

// Update chat user meta
const updateChatUserMetaStartCaseFormallyAPI = async (userId, caseId) => {
    let URL = `start-case-formally/${userId}/${caseId}`;
    try {
        const response = await authApi.postAPI(URL);
        return response;
    }
    catch (e) {
        toast.error("Something went wrong!", { duration: 4000 });
    }
}

const getClientListAPI = async () => {
    let URL = 'chat_client';
    try {
        const response = await authApi.getAPI(URL);
        return response;
    }
    catch (e) {
        toast.error("Something went wrong!", { duration: 4000 });
    }
}


// Used
const getAllChatUsersListAPI = async (userId, search, roleId, isArchivedList) => {
    let URL = `chat-cases/${userId}?roll_id=${roleId}&archivedList=${isArchivedList}`;
    URL = search ? `${URL}&search=${search}` : URL

    try {
        const response = await authApi.getAPI(URL);
        return response;
    }
    catch (e) {
        toast.error("Something went wrong!", { duration: 4000 });
    }
}

//Request accept/ Reject by broker api call
const AcceptOrRejectRequestAPI = async (requestModel) => {
    let URL = `update-broker-chat-request`;
    try {
        const response = await authApi.postAPI(URL, requestModel);
        return response;
    }
    catch (e) {
        toast.error("Something went wrong!", { duration: 4000 });
    }
}

const verifyInviteCase = async (requestModel) => {
    let URL = `invite-user-link-verify`;
    try {
        const response = await authApi.postAPI(URL, requestModel);
        return response;
    }
    catch (e) {
        toast.error("Something went wrong!", { duration: 4000 });
    }
}
// Download Esign
const downloadESignDocumentAPI = async (senderId, submissionID, caseId) => {
    let URL = `e-signed-document/${senderId}/${submissionID}/${caseId}`;
    try {
        const response = await authApi.getAPI(URL);
        return response;
    }
    catch (e) {
        toast.error("Something went wrong!", { duration: 4000 });
    }
}
// List of Document
const getCaseDocumentsCountAPI = async (caseID) => {
    let URL = `case-documents-count/${caseID}`;
    try {
        const response = await authApi.getAPI(URL);
        return response;
    }
    catch (e) {
        toast.error("Something went wrong!", { duration: 4000 });
    }
}

// Users Case Document Count
const getUsersCaseDocumentsCountAPI = async (caseID) => {
    let URL = `users-case-document-count/${caseID}`;
    try {
        const response = await authApi.getAPI(URL);
        return response;
    }
    catch (e) {
        toast.error("Something went wrong!", { duration: 4000 });
    }
}

// For Chat History, Doc count
const getCaseTotalDocumentCountAPI = async (caseID) => {
    let URL = `case-total-document-count/${caseID}`;
    try {
        const response = await authApi.getAPI(URL);
        return response;
    }
    catch (e) {
        toast.error("Something went wrong!", { duration: 4000 });
    }
}

// upload document list
const uploadDocumentListAPI = async (caseID, userId) => {
    let URL = `user-document-list/${caseID}/${userId}`;
    try {
        const response = await authApi.getAPI(URL);
        return response;
    }
    catch (e) {
        toast.error("Something went wrong!", { duration: 4000 });
    }
}


// upload document list
const noDocumentAPI = async (docId) => {
    let URL = `no-documents/${docId}`;
    try {
        const response = await authApi.postAPI(URL);
        return response;
    }
    catch (e) {
        toast.error("Something went wrong!", { duration: 4000 });
    }
}

// case step information data
const getCaseInformationStepAPI = async (caseId) => {
    const URL = `case-information-step/${caseId}`
    try{
        const response = await authApi.getAPI(URL)
        return response
    }catch (e) {
        toast.error("Something went wrong!", { duration: 4000 });
    }
}

export {
    verifyChatUserAPI, getAllChatAPI, getClientListAPI, getAllChatUsersListAPI,
    AcceptOrRejectRequestAPI,verifyInviteCase, downloadESignDocumentAPI, 
    getCaseDocumentsCountAPI, getUsersCaseDocumentsCountAPI, getCaseTotalDocumentCountAPI,
    uploadDocumentListAPI, noDocumentAPI, getChatUserMetaStartCaseFormallyAPI, updateChatUserMetaStartCaseFormallyAPI, 
    getChatCaseCompleteStatusByCaseIdAPI, updateChatCaseCompletedStatus, getCaseInformationStepAPI
}