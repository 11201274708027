//Common string operations

export const CapitalizeAllWords = (name) => {
  var nameArray = [];
  if (name !== undefined || name != null) {
    nameArray = name.trim().split(' ');
  }
  var modifiedName = '';

  nameArray.forEach(element => {
    modifiedName = modifiedName + element?.charAt(0)?.toUpperCase() + element?.slice(1) + " "
  });
  return modifiedName;
}

export const Capitalize = (name) => {
  return name?.charAt(0)?.toUpperCase() + name?.slice(1);
}

export const GetRoleNames = (roleId) => {
  switch (roleId) {
    case 1:
      return 'Admin';

    case 2:
      return 'Immigration Consultant';

    case 3:
      return 'Customer';

    case 4:
      return 'Broker';

    default:
      return 'User';
  }
}