import React, { Fragment, useEffect, useState } from "react";
// import ClientForm from "../common-component/ClientForm";
// import React, { useState, useEffect } from "react";
import { Nav, Tab } from "@themesberg/react-bootstrap";
import Select from "react-select";
import { useHistory } from 'react-router-dom';
import { isInputEvent, useFormik } from "formik";
import { toast, Toaster } from "react-hot-toast";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faHome, faRedo } from '@fortawesome/free-solid-svg-icons';
import * as Yup from "yup";
import { Col, Row, Card, Form, Button, InputGroup, Breadcrumb, Alert } from "@themesberg/react-bootstrap";
import { GetCountriesApi } from '../../../actions/LoginAction';
import { CreateClientApi } from '../../../actions/ClientActions';
import { DataLoading } from "../../../components/air-migrate/Loader";
import "./Index.css";
import '../../../assets/global-css/Index.css';
import '../../../assets/mobile-custom-css/Index.css';

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { HomepageUrl } from "../../../utilities/helpers/Constant";
import { Default_Country } from '../../../config.tsx';
import ORImage from '../../../assets/img/templates/ORImage.png';

//Alert Icons
import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import CommonAlert from "../../../common-components/CommonAlert.jsx";
import { ALERT_SHOW_TIME } from "../../../config.tsx";
import CommonBreadcrumb from "../../../common-components/CommonBreadcrumb.jsx";
import { CASE_NAME_PREFIX } from "../../../config.tsx";

const AddClient = () => {
  //#region State and variables
  const [countryCodes, setCountryCodes] = useState([]);
  const [countryCodeId, setCountryCodeId] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isEmailEntered, setIsEmailEntered] = useState(false);
  const [isPhoneEntered, setIsPhoneEntered] = useState(false);
  const [isCountryCodeEntered, setIsCountryCodeEntered] = useState(false);
  const [contact, setContact] = useState("email");
  const [inputValues,setInputValue] = useState('');

  const [breadcrumbArray, setBreadcrumbArray] = useState([
    { name: 'Home', url: HomepageUrl },
    { name: 'Clients', url: '/admin/clients' },
    { name: 'Add Client', url: '' }

  ]);
  const history = useHistory();

  //States to manage mobile ui
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const [singleScreen, setSingleScreen] = useState(false);

  const [isToastVisible, setIsToastVisible] = useState(false);

  //Alert states
  const [alertsArray, setAlertsArray] = useState([]);
  const [count, setCount] = useState(1);
  //#endregion

  //#region useEffect
  useEffect(() => {
    setIsToastVisible(false);
    //functionality to set collapsible table 
    window.addEventListener('resize', updateDimension);
    updateDimension();

    // removing eventlistener for no furhter effect after 
    return (() => {
      window.removeEventListener('resize', updateDimension);
    })
  }, [count])
  //#endregion

  //#region Functions

  const updateDimension = () => {
    setScreenSize(getCurrentDimension());
    var value = getCurrentDimension();
    // set a variable true when screen width reaches <768px
    if (value.width <= 767) {
      setSingleScreen(true)
    } else {
      setSingleScreen(false)
    }
  }

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight
    }
  }
  const handleAlert = (id) => {
    let array = [];
    for (let i = 0; i < alertsArray.length; i++) {
      if ((alertsArray[i].id) == id) {
        array = alertsArray.pop();

      }
    }
    setCount(Math.floor(Math.random() * 1000))
  }


  //validation schema
  const AddClientValidationSchema = Yup.object().shape({
    first_name: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('First name is required'),
    // last_name: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Last name is required'),
    primary_phone: Yup.string().required("Please enter phone number")
      // .matches(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/, "Please enter valid phone number")
      .min(8, 'Invalid number, minimum 8 digits required!').max(15, 'Invalid number, maximum 15 digits required!')
    ,
    email: Yup.string().matches(/^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/, 'Invalid email').required('Email is required'),
    primary_country_code: Yup.string().required("Please select country code "),
    case_keyword: Yup.string().min(5, 'Too Short!').max(50, 'Too Long!').required('Case name is required')
  });

  const AddClientValidationSchemaForNoValidation = Yup.object().shape({
    first_name: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('First name is required'),
    // last_name: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Last name is required'),
    primary_phone: Yup.string().
      // matches(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/, "Please enter valid phone number"),
      min(8, 'Invalid number, minimum 8 digits required!').max(15, 'Invalid number, maximum 15 digits required!'),
    email: Yup.string().matches(/^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/, 'Invalid email'),
    case_keyword: Yup.string().min(5, 'Too Short!').max(50, 'Too Long!').required('Case name is required')
  });

  const AddClientValidationSchemaForCountrCode = Yup.object().shape({
    first_name: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('First name is required'),
    // last_name: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Last name is required'),
    primary_country_code: Yup.string().required("Please select country code "),
    primary_phone: Yup.string().
      // matches(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/, "Please enter valid phone number"),
      min(8, 'Invalid number, minimum 8 digits required!').max(15, 'Invalid number, maximum 15 digits required!'),
    email: Yup.string().email('Invalid email'),
    case_keyword: Yup.string().min(5, 'Too Short!').max(50, 'Too Long!').required('Case name is required')
  });


  //define formik flow
  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      case_keyword: '',
      primary_country_code: '',
      primary_phone: '',
      email: '',
    },
    enableReinitialize: true,
    validationSchema: (isPhoneEntered === true) ?
      (isCountryCodeEntered === true) ? AddClientValidationSchemaForNoValidation : AddClientValidationSchemaForCountrCode
      : (isEmailEntered === true) ? AddClientValidationSchemaForNoValidation : AddClientValidationSchema,
    onSubmit: (values) => {
      setIsSubmit(true)
      // console.log('values inside formik', values);
      onSubmitCall(values);
    }
  })

  //on submit function

const [isSubmit,setIsSubmit]=useState(false);
  const onSubmitCall = async (data) => {
    setIsSubmit(true);
    if(inputValues ==''||null){
      return
    }
    setIsToastVisible(true);
    var addClientRequestModel;
    if (isPhoneEntered === true && isCountryCodeEntered === true) {
      addClientRequestModel = { ...data, primary_country_code: formik.values.primary_country_code }
    }
    else {
      addClientRequestModel = data;
    }
    // console.log("addClientRequestModel",addClientRequestModel)
    setIsLoading(true);
    const response = await CreateClientApi(addClientRequestModel);
    setIsLoading(false);
    if (response.status_code === 1) {

      setAlertsArray([...alertsArray, { id: `add-client${alertsArray.length}`, type: 'success', message: response.message }]);

      setIsSubmit(false);
      setTimeout(() => {
        history.push('/admin/clients');
      }, ALERT_SHOW_TIME);
    }
    else {
      setAlertsArray([...alertsArray, { id: `add-client${alertsArray.length}`, type: 'danger', message: response.message }]);

    }
    // console.log("response at add client submission", response);

  }

  const HandlePhoneInputChange = (value, country) => {
    // console.log('number value', value, country);
    if (country.dialCode == '') {
      setIsCountryCodeEntered(false);
    }
    else {
      formik.setFieldValue("primary_country_code", country.dialCode);
      setIsCountryCodeEntered(true);
    }
    var phone = value.slice(country.dialCode.length);
    if (phone == '') {
      formik.setFieldValue("primary_phone", phone);
      setIsPhoneEntered(false);
    }
    else {
      // console.log('phone', phone)
      formik.setFieldValue("primary_phone", phone);
      setInputValue(phone);
      setIsPhoneEntered(true);
    }
  }

  //#region Alerts
  const [hiddenAlerts, setHiddenAlerts] = React.useState([]);

  const onClose = (alertId) => {
    const hiddenAlertsUpdated = [...hiddenAlerts, alertId];
    setHiddenAlerts(hiddenAlertsUpdated);
  };

  const shouldShowAlert = (alertId) => (
    hiddenAlerts.indexOf(alertId) === -1
  );

  /* Auto generate case name functionality here  */
  /* const for toggle button */
  const [changeCaseName, setChangeCaseName] = useState(false);

  useEffect(() => {
    formik.setFieldValue("case_keyword", generateRandomCaseName())
  }, [changeCaseName])

  /* generate auto case name function */
  const generateRandomCaseName = () => {
    const prefix = CASE_NAME_PREFIX;
    const randomNumber = Math.floor(Math.random() * 900000) + 100000; // Generates a 6-digit number
    return prefix + "-" + randomNumber;
  }

  /*function to run on refresh button click to change case name   */
  const handleToggleClick = () => {
    setChangeCaseName(!changeCaseName);
  };

  /* function for radio button of email and phone */
  const onContactChange = (value) => {
    setContact(value);
    // const value = toString(value);
    if (value == "phone") {
      setInputValue('')
        formik.setFieldValue("email", '');
        setIsPhoneEntered(true);
        setIsCountryCodeEntered(true);
        setIsEmailEntered(false);
    }else if(value == "email"){
      setInputValue('')
        setIsEmailEntered(true);
        setIsPhoneEntered(false);
        setIsCountryCodeEntered(false);
        formik.setFieldValue("primary_country_code", '');
        formik.setFieldValue("primary_phone", '');
    }
};
  //#endregion
  //#endregion

  //#region Styles
  //#endregion

  //#region JSX
  return (
    <Fragment>
      <>
        <div className="d-block mb-4 mb-md-0">
          {/* <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item className="pe-auto" onClick={() => history.push(HomepageUrl)}><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item onClick={() => history.push("/admin/clients")}>Clients</Breadcrumb.Item>
            <Breadcrumb.Item active>Add client</Breadcrumb.Item>
          </Breadcrumb> */}
          <CommonBreadcrumb breadcrumbArray={breadcrumbArray} />
          <h4 className={`"mt-2 " ${singleScreen ? 'f-18' : ''}`}>Add Client</h4>
        </div>
        <Row>
          <Col xs={12}>
            {(alertsArray.length > 0) &&
              alertsArray.reverse().map((item, index) => {
                return (
                  <CommonAlert key={index} id={item.id} type={item.type} message={item.message} handleAlert={handleAlert} />
                )
              })
            }
            <Card border="light" className="bg-white shadow-sm mb-4">
              <Card.Body>

                <Form onSubmit={formik.handleSubmit}>
                  <Row>
                    <Col sm={12} md={9} lg={6} className="mb-3">
                      <Form.Group >
                        <Form.Label className="">First Name <span className="c-r">*</span></Form.Label>
                        <Form.Control
                          id="first_name"
                          name="first_name"
                          value={formik.values.first_name}
                          type="text"
                          placeholder="Enter first name"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className="f-16 first-name"
                          size="lg"
                        />

                        {formik.touched.first_name && formik.errors.first_name ? (
                          <small className="text-danger">{formik.errors.first_name}</small>
                        ) : null}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12} md={9} lg={6} className="mb-3">
                      <Form.Group >
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                          id="last_name"
                          name="last_name"
                          value={formik.values.last_name}
                          type="text"
                          placeholder="Enter last name"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.last_name && formik.errors.last_name ? (
                          <small className="text-danger">{formik.errors.last_name}</small>
                        ) : null}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} sm={12} md={9} lg={6} className="mb-3">
                      <Form.Group >
                        <Form.Label>Case Name <span className="c-r">*</span></Form.Label>
                        <div className="case-name-input-block">
                          <Form.Control
                            id="case_keyword"
                            name="case_keyword"
                            value={formik.values.case_keyword}
                            type="text"
                            placeholder="Enter case name"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          <div className="case-name-refresh-icon">
                            <div className="d-flex align-items-center cu-po" variant="success" onClick={() => { handleToggleClick() }}>
                              <FontAwesomeIcon icon={faRedo} size="lg" color="#47C7F4" title="Regenerate case name" />
                            </div>
                          </div>
                        </div>
                        {formik.touched.case_keyword && formik.errors.case_keyword ? (
                          <small className="text-danger">{formik.errors.case_keyword}</small>
                        ) : null}
                      </Form.Group>
                    </Col>
                    {/* <Col xs={1} sm={1} md={1} lg={1} className="d-flex align-items-center justify-content-center mt-3">
                      <div className="">
                        <div className="d-flex align-items-center cu-po" variant="success" onClick={() => { handleToggleClick() }}>
                          <FontAwesomeIcon icon={faRedo} size="lg" color="#47C7F4" title="Regenerate case name" />
                        </div>
                      </div>
                    </Col> */}
                  </Row>
                  <Row>
                    <Row>
                      <Col sm={4} className="d-flex justify-content-between">
                        <Form.Group>
                          <div className="d-flex justify-content-between">
                            <Form.Check
                              inline
                              label="Email"
                              name="contact"
                              type="radio"
                              id={`inline-radio-1`}
                              value={toString("email")}
                              checked={contact === "email"}
                              onChange={()=>{onContactChange("email")}}

                            />
                            <Form.Check
                              inline
                              label="Mobile Number"
                              name="contact"
                              type="radio"
                              id={`inline-radio-2`}
                              value={toString("phone")}
                              checked={contact === "phone"}
                              onChange={()=>{onContactChange("phone")}}

                            />
                          </div>

                        </Form.Group>

                        {/* <Form.Group>
                        <input
                          type="radio"
                          name="contact"
                          value="email"
                          id="email"
                          checked={contact === "email"}
                          onChange={onContactChange}
                        />
                         <Form.Label className="ms-4">Email </Form.Label>
                        </Form.Group>
                        <Form.Group>
                       
                        <input
                          type="radio"
                          name="contact"
                          value="phone"
                          id="phone"
                          checked={contact === "phone"}
                          onChange={onContactChange}
                        />
                         <Form.Label className="ms-4">Phone </Form.Label>
                         </Form.Group> */}
                      </Col>
                    </Row>
                    {contact == 'email' &&
                      <Col sm={12} md={9} lg={6} className="mb-3">
                        <Form.Group >
                          <Form.Label>Email <span className="c-r">*</span></Form.Label>
                          <Form.Control
                            id="email"
                            name="email"
                            value={formik.values.email}
                            type="text"
                            placeholder="Enter email"
                            onChange={(e) => {
                              setInputValue(e.target.value);
                              // console.log('email value', e.target.value);
                              formik.setFieldValue("email", e.target.value);
                              if (e.target.value == '') {
                                setIsEmailEntered(false);
                              }else {setIsEmailEntered(true)};
                            }}
                            onBlur={formik.handleBlur}
                          />
                          {isEmailEntered && (inputValues == ''||null) && isSubmit &&
                                <small className="text-danger">Email is required</small>
                              }
                          {formik.touched.email && formik.errors.email ? (
                            <small className="text-danger">{formik.errors.email}</small>
                          ) : null}
                        </Form.Group>
                      </Col>
                    }
                    {/* <Col sm={12} md={9} lg={1} className="d-flex align-items-center justify-content-center mt-md-3 mt-2">
                      <div >
                        <p className="lineText">OR</p>
                      </div>
                    </Col> */}
                    {contact == 'phone' &&
                      <Col sm={12} md={9} lg={6} className="mb-3">
                        <Row>
                          <>
                            <div className="inputRow">
                              <Form.Label className="mobile-number-text">Mobile Number <span className="c-r">*</span></Form.Label>
                              <PhoneInput
                                country={Default_Country}
                                preferredCountries={['in', 'ca', 'us']}
                                autoFormat
                                // containerClass={` ${formValue?.phone?.error ? "is-invalid" : ""}`}
                                inputClass={`PhoneInputInput`}
                                buttonClass={'country-dropdown'}
                                dropdownClass={'country-dropdown-container'}
                                enableSearch
                                countryCodeEditable={false}
                                style={{}}
                                onChange={(value, country) => {
                                  HandlePhoneInputChange(value, country)
                                }}
                                placeholder="Phone Number"
                                autocompleteSearch={true}
                                inputProps={{
                                  name: 'phone',
                                  required: false,

                                }}

                              />
                              {isPhoneEntered && (inputValues == ''||null) && isSubmit &&
                                <small className="text-danger">Phone is required</small>
                              }
                              {formik.touched.primary_phone && formik.errors.primary_phone ? (
                                <small className="text-danger">{formik.errors.primary_phone}</small>
                              ) : null}
                            </div>
                          </>
                        </Row>
                      </Col>
                    }
                  </Row>

                  {/* <Row >
                    <Col sm={12} xl={6} lg={6} xs={12} md={4} >
                      <div className="or-image-block">
                        <img src={ORImage} alt="or-image" width="auto" style={{ margin: "10px 0px", textAlign: 'center' }} />
                      </div>

                    </Col>
                  </Row> */}

                  {/* <Col sm={12} md={9} lg={3}>
                    <Row>
                      <>

                        <div className="inputRow">
                          <Form.Label className="mobile-number-text">Mobile Number <span className="c-r">*</span></Form.Label>
                          <PhoneInput
                            country={Default_Country}
                            preferredCountries={['in', 'ca', 'us']}
                            autoFormat
                            // containerClass={` ${formValue?.phone?.error ? "is-invalid" : ""}`}
                            inputClass={`PhoneInputInput`}
                            buttonClass={'country-dropdown'}
                            dropdownClass={'country-dropdown-container'}
                            enableSearch
                            countryCodeEditable={false}
                            style={{ paddingRight: '10px' }}
                            onChange={(value, country) => {
                              HandlePhoneInputChange(value, country)
                            }}
                            placeholder="Phone Number"
                            autocompleteSearch={true}
                            inputProps={{
                              name: 'phone',
                              required: false,

                            }}

                          />
                          {formik.touched.primary_phone && formik.errors.primary_phone ? (
                            <small className="text-danger">{formik.errors.primary_phone}</small>
                          ) : null}
                        </div>
                      </>
                    </Row>
                  </Col> */}


                  <Row className="mt-3">
                    <Col sm={12} md={9} lg={6}>
                      <Button type="submit"
                        className="text-white me-2 f-16"
                        variant="success">
                        Invite to Airmigrate
                      </Button>
                      <Button
                        className="f-16"
                        variant="danger"
                        onClick={() => history.push("/admin/clients")}
                      >
                        Cancel
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        {/* <Toaster position="top-right" reverseOrder={false} /> */}

        {/* <Toaster position="top-right" reverseOrder={false} toastOptions={{
          style: {
            display: isToastVisible ? 'auto' : 'none'
          },
        }} /> */}

        {isLoading === true && <DataLoading />}
      </>
    </Fragment>
  );
  //#endregion
};
export default AddClient;
