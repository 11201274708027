
import React, { useState, useEffect } from 'react';
import { DataLoading } from '../../components/air-migrate/Loader.jsx';
import { verifyInviteCase } from '../../actions/ChatsActions.jsx';
import { useHistory } from "react-router-dom";
// import './index.css';
import { toast } from 'react-hot-toast'

const InviteCaseVerify = (props) => {

    const history = useHistory()
    const { userId, caseId ,by_user_uuid} = props?.match?.params
    useEffect(() => {
        // First Check, User verified or not
        verifyUser()
    }, [])



    
    const verifyUser = async () => {
        var requestBody = {
            case_id: caseId,
            user_uuid: userId,
            by_user_uuid: by_user_uuid,

        }
        const response = await verifyInviteCase(requestBody)
        // console.log('response11', response)
        if (response?.status_code == 1) {
            // console.log('verifyUser',  response.data[0])
            history.push(`/chat-process/${userId}/${caseId}`)
        }
         else {
            toast.error('Oops! It seems like the link you clicked on is not valid or has expired!!');
            history.push('/examples/404')
        }
    }


    return (
        <DataLoading /> 
    )

}

export default InviteCaseVerify