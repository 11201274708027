/*
A common  actions file , which contains all the api's which will be used in My Profile module.
*/

import { authApi } from '../utilities/helpers/ApiHelper';
import { toast } from 'react-hot-toast';

const catchObject = {
    "data": [
        {

        }
    ],
    "message": "Something went wrong at backend",
    "status_code": 2
}

// get My profile data for Broker/Consultant 
async function GetMyProfileDataApi(userId) {
    let url = `myprofile${userId ? '/' + userId : ''}`;
    try {
        const response = await authApi.getAPI(url);
        return response;
    }
    catch (err) {
        console.error("Something went wrong!", err);
    }

}

//  funtion to get otp on contact/email for verifying 
async function getOtpToVerifyPhoneEmailApi(data) {
    let url = `verify-user-detail`;
    try {
        const response = await authApi.postAPI(url, data);
        if (response.status_code === 1) {
            return response;
        } else {
            toast.error(response.message, { duration: 4000 })
        }
    }
    catch (err) {
        toast.error("Something went wrong!", { duration: 4000 });
        console.error("Something went wrong!", err);
    }
}

// function to  verify contact/email
async function verifyPhoneEmailApi(data) {
    let url = `verify-user-otp`;
    try {
        const response = await authApi.postAPI(url, data);
        return response;
    }
    catch (err) {
        console.error("Something went wrong!", err);
        return catchObject;
    }
}
// function to update my profile details 
async function updateMyprofileApi(data) {
    let url = `myprofile`;
    try {
        const response = await authApi.updateAPI(url, data);
        return response;
    }
    catch (err) {
        console.error("Something went wrong!", err);
        return catchObject;
    }
}
// function for associated member list for customer
async function GetAssociatedMemberListForCustomerApi(id) {
    let url = `get-client-cases-associated-member/${id}?primary=${true}&own=${false}`;
    try {
        const response = await authApi.getAPI(url);
        return response;
    }
    catch (err) {
        // toast.error("Something went wrong in fetching associate member list!", { duration: 4000 });
        console.error("Something went wrong!", err);
    }
}
// function for broker and consultant myprofile document list 
async function GetMyprofileDocumentListApi(id, role) {
    let url = `get-agents-documents/${id}${role ? '/' + role : ''}`;

    var data = { user_uuid: id };
    try {
        const response = await authApi.getAPI(url, data);
        return response;
    }
    catch (err) {
        toast.error("Something went wrong in fetching document list!", { duration: 4000 });
        console.error("Something went wrong!", err);
    }
}
// function to view myprofile documen api call
async function MyProfileViewDocumentApi(id) {
    let URL = `agent-view-document`
    console.log("GetLinkToViewDocumentApi", URL, id)
    let data = {
        "documents_id": id,
        "headerTag": 'inline',
    }
    try {
        const response = await authApi.getAPI(URL, data);
        if (response.status_code == 1) {
            return response;
        }
        else {
            toast.error(response.message, { duration: 3000 });
        }
    }
    catch (err) {
        toast.error("Something went wrong in fetching view document!", { duration: 4000 });
        console.error("omething went wrong in fetching view document !", err);
    }
}

// get Esigned document list api
async function GetEsignedDocumentListApi(userId) {
    let url = `my-signed-docs${userId ? '/' + userId : ''} `;
    // let url =`my-signed-docs`
    try {
        const response = await authApi.getAPI(url);
        return response;
    }
    catch (err) {
        console.error("Something went wrong in fetching Esigned document list!", err);
        return catchObject;
    }
}

/* Agent My profile get other details api here */
async function GetAgentOtherDetailsApi(userId) {
    let url = `other-detail${userId ? '/' + userId : ''} `;
    try {
        const response = await authApi.getAPI(url);
        return response;
    }
    catch(err) {
        console.error("Something went wrong in fetching other details!", err);
        return catchObject;
    }
}

/* Agent My profile update other details api here */
async function UpdateAgentOtherDetailsApi(data) {
    let url = `other-detail`;
    try {
        const response = await authApi.postAPI(url, data);
        return response;
    }
    catch (err) {
        console.error("Something went wrong in updating other details!", err);
        return catchObject;
    }
}

export {
    GetMyProfileDataApi,
    getOtpToVerifyPhoneEmailApi,
    verifyPhoneEmailApi,
    updateMyprofileApi,
    GetAssociatedMemberListForCustomerApi,
    GetMyprofileDocumentListApi,
    MyProfileViewDocumentApi,
    GetEsignedDocumentListApi,
    GetAgentOtherDetailsApi,
    UpdateAgentOtherDetailsApi
}
