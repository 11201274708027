import React, { Fragment } from "react";
import Logo from "../../assets/img/landing-page/logo-img.png";
import { InputGroup, Input } from "reactstrap";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { SignInApi } from "../../actions/LoginAction";
import "./Index.css";
import { DataLoading } from "../../components/air-migrate/Loader";
import {
  Col,
  Row,
  Form,
  Button,
  Container,
  FormGroup,
} from "@themesberg/react-bootstrap";
import CommonServices from "../../common-components/CommonServices";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { Default_Country_Code, Default_Country } from "../../config.tsx";
import CommonAlert from "../../common-components/CommonAlert.jsx";
import UnleashMessage from "../../common-components/UnleashMessage.jsx";

const Login = () => {
  //#region states
  const [isLoading, setIsLoading] = useState(true);
  const [phoneNumber, setphoneNumber] = useState(null)
  // Check Unleash mobile login feature is enabled or not
  const mobileLoginFeatureFlag = CommonServices.GetUnleashFeatureFlag('frontend-mobile-login-feature');
  // Check Unleash E-Mail login feature is enabled or not
  const emailLoginFeatureFlag = CommonServices.GetUnleashFeatureFlag('signin');
  // True: When Click on Send OTP Button
  const [isButtonDisable, setIsButtonDisable] = useState(false)
  const [formValue, setFormValue] = useState({
    email: { value: "", error: "" },
    phone: { value: "", error: "" },
    primary_country_code: { value: "", error: "" },
  });
  const [loginWith, setLoginWith] = useState("");
  const history = useHistory();
  const setInitilaValue = () => {
    setLoginWith("");
    setFormValue({
      email: { value: "", error: "" },
      phone: { value: "", error: "" },
      primary_country_code: { value: Default_Country_Code, error: "" }, /*  setting default value of country code for default country Canada */
    });
  };
  //Alert states and functions
  const [alertsArray, setAlertsArray] = useState([]);
  const [count, setCount] = useState(0);
  const handleAlert = (id) => {
    let array = [];
    for (let i = 0; i < alertsArray.length; i++) {
      if ((alertsArray[i].id) == id) {
        array = alertsArray.pop();
      }
    }
    setCount(Math.floor(Math.random() * 1000))
  }
  //#region useEffect
  useEffect(() => { setIsLoading(false)}, [count]);

  useEffect(() => {
    setInitilaValue();
  }, []);
  //#endregion useEffect
  const handlePhoneChange = (value, country, event, formattedValue) => {
    const IsCountryCode = country.dialCode;
    const IsPhoneValue = value.slice(country.dialCode.length)
    // Update state with phone number and country code
    if (IsCountryCode) {
      onInputChange("primary_country_code", Number(IsCountryCode))
    }
    if (IsPhoneValue) {
      onInputChange("phone", Number(IsPhoneValue))
    }
    setphoneNumber(value)
    setFormValue({
      email: { value: "", error: "" },
      phone: { value: IsPhoneValue, error: "" },
      primary_country_code: { value: IsCountryCode, error: "" }, /*  setting default value of country code for default country Canada */
    });
  };
  // validate email 
  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  // Custom Validation
  const onInputChange = (key, value) => {
    // By Email
    if (key == "email") {
      setLoginWith("byEmail");
      setphoneNumber(null);
      let errorValue = "";
      let isValid = validateEmail(value);
      if (!isValid && value) {
        errorValue = "You have entered an invalid email address !";
      }
      if (value === "") {
        errorValue = "Email is required!";
      }
      let formValueState = {
        [key]: { value: value, error: errorValue },
        primary_country_code: { value: "", error: "" },
        phone: { value: "", error: "" },
      };
      setFormValue({ ...formValueState });
    } else {
      // By Phone & Contry Code
      setLoginWith("byPhone");
      let oldValues = {};
      const { email, ...rest } = formValue;
      oldValues = rest;
      let errorValue = "";
      // Phone Validation
      if (key == "phone") {
        // Check contry code
        if (!oldValues?.primary_country_code?.value) {
          oldValues = {
            ...oldValues,
            primary_country_code: { value: "", error: "Required !" },
          };
        }
      } else {
        // Contry Code Validation
        if (!value || value == "+0") {
          errorValue = "Required!";
        }
        // Check Phone code
        if (!oldValues?.phone?.value) {
          oldValues = {
            ...oldValues,
            phone: { value: "", error: "Phone is required !" },
          };
        }
      }
      // Set Phone & Country Code Value & Error
      let formValueState = {
        ...oldValues,
        [key]: { value: value, error: errorValue },
        email: { value: "", error: "" },
      };
      setFormValue(formValueState);
    }
  };

  // When user press Enter key 
  const handleKeyPress = (event) => {
    // When user enter, send message
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };
  // function call on login submit
  const handleSubmit = async () => {
    // If User Not Touch Any Input
    if (!loginWith) {
      setFormValue({
        email: { value: "", error: "Email is required !" },
        phone: { value: "", error: "Phone is required !" },
        primary_country_code: { value: "", error: "Required !" },
      });
      return true;
    }
    //login with email
    if (loginWith == "byEmail" && formValue?.email?.error) {
      console.error("Error in Email");
      return true;
    } else if (
      formValue?.phone?.error ||
      formValue?.primary_country_code?.error
    ) {
      console.error("Error in Phone & Code");
      return true;
    } else {
      const { email, phone, primary_country_code } = formValue;
      let formData = {};
      if (loginWith == "byEmail") {
        formData = { email: email.value };
      } else {
        formData = {
          phone: phone.value,
          primary_country_code: primary_country_code.value,
        };
      }
      try {
        setIsButtonDisable(true)
        const response = await SignInApi(formData);
        setIsButtonDisable(false)
        if (response.status_code === 1) {
          localStorage.setItem("userDetails", JSON.stringify(response.data));
          history.push("/verify-otp", { state: formData });
          return response.data;
        } else {
          setAlertsArray([...alertsArray, { id: `download-document${alertsArray.length}`, type: 'danger', message: response?.message }]);
        }
        return false;
      } catch (e) {
        console.error(e);
      }
    }
  };

  //#region render
  return (
    <Fragment>
      <>
        <Container className="login-main-container" fluid>
          <Row className="login-main-row">
            <Col xs={12} sm={12} md={12} lg={7} className=" d-none d-md-flex login-side-text-block">
              <div className="login-side-text">
                <h1 className="login-side-head-text">Airmigrate</h1>
                <p> Redefining how the world immigrates. </p>
                <p>
                  <span className="fw-bolder">Log In securely -</span> We ensure the utmost privacy and protection of your information as you navigate your new path.
                </p>
              </div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={5} className="login-form-block">
              {(emailLoginFeatureFlag || mobileLoginFeatureFlag) ? (
                <div className="inputContainer">
                  <div className=" login-alert-container">
                    {(alertsArray.length > 0) &&
                      alertsArray.reverse().map((item, index) => {
                        return (
                          <div className="text-center">
                            <CommonAlert key={index} id={item.id} type={item.type} message={item.message} handleAlert={handleAlert} />
                          </div>
                        )
                      })
                    }
                  </div>

                  <div className="welcomeContainer">
                    <h2 className="welcomeText">Welcome to</h2>
                    <img src={Logo} alt="" onClick={() => history.push('/')} className="login-logo-img" />
                    {/* Unleash check for Email login */}
                    {emailLoginFeatureFlag || mobileLoginFeatureFlag ? (
                      <div className="loginMessageContainer">
                        <p className="loginMessageText">
                          Enter your
                          {emailLoginFeatureFlag && mobileLoginFeatureFlag ? (
                            <span> Email OR Phone </span>
                          ) : (
                            <>
                              {emailLoginFeatureFlag && <span> Email </span>}
                              {mobileLoginFeatureFlag && <span> Phone </span>}
                            </>
                          )}
                          for Login/Sign Up{" "}
                        </p>
                      </div>
                    ) :
                      <div></div>
                    }
                  </div>
                  <Form>
                    <div className="inputTextContainer">
                      <div className="inputTextContainerWidth">
                        {emailLoginFeatureFlag &&
                          <FormGroup className="">
                            <InputGroup className="input-group-alternative">
                              <Input
                                className={`form-control-alternative ${formValue?.email?.error ? "is-invalid" : ""}`}
                                id="email"
                                placeholder="Email"
                                type="text"
                                name="email"
                                value={formValue?.email?.value}
                                onChange={(event) => onInputChange("email", event.target.value)}
                                autoFocus={true}
                                onKeyDown={handleKeyPress} />
                            </InputGroup>
                            {formValue?.email?.error ? (
                              <small className="text-danger">{formValue?.email?.error}</small>
                            ) : null}
                          </FormGroup>
                        }
                        {/* condition check for unleash feature flag for mobile number login feature */}
                        {(mobileLoginFeatureFlag && emailLoginFeatureFlag) &&
                          <div className="lineContainer">
                            <div className="horizontolLine"></div>
                            <p className="lineText">OR</p>
                            <div className="horizontolLine"></div>
                          </div>
                        }
                        {(mobileLoginFeatureFlag) &&
                          <div className="inputRow">
                            <PhoneInput
                              country={Default_Country}
                              preferredCountries={['in', 'ca', 'us']}
                              autoFormat
                              containerClass={` ${formValue?.phone?.error ? "is-invalid" : ""}`}
                              inputClass={`PhoneInputInput ${formValue?.phone?.error ? "is-invalid" : ""}`}
                              buttonClass={'country-dropdown'}
                              dropdownClass={'country-dropdown-container'}
                              enableSearch
                              countryCodeEditable={false}
                              value={phoneNumber}
                              onChange={handlePhoneChange}
                              onKeyDown={handleKeyPress}
                              placeholder="Phone Number"
                              autocompleteSearch={true}
                              inputProps={{
                                name: 'phone',
                                required: true,
                              }}
                            />
                            {formValue?.primary_country_code?.error && formValue?.phone?.error && (
                              <small className="text-danger">
                                {formValue?.phone?.error}
                              </small>
                            )}
                          </div>
                        }
                        <div className="sendOtpContainer">
                          <Button
                            className="otp-btn"
                            color="#0184FE"
                            onClick={handleSubmit}
                            disabled={(phoneNumber?.length <= 9) || isButtonDisable}
                          >
                            Send OTP
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Form>
                  <div className="termConditionContainer pb-4" onClick={() => history.push('/terms-condition')}>
                    <p className="termConditionText">
                      By continuing you agree to AirMigrate
                    </p>
                    <span className="termConditionTextSecond">
                      Terms of Use & Privacy Policy
                    </span>
                  </div>
                </div>) :
                (
                  <div className="inputContainer">
                    <UnleashMessage />
                  </div>

                )}
            </Col>
          </Row>
        </Container>
      </>
      {isLoading === true && <DataLoading />}
    </Fragment>
  );
};
//#endregion render
export default Login;
