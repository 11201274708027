import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    myProfile:'',
    // 'https://www.shutterstock.com/shutterstock/photos/1395298487/display_1500/stock-photo-focused-woman-wearing-headphones-using-laptop-in-cafe-writing-notes-attractive-female-student-1395298487.jpg',
    count:0
}

export const MyProfileSlice = createSlice({
    name: 'myProfileModule',
    initialState,
    reducers: {
        setMyProfile: (state, value) => {
            // console.log('state and value', value);
            state.myProfile = value.payload;
        },

        setCount:(state) =>{
            // console.log('setCount call');
            state.count = Math.floor(Math.random() * 1000);
        }
       
    },
})

// Action creators are generated for each case reducer function
export const { setMyProfile, setCount} = MyProfileSlice.actions

export default MyProfileSlice.reducer