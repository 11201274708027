import { authApi } from '../utilities/helpers/ApiHelper';
import { toast } from 'react-hot-toast';

async function GetAgentListApi(startingIndex, pageLimit, searchText, roleId) {
    let url = '';

    if (roleId && searchText) {
        url = `agents-list?start=${startingIndex}&limit=${pageLimit}&role=${roleId}&search=${searchText}`;
    }
    else if (roleId) {
        url = `agents-list?start=${startingIndex=1}&limit=${pageLimit}&role=${roleId}`;
    }
    else if (searchText) {
        url = `agents-list?start=${startingIndex=1}&limit=${pageLimit}&search=${searchText}`;
    }
    else {
        url = `agents-list?start=${startingIndex}&limit=${pageLimit}`;
    }

    try {
        const response = await authApi.getAPI(url);
        return response;
    } catch (e) {
        toast.error("Something went wrong!", { duration: 4000 });
    }
}

async function DeleteAgentApi(user_uuid) {
    let url = `agent-delete/${user_uuid}`;
    try {
        const response = await authApi.deleteAPI(url);
        // console.log("deleteagent", response)
        return response;
    }
    catch (e) {
        toast.error("Something went wrong!", { duration: 4000 });
    }
}

async function GetAgentRequestsApi(startingIndex, pageLimit, searchText, roleId) {
    let url = '';

    if (roleId && searchText) {
        url = `agent-request-list?start=${startingIndex}&limit=${pageLimit}&role=${roleId}&search=${searchText}`;
    }
    else if (roleId) {
        url = `agent-request-list?start=${startingIndex=1}&limit=${pageLimit}&role=${roleId}`;
    }
    else if (searchText) {
        url = `agent-request-list?start=${startingIndex=1}&limit=${pageLimit}&search=${searchText}`;
    }
    else {
        url = `agent-request-list?start=${startingIndex}&limit=${pageLimit}`;
    }

    try {
        const response = await authApi.getAPI(url);
        return response;
    } catch (e) {
        toast.error("Something went wrong!", { duration: 4000 });
    }
}



async function CreateAgentRequestApi(user_uuid, status) {
    const requestbody = {
        user_uuid:user_uuid,
        status:status  
    }
    let url = `agent-request-update`;
    try {
        const response = await authApi.postAPI(url, requestbody);
        return response;
    }
    catch (e) {
        toast.error("Something went wrong!", { duration: 4000 });
    }
}

export {GetAgentListApi,DeleteAgentApi,GetAgentRequestsApi,CreateAgentRequestApi}