import React, { useEffect } from 'react';
import { withScriptjs, withGoogleMap, GoogleMap, OverlayView, Circle, InfoWindow, Marker } from 'react-google-maps';
import { PROFILE_PATH } from '../../config.tsx';
import BrokerIcon from "../../assets/img/real-state/mapicon.png";
import { useHistory } from 'react-router-dom';
import { DEFAULT_MAP_CENTER, GOOGLE_MAP_CIRCLE_RANGE } from '../../config.tsx';
import CommonServices from '../../common-components/CommonServices.jsx';
import { useSelector } from 'react-redux';

const Map = withScriptjs(
  withGoogleMap((props) => {
    //#region conts start
    const [activeMarker, setActiveMarker] = React.useState(null);
    const [defaultLat, setDefaultLat] = React.useState(45.424721);
    const [defaultLong, setDefaultLong] = React.useState(-75.695000);
    const history = useHistory();
    const isConsultant = localStorage.getItem('roleId') != 3 ? true : false;
    const [zoom, setZoom] = React.useState(props.inRealEstate ? 11 : props.showRangeCircle ? 7 : 6);
    //#endregion consts end
    const handleMarkerClick = (location) => { setActiveMarker(location); };
    const visaUploadModule = useSelector(state => state.visaUploadModule)

    useEffect(() => {
      if (props.locations && props.locations.length && props.searchFlag) {
        setDefaultLat(Number(props.locations[0]?.lat))
        setDefaultLong(Number(props.locations[0]?.long))
      } else {
        setDefaultLat(DEFAULT_MAP_CENTER.lat);
        setDefaultLong(DEFAULT_MAP_CENTER.long);
      }
    }, [props.locations, props.searchFlag]);

    /* function to call on click on infoWindow on Map to go user details page  */
    const handleClick = (userID) => {
      if (!isConsultant && visaUploadModule.isUserLogin) {
        // Uploaded & not Approved
        if (visaUploadModule.isVisaUploaded && (!visaUploadModule.isVisaApproved)) {
          history.push('/status-pending-page')
        } // not Uploaded 
        else if (visaUploadModule.isVisaUploaded) {
          history.push(`agent-details/${userID}`);
        }
      }
    };

    //#region start render
    return (
      <GoogleMap
        defaultZoom={props.inRealEstate ? 11 : props.showRangeCircle ? 12 : 8}
        defaultCenter={{
          lat: defaultLat,
          lng: defaultLong,
        }}
        center={{
          lat: Number(props.locations && props.locations[0]?.lat) || defaultLat,
          lng: Number(props.locations && props.locations[0]?.long) || defaultLong,
        }}
        zoom={zoom}
        options={{
          disableDefaultUI: true, // This disables the default UI controls
          zoomControl: props.showRangeCircle || props.inRealEstate, // This enables the zoom control
        }}
        key={props.locations && props.locations.length}
      >
        {props.locations && props.locations.length &&
          <>
            {props.locations.map((location, index) => (
              <>
                {props.inRealEstate && location ? (
                  <OverlayView
                    key={Math.random()}
                    position={{ lat: Number(location.lat), lng: Number(location.long) }}
                    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET || OverlayView.OVERLAY_LAYER}
                    getPixelPositionOffset={(width, height) => ({
                      x: -(width / 2),
                      y: -(height / 2),
                    })}
                  >
                    <div
                      style={{
                        borderRadius: '50%',
                        width: '40px',
                        height: '40px',
                        backgroundColor: 'white',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                      }}
                      onClick={() => handleMarkerClick(location)}
                    >
                      <img
                        src={location.profile_path ? location.profile_path : BrokerIcon}
                        alt="Custom Marker"
                        style={{ width: '40px', height: '40px', borderRadius: '50%', border: '2px solid #47C7F4' }}
                      />
                    </div>
                  </OverlayView>
                ) : (
                  <Marker
                    {...location}
                    key={index}
                    position={{ lat: Number(location.lat), lng: Number(location.long) }}
                    onClick={() => {
                      setActiveMarker(location);
                    }}
                  >
                    {props.locations && props.locations.length > 0 && props.showRangeCircle && (
                      <Circle
                        key={index}
                        center={{ lat: Number(location.lat), lng: Number(location.long) }}
                        radius={GOOGLE_MAP_CIRCLE_RANGE}
                        options={{
                          strokeColor: "#4A5073",
                          strokeOpacity: 0.8,
                          strokeWeight: 1,
                          fillColor: "#4A5073",
                          fillOpacity: 0.35,
                        }}
                      />
                    )}
                  </Marker>
                )}

              </>
            ))}
          </>
        }
        {(activeMarker && props.inRealEstate) && (
          <InfoWindow
            position={{ lat: Number(activeMarker.lat), lng: Number(activeMarker.long) }}
            onCloseClick={() => {
              setActiveMarker(null);
            }}
          >
            <div className='infowindow-content'>
              <img onClick={() => { handleClick(activeMarker.user_uuid) }} src={activeMarker.profile_path ? activeMarker.profile_path : PROFILE_PATH} alt="" className='infowindow-image' />
              <div>
                <p onClick={() => { handleClick(activeMarker.user_uuid) }} className={`text-black fw-bolder mb-0 cursor-pointer ${!visaUploadModule.isUserLogin || !visaUploadModule.isVisaApproved ? 'blurred-name' : ''}`} >{CommonServices.getUserFullName(activeMarker)}</p>
                <p className={`mb-0 ${!visaUploadModule.isUserLogin || !visaUploadModule.isVisaApproved ? 'blurred-name' : ''}`}>{activeMarker.email ? activeMarker.email : "No Email Provided"}</p>
                <p className={`mb-0 ${!visaUploadModule.isUserLogin || !visaUploadModule.isVisaApproved ? 'blurred-name' : ''}`}>{(activeMarker.primary_country_code && activeMarker.primary_phone) ? '+' + activeMarker.primary_country_code + activeMarker.primary_phone : 'Not Available'}</p>
              </div>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
    );
  })
);
//#endregion render end
export default Map;
