import React, { useState, useRef, useEffect } from 'react';
import { Modal, Button, ProgressBar } from 'react-bootstrap';
import uploadIcon from '../../assets/img/allDocumentList/uploadIcon.png'
import styles from '../../views/my-profile/Index.module.css'
import { uploadFilesApi } from '../../actions/FileUploadActions';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { DOCUMENT_UPLOAD_LIMIT } from '../../config.tsx';
import CommonAlert from '../../common-components/CommonAlert.jsx';
import ImageCrop from './ImageCrop.jsx';
import trashIcon from "./../../assets/img/icons/Airmigrate icons/512px/Trash.png";
import editIcon from "./../../assets/img/icons/Airmigrate icons/512px/Edit.png";
import pdfIcon from "./../../assets/img/icons/Airmigrate icons/512px/pdf_icon.png";
import messageHelper from '../../utilities/helpers/messageHelper.js';
const FileUploadModal = (props) => {
    //#region consts start
    const { show, handleClose, documentId, FunctionToRefreshAfterUpload, isReceivedRequest, setCount, isMyProfileDocs, isPopupChat = false, setModalAlertsArray, modalAlertsArray, visaUpload = false, popupTitle = 'Upload Files', isChatUpload, mFiles, setMfiles, demoCompress } = props;
    const [uploadProgress, setUploadProgress] = useState(0);
    const dropRef = useRef(null);
    // Create  references to the hidden file input elements
    const hiddenFileInput1 = useRef(null);
    const hiddenFileInput2 = useRef(null);
    //Alert states 
    const [alertsArray, setAlertsArray] = useState([]);
    const [uploadCount, setuploadCount] = useState(0);
    // conts for files selected
    const [filesObjectsArray, setFilesObjectsArray] = useState([]);
    const [isErrorMessages, setIsErrorMessages] = useState(false);
    const [tempFileArray, setTempFileArray] = useState([]);
    const [isUpload, setIsupload] = useState(false);
    const [isDocumentUpload, setIsDocumentUpload] = useState(true);
    const [newFileCroppedArray, setNewFileCroppedArray] = useState([])

    //#endregion conts

    //#region functions start
    // Alert handle function
    const handleAlert = (id) => {
        let array = [];
        for (let i = 0; i < alertsArray.length; i++) {
            if ((alertsArray[i].id) == id) {
                array = alertsArray.pop();
            }
        }
        setuploadCount(Math.floor(Math.random() * 1000))
    }

    useEffect(() => {
        setFilesObjectsArray([]);
        setUploadProgress(0);
        if (isChatUpload) {
            handleFile(mFiles);
        }
    }, [uploadCount, mFiles, isChatUpload, show]);
    
    //  function to handle file change event 
    const handleFileChange = (e) => {
        const selectedFiles = Array.from(e.target.files);
        if (selectedFiles) {
            handleFile(selectedFiles);    /*  Calling the handleFile function */
        }
    };

    //function runs when the Button component is clicked to open file input
    const handleClick = (event, fileType) => {
        setUploadProgress(0);
        if (fileType == 'image') {
            hiddenFileInput1?.current.click();
        } else if (fileType == 'pdf') {
            hiddenFileInput2?.current.click();
        }
    };

    // function to handle selected file  and display it on screen
    const handleFile = (selectedFiles) => {
        const newObjectsArray = selectedFiles.map(file => {
            var newFileArray = { 'myFileData': file }
            var fileError = ''
            var isCropped = true
            if (!['image/jpeg', 'image/png', 'application/pdf'].includes(file.type)) {
                fileError = messageHelper('file-type-error');
                isCropped = false
            }else if (file.size > DOCUMENT_UPLOAD_LIMIT) { // 10MB limit
                fileError = fileError = messageHelper('file-size-error');
                isCropped = false
            }
            newFileArray['error'] = fileError
            newFileArray['isCropped'] = isCropped
            return newFileArray
        });
        setTempFileArray([...newObjectsArray])
        // setFilesObjectsArray([...filesObjectsArray, ...newObjectsArray])
    };

    useEffect(() => {
        setFilesObjectsArray([...filesObjectsArray, ...tempFileArray])
    }, [tempFileArray])

    // Function to handel drag and drop of the file  onto the Dropzone area
    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const files = Array.from(e.dataTransfer.files);
        if (files.length > 0) {
            handleFile(files);
        }
    };

    // Function to handel file upload 
    const handleUpload = async () => {
        setIsupload(true);
        if (!filesObjectsArray) return;

        // Data to send with file    
        let formData = new FormData();
        filesObjectsArray.forEach((file) => {
            if (!file.error) {
                formData.append('docs', file.myFileData);
            }
        });
        formData.append('documents_id', documentId);
        // Initialize the upload progress
        setUploadProgress(0);
        // Progress bar update settings
        const targetTime = 60; // total time in seconds (1 minutes)
        const intervalTime = 500; // interval in milliseconds (0.5 second)
        const totalIntervals = targetTime * (1000 / intervalTime);
        const progressIncrement = 100 / totalIntervals;

        // Interval to update the progress bar
        const interval = setInterval(() => {
            setUploadProgress((oldProgress) => {
                if (oldProgress >= 100) {
                    clearInterval(interval);
                    return 100;
                }
                return Math.min(oldProgress + progressIncrement, 100);
            });
        }, intervalTime);

        let URL = 'upload-document';
        if (demoCompress) {
            URL = 'upload-document-demo'
        }
        if (isMyProfileDocs) {
            URL = 'upload-agent-document';
        }
        if (visaUpload) {
            URL = 'upload-visa-document';
        }
        try {
            const response = await uploadFilesApi(URL, formData);
            if (response.status_code === 1) {
                handleCancel();
                setModalAlertsArray([...modalAlertsArray, { id: `file_upload${modalAlertsArray.length}`, type: 'success', message: response?.message }]);
                if (isReceivedRequest) {
                    setCount(Math.floor(Math.random() * 1000));
                }
                FunctionToRefreshAfterUpload();
                setIsupload(false);
                clearInterval(interval);
            } else {
                setAlertsArray([...alertsArray, { id: `file_upload${alertsArray.length}`, type: 'danger', message: response?.message }]);
                setUploadProgress(0);
                setIsupload(false);
                clearInterval(interval);
            }
        } catch (error) {
            clearInterval(interval);
            console.error('Upload failed:', error);
            setAlertsArray([...alertsArray, { id: `file_upload${alertsArray.length}`, type: 'danger', message: 'An error occurred during upload.' }]);
            setUploadProgress(0);
            setIsupload(false);
        }
    };
    // function to cancel the upload process
    const handleCancel = () => {
        setUploadProgress(0);
        setFilesObjectsArray([]);
        setIsErrorMessages(false);
        setTempFileArray([]);
        setIsupload(false);
        if (mFiles && isChatUpload) {
            setMfiles([]);
        }
        handleClose();
    };

    const getFileToUpload = (file, previewShow, index) => {
        setNewFileCroppedArray([file, index, previewShow]);
    }


    useEffect(() => {
        if (newFileCroppedArray) {
            var myDataCheck = []
            for (let index = 0; index < filesObjectsArray.length; index++) {
                const element = filesObjectsArray[index];

                if (index == newFileCroppedArray[1]) {
                    element.myFileData = newFileCroppedArray[0]
                    element.isCropped = false
                }
                myDataCheck.push(element)
            }
            setFilesObjectsArray([...myDataCheck]);
        }

    }, [newFileCroppedArray]);

    /* removing items from file Array */
    const removeItemFromArray = (item) => {
        if (filesObjectsArray) {
            let myDataCheck = []
            for (let index = 0; index < filesObjectsArray.length; index++) {
                const element = filesObjectsArray[index];
                if (element.myFileData.name !== item.myFileData.name) {
                    myDataCheck.push(element)
                }
            }
            setFilesObjectsArray([...myDataCheck]);
        }
    }
    /* useEfect for error check function run while array is ready  */
    useEffect(() => {
        checkErrors();
    }, [filesObjectsArray])

    /* function to check errors  */
    const checkErrors = () => {
        let errorArray = [];
        if (filesObjectsArray) {
            for (let index = 0; index < filesObjectsArray.length; index++) {
                const element = filesObjectsArray[index];
                if (element.error) {
                    errorArray.push(element.error);
                }
            }
            if (errorArray.length >= 1) {
                setIsErrorMessages(true);
            } else {
                setIsErrorMessages(false);
            }
        }
    }

    /* recrop functionality for images */
    const recropImage = (item) => {
        if (filesObjectsArray) {
            var myDataCheck = []
            for (let index = 0; index < filesObjectsArray.length; index++) {
                const element = filesObjectsArray[index];
                if (element.myFileData.name == item.myFileData.name) {
                    element.myFileData = item.myFileData;
                    element.isCropped = true
                }
                myDataCheck.push(element)
            }
            setFilesObjectsArray([...myDataCheck]);
        }
    }
    //#endregion functions end
    //#region render
    return (
        <Modal show={show} onHide={handleClose} className={[styles.uploadFileModalBody, isPopupChat ? 'document-upload-model-file-upload' : '']} size='md' centered>
            <Modal.Header closeButton>
                <Modal.Title className='file-upload-title c-lg'>{popupTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {(alertsArray.length > 0) &&
                    alertsArray.reverse().map((item, index) => {
                        return (
                            <CommonAlert key={index} id={item.id} type={item.type} message={item.message} handleAlert={handleAlert} />
                        )
                    })
                }
                {filesObjectsArray.length > 0 ? (
                    <>
                        {filesObjectsArray.map((item, index) => (
                            <>
                                <div key={index} className={`mt-3 ${styles.filePreviewBlock}`}>
                                    {!isUpload &&
                                        <div className='d-flex justify-content-end my-2'>
                                            <img src={trashIcon} alt="Remove" onClick={() => { removeItemFromArray(item) }} className={styles.canelIconBtn} title="Remove" />
                                            {item.myFileData.type.startsWith('image/') && !(item.error) && !(item.isCropped) &&
                                                <img src={editIcon} alt="Recrop" onClick={() => { recropImage(item) }} className={styles.canelIconBtn} title="Recrop" />
                                            }
                                        </div>
                                    }
                                    <div className='text-center'>
                                        {item.error && <p className="text-danger">{item.error}</p>}
                                        {item.myFileData.type.startsWith('image/') ? (item.isCropped ?
                                            (
                                                <ImageCrop imgSrc={item.myFileData} sendFile={getFileToUpload} index={index} isDocumentUpload={isDocumentUpload} isUpload={isUpload} />
                                            )
                                            :
                                            <div>
                                                <img
                                                    src={URL.createObjectURL(item.myFileData)}
                                                    alt={item.myFileData.name}
                                                    className={styles.filePreviewImg}
                                                />
                                                <div className={styles.uploadedFileInfo}>
                                                    <div>
                                                        <p className='max-width-from-chat-popup'> File Name: {item.myFileData.name}</p>
                                                        <p> File Size: {(item.myFileData.size / 1024 / 1024).toFixed(2)} MB</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                            :
                                            (
                                                <>
                                                    {item.myFileData.type.startsWith('application/pdf') &&
                                                        <>
                                                            <p>Preview not available for this file type.</p>
                                                            <img src={pdfIcon} alt="pdf_icon" height={100} width={'auto'} />
                                                        </>
                                                    }
                                                    <div className={styles.uploadedFileInfo}>
                                                        <div>
                                                            <p className='max-width-from-chat-popup'> File Name: {item.myFileData.name}</p>
                                                            <p> File Size: {(item.myFileData.size / 1024 / 1024).toFixed(2)} MB</p>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        }
                                    </div>
                                </div>
                            </>
                        ))}
                        <ProgressBar now={uploadProgress} label={`${uploadProgress}%`} animated variant='success' visuallyHidden />
                        <div className={styles.fileUploadButtonBlock}>
                            <Button disabled={isUpload} variant='danger' onClick={handleCancel}>Cancel</Button>
                            <Button variant='success' onClick={handleUpload} disabled={isErrorMessages || isUpload}>Upload</Button>

                        </div>
                    </>
                ) :
                    (
                        <div ref={dropRef} onDragOver={handleDragOver} onDrop={handleDrop} className={styles.fileDragDropArea}>
                            <img className={'chat-popup-file-uploaded-grad  file-upload-img-icon'} src={uploadIcon} alt="" />
                        </div>

                    )}
                {!isUpload &&
                    <div>
                        <div className={` mb-2 ${styles.seprateFileTypeButton}`} >
                            <p onClick={(e) => { handleClick(e, 'image') }} >Select Images</p>
                            <input type="file" onChange={handleFileChange} style={{ display: 'none' }} ref={hiddenFileInput1} multiple={true} accept="image/png, image/jpeg, image/jpg" />
                        </div>
                        <div className={styles.seprateFileTypeButton} >
                            <p onClick={(e) => { handleClick(e, 'pdf') }} >Select PDF Files</p>
                            <input type="file" onChange={handleFileChange} style={{ display: 'none' }} ref={hiddenFileInput2} multiple={true} accept="application/pdf" />
                        </div>
                    </div>
                }
                {popupTitle == 'Visa Upload' && <span style={{ fontSize: 12 }}>This is needed to ensure we are not dealing with a bot and to ensure you get the best deals. Validated clients get the first priority. </span>}

            </Modal.Body>
        </Modal>
    );
};
//#endregion render
export default FileUploadModal;
