import React from 'react';
import { Fragment, useEffect, useState } from 'react';
import "react-datetime/css/react-datetime.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCog, faHome, faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Form, Button, ButtonGroup, Breadcrumb, InputGroup, Dropdown } from '@themesberg/react-bootstrap';

//Local imports
import MobileNotificationsTable from '../../components/air-migrate/MobileNotificationsTable.jsx';
import "../../scss/volt.scss";
import '../../assets/global-css/Index.css';
import { useHistory } from 'react-router-dom';
import CommonServices from '../../common-components/CommonServices.jsx';
import { HomepageUrl } from '../../utilities/helpers/Constant.jsx';
import { GetNotificationsCountApi } from '../../actions/CommonActions.jsx';
import { SOCKET_SERVER_URL } from '../../config.tsx';
import io from 'socket.io-client'

const MobileNotifications = () => {
    //use state for notification counts
    const[notificationCounts,setNotificationCounts] = useState();
    //#region States and variables
    const history = useHistory();

    // useEffect code here
      useEffect(() => {
        const amUserId = localStorage.getItem('amUserId');
        if(amUserId){
            GetAllNotificationCounts();
            
            const newSocket = io(SOCKET_SERVER_URL)      
            newSocket.on('connect', () => {
                newSocket.emit('connect_on_notification', amUserId)
            })
            newSocket.on('new_notification', (response) => {
                setNotificationCounts(response.length)
            })
        }


      }, []);
    //#endregion States and variables

 /* function to get notification counts */
 const GetAllNotificationCounts = async () => {
    const response = await GetNotificationsCountApi();
    if (response?.status_code == 1) {
      const notifications = response.data
      setNotificationCounts(notifications);
  }
}

    /* Notification Unleash */
    let notificationFlag = CommonServices.GetUnleashFeatureFlag('get-my-notifications');

    return (
        <Fragment>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-0 pb-2">
                <div className="d-block  mb-md-0">
                    <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
                        <Breadcrumb.Item className='pe-auto' onClick={() => history.push(HomepageUrl)}><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
                        <Breadcrumb.Item active>Clients</Breadcrumb.Item>
                    </Breadcrumb>
                    <div className='d-flex align-items-center justify-content-center'>
                    <h4 className={`mt-2 f-18`}>Notifications </h4><p className='ms-2 mobile-menu-notification-count text-white'>{notificationCounts}</p>
                    </div>
                </div>
            </div>

            {notificationFlag &&
                <MobileNotificationsTable />
            }
        </Fragment>
    )
    //#endregion
}

export default MobileNotifications;