
import { authApi } from '../utilities/helpers/ApiHelper';
import { toast } from 'react-hot-toast';

async function GetAllAgentListApi(searchedText) {
    let url = '';
    if (searchedText !== '') {
        url = `getAllBroker?search=${searchedText}`;
    }
    else url = 'getAllBroker'
    try {
        const response = await authApi.getAPI(url);
        // return response.data;

        if (response.status_code === 1) {
            //  toast.success(response.message, { duration: 3000 });
            return response.data;

        } else {
            toast.error(response.message, { duration: 4000, },);
        }
        return response.data;
    }
    catch (e) {
        toast.error("Something went wrong!", { duration: 4000 });
    }
}

async function GetFavouritesByIdApi(user_uuid) {

    let url = `createMyFavoriteBroker/${user_uuid}`;
    try {
        const response = await authApi.getAPI(url);
        // toast.success(response.message, { duration: 3000 });
        return response;
    }
    catch (e) {
        toast.error("Something went wrong!", { duration: 4000 });
    }
}

async function GetAllFavouritesListApi(searchedWord) {
    let url = '';
    if (searchedWord !== '') {
        url = `getMyAllBroker?search=${searchedWord}`;
    }
    else url = 'getMyAllBroker'
    try {
        const response = await authApi.getAPI(url);
        // console.log("fav",response)
        // return response.data;

        if (response.status_code === 1) {
            //  toast.success(response.message, { duration: 3000 });
            return response.data;

        } else {
            toast.error(response.message, { duration: 4000, },);
        }
        return response.data;
    }
    catch (e) {
        toast.error("Something went wrong!", { duration: 4000 });
    }
}

async function GetAgentDetailByIdApi(user_uuid) {

    let url = `broker-details/${user_uuid}`;
    try {
        const response = await authApi.getAPI(url);
        if (response.status_code === 1) {
            // toast.success(response.message, { duration: 3000 });
            return response.data;

        } else {
            toast.error(response.message, { duration: 4000, },);
        }
        return response.data;
    }
    catch (e) {
        toast.error("Something went wrong!", { duration: 4000 });
    }
}
// Get top 4 Brokers list api  
async function GetTopBroker() {

    let url = `getTopBroker`;
    try {
        const response = await authApi.getAPI(url);
        return response;
    }
    catch (e) {
        toast.error("Something went wrong!", { duration: 4000 });
    }
}

// Get Top 4 Immigration Consultant api 
async function GetTopConsutant() {
    let url = `getTopConsutant`;
    try {
        const response = await authApi.getAPI(url);
        return response;
    }
    catch (e) {
        toast.error("Something Went wrong!", { duration: 4000 })
    }
}

//function created to call sent a query api
async function CreateQueryApi(brokerDetails, id) {

    let url = `send-broker-query/${id}`;
    try {
        const response = await authApi.postAPI(url, brokerDetails);
        return response;
    }
    catch (e) {
        console.error(e);
    }
}

async function GetLatLngBrokerListApi(latitude = 22.7532848, longitude = 75.8936962) {
    let url = `getAllBrokerWithPosition?lat=${latitude}&lng=${longitude}&description=true`;
    try {
        const response = await authApi.getAPI(url);
        return response;
    }
    catch (e) {
        console.log("Something went wrong!", e)
    }
}
/* new Real estate broker  list */
async function GetBrokersWithLocationApi(search, lat, long, favorite) {
    let url = 'get-broker-data-with-lat-long?';
    let pre = false
    if (search) {
        pre = true;
        url += 'search=' + search
    }

    if (lat & long) {
        pre = true
        let latlong = 'lat=' + lat + '&long=' + long
        url += pre ? '&' + latlong : latlong;
    }
    if (favorite) {
        let fav = 'favorite=' + favorite;
        url += pre ? '&' + fav : fav;
    }

    try {
        const response = await authApi.getAPI(url);
        return response;
    }
    catch (e) {
        console.log("Something went wrong!", e);
    }
}

/* new get agent details API here  */
async function GetNewAgentDetailByIdApi(user_uuid) {

    let url = `broker-details-lists/${user_uuid}`;
    try {
        const response = await authApi.getAPI(url);
        return response;
    }
    catch (e) {
        console.error("Something went wrong!", { duration: 4000 });
    }
}

export {
    GetAllAgentListApi, GetFavouritesByIdApi, GetAllFavouritesListApi, GetAgentDetailByIdApi, GetTopBroker,
    CreateQueryApi, GetLatLngBrokerListApi, GetTopConsutant, GetBrokersWithLocationApi,GetNewAgentDetailByIdApi
}