import React, { Fragment, useEffect, useState } from 'react';
import { Button, Card, Image ,Modal} from '@themesberg/react-bootstrap';
import { useHistory } from 'react-router-dom';
//Local imports
import { GetClientDocumentsListByIdForBrokerApi } from '../../actions/ClientActions';
import { Capitalize } from '../../common-components/StringConversions';
import AirMigrateLogo from '../../assets/img/landing-page/logo-img.png';
import { GetAssociatedMemberListForClientAndCaseApi, CreateDocumentRequestApi, RemoveRequestedDocumentsAPI } from '../../actions/CaseActions';
import styles from '../../views/cases/case-details/Index.module.css';
import { useSelector } from 'react-redux';
import { GetLinkToDownloadDocumentWithAlertApi } from '../../actions/FileUploadActions';
import Delete from '../../assets/img/icons/Airmigrate icons/16px/Trash.png';
import CommonConfirmBox from '../../common-components/CommonConfirmBox';
import CaseCompeteInfoModal from './CaseCompleteMessageModal';

const ViewClientDocumentModel = ({ handleAlert, alertsArray, setAlertsArray, viewCount, setViewCount, countryCodeOfSelectedMember, userName, ChangedParticipantUserIds, setChangedParticipantUserIds, isEditProfile, userId, count, setCount, caseId, setRequestMoreClicked, caseStatus }) => {
    //#region State and variable
    const [isLoading, setIsLoading] = useState();
    const [applicantsDoc, setApplicantsDoc] = useState([]);
    const [sponsorsDoc, setSponsorsDoc] = useState([]);
    const [documentList, setDocumentList] = useState([]);
    const [participantDropdownList, setParticipantDropdownList] = useState([]);
    const [participantsUserIds, setParticipantUserIds] = useState([]);
    const [associateMemberList, setAssociateMemberList] = useState([]);
    const [selectedParticipantId, setSelectedParticipantId] = useState({});
    const [userRoleId, setUserRoleId] = useState(localStorage.getItem('roleId'));
    const history = useHistory();
    const [viewCount2, setViewCount2] = useState(0);
    const showRequestButton = useSelector(state => state.myPaymentModule.isInstallmentAvailable)
    // Variable for confirm box
    const [openConfirmBox, setOpenConfirmBox] = useState(false)
    const [confirmBoxValue, setConfirmBoxValue] = useState(false)
    const handleConfirmBox = () => setOpenConfirmBox(!openConfirmBox)
    const [clickedDocumentId, setClickedDocumentId] = useState();
    const [showCaseCompleteInfoModal, setshowCaseCompleteInfoModal] = useState(false);

    //#endregion State and variable

    //#region useEffect
    useEffect(() => {
        if ((isEditProfile == false) && (userId == undefined)) {
            GetAssociatedMemberListForClientAndCase();
        }
        else {
            GetDocumentsListById(userId);
        }
    }, [count, viewCount, viewCount2])
    //#endregion

    //#region Functions

    const GetDocumentsListById = async (id) => {
        setIsLoading(true);
        var documentsList;
        if (isEditProfile == true) {
            documentsList = await GetClientDocumentsListByIdForBrokerApi(userId);
        }
        else {
            documentsList = await GetClientDocumentsListByIdForBrokerApi(id, caseId);
        }
        setDocumentList(documentsList);
        if (documentsList?.length) {
            SetDocuments(documentsList[0]);
        }
        setIsLoading(false);
    }

    const SetDocuments = async (docList) => {
        setApplicantsDoc(docList.applicants_doc);
        setSponsorsDoc(docList.sponser_doc);
    }

    //Double call from case details page
    const GetAssociatedMemberListForClientAndCase = async () => {
        const response = await GetAssociatedMemberListForClientAndCaseApi(userId, caseId);
        setAssociateMemberList(response);
        if (response?.length) {
            SetParticipantsDropdownList(response);
        }
    }

    const SetParticipantsDropdownList = async (data) => {
        var participantArray = [];
        var userIds = [];
        for (let i = 0; i < data.length; i++) {
            if (data[i].is_primary == true) {
                GetDocumentsListById(data[i].user_uuid);
            }
            if (data[i].status != 1) {
                participantArray.push({
                    label: (data[i].first_name == null || data[i].first_name == '') ? `User 
                
                ` : Capitalize(data[i].first_name), value: Capitalize(data[i].first_name), id: data[i].user_uuid
                });
                setParticipantDropdownList(participantArray);
                userIds.push(data[i].user_uuid);
            }
        }
        setParticipantUserIds(userIds);
    }

    const DownloadDocument = async (docId) => {
        let url = `view-document`;
        const response = await GetLinkToDownloadDocumentWithAlertApi(url, docId);
        if (response.status_code == 1) {
            setAlertsArray([...alertsArray, { id: `download-document${alertsArray.length}`, type: 'success', message: response.message }]);
        }
        else {
            setAlertsArray([...alertsArray, { id: `download-document${alertsArray.length}`, type: 'danger', message: response.message }]);
        }
        const downloadUrl = response?.data[0].url;
        let alink = document.createElement("a");
        alink.href = downloadUrl;
        alink.click();
        setViewCount2(Math.floor(Math.random() * 1000));
    }

    const CreateDocumentRequest = async (id) => {
        const requestBody = {
            documents_id: id
        }
        const response = await CreateDocumentRequestApi(requestBody);

        if (response.status_code == 1) {
            setAlertsArray([...alertsArray, { id: `create-document-request${alertsArray.length}`, type: 'success', message: response.message }]);
        }
        else {
            setAlertsArray([...alertsArray, { id: `create-document-request${alertsArray.length}`, type: 'danger', message: response.message }]);
        }
        setViewCount2(Math.floor(Math.random() * 100));
    }

    // remove documents from requested list
    const removeDocuments = async () => {
        const requestBody = {
            documents_id: clickedDocumentId,
            request_user_uuid: userId
        }
        setConfirmBoxValue(false)
        let Url = ``;
        if (isEditProfile == true) {
            Url = `document-access-request-delete`
        } else {
            Url = `document-request-remove`;
        }
        const response = await RemoveRequestedDocumentsAPI(Url, requestBody);
        if (response.status_code == 1) {
            GetDocumentsListById(userId)
            setAlertsArray([...alertsArray, { id: `create-document2-request${alertsArray.length}`, type: 'success', message: response.message }]);
        }
        else {
            setAlertsArray([...alertsArray, { id: `create-document2-request${alertsArray.length}`, type: 'danger', message: response.message }]);
        }
    }

    // Delete icon for document requested
    const DeleteDocumentIcon = ({ item }) => {
        return (
            [2, 4, '2', '4'].includes(userRoleId) &&
            <Image
                src={Delete}
                style={{ marginLeft: 5, color: "red", fontSize: "25px", cursor: "pointer", height: '100%' }}
                onClick={() =>
                (handleConfirmBox(),
                    setClickedDocumentId(item?.id))
                }
            />
        )
    }

    // useEffect for confirm box value
    useEffect(() => {
        if (confirmBoxValue) {
            handleConfirmBox()
            removeDocuments()
        }
    }, [confirmBoxValue])   

        //#endregion

        //#region JSX
        return (
            <Fragment>
                {documentList?.length > 0 ?
                    <Card className="document-card-block document-list-block">
                        <div className="d-flex document-card-inner active-card justify-content-center" >
                            <Card.Text className='f-18' >List of documents</Card.Text>
                        </div>
                        {documentList?.length && documentList?.map((item, index) => {
                            if (isEditProfile == true) {
                                if (index <= 7) {
                                    return (
                                        <div className="d-flex document-card-inner document-content-block" key={index}
                                            style={{
                                                borderBottom: (((documentList.length < 7) && (index == (documentList.length - 1))) || ((documentList.length >= 7) && (index == 6))) ? 'none' : '1px solid #D1D7E0'
                                            }}
                                        >
                                            {((userRoleId == 3) || (userRoleId == 1)) ?
                                                <Card.Text>{` ${Capitalize(item.document_type)} : ${Capitalize(item.document_option)}`}</Card.Text> :
                                                <Card.Text>{` ${Capitalize(item.document_type)} : `}
                                                    <p>{Capitalize(item.document_option)}</p>
                                                </Card.Text>
                                            }
                                            {(userRoleId == 2 || userRoleId == 4) &&
                                                <div className={styles.associatedbuttonblock} >
                                                    {(item?.status?.current_staus == null) ?
                                                        <button
                                                            className={`mb-2 f-16 bg-c-dg pe-auto border-none ${styles.inviteDocDownloadBtn}`}
                                                            onClick={() => { CreateDocumentRequest(item.id) }}
                                                        >
                                                            Request
                                                        </button> : (item?.status?.current_staus == 1 && item?.status?.url != null) ?
                                                            <Button variant='primary' className={`mb-1 p-1 f-16 ${styles.inviteDocDownloadBtn}`} onClick={() => {
                                                                DownloadDocument(item.id);
                                                            }}>Download</Button>
                                                            :
                                                            (item?.status?.current_staus == 0) ?
                                                                <div className='d-flex  align-items-center mb-1'>
                                                                    <button
                                                                        className=' f-16'
                                                                        style={{ color: "#6d747d", backgroundColor: "#61dafb", borderColor: " #61dafb", cursor: "pointer" }}
                                                                        onClick={() => { }}
                                                                    >Requested
                                                                    </button>
                                                                    <DeleteDocumentIcon item={item} />
                                                                </div>
                                                                :
                                                                (item?.status?.current_staus == 2) ?
                                                                    <div className='d-flex align-items-center mb-1'>
                                                                        <button
                                                                            className=' f-16' style={{ backgroundColor: '#FA5252', border: 'none', cursor: "pointer" }}
                                                                            onClick={() => { }}
                                                                        >
                                                                            Rejected
                                                                        </button>
                                                                        <DeleteDocumentIcon item={item} />
                                                                    </div>
                                                                    :
                                                                    (item?.status?.current_staus == 1 && item?.status?.url == null && item.uploaded == false) &&
                                                                    <div className='d-flex align-items-center mb-1'>
                                                                        <button className='f-16'
                                                                            style={{ marginBottom: "5px", color: "#6d747d", backgroundColor: "#61dafb", borderColor: " #61dafb", cursor: "pointer" }}
                                                                            onClick={() => { }}
                                                                        >
                                                                            {/* Not Uploaded */}
                                                                            Requested
                                                                        </button>
                                                                        <DeleteDocumentIcon item={item} />
                                                                    </div>
                                                    }
                                                </div>
                                            }
                                        </div>
                                    )
                                }
                            }
                            else {
                                return (
                                    <div className="d-flex document-card-inner document-content-block" key={index}
                                        style={{
                                            borderBottom: `${(index < (documentList?.length - 1)) ? '1px solid #D1D7E0' : 'none'}`
                                        }}
                                    >
                                        {((userRoleId == 3) || (userRoleId == 1)) ?
                                            <Card.Text>{` ${Capitalize(item.document_type)} : ${Capitalize(item.document_option)}`}</Card.Text> :
                                            <Card.Text>{` ${Capitalize(item.document_type)} : `}
                                                {Capitalize(item.document_option)}
                                            </Card.Text>
                                        }
                                        {(userRoleId == 2 || userRoleId == 4) &&
                                            <div className={styles.associatedbuttonblock} >
                                                {(item?.status?.current_staus == null) ?
                                                    <button
                                                        className={`mb-2 f-16 bg-c-dg border-none pe-auto ${styles.inviteDocDownloadBtn}`}

                                                        onClick={() => { CreateDocumentRequest(item.id) }}
                                                    >
                                                        Request
                                                    </button> : (item?.status?.current_staus == 1 && item.status.url != null) ?
                                                        <Button variant='primary' className={`mb-1 p-1 f-16 ${styles.inviteDocDownloadBtn}`} onClick={() => {
                                                            DownloadDocument(item.id)
                                                        }}>Download</Button>
                                                        :
                                                        (item?.status?.current_staus == 0) ?
                                                            <div className='d-flex align-items-center mb-1'>
                                                                <button className='f-16'
                                                                    style={{ cursor: "pointer", color: "#6d747d", backgroundColor: "#61dafb", borderColor: " #61dafb" }}
                                                                    onClick={() => { }}
                                                                >Requested
                                                                </button>
                                                                <DeleteDocumentIcon item={item} />
                                                            </div>
                                                            :
                                                            (item?.status?.current_staus == 2) ?
                                                                <div className='d-flex align-items-center mb-1'>
                                                                    <button
                                                                        className=' f-16 mb-1' style={{ cursor: "pointer", backgroundColor: '#FA5252', border: 'none', }}
                                                                        onClick={() => { }}
                                                                    >
                                                                        Rejected
                                                                    </button>
                                                                    <DeleteDocumentIcon item={item} />
                                                                </div>
                                                                :
                                                                (item?.status?.current_staus == 1 && item?.status?.url == null && item.uploaded == false) &&
                                                                <div className='d-flex align-items-center mb-1'>
                                                                    <button className=' f-16 mb-1'
                                                                        style={{ cursor: "pointer", color: "#6d747d", backgroundColor: "#61dafb", borderColor: " #61dafb" }}
                                                                        onClick={() => { }}
                                                                    >
                                                                        {/* Not Uploaded */}
                                                                        Requested
                                                                    </button>
                                                                    <DeleteDocumentIcon item={item} />
                                                                </div>
                                                }
                                            </div>
                                        }
                                    </div>
                                )
                            }
                        })}
                        
                        {(isEditProfile == false) ?
                            <>
                                {(([2, 4, '2', '4'].includes(userRoleId))) &&
                                    <div className='request-button-block'>
                                        <Button style={{ cursor: "pointer", marginBottom: "10px !important" }} className="view-document-button mb-2 f-16 w-auto"
                                            onClick={() => {
                                                if (caseStatus == 3) {
                                                    setshowCaseCompleteInfoModal(true);
                                                }else{
                                                    setRequestMoreClicked(true)
                                                }
                                            }
                                            }>
                                            Request More
                                        </Button>
                                    </div>
                                }
                            </>
                            :
                            (documentList.length > 7) &&
                            <div className='request-button-block'>
                                <Button style={{ cursor: "pointer", marginBottom: "10px !important" }} className="view-document-button mb-2 v" onClick={() => history.push('/all-document-list', { userId: userId, userName: userName, isEdit: true })}>
                                    View All
                                </Button>
                            </div>
                        }
                    </Card>
                    :
                    <Card className="no-document-card">
                        <div className="d-flex document-card-inner active-card justify-content-center" >
                            <Card.Text className='f-18' >List of documents</Card.Text>
                        </div>
                        <div className="no-document-content" style={{ textAlign: "center" }}>
                            <img src={AirMigrateLogo} alt="air-migrate-logo" className="mb-3 no-document-logo-image" />
                            <h2 style={{ fontSize: "16px", color: "#4A5073" }}>No documents available</h2>

                            {((isEditProfile == false) && ([2,4,'2','4'].includes(userRoleId))) && 
                                <Button style={{ cursor: "pointer" }} className="view-document-button f-16" onClick={() => { 
                                    if (caseStatus == 3) {
                                        setshowCaseCompleteInfoModal(true);
                                    }else{
                                        setRequestMoreClicked(true)
                                    }
                                    }}>
                                    Request
                                </Button>
                            }
                        </div>
                    </Card>
                }
                {
                    openConfirmBox &&
                    <CommonConfirmBox openConfirmBox={openConfirmBox} handleConfirmBox={handleConfirmBox} setConfirmBoxValue={setConfirmBoxValue} title='Are you sure do you want to remove document?' />
                }
                 {/* Case Completed Warning pop up modal */}
                 <CaseCompeteInfoModal showCaseCompleteInfoModal={showCaseCompleteInfoModal} setshowCaseCompleteInfoModal={setshowCaseCompleteInfoModal}/>
            </Fragment>
        )
        //#endregion
    }
    export default ViewClientDocumentModel;