import React, { Fragment, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Col,
  Row,
  Card,
  Image,
  OverlayTrigger,
  Tooltip,
  Table,
} from "@themesberg/react-bootstrap";
import { useHistory } from "react-router-dom";
import { toast } from "react-hot-toast";
import { Breadcrumb, Form, InputGroup, Dropdown, Button, ButtonGroup } from "@themesberg/react-bootstrap";
import { faHome, faSearch } from "@fortawesome/free-solid-svg-icons";
import styles from '../../../views/cases/Index.module.css';

//Local imports, core styles, vendor styles
import { GetConsultantBrokerCaseListApi } from "../../../actions/CaseActions.jsx";
import { DataLoading } from "../../../components/air-migrate/Loader.jsx";
import { Capitalize, CapitalizeAllWords } from "../../../common-components/StringConversions";
import NoDataFound from "../../../components/air-migrate/NoDataFound";
import CustomPagination from "../../../common-components/CustomPagination";
// import styles from "../../../views/cases/immigration-case-list/Index.css";
// import "../../../assets/global-css/Index.css";
import "../../../scss/volt.scss";
import "react-datetime/css/react-datetime.css";
import CommonServices from "../../../common-components/CommonServices.jsx";
import Trash from '../../../assets/img/icons/Airmigrate icons/16px/Trash.png';
import Group from '../../../assets/img/icons/Airmigrate icons/16px/Group.png';
import Eye from '../../../assets/img/icons/Airmigrate icons/16px/View.png';
import Chat from '../../../assets/img/icons/Airmigrate icons/16px/Chat.png';
import { HomepageUrl } from "../../../utilities/helpers/Constant.jsx";
import ProfileIconsModal from "../../../components/air-migrate/ProfileIconsModal.jsx";
import CommonBreadcrumb from "../../../common-components/CommonBreadcrumb.jsx";

const BrokerCaseList = ({ search, isSearchChange }) => {
  //#region States and variables
  const history = useHistory();
  const [brokerCaseList, setBrokerCaseList] = useState([]);
  const [copiedbrokerCaseList, setCopiedbrokerCaseList] = useState([]);
  const [startingIndex, setStartingIndex] = useState(1);
  const [roleId, setRoleId] = useState("");
  const [pageLimit, setPageLimit] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [previousActiveItemValue, setPreviousItemActiveValue] = useState(1);


  //pagination relates states
  const [activeItem, setActiveItem] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalClientCount, setTotalClientCount] = useState(0);
  const [count, setCount] = useState(0);
  const [consultantName, setConsultantName] = useState(
    Capitalize(localStorage.getItem("userName"))
  );

  // Login user uuid for chat link
  const [loginUserId, setLoginUserId] = useState("");

  //States to manage mobile ui
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const [singleScreen, setSingleScreen] = useState(false);

  //Breadcrumb state
  const [breadcrumbArray, setBreadcrumbArray] = useState([
    { name: 'Home', url: HomepageUrl },
    { name: 'Cases', url: '' }
  ]);

  //#endregion

  //#region Styles
  const filterStyle = {
    marginRight: "2px",
    marginLeft: "3px",
    // color: '#0184fe'
  };
  //#endregion

  //#region useEffect

  useEffect(() => {
    const amUserId = localStorage.getItem('amUserId')
    if (amUserId) {
      setLoginUserId(amUserId)
    }
    GetBrokerList();

    //functionality to set collapsible table 
    window.addEventListener('resize', updateDimension);
    updateDimension();

    // removing eventlistener for no furhter effect after 
    return (() => {
      window.removeEventListener('resize', updateDimension);
    })
  }, [searchText, activeItem]);
  //#endregion

  //#region style
  const hideChildTable = {
    display: 'none'
  }
  //#endregion


  //#region Functions (all document list table body)
  const updateDimension = () => {
    setScreenSize(getCurrentDimension());
    var value = getCurrentDimension();
    // set a variable true when screen width reaches <768px
    if (value.width <= 767) {
      setSingleScreen(true)
    } else {
      setSingleScreen(false)
    }
  }

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight
    }
  }

  const handleTableCollapse = (parentId, childId) => {
    const childElement = document.getElementById(childId);
    const parentElement = document.getElementById(parentId);
    console.log("childElement, parentElement and content", parentId, childId, childElement, parentElement);
    if (parentElement.innerHTML === '+') {
      childElement.style.display = 'table-row';
      parentElement.innerHTML = '-';
    }
    else {
      childElement.style.display = 'none';
      parentElement.innerHTML = '+';
    }
  }


  const TableRow = ({ case_name, applicants, sponser, case_status, case_id, creator }) => {
    console.log("case_name", case_name);

    return (
      <tr>
        <td>
          <div className="case-name-content f-16">
            {CapitalizeAllWords(case_name || "")}
          </div>
        </td>
        <td>
          {applicants
            ? applicants.map((applicant, index) => (
              <div
                key={`applicant-${index}`}
                className={styles.casesprofilecontentblock}
              >
                <div>
                  <a onClick={() => {
                    history.push(`/admin/clients/edit-client`, { userId: applicant.user_uuid, caseId: applicant.case_id, caseName: applicant.case_name })
                  }} className="user-avatar casses-img-avatar m-2 f-16">
                    {/* {applicant.profile_path ? (
                      <Image
                        src={applicant.profile_path}
                        className="rounded-circle me-3"
                      />
                    ) : (
                      <div className={`me-3 bg-light rounded-circle d-flex justify-content-center align-items-center p-3 immigration-case-list`} >
                        {((applicant?.first_name != null) && (applicant?.last_name != null))
                          ?
                          <small className=" fw-bolder m-0 text-black">{CommonServices.getNameCharByFullName(applicant?.first_name + ' ' + applicant?.last_name)}</small> :
                          (applicant?.first_name != null) ?
                            <small className=" fw-bolder m-0 text-black">{CommonServices.getNameCharByFullName(applicant?.first_name)}</small> :
                            <small className=" fw-bolder m-0 text-black">U</small>
                        }
                      </div>
                    )} */}

                    <ProfileIconsModal userDetails={applicant} />

                  </a>
                </div>
                <div className={styles.tablecasesprofilename}>
                  <a className="f-16 m-2" onClick={() => {
                    history.push(`/admin/clients/edit-client`, { userId: applicant.user_uuid, caseId: applicant.case_id, caseName: applicant.case_name })
                  }}>
                    {CommonServices.getUserFullName(applicant)}
                  </a>
                </div>
              </div>
            ))
            : <span className="f-16">Not available</span>}
        </td>
        <td>
          {creator
            ? creator.map((creator, index) => (
              <div
                key={`creator-${index}`}
                className={styles.casesprofilecontentblock}
              >
                <div className="img-case-cursor">
                  <a className="user-avatar casses-img-avatar m-2">
                    <ProfileIconsModal userDetails={creator} />

                    {/* {creator.profile_path ? (
                      <Image
                        src={creator.profile_path}
                        className="rounded-circle me-3"
                      />
                    ) : (
                      <div className={`me-3 bg-light rounded-circle d-flex justify-content-center align-items-center p-3 immigration-case-list`}  >
                        {((creator?.first_name != null) && (creator?.last_name != null))
                          ?
                          <small className=" fw-bolder m-0 text-black">{CommonServices.getNameCharByFullName(creator?.first_name + ' ' + creator?.last_name)}</small> :
                          (creator?.first_name != null) ?
                            <small className=" fw-bolder m-0 text-black">{CommonServices.getNameCharByFullName(creator?.first_name)}</small> :
                            <small className=" fw-bolder m-0 text-black">U</small>
                        }
                      </div>
                    )} */}
                  </a>
                </div>
                <div className={styles.tablecasesprofilename}>
                  <a className="img-case-cursor f-16 m-2" onClick={() => {
                    history.push(`/admin/clients/edit-client`, { userId: creator.user_uuid, caseId: creator.case_id, caseName: creator.case_name })
                  }}>
                    {CommonServices.getUserFullName(creator)}
                  </a>
                </div>
              </div>
            ))
            : ""}
        </td>

        <td>
          <p>
            {case_status === 0
              ? "Pending"
              : case_status === 1
                ? "Active"
                : case_status === 2
                  ? "Inactive"
                  : ""}
          </p>
          {/* <div className={styles.statusbutton}>
            <button
              // style={{ backgroundColor: case_status === 0 ? '#9B59B6' : case_status === 1 ? '#54A0FF' : case_status === 2 ? '#30CB83' : '#9B59B6' }}
            style={{ backgroundColor: case_status === 0 ? "#FFB136" : case_status === 1 ? "#05A677" : case_status === 2 ? "#0184FE" : '#9B59B6' }}
            >
              {case_status === 0 ? "Pending" : case_status === 1 ? "Active" : case_status === 2 ? "Inactive" : ""}</button>
          </div> */}
        </td>
        <td>
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <span className="icon icon-sm">
                <Image src={Group} className="form-range-track-cursor" />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-action-show">

              <Dropdown.Item
                onClick={() => {
                  history.push(`/chat`, { userId: loginUserId, caseId: case_id, caseName: case_name })
                }}
              >
                <Image src={Chat} className="form-range-track-cursor" /> Chat
              </Dropdown.Item>

              <Dropdown.Item
                onClick={() => {
                  history.push(`/cases/case-details`, { userId: loginUserId, caseId: case_id, caseName: brokerCaseList.case_name, isBrokerList: true })
                }}
              >
                <Image src={Eye} className="form-range-track-cursor" /> View
              </Dropdown.Item>

            </Dropdown.Menu>
          </Dropdown>

          {/* <div className={styles.actionbuttongroup}>
            <div className={styles.chatbtn}>
              <button 
              onClick={() => {
               history.push(`/chat`,{ userId: loginUserId, caseId: case_id, caseName: case_name}) 
              }}
              >Chat</button>
            </div>
            <div
              className={styles.viewbtn}>
              <button 
              onClick={() => {
               history.push(`/cases/case-details`,{ userId: loginUserId, caseId: case_id, caseName: brokerCaseList.case_name}) 
              }}
              >View Details</button>
            </div>
          </div> */}
        </td>
      </tr>
    );
  };

  const MobileTableBrokerCaseList = () => {
    return (
      <table className="table">
        <thead className="mobile-thead">
          <tr>
            <th ></th>
            <th className="text-wrap f-14">Broker</th>
            <th className="mobile-table-action-th f-14" >Action</th>
          </tr>
        </thead>
        {
          brokerCaseList.map(props => {
            const { case_name, applicants, sponser, case_status, case_id, creator } = props;
            console.log("sponsors, applicants and creators", sponser, applicants, creator);
            var originalCaseName = CapitalizeAllWords(case_name);
            var editedCaseName = '';
            if (originalCaseName.length > 25) {
              editedCaseName = originalCaseName.slice(0, 24);
            }
            console.log("original");
            return (
              <tbody key={`client-${case_id}`}>
                <tr>
                  <td className=""><span className="plus-icon" id={`expandChildTable-${case_id}`}
                    onClick={() => handleTableCollapse(`expandChildTable-${case_id}`, `childTableRow-${case_id}`)}
                  >+</span></td>
                  <td className="text-wrap f-16">
                    {`${originalCaseName.length > 25 ? editedCaseName + '...' : originalCaseName}`}
                  </td>
                  <td className="mobile-table-action-td">
                    <Dropdown as={ButtonGroup}>
                      <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
                        <span className="icon icon-sm">
                          <Image className="pe-none" src={Group} />
                        </span>
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dropdown-action-show">
                        <Dropdown.Item className="f-16"
                          onClick={() => {
                            history.push(`/chat`, { userId: loginUserId, caseId: case_id, caseName: case_name })
                          }}
                        >
                          <Image className="pe-auto pa-r-5" src={Chat} /> Chat
                        </Dropdown.Item>
                        <Dropdown.Item className="f-16"
                          onClick={() => {
                            history.push(`/cases/case-details`, { userId: loginUserId, caseId: case_id, caseName: brokerCaseList.case_name, isBrokerList: true })
                          }}
                        >
                          <Image className="pe-auto pa-r-5" src={Eye} /> View
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
                <tr id={`childTableRow-${case_id}`} style={hideChildTable}>
                  <td colSpan="3">
                    <div className="text-wrap ">
                      {((creator !== null) && (creator !== undefined) && (creator.length > 0)) ?
                        <h6 className="f-16 c-dg">Broker : {" "}
                          {
                            creator?.map((item, index) => {
                              const { first_name, last_name, profile_path } = item;
                              return (
                                <Row className="align-items-center pe-none text-wrap img-block-collapse pt-2" key={index}>
                                  <Col className="col-auto imageLeftLess pa-r-0">
                                    <a className="user-avatar pe-none" onClick={() => {
                                      history.push(`/admin/clients/edit-client`, { userId: item.user_uuid, caseId: item.case_id, caseName: item.case_name })
                                    }}>
                                      {(profile_path == '' || profile_path == undefined || profile_path == null) ?
                                        <div className={` bg-light\ d-flex justify-content-center align-items-center img-d-35`} >
                                          {((first_name != null) && (last_name != null)) ?
                                            <p className=" fw-bolder pt-3 c-lg f-16" >{CommonServices.getNameCharByFullName(first_name + ' ' + last_name)}</p> :
                                            (first_name != null) ?
                                              <p className=" fw-bolder pt-3 c-lg f-16" >{CommonServices.getNameCharByFullName(first_name + ' ' + last_name)}</p> :
                                              <p className=" fw-bolder pt-3 c-lg f-16">U</p>
                                          }
                                        </div>
                                        :
                                        <Image src={profile_path} className=" img-d-35" />
                                      }
                                    </a>
                                  </Col>
                                  <Col className="ms--2">
                                    <h4 className=" mb-0 f-16 c-lg fw-normal" onClick={() => {
                                      history.push(`/admin/clients/edit-client`, { userId: item.user_uuid, caseId: item.case_id, caseName: item.case_name })
                                    }}>
                                      {first_name !== null && last_name !== null ?

                                        Capitalize(first_name) + " " + Capitalize(last_name) :
                                        first_name !== null ? Capitalize(first_name) : 'User'
                                      }
                                    </h4>

                                  </Col>

                                </Row>
                              )
                            })
                          }
                        </h6>
                        :
                        <h6 className="f-16 c-dg">Broker : {" "}
                          <span className="f-16 c-lg fw-normal">Not available</span>
                        </h6>
                      }
                    </div>
                    <div className="text-wrap ">
                      {((applicants !== null) && (applicants !== undefined) && (applicants.length > 0)) ?
                        <h6 className="f-14 c-dg">Applicants : {" "}
                          {
                            applicants?.map((item, index) => {
                              const { first_name, last_name, profile_path } = item;
                              return (

                                <Row className="align-items-center pe-none text-wrap img-block-collapse pt-2" key={index}>
                                  <Col className="col-auto imageLeftLess pa-r-0">
                                    <a className="user-avatar pe-none"
                                      onClick={() => {
                                        history.push(`/admin/clients/edit-client`, { userId: item.user_uuid, caseId: item.case_id, caseName: item.case_name })
                                      }}
                                    >
                                      {(profile_path == '' || profile_path == undefined || profile_path == null) ?
                                        <div className={` bg-light  d-flex justify-content-center align-items-center img-d-35`} >
                                          {((first_name != null) && (last_name != null)) ?
                                            <p className=" fw-bolder pt-3 c-lg f-16" >{CommonServices.getNameCharByFullName(first_name + ' ' + last_name)}</p> :
                                            (first_name != null) ?
                                              <p className=" fw-bolder pt-3 c-lg f-16" >{CommonServices.getNameCharByFullName(first_name + ' ' + last_name)}</p> :
                                              <p className=" fw-bolder pt-3 c-lg f-16">U</p>
                                          }
                                        </div>
                                        :
                                        <Image src={profile_path} className=" img-d-35" />
                                      }
                                    </a>
                                  </Col>
                                  <Col className="ms--2">
                                    <h4 className=" mb-0 f-16 c-lg fw-normal" onClick={() => {
                                      history.push(`/admin/clients/edit-client`, { userId: item.user_uuid, caseId: item.case_id, caseName: item.case_name })
                                    }}>
                                      {first_name !== null && last_name !== null ?

                                        Capitalize(first_name) + " " + Capitalize(last_name) :
                                        first_name !== null ? Capitalize(first_name) : 'User'
                                      }
                                    </h4>

                                  </Col>

                                </Row>

                              )
                            })

                          }
                        </h6> :

                        <h6 className="f-16 c-dg">Applicants :{" "}
                          <span className="f-16 c-lg fw-normal">Not available</span>
                        </h6>
                      }
                    </div>
                    <div className="text-wrap ">
                      <h6 className="f-16 c-dg">Status: {" "}
                        <span className="f-16 c-lg fw-normal">
                          {case_status === 0 ? "Pending" : case_status === 1 ? "Active" : case_status === 2 ? "Inactive" : ""}</span></h6>
                    </div>

                  </td>
                </tr>
              </tbody>
            )
          }
          )
        }

      </table>
    )
  }

  const GetBrokerList = async () => {
    if (((search == '') && (isSearchChange == false)) || (activeItem != previousActiveItemValue)) {
      setIsLoading(true);
    }
    const broker = await GetConsultantBrokerCaseListApi(startingIndex, pageLimit, searchText, 4);
    console.log("broker.....", broker);
    localStorage.setItem("role", 4);
    if (broker?.status_code === 1) {
      const brokerData = broker.data[0].results;
      setBrokerCaseList(brokerData);
      setTotalClientCount(broker.data[0]?.count);
      const pages = Math.ceil(broker.data[0]?.count / pageLimit);
      console.log(" total pages", pages);
      setTotalPages(pages);
    } else {
      toast.error(broker.message, { duration: 4000 });
    }
    setIsLoading(false);
  };




  //#endregion

  //#region JSX
  return (
    <Fragment>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap pt-0 pb-2">
        <div className="d-block mb-4 mb-md-0">
          {/* <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item className="form-range-track-cursor" onClick={() => { history.push(HomepageUrl) }}>
              <FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item active>Cases</Breadcrumb.Item>
          </Breadcrumb> */}

          <CommonBreadcrumb breadcrumbArray={breadcrumbArray} />
        </div>
        {/* Immigration cases redirect button */}
        <div className={`${singleScreen ? '' : 'mt-5'}`}>
          <Button className={`mb-md-4 mb-0 text-wrap ${singleScreen ? 'f-18' : ''}`} variant="primary" onClick={() => history.push("/cases/immigration-cases")}> Immigration Cases</Button>
        </div>
      </div>

      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">
          <Col sm={12} xs={12} md={6} lg={4} xl={7} >
            <div>
              <h4 className={`text-wrap ${singleScreen ? 'f-18' : ''}`}>Real Estate Cases</h4>
            </div>
          </Col>

          <Col sm={12} xs={12} md={6} lg={8} xl={5}>
            <InputGroup>
              <InputGroup.Text className={styles.caselistSearchBox}>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <Form.Control className={singleScreen && 'f-16'} type="text" placeholder="Search Case Name" value={searchText} onChange={(e) => setSearchText(e.target.value)} className={styles.caselistSearchBox} />
            </InputGroup>
          </Col>
        </Row>
      </div>
      {isLoading === true ? (
        <DataLoading />
      ) : brokerCaseList.length === 0 ? (
        <NoDataFound />
      ) :
        <>
          {singleScreen === false ?
            <Card border="light" className="shadow-sm mb-4 ">
              <Card.Body className="pb-0 custom-table-body">
                <Table
                  responsive
                  className="table-centered custom-table-responsive table-nowrap rounded mb-0"
                >
                  <thead className="thead-light border-bottom">
                    <tr>
                      <th className="border-0 c-lg f-14">
                        Case Name{" "}
                      </th>
                      <th className="border-0 c-lg f-14">
                        Applicants
                      </th>
                      <th className="border-0 c-lg f-14">
                        Broker
                      </th>
                      <th className="border-0 c-lg f-14">
                        Status
                      </th>
                      <th className="border-0 action-block-custom-width c-lg f-14">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {brokerCaseList.map((broker) => (
                      <TableRow
                        key={`broker-${broker.id}`}
                        {...broker}
                      />
                    ))}
                  </tbody>
                </Table>
                <div className="pagenation-custom-block">
                  <div className="pagination-msg">
                    {`Showing ${startingIndex} to ${Math.min(startingIndex + pageLimit - 1, totalClientCount)} of ${totalClientCount} entries`}
                  </div>
                  <CustomPagination
                    activeItem={activeItem}
                    setActiveItem={setActiveItem}
                    totalPages={totalPages}
                    setStartingIndex={setStartingIndex}
                    isSingleScreen={false}
                  />
                </div>
              </Card.Body>
            </Card> :

            <div className="bg-white">
              <MobileTableBrokerCaseList />
              <div className="pagenation-custom-block pagination-padding">
                <div className="pagination-msg f-16">
                  {`Showing ${startingIndex} to ${Math.min(startingIndex + pageLimit - 1, totalClientCount)} of ${totalClientCount} entries`}
                </div>
                <CustomPagination isSingleScreen={true} activeItem={activeItem} setActiveItem={setActiveItem} totalPages={totalPages} setStartingIndex={setStartingIndex} />
              </div>
            </div>
          }
        </>
      }
    </Fragment>
  );
  //#endregion
};

export default BrokerCaseList;
