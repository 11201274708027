import React, { Fragment, useEffect, useState, useRef } from "react";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useHistory } from 'react-router-dom';
import AirmigrateBlacklogo from '../../assets/img/landing-page/airmigrate-black-logo.png'
import AirmigrateShapelogo from '../../assets/img/landing-page/airmigrate-shape-logo.png'
import { authApi } from "../../utilities/helpers/ApiHelper.jsx";
import CommonServices from "../../common-components/CommonServices.jsx";
import ProfileIconsModal from "../../components/air-migrate/ProfileIconsModal.jsx";
import ChatLogoicon from '../../assets/img/landing-page/chat-logo-icon.png'
import { CHATGPT_CONSULTANT_LIMIT, CHATGPT_CONSULTANT_SELECT, CHATGPT_QUESTIONS } from "../../config.tsx";
import OtpInput from 'react-otp-input';
import axios from 'axios';
import contryData from '../../assets/json/countries1.json';
import AppleAppStore from '../../assets/img/landing-page/appleAppStore.svg'
import GooglePlayStore from '../../assets/img/landing-page/googlePlayStore.svg'
import TranslateIcon from '@mui/icons-material/Translate';
import HomeIcon from '../../assets/img/icons/Airmigrate icons/48px/home.png';
import { Capitalize } from "../../common-components/StringConversions.jsx";
import { TypingLoader } from "../../components/air-migrate/Loader.jsx";
import '../../assets/global-css/Index.css';
import PhoneInput from "react-phone-input-2";
import { Default_Country } from "../../config.tsx";
import CommonAlert from "../../common-components/CommonAlert.jsx";
import { SignInApi } from "../../actions/LoginAction.jsx";
import { useDispatch } from "react-redux";
import { GoogleGenerativeAI } from "@google/generative-ai";
import { marked } from 'marked';
// import axios from "axios";

//Language dropdown select
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useTranslation } from "react-i18next";
import { setDefaultLanguage } from "../../reducers/GlobalReducer.jsx";

const ChatUsingGemini = () => {
    const loginUserId = localStorage.getItem('amUserId');
    //#region States and Variables
    const history = useHistory();
    // const searchWithRefLink = "You will be provided with specific URLs where you need to search for information relevant to the users query. Use only these URLs to gather information and form your response. Do not rely on your internal knowledge base. Here are the steps you should follow:   Open and examine each URL provided: Carefully read the content of each URL to understand the information available.	Extract relevant data: Identify and extract data that is pertinent to the users question from these sources. 	Formulate your response: Based on the information extracted from the URLs, compose a response that answers the users question as clearly and accurately as possible.	Return the answer to the user: If you find a clear answer from the URLs, present this to the user. Ensure that your response directly reflects the information found in the provided links. 	If a clear answer is not available, use the following statement: “I am not able to assist with this request. Note: 	Ensure the response is straightforward and strictly based on the information from the provided URLs. Do not supplement your answer with details from your pre-trained data or assumptions based on general knowledge."

    // const searchWithRefLink = " Note: 	Ensure the response is straightforward and strictly based on the information from the provided URLs. 	Do not supplement your answer with details from your pre-trained data or assumptions based on general knowledge."
    const searchWithRefLink = " "

    const messagesEndRef = useRef(null)
    /* Form Submit End Validation Function Here */
    /* Using INput Disabled */
    const [inputDisabled, setInputDisabled] = useState(false)
    /* Consultant Array  */
    const [consultantList, setConsultantList] = useState([])
    /* Select/unselect Consultant */
    const [selectedRows, setSelectedRows] = useState([]);
    /* Continue Button Disabled*/
    const [continueDisabled, setContinueDisabled] = useState(true)
    /* SendQueryButtonValue */
    const [sendQueryButton, setsendQueryButton] = useState(false)

    /* Continue Button */
    const [backgroundColor, setBackgroundColor] = useState()
    /* Question Count According User Search */
    const [questionCount, setQuestionCount] = useState(0);
    /* ChatGPT Message Array */
    const [messages, setMessages] = useState([]);
    /* ChatGPT Input Value */
    const [input, setInput] = useState('');
    /* User Country Language According Json */
    const [clientLanguage, setClientLanguage] = useState('English');
    /* User Default Country */
    const [userCountry, setUserCountry] = useState(null);
    /* Default Language For Chat, Change According User Country */
    const [userLanguage, setUserLanguage] = useState('English');
    /* WEbsite Link According Client Provided */
    const [userCountryRefLink, setUserCountryRefLink] = useState();
    /* Otp Show Variable */
    const [showOtpBox, setShowOtpBox] = useState(false);
    /* After Submission of Docs Create Hold its Primary Id */
    const [identity, setIdentity] = useState([])
    /* OTP Variable Value Set according User */
    const [otpVariable, setOtpVariable] = useState()
    /* Otp Verify Button enable/disabled */
    const [otpbuttonSUbmit, setOtpbuttonSUbmit] = useState(true)
    /* After Verified Otp, Thanks message */
    const [thanks, setThanks] = useState(false)
    /* Store All Question in Variable */
    const [holdQuestions, setHoldQuestions] = useState([])


    //Manage language change
    const [mainLanguauge, setMainLanguage] = useState('');
    const [chatGptLoader, setChatGptLoader] = useState(true);
    const [isCountryCodeEntered, setIsCountryCodeEntered] = useState(false);
    const [isPhoneEntered, setIsPhoneEntered] = useState(false);
    const [alertsArray, setAlertsArray] = useState([]);
    const [count, setCount] = useState(0);
    const [otpObject, setOtpObject] = useState({ otpMessage: 'Please enter correct OTP', showOtpError: false });
    const [resendOtpRequestModal, setResendOtpRequestModal] = useState({});

    //States to manage mobile ui
    const [screenSize, setScreenSize] = useState(getCurrentDimension());
    const [singleScreen, setSingleScreen] = useState(false);

    const dispatch = useDispatch();
    // const formik = useFormik();


    /* Chat Form Varibales Define Here */

    /* Country TO, current user location */
    const [chatCountryTo, setChatCountryTo] = useState();
    /* User Name According Form */
    const [chatYourName, setChatYourName] = useState();
    /* Chat Validation and email and otp Form Bypass variable */
    const [verifyFormByPass, setVerifyFormByPass] = useState(true);
    const [userRoleId, setUserRole] = useState(localStorage.getItem('roleId'));
    const [userId, setUserId] = useState(localStorage.getItem('amUserId'))
    const [currentStatus, setCurrentStatus] = useState(localStorage.getItem('status'))


    // const handleChange = (event) => {
    //     setSelectedLanguage(event.target.value);
    // };

    //i18n
    const { t, i18n: { changeLanguage, language } } = useTranslation();
    // console.log('Translati', t);
    const [selectedLanguage, setSelectedLanguage] = useState('');
    // const [currentLanguage, setCurrentLanguage] = useState(language)
    const handleChangeLanguage = (value) => {
        //   const newLanguage = value ;
        setSelectedLanguage(value);
        changeLanguage(value);
        searchHandleLanguageChange(value);
    }


    const inputStyle = {
        border: "1px solid #00000028",
        borderRadius: "8px",
        backgroundColor: "white",
        margin: "0px 20px",
        color: "black",
        width: "40px",
        height: "40px",
    }

    //Generative Ai
    const genAI = new GoogleGenerativeAI('AIzaSyCRvPg2m5R2zBhRf6mzwJ1yGwvpgPUn07k');
    const model = genAI.getGenerativeModel({ model: "gemini-pro" });
    const [aiResponse, setResponse] = useState('');





    //#endregion

    //#region useEffect

    /* Default Languagae Selection Via api, and Country Also */
    useEffect(() => {

        var userDetails = localStorage.getItem("accessToken");
        if ((userDetails) && (currentStatus > 0)) {
            if (userRoleId == 1) {
                history.push('/admin/agents');
            } else {
                history.push('/chat', { userId: userId });
            }
        }

        if (questionCount == CHATGPT_QUESTIONS) {
            fetchData();
            scrollToBottom();
        }

        //functionality to set collapsible table 
        window.addEventListener('resize', updateDimension);
        updateDimension();

        // removing eventlistener for no furhter effect after 
        return (() => {
            window.removeEventListener('resize', updateDimension);
        })

    }, [questionCount]);

    /* Consultant API call, After Question Count is 5 */
    useEffect(() => {
        fetchGeolocationData();
        scrollToBottom();



        //functionality to set collapsible table 
        window.addEventListener('resize', updateDimension);
        updateDimension();

        // removing eventlistener for no furhter effect after 
        return (() => {
            window.removeEventListener('resize', updateDimension);
        })
    }, []);


    //#endregion

    //#region Functions

    // Generative AI Call to fetch dishes
    async function aiRun(input) {
        const prompt = `${input} `;
        const result = await model.generateContent(prompt);
        const response = result.response;
        const text = response.text();
        console.log("response of gemini", text);
        setResponse(text);
        return text;
    }

    const MarkdownRenderer = (markdown) => {
        // Convert markdown to HTML
        const htmlContent = marked(markdown);

        return (
            <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
        );
    };

    const summarizeText = async (text) => {
        const apiKey = 'AIzaSyCRvPg2m5R2zBhRf6mzwJ1yGwvpgPUn07k';
        const url = 'https://api.gemini.com/summarize'; // Replace with the actual API endpoint

        try {
            const response = await axios.post(url, {
                text,
                max_length: 100  // or any other parameter you need
            }, {
                headers: {
                    'Authorization': `Bearer ${apiKey}`,
                    'Content-Type': 'application/json'
                }
            });

            return response.data.summary;  // Adjust based on the API's response structure
        } catch (error) {
            console.error('Error summarizing text:', error);
            return text;  // Fallback to original text if there's an error
        }
    };

    const summarizeNewText = async (text) => {
        const apiKey = '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'; // Replace with your OpenAI API key
        const url = 'https://api.openai.com/v1/engines/davinci-codex/completions'; // Adjust to the correct endpoint

        const data = {
            prompt: `Summarize the following text:\n\n${text}\n\nSummary:`,
            max_tokens: 150,  // Adjust as necessary
            n: 1,
            stop: ["\n"],
            temperature: 0.5
        };

        try {
            const response = await axios.post(url, data, {
                headers: {
                    'Authorization': `Bearer ${apiKey}`,
                    'Content-Type': 'application/json'
                }
            });

            return response.data.choices[0].text.trim();  // Adjust based on the API's response structure
        } catch (error) {
            console.error('Error summarizing text:', error);
            return text;  // Fallback to original text if there's an error
        }
    };

    // button event trigger to consume gemini Api

    const handleClick = async () => {
        let lastArray = holdQuestions[holdQuestions.length - 1]
        console.log(" lastArray, messages ", holdQuestions, lastArray, messages);
        let inputSearch = lastArray['content']
        let index = lastArray['index'];
        // var maxLength = 200;
        console.log("holdQustions", holdQuestions);
        var inputValue = [];

        //Get answers based on previous questions  
        for (let i = holdQuestions.length - 1; i >= 0; i--) {
            inputValue.push(holdQuestions[i]?.content);
        }
        console.log("inputValue", inputValue);

        var inputValueWithLanguageSearch = inputValue;
        let CreateLocalSearch = (userLanguage == 'English') ? inputSearch : `${inputSearch}. search in ${userLanguage} language.`
        inputValueWithLanguageSearch[0] = CreateLocalSearch;

        console.log("createLocalSearch", CreateLocalSearch);

        console.log("inputValueWithLanguageSearch", inputValueWithLanguageSearch);
        try {
            const NormalResponse = await aiRun(inputValueWithLanguageSearch);
            console.log("Normal text", NormalResponse);
            const summarizedText = await summarizeText(NormalResponse);
            console.log("Summarize text", summarizedText);
            // const response = `${NormalResponse.replaceAll("**",'\n')}`;
            const response = MarkdownRenderer(NormalResponse);

            if (index == null) {
                /* Add AI response to the chat interface */
                console.log("Hello I am in IFFFFF");
                const GetDate1 = CommonServices.getDateTimeByTimestampForChat(CommonServices.getCurrentTimestamp());
                setMessages(messages => [...messages, {
                    text: response,
                    // text: "Chatgpt Answer :  " + input,
                    sender: 'ai',
                    'like': null,
                    'search': inputSearch,
                    date: GetDate1
                }
                ]
                );



            }
            else {
                console.log("Hello I am in ELSEEEEE");

                setMessages(
                    messages.map((item, i) => {
                        if (i === index) {
                            console.log("Hello I am in ELSEEEE + IFFFFF");

                            const GetDate3 = CommonServices.getDateTimeByTimestampForChat(CommonServices.getCurrentTimestamp())
                            return {
                                ...item,
                                text: "New : " + response,
                                // text: "New Message Here :  " + input,
                                'like': false,
                                date: GetDate3
                            };
                        } else {
                            console.log("Hello I am in ELSEEEE + ELSEEEE");

                            return item;
                        }
                    })
                );
            }

            /* Increase User Question Count */
            if (questionCount <= CHATGPT_QUESTIONS) {
                setQuestionCount(questionCount + 1);
            }

            scrollToBottom()
            setChatGptLoader(false);

            return true;
        }
        catch (error) {
            console.error('Error fetching response from API:', error);
            const GetDate1 = CommonServices.getDateTimeByTimestampForChat(CommonServices.getCurrentTimestamp())
            setMessages(messages => [...messages, {
                text: 'Sorry, unable to process your request, please try again!',
                // text: "Chatgpt Answer :  " + input,
                sender: 'ai',
                'like': null,
                'search': inputSearch,
                date: GetDate1
            }
            ]
            );
        }
        setChatGptLoader(false);
    }

    const updateDimension = () => {
        setScreenSize(getCurrentDimension());
        var value = getCurrentDimension();
        // set a variable true when screen width reaches <768px
        if (value.width <= 767) {
            setSingleScreen(true)
        } else {
            setSingleScreen(false)
        }
    }

    function getCurrentDimension() {
        return {
            width: window.innerWidth,
            height: window.innerHeight
        }
    }

    // Function to fetch geolocation data based on IP address
    const fetchGeolocationData = async () => {
        try {

            // if 

            // Get the user's IP address using ipify.org https://ipapi.co/json/
            const getUserIpDetails = await axios.get('https://ipapi.co/json/');


            if (getUserIpDetails.status == 200 && contryData[0][getUserIpDetails.data.country_name]) {
                /* According Api Respopnse Set User Country and Language With the Help of Json */
                setUserCountry(getUserIpDetails.data.country_name);
                setUserLanguage(contryData[0][getUserIpDetails.data.country_name]['language']);
                // setClientLanguage(contryData[0][getUserIpDetails.data.country_name]['language']);

                //Sey default i18n language to countrie's local language
                // dispatch(setDefaultLanguage(contryData[0][getUserIpDetails.data.country_name]['language']));
                setMainLanguage(contryData[0][getUserIpDetails.data.country_name]['language']);
                if (selectedLanguage == '') {
                    handleChangeLanguage(contryData[0][getUserIpDetails.data.country_name]['language']);
                }
                /* Set Another Varibale, If User Change To English */
                // if (contryData[0][getUserIpDetails.data.country_name]['language'] === 'Hindi') {
                //     setClientLanguage('hi')
                // }
                // else {
                //     setClientLanguage(contryData[0][getUserIpDetails.data.country_name]['language'])
                // }
                /* Check User Country Have Refrences Link According Json File, If Found Set in Search */
                //   if (contryData[0][getUserIpDetails.data.country_name]['ref']){
                //       setUserCountryRefLink(contryData[0][getUserIpDetails.data.country_name]['ref'])
                //   }
            }

        } catch (error) {
            console.error('Error fetching geolocation data:', error);
        }
    };


    /* Get Consultant list From DB */
    async function fetchData() {
        // console.log("All Variable Faileed ",chatCountryTo , userCountry , chatYourName)
        if (chatCountryTo && userCountry && chatYourName && inputDisabled) {
            /* Put Data in Chat */
            const GetDate2 = CommonServices.getDateTimeByTimestampForChat(CommonServices.getCurrentTimestamp())
            setMessages(messages => [...messages, {
                text: t('consultant_suggestion_list'),
                sender: 'ai',
                'like': null,
                'search': null,
                date: GetDate2
            }
            ]
            );


            /* Top Consultant Api */
            let consultantApiResponse = await authApi.newGetAPI('getTopConsutant?limit=' + CHATGPT_CONSULTANT_LIMIT)

            if (consultantApiResponse.status_code == 1 && consultantApiResponse.data && consultantApiResponse.data[0]) {
                setConsultantList(consultantApiResponse.data[0]['results'])

                /* Input Button Disabled */
                // setInputDisabled(false) 
            } else {
                setAlertsArray([...alertsArray, { id: `get-consultant${alertsArray.length}`, type: 'danger', message: consultantApiResponse.message }]);
            }
            scrollToBottom();
        }

        return true
    }

    // Scroll Chat Screen, When receive new messages
    const scrollToBottom = () => {
        setTimeout(() => {

            messagesEndRef.current?.scrollIntoView(false);
            // messagesEndRef.current?.scrollIntoView({ block: 'end',  behavior: 'smooth' });

        }, 0);

    }

    //Function to handle alerts
    const handleAlert = (id) => {
        let array = [];
        for (let i = 0; i < alertsArray.length; i++) {
            if ((alertsArray[i].id) == id) {
                array = alertsArray.pop();
            }
        }
        setCount(Math.floor(Math.random() * 1000))
    }

    const handleContryAndPhoneChange = (value, country, setFieldValue) => {

        // console.log('value and country', value, country, "setFieldValue");
        // console.log('number value', value, country);
        if (country.dialCode == '') {
            setIsCountryCodeEntered(false);
        }
        else {
            setFieldValue("country_code", country.dialCode);
            setIsCountryCodeEntered(true);
        }
        var phone = value.slice(country.dialCode.length);
        if (phone == '') {
            setFieldValue("phone", phone);
            setIsPhoneEntered(false);
        }
        else {
            // console.log('phone', phone)
            setFieldValue("phone", phone);
            setIsPhoneEntered(true);
        }
    }

    /* Full Form Validation */
    const validate = values => {
        // console.log('validate enters', values);
        const errors = {};
        /* User Name Validation */
        if (!values.user_name) {
            errors.user_name = t('validate_name');
        }

        /* USer Name Range Validation  */
        if (values.user_name && (values.user_name.length < 1 || values.user_name.length > 40)) {
            errors.user_name = t('validate_name_no');
        }

        /* User Current Country */
        if (!values.country_to) {
            errors.country_to = t('validate_country_to');
        }

        /* String Upper For Check */
        const toTitleCase = str => str.replace(/(^\w|\s\w)(\S*)/g, (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase())

        /* User Want Go, Country Name */
        if (!values.country_from) {
            errors.country_from = t('validate_country_from');
        } else {
            const newCountryFrom = toTitleCase(values.country_from);
            if (newCountryFrom && contryData[0] && contryData[0][newCountryFrom] && contryData[0][newCountryFrom]['ref']) {
                setUserCountryRefLink(contryData[0][newCountryFrom]['ref'])

            }
        }

        /* Continue Button */
        if ((values.user_name.length > 1) && values.country_to && values.country_from) {
            /* User Form Variable Set in State */
            setChatCountryTo(values.country_to)
            /* Set New Country For Pre Defined Questions */
            setUserCountry(toTitleCase(values.country_from))
            /* Set USer Name According Form  */
            setChatYourName(values.user_name)


            setBackgroundColor('green')
            setContinueDisabled(false)
        } else {
            // setBackgroundColor('red')
            setContinueDisabled(true)
        }
        // console.log("values email into!", values.email, values.phone);
        /* Email Or Phone No Validation, If User Session is active Do not check Email and phone get it from token  */
        if (!loginUserId) {
            if (!values.email && !values.phone) {
                // console.log("values email into!", values.email, values.phone);
                // errors.emailOrPhone = 'Please enter either an email address or a phone number';
                errors.email = t('validate_email');
                errors.phone = t('validate_phone');
            } else if (values.email && !/^\S+@\S+\.\S+$/.test(values.email)) {
                errors.email = t('validate_invalid_email');
            } else if (values.phone && !/^\d{10}$/.test(values.phone)) {
                errors.phone = t('validate_invalid_phone');
            } else if ((values.country_code || values.phone) && !/^\d{1,3}$/.test(values.country_code)) {
                errors.country_code = t('validate_invalid_country');
            }

        }

        return errors;
    };

    /* Full Form Submit */
    const handleSubmit = async (values, { setSubmitting }) => {
        // console.log('handle submit call!', values);

        //Remove unrelevant data from messages
        const newMessageArray = [];
        for (let i = 0; i < messages.length; i++) {
            if (messages[i].text != '') {
                newMessageArray.push(messages[i]);
            }
        }

        //Check that user registered with phone or country
        if (values.email !== '') {
            setResendOtpRequestModal({ ...resendOtpRequestModal, email: values.email });
        }
        else {
            setResendOtpRequestModal({ ...resendOtpRequestModal, phone: values.phone, primary_country_code: values.country_code });
        }

        values['consultant_uuid'] = selectedRows
        values['json_data'] = newMessageArray;
        // console.log(" New Form Submit ", values);



        let consultantApiResponse = await authApi.newPostAPI('create-chat-inquery', values)
        if (consultantApiResponse.status_code == 1 && consultantApiResponse.data && consultantApiResponse.data[0]) {
            setIdentity(consultantApiResponse.data[0]['identity'])
            setShowOtpBox(true)

            /* Login User By passs Otp and Email and phone */
            if (loginUserId) {
                setThanks(true)
                setShowOtpBox(true)
                setsendQueryButton(true)
                setVerifyFormByPass(false)
            }
            setAlertsArray([...alertsArray, { id: `create-chat${alertsArray.length}`, type: 'success', message: consultantApiResponse.message }]);

        }
        // else if(consultantApiResponse.status_code == 1){
        //     setAlertsArray([...alertsArray, { id: `verify-otp${alertsArray.length}`, type: 'success', message: verifyUserResponse.message }]);

        // }
        else {
            setIdentity([])
            setShowOtpBox(false)
            setAlertsArray([...alertsArray, { id: `create-chat${alertsArray.length}`, type: 'danger', message: consultantApiResponse.message }]);
        }

        // setSubmitting(false);
    };


    /* After User SUmbit Input search in Chatgpt */
    const chatGptHandleSubmit = async (e, inputParam) => {

        //Condition added get search input from state or from argument
        if (typeof (inputParam) !== 'string') {

            e.preventDefault();
            if (!input.trim()) { return; }
        }
        else {
            if (!inputParam.trim()) { return; }
        }


        /* If User Ask More Than Five Question We Showing Consultant List According Country Selection */
        if (questionCount > CHATGPT_QUESTIONS) {
            // alert("new Consultant List")
            return
        }


        try {
            const GetDate = CommonServices.getDateTimeByTimestampForChat(CommonServices.getCurrentTimestamp())
            //Condition added get search input from state or from argument
            setChatGptLoader(true);
            if (typeof (inputParam) !== 'string') {
                console.log("i am in IF");
                setMessages(messages => [...messages, { text: input, sender: 'user', 'like': null, 'search': input, date: GetDate },

                /*object added to show loader in chat*/
                { text: '', sender: 'ai', 'like': null, 'search': input, date: '' }
                ]);

                /* Send Input To chatGPT Api For Process */
                chatGptApi(input, null, 0, userCountryRefLink, messages)
                // handleClick(input)


            }
            else {
                console.log("i am in ELSE", inputParam);
                setMessages(messages => [...messages, { text: inputParam, sender: 'user', 'like': null, 'search': inputParam, date: GetDate },

                /*object added to show loader in chat*/
                { text: '', sender: 'ai', 'like': null, 'search': input, date: '' }
                ]);
                /* Send Input To chatGPT Api For Process */
                chatGptApi(inputParam, null, 0, userCountryRefLink, messages)
                // handleClick(input)



            }


            setInput('');
            scrollToBottom()

        } catch (error) {
            console.error('Error fetching response from API:', error);
        }
    };


    /* Create Like and Dislike Choice According User */
    const favoriteSearch = (index, choiceValue, searchText) => {

        /* If User Dislike any question than Consultant List appears */
        if (choiceValue == false) {
            if (questionCount < CHATGPT_QUESTIONS) {
                setQuestionCount(CHATGPT_QUESTIONS)
            }
        }

        /* Set List Of Messages Like Or Dislike */
        setMessages(
            messages.map((item, i) => {
                if (i === index) {

                    if (item.like != false) {
                        /* If User Dislike Search New Data Without Refrences Link (Client Want to Stop Dis Functionality) */
                        // if (choiceValue == false) {
                        //     chatGptApi(searchText, index, searchText, '')
                        // }
                        return { ...item, 'like': choiceValue };
                    }
                    return item
                } else {
                    return item;
                }
            })
        );
    }

    /* ChatGPT API to fetch Data according User provided Input */
    const chatGptApi = async (inputSearch, index = null, force = 0, userCountryRefLinkVariable = "",) => {

        console.log("InputSearch", inputSearch);
        // let CreateLocalSearch = (userLanguage == 'English') ? inputSearch : `${inputSearch}. search in ${userLanguage} language.`
        // let WithLink = CreateLocalSearch;

        // /* Check Ref link */
        // if (userCountryRefLinkVariable) {
        //     WithLink = WithLink + ` Using Given Reference Link ${userCountryRefLinkVariable}. ${searchWithRefLink}`;
        // }


        /* Set New Variabele to Store history questions */
        console.log("Messages", messages);
        setHoldQuestions(holdQuestions => [...holdQuestions, {
            "role": "user",
            "content": inputSearch,
            "index": index
        }])

    }

    useEffect(() => {
        // action on update of movies\
        if (holdQuestions.length) {
            // newChatGpt();
            handleClick();

        }

    }, [holdQuestions]);

    const newChatGpt = async () => {
        let lastArray = holdQuestions[holdQuestions.length - 1]
        // console.log(" lastArray " , holdQuestions,lastArray)
        let inputSearch = lastArray['content']
        let index = lastArray['index']

        try {

            // Make request to ChatGPT API
            const response = await axios.post(
                'https://api.openai.com/v1/chat/completions',
                {
                    // model: `text-davinci-002-${language}`, // Add language suffix
                    model: `gpt-3.5-turbo`, // Add language suffix
                    // prompt: input,
                    // messages: [
                    //     {
                    //         "role": "user",
                    //         "content": WithLink
                    //     }
                    // ],
                    messages: holdQuestions,
                    temperature: 0.7,
                    max_tokens: 200
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer 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' // Replace with your API key
                    }
                }
            );
            if (index == null) {
                /* Add AI response to the chat interface */
                const GetDate1 = CommonServices.getDateTimeByTimestampForChat(CommonServices.getCurrentTimestamp())
                setMessages(messages => [...messages, {
                    text: response.data.choices[0]['message']['content'].trim(),
                    // text: "Chatgpt Answer :  " + input,
                    sender: 'ai',
                    'like': null,
                    'search': inputSearch,
                    date: GetDate1
                }
                ]
                );


                /* Question Cross Its Limit */
                // if ((CHATGPT_QUESTIONS - 1) == questionCount) {
                //     const GetDate2 = CommonServices.getDateTimeByTimestampForChat(CommonServices.getCurrentTimestamp())
                //     setMessages(messages => [...messages, {
                //                 text: t('consultant_suggestion_list'),
                //                 sender: 'ai',
                //                 'like': null,
                //                 'search': null,
                //                 date: GetDate2
                //             }
                //         ]
                //     );
                // }
            } else {
                setMessages(
                    messages.map((item, i) => {
                        if (i === index) {
                            const GetDate3 = CommonServices.getDateTimeByTimestampForChat(CommonServices.getCurrentTimestamp())
                            return {
                                ...item,
                                text: "New : " + response.data.choices[0]['message']['content'].trim(),
                                // text: "New Message Here :  " + input,
                                'like': false,
                                date: GetDate3
                            };
                        } else {
                            return item;
                        }
                    })
                );
            }
            /* Increase User Question Count */
            if (questionCount <= CHATGPT_QUESTIONS) {
                setQuestionCount(questionCount + 1);
            }

            scrollToBottom()
            return true
        }
        catch (error) {
            console.error('Error fetching response from API:', error);
            const GetDate1 = CommonServices.getDateTimeByTimestampForChat(CommonServices.getCurrentTimestamp())
            setMessages(messages => [...messages, {
                text: 'Sorry, unable to process your request, please try again!',
                // text: "Chatgpt Answer :  " + input,
                sender: 'ai',
                'like': null,
                'search': inputSearch,
                date: GetDate1
            }
            ]
            );
        }
        setChatGptLoader(false);
    }

    /*ChatGPT Input Change */
    const searchInputHandleChange = (e) => {
        setInput(e.target.value);
    };

    /* When User Want By-pass Question answer and switch to Consultant Lists */
    const setDefaultConsultant = (e) => {
        if (chatCountryTo && userCountry && chatYourName && inputDisabled) {
            setQuestionCount(CHATGPT_QUESTIONS);
        }
    };


    /* User Change Language For ChatGpt API */
    const searchHandleLanguageChange = (e) => {
        // setUserLanguage(e.target.value);
        setUserLanguage(e);
    };


    /* Select Or Unselect Consultant from List */
    const consultantSelection = (rowId) => {
        const index = selectedRows.indexOf(rowId);
        if (index === -1) {
            if (selectedRows.length != CHATGPT_CONSULTANT_SELECT) {
                setSelectedRows([...selectedRows, rowId]);
            }

        } else {
            const updatedRows = [...selectedRows];
            updatedRows.splice(index, 1);
            setSelectedRows(updatedRows);
        }

        // console.log("Selected consultant ", selectedRows)
    };


    /* Continue Button Accepted */
    const continueAccepted = () => {
        // console.log(" continueAccepted ")
        /* input Disabled Button */
        setInputDisabled(true)
        scrollToBottom();
    }

    /* Send Query Button Click  */
    const sendQueryClick = () => {
        if (selectedRows.length) {
            /* Email or Phone Button Enable */
            setsendQueryButton(true)
            scrollToBottom();

            if (loginUserId) {
                setVerifyFormByPass(false)
            }
        }

    }


    /* Otp Fill Event */
    const otpFillEvent = (key, value) => {
        // setOtpVariable('');
        setOtpObject({ ...otpObject, showOtpError: false })
        // console.log(" OTP FILL ", key, value)
        setOtpVariable(value)
        // setOtpVariable('');

        if (value.length == 4) {
            setOtpbuttonSUbmit(false)
        } else {
            setOtpbuttonSUbmit(true)
        }

    }

    const resendOtp = async () => {
        // const response = await SignInApi(resendOtpRequestModal);
        setOtpObject({ ...otpObject, showOtpError: false })
        let response = await authApi.newPostAPI('resend-chatgpt-otp', { 'identity': identity })
    }

    const verifyOtpSubmit = async () => {
        setOtpObject({ ...otpObject, showOtpError: false })

        // console.log("Verify Submit ", otpVariable, identity)
        const values = { 'otp': otpVariable, 'identity': identity }

        if (otpVariable.length == 4) {
            let verifyUserResponse = await authApi.newPostAPI('verify-chat-inquery-otp', values)
            if (verifyUserResponse.status_code == 1) {
                setAlertsArray([...alertsArray, { id: `verify-otp${alertsArray.length}`, type: 'success', message: verifyUserResponse.message }]);
                setThanks(true)
                setOtpObject({ otpMessage: 'Please enter correct OTP', showOtpError: false })

            }
            else {
                setAlertsArray([...alertsArray, { id: `verify-otp${alertsArray.length}`, type: 'danger', message: verifyUserResponse.message }]);
                setOtpObject({ ...otpObject, showOtpError: true })
            }
        }
        else { setOtpbuttonSUbmit(false) }

    }
    /* Form Function Ends */
    //#endregion

    //#region JSX
    return (
        <div className="1">
            <section className="landing-page-section">
                <div className="landing-page-inner-section">
                    <div className="row">

                        <div className="col-lg-6 p-0">

                            <div className={`landing-page-form-section ${((questionCount >= 5) || (inputDisabled === false)) ? 'landing-page-full-height' : 'landing-page-form-section-height'}`}>


                                {/* <div className="language-select-box">
                                    <select value={userLanguage} onChange={searchHandleLanguageChange}>
                                        {clientLanguage != 'English' ? (<option value={clientLanguage}> {clientLanguage} </option>) : ("")}

                                        <option value="English"> English </option>
                                    </select>
                                </div> */}

                                {/* Show Alerts */}
                                {/* {(alertsArray.length > 0) &&
                                    alertsArray.reverse().map((item, index) => {
                                        return (
                                            <CommonAlert key={index} id={item.id} type={item.type} message={item.message} handleAlert={handleAlert} />
                                        )
                                    })
                                } */}

                                <div className="landing-header-block">
                                    <div className="logo-block cu-po">
                                        <img src={AirmigrateBlacklogo} alt="" onClick={() => history.push('/')} />
                                    </div>
                                    {/* <select value={userLanguage} onChange={searchHandleLanguageChange}>
                                        {clientLanguage != 'English' ? (
                                            <option value={clientLanguage}> {clientLanguage} </option>)
                                            : ("")}

                                        <option value="English"> English </option>
                                        Add more languages as needed
                                    </select> */}

                                    <div className="d-flex justify-content-between ">
                                        {/* <div className="cu-po mt-1">
                                           
                                            <span onClick={() => {
                                                if (clientLanguage == "English") {
                                                    setClientLanguage(mainLanguauge);
                                                    searchHandleLanguageChange('English');
                                                }
                                                else {
                                                    setClientLanguage('English');
                                                    searchHandleLanguageChange(mainLanguauge);
                                                }
                                            }}>{" "} {clientLanguage}</span>
                                        </div> */}

                                        <FormControl sx={{ m: 0, minWidth: singleScreen ? 120 : 160 }} size="small">
                                            <InputLabel id="demo-select-large-label">{singleScreen ? 'Language' : 'Language Preference'}</InputLabel>
                                            <Select
                                                labelId="demo-select-large-label"
                                                id="demo-select-small"
                                                value={selectedLanguage}
                                                label={singleScreen ? 'Language' : 'Language Preference'}
                                                onChange={(e) => { handleChangeLanguage(e.target.value) }}
                                            >
                                                <MenuItem value={mainLanguauge}> {mainLanguauge} </MenuItem>
                                                {mainLanguauge != 'English' && <MenuItem value='English'>English</MenuItem>}
                                                {mainLanguauge != 'Hindi' && <MenuItem value='Hindi'>Hindi</MenuItem>}
                                                {mainLanguauge != 'French' && <MenuItem value='French'>French</MenuItem>}
                                                {mainLanguauge != 'German' && <MenuItem value='German'>German</MenuItem>}
                                            </Select>
                                        </FormControl>
                                        <div className="landing-header-icon-block ">
                                            {/* <i className="fa-solid fa-house"></i> */}
                                            <img src={HomeIcon} alt="home-icon" className="landing-page-home-icon cu-po" onClick={() => history.push('/')} />
                                        </div>
                                    </div>
                                    {/* <div className="d-flex justify-content-between">
                                        <div>
                                            <TranslateIcon /><span onClick={() => {
                                                if (clientLanguage == "en") {
                                                    setClientLanguage('hi');
                                                }
                                                else {
                                                    setClientLanguage('en');
                                                }
                                            }}>{" "} {clientLanguage}</span>
                                        </div>
                                        <div className="landing-header-icon-block">
                                            <i className="fa-solid fa-house"></i>
                                        </div>

                                    </div> */}

                                </div>

                                <div className="landing-login-form-block">
                                    <div className="landing-form-heading landing-form-heading-info">
                                        <img src={AirmigrateShapelogo} alt="" />
                                        <p>{t('main_heading')}</p>
                                    </div>
                                </div>

                                <div id="main-block" ref={messagesEndRef}>
                                    <Formik initialValues={{ user_name: '', country_to: '', country_from: 'Canada', email: '', phone: "", country_code: '' }}
                                        validate={validate} onSubmit={handleSubmit}  >
                                        {({ isSubmitting, setFieldValue, setFieldError }) => (
                                            <Form >
                                                <span className="chat-bottom-span">
                                                    {/* Form Here  */}



                                                    {/* Where You From  */}
                                                    <div className="login-input-block login-custom-input">
                                                        {/* <label>What’s Your Name?</label>  <input type="text" placeholder="Enter Name" /> */}

                                                        <label>{t('name_heading')}</label>
                                                        <Field type="text" name="user_name" disabled={inputDisabled} placeholder={t('name_placeholder')} />
                                                        <ErrorMessage name="user_name" component="div" className="error" />

                                                    </div>


                                                    {/* Current Country */}
                                                    <div className="login-input-block login-custom-input">
                                                        <label>{t('current_country')}</label>
                                                        {/* <input type="text" placeholder="Current Country" /> */}
                                                        <Field type="text" name="country_to" disabled={inputDisabled} placeholder={t('india')} />
                                                        <ErrorMessage name="country_to" component="div" className="error" />
                                                    </div>


                                                    {/* Want To Go Country */} {/* Client Want to Remove  */}
                                                    <div className="login-input-block login-custom-input display-none">
                                                        <label>{t('country_to')}</label>
                                                        {/*  <input type="text" placeholder="Enter Name" /> */}
                                                        <Field type="text" name="country_from" disabled={inputDisabled} placeholder={t('canada')} />
                                                        <ErrorMessage name="country_from" component="div" className="error" />
                                                    </div>


                                                    {/* Continue Button */}
                                                    <div className="continue-condiditon-button continue-button-block">
                                                        {/* <button>Continue</button> */}
                                                        <button type="button"
                                                            // style={{ "backgroundColor": backgroundColor }}
                                                            disabled={continueDisabled}
                                                            onClick={() => continueAccepted()}
                                                        >{t('continue')}</button>
                                                    </div>
                                                    <div className="continue-condiditon-button therms-use-block">
                                                        <p>{t('terms_suggestion')}</p>
                                                        <p onClick={() => history.push('/terms-condition')} className="therms-color-text cursor-pointer">{t('terms_label')}</p>
                                                    </div>



                                                    {/* Four option Appers in After User Select Continue Button  */}
                                                    {
                                                        (inputDisabled && (messages.length == 0) && (consultantList.length == 0))
                                                            ?
                                                            <>
                                                                <div className="document-details-block">
                                                                    <div className="row">
                                                                        <div className="col-md-6">
                                                                            <a>
                                                                                <div className="details-suggestion-block" onClick={() => {
                                                                                    // setInput('How to get PR in Canada ?');
                                                                                    chatGptHandleSubmit(undefined, t('get_pr') + ' in ' + userCountry);
                                                                                }}>
                                                                                    <span className="details-heading-text">{t('get_pr') + ' in ' + userCountry}</span>
                                                                                    <span className="details-subheading-text">{t('details_and_document')}</span>
                                                                                </div>
                                                                            </a>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <a>
                                                                                <div className="details-suggestion-block" onClick={() => {
                                                                                    // setInput('Student Visa for Canada');
                                                                                    chatGptHandleSubmit(undefined, t('student_visa') + ' in ' + userCountry);
                                                                                }}>
                                                                                    <span className="details-heading-text">{t('student_visa') + ' in ' + userCountry}</span>
                                                                                    <span className="details-subheading-text">{t('visa_details')}</span>
                                                                                </div>
                                                                            </a>
                                                                        </div>


                                                                        <div className="col-md-6 mt-2">
                                                                            <a>
                                                                                <div className="details-suggestion-block" onClick={() => {
                                                                                    // setInput('How to get PR in Canada ?');
                                                                                    chatGptHandleSubmit(undefined, t('get_wp') + ' in ' + userCountry);
                                                                                }}>
                                                                                    <span className="details-heading-text">{t('get_wp') + ' in ' + userCountry}</span>
                                                                                    <span className="details-subheading-text">{t('details_and_document')}</span>
                                                                                </div>
                                                                            </a>
                                                                        </div>
                                                                        <div className="col-md-6 mt-2">
                                                                            <a>
                                                                                <div className="details-suggestion-block" onClick={() => {
                                                                                    // setInput('Student Visa for Canada');
                                                                                    chatGptHandleSubmit(undefined, t('get_re') + ' in ' + userCountry);
                                                                                }}>
                                                                                    <span className="details-heading-text">{t('get_re') + ' in ' + userCountry}</span>
                                                                                    <span className="details-subheading-text">{t('visa_details')}</span>
                                                                                </div>
                                                                            </a>
                                                                        </div>


                                                                    </div>
                                                                </div>
                                                            </>
                                                            : ""
                                                    }




                                                    {/* Chat Function Appers Here */}
                                                    {
                                                        inputDisabled ?
                                                            <>
                                                                <div className="chatGPT-div" >
                                                                    {messages.map((message, index) => {

                                                                        return (
                                                                            <div key={index}>
                                                                                {message.sender === 'user' ? (
                                                                                    <div className="chat-send-message-block">
                                                                                        <p className="send-user-name" style={{ textAlign: 'right' }}>You</p>
                                                                                        <div className="send-message-content">
                                                                                            <div className="send-message-content-block">
                                                                                                <p>{message.search}</p>
                                                                                            </div>
                                                                                            <div className="send-message-time-info">{message.date}</div>
                                                                                        </div>


                                                                                    </div>

                                                                                ) : (



                                                                                    <>
                                                                                        {message.text != '' ?
                                                                                            <div className="chat-received-message-block">
                                                                                                <p className="send-user-name" style={{ textAlign: 'left' }}>
                                                                                                    AI
                                                                                                </p>


                                                                                                <div className="received-message-content">
                                                                                                    <div className="received-message-content-block">
                                                                                                        <p>
                                                                                                            {message.text}
                                                                                                        </p>
                                                                                                    </div>
                                                                                                    <div className="received-message-time-info">
                                                                                                        <div className="chat-like-info">
                                                                                                            <button type="button"
                                                                                                                className={message.like == true ? 'bg-like-color' : ''}
                                                                                                                style={{ textAlign: 'left' }}
                                                                                                                onClick={() => { favoriteSearch(index, true, message.search) }}>
                                                                                                                <span className="like-icon"><i className="fa-solid fa-thumbs-up"></i></span>
                                                                                                            </button>
                                                                                                            <button type="button"
                                                                                                                className={message.like == false ? 'bg-dislike-color' : ''}
                                                                                                                style={{ textAlign: 'left' }}
                                                                                                                onClick={() => { favoriteSearch(index, false, message.search) }}>
                                                                                                                <span className="dislike-icon"><i className="fa-solid fa-thumbs-down"></i></span>
                                                                                                            </button>
                                                                                                        </div>
                                                                                                        <span className="received-time">{message.date}</span>
                                                                                                    </div>
                                                                                                </div>


                                                                                            </div>

                                                                                            // <>
                                                                                            //     {(message.text == 'loader') &&

                                                                                            //     }
                                                                                            // </>
                                                                                            :




                                                                                            <>
                                                                                                {((chatGptLoader == true) && (message.text == '') && (index == messages.length - 1)) &&
                                                                                                    <div className="chat-received-message-block">
                                                                                                        <p className="send-user-name" style={{ textAlign: 'left' }}>
                                                                                                            AI
                                                                                                        </p>
                                                                                                        <TypingLoader />
                                                                                                    </div>
                                                                                                }
                                                                                            </>
                                                                                        }
                                                                                    </>


                                                                                )}
                                                                            </div>
                                                                        )
                                                                    }


                                                                    )}
                                                                </div>

                                                            </>
                                                            :
                                                            ""
                                                    }


                                                    {/* ChatGPT ENDS */}


                                                    {/* Consultant List Appers */}
                                                    <div className="consultant-chat-gpt-list">
                                                        {consultantList.map((consultantDetails, index) => (
                                                            <div key={index} className="user-info-block">
                                                                <div key={index} className=" user-chat-info-block">
                                                                    <div key={index} className="user-info-image-block user-info-image">
                                                                        {/* <div className="user-info-image">
                                                                    <img src={consultantDetails.profile_path} />

                                                                </div> */}
                                                                        <ProfileIconsModal userDetails={consultantDetails} />
                                                                    </div>
                                                                    <div className="user-info-details">
                                                                        <div className="user-info-details-content-block">
                                                                            {/* {consultantDetails.first_name} {consultantDetails.last_name} */}
                                                                            {((consultantDetails.first_name !== null) && (consultantDetails.last_name !== null)) ?
                                                                                Capitalize(consultantDetails.first_name) + " " + Capitalize(consultantDetails.last_name) :
                                                                                (consultantDetails.first_name !== null) ? Capitalize(consultantDetails.first_name) : 'User'
                                                                            }
                                                                        </div>
                                                                        <div className="user-location-review-info">
                                                                            <span className="location-icon">
                                                                                {
                                                                                    consultantDetails.address.country
                                                                                        ?
                                                                                        <>
                                                                                            <i className="fa-solid fa-location-dot"></i> {consultantDetails.address.country}
                                                                                        </>
                                                                                        :
                                                                                        ""
                                                                                }

                                                                                {/* {t('canada')} */}

                                                                            </span>
                                                                            <span className="review-icon">
                                                                                {/* <ul>
                                                                                    <li><a href="#"><i className="fa-solid fa-star"></i></a></li>
                                                                                    <li><a href="#"><i className="fa-solid fa-star"></i></a></li>
                                                                                    <li><a href="#"><i className="fa-solid fa-star"></i></a></li>
                                                                                    <li><a href="#"><i className="fa-solid fa-star"></i></a></li>
                                                                                    <li><a href="#"><i className="fa-solid fa-star-half"></i></a></li>
                                                                                </ul> */}
                                                                            </span>
                                                                        </div>
                                                                        <div className="user-select-button">
                                                                            <button type="button" onClick={() => consultantSelection(consultantDetails.user_uuid)} style={{ backgroundColor: selectedRows.includes(consultantDetails.user_uuid) ? backgroundColor : '' }}>

                                                                                {
                                                                                    selectedRows.includes(consultantDetails.user_uuid)
                                                                                        ? t('selected')
                                                                                        : t('select')

                                                                                }
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}

                                                        {
                                                            /* If Consultant Array and Its Selection Done Than Submit Button Appers */
                                                            consultantList.length && selectedRows.length
                                                                ?
                                                                /* */
                                                                loginUserId
                                                                    ?
                                                                    <>
                                                                        <button type="submit" className="country-form-send-btn-query send-query-button" >{t('send_query')}</button>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <button type="button" onClick={() => sendQueryClick()} className="send-query-button akash" >{t('send_query')}</button>
                                                                    </>



                                                                :
                                                                /* When Only consultant List Appers */
                                                                ((consultantList.length && selectedRows.length == 0) ? <button className="send-query-button" type="button">{t('send_query')}</button> : "")
                                                        }
                                                    </div>

                                                    {/* Form END Here */}





                                                    {/* Email Or Phone Number Validation  */}
                                                    {sendQueryButton

                                                        ?
                                                        <>
                                                            {
                                                                verifyFormByPass ?
                                                                    <div className="chat-received-message-block ">
                                                                        <p className="send-user-name" style={{ textAlign: 'left' }}>
                                                                            <span className="user-chat-icon">
                                                                                <img src={ChatLogoicon} alt="" /></span>
                                                                            <span>AI:</span>
                                                                        </p>
                                                                        <div className="received-message-content send-query-form-width">
                                                                            <div className="received-message-content-block chat-country-received-form">
                                                                                <div className="chat-country-form">
                                                                                    <p>{t('please_verify_phone')}</p>
                                                                                    <div className="chat-country-input">
                                                                                        <Field type="text" name="email" placeholder="Enter Email Address" autoComplete="off" />
                                                                                        <ErrorMessage name="email" component="div" className="error" />
                                                                                    </div>
                                                                                    <div className="or-block">{t('or')}</div>
                                                                                    <div className="chat-country-input">
                                                                                        <div className="row">
                                                                                            {/* <div className="col-3">
                                                                                                <Field className="country-chat-input" type="tel" name="country_code" placeholder="Country Code" max="3" autoComplete="off" />

                                                                                            </div>
                                                                                            <div className="col-9">
                                                                                                <Field type="tel" name="phone" placeholder="Enter Phone Number" autoComplete="off" />

                                                                                            </div> */}
                                                                                            <PhoneInput
                                                                                                country={Default_Country}
                                                                                                preferredCountries={['in', 'ca', 'us']}
                                                                                                autoFormat
                                                                                                containerClass={`mt-2 mb-2`}
                                                                                                inputClass={`chat-phone-input `}
                                                                                                buttonClass={'country-dropdown'}
                                                                                                dropdownClass={'country-dropdown-container'}
                                                                                                enableSearch
                                                                                                countryCodeEditable={false}
                                                                                                onChange={(value, country) => handleContryAndPhoneChange(value, country, setFieldValue)}
                                                                                                placeholder="Phone Number"
                                                                                                autocompleteSearch={true}
                                                                                                inputProps={{
                                                                                                    name: 'phone',
                                                                                                    required: true,
                                                                                                }}

                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <ErrorMessage name="country_code" component="div" className="error" />
                                                                                    <ErrorMessage name="phone" component="div" className="error" />
                                                                                    <ErrorMessage name="emailOrPhone" component="div" className="error" />


                                                                                    {/* Hide Button Otp Show Option  */}
                                                                                    {
                                                                                        !showOtpBox
                                                                                            ?
                                                                                            <button type="submit" className="country-form-send-btn-query send-query-button" disabled={false}>{t('submit')}</button>
                                                                                            :
                                                                                            ""

                                                                                    }

                                                                                    {/* disabled={isSubmitting} */}
                                                                                </div>

                                                                            </div>
                                                                            {/* <div className="received-message-time-info">
                                                                        <span className="received-time">{CommonServices.getDateTimeByTimestampForChat(CommonServices.getCurrentTimestamp())}</span>
                                                                    </div> */}
                                                                        </div>

                                                                    </div>
                                                                    :
                                                                    ""
                                                            }

                                                            {/* Otp Send Box  */}



                                                            {
                                                                showOtpBox
                                                                    ?
                                                                    <>
                                                                        {
                                                                            verifyFormByPass ?
                                                                                <div className="chat-received-message-block">
                                                                                    <p className="send-user-name" style={{ textAlign: 'left' }}>
                                                                                        <span className="user-chat-icon">
                                                                                            <img src={ChatLogoicon} alt="" /></span>
                                                                                        <span>AI:</span>
                                                                                    </p>
                                                                                    <div className="received-message-content send-query-form-width">
                                                                                        <div className="received-message-content-block chat-country-received-form">
                                                                                            <div className="chat-country-form">
                                                                                                <p>{t('please_enter_otp')}</p>

                                                                                                <div className="chatgpt-otp-input-fields ">

                                                                                                    <OtpInput
                                                                                                        onChange={(e) => { otpFillEvent('messageItem', e) }}
                                                                                                        value={otpVariable}
                                                                                                        inputStyle={inputStyle}
                                                                                                        numInputs={4}
                                                                                                        inputType="number"
                                                                                                        separator={<span></span>}
                                                                                                        renderInput={(props) => <input {...props} />}
                                                                                                        shouldAutoFocus={true}

                                                                                                    />
                                                                                                    {otpObject.showOtpError &&
                                                                                                        <p className="f-14 c-r mt-2">{otpObject.otpMessage}</p>
                                                                                                    }
                                                                                                </div>


                                                                                                {/* Hide Button with Thanks Message Appers */}
                                                                                                {
                                                                                                    !thanks
                                                                                                        ?
                                                                                                        // <div className="d-flex justify-content-between align-items-center">
                                                                                                        <div>
                                                                                                            <button type="button" disabled={otpbuttonSUbmit} onClick={() => verifyOtpSubmit()} className="country-form-send-btn-query send-query-button w-100">{t('verify')}</button>
                                                                                                            {/* <button type="button" onClick={() => resetOtp()} className="country-form-send-btn-query send-query-button"> Reset</button> */}
                                                                                                            <p className="mt-2">{t('did_not_recieve')}  <span className=" c-chat cu-po ml-1" onClick={() => { resendOtp() }}>{t('resend_otp')}</span></p>
                                                                                                        </div>

                                                                                                        :
                                                                                                        ""

                                                                                                }



                                                                                            </div>

                                                                                        </div>
                                                                                        <div className="received-message-time-info">
                                                                                            <span className="received-time">{CommonServices.getDateTimeByTimestampForChat(CommonServices.getCurrentTimestamp())}</span>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                                :
                                                                                ""

                                                                        }
                                                                        {/* Thanks Message Appers  */}
                                                                        {
                                                                            thanks
                                                                                ?
                                                                                <div className="chat-received-message-block">
                                                                                    <p className="send-user-name" style={{ textAlign: 'left' }}>
                                                                                        AI
                                                                                    </p>
                                                                                    <div className="received-message-content">
                                                                                        <div className="received-message-content-block">
                                                                                            <p>{t('thanks_message')}</p>
                                                                                        </div>
                                                                                        <div className="received-message-time-info">
                                                                                            <span className="received-time"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                :
                                                                                ""

                                                                        }

                                                                    </>

                                                                    :
                                                                    ""
                                                            }



                                                            {/* <div>
                                                        <label>Email:</label>
                                                        <Field type="text" name="email" />
                                                        <ErrorMessage name="email" component="div" className="error" />
                                                    </div>
                                                    <div>
                                                        <label>Phone Number:</label>
                                                        <Field type="tel" name="phone" />
                                                        <ErrorMessage name="phone" component="div" className="error" />
                                                    </div>
                                                    <button type="submit" disabled={isSubmitting}>Submit</button> */}
                                                        </>

                                                        :
                                                        ""
                                                    }
                                                </span>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>

                                {/* Chat Gpt Text Form and Chat Data  */}
                                <form onSubmit={
                                    // ()=> {chatGptHandleSubmit( input)}
                                    chatGptHandleSubmit
                                } className="send-input-form-block">
                                    {
                                        inputDisabled ?
                                            questionCount < CHATGPT_QUESTIONS &&
                                            <>
                                                <span>
                                                    <input type="text" className="chat-placeholder" value={input} onChange={searchInputHandleChange} placeholder={t('input_placeholder')} />
                                                    <button type="submit"><i className="fa-regular fa-paper-plane"></i></button>
                                                </span>

                                            </>


                                            :
                                            <span></span>
                                    }

                                    <div className={`form-note-text-info ${inputDisabled ? `with-input` : `without-input`} `} >
                                        <p>{t('note')}
                                            <span className="bottom-note-text-anchor">
                                                <a onClick={setDefaultConsultant}> consultants </a>
                                            </span>
                                            <span>{t('note2')}</span>
                                        </p>


                                    </div>
                                </form>




                            </div>
                        </div>

                        <div className=" col-lg-6 p-0">
                            <div className="landing-page-img-section">
                                <div className="app-button-block">
                                    {/* <img src={AppleAppStore} alt="" /> */}
                                    {/* <img src={GooglePlayStore} alt="" /> */}
                                </div>
                            </div>
                        </div>

                    </div>
                    {/* row */}

                </div>
                {/* landing-page-inner-section */}

            </section>
        </div>
    )
    //#endregion
}
export default ChatUsingGemini;