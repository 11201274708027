import React, { Fragment, useState, useEffect, useRef } from "react";
import styles from "./Index.module.css";
import './Index.css';
import { Container, Row, Col, Form, Button } from "@themesberg/react-bootstrap";
import Header from "../../layout/Header";
import Footer from "../../layout/Footer";
import { DataLoading } from "../../components/air-migrate/Loader";
import NewAgentCard from "../../components/air-migrate/NewAgentCard";
import Map from "../../components/air-migrate/ServiceArea.jsx";
import { GOOGLE_MAP_API_KEY } from "../../config.tsx";
import { GetBrokersWithLocationApi } from '../../actions/RealEstateActions';
import LocationSearchBox from "../../components/air-migrate/LocationSearchBox.jsx";
import { GetFavouritesByIdApi } from "../../actions/RealEstateActions";
import NoDataFound from "../../components/air-migrate/NoDataFound";
import { PROFILE_PATH } from "../../config.tsx";
import CommonAlert from "../../common-components/CommonAlert.jsx";

const RealEstate = () => {
    //#region states and consts
    const keywordSearchRef = useRef();
    const [isUserLogin, setIsUserLogin] = useState(localStorage.getItem('accessToken') ? true : false);
    const [isLoading, setIsLoading] = useState(false);
    const [realEstatebrokerList, setRealEstatebrokerList] = useState([]);
    const [searchFilter, setSearchFilter] = useState('keyword');
    const [latValue, setLatValue] = useState(null);
    const [longValue, setLongValue] = useState(null);
    const [locationName, setLocationName] = useState(null);
    const [searchValue, setSearchValue] = useState(null);
    const [favorite, setFavorite] = useState('');
    const [searchFlag, setSearchFlag] = useState(false);
    //Alert states and functions
    const [alertsArray, setAlertsArray] = useState([]);
    const [countAlert, setCountAlert] = useState(0);
    //#endregion states and conts

    const handleAlert = (id) => {
        let array = [];
        for (let i = 0; i < alertsArray.length; i++) {
            if ((alertsArray[i].id) == id) {
                array = alertsArray.pop();
            }
        }
        setCountAlert(Math.floor(Math.random() * 1000))
    }

    // getting broker's list here
    const fetchBrokersData = async (search, lat, long, favorite) => {
        try {
            setIsLoading(true);
            const response = await GetBrokersWithLocationApi(search, lat, long, favorite);
            if (response.data && response.data[0]?.results) {
                setRealEstatebrokerList(response.data[0]?.results);
                setIsLoading(false);
            }
        } catch (error) {
            console.error("Error fetching broker list!", error);
            setIsLoading(false);
        }
    };
    //#region useEffect start
    useEffect(() => {
        if (favorite) {
            fetchBrokersData('', '', '', favorite);
        } else {
            fetchBrokersData();
        }
    }, [countAlert, favorite]);
    //#region useEffect end

    //#region handler functions start
    // reset keyword input box
    const resetKeywordInputSearch = () => {
        if (keywordSearchRef.current && (keywordSearchRef.current.value !== '')) {
            keywordSearchRef.current.value = '';
        }
    }
    // function to run on filter change 
    const handelFilterChange = (e) => {
        setSearchFilter(e.target.value)
        resetKeywordInputSearch();
        setSearchValue(null);
        setLocationName(null);
    }
    // function to run on searching 
    const handleSearch = () => {
        setSearchFlag(true)
        if (searchFilter == 'location') {
            if (!locationName) {
                handleReset();
            } else {
                fetchBrokersData('', latValue, longValue, favorite);
            }
        } if (searchFilter == 'keyword') {
            if (!searchValue) {
                handleReset();
            } else {
                fetchBrokersData(searchValue, '', '', favorite);
            }
        }
    }
    // blank search reset 
    const handleReset = () => {
        setSearchFlag(false);
        resetKeywordInputSearch();
        setSearchValue(null);
        setLocationName(null);
        setLatValue(null);
        setLongValue(null);
        fetchBrokersData('', '', '', favorite);

    }

    // favorite button toggeler
    const toggleFavorite = () => {
        resetKeywordInputSearch();
        setSearchValue(null);
        setLocationName(null);
        const newFavoriteValue = favorite == 1 ? '' : 1;
        setFavorite(newFavoriteValue);
        fetchBrokersData('', '', '', newFavoriteValue);
    }

    // Call  function to get location data from location  search box
    const onLocationSearchHandler = (locationData) => {
        if (locationData) {
            setLocationName(locationData.location);
            setLatValue(locationData.latitude);
            setLongValue(locationData.longitude);
        }
    }
    // on favaroute click
    const handleFavoriteClick = async (user_uuid, index) => {
        if (!isUserLogin) {
            return
        }
        try {
            setIsLoading(true);
            const favoritesList = await GetFavouritesByIdApi(user_uuid);
            if (favoritesList.status_code === 1) {
                setAlertsArray([...alertsArray, { id: `${'fav'}${alertsArray.length}`, type: 'success', message: favoritesList?.message }]);
                const updatedBrokerList = [...realEstatebrokerList];
                updatedBrokerList[index].favorite_user_id = !updatedBrokerList[index].favorite_user_id;
                setRealEstatebrokerList(updatedBrokerList);
                if (favorite) {
                    fetchBrokersData('', '', '', favorite);
                }
            } else {
                setAlertsArray([...alertsArray, { id: `${'fav'}${alertsArray.length}`, type: 'danger', message: favoritesList?.message }]);
            }
            setIsLoading(false);
        } catch (error) {
            console.error("Error fetching favorites list:", error);
        }
    };

    // function to ge response from children
    const getResponseForAlert = (data) => {
        setAlertsArray([...alertsArray, { id: `${'fav'}${alertsArray.length}`, type: 'success', message: data?.message }]);
    }
    //#region handle functions end

    //#region render start
    return (
        <Fragment>
            <Header isUserLogin={isUserLogin} setIsUserLogin={setIsUserLogin} />
            <Container className={styles.newRealEstateContainer} >
                <Row>
                    {(alertsArray.length > 0) &&
                        alertsArray.reverse().map((item, index) => {
                            return (
                                <div className='mb-3'>
                                    <CommonAlert key={index} id={item.id} type={item.type} message={item.message} handleAlert={handleAlert} />
                                </div>
                            )
                        })
                    }
                </Row>
                <Row>
                    <Col sm={12} md={6} lg={6} xl={6}>
                        <Row>
                            <Col sm={12} md={12} lg={4} xl={4} className="mb-3 mb-lg-0">
                                <Form.Group >
                                    <Form.Select aria-label="Select Filter"
                                        className={styles.realEstateFormControl}
                                        id="agent-filter"
                                        name="agent-filter"
                                        value={searchFilter}
                                        placeholder="Select Filter"
                                        onChange={(e) => { handelFilterChange(e) }}
                                    >
                                        <option className="d-none" value="">Select Option</option>
                                        <option value="location"> Search By Location</option>
                                        <option value="keyword"> Search By Keyword</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col sm={12} md={12} lg={6} xl={6} className="mb-3 mb-lg-0">
                                <Form.Group>
                                    {searchFilter == 'location' ?
                                        (<LocationSearchBox
                                            onLocationSearchHandler={onLocationSearchHandler}
                                            name={'location_search'}
                                            value={locationName}
                                            className={`form-control ${styles.realEstateFormControl}`}
                                            onChange={(e) => { setLocationName(e.target.value) }}
                                        // onBlur={serviceLocationFormik.handleBlur} 
                                        />)
                                        :
                                        (<Form.Control
                                            className={styles.realEstateFormControl}
                                            ref={keywordSearchRef}
                                            id="search"
                                            name="search-box"
                                            value={searchValue}
                                            placeholder="Enter keyword"
                                            onChange={(e) => { setSearchValue(e.target.value) }}
                                        >
                                        </Form.Control>)
                                    }
                                </Form.Group>
                            </Col>
                            <Col sm={12} md={12} lg={2} xl={2}>
                                <Button className={styles.buttonDiv} onClick={() => handleSearch()}><i className=" fa fa-search small me-2"></i> <span className={styles.hideSearch}>Search</span></Button>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col sm={12} md={12} lg={8} xl={8} className=' text-start'>
                                <h5 className="my-auto">Advanced Search</h5>
                            </Col>
                            {isUserLogin &&
                                <Col sm={12} md={12} lg={4} xl={4} className='text-end pe-0'>
                                    <Button className={styles.favouriteButton} onClick={() => toggleFavorite()} ><i className={`fa fa-heart small me-2 ${favorite == 1 ? 'red' : ''}`}></i> {favorite == 1 ? "Broker List" : "My Favorites"}</Button>
                                </Col>
                            }
                        </Row>
                        <Row className="mt-3">
                            <Col sm={12} md={12} lg={12} xl={12}>
                                <div className="row img-block agent-card-row">
                                    {realEstatebrokerList.length ? (
                                        <>
                                            {realEstatebrokerList && realEstatebrokerList.map((broker, index) => (
                                                <div key={index} className={`col-md-4 explore-agent-card-block ${realEstatebrokerList.length <= 3 ? 'd-block' : ''}`}>
                                                    <NewAgentCard
                                                        isUserLogin={isUserLogin}
                                                        profilePath={broker.profile_path ? broker.profile_path : PROFILE_PATH}
                                                        firstName={broker.first_name}
                                                        lastName={broker.last_name}
                                                        address={broker.address}
                                                        userUuid={broker.user_uuid}
                                                        isFavorite={broker.favorite_user_id}
                                                        handleFavoriteClick={() => handleFavoriteClick(broker.user_uuid, index)}
                                                        setIsLoading={setIsLoading}
                                                        isLoading={isLoading}
                                                        getResponseForAlert={getResponseForAlert}
                                                        alertsArray={alertsArray}
                                                        setAlertsArray={setAlertsArray}
                                                    />
                                                </div>
                                            ))}
                                        </>
                                    ) : (<>
                                        <NoDataFound />
                                    </>
                                    )}

                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={12} md={6} lg={6} xl={6} className="pt-4 pt-md-1">
                        <div className={styles.googleMapBlock}>
                            {/* {realEstatebrokerList.length && */}
                            <Map
                                googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}&v=3.exp&libraries=geometry,drawing,places`}
                                loadingElement={<div style={{ height: '100%' }} />}
                                containerElement={<div style={{ height: '100%', width: '100%' }} />}
                                mapElement={<div style={{ height: '100%', width: '100%' }} />}
                                locations={realEstatebrokerList}
                                inRealEstate={true}
                                searchFlag={searchFlag}
                                setSearchFlag={setSearchFlag}

                            />
                        </div>
                    </Col>
                </Row>
            </Container>
            <Footer isUserLogin={isUserLogin} setIsUserLogin={setIsUserLogin} />
            {!isUserLogin && isLoading && <DataLoading />}
        </Fragment>
    );
};
//#endregion render end
export default RealEstate;
