import React, { Fragment, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
    Card,
    Row,
    Col,
    OverlayTrigger,
    Tooltip,
    Button,
    ListGroup,
    ListGroupItem,
    ButtonGroup,
} from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faEye } from "@fortawesome/free-solid-svg-icons";
// Local Imports
import styles from "./Index.module.css";
import MyProfile from "../../components/air-migrate/Myprofile";
import MyProfileDocumentList from "./components/MyProfileDocumentList.jsx";
import AboutMe from "./components/AboutMe";
import { GetMyProfileDataApi } from "../../actions/MyProfileActions";
import { DataLoading } from "../../components/air-migrate/Loader";
import Map from "../../components/air-migrate/ServiceArea.jsx";
import { GOOGLE_MAP_API_KEY } from "../../config.tsx";
import AssociatedPeopleList from "./components/AssociatedPeopleList.jsx";
import EsignedDocumentList from "./components/EsignedDocumentList.jsx";
import { HomepageUrl } from "../../utilities/helpers/Constant.jsx";
import CommonAlert from "../../common-components/CommonAlert.jsx";
import CommonServices from "../../common-components/CommonServices.jsx";
import DocumentViewModal from "../../components/air-migrate/DocumentViewModal.jsx";
import UnleashMessage from "../../common-components/UnleashMessage.jsx";
import CommonBreadcrumb from "../../common-components/CommonBreadcrumb.jsx";
import OtherContactDetails from "./components/OtherContactDetails.jsx";
import messageHelper from "../../utilities/helpers/messageHelper.js";

// Main  Component
const PersonalMyProfile = () => {
    //#region states consts
    const history = useHistory();
    const location = useLocation();
    const roleId = localStorage.getItem('roleId')
    // state for loader setting true or false
    const [isLoading, setIsLoading] = useState(false);
    // state for User Login  Data From the local storage
    const [isUserLogin, setIsUserLogin] = useState(false);
    // rolid state here
    const [userRoleId, setUserRoleId] = useState();
    // state to handel myprofile data 
    const [myProfileDetails, setMyprofileDetails] = useState({});
    // Service Area component consts
    const apiKey = GOOGLE_MAP_API_KEY; // Google Map API key
    // const rangeInMeters = GOOGLE_MAP_CIRCLE_RANGE; // Circle Range in Meters
    // state to handel lattitude and longitude values
    // const [latLongValues, setLatLongValues] = useState({ lat: 0, long: 0 });
    const [latLongArray, setlatLongArray] = useState([]);
    //state for getting user id for asscociated member's profile view
    const [UserId, setUserId] = useState(location.state?.userId ? location.state?.userId : '');
    // const for agent's document list view to admin (send only to document list api)
    const [agentRole, setagentRole] = useState(location.state?.roleId ? location.state?.roleId : localStorage.getItem('roleId'));
    //Alert states
    const [alertsArray, setAlertsArray] = useState([]);
    const [count, setCount] = useState(0);
    // Edit option disabled using this const
    const [isEditProfile, setIsEditProfile] = useState(localStorage.getItem('roleId') == 1 ? false : true)
    // unleash checks for all my profile  page components
    // Esign Document list
    const esignDocumentViewFeatureFlag = CommonServices.GetUnleashFeatureFlag('my-signed-docs-list');
    // associate member list
    const getClientCaseAssociateMemberFeatureFlag = CommonServices.GetUnleashFeatureFlag('frontend-Get-client-cases-associated-member');
    // My profile Data view
    const isViewProfileFeatureFlag = CommonServices.GetUnleashFeatureFlag("My-Profile-GET");
    // Agent Document list
    const agentDocumentListFlag = CommonServices.GetUnleashFeatureFlag("agents-documents-list");
    // Edit Fetaure toggle
    const editProfileFeatureFlag = CommonServices.GetUnleashFeatureFlag("Update-My-Profile");
    // end unleash variable block

    // View Document
    const [showViewDocumentModal, setShowViewDocumentModal] = useState(false)
    // const [viewDocumentId, setViewDocumentId] = useState()
    const handleViewDocumentModal = () => setShowViewDocumentModal(!showViewDocumentModal);
    const [selectedUserUuid, setSelectedUserUuid] = useState('');
    //#endregionstates consts

    // Use Effect for initialization
    useEffect(() => {
        var userDetails = localStorage.getItem("accessToken");
        if (userDetails) {
            setIsUserLogin(true);
            GetMyProfileData(UserId);
        } else {
            history.push("/")
        }
        if (roleId != 1) {
            setIsEditProfile(editProfileFeatureFlag ? true : false);
        }
    }, [editProfileFeatureFlag, count]);

    // Function to call get profile data api
    const GetMyProfileData = async (UserId) => {
        try {
            var response = await GetMyProfileDataApi(UserId);
            setMyprofileDetails(response.data[0]);
            extractMetaAndGeoLocation(response.data[0].other_details);
            setUserRoleId(response.data[0].role)
            dynamicBreadcrumb();
        } catch (err) {
            console.error(messageHelper('profile-error'), err);
        }
    }

    // function to extract lat long and meta vaules(other details) from profile data
    const extractMetaAndGeoLocation = (data) => {
        // getting other details from profile details 
        if (data) {
            // make a object of all keys and values from otherDetails array
            var metaDetails = data.reduce((accumulator, current) => {
                accumulator[current.key] = current.value;
                return accumulator;
            }, {});
            // Making array of all service locations 
            const serviceLocationKeys = ['service_location', 'service_location2', 'service_location3'];
            let locationArray = [];
            let latLongArray = [];
            // Iterate over the serviceLocationKeys array and push the corresponding values from the metaDetails
            for (const key of serviceLocationKeys) {
                if (metaDetails[key]) {
                    if (CommonServices.isJSON(metaDetails[key])) {
                        locationArray.push(JSON.parse(metaDetails[key]));
                    } else {
                        locationArray.push(metaDetails[key]);
                    }
                }
            }

            // get array of all lattitude and logitude for map
            locationArray.forEach((loaction) => {
                latLongArray.push({
                    lat: loaction.lattitude,
                    long: loaction.longitude
                })
            })
            setlatLongArray(latLongArray);
        }

    };

    // get response messaage from child components for Alert
    const getResponseMessageFromChild = (response, messageFor) => {
        if (response?.status_code == 1) {
            setAlertsArray([{ id: `${messageFor}${alertsArray.length}`, type: 'success', message: response?.message }, ...alertsArray]);
        } else {
            setAlertsArray([{ id: `${messageFor}${alertsArray.length}`, type: 'danger', message: response?.message }, ...alertsArray]);
        }
    }

    const handleAlert = (id) => {
        let array = [];
        for (let i = 0; i < alertsArray.length; i++) {
            if ((alertsArray[i].id) == id) {
                array = alertsArray.pop();
            }
        }
        setCount(Math.floor(Math.random() * 1000))
    }

    // function to download document
    const downloadDocument = async (documentURL) => {
        if (documentURL) {
            CommonServices.downloadFileService(documentURL, 'Visa_document', 'pdf')
        }
    }

    /* view Visa document modal function */
    const viewVisaDocument = (userUuid) => {
        setSelectedUserUuid(userUuid);
        handleViewDocumentModal()
    }

    /* dynamic bradcrumb code here  */
    const dynamicBreadcrumb = () => {
        var breadcrumb = [];
        if (roleId == 1) {
            if (!isEditProfile) {
                if (myProfileDetails?.role == 3) {
                    localStorage.setItem("role", myProfileDetails?.role)
                    return "Customer Profile";

                } else if (myProfileDetails?.role == 4 || myProfileDetails.role == 2) {
                    localStorage.setItem("role", myProfileDetails?.role)
                    return "Agent Profile";

                }
            }
        }
        else {
            return <strong>My Profile</strong>
        }
    }
    const [breadcrumbArray, setBreadcrumbArray] = useState([
        { name: 'Home', url: HomepageUrl },
        { name: dynamicBreadcrumb(), url: '' }

    ]);

    //#region render start
    return (
        <Fragment>
            {/* Show Loader until data not load completely */}
            {(isUserLogin && isLoading) ? <DataLoading />
                : <><div className="d-block mb-4 mb-md-0">
                    <CommonBreadcrumb breadcrumbArray={breadcrumbArray} />
                    <div className="edit-client-heading">
                        <h4>{dynamicBreadcrumb()}</h4>
                    </div>
                    {(alertsArray.length > 0) &&
                        alertsArray.map((item, index) => {
                            return (
                                <CommonAlert key={index} id={item.id} type={item.type} message={item.message} handleAlert={handleAlert} />
                            )
                        })
                    }
                </div>
                    {isViewProfileFeatureFlag ? (
                        <>
                            {[2, 3, 4, '2', '3', '4'].includes(userRoleId) &&
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={(userRoleId === '3') ? 8 : 6} xl={(userRoleId === '3') ? 8 : 4}>
                                        <MyProfile myProfileDetails={myProfileDetails} GetMyProfileData={GetMyProfileData} isLoading={isLoading} setIsLoading={setIsLoading} sendDataToParent={getResponseMessageFromChild} isEditProfile={isEditProfile} />
                                    </Col>
                                    {(userRoleId !== '3') ? /* Fields Visible to Agents Only Starts */
                                        (<>
                                            <Col xs={12} sm={12} md={12} lg={6} xl={4} className='mt-xl-0 mt-lg-0 mt-4'>
                                                <AboutMe myProfileDetails={myProfileDetails} GetMyProfileData={GetMyProfileData} sendDataToParent={getResponseMessageFromChild} isLoading={isLoading} setIsLoading={setIsLoading} isEditProfile={isEditProfile} />
                                            </Col>
                                            <Col xs={12} sm={12} md={12} lg={6} xl={4} className={`mt-xl-0 mt-lg-4 mt-4 ${styles.documentListCard}`}>
                                                {agentDocumentListFlag &&
                                                    <MyProfileDocumentList sendDataToParent={getResponseMessageFromChild} alertsArray={alertsArray} setAlertsArray={setAlertsArray} isEditProfile={isEditProfile} UserId={UserId} agentRole={agentRole} />
                                                }
                                                <div className="mt-4">
                                                    <OtherContactDetails sendDataToParent={getResponseMessageFromChild} alertsArray={alertsArray} setAlertsArray={setAlertsArray} isEditProfile={isEditProfile} UserId={UserId} agentRole={agentRole} />
                                                </div>
                                            </Col>
                                            <Col xs={12} sm={12} md={12} lg={6} xl={4} className="mt-4">
                                                <Card>
                                                    <Card.Header>
                                                        <div className={styles.documentCardHeader}>
                                                            <h3 className="f-16 c-lg">Service Area</h3>
                                                        </div>
                                                    </Card.Header>
                                                    <Card.Body className="p-2">
                                                        {latLongArray.length ?
                                                            (<div className={styles.serviceAreaBlock}>
                                                                <Map
                                                                    googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${apiKey}&v=3.exp&libraries=geometry,drawing,places`}
                                                                    loadingElement={<div style={{ height: '100%' }} />}
                                                                    containerElement={<div style={{ height: '100%', width: '100%' }} />}
                                                                    mapElement={<div style={{ height: '100%', width: '100%' }} />}
                                                                    locations={latLongArray}
                                                                />
                                                            </div>
                                                            ) :
                                                            (<>
                                                                <h4 className="text-center f-16 c-lg">No Data Found!</h4>
                                                            </>)
                                                        }
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        </>)  /* Fields Visible to Agents Only Ends */
                                        : (
                                            <>{/* Fields Visible to Users Only Starts */}
                                                <Col xs={12} sm={12} md={12} lg={6} xl={4} className="mt-xl-0 mt-lg-4 mt-4">
                                                    {esignDocumentViewFeatureFlag &&
                                                        <div>
                                                            <EsignedDocumentList isEditProfile={isEditProfile} UserId={UserId} />
                                                        </div>
                                                    }
                                                    {getClientCaseAssociateMemberFeatureFlag &&
                                                        <div className={`${getClientCaseAssociateMemberFeatureFlag ? 'mt-3' : 'mt-0'}`}>
                                                            <AssociatedPeopleList isEditProfile={isEditProfile} UserId={UserId} />
                                                        </div>
                                                    }
                                                    {myProfileDetails?.visa_document &&
                                                        <div className="mt-3">
                                                            <Card>
                                                                <Card.Header>
                                                                    <div className={styles.documentCardHeader}>
                                                                        <h4 className="f-16 c-lg">Visa</h4>
                                                                    </div>
                                                                </Card.Header>
                                                                <Card.Body className="p-2">
                                                                    {/* Document View Modal here */}
                                                                    {showViewDocumentModal &&
                                                                        <DocumentViewModal show={showViewDocumentModal} handleClose={handleViewDocumentModal} documentId='' isCustomerTypeView={true} userUuid={selectedUserUuid} />
                                                                    }
                                                                    <ListGroup>
                                                                        <ListGroupItem >
                                                                            <div
                                                                                className={`client-block1-details ${styles.listItem} ${styles.docType}`}
                                                                            >
                                                                                <p className="f-16">Visa Document</p>
                                                                                <div className={`${styles.listItem}`}>
                                                                                    <ButtonGroup className={styles.esignActionBtnGrp}>
                                                                                        <>
                                                                                            <OverlayTrigger
                                                                                                placement="top"
                                                                                                overlay={<Tooltip id="view">View</Tooltip>}
                                                                                            >
                                                                                                <Button
                                                                                                    variant="outline-primary"
                                                                                                    onClick={() => viewVisaDocument(myProfileDetails.user_uuid)}
                                                                                                >
                                                                                                    <FontAwesomeIcon icon={faEye} className="" />
                                                                                                </Button>
                                                                                            </OverlayTrigger>
                                                                                            <OverlayTrigger
                                                                                                placement="top"
                                                                                                overlay={<Tooltip id="upload">Download</Tooltip>}
                                                                                            >
                                                                                                <Button
                                                                                                    variant="outline-primary"
                                                                                                    onClick={() => { downloadDocument(myProfileDetails.visa_document) }}
                                                                                                >
                                                                                                    <FontAwesomeIcon icon={faDownload} className="" />
                                                                                                </Button>
                                                                                            </OverlayTrigger>
                                                                                        </>
                                                                                    </ButtonGroup>
                                                                                </div>
                                                                            </div>
                                                                        </ListGroupItem>
                                                                    </ListGroup>
                                                                </Card.Body>
                                                            </Card>
                                                        </div>
                                                    }
                                                </Col>
                                            </>
                                        )/* Fields Visible to Users Only Ends */
                                    }
                                </Row>
                            }
                        </>
                    ) : (
                        /* Component shows if Myprofile View Unleash is Off */
                        <UnleashMessage />
                    )}
                </>
            }
        </Fragment>
    );
};
//#endregion render end
export default PersonalMyProfile;
