import React, { useState, useEffect,useRef } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { Button } from 'react-bootstrap';
import styles from '../../views/my-profile/Index.module.css';

export const ImageCrop = ({ imgSrc, sendFile,index ,isDocumentUpload,isUpload}) => {
    //#region conts start
    const [image, setImage] = useState();
    const cropperRef = useRef();
    const [showCanVasPreview, setShowCanVasPreview] = useState(true);
    //#endregion conts end

    //#region hooks start
    useEffect(()=>{
        setImage(URL.createObjectURL(imgSrc));
    },[imgSrc])
    //#endregion hooks end

     //#region handlers start
    const getCropData = async () => {
        if (typeof cropperRef.current?.cropper !== "undefined") {
            const resizedCanvas = resizeImage(cropperRef.current?.cropper.getCroppedCanvas(), 1000, 1000);
            const blob = await new Promise((resolve) => {
                resizedCanvas.toBlob((blob) => {
                    resolve(blob);
                }, 'image/jpeg', 1); // Adjust the quality as needed (0.8 is just an example)
            });
            const file = new File([blob], imgSrc.name, { type: 'image/jpeg' });
            sendFile(file, showCanVasPreview, index);   //  sending file to parent myprofile upload modal 
        }
    };
    // image resize function call
    const resizeImage = (canvas, targetWidth, targetHeight) => {
        const resizedCanvas = document.createElement('canvas');
        resizedCanvas.width = targetWidth;
        resizedCanvas.height = targetHeight;
        const ctx = resizedCanvas.getContext('2d');
        if (!ctx) {
            throw new Error('No 2d context');
        }
        ctx.drawImage(canvas, 0, 0, canvas.width, canvas.height, 0, 0, targetWidth, targetHeight);
        return resizedCanvas;
    };
    //#endregion handlers start

    //#region render start
    return (
        <>
            {showCanVasPreview && image &&
                <div className={`w-100 ${isDocumentUpload?'':styles.filePreviewBlock}`}>
                    <div>
                        <Cropper
                            ref={cropperRef}
                            style={{ height: '100%', width: "100%" }}
                            aspectRatio={1}
                            src={image}
                            viewMode={1}
                            minCropBoxHeight={10}
                            minCropBoxWidth={10}
                            background={false}
                            responsive={false}
                            autoCropArea={1}
                            checkOrientation={true}
                            guides={true}
                            cropBoxResizable={true}
                            dragMode="move"
                        />
                    </div>
                    <Button disabled={isUpload} className='my-2' variant='success' onClick={() => { getCropData(); setShowCanVasPreview(false)}}>Crop</Button>
                </div>
            }
        </>
        );
};
//#endregion render end
export default ImageCrop;
