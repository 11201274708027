import { authApi } from '../utilities/helpers/ApiHelper';
import { toast } from 'react-hot-toast';

async function CreateChatRequestApi(requestBody) {
   
    let url = `gemini-chat-ai`;
    try {
        const response = await authApi.postAPI(url, requestBody);
        return response;
    }
    catch (e) {
        toast.error("Something went wrong!", { duration: 4000 });
    }
}

export {CreateChatRequestApi}