import React, { useState, useEffect } from "react";
import {
    Card,
    ListGroup,
    ListGroupItem,
    Button,
    ButtonGroup,
    OverlayTrigger,
    Tooltip,
} from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import styles from "../Index.module.css";
import FileUploadModal from "../../../components/air-migrate/FileUploadModal.jsx";
import DocumentViewModal from "../../../components/air-migrate/DocumentViewModal.jsx";
import { GetMyprofileDocumentListApi } from "../../../actions/MyProfileActions.jsx";

const MyProfileDocumentList = ({ alertsArray, setAlertsArray, isEditProfile,UserId,agentRole }) => {
    const [userId, setUserId] = useState(UserId?UserId:localStorage.getItem('amUserId'))
    /* file Uplaod Modal const here */
    const [show, setShow] = useState(false);
    const uploadHandleClose = () => setShow(false);
    const handleShow = (docId) => { setShow(true); setCurrentDocId(docId); console.log("handleShow", docId); };
    // Documents list const here
    const [documentList, setDocumentList] = useState([]);
    // doc id state
    const [currentDocId, setCurrentDocId] = useState();
    // consts for document modal here 
    const [showDocModal, setShowDocModal] = useState(false);
    const viewHandleClose = () => setShowDocModal(false);
    const viewHandleOpen = (docId) => { setShowDocModal(true); setCurrentDocId(docId); };

// #region UseEffect
    useEffect(() => {
        getProfileDocumentList();
    }, [])
// #endregion useEffect

// #region Agent Document List Api
    // function to fetch document list from API
    const getProfileDocumentList = async () => {
        if(agentRole == 2 || agentRole == 4){
            const response = await GetMyprofileDocumentListApi(userId,agentRole);
            if (response?.status_code === 1) {
                setDocumentList(response?.data);
            } else {
                console.error(response?.message);
            }
        } 
    }
    // function to get column name from data
    const getColumnName  = (data) => {
        let newData = eval(data)
        return "("+newData[0]['suggestion']+")"
    }
//#region render start
    return (
        <Card>
            <Card.Header>
                <div className={styles.documentCardHeader}>
                    <h3 className="f-16 c-lg">List of Documents</h3>
                </div>
            </Card.Header>
            <Card.Body className="p-2">
                {/* FIle Upload Modal component */}
                {show &&
                    <FileUploadModal
                        show={show}
                        handleClose={uploadHandleClose}
                        documentId={currentDocId}
                        isMyProfileDocs={true}
                        FunctionToRefreshAfterUpload={getProfileDocumentList}
                        setModalAlertsArray={setAlertsArray}
                        modalAlertsArray={alertsArray}
                    />
                }
                {/* Document View Modal here */}
                {showDocModal &&
                    <DocumentViewModal show={showDocModal} handleClose={viewHandleClose} isMyProfileDocs={true} documentId={currentDocId} />
                }
                <ListGroup>
                    {documentList.length > 0 ? (
                        <>
                            {documentList.map((doc, index) => {
                                return (
                                    <ListGroupItem key={index}>
                                        <div
                                            className={`client-block1-details ${styles.listItem} ${styles.docType}`}
                                        >   
                                            <p className="f-16">
                                                {doc.type} <span className="help-agent-docs">{ doc.options ? getColumnName(doc.options) : ""}</span>
                                            </p>
                                            <div className={`${styles.listItem}`}>
                                                <ButtonGroup className={styles.esignActionBtnGrp}>
                                                    {doc.uploaded &&
                                                        <>
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={<Tooltip id="view">View</Tooltip>}
                                                            >
                                                                <Button
                                                                    variant="outline-primary"
                                                                    onClick={() => viewHandleOpen(doc.document_id)}
                                                                >
                                                                    <FontAwesomeIcon icon={faEye} className="" />
                                                                </Button>
                                                            </OverlayTrigger>
                                                            {isEditProfile &&
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    overlay={<Tooltip id="upload">Re Upload</Tooltip>}
                                                                >
                                                                    <Button
                                                                        variant="outline-primary"
                                                                        onClick={() => { handleShow(doc.id) }}
                                                                    >
                                                                        <FontAwesomeIcon icon={faUpload} className="" />
                                                                    </Button>
                                                                </OverlayTrigger>
                                                            }
                                                        </>
                                                    }
                                                    {!doc.uploaded && isEditProfile &&
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={<Tooltip id="upload">Upload</Tooltip>}
                                                        >
                                                            <Button
                                                                variant="outline-primary"
                                                                onClick={() => { handleShow(doc.id) }}
                                                            >
                                                                <FontAwesomeIcon icon={faUpload} className="" />
                                                            </Button>
                                                        </OverlayTrigger>
                                                    }
                                                    {!isEditProfile && !doc.uploaded && 
                                                    <>
                                                    <p>Not Available</p>
                                                    </>}
                                                </ButtonGroup>
                                            </div>
                                        </div>
                                    </ListGroupItem>
                                )
                            })
                            }
                        </>) : (
                        <>
                            <h4 className="text-center f-16 c-lg">No Documents Found!</h4>
                        </>)
                    }
                </ListGroup>
            </Card.Body>
        </Card>
    );
};
//#endregion render end
export default MyProfileDocumentList;