import React, { Fragment, useEffect, useState } from "react";
import { Card, Button, Row, Col, Form } from '@themesberg/react-bootstrap'
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from "react-hot-toast";
import Select from 'react-select';

//Local imports
import ViewClientDocumentModel from "../../../components/air-migrate/ViewClientDocumentsModel";
import { Capitalize } from "../../../common-components/StringConversions";
import styles from "./Index.module.css";
import '../../../assets/global-css/Index.css';
import { DataLoading } from "../../../components/air-migrate/Loader";
import EditPayment from "../../clients/edit-payment/Index";
import TemplateForm from "../../templates/common-component/TemplateForm";
import UserNotes from "../../../components/air-migrate/UserNotes.jsx";
import { GetAssociatedMemberListForClientAndCaseApi, UpdateCaseNameApi, GetCaseNameApi } from "../../../actions/CaseActions.jsx";
import NoDataFound from "../../../components/air-migrate/NoDataFound.jsx";
import AddClientModal from "../add-client-modal/Index.jsx";
import CommonServices from "../../../common-components/CommonServices.jsx";
import { HomepageUrl } from "../../../utilities/helpers/Constant.jsx";
import CaseChatPopup from "../../../components/air-migrate/CaseChatPopup.jsx";
import '../../../assets/mobile-custom-css/Index.css';
import '../../clients/edit-client/Index.css';
import CommonAlert from "../../../common-components/CommonAlert.jsx";
import CommonBreadcrumb from "../../../common-components/CommonBreadcrumb.jsx";
import { getChatCaseCompleteStatusByCaseIdAPI } from "../../../actions/ChatsActions.jsx";
import CaseInformationStep from "../../../components/air-migrate/CaseInformationStep.jsx";
import CaseCompeteInfoModal from "../../../components/air-migrate/CaseCompleteMessageModal.jsx";

const CaseDetails = () => {
  //#region State and variables
  const history = useHistory();
  const location = useLocation();
  const [requestMoreClicked, setRequestMoreClicked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userRoleId, setUserRoleId] = useState(localStorage.getItem('roleId'));
  const [userId, setUserId] = useState(localStorage.getItem('amUserId'));
  // location?.state.userId
  const [caseId, setCaseId] = useState(location?.state.caseId);
  const [count, setCount] = useState(0);
  const [associateMemberList, setAssociatedMemberList] = useState([]);
  const [ChangedParticipantUserIds, setChangedParticipantUserIds] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [caseRoleId, setCaseRoleId] = useState(localStorage.getItem("role"));
  const [caseName, setCaseName] = useState(location.state?.caseName ? location.state?.caseName : 'Case Details');
  const [caseNameGetByLocation, setCaseNameGetByLocation] = useState(location.state?.caseName ? location.state?.caseName : 'Case Details');
  const [isCaseNameEdit, setIsCaseNameEdit] = useState(false);
  const [participantDropdownList, setParticipantDropdownList] = useState([]);
  const [participantsUserIds, setParticipantUserIds] = useState([]);
  const [selectedParticipantId, setSelectedParticipantId] = useState({});
  const [countryCodeOfSelectedMember, setCountryCodeOfSelectedMember] = useState(91);
  const [viewCount, setViewCount] = useState(0);
  const [templateCount, setTemplateCount] = useState(0);
  //States to manage mobile ui
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const [singleScreen, setSingleScreen] = useState(false);
  const [isToastVisible, setIsToastVisible] = useState(false);
  const [alertsArray, setAlertsArray] = useState([]);
  // used for case current status in action
  const [caseCurrentStatus, setCaseCurrentStatus] = useState(false)
  // case status state 
  const [caseStatus, setCaseStatus] = useState();
  const [showCaseCompleteInfoModal, setshowCaseCompleteInfoModal] = useState(false);

  //#endregion

  //#region useEffect
  useEffect(() => {
    GetCaseName();
    GetAssociatedMemberListForClientAndCase();
    getChatCaseCompleteStatusByCase()
    //functionality to set collapsible table 
    window.addEventListener('resize', updateDimension);
    updateDimension();
    // removing eventlistener for no furhter effect after 
    return (() => {
      window.removeEventListener('resize', updateDimension);
    })

  }, [])
  //#endregion

  //#region Functions
  // used for case current status in action
  const getChatCaseCompleteStatusByCase = async () => {
    const response = await getChatCaseCompleteStatusByCaseIdAPI(caseId)
    if (response?.status_code == 1) {
      setCaseCurrentStatus(response.data)
    } else {
      const message = response?.message ? response.message : 'Something went wrong, please try again!!'
      toast.error(message, { duration: 4000 });
    }
  }

  //#region Alerts
  const handleAlert = (id) => {
    let array = [];
    for (let i = 0; i < alertsArray.length; i++) {
      if ((alertsArray[i].id) == id) {
        array = alertsArray.pop();

      }
    }
    setCount(Math.floor(Math.random() * 1000))
  }

  //#endregion

  const handleClick = () => {
    let url = "/cases";
    if (caseRoleId == 2) {
      return url += "/immigration-cases";
    } else if (caseRoleId == 4) {
      return url += "/broker-cases";
    }
  };

  const [breadcrumbArray, setBreadcrumbArray] = useState([
    { name: 'Home', url: HomepageUrl },
    { name: 'Cases', url: handleClick() },
    { name: 'Case Details', url: '' }
  ]);

  const [breadcrumbArrayForAgents, setBreadcrumbArrayForAgents] = useState([
    { name: 'Home', url: HomepageUrl },
    { name: 'Case Details', url: '' }
  ]);


  const updateDimension = () => {
    setScreenSize(getCurrentDimension());
    var value = getCurrentDimension();
    // set a variable true when screen width reaches <768px
    if (value.width <= 767) {
      setSingleScreen(true)
    } else {
      setSingleScreen(false)
    }
  }

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight
    }
  }

  const toggle = () => {
    setIsModalOpen(isModalOpen => !isModalOpen);
  }
  
  // get case name api  call 
  const GetCaseName = async () => {
    const response = await GetCaseNameApi(caseId);
    if (response.length > 0) {
      setCaseName(response[0].case_name);
      setCaseStatus(response[0].case_status);
    }
  }

  const GetAssociatedMemberListForClientAndCase = async () => {
    const response = await GetAssociatedMemberListForClientAndCaseApi(userId, caseId);

    setAssociatedMemberList(response);

    //condition to add primary applicant on top
    let array = [];
    if (response && response.length) {
      for (let i = 0; i < response.length; i++) {
        if (response[i].is_primary == true) {
          array.push(response[i]);

        }
      }

      for (let i = 0; i < response.length; i++) {
        if (response[i].is_primary != true) {
          array.push(response[i]);

        }
      }

      setAssociatedMemberList(array);
      SetParticipantsDropdownList(array);
    }

  }
  const SetParticipantName = (isPrimary, isSponsor) => {
    if (isPrimary == true && isSponsor == true) {

      return (
        <div className={styles.associatedbuttonblock}>
          <button className="f-16 cu-au" style={{ background: '#32cd32' }}> Primary</button>
          {/* <button style={{ background: '#fbb23c' }}>Sponsor</button> */}
        </div>
      )
    }
    else if (isSponsor == true && isPrimary == false) {
      return (
        <div className={styles.associatedbuttonblock}>
          <button className="f-16 cu-au" style={{ background: '#fbb23c' }}>Sponsor</button>
        </div>
      )
    }
    else if (isPrimary == true && isSponsor == false) {
      return (
        <div className={styles.associatedbuttonblock}>
          <button className="f-16 cu-au" style={{ background: '#32cd32' }}>Primary </button>
        </div>
      )
    }
    else if (isPrimary == false && isSponsor == false) {
      return (
        <div className={styles.associatedbuttonblock}>
          <button className="f-16 cu-au" style={{ background: '#47c7f4' }}>Applicant</button>
        </div>
      )
    }
  }

  const SetParticipantsDropdownList = async (data) => {
    var participantArray = [];
    var userIds = [];
    for (let i = 0; i < data.length; i++) {
      if (data[i].is_primary == true) {
        setSelectedParticipantId({
          label: CommonServices.getUserFullName(data[i]),
          value: Capitalize(data[i].first_name), id: data[i].user_uuid, countryCode: data[i].primary_country_code
        });
        setChangedParticipantUserIds([...ChangedParticipantUserIds, data[i].user_uuid]);
        setCountryCodeOfSelectedMember(Number(data[i].primary_country_code != null ? data[i].primary_country_code : 1));
      }

      /* CLient Want Invited Person Also In Dropdown */
      if (data[i].status != 3) {
        participantArray.push({
          label: CommonServices.getUserFullName(data[i]),
          value: Capitalize(data[i].first_name), id: data[i].user_uuid, countryCode: data[i].primary_country_code
        });
        setParticipantDropdownList(participantArray);
        userIds.push(data[i].user_uuid);
        setParticipantUserIds(userIds);
      }
    }
  }

  const handleParticipantChange = (data) => {
    setSelectedParticipantId(data);
    var array = [];
    array.push(data.id);

    setChangedParticipantUserIds(array);
    if (data.countryCode == null || data.countryCode == '' || data.countryCode == undefined) {
      setCountryCodeOfSelectedMember(91);
    }
    else {
      setCountryCodeOfSelectedMember(data.countryCode);
    }
    setTemplateCount(Math.floor(Math.random() * 100));
    setViewCount(Math.floor(Math.random() * 100));
  }

  const UpdateCaseName = async () => {
    if (caseName.length < 60) {
      const requestBody = {
        name: caseName
      }
      const response = await UpdateCaseNameApi(requestBody, caseId);
      if (response.data.length > 0) {
        setCaseNameGetByLocation(response.data[0].case_name);
      }
      GetAssociatedMemberListForClientAndCase();
      setIsCaseNameEdit(false);
    }
  }
  //#endregion

  //#region JSX
  return (
    <Fragment>
      <div className="d-block mb-2 mb-md-0">
        <CommonBreadcrumb pageName='Case-Details' breadcrumbArray={(userRoleId == 2 || userRoleId == 4 || userRoleId == 1) ? breadcrumbArrayForAgents : breadcrumbArray} />
        {isCaseNameEdit == false ?
          <div className="d-flex justify-start page-heading-text-info">
            <h4 className={`${singleScreen === true ? 'f-18 ' : 'mt-2'}`}>{Capitalize(caseName)}</h4>
            {((userRoleId == 2) || (userRoleId == 4)) &&
              <span className={`edit-text-info ${styles.editLinkStyle} ${singleScreen === true ? 'f-14 mt-0 pb-2' : ''}`} onClick={() => { setIsToastVisible(true); setIsCaseNameEdit(true) }}
              > Edit </span>
            }
          </div> :
          <div className="d-flex justify-start mb-2 mt-2">
            <Form.Group className="mt-0 me-2">
              <Form.Control
                id="case_name"
                name="case_name"
                value={caseName}
                type="text"
                placeholder=""
                onChange={(e) => setCaseName(e.target.value)}
              />
              {caseName.length > 60 &&
                <p style={{ color: "red" }}>Case name max length is 50 </p>
              }
            </Form.Group>
            <Button variant="success" className="mt-0 " type="submit" style={{ maxHeight: "42px" }} onClick={() => { setIsToastVisible(true); UpdateCaseName() }}>Save</Button>
          </div>
        }
      </div>

      {/*Alerts*/}
      <Row className="p-0 ">
        <Col lg={12} sm={12} md={12} xs={12} xl={12}>
          {(alertsArray.length > 0) &&
            alertsArray.reverse().map((item, index) => {
              return (
                <CommonAlert key={index} id={item.id} type={item.type} message={item.message} handleAlert={handleAlert} />
              )
            })
          }
        </Col>
      </Row>

      {/* Case Step Information (Only for Agents) & Not for mobile view */}
      {
        userRoleId != 3 && !singleScreen &&
        <CaseInformationStep
          userId={userId}
          caseId={caseId}
          userRoleId={userRoleId}
          caseName={caseName}
        />
      }

      <Row className="">
        <Col lg={6} md={12} xl={4} sm={12}>
          <Row className="row">
            <Col xl={12} lg={12} md={12} sm={12} >
              <Card className={styles.peopleassociatedateditblock}>
                <Card.Body className={styles.peopleassociatedatedit}>
                  <div className={styles.casesHeadingBlock} >
                    <h3
                      style={{ fontSize: '18px', fontFamily: "Nunito Sans sans-serif", color: "#262B40" }}
                    >Participants
                    </h3>
                  </div>
                  {associateMemberList?.length ?
                    <>
                      {associateMemberList?.map((item, index) => {

                        return (
                          <div className={styles.peopleassociatedcontent} key={index}
                            style={{
                              borderBottom: `${(index < (associateMemberList?.length - 1)) ? '1px solid #D1D7E0' : 'none'}`
                            }}
                          >
                            <div className={styles.associatedcontentleft}>
                              <div style={{ marginRight: "10px", height: "auto" }}>
                                {item.profile_path ?
                                  <img src={item.profile_path} alt="profile image" className="rounded-circle" style={{ minHeight: 50, minWidth: 50, width: 50, height: 50 }} />
                                  :
                                  <div className={`bg-light rounded-circle d-flex justify-content-center align-items-center`} style={{ minHeight: 50, minWidth: 50, width: 50, height: 50 }} >
                                    <p className=" fw-bolder pt-2" style={{ fontSize: "14px" }}>{CommonServices.getNameCharByFullName(CommonServices.getUserFullName(item))}</p>
                                  </div>

                                }
                              </div>
                              <div className={styles.profiletextinfo}>
                                <p className="fw-bold user-name-info d-flex responsive-button "
                                  style={{
                                    fontSize: "16px", justifyContent: "space-between",
                                    alignItems: "center",
                                    cursor: ((item.profile_access == true || item?.is_primary == true) && (userRoleId != 1)) ? 'pointer' : '',
                                  }} variant="primary">
                                  <span
                                    className="mr-2"
                                    style={{ marginRight: '5px' }}
                                    onClick={() => {
                                      ((item.profile_access == true || item?.is_primary == true) && userRoleId != 1) ?
                                        history.push("/admin/clients/edit-client", { userId: item.user_uuid }) :console.log('is false')
                                    }}
                                  >
                                    {CommonServices.getUserFullName(item)}
                                  </span>

                                  {item?.status == 1 ?
                                    <div className={styles.associatedbuttonblock}>
                                      <button className="f-16 cu-au" style={{ background: '#05A677' }}>Invited</button>
                                    </div>
                                    : (item?.status == 3) ?
                                      <div className={styles.associatedbuttonblock}>
                                        <button className="f-16 cu-au" style={{ background: '#FA5252' }}>Rejected</button>
                                      </div>
                                      :
                                      SetParticipantName(item.is_primary, item.is_sponser)
                                  }

                                </p>
                                {item.primary_phone &&
                                  <p className={styles.UserNumbertext} style={{ fontSize: "14px" }}>{item?.primary_country_code ? `+${item?.primary_country_code}` : ''} {item.primary_phone}</p>
                                }
                                {item.email &&
                                  <p style={{ fontSize: "14px" }}>{item.email}</p>
                                }
                              </div>
                            </div>
                          </div>
                        )
                      })}

                    </>
                    :
                    <NoDataFound />
                  }
                  {userRoleId != 1 &&
                    <Button
                      isDisabled={userRoleId == 1 ? true : false}
                      onClick={() => {
                        if (userRoleId != 1 && caseStatus != 3) {
                          setIsToastVisible(true);
                          toggle();
                        }else{
                          setshowCaseCompleteInfoModal(true);
                        }
                      }}
                      className={[styles.AddClientbtn, "f-16 pe-auto"]}>Invite People
                    </Button>
                  }
                  <AddClientModal setCount={setCount} caseId={caseId} toggle={toggle} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
                </Card.Body>
              </Card>
            </Col>


          </Row>
          {((userRoleId == 2 || userRoleId == 1 || userRoleId == 3) && (location.state.isBrokerList != true)) &&
            <EditPayment
              alertsArray={alertsArray}
              setAlertsArray={setAlertsArray}
              handleAlert={handleAlert}
              userUuid={userId}
              caseId={caseId}
              userRoleId={userRoleId}
              caseStatus={caseStatus} />
          }
        </Col>
        <Col lg={6} md={12} xl={4} sm={12} className="document-block-area">

          <Form.Group as={Col} className="mb-2">
            <Select
              classNamePrefix="select"
              isDisabled={false}
              isLoading={false}
              isClearable={false}
              isRtl={false}
              isMulti={false}
              isSearchable={false}
              name="participants-name"
              id={`participants-name`}
              value={selectedParticipantId}
              options={participantDropdownList}
              onChange={(e) => { handleParticipantChange(e) }}
              className="f-16"
            />
          </Form.Group>
          {requestMoreClicked === true &&
            <TemplateForm
              id={userId}
              caseId={caseId}
              isLoading={isLoading}
              setCount={setCount}
              count={count}
              setIsLoading={setIsLoading}
              setRequestMoreClicked={setRequestMoreClicked}
              memberList={associateMemberList}
              ChangedParticipantUserIds={ChangedParticipantUserIds}
              setChangedParticipantUserIds={setChangedParticipantUserIds}
              countryCodeOfSelectedMember={countryCodeOfSelectedMember}
              templateCount={templateCount}
              setTemplateCount={setTemplateCount}
              selectedParticipantId={selectedParticipantId}
              alertsArray={alertsArray}
              setAlertsArray={setAlertsArray}
              handleAlert={handleAlert}
            />
          }
          <ViewClientDocumentModel
            isEditProfile={false}
            userId={selectedParticipantId.id}
            setCount={setCount} count={count} caseId={caseId}
            setRequestMoreClicked={setRequestMoreClicked}
            ChangedParticipantUserIds={ChangedParticipantUserIds}
            setChangedParticipantUserIds={setChangedParticipantUserIds}
            countryCodeOfSelectedMember={countryCodeOfSelectedMember}
            viewCount={viewCount}
            setViewCount={setViewCount}
            alertsArray={alertsArray}
            setAlertsArray={setAlertsArray}
            handleAlert={handleAlert}
            caseStatus={caseStatus}

          />
        </Col>
        <Col lg={6} md={12} xl={4} sm={12}>
          <Row className="row">
            <Col xl={12} lg={12} md={12} sm={12} >
              <UserNotes
                handleAlert={handleAlert}
                alertsArray={alertsArray}
                setAlertsArray={setAlertsArray}
                caseId={caseId} userId={userId} setIsToastVisible={setIsToastVisible} />
            </Col>
          </Row>
        </Col>
      </Row>

      {/* Case Chat Popup */}
      {(userRoleId != 1) &&
        <CaseChatPopup caseId={caseId} caseName={caseName} isArchivedList={caseCurrentStatus} />
      }
   {/* Case Completed Warning pop up modal */}
   <CaseCompeteInfoModal showCaseCompleteInfoModal={showCaseCompleteInfoModal} setshowCaseCompleteInfoModal={setshowCaseCompleteInfoModal}/>
      {isLoading === true && <DataLoading />}
    </Fragment>
  );
 //#endregion
};
export default CaseDetails;
