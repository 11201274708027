import React, { Fragment, useEffect, useState } from 'react';
import { Card, Button, Row, Col, Form, Image } from '@themesberg/react-bootstrap';
import { Dropdown, ButtonGroup } from '@themesberg/react-bootstrap';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { GetClientNotesByIdApi, CreateClientNotesApi, EditClientNotesApi, DeleteNotesApi } from '../../actions/ClientActions';
import { Capitalize } from '../../common-components/StringConversions';
import LockRoundedIcon from '@mui/icons-material/LockRounded';
import LockIcon from '../../assets/img/icons/Airmigrate icons/16px/Lock.png'
import '../../assets/global-css/Index.css';
import View from '../../assets/img/icons/Airmigrate icons/16px/View.png';
import Edit from '../../assets/img/icons/Airmigrate icons/16px/Edit.png';
import Action from '../../assets/img/icons/Airmigrate icons/16px/Action.png';
import Group from '../../assets/img/icons/Airmigrate icons/16px/Group.png';
import Trash from '../../assets/img/icons/Airmigrate icons/16px/Trash.png';
import Calendar from '../../assets/img/icons/Airmigrate icons/16px/Calendar.png';


const UserNotes = ({ handleAlert, alertsArray, setAlertsArray, userId, caseId, }) => {
  const location = useLocation();
  const [multipleNotes, setMultipleNotes] = useState([]);
  const [editingNoteId, setEditingNoteId] = useState(null);
  const [editedNote, setEditedNote] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [userNotes, setUserNotes] = useState('');
  const [count, setCount] = useState(0);

  useEffect(() => {
    // Fetch client notes when the component mounts
    GetClientNotesById(userId);
  }, [count]);

  // Function to format date
  function formatDate(date = new Date()) {
    var day, month, year;

    year = date.getFullYear();
    month = date.getMonth() + 1;
    day = date.getDate();

    if (month < 10) {
      month = '0' + month;
    }

    if (day < 10) {
      day = '0' + day;
    }

    return day + '-' + month + '-' + year;
  }
  var ddmmyyyy = formatDate();
  console.log(ddmmyyyy);

  const GetClientNotesById = async (id) => {
    try {
      setIsLoading(true);
      const clientDetails = await GetClientNotesByIdApi(id, caseId);
      console.log('client notes by id!', clientDetails);
      if (clientDetails.length > 0) {
        setMultipleNotes(clientDetails);
      } else {
        setMultipleNotes([]);
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching client notes:', error);
    }
  };

  const CreateNotes = async () => {

    const requestBody = {
      notes: userNotes,
    };
    setIsLoading(true);
    const clientDetails = await CreateClientNotesApi(requestBody, userId, caseId);
    setIsLoading(false);
    if (clientDetails.status_code === 1) {
      setAlertsArray([...alertsArray, { id: `create-notes${alertsArray.length}`, type: 'success', message: clientDetails.message }]);
      setUserNotes('');
      // GetClientNotesById(userId); // Refresh notes after creation
      setCount(Math.floor(Math.random() * 1000));
    } else {
      setAlertsArray([...alertsArray, { id: `create-notes${alertsArray.length}`, type: 'danger', message: clientDetails.message }]);
    }
    // setTimeout(() => {
    //   handleAlert(`create-notes${alertsArray.length}`);
    // }, 15000)

  };

  const UpdateNotes = async (id) => {
    console.log('id at update', id);
    const requestBody = {
      notes: editedNote,
      id: id,
    };
    try {
      setIsLoading(true);
      console.log('request-body', requestBody);
      const clientDetails = await EditClientNotesApi(requestBody, userId, caseId);

      if (clientDetails.status_code === 1) {
        setAlertsArray([...alertsArray, { id: `update-notes${alertsArray.length}`, type: 'success', message: clientDetails.message }]);

      } else {
        setAlertsArray([...alertsArray, { id: `update-notes${alertsArray.length}`, type: 'danger', message: clientDetails.message }]);

      }
      setCount(Math.floor(Math.random() * 1000));
    } catch (error) {

    }
    // setTimeout(() => {
    //   handleAlert(`update-notes${alertsArray.length}`);
    // }, 15000)
  };


  const DeleteNote = async (id) => {
    const requestBody = {
      id: id,
    };
    try {
      setIsLoading(true);
      const response = await DeleteNotesApi(requestBody, userId, caseId);
      console.log("deletenotes", response);
      setIsLoading(false);
      if (response.status_code === 1) {
        setAlertsArray([...alertsArray, { id: `delete-notes${alertsArray.length}`, type: 'success', message: response.message }]);

        // GetClientNotesById(userId);
      } else {

        setAlertsArray([...alertsArray, { id: `delete-notes${alertsArray.length}`, type: 'danger', message: response.message }]);
      }
      setCount(Math.floor(Math.random() * 1000));

    } catch (error) {
      console.error('Error deleting note:', error);
    }
    // setTimeout(() => {
    //   handleAlert(`delete-notes${alertsArray.length}`);
    // }, 15000)

  };

  const cancelEditNote = () => {
    setEditingNoteId(null);
    setEditedNote('');
  };

  const startEditNote = (noteId, noteContent) => {
    setEditingNoteId(noteId);
    setEditedNote(noteContent);
  };

  const saveEditedNote = (id) => {
    // console.log("I am in", id);
    if (editingNoteId !== null) {
      UpdateNotes(id);
      // Update the state with the edited note content
      setMultipleNotes((prevNotes) =>
        prevNotes.map((note) =>
          note.id === id ? { ...note, notes: editedNote } : note
        )
      );
    }
    cancelEditNote();
  };


  // Function to save the edited note to the state
  const handleEditedNoteChange = (event) => {
    setEditedNote(event.target.value);
  };

  return (
    <Fragment>


      <Row className="row">
        <Col xl={12} lg={12} md={12} sm={12}>
          <Card>
            <Card.Body
            // style={{ padding: 20 }}
            >
              <Row>
                <Col md={12}>
                  <Form.Group id="userNotes">
                    <div className='usernotes-heading'>
                      <Image src={LockIcon} style={{ cursor: "auto", paddingRight: "8px", marginBottom: '5px' }} />
                      <Form.Label>Private notes
                        {/* <LockRoundedIcon style={{paddingLeft:"5px", fontSize:'25px', paddingBottom:'5px'}}/> */}

                      </Form.Label>
                    </div>
                    <Form.Control
                      id="userNotes"
                      name="userNotes"
                      as="textarea"
                      rows="3"
                      value={userNotes}
                      className='f-16'
                      onChange={(e) => setUserNotes(e.target.value)}
                      type="text"
                      placeholder={`Please Type Note Here`}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Button variant="success" className="mt-2 f-16" type="submit" onClick={() => { userNotes.trim() !== '' && CreateNotes() }}>
                Save
              </Button>

            </Card.Body>
          </Card>
          <Card className="custom-card-notes">
            {multipleNotes.map((notes, index) => {
              const createdAtDate = new Date(notes.created_at);
              const formattedDate = formatDate(createdAtDate);
              const formattedTime = createdAtDate.toLocaleTimeString('en-US', {
                hour: '2-digit',
                minute: '2-digit',
              }); // Format: 10:00 AM
              return (
                <Card key={`note-${index}`} className="mb-3">
                  <Card.Body>
                    <div className='user-notes-body'>
                      {/* <Card.Text className="notes_date ">
                        {' '}
                     
                        <Image src={Calendar} className="pe-none" /> {'            '}
                        <span className='fs-small' style={{ fontSize: "16px", marginLeft: '15px', fontWeight: "400px", color: "#4A5073" }}>
                          {formattedDate}
                        </span>


                      </Card.Text> */}

                      <div className='d-flex justify-content-between mb-2'>
                        <div className='  justify-content-start'>
                          <Image src={Calendar} className="pe-none" /> {''}
                          <span className='align-middle' style={{ fontSize: "16px", marginLeft: '10px', fontWeight: "400px", color: "#4A5073" }}>
                            {formattedDate}
                          </span>
                        </div>
                        <span className='justify-content-end align-middle'>
                          <Image src={Edit} className="pe-auto cu-po" onClick={() => { startEditNote(notes.id, notes.notes) }} />{' '}
                          <Image src={Trash} className="pe-auto cu-po" onClick={() => { DeleteNote(notes.id) }} />
                        </span>
                      </div>

                      {/* <div>
                        <Dropdown>
                          <div className="dot-custum-notes-icon">
                            <Dropdown.Toggle variant="transparent" id={`dropdown-button-${index}`} className="text-dark">
                              <MoreVertRoundedIcon />
                            </Dropdown.Toggle>
                          </div>
                          <Dropdown.Menu className='case-details-dropdown'>
                            <Dropdown.Item onClick={() => startEditNote(notes.id, notes.notes)}>Edit</Dropdown.Item>
                            <Dropdown.Item onClick={() => DeleteNote(notes.id)}>Delete</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div> */}
                      {/* <div className="dot-custum-notes-icon">
                        <Dropdown as={ButtonGroup} >
                          <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
                            <span className="icon icon-sm">
                              <Image className="pe-auto" src={Group} />
                            </span>
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="dropdown-action-show">
                            <Dropdown.Item
                              onClick={() => startEditNote(notes.id, notes.notes)}
                            >

                              <Image src={Edit} className="pe-auto" onClick={() => startEditNote(notes.id, notes.notes)}/> Edit
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => DeleteNote(notes.id)}
                            >
                              <Image src={Trash} className="pe-auto"  onClick={() => DeleteNote(notes.id)}/> Delete
                            </Dropdown.Item>
                          </Dropdown.Menu>

                        </Dropdown>
                      </div> */}

                      {/* <div className="dot-custum-notes-icon">
                        <div className='d-flex'>
                          <Image src={Edit} className="pe-auto" onClick={() => startEditNote(notes.id, notes.notes)} />
                          <Image src={Trash} className="pe-auto" onClick={() => DeleteNote(notes.id)} />
                        </div>
                      </div> */}
                      {editingNoteId === notes.id ? (
                        <div>
                          <Form.Control as="textarea" rows={3} value={editedNote} onChange={(e) => handleEditedNoteChange(e)} />
                          <Button variant="success" className="mt-2 update-btn f-16" onClick={() => { saveEditedNote(notes.id) }}>
                            Update
                          </Button>
                          <Button variant="danger" className="mt-2 ms-2 cancel-btn f-16" onClick={() => { cancelEditNote() }}>
                            Cancel
                          </Button>
                        </div>
                      ) : (
                        <Card.Text className='f-16 c-lg ' >{notes.notes}</Card.Text>
                      )}
                    </div>
                  </Card.Body>
                </Card>
              );
            })}
          </Card>
        </Col>
      </Row>


    </Fragment>
  );
};

export default UserNotes;
