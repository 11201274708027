import React, { Fragment } from "react";
import Logo from "../../assets/img/login/loginLogo.svg";
import { InputGroup, Input } from "reactstrap";
import toast, { Toaster } from "react-hot-toast";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { GetCountriesApi, SignInApi } from "../../actions/LoginAction";
import { DataLoading } from "./Loader";
import PhoneInput from "react-phone-input-2";
import "../../views/user-registration/Index.css";
import { RegistrationBrokerApi } from "../../actions/RegistrationAction";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Default_Country_Code, Default_Country, LICENCE_NUMBER_MINIMUM_LIMIT, LICENCE_NUMBER_MAXIMUM_LIMIT } from "../../config.tsx";



import {
  Col,
  Row,
  Card,
  Form,
  Button,
  Container,
  FormGroup,
  Tab,
  Tabs,
} from "@themesberg/react-bootstrap";

import * as Yup from "yup";
import { useFormik } from "formik";

const CommonBrokerRegistraionForm = ({ setAlertsArray, alertsArray }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isPhoneEntered, setIsPhoneEntered] = useState(false);
  const [isCountryCodeEntered, setIsCountryCodeEntered] = useState(false);
  const [first_name, setfirst_name] = useState("");
  const [last_name, setlast_name] = useState("");
  const [estate_license_number, setestate_license_number] = useState("");
  const [isEmailEntered, setIsEmailEntered] = useState(false);
  const history = useHistory();

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const LoginFormValidationSchema = Yup.object().shape({
    first_name: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("First name is required")
      .matches(
        /^[a-zA-Z][a-zA-Z ]*$/,
        "Numbers and Special characters are not allowed"
      ),
    last_name: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Last name is required")
      .matches(
        /^[a-zA-Z][a-zA-Z ]*$/,
        "Numbers and Special characters are not allowed"
      ),
    estate_license_number: Yup.string()
      .required("License number is required")
      .min(LICENCE_NUMBER_MINIMUM_LIMIT, `Minimum ${LICENCE_NUMBER_MINIMUM_LIMIT} characters required`).max(LICENCE_NUMBER_MAXIMUM_LIMIT, `Maximum ${LICENCE_NUMBER_MAXIMUM_LIMIT} characters allowed`),
    primary_country_code: Yup.string().required("Please select country code "),
    email: Yup.string()
      .required("Email is required")
      .email("Email is not valid")
      .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,20}$/i, "Invalid Email"),
    primary_phone: Yup.string()
      .required("Mobile number is required")
      .matches(phoneRegExp, "Mobile number is not valid")
      .min(10, "Phone Number must be at least 10 characters")
      .max(12),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      first_name: "",
      last_name: "",
      estate_license_number: "",
      primary_phone: "",
      primary_country_code: Default_Country_Code, /* default value for country code */
    },
    enableReinitialize: true,
    validationSchema: LoginFormValidationSchema,
    onSubmit: (values) => {
      // console.log("values inside formik", values);
      onSubmitCall(values);
    },
  });

  const onSubmitCall = async (data) => {
    var registrationRequestModel;
    if (isPhoneEntered === true && isCountryCodeEntered === true) {
      registrationRequestModel = {
        ...data,
        primary_country_code: formik.values.primary_country_code,
      };
    }
    else {
      registrationRequestModel = data;
    }
    setIsLoading(true);

    const response = await RegistrationBrokerApi(registrationRequestModel);
    setIsLoading(false);
    if (response.status_code === 1) {
      localStorage.setItem("registrationDetails", JSON.stringify(response.data));
      // toast.success(response.message, { duration: 4000 });

      history.push("/registration-verify-otp", {
        primary_country_code: response.data.primary_country_code,
        phone: response.data.primary_phone,
      });
    } else {
      console.error(response.message, 'response failed')
      setAlertsArray([...alertsArray, { id: `${'registration'}${alertsArray.length}`, type: 'danger', message: response?.message }]);
    }

    // toast.error(response.message, { duration: 4000 });

  };

  const HandlePhoneInputChange = (value, country) => {

    if (country.dialCode == "") {
      setIsCountryCodeEntered(false);
    } else {
      formik.setFieldValue("primary_country_code", country.dialCode);
      setIsCountryCodeEntered(true);
    }
    var primary_phone = value.slice(country.dialCode.length);
    if (primary_phone == "") {
      formik.setFieldValue("primary_phone", primary_phone);
      setIsPhoneEntered(false);
    } else {

      formik.setFieldValue("primary_phone", primary_phone);
      setIsPhoneEntered(true);
    }
  };

  const handleSubmit = async () => {
    // Validation and submission logic
  };
  return (
    <Fragment>
      <Form className="registration-custom-form" onSubmit={formik.handleSubmit}>
        {/* First name */}
        <FormGroup className="alternative-input-block">
          <InputGroup className="input-group-alternative">
            <Input
              className={`form-control-alternative ${formik.touched.first_name && formik.errors.first_name ? "is-invalid" : ""}`}
              id="first_name"
              name="first_name"
              value={formik.values.first_name}
              type="text"
              placeholder="First Name*"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </InputGroup>
          {formik.touched.first_name && formik.errors.first_name ? (
            <small className="text-danger text-left">{formik.errors.first_name}</small>
          ) : null}
        </FormGroup>

        {/* Last name */}
        <FormGroup className="alternative-input-block">
          <InputGroup className="input-group-alternative">
            <Input
              className={`form-control-alternative ${formik.touched.last_name && formik.errors.last_name ? "is-invalid" : ""}`}
              id="last_name"
              name="last_name"
              value={formik.values.last_name}
              type="text"
              placeholder="Last Name*"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </InputGroup>
          {formik.touched.last_name && formik.errors.last_name ? (
            <small className="text-danger text-left">{formik.errors.last_name}</small>
          ) : null}
        </FormGroup>

        <FormGroup className="alternative-input-block">
          <InputGroup className="input-group-alternative">
            <Input
              className={`form-control-alternative ${formik.touched.email && formik.errors.email ? "is-invalid" : ""}`}
              id="email"
              name="email"
              value={formik.values.email}
              type="text"
              placeholder="Email*"
              onChange={(e) => {
                console.log("email value", e.target.value);
                formik.setFieldValue("email", e.target.value);
                if (e.target.value == "") {
                  setIsEmailEntered(false);
                } else setIsEmailEntered(true);
              }}
              onBlur={formik.handleBlur}
            />
          </InputGroup>
          {formik.touched.email && formik.errors.email ? (
            <small className="text-danger text-left">{formik.errors.email}</small>
          ) : null}
        </FormGroup>

        {/* Phone number */}
        <FormGroup className="alternative-input-block">
          <PhoneInput
            className={`form-control-alternative ${formik.touched.email && formik.errors.email ? "is-invalid" : ""}`}
            country={Default_Country}
            preferredCountries={["in", "ca", "us"]}
            autoFormat
            containerClass={`form-control-alternative ${formik.touched.primary_phone && formik.errors.primary_phone ? "is-invalid" : ""}`}
            inputClass={`PhoneInputInput`}
            buttonClass={"country-dropdown"}
            dropdownClass={'country-dropdown-container'}
            enableSearch
            countryCodeEditable={false}
            style={{ paddingRight: "10px" }}
            onChange={(value, country) => {
              HandlePhoneInputChange(value, country);
            }}
            placeholder="Phone Number"
            autocompleteSearch={true}
            inputProps={{
              name: "primary_phone",
              required: false,
            }}
          />
          {formik.touched.primary_phone && formik.errors.primary_phone ? (
            <small className="text-danger text-left">{formik.errors.primary_phone}</small>
          ) : null}
        </FormGroup>


        {/* license Number */}
        <FormGroup className="alternative-input-block">
          <InputGroup className="input-group-alternative">
            <Input
              className={`form-control-alternative  ${formik.touched.estate_license_number && formik.errors.estate_license_number ? " is-invalid" : " "}`}
              id="estate_license_number"
              placeholder="License Number*"
              type="text"
              value={formik.values.estate_license_number}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </InputGroup>
          {formik.touched.estate_license_number &&
            formik.errors.estate_license_number ? (
            <div className="text-danger-block">
              <small className="text-danger text-left">
                {formik.errors.estate_license_number}
              </small>
            </div>
          ) : null}
        </FormGroup>

        {/* Button */}
        <div className="sendOtpContainer " >
          <Button
            className="otp-btn"
            color="#0184FE"
            onClick={handleSubmit}
            // disabled={(phone?.length <= 9)}
            type="submit"
          >
            Send OTP to Phone
          </Button>
        </div>
        <div className="termConditionContainer" onClick={() => history.push('/terms-condition')}>
          <p className="termConditionText">
            By continuing you agree to Air Migrate
          </p>
          <a className="termConditionTextSecond">
            Terms of Use & Privacy Policy
          </a>
        </div>
      </Form>
      <Toaster position="top-right" reverseOrder={false} />
    </Fragment>
  );
};

export default CommonBrokerRegistraionForm;
