import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isInstallmentAvailable: false,
}

export const PaymentSlice = createSlice({
    name: 'myPaymentModule',
    initialState,
    reducers: {
        setInstallment: (state, value) => {
            console.log('state and value', value);
            state.isInstallmentAvailable = value.payload;
        },
    },
})

// Action creators are generated for each case reducer function
export const { setInstallment, } = PaymentSlice.actions

export default PaymentSlice.reducer