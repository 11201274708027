import React, { useState, useEffect } from "react";
import { Nav, Tab, Modal } from "@themesberg/react-bootstrap";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-hot-toast";
import { Col, Row, Card, Form, Button } from "@themesberg/react-bootstrap";
import "react-datetime/css/react-datetime.css";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import * as Mui from "@mui/material";

//Local imports, vender css
import "../../../scss/volt.scss";
import {
    CreateTemplateApi,
    GetDocumentApi,
    GetTemplateNamesApi,
    GetCheckListForDocumetApi,
    deletCheckListForDocumetApi,
} from "../../../actions/TemplateActions";
import ORImage from "../../../assets/img/templates/ORImage.png";
import SelectedTemplateView from "../../../components/air-migrate/SelectedTemplate";
import { Capitalize } from "../../../common-components/StringConversions";
import { CreatePaymentIsVisibleApi } from "../../../actions/PaymentActions";
import TrashIcon from "../../../assets/img/icons/Airmigrate icons/512px/Trash.png";
import CommonConfirmBox from "../../../common-components/CommonConfirmBox";
import infoIcon from "../../../assets/img/icons/Airmigrate icons/512px/Info.png";
import messageHelper from "../../../utilities/helpers/messageHelper";
import CheckListSuggetionModal from "../../../components/air-migrate/DocumentChecklistSuggetionModal";
import { UpdateCheckListSuggetionApi } from "../../../actions/TemplateActions";

const TemplateForm = ({
    handleAlert,
    alertsArray,
    setAlertsArray,
    selectedParticipantId,
    templateCount,
    setTemplateCount,
    countryCodeOfSelectedMember,
    count,
    ChangedParticipantUserIds,
    setChangedParticipantUserIds,
    memberList,
    caseId,
    setCount,
    GetClientDocumentCount,
    documentId,
    id,
    isTemplateSelected,
    isLoading,
    setIsLoading,
    setRequestMoreClicked,
}) => {
    //#region State and variables
    const history = useHistory();
    const [templateId, setTemplateId] = useState("");
    const [role, setRole] = useState("");
    const [templateNames, setTemplateNames] = useState([]);
    const [userUuid, setUserUuid] = useState("");
    const [templateDetails, setTemplateDetails] = useState([]);
    const [isPreviewVisible, setIsPreviewVisible] = useState(false);
    const [isPreviewClicked, setIsPreviewClicked] = useState(false);
    const [formikFields, setFormikFields] = useState({});
    const options = [
        { value: "chocolate", label: "Chocolate" },
        { value: "strawberry", label: "Strawberry" },
        { value: "vanilla", label: "Vanilla" },
    ];
    const [isCreateTemplateSelected, setIsCreateTemplateSelected] =
        useState(true);
    const [showFileds, setShowFields] = useState(false);
    const [selectTemplateId, setSelectTemplateId] = useState("");
    const [isCheckBoxSelected, setIsCheckBoxSelected] = useState(false);
    const [documentList, setDocumentList] = useState([]);
    const [results, setResults] = useState([]);
    var [keys, setKeys] = useState(["otherDocuments"]);
    const [dynamicSchema, setDynamicSchema] = useState();
    const [participantDropdownList, setParticipantDropdownList] = useState([]);
    const [participantsUserIds, setParticipantUserIds] = useState([]);
    // const [selectedParticipantId, setSelectedParticipantId] = useState({});
    // const [countryCodeOfSelectedMember, setCountryCodeOfSelectedMember] = useState(91);
    const [formOptions, setFormOptions] = useState([]);
    const [documentCheckList, setDocumentCheckList] = useState([]);
    const [sendDocumentCheckList, setSendDocumentChecklist] = useState([]);
    const [otherDocsName, setOtherDocsName] = useState();
    const [checkListString, setCheckListString] = useState();
    const [isAllChecked, setIsAllChecked] = useState(false);

    // Variable for confirm box
    const [openConfirmBox, setOpenConfirmBox] = useState(false);
    const [confirmBoxValue, setConfirmBoxValue] = useState(false);
    const handleConfirmBox = () => setOpenConfirmBox(!openConfirmBox);
    const [clickedDocumentId, setClickedDocumentId] = useState();
    // suggestion modal consts here
    const [checkListSuggetions, setCheckListSuggetions] = useState("");
    const [showcheckListSuggetionModal, setShowcheckListSuggetionModal] =
        useState(false);
    const handlecheckListSuggetionModal = () =>
        setShowcheckListSuggetionModal(!showcheckListSuggetionModal);
    //#endregion

    //#region  useEffect
    useEffect(() => {
        //get agent id from local storage

        // console.log('setSElected', selectTemplateId);
        setUserUuid(id);

        //get roleid from localStorage
        const role = JSON.parse(localStorage.getItem("roleId"));
        setRole(role);

        //get documents, get template names  api call
        // GetTemplateNames();
        GetDocumentNames(countryCodeOfSelectedMember);

        // if (memberList.length) {
        //     console.log("MemberList", memberList);
        //     SetParticipantsDropdownList(memberList);
        // }
    }, [count, templateCount]);

    useEffect(() => {
        /* Document check list api call */
        GetDocumentCheckList();
    }, [count]);
    //#endregion
    // console.log('otherDocsName', otherDocsName);
    //
    //#region Formik section

    //validation schema
    const TemplateFormValidationSchemaForSelectTemplateOption =
        Yup.object().shape({
            selectTemplate: Yup.string().required("Please select template name"),
        });

    const dynamicValidationGenerator = (formField) => {
        console.log("formField", formField);
        console.log("formikField", formikFields);
        //dynamic required validation for required field
        const validateObj = {};
        formField.map((field) => {
            var key = `${field.options.key}`;
            {
                field.options.required &&
                    Object.assign(validateObj, {
                        [key]: Yup.array().required(`${field.options.label} is required`),
                    });
            }
        });
        return validateObj;
    };

    const dynamicValidationGeneratorIncludingSponsors = (formField) => {
        console.log("formField", formField);
        console.log("formikField", formikFields);
        //dynamic required validation for required field
        const validateObj = {};
        formField.map((field) => {
            var key = `${field.options.key}-for-applicants`;
            {
                field.options.required &&
                    Object.assign(validateObj, {
                        [key]: Yup.string().required(`${field.options.label} is required`),
                    });
            }
        });
        formField.map((field) => {
            var key = `${field.options.key}-for-sponsors`;
            {
                field.options.required &&
                    Object.assign(validateObj, {
                        [key]: Yup.string().required(`${field.options.label} is required`),
                    });
            }
        });
        console.log("validateObj", validateObj);
        return validateObj;
    };

    const templateSchema = (formField) =>
        Yup.object().shape({
            ...dynamicValidationGenerator(formField),
        });

    const templateSchemaIncludingSponsors = (formField) =>
        Yup.object().shape({
            templateName: Yup.string()
                .min(2, "Too Short!")
                .max(50, "Too Long!")
                .required("Template name is required"),
            description: Yup.string().required("Please enter description"),
            ...dynamicValidationGeneratorIncludingSponsors(formField),
        });

    //created a formik variable to add validations in form
    const formik = useFormik({
        initialValues: formikFields,

        //enableReinitialize will set agent details in formik fields
        enableReinitialize: true,
        // validationSchema: templateSchema(results),
        onSubmit: (values) => {
            // console.log("formik.handlesubmit", values);
            onSubmitCall(values);
        },
    });

    //request body for create template
    const RequestBodyForCreateTemplate = (data) => {
        let array = [];
        let sponsors = [];
        let count = 0;

        //Function to get value field value of selected field.
        const GetFieldValue = (array, key) => {
            var value = "";
            array.map((item, index) => {
                if (item.key === key) {
                    // console.log('value with key', item.key,item.value);
                    value = item.value;
                }
            });
            return value;
        };

        const GetOptions = (array) => {
            // console.log("array at form", array);
            var value = [];
            if (Array.isArray(array) == true) {
                array?.map((item, index) => {
                    item.value = item.originalValue;
                    // delete item.label;
                    // delete item.originalValue;
                    value.push(item);
                });
                // console.log("value at options in formik", value, array);
                return value;
            } else return value;
        };

        //Create dynamic array for applicants
        Object.keys(formikFields).map((item, index) => {
            // console.log('item', item);
            if (index < results.length) {
                var values = GetFieldValue(
                    results[index].options.option,
                    formik.values[item]
                );
                var options = GetOptions(formik.values[item]);

                // console.log("valueeeeeeeeeee", values);
                var obj = {
                    key: results[index].options.key,
                    label: results[index].options.label,
                    option: options,
                    // [
                    //     {
                    //         key: formik.values[item],
                    //         value: values
                    //     },
                    // ]
                };
                // console.log("object field", obj);
                array.push(obj);
            }
        });

        var finaleString;
        if (data.otherDocuments) {
            finaleString =
                data.otherDocuments +
                (data.otherDocuments && sendDocumentCheckList.length ? "," : "") +
                sendDocumentCheckList.join(",");
            setCheckListString(finaleString);
        } else {
            finaleString = sendDocumentCheckList.join(",");
            setCheckListString(finaleString);
        }
        // console.log('finaleString', finaleString);
        const requestBody = {
            case_id: caseId,
            // users_uuid: ChangedParticipantUserIds,
            users_uuid: [selectedParticipantId.id],
            others: finaleString,
            case_options: array,
        };
        // console.log('requestBody', requestBody);
        return requestBody;
    };
    //function created to pass agent detail and call agent api.
    const onSubmitCall = async (templateData) => {
        if (ChangedParticipantUserIds.length) {
            const createTemplateRequestModal =
                RequestBodyForCreateTemplate(templateData);
            // console.log("createTemplateRequestModal", createTemplateRequestModal)
            // CreatePaymentIsVisible(true);
            let templateResponse;
            // console.log("formik value after upload", templateData);
            setIsLoading(true);
            templateResponse = await CreateTemplateApi(createTemplateRequestModal);
            setIsLoading(false);
            setRequestMoreClicked(false);

            if (templateResponse.status_code === 1) {
                setAlertsArray([
                    ...alertsArray,
                    {
                        id: `create-document${alertsArray.length}`,
                        type: "success",
                        message: templateResponse.message,
                    },
                ]);
                setCount(Math.floor(Math.random() * 100));
            } else {
                setAlertsArray([
                    ...alertsArray,
                    {
                        id: `create-document${alertsArray.length}`,
                        type: "danger",
                        message: templateResponse.message,
                    },
                ]);
            }

            return templateResponse;
        } else {
            toast.error("No participant available!", { duration: 3000 });
        }
    };

    //#endregion

    //#region Functions
    async function GetDocumentNames(countryCode) {
        setIsLoading(true);
        // AgentListApi is a method placed inside actions contains agent list api integration code
        const documents = await GetDocumentApi(countryCode);
        // console.log('documents', documents);
        setResults(documents);
        var fields = formikFields;

        //For applicants
        for (let i = 0; i < documents.length; i++) {
            const index = `${documents[i].options.key}`;
            fields = { ...fields, [index]: "" };
        }
        fields = {
            ...fields,
            otherDocuments: "",
        };

        setIsLoading(false);
        setFormikFields(fields);
        setIsLoading(false);
    }
    // const SetParticipantsDropdownList = async (data) => {
    //     var participantArray = [];
    //     var userIds = [];
    //     for (let i = 0; i < data.length; i++) {
    //         if (data[i].is_primary == true) {
    //             setSelectedParticipantId({ label: (data[i].first_name == null || data[i].first_name == '') ? `User ` : Capitalize(data[i].first_name), value: Capitalize(data[i].first_name), id: data[i].user_uuid, countryCode: data[i].primary_country_code });
    //             setChangedParticipantUserIds([...ChangedParticipantUserIds, data[i].user_uuid]);
    //             // setCountryCodeOfSelectedMember(Number(data[i].primary_country_code));
    //         }
    //         if (data[i].status != 1) {
    //             participantArray.push({ label: (data[i].first_name == null || data[i].first_name == '') ? `User ` : Capitalize(data[i].first_name), value: Capitalize(data[i].first_name), id: data[i].user_uuid, countryCode: data[i].primary_country_code });
    //             setParticipantDropdownList(participantArray);
    //             userIds.push(data[i].user_uuid);
    //             setParticipantUserIds(userIds);
    //             // setChangedParticipantUserIds(userIds[0])
    //         }

    //     }
    // }

    // const handleParticipantChange = (data) => {
    //     console.log("handleChange", data);
    //     setSelectedParticipantId(data);
    //     var array = [];
    //     array.push(data.id);
    //     console.log('handleChange 2', array);
    //     setChangedParticipantUserIds(array);
    //     if (data.countryCode == null || data.countryCode == '' || data.countryCode == undefined) {
    //         // setCountryCodeOfSelectedMember(91);
    //         GetDocumentNames(91);
    //     }
    //     else {
    //         // setCountryCodeOfSelectedMember(data.countryCode);
    //         GetDocumentNames(data.countryCode);
    //     }
    // }

    const CreatePaymentIsVisible = async (value) => {
        if (value) {
            const response = await CreatePaymentIsVisibleApi(caseId);
        }
    };
    /* ----------------------------------------------------------document checklist functions starts here------------------------------------*/
    /* get checklist of document for requesting in bulk */
    const GetDocumentCheckList = async () => {
        const response = await GetCheckListForDocumetApi();
        if (response.status_code === 1) {
            const newItems = response.data.map((item) => {
                return { ...item, isChecked: false };
            });
            setDocumentCheckList(newItems);
        } else {
            console.error("something went wrong");
        }
    };

    // Function to handle individual checkbox toggle
    const handleCheck = (items, clickedIndex) => {
        var data = [];
        var isCheck = false;
        for (let index = 0; index < documentCheckList.length; index++) {
            const element = documentCheckList[index];
            if (clickedIndex == index) {
                element.isChecked = !element.isChecked;
                isCheck = element.isChecked;
                if (element.isChecked == false) {
                    if (sendDocumentCheckList.length > 0) {
                        for (const doc of sendDocumentCheckList) {
                            if (sendDocumentCheckList.includes(element.document_option)) {
                                sendDocumentCheckList.splice(
                                    sendDocumentCheckList.indexOf(element.document_option),
                                    1
                                );
                            }
                        }
                    }
                }
            }
            data.push(element);
        }
        setDocumentCheckList([...data]);
        if (isCheck) {
            setSendDocumentChecklist([
                ...sendDocumentCheckList,
                items.document_option,
            ]);
        }
        if (
            documentCheckList.length == sendDocumentCheckList.length + isCheck ? 1 : 0
        ) {
            setIsAllChecked(true);
        } else {
            setIsAllChecked(false);
        }
    };

    // Function to handle "Select All" button click
    const SelectAllCheckList = () => {
        var newString = [];
        const newItems = documentCheckList.map((item) => {
            if (!isAllChecked) {
                newString.push(item.document_option);
            }
            return { ...item, isChecked: !isAllChecked };
        });
        setDocumentCheckList(newItems);
        setSendDocumentChecklist([...newString]);
        setIsAllChecked(!isAllChecked);
    };

    /* remove document checklist items  */
    const removeDocumentCheckItem = async (id) => {
        let Id = { id };
        setConfirmBoxValue(false);
        const response = await deletCheckListForDocumetApi(Id);
        if (response.status_code === 1) {
            GetDocumentCheckList();
            setAlertsArray([
                ...alertsArray,
                {
                    id: `remove-document${alertsArray.length}`,
                    type: "success",
                    message: response.message,
                },
            ]);
            setCount(Math.floor(Math.random() * 100));
        } else {
            setAlertsArray([
                ...alertsArray,
                {
                    id: `remove-document${alertsArray.length}`,
                    type: "danger",
                    message: response.message,
                },
            ]);
            setCount(Math.floor(Math.random() * 100));
        }
    };

    // useEffect for confirm box value
    useEffect(() => {
        if (confirmBoxValue) {
            handleConfirmBox();
            removeDocumentCheckItem(clickedDocumentId);
        }
    }, [confirmBoxValue]);

    // Function to handle suggetions in suggetion modal
    const handelCheckListSuggestion = (text, id) => {
        setCheckListSuggetions(text === null ? " " : text);
        setClickedDocumentId(id);
        setShowcheckListSuggetionModal(true);
    };

    // function to update document checklist suggestions
    const updateCheckListSuggestion = async () => {
        let data = {
            document_id: clickedDocumentId,
            text: checkListSuggetions,
        };
        const response = await UpdateCheckListSuggetionApi(data);
        if (response.status_code === 1) {
            // GetDocumentCheckList();
            handlecheckListSuggetionModal();
            setAlertsArray([
                ...alertsArray,
                {
                    id: `document-suggetion${alertsArray.length}`,
                    type: "success",
                    message: response.message,
                },
            ]);
            setCount(Math.floor(Math.random() * 100));
        } else {
            setAlertsArray([
                ...alertsArray,
                {
                    id: `document-suggetion${alertsArray.length}`,
                    type: "danger",
                    message: response.message,
                },
            ]);
            setCount(Math.floor(Math.random() * 100));
        }
    };

    //#endregion
    // console.log("documentList", documentCheckList)
    //#region JSX
    return (
        <>
            {/* checlist suggetion modal*/}
            <CheckListSuggetionModal
                showcheckListSuggetionModal={showcheckListSuggetionModal}
                setShowcheckListSuggetionModal={setShowcheckListSuggetionModal}
                checkListSuggetions={checkListSuggetions}
                setCheckListSuggetions={setCheckListSuggetions}
                updateCheckListSuggestion={updateCheckListSuggestion}
                handlecheckListSuggetionModal={handlecheckListSuggetionModal}
            />
            <Form
                onSubmit={formik.handleSubmit}
                onReset={formik.handleReset}
                className="mt-3"
            >
                <Row>
                    <Col xs={12} xl={12} classname="p-0" style={{ padding: "0px" }}>
                        {/*Create or edit template options section*/}
                        <Card
                            border="light"
                            className="bg-white document-card shadow-sm mb-4"
                            style={{ margin: "0px 12px" }}
                        >
                            <Card.Body>
                                <Row>
                                    <Col md={12} className="select-documents-tab-block p-0">
                                        <div className="d-flex document-card-inner active-card justify-content-center">
                                            <h5 className="mb-0 mt-0 text-center c-lg f-18">
                                                Request Documents
                                            </h5>
                                        </div>
                                        {/* <Form.Group as={Col} className="mb-2">
                                            <Select
                                                classNamePrefix="select"
                                                isDisabled={false}
                                                isLoading={false}
                                                isClearable={false}
                                                isRtl={false}
                                                isMulti={false}
                                                isSearchable={true}
                                                name="participants-name"
                                                id={`participants-name`}
                                                value={selectedParticipantId}
                                                options={participantDropdownList}
                                                onChange={(e) => { handleParticipantChange(e) }}
                                            />
                                        </Form.Group> */}

                                        {/* Tabs Section*/}

                                        {results.map((field, index) => {
                                            var documentArray = [];
                                            keys.push(`${field.options.key}`);
                                            formikFields[`${field.options.key}`] = "";
                                            for (let j = 0; j < field.options.option.length; j++) {
                                                documentArray.push({
                                                    label: field.options.option[j].key,
                                                    value: field.options.option[j].key,
                                                    originalValue: field.options.option[j].value,
                                                    key: field.options.option[j].key,
                                                    placeholder: field.options.option[j].placeholder,
                                                });
                                            }

                                            return (
                                                <Row key={index}>
                                                    <Col md={12}>
                                                        <Form.Group as={Col} className="mt-2">
                                                            <Form.Label>{field.options.label}</Form.Label>
                                                            <Select
                                                                classNamePrefix="select"
                                                                isDisabled={false}
                                                                isLoading={false}
                                                                isClearable={false}
                                                                isMulti={true}
                                                                isRtl={false}
                                                                isSearchable={true}
                                                                name={`${field.options.key}`}
                                                                id={`${field.options.key}`}
                                                                options={documentArray}
                                                                value={formik.values[field.options.key]}
                                                                onChange={(option) => {
                                                                    console.log("option", option);
                                                                    formik.setFieldValue(
                                                                        `${field.options.key}`,
                                                                        option
                                                                    );
                                                                }}
                                                            />
                                                            {formik.touched[`${field.options.key}`] &&
                                                                formik.errors[`${field.options.key}`] ? (
                                                                <small className="text-danger">
                                                                    {formik.errors[`${field.options.key}`]}
                                                                </small>
                                                            ) : null}
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            );
                                        })}
                                    </Col>
                                    {/* <Col md={2} /> */}
                                </Row>
                                <Row>
                                    {/* <Col md={2} /> */}
                                    <Col md={12} className="p-0">
                                        <Form.Group className="mb-3 mt-2">
                                            <Form.Label>
                                                Add each document’s name and separate them by comma{" "}
                                            </Form.Label>
                                            <Form.Control
                                                name="otherDocuments"
                                                id="otherDocuments"
                                                as="textarea"
                                                rows="3"
                                                type="text"
                                                placeholder="eg: Passport, Bank Statements for 2 months, Tax statements for 1 year etc"
                                                value={formik.values.otherDocuments}
                                                onChange={(e) => {
                                                    formik.handleChange(e);
                                                    setOtherDocsName(e.target.value);
                                                }}
                                                onBlur={formik.handleBlur}
                                            />
                                        </Form.Group>
                                    </Col>
                                    {/* <Col md={2} /> */}
                                </Row>
                                {/* Document checklist*/}
                                <Row>
                                    <Form>
                                        {documentCheckList.length > 0 && (
                                            <>
                                                <Form.Check // prettier-ignore
                                                    type="checkbox"
                                                    // id={`default-Select-all`}
                                                    label="Select All"
                                                    checked={isAllChecked}
                                                    onChange={SelectAllCheckList}
                                                />
                                                <hr />
                                                {documentCheckList.map((item, index) => {
                                                    return (
                                                        <div
                                                            key={`default-${item.id}`}
                                                            className="mb-3 d-flex justify-content-between align-items-center"
                                                        >
                                                            <div className="d-flex align-items-center">
                                                                <Form.Check // prettier-ignore
                                                                    type="checkbox"
                                                                    id={`default-${item.id}`}
                                                                    label={
                                                                        item.document_option
                                                                            ? item.document_option
                                                                            : ""
                                                                    }
                                                                    checked={item.isChecked}
                                                                    onChange={() => {
                                                                        handleCheck(item, index);
                                                                    }}
                                                                />
                                                                <img
                                                                    className="mx-2 cu-po"
                                                                    src={infoIcon}
                                                                    alt=""
                                                                    height={12}
                                                                    width={12}
                                                                    onClick={() => {
                                                                        handelCheckListSuggestion(
                                                                            item.reference_suggest_text,
                                                                            item.id
                                                                        );
                                                                    }}
                                                                />
                                                            </div>
                                                            <img
                                                                onClick={() => {
                                                                    item.isChecked ? void 0 : handleConfirmBox();
                                                                    setClickedDocumentId(item.id);
                                                                }}
                                                                src={TrashIcon}
                                                                alt="Remove Icon"
                                                                height={20}
                                                                width={20}
                                                                title="Remove"
                                                                className="cu-po"
                                                            />
                                                        </div>
                                                    );
                                                })}
                                            </>
                                        )}
                                    </Form>
                                </Row>
                                {isPreviewVisible === true &&
                                    templateDetails.length > 0 &&
                                    isCreateTemplateSelected === false && (
                                        <SelectedTemplateView
                                            selectedId={selectTemplateId}
                                            templateDetails={templateDetails}
                                        />
                                    )}
                                <Row>
                                    <Col lg={12} md={12} sm={12} className="p-0">
                                        <div className="mt-3 save-template-btn-block d-flex">
                                            {/* {isCreateTemplateSelected === false ?
                                                <Button variant="success" type="submit">Use template</Button> : */}
                                            <Button
                                                style={{ cursor: "pointer" }}
                                                variant="success"
                                                className="save-template-btn"
                                                type="submit"
                                                onClick={() => {
                                                    console.log("hello user");
                                                }}
                                            >
                                                Save{" "}
                                            </Button>
                                            <Button
                                                style={{
                                                    cursor: "pointer",
                                                    background: "rgb(251 83 83)",
                                                    border: "none",
                                                    marginLeft: "5px",
                                                }}
                                                variant="success"
                                                className="save-template-btn"
                                                type="reset"
                                                onClick={() => {
                                                    setRequestMoreClicked(false);
                                                }}
                                            >
                                                Cancel{" "}
                                            </Button>
                                            {/* } */}
                                        </div>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Form>
            {
                // Confirm box for Romeve requested document
                openConfirmBox && (
                    <CommonConfirmBox
                        openConfirmBox={openConfirmBox}
                        handleConfirmBox={handleConfirmBox}
                        setConfirmBoxValue={setConfirmBoxValue}
                        title="Are you sure do you want to remove document?"
                    />
                )
            }
        </>
    );
    //#endregion
};
export default TemplateForm;
