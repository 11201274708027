import React, { Fragment, useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import QuestionAnswerRoundedIcon from '@mui/icons-material/QuestionAnswerRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import CalendarTodayRoundedIcon from '@mui/icons-material/CalendarTodayRounded';
import { Col, Row, Card, Image, OverlayTrigger, Tooltip, Table } from '@themesberg/react-bootstrap';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-hot-toast';

//Local imports, core styles, vendor styles
import { GetClientListApi } from "../../actions/ClientActions";
import { DataLoading } from "./Loader";
import { Capitalize } from '../../common-components/StringConversions';
import NoDataFound from '../../components/air-migrate/NoDataFound';
import CustomPagination from "../../common-components/CustomPagination";
import styles from '../../views/cases/Index.module.css';
import '../../assets/global-css/Index.css';
import "../../scss/volt.scss";
import "react-datetime/css/react-datetime.css";
import { PROFILE_PATH } from "../../config.tsx";

const CaseListTable = ({ search }) => {

    //#region States and variables
    const history = useHistory();
    const [clientList, setClientList] = useState([]);
    const [copiedClientList, setCopiedClientList] = useState([]);
    const [startingIndex, setStartingIndex] = useState(1);
    const [pageLimit, setPageLimit] = useState(10);
    const [isLoading, setIsLoading] = useState();

    //pagination relates states
    const [activeItem, setActiveItem] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalClientCount, setTotalClientCount] = useState(0);
    const [count, setCount] = useState(0);
    const [consultantName, setConsultantName] = useState(Capitalize(localStorage.getItem('userName')));


    // Login user uuid for chat link
    const [loginUserId, setLoginUserId] = useState('')

    //#endregion

    //#region Styles
    const filterStyle = {
        marginRight: '2px',
        marginLeft: '3px',
        // color: '#0184fe'
    }
    //#endregion

    //#region useEffect
    useEffect(() => {

        let amUserId = localStorage.getItem('amUserId')
        if (amUserId) {
            setLoginUserId(amUserId)
        }
        GetClientList();
    }, [search, activeItem])
    //#endregion

    //#region Functions
    const TableRow = (props) => {
        const { consultant_uuid, chat_link_verify_status, created_at, email, profile_path,
            email_verified, first_name, last_name, phone_is_verify, primary_phone, user_uuid, case_keyword } = props;

        // const PROFILE_PATH = 'https://air-migrate-public-bucket.s3.ca-central-1.amazonaws.com/default-broker-image.png';
        return (
            <tr>
                <td>Permanent Residency Case</td>
                <td>
                    <div className={styles.casesprofilecontentblock}>
                        {/* <div className={styles.tablecasesprofileimg}> */}
                        <div>
                            <a href="#top" className="user-avatar">
                                <Image src={PROFILE_PATH} className="rounded-circle me-3" />
                            </a>
                        </div>

                        {/* </div> */}
                        <div className={styles.tablecasesprofilename}><a href="">Akash Gour</a></div>
                    </div>
                    <div className={styles.casesprofilecontentblock}>
                        <div >
                            <a href="#top" className="user-avatar">
                                <Image src={PROFILE_PATH} className="rounded-circle me-3" />
                            </a>
                        </div>
                        <div className={styles.tablecasesprofilename}><a href="">Manisha</a></div>
                    </div>
                </td>
                <td>
                    <div className={styles.casesprofilecontentblock}>
                        <div >
                            <a href="#top" className="user-avatar">
                                <Image src={PROFILE_PATH} className="rounded-circle me-3" />
                            </a>
                        </div>
                        <div className={styles.tablecasesprofilename}><a href="">Yogesh Harle</a></div>
                    </div>
                    <div className={styles.casesprofilecontentblock}>
                        <div >
                            <a href="#top" className="user-avatar">
                                <Image src={PROFILE_PATH} className="rounded-circle me-3" />
                            </a>
                        </div>
                        <div className={styles.tablecasesprofilename}><a href="">Muskan</a></div>
                    </div>
                    <div className={styles.casesprofilecontentblock}>
                        <div >
                            <a href="#top" className="user-avatar">
                                <Image src={PROFILE_PATH} className="rounded-circle me-3" />
                            </a>
                        </div>
                        <div className={styles.tablecasesprofilename}><a href="">Nikita Rathore</a></div>
                    </div>
                    <div className={styles.casesprofilecontentblock}>
                        <div >
                            <a href="#top" className="user-avatar">
                                <Image src={PROFILE_PATH} className="rounded-circle me-3" />
                            </a>
                        </div>
                        <div className={styles.tablecasesprofilename}><a href="">Urvashi</a></div>
                    </div>
                </td>
                <td>
                    <div className={styles.statusbutton}>
                        <button>In Progress</button>
                    </div>
                </td>
                <td>
                    <div className={styles.actionbuttongroup}>
                        <div className={styles.chatbtn}><button>Chat</button></div>
                        <div className={styles.viewbtn}
                            onClick={()=> {history.push('/')}}
                        ><button>View Details</button></div>
                    </div>
                </td>


            </tr>
        );
    };

    const GetClientList = async () => {
        setIsLoading(true);
        const client = await GetClientListApi(startingIndex, pageLimit, search);
        setIsLoading(false);

        if (client.status_code === 1) {
            setClientList(client.data[0]?.results);
            setCopiedClientList(client.data[0]?.results);

            setTotalClientCount(client.data[0]?.count);
            const pages = Math.ceil(client.data[0]?.count / pageLimit);
            console.log(' total pages', pages);
            setTotalPages(pages);

        } else {
            toast.error(client.message, { duration: 4000, },);
        }
        console.log("client list", client);

    }

    const SortingAsc = (data, key) => {
        console.log("data and key", data, key);
        let sortedData;

        if (key == 'first_name') {
            sortedData = data.sort(function (a, b) {
                let x = a.first_name?.toLowerCase();
                let y = b.first_name?.toLowerCase();

                //compare the word which is comes first
                if (x > y) { return 1; }
                if (x < y) { return -1; }
                return 0;
            });

        }
        else if (key == 'case_keyword') {
            sortedData = data.sort(function (a, b) {
                let x = a.case_keyword?.toLowerCase();
                let y = b.case_keyword?.toLowerCase();

                //compare the word which is comes first
                if (x > y) { return 1; }
                if (x < y) { return -1; }
                return 0;
            });
        }
        else if (key == 'created_at') {
            sortedData = data.sort(function (a, b) {
                var keyA = new Date(a.created_at),
                    keyB = new Date(b.created_at);
                // Compare the 2 dates
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
            });

        }
        console.log("sorted data", sortedData);
        setCopiedClientList(sortedData);
        setCount(count => count + 1);
    }

    const SortingDes = (data, key) => {
        console.log("data and key", data, key);
        let sortedData;

        if (key == 'first_name') {
            sortedData = data.sort(function (a, b) {
                let x = a.first_name?.toLowerCase();
                let y = b.first_name?.toLowerCase();

                //compare the word which is comes first
                if (x < y) { return 1; }
                if (x > y) { return -1; }
                return 0;
            });

        }
        else if (key == 'case_keyword') {
            sortedData = data.sort(function (a, b) {
                let x = a.case_keyword?.toLowerCase();
                let y = b.case_keyword?.toLowerCase();

                //compare the word which is comes first
                if (x < y) { return 1; }
                if (x > y) { return -1; }
                return 0;
            });
        }
        else if (key == 'created_at') {
            sortedData = data.sort(function (a, b) {
                var keyA = new Date(a.created_at),
                    keyB = new Date(b.created_at);
                // Compare the 2 dates
                if (keyA > keyB) return -1;
                if (keyA < keyB) return 1;
                return 0;
            });

        }
        console.log("sorted data", sortedData);
        setCopiedClientList(sortedData);
        setCount(count => count + 1);
    }

    const DummyContentRow = async () => {
        return (
            <tr>
                <td>Permanent Residency Case</td>
                <td>
                    <div className={styles.casesprofilecontentblock}>
                        {/* <div className={styles.tablecasesprofileimg}> */}
                        <div>
                            <a href="#top" className="user-avatar">
                                <Image src={PROFILE_PATH} className="rounded-circle me-3" />
                            </a>
                        </div>

                        {/* </div> */}
                        <div className={styles.tablecasesprofilename}><a href="">Akash Gour</a></div>
                    </div>
                    <div className={styles.casesprofilecontentblock}>
                        <div >
                            <a href="#top" className="user-avatar">
                                <Image src={PROFILE_PATH} className="rounded-circle me-3" />
                            </a>
                        </div>
                        <div className={styles.tablecasesprofilename}><a href="">Manisha</a></div>
                    </div>
                </td>
                <td>
                    <div className={styles.casesprofilecontentblock}>
                        <div >
                            <a href="#top" className="user-avatar">
                                <Image src={PROFILE_PATH} className="rounded-circle me-3" />
                            </a>
                        </div>
                        <div className={styles.tablecasesprofilename}><a href="">Yogesh Harle</a></div>
                    </div>
                    <div className={styles.casesprofilecontentblock}>
                        <div >
                            <a href="#top" className="user-avatar">
                                <Image src={PROFILE_PATH} className="rounded-circle me-3" />
                            </a>
                        </div>
                        <div className={styles.tablecasesprofilename}><a href="">Muskan</a></div>
                    </div>
                    <div className={styles.casesprofilecontentblock}>
                        <div >
                            <a href="#top" className="user-avatar">
                                <Image src={PROFILE_PATH} className="rounded-circle me-3" />
                            </a>
                        </div>
                        <div className={styles.tablecasesprofilename}><a href="">Nikita Rathore</a></div>
                    </div>
                    <div className={styles.casesprofilecontentblock}>
                        <div >
                            <a href="#top" className="user-avatar">
                                <Image src={PROFILE_PATH} className="rounded-circle me-3" />
                            </a>
                        </div>
                        <div className={styles.tablecasesprofilename}><a href="">Urvashi</a></div>
                    </div>
                </td>
                <td>
                    <div className={styles.statusbutton}>
                        <button>In Progress</button>
                    </div>
                </td>
                <td>
                    <div className={styles.actionbuttongroup}>
                        <div className={styles.chatbtn}><button>Chat</button></div>
                        <div className={styles.viewbtn}><button>View Details</button></div>
                    </div>
                </td>


            </tr>
        )
    }
    //#endregion

    //#region JSX
    return (
        <Fragment>
            {isLoading === true ? <DataLoading /> :
                ((clientList.length == 0) || (clientList.length == undefined) || (clientList.length == null)) ?
                    <NoDataFound /> :
                    <Card border="light" className="shadow-sm mb-4 ">
                        <Card.Body className="pb-0">
                            <Table responsive className="table-centered custom-table-responsive table-nowrap rounded mb-0">
                                <thead className="thead-light border-bottom">
                                    <tr>
                                        <th className="border-0" style={{ color: "#262b40" }}>Case Name <span>
                                            <FontAwesomeIcon icon={faArrowUp} style={filterStyle}
                                                onClick={() => {
                                                    console.log("sort");
                                                    SortingAsc(copiedClientList, "first_name")
                                                }}
                                            /></span>

                                            <span>
                                                <FontAwesomeIcon icon={faArrowDown}
                                                    onClick={() => {
                                                        console.log("sort");
                                                        SortingDes(copiedClientList, "first_name")
                                                    }}
                                                /></span></th>
                                        <th className="border-0" style={{ color: "#262b40" }}>Applicants<span>
                                            <FontAwesomeIcon icon={faArrowUp} style={filterStyle}
                                                onClick={() => {
                                                    console.log("sort");
                                                    SortingAsc(copiedClientList, "case_keyword")

                                                }}
                                            /></span>
                                            <span>
                                                <FontAwesomeIcon icon={faArrowDown}
                                                    onClick={() => {
                                                        console.log("sort");
                                                        SortingDes(copiedClientList, "case_keyword")
                                                    }}
                                                /></span>
                                        </th>
                                        <th className="border-0" style={{ color: "#262b40" }}>Sponsors<span>
                                            <FontAwesomeIcon icon={faArrowUp} style={filterStyle}
                                                onClick={() => {
                                                    console.log("sort");
                                                    SortingAsc(copiedClientList, "created_at")
                                                }}
                                            /></span>
                                            <span className="">
                                                <FontAwesomeIcon icon={faArrowDown}
                                                    onClick={() => {
                                                        console.log("sort");
                                                        SortingDes(copiedClientList, "created_at")
                                                    }}
                                                /></span>
                                        </th>
                                        <th className="border-0" style={{ color: "#262b40" }}>Status
                                        </th>
                                        <th className="border-0" style={{ color: "#262b40" }}>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {copiedClientList.map(client => <TableRow key={`client-${client.consultant_uuid}`} {...client} />)}
                                    {/* <tr>
                                        <td>Permanent Residency Case</td>
                                        <td>
                                            <div className={styles.casesprofilecontentblock}>
                                                
                                                <div>
                                                    <a href="#top" className="user-avatar">
                                                        <Image src={PROFILE_PATH} className="rounded-circle me-3" />
                                                    </a>
                                                </div>

                                         
                                                <div className={styles.tablecasesprofilename}><a href="">Akash Gour</a></div>
                                            </div>
                                            <div className={styles.casesprofilecontentblock}>
                                                <div >
                                                    <a href="#top" className="user-avatar">
                                                        <Image src={PROFILE_PATH} className="rounded-circle me-3" />
                                                    </a>
                                                </div>
                                                <div className={styles.tablecasesprofilename}><a href="">Manisha</a></div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className={styles.casesprofilecontentblock}>
                                                <div >
                                                    <a href="#top" className="user-avatar">
                                                        <Image src={PROFILE_PATH} className="rounded-circle me-3" />
                                                    </a>
                                                </div>
                                                <div className={styles.tablecasesprofilename}><a href="">Yogesh Harle</a></div>
                                            </div>
                                            <div className={styles.casesprofilecontentblock}>
                                                <div >
                                                    <a href="#top" className="user-avatar">
                                                        <Image src={PROFILE_PATH} className="rounded-circle me-3" />
                                                    </a>
                                                </div>
                                                <div className={styles.tablecasesprofilename}><a href="">Muskan</a></div>
                                            </div>
                                            <div className={styles.casesprofilecontentblock}>
                                                <div >
                                                    <a href="#top" className="user-avatar">
                                                        <Image src={PROFILE_PATH} className="rounded-circle me-3" />
                                                    </a>
                                                </div>
                                                <div className={styles.tablecasesprofilename}><a href="">Nikita Rathore</a></div>
                                            </div>
                                            <div className={styles.casesprofilecontentblock}>
                                                <div >
                                                    <a href="#top" className="user-avatar">
                                                        <Image src={PROFILE_PATH} className="rounded-circle me-3" />
                                                    </a>
                                                </div>
                                                <div className={styles.tablecasesprofilename}><a href="">Urvashi</a></div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className={styles.statusbutton}>
                                                <button>In Progress</button>
                                            </div>
                                        </td>
                                        <td>
                                            <div className={styles.actionbuttongroup}>
                                                <div className={styles.chatbtn}><button>Chat</button></div>
                                                <div className={styles.viewbtn}><button>View Details</button></div>
                                            </div>
                                        </td>


                                    </tr> */}
                                </tbody>
                            </Table>
                            <CustomPagination activeItem={activeItem} setActiveItem={setActiveItem} totalPages={totalPages} setStartingIndex={setStartingIndex} />
                        </Card.Body>
                    </Card>
            }
        </Fragment>
    )
    //#endregion

}
export default CaseListTable;

