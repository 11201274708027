import React, { Fragment, useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { Card, Image, Table, Button, Dropdown, ButtonGroup } from '@themesberg/react-bootstrap';
import { DocumentsListForClientOnlyApi } from "../../../actions/ClientActions";
import "react-datetime/css/react-datetime.css";
//Local imports, core styles, vendor styles
import { DataLoading } from "../../../components/air-migrate/Loader.jsx";
import { Capitalize } from "../../../common-components/StringConversions.jsx";
import NoDataFound from '../../../components/air-migrate/NoDataFound';
import CustomPagination from "../../../common-components/CustomPagination";
import styles from '../../../views/requests/Index.module.css';
import '../../../assets/global-css/Index.css';
import "../../../scss/volt.scss";
import { PROFILE_PATH } from "../../../config.tsx";
import PdfIcon from "../../../assets/img/allDocumentList/pdfIcon.svg";
import docStyle from "../../../views/view-all-documents/Index.module.css";
import GiveAccessModal from "../components/GiveAccessModal.jsx";
import tableStyle from './Index.module.css'
import FileUploadModal from "../../../components/air-migrate/FileUploadModal.jsx";
import DocumentViewModal from "../../../components/air-migrate/DocumentViewModal.jsx";
import CommonServices from "../../../common-components/CommonServices.jsx";
// Icons img imports
import Download from '../../../assets/img/icons/Airmigrate icons/512px/Download document.png';
import View from '../../../assets/img/icons/Airmigrate icons/512px/View.png';
import Reupload from '../../../assets/img/icons/Airmigrate icons/512px/Reupload.png';
import Group from '../../../assets/img/icons/Airmigrate icons/512px/Group.png';
import { GetLinkToDownloadDocumentWithAlertApi } from "../../../actions/FileUploadActions.jsx";

const UserDocumentTable = ({ search, isSearchChange, singleScreen, alertsArray, setAlertsArray, setCount, count }) => {
    //#region States and variables
    //Alert states
    const [modalAlertsArray, setModalAlertsArray] = useState([]);
    const [documentList, setDocumentList] = useState([]);
    const [startingIndex, setStartingIndex] = useState(1);
    const [pageLimit, setPageLimit] = useState(10);
    const [isLoading, setIsLoading] = useState();
    //pagination relates states
    const [activeItem, setActiveItem] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalDocumentCount, setTotalDocumentCount] = useState(0);
    const [previousActiveItemValue, setPreviousItemActiveValue] = useState(1);

    //#region useEffect
    useEffect(() => {
        GetDocumentsListById();
        if (modalAlertsArray) {
            setAlertsArray(modalAlertsArray);
        }
    }, [search, count, activeItem, modalAlertsArray])
    const [currentRequestApplicants, setCurrentRequestApplicants] = useState([])
    // consts for give access modal
    const [showAccess, setShowAccess] = useState(false);
    const hideGiveAccessModal = () => setShowAccess(false);
    const showGiveAccessModal = (docId, data) => {
        setShowAccess(true);
        setcurrentDocId(docId);
        setCurrentRequestApplicants(data)
    };
    const [showDocModal, setShowDocModal] = useState(false);
    const viewHandleClose = () => setShowDocModal(false);
    const viewHandleOpen = (docId) => {
        setShowDocModal(true);
        setcurrentDocId(docId);
    };
    // consts for  file Uplaod Modal here
    const [currentDocId, setcurrentDocId] = useState(null);
    const [showUpload, setShowUpload] = useState(false);
    const uploadHandleClose = () => setShowUpload(false);
    const uploadHandleShow = (docId) => {
        setShowUpload(true);
        setcurrentDocId(docId);
    };
    const hideChildTable = {
        display: 'none'
    }

    //#region Functions (all document list table body)
    const handleTableCollapse = (parentId, childId) => {
        const childElement = document.getElementById(childId);
        const parentElement = document.getElementById(parentId);
        console.log("childElement, parentElement and content", parentId, childId, childElement, parentElement);
        if (parentElement.innerHTML === '+') {
            childElement.style.display = 'table-row';
            parentElement.innerHTML = '-';
        }
        else {
            childElement.style.display = 'none';
            parentElement.innerHTML = '+';
        }
    }

    // function to download document
    const DownloadDocument = async (docId) => {
        let url = `view-document`;
        const response = await GetLinkToDownloadDocumentWithAlertApi(url, docId);
        if (response?.status_code == 1) {
            setAlertsArray([...alertsArray, { id: `download-document${alertsArray.length}`, type: 'success', message: response?.message }]);
            const downloadUrl = response?.data[0].url;
            let alink = document.createElement("a");
            alink.href = downloadUrl;
            alink.click();
        }
        else {
            setAlertsArray([...alertsArray, { id: `download-document${alertsArray.length}`, type: 'danger', message: response?.message }]);
        }

        setCount(Math.floor(Math.random() * 1000));
    }

    //#region Table component for desktop 
    const TableBodyDocumentList = (props) => {
        const { document_option, document_type, id, required, status, uploaded, created_at, size, url, requests_applicants, suggestion, updated_at } = props;
        return (<>
            <tr className={docStyle.documentListRow}>
                <td style={{ paddingLeft: 10 }}>
                    <div className={styles.casesprofilecontentblock} >
                        <div>
                            <img src={PdfIcon} alt="pdfLogo" height={45} width={45} style={{ minWidth: 45, minHeight: 45, marginRight: 5 }} />
                        </div>
                        <div
                        >
                            <p>{Capitalize(document_type)}</p>
                            <small style={{ color: '#B9B9B9' }}>{(size == null || size == undefined || size == '') ? '' : Number(size).toFixed(3) + ' MB'}</small>
                        </div>
                    </div>
                </td>
                <td>
                    <div
                    >
                        <p>{Capitalize(document_option)}</p>
                    </div>
                </td>
                <td>
                    <div
                    >
                        <div >
                            <p>{suggestion}</p>
                        </div>
                    </div>

                </td>
                <td style={{ minWidth: 'fit-content', maxWidth: '229px', textAlign: 'center', overflow: 'hidden' }}>
                    {((requests_applicants !== null) && (requests_applicants !== undefined) && (requests_applicants.length > 0)) ? (
                        <div className="d-flex align-items-center mb-3">
                            {requests_applicants?.slice(0, 5).map((user, index) => {
                                return (
                                    <div key={index} onClick={() => { showGiveAccessModal(id, requests_applicants) }} style={{ minWidth: 'fit-content' }}>
                                        {user.profile_path ? (
                                            <img src={user.profile_path ? user.profile_path : PROFILE_PATH} alt="" className={tableStyle.giveAccessImage} />
                                        ) : (

                                            <div className={`bg-light  ${tableStyle.giveAccessImage}`}>
                                                <strong className=" fw-bolder small">{CommonServices.getNameCharByFullName((user.first_name ? user?.first_name : 'user') + ' ' + (user.last_name ? user?.last_name : ''))}</strong>
                                            </div>
                                        )}
                                    </div>
                                )
                            })
                            }
                        </div>
                    ) : (
                        <Button className={tableStyle.giveAccessImage} title="Add Access" onClick={() => { showGiveAccessModal(id, requests_applicants) }}><FontAwesomeIcon icon={faUserPlus} /></Button>
                    )
                    }
                </td>
                <td>
                    <div
                    >
                        {((updated_at !== null) && (updated_at !== undefined) && (updated_at !== '')) ?
                            <p>{updated_at?.includes(' ') === true ? updated_at?.split(' ')[0] : updated_at?.split('T')[0]}</p> :
                            <p>Not available</p>

                        }
                    </div>
                </td>
                <td>
                    <Dropdown as={ButtonGroup} >
                        <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
                            <span className="icon icon-sm">
                                <Image src={Group} style={{ cursor: "pointer" }} className="" height={15} />
                            </span>
                        </Dropdown.Toggle>
                        {url &&
                            <Dropdown.Menu className="dropdown-action-show">
                                <Dropdown.Item
                                    onClick={() => { viewHandleOpen(id) }}
                                >
                                    <Image src={View} style={{ cursor: "pointer", marginRight: '0px' }} width={20} /> View
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => { { DownloadDocument(id) } }}>
                                    <Image src={Download} style={{ cursor: "pointer", marginRight: '5px' }} className="document-list-icon-size" />Download
                                </Dropdown.Item>
                                <Dropdown.Item
                                    onClick={() => { uploadHandleShow(id) }}
                                >
                                    <Image src={Reupload} style={{ cursor: "pointer", marginRight: '5px' }} className="document-list-icon-size" /> Reupload
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        }

                    </Dropdown>
                </td>

            </tr>
        </>
        );
    };
//#endregion  table component for desktop end 

//#region table component for mobile 
   const MobileTableDocumentList = (props) => {
        return (
            <table className="table">
                <thead className="mobile-thead">
                    <tr>
                        <th ></th>
                        <th className="f-14">Document Type</th>
                        <th className="mobile-table-action-th f-14" >Action</th>
                    </tr>
                </thead>
                {
                    documentList.map(props => {
                        const { document_option, document_type, id, required, status, uploaded, created_at, size, url, requests_applicants, suggestion, updated_at } = props;
                        return (
                            <tbody key={`client-${id}`}>
                                <tr>
                                    <td className=""><span className="plus-icon" id={`expandChildTable-${id}`}
                                        onClick={() => handleTableCollapse(`expandChildTable-${id}`, `childTableRow-${id}`)}
                                    >+</span></td>
                                    <td>
                                        <div className={styles.casesprofilecontentblock} >
                                            <div>
                                                <img src={PdfIcon} alt="pdfLogo" height={45} width={45} style={{ minWidth: 45, minHeight: 45, marginRight: 5 }} />
                                            </div>
                                            <div className={[docStyle.commonTypographyForRow]}>
                                                <p className="f-16 c-lg">{document_type ? Capitalize(document_type) : "Not available"}</p>
                                                <small style={{ color: '#B9B9B9' }}>{(size == null || size == undefined || size == '') ? 'size not available' : Number(size).toFixed(3) + ' MB'}</small>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="mobile-table-action-td">

                                        <Dropdown as={ButtonGroup} >
                                            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
                                                <span className="icon icon-sm">

                                                    <Image src={Group} className="pe-auto" height={15} />
                                                </span>
                                            </Dropdown.Toggle>
                                            {url &&
                                                <Dropdown.Menu className="dropdown-action-show">
                                                    <Dropdown.Item
                                                        onClick={() => { viewHandleOpen(id) }}
                                                    >
                                                        <Image src={View} style={{ cursor: "pointer", marginRight: '0px' }} width={20} /> View
                                                    </Dropdown.Item>
                                                    <Dropdown.Item onClick={() => { { DownloadDocument(id) } }}>
                                                        <Image src={Download} style={{ cursor: "pointer", marginRight: '5px' }} className="document-list-icon-size" />Download
                                                    </Dropdown.Item>
                                                    <Dropdown.Item className="f-16 pe-auto "
                                                        onClick={() => { uploadHandleShow(id) }}
                                                    >
                                                        <Image src={Reupload} className=" pa-r-5 document-list-icon-size" /> Reupload
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            }

                                        </Dropdown>
                                    </td>
                                </tr>
                                <tr id={`childTableRow-${id}`} style={hideChildTable}>
                                    <td colSpan="3">
                                        <div className="text-wrap ">
                                            <h6 className="f-16 c-dg">Document Title : {" "}
                                                <span className="f-16 c-lg text-wrap fw-normal">{((document_option == null) || (document_option == '')) ? 'Not available' : Capitalize(document_option)}</span>
                                            </h6>
                                        </div>
                                        <div className="text-wrap ">
                                            <h6 className="f-16 c-dg">Help Text : {" "}
                                                <span className="f-16 c-lg text-wrap fw-normal">{suggestion ? suggestion : 'Not available'}</span></h6>
                                        </div>
                                        <div className="text-wrap ">
                                            <h6 className="f-16 c-dg">Uploaded Date : {" "}
                                                <span className="f-16 c-lg text-wrap fw-normal">{updated_at?.includes(' ') === true ? updated_at?.split(' ')[0] : updated_at?.split('T')[0]}</span></h6>
                                        </div>
                                        <div className="text-wrap ">

                                            {((requests_applicants !== null) && (requests_applicants !== undefined) && (requests_applicants.length > 0)) ?
                                                <h6 className="f-16 c-dg">Current access : {" "}
                                                    <div className={tableStyle.giveAccessImgsBlock}>
                                                        {requests_applicants?.slice(0, 5).map((user, index) => {
                                                            return (
                                                                <div key={index} onClick={() => { showGiveAccessModal(id, requests_applicants) }}  >
                                                                    {user.profile_path ? (
                                                                        <img src={user.profile_path ? user.profile_path : PROFILE_PATH} alt="" className={tableStyle.giveAccessImage} />
                                                                    ) : (
                                                                        <div className={`bg-light  ${tableStyle.giveAccessImage}`}>
                                                                            <strong className=" fw-bolder small">{CommonServices.getNameCharByFullName((user.first_name ? user?.first_name : 'user') + ' ' + (user.last_name ? user?.last_name : ''))}</strong>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            )
                                                        })
                                                        }
                                                    </div>
                                                </h6>
                                                :
                                                <h6 className="f-16 c-dg text-wrap">Current access : {" "}
                                                    <Button className={tableStyle.giveAccessImage} title="Add Acess" onClick={() => { showGiveAccessModal(id, requests_applicants) }}><FontAwesomeIcon icon={faUserPlus} /></Button>
                                                </h6>
                                            }

                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        )
                    }
                    )
                }
            </table>
        )
    }
    const GetDocumentsListById = async () => {
        if (((search == '') && (isSearchChange == false)) || (activeItem != previousActiveItemValue)) {
            setIsLoading(true);
        }

        let documentsList = await DocumentsListForClientOnlyApi(startingIndex, pageLimit, search);
        if (documentsList[0].results.length > 0) {
            setDocumentList(documentsList[0]?.results);
            setTotalDocumentCount(documentsList[0]?.count);
            let results = documentsList[0]?.results;
            // to get updated access users  array into give access modal
            if (showAccess) {
                results.map((result, index) => {
                    if (currentDocId === result.id) {
                        setCurrentRequestApplicants(result.requests_applicants)
                    }
                });
            }
        }
        else {
            setDocumentList([]);
        }
        const pages = Math.ceil(documentsList[0]?.count / pageLimit);
        setTotalPages(pages);
        setIsLoading(false);
    }
    //#endregion table component for mobile 

    //#region Common table heading 
    return (
        <Fragment>
            {isLoading === true ? <DataLoading /> :
                ((documentList?.length == null) || (documentList?.length == undefined) || (documentList?.length == null) || (documentList?.length == 0)) ?
                    <NoDataFound /> :
                    <>
                        {singleScreen === false ?
                            <Card border="light" className="shadow-sm mb-4 ">
                                <Card.Body className="pb-0 custom-table-body">
                                    <Table responsive className="table-centered custom-table-responsive table-nowrap rounded mb-0">
                                        <thead className="thead-light border-bottom custom-table-heading">
                                            <tr>
                                                <th className="border-0 c-lg f-14" >Document Type</th>
                                                <th className="border-0 c-lg f-14" >Document Title</th>
                                                <th className="border-0 c-lg f-14" >Help Text</th>
                                                <th className="border-0 c-lg f-14" >Current Access</th>
                                                <th className="border-0 c-lg f-14" >Uploaded Date</th>
                                                <th className="border-0 c-lg f-14" >Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {documentList.map(item => (
                                                <TableBodyDocumentList key={item.id} {...item} />
                                            ))
                                            }
                                        </tbody>
                                    </Table>
                                    <div className="pagenation-custom-block">
                                        <div className="pagination-msg">
                                            {`Showing ${startingIndex} to ${Math.min(
                                                startingIndex + pageLimit - 1,
                                                totalDocumentCount
                                            )} of ${totalDocumentCount} entries`}
                                        </div>
                                        <CustomPagination isSingleScreen={false} activeItem={activeItem} setActiveItem={setActiveItem} totalPages={totalPages} setStartingIndex={setStartingIndex} />
                                    </div>

                                </Card.Body>
                            </Card>
                            :
                            <div className="bg-white"> <MobileTableDocumentList />
                                <div className="pagenation-custom-block pagination-padding">
                                    <div className="pagination-msg f-14">
                                        {`Showing ${startingIndex} to ${Math.min(
                                            startingIndex + pageLimit - 1,
                                            totalDocumentCount
                                        )} of ${totalDocumentCount} entries`}
                                    </div>
                                    <CustomPagination isSingleScreen={true} activeItem={activeItem} setActiveItem={setActiveItem} totalPages={totalPages} setStartingIndex={setStartingIndex} />
                                </div>
                            </div>
                        }
                    </>
            }
            <div>
                {/* Give Access Modal Component */}
                {showAccess &&
                    <GiveAccessModal giveAccesDetailsArray={currentRequestApplicants} show={showAccess} handleClose={hideGiveAccessModal} documentId={currentDocId} FunctionToRefresh={GetDocumentsListById} />
                }
                {/* FIle Upload Modal component */}
                {showUpload &&
                    <FileUploadModal show={showUpload} handleClose={uploadHandleClose} documentId={currentDocId} FunctionToRefreshAfterUpload={GetDocumentsListById} setModalAlertsArray={setModalAlertsArray} modalAlertsArray={modalAlertsArray} />
                }
                {/* Document View Modal here */}
                {showDocModal &&
                    <DocumentViewModal show={showDocModal} handleClose={viewHandleClose} documentId={currentDocId} />
                }

            </div>
        </Fragment>
    )
    //#endregion common table heading end
}
export default UserDocumentTable;

