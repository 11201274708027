import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { GetLinkToViewDocumentApi } from '../../actions/FileUploadActions';
import { GetLinkToViewDocumentForAgentApi } from '../../actions/CustomerAction';
import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const DocumentViewModal = ({ show, handleClose, documentId, isMyProfileDocs, isCustomerTypeView, userUuid }) => {
    //#region  conts
    const [url, setURL] = useState("");
    //#endregion conts

    // useEffect for get view of the file.
    useEffect(() => {
        if (documentId) {
            getActualLink(documentId);
        }
        else if (isCustomerTypeView) {
            getVisaDocumentURl(userUuid)
        }
        else {
            handleClose();
            console.error('Couldn\'t find a valid document ID');
            return;
        }
    }, []);

    // functon to get actual link for viewing document
    const getActualLink = async (documentId) => {
        setURL('')
        let URL = `view-document`;
        if (isMyProfileDocs) {
            URL = `agent-view-document`;
        }
        const res = await GetLinkToViewDocumentApi(URL, documentId);
        if (res?.status_code == 1) {
            setURL(res?.data[0].url);
        } else {
            handleClose()
        }
    }
    // function to get visa document url for view
    const getVisaDocumentURl = async (userUuid) => {
        setURL('')
        let URL = `agent-view-visa-document`;
        const res = await GetLinkToViewDocumentForAgentApi(URL, userUuid);
        if (res?.status_code == 1) {
            setURL(res?.data[0].url);
        } else {
            handleClose()
        }
    }
    // Code to open document into new page 
    useEffect(() => {
        if (url) {
            window.open(url)
            handleClose()
        }
    }, [handleClose, url])

    //#region render start
    return (
        url ?
            <div className='view-modal-div'>
                <Modal show={show} onHide={handleClose} size={'md'} className='document-view-modal' centered >
                    <>
                        <Modal.Header closeButton>
                            <Modal.Title className='f-18 c-lg'>View Document</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {/* <object width="100%" height="100%" data={url} type="application/pdf" >
                        <iframe
                            src={url + '#view=Fit&#zoom=0'}
                            className='docIframe'
                            style={{ width: '100%', height: '100%' }}
                            title="Document"
                            type="application/pdf"
                        />
                    </object> */}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose} className='f-18 c-lg'>
                                Close
                            </Button>
                        </Modal.Footer>
                    </>
                </Modal>
            </div>
            : null
    );
};
//#region render end
export default DocumentViewModal;