import React, { useState, useEffect } from 'react';
import {
    Modal
} from "@themesberg/react-bootstrap";
/* Local Inputs */
import styles from "../../views/my-profile/Index.module.css";
import OtpInput from 'react-otp-input';
import { verifyPhoneEmailApi } from '../../actions/MyProfileActions';
import CommonAlert from '../../common-components/CommonAlert';
import messageHelper from '../../utilities/helpers/messageHelper';

// Main Otp verify Modal component 
const OtpVerifyModal = ({ showOtpVerifyModal, setSHowOtpVerifyModal, verifyColumn, handleVerifyButtonPress, GetMyProfileData }) => {
    //  State to hold otp
    const [otpValue, setOtpvalue] = useState(' ');
    // state for handling submit state
    const [isSubmitting, setIsSubmitting] = useState(false)
    //  Function to handle the open /close of verify Otp modal
    const handleOtpModalClose = () => setSHowOtpVerifyModal(false);
    //  Function  to handle OTP input change
    const handleChange = (otpValue) => {
        setOtpvalue(otpValue);
    }
    //Alert states and functions
    const [alertsArray, setAlertsArray] = useState([]);
    const [count, setCount] = useState(0);
    const handleAlert = (id) => {
        let array = [];
        for (let i = 0; i < alertsArray.length; i++) {
            if ((alertsArray[i].id) == id) {
                // console.log('Hi', alertsArray[i].id, id);
                array = alertsArray.splice(i, 1);
            }
        }
        setCount(Math.floor(Math.random() * 1000))
    }

    // useeffect to render the page while count changes
    useEffect(() => { }, [count]);

    // function  to handle otp send button click
    const handleOtpVerification = async (e) => {
        e.preventDefault();
        setIsSubmitting(true)
        let dataTosend = {
            "verify_column_name": verifyColumn,
            "otp": otpValue
        }
        try {
            let response = await verifyPhoneEmailApi(dataTosend);
            if (response.status_code === 1) {
                setIsSubmitting(false);
                handleOtpModalClose();
                GetMyProfileData();
            } else {
                setIsSubmitting(false);
                setAlertsArray([...alertsArray, { id: `${'otp'}${alertsArray.length}`, type: 'danger', message: response?.message }]);
            }
        } catch (err) {
            setIsSubmitting(false);
            console.error(messageHelper('error'), err);
        }
    }

    // function to handel the resend otp send click
    const handleResendOtp = () => {
        handleVerifyButtonPress(verifyColumn);
    };
    //#region render start
    return (
        <Modal show={showOtpVerifyModal} contentClassName={styles.mainModalContainer} onHide={handleOtpModalClose} centered >
            <div className={`main-container my-profile-otp-modal flex-column ${styles.otpModal}`}>
                {(alertsArray.length > 0) &&
                    alertsArray.reverse().map((item, index) => {
                        return (
                            <div className='mt-2 p-0'>
                                <CommonAlert key={index} id={item.id} type={item.type} message={item.message} handleAlert={handleAlert} />
                            </div>
                        )
                    })
                }
                <div className="inner-container">
                    <div className="text-container">
                        <h2 className="heading">Enter Verification Code</h2>
                        <p className="inner-text">
                            Please check your Mail or Message For Received OTP to Verify
                        </p>
                    </div>
                    <form>
                        <div className="otp-input-fields">
                            <OtpInput
                                onChange={handleChange}
                                value={otpValue}
                                inputStyle="inputStyle mx-2"
                                numInputs={4}
                                inputType="number"
                                separator={<span></span>}
                                renderInput={(props) => <input {...props} />}
                                shouldAutoFocus={true}
                            />
                        </div>
                        <div className="lower-container">
                            <button className="verify-btn" type='submit' onClick={handleOtpVerification}>
                                {isSubmitting ? 'Verifying...' : 'Verify OTP'}
                            </button>
                            <p className="lower-text">Didn’t Receive the OTP ? <span className="resend-text" onClick={handleResendOtp}>RESEND OTP</span></p>
                        </div>
                    </form>
                </div>
            </div>
        </Modal>
    )
};
//#endregion render end
export default OtpVerifyModal;