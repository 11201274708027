import AnvilSignatureModal, { generateEtchSignURL } from '@anvilco/react-signature-modal'
import '@anvilco/react-signature-modal/dist/styles.css'
import { useEffect, useState } from 'react';


const ESign = (props) => {
 
    const [isModalOpen, setIsModalOpen] = useState(true)
    const [loading, setLoading] = useState(true)
    const [iframeURL, setIframeURL] = useState('https://app.useanvil.com/api/etch/verify/swC6L4aqNYOfpgGSVS4s?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzaWduZXJJZCI6NjkxMTIyLCJjbGllbnRVc2VySWQiOiJUaGUgc2lnbmVyJ3MgdXNlciBpZCBpbiB5b3VyIHN5c3RlbSBiZWxvbmdzIGhlcmUiLCJjcmVhdGVkQXQiOjE3MDU5MTYxMDA3MTUsImV4dHJhIjoieVJlcyIsImlhdCI6MTcwNTkxNjEwMCwiZXhwIjoxNzA2MDAyNTAwfQ.ANVtOe7Z09CXo8fCtBJ6dB8MyrSKUKkGpsCs3gOIS9Y')

    // const signingURL = generateEtchSignURL({ variables: { 'generateEtchSignURL', clientUserId } })
    // const urlToFrame = newURL + '&withinIframe=true'

    return (
        <div className="App">
                <AnvilSignatureModal
                    iframeURL={iframeURL}
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    onLoad={() => setLoading(false)}
                    onEvent={(eventObject) => {
                        console.log(eventObject)

                        // See https://www.useanvil.com/docs/api/e-signatures/#iframe-event-details
                        // for all event details.
                        //
                        // Example:
                        //
                        // {
                        //   action: 'signerComplete',
                        //   signerStatus: 'completed',
                        //   signerEid: 'Jc1ZJisPnpF5lHyfyqBW',
                        //   nextSignerEid: 'WBqyfyHl5FpnPsiJZ1cJ',
                        //   documentGroupStatus: 'partial',
                        //   documentGroupEid: 'nEKq2eGim0ijSqKd98nG',
                        //   etchPacketEid: 'XzfmVPfGUEyBc1XyylFo',
                        // }
                    }}
                />
        </div>
    );
};

export default ESign;