import React, { Fragment, useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import QuestionAnswerRoundedIcon from '@mui/icons-material/QuestionAnswerRounded';
import { Col, Row, Nav, Card, Image, OverlayTrigger, Tooltip, Table, Dropdown, ButtonGroup, Button } from '@themesberg/react-bootstrap';
import { useHistory } from 'react-router-dom';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import { toast, Toaster } from 'react-hot-toast';

//Local imports, core styles, vendor styles
import { GetClientListApi } from "../../actions/ClientActions";
import { DataLoading } from "./Loader";
import { Capitalize } from '../../common-components/StringConversions';
import NoDataFound from './NoDataFound';
import CustomPagination from "../../common-components/CustomPagination";
import '../../assets/global-css/Index.css';
import "../../scss/volt.scss";
import "react-datetime/css/react-datetime.css";
import CommonServices from "../../common-components/CommonServices";
import View from '../../assets/img/icons/Airmigrate icons/16px/View.png';
import Edit from '../../assets/img/icons/Airmigrate icons/16px/Edit.png';
import Action from '../../assets/img/icons/Airmigrate icons/16px/Action.png';
import Group from '../../assets/img/icons/Airmigrate icons/16px/Group.png';
import Calendar from '../../assets/img/icons/Airmigrate icons/16px/Calendar.png';
import { create } from "@mui/material/styles/createTransitions";

import '../../assets/mobile-custom-css/Index.css';
import ProfileIconsModal from "./ProfileIconsModal";
// import '../../views/clients/Index.css';

const ClientListTable = ({ search, isSearchChange, singleScreen }) => {

    //#region States and variables
    const history = useHistory();
    const [clientList, setClientList] = useState([]);
    const [copiedClientList, setCopiedClientList] = useState([]);
    const [startingIndex, setStartingIndex] = useState(1);
    const [pageLimit, setPageLimit] = useState(10);
    const [isLoading, setIsLoading] = useState();

    //pagination relates states
    const [activeItem, setActiveItem] = useState(1);
    const [previousActiveItemValue, setPreviousItemActiveValue] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalClientCount, setTotalClientCount] = useState(0);
    const [count, setCount] = useState(0);
    const [consultantName, setConsultantName] = useState(Capitalize(localStorage.getItem('userName')));

    // Login user uuid for chat link
    const [loginUserId, setLoginUserId] = useState('');
    const [userRoleId, setUserRoleId] = useState(localStorage.getItem('roleId'));

    //To hide toaster
    const [isToastVisible, setIsToastVisible] = useState(false);

    //#endregion

    //#region useEffect
    useEffect(() => {
        setIsToastVisible(false);
        let amUserId = localStorage.getItem('amUserId')
        if (amUserId) {
            setLoginUserId(amUserId)
        }
        GetClientList();

    }, [search, activeItem])
    //#endregion

    //#region Style
    const hideChildTable = {
        display: 'none'
    }
    //#endregion

    //#region Functions

    const GetStatus = (status) => {
        if (status === 0) {
            return 'Pending';
        }
        else if (status === 1) {
            return 'Active';
        }
        else if (status === 2) {
            return 'Inactive';
        }
        else if (status === 3) {
            return 'Delete';
        }
    }

    const handleTable = (parentId, childId) => {
        const childElement = document.getElementById(childId);
        const parentElement = document.getElementById(parentId);
        console.log("childElement, parentElement and content", parentId, childId, document.getElementById(childId));
        if (parentElement.innerHTML === '+') {
            document.getElementById(childId).style.display = 'table-row';
            parentElement.innerHTML = '-';
        }
        else {
            document.getElementById(childId).style.display = 'none';
            parentElement.innerHTML = '+';
        }
    }

    const TableRow = (props) => {
        const { consultant_uuid, chat_link_verify_status, created_at, email, profile_path, status,
            email_verified, first_name, last_name, phone_is_verify, primary_phone, user_uuid, case_keyword } = props;


        return (
            <tr>
                <td>
                    <Row className="align-items-center">
                        <Col className="col-auto imageLeftLess custom-user-image">
                            {/* <a href="" className="user-avatar">
                                {(profile_path == '' || profile_path == undefined || profile_path == null) ?

                                    <div className={` bg-light rounded-circle d-flex justify-content-center align-items-center img-d-60`}

                                    >
                                        {((first_name != null) && (last_name != null)) ?
                                            <p className=" fw-bolder pt-2 c-lg" >{CommonServices.getNameCharByFullName(first_name + ' ' + last_name)}</p> :
                                            (first_name != null) ?
                                                <p className=" fw-bolder pt-2 c-lg" >{CommonServices.getNameCharByFullName(first_name + ' ' + last_name)}</p> :
                                                <p className=" fw-bolder pt-2 c-lg" >U</p>
                                        }
                                    </div>
                                    :
                                    <Image src={profile_path} className="rounded-circle pe-none img-d-60" />
                                }
                            </a> */}
                            <ProfileIconsModal userDetails={props} />
                        </Col>
                        <Col className="ms--2">
                            <h4 className="h6 mb-0">
                                <a href="" className="pe-none f-1r work-break c-lg" >
                                    {(first_name == null) ? 'User' : ((first_name != null) && ((last_name != null))) ? Capitalize(first_name) + " " + Capitalize(last_name) : Capitalize(first_name)}
                                </a>
                            </h4>
                            {email &&
                                <>
                                    <small className="pe-none f-1r word-break" >
                                        {email}
                                    </small><br />
                                </>
                            }
                            {userRoleId == 2 &&
                                <small className="pe-none f-1r work-break" >
                                    {primary_phone}
                                </small>
                            }
                        </Col>

                    </Row>
                </td>
                <td className="align-middle hide-content-mobile f-1r" >
                    {/* {case_keyword === null ? 'Not available' : Capitalize(case_keyword)} */}
                    {((status == null) || (status == '')) ? 'Not available' : GetStatus(status)}
                </td>
                <td className="align-middle hide-content-mobile f-1r" >{(created_at.includes(' ') === true) ? created_at?.split(' ')[0] : created_at?.split('T')[0]}</td>
                <td className="align-middle f-1r">
                    <Dropdown as={ButtonGroup}>
                        <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
                            <span className="icon icon-sm">

                                <Image src={Group} className="pe-auto" />
                            </span>
                        </Dropdown.Toggle>
                        {userRoleId == 4 ?
                            <Dropdown.Menu className="dropdown-action-show">
                                <Dropdown.Item
                                    onClick={() => { history.push('/admin/clients/edit-client', { userId: user_uuid }) }}
                                >

                                    <Image src={View} className="pe-auto" /> View
                                </Dropdown.Item>
                            </Dropdown.Menu> :
                            <Dropdown.Menu
                                className="dropdown-action-show"
                            >
                                {/* {chat_link_verify_status == true ? */}
                                <Dropdown.Item
                                    onClick={() => { history.push('/admin/clients/edit-client', { userId: user_uuid }) }}
                                >
                                    <Image className="pe-auto" src={Edit} /> Edit
                                </Dropdown.Item>
                                {/* :
                                    <Dropdown.Item
                                        onClick={() => { }}
                                    >

                                        <Image src={Calendar} className="pe-auto pr-1" /> Invited
                                    </Dropdown.Item>
                                } */}

                            </Dropdown.Menu>
                        }
                    </Dropdown>
                </td>
            </tr>
        );
    };

    const GetClientList = async () => {
        if (((search == '') && (isSearchChange == false)) || (activeItem != previousActiveItemValue)) {
            setIsLoading(true);
        }
        const client = await GetClientListApi(startingIndex, pageLimit, search);
        setIsLoading(false);

        if (client.status_code === 1) {
            setClientList(client.data[0]?.results);
            setCopiedClientList(client.data[0]?.results);

            setTotalClientCount(client.data[0]?.count);
            const pages = Math.ceil(client.data[0]?.count / pageLimit);
            console.log(' total pages', pages);
            setTotalPages(pages);

        } else {
            toast.error(client.message, { duration: 4000, },);
        }
        console.log("client list", client);

    }

    const SortingAsc = (data, key) => {
        console.log("data and key", data, key);
        let sortedData;

        if (key == 'first_name') {
            sortedData = data.sort(function (a, b) {
                let x = a.first_name?.toLowerCase();
                let y = b.first_name?.toLowerCase();

                //compare the word which is comes first
                if (x > y) { return 1; }
                if (x < y) { return -1; }
                return 0;
            });

        }
        else if (key == 'status') {
            sortedData = data.sort(function (a, b) {
                // let x = a.status?.toLowerCase();
                // let y = b.status?.toLowerCase();

                // //compare the word which is comes first
                // if (x > y) { return 1; }
                // if (x < y) { return -1; }
                // return 0;
                var keyA = new Date(a.status),
                    keyB = new Date(b.status);
                // Compare the 2 dates
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;

            });
        }
        else if (key == 'created_at') {
            sortedData = data.sort(function (a, b) {
                var keyA = new Date(a.created_at),
                    keyB = new Date(b.created_at);
                // Compare the 2 dates
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
            });

        }
        console.log("sorted data", sortedData);
        setCopiedClientList(sortedData);
        setCount(count => count + 1);
    }

    const SortingDes = (data, key) => {
        console.log("data and key", data, key);
        let sortedData;

        if (key == 'first_name') {
            sortedData = data.sort(function (a, b) {
                let x = a.first_name?.toLowerCase();
                let y = b.first_name?.toLowerCase();

                //compare the word which is comes first
                if (x < y) { return 1; }
                if (x > y) { return -1; }
                return 0;
            });

        }
        else if (key == 'status') {
            sortedData = data.sort(function (a, b) {
                // let x = a.status?.toLowerCase();
                // let y = b.status?.toLowerCase();

                // //compare the word which is comes first
                // if (x < y) { return 1; }
                // if (x > y) { return -1; }

                var keyA = new Date(a.status),
                    keyB = new Date(b.status);
                // Compare the 2 dates
                if (keyA > keyB) return -1;
                if (keyA < keyB) return 1;
                return 0;

            });
        }
        else if (key == 'created_at') {
            sortedData = data.sort(function (a, b) {
                var keyA = new Date(a.created_at),
                    keyB = new Date(b.created_at);
                // Compare the 2 dates
                if (keyA > keyB) return -1;
                if (keyA < keyB) return 1;
                return 0;
            });

        }
        console.log("sorted data", sortedData);
        setCopiedClientList(sortedData);
        setCount(count => count + 1);
    }
    //#endregion

    //#region JSX
    return (
        <Fragment>
            {isLoading === true && <DataLoading />}
            {(clientList.length == 0) || (clientList.length == undefined) || (clientList.length == null) ?
                <NoDataFound /> :
                <>
                    {(singleScreen === false) ?
                        <Card border="light" className="shadow-sm mb-4 ">
                            <Card.Body className="pb-0">

                                {/*Web Table*/}

                                <Table responsive className="table-centered  table-nowrap rounded mb-0">
                                    <thead className="thead-light border-bottom">
                                        <tr>
                                            <th className="border-0 c-lg f-14" >Name <span>
                                                <FontAwesomeIcon icon={faArrowUp}
                                                    onClick={() => {
                                                        console.log("sort");
                                                        SortingAsc(copiedClientList, "first_name")
                                                    }}
                                                /></span>

                                                <span>
                                                    <FontAwesomeIcon icon={faArrowDown}
                                                        onClick={() => {
                                                            console.log("sort");
                                                            SortingDes(copiedClientList, "first_name")
                                                        }}
                                                    /></span></th>
                                            <th className="border-0 hide-content-mobile c-lg f-14" >Status <span>
                                                <FontAwesomeIcon className="mr-2 ml-2" icon={faArrowUp}
                                                    onClick={() => {
                                                        console.log("sort");
                                                        SortingAsc(copiedClientList, "status")

                                                    }}
                                                /></span>
                                                <span>
                                                    <FontAwesomeIcon icon={faArrowDown}
                                                        onClick={() => {
                                                            console.log("sort");
                                                            SortingDes(copiedClientList, "status")
                                                        }}
                                                    /></span>
                                            </th>
                                            <th className="border-0 hide-content-mobile c-lg f-14" >Created At <span>
                                                <FontAwesomeIcon className="mr-2 ml-2" icon={faArrowUp}
                                                    onClick={() => {
                                                        console.log("sort");
                                                        SortingAsc(copiedClientList, "created_at")
                                                    }}
                                                /></span>
                                                <span className="">
                                                    <FontAwesomeIcon icon={faArrowDown}
                                                        onClick={() => {
                                                            console.log("sort");
                                                            SortingDes(copiedClientList, "created_at")
                                                        }}
                                                    /></span>
                                            </th>
                                            <th className="border-0 c-lg f-14" >Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {copiedClientList.map(client => <TableRow key={`client-${client.consultant_uuid}`} {...client} />)}
                                    </tbody>
                                </Table>



                                {/* Pagination block */}
                                <div className="pagenation-custom-block">
                                    <div className="pagination-msg">
                                        {`Showing ${startingIndex} to ${Math.min(
                                            startingIndex + pageLimit - 1,
                                            totalClientCount
                                        )} of ${totalClientCount} entries`}
                                    </div>
                                    <CustomPagination isSingleScreen={false} setPreviousItemActiveValue={setPreviousItemActiveValue} activeItem={activeItem} setActiveItem={setActiveItem} totalPages={totalPages} setStartingIndex={setStartingIndex} />
                                </div>


                            </Card.Body>
                        </Card>
                        :
                        //Mobile Table
                        <div className="bg-white">
                            <table className="table">
                                <thead className="mobile-thead">
                                    <tr>
                                        <th ></th>
                                        <th className="f-14 c-lg">Name
                                            <span className="sort-filter">
                                                <FontAwesomeIcon icon={faArrowUp}
                                                    onClick={() => {
                                                        console.log("sort");
                                                        SortingAsc(copiedClientList, "first_name")
                                                    }}
                                                /></span>

                                            <span>
                                                <FontAwesomeIcon icon={faArrowDown}
                                                    onClick={() => {
                                                        console.log("sort");
                                                        SortingDes(copiedClientList, "first_name")
                                                    }}
                                                /></span>
                                        </th>
                                        <th className="mobile-table-action-th f-14" >Action</th>
                                    </tr>
                                </thead>
                                {
                                    copiedClientList.map(client => {
                                        const { consultant_uuid, chat_link_verify_status, created_at, email, profile_path,
                                            email_verified, first_name, last_name, phone_is_verify, primary_phone, user_uuid, case_keyword, status } = client;
                                        return (
                                            <tbody key={`client-${client.user_uuid}`}>
                                                <tr>
                                                    <td ><span className="plus-icon" id={`expandChildTable-${user_uuid}`} onClick={() => {
                                                        handleTable(`expandChildTable-${user_uuid}`, `childTableRow-${user_uuid}`)
                                                    }}>+</span></td>
                                                    <td>
                                                        <Row className="align-items-center pe-none text-wrap img-block" >
                                                            <Col className="col-auto imageLeftLess ">
                                                                {/* <a className="user-avatar pe-none" >
                                                                    {(profile_path == '' || profile_path == undefined || profile_path == null) ?
                                                                        <div className={` bg-light rounded-circle d-flex justify-content-center align-items-center img-d-60`} >
                                                                            {((first_name != null) && (last_name != null)) ?
                                                                                <p className=" fw-bolder pt-2 c-lg f-16" >{CommonServices.getNameCharByFullName(first_name + ' ' + last_name)}</p> :
                                                                                (first_name != null) ?
                                                                                    <p className=" fw-bolder pt-2 c-lg f-16" >{CommonServices.getNameCharByFullName(first_name + ' ' + last_name)}</p> :
                                                                                    <p className=" fw-bolder pt-2 c-lg f-16">U</p>
                                                                            }
                                                                        </div>
                                                                        :
                                                                        <Image src={profile_path} className="rounded-circle img-d-60" />
                                                                    }
                                                                </a> */}
                                                                <ProfileIconsModal userDetails={client} />
                                                            </Col>
                                                            <Col className="ms--2">
                                                                <h4 className=" mb-0 f-16 c-lg text-wrap" >
                                                                    {((first_name !== null) && (last_name !== null)) ?
                                                                        Capitalize(first_name) + " " + Capitalize(last_name) :
                                                                        (first_name !== null) ? Capitalize(first_name) : 'User'
                                                                    }
                                                                </h4>
                                                                <span className="f-16 c-lg">
                                                                    {email && <>
                                                                        <span className="text-break"
                                                                        >{email}</span><br />
                                                                    </>}
                                                                </span>
                                                                {userRoleId == 2 &&
                                                                    <span className="f-16 c-lg">
                                                                        {primary_phone !== null && <>
                                                                            <span className="text-break"
                                                                            >{primary_phone}</span><br />
                                                                        </>}
                                                                    </span>
                                                                }
                                                            </Col>

                                                        </Row>
                                                    </td>
                                                    <td className="mobile-table-action-td">
                                                        <Dropdown as={ButtonGroup} >
                                                            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
                                                                <span className="icon icon-sm">

                                                                    <Image className="pe-auto" src={Group} />
                                                                </span>
                                                            </Dropdown.Toggle>
                                                            {userRoleId == 4 ?
                                                                <Dropdown.Menu className="dropdown-action-show">
                                                                    <Dropdown.Item
                                                                        onClick={() => { history.push('/admin/clients/edit-client', { userId: user_uuid }) }}
                                                                    >

                                                                        <Image src={View} className="pe-auto" /> View
                                                                    </Dropdown.Item>
                                                                </Dropdown.Menu> :
                                                                <Dropdown.Menu
                                                                    className="dropdown-action-show"
                                                                >
                                                                    {/* {chat_link_verify_status == true ? */}
                                                                    <Dropdown.Item className="f-16"
                                                                        onClick={() => { history.push('/admin/clients/edit-client', { userId: user_uuid }) }}
                                                                    >
                                                                        <Image className="pe-auto" src={Edit} /> Edit
                                                                    </Dropdown.Item>
                                                                    {/* <Dropdown.Item className="f-16"
                                                                            onClick={() => { }}
                                                                        >
                                                                            <Image src={Calendar} className="pe-auto" /> Invited
                                                                        </Dropdown.Item>
                                                                    } */}

                                                                </Dropdown.Menu>}
                                                        </Dropdown></td>

                                                </tr>
                                                <tr id={`childTableRow-${user_uuid}`} style={hideChildTable}>
                                                    <td colSpan="3">
                                                        {/* <div className="wb">
                                                            <h6 className="f-14 c-dg">Email : {" "}
                                                                <span className="f-14 c-lg text-wrap">{email ? email : 'Not available'}</span>
                                                            </h6>

                                                        </div> */}
                                                        <div className="wb">
                                                            <h6 className="f-16 c-dg">Status {" "}
                                                                <span className="f-16 c-lg text-wrap fw-normal">
                                                                    {((status == null) || (status == '')) ?
                                                                        'Not available' : GetStatus(status)}</span>
                                                            </h6>

                                                        </div>
                                                        <div className="wb">
                                                            <h6 className="f-16 c-dg">Created at : {" "}
                                                                <span className="f-16 c-lg text-wrap fw-normal">{created_at.includes(' ') === true ? created_at.split(' ')[0] : created_at.split('T')[0]}</span>
                                                            </h6>

                                                        </div>
                                                    </td>
                                                </tr>

                                            </tbody>
                                        )
                                    }
                                    )
                                }

                            </table>

                            {/* Pagination block */}
                            <div className="pagenation-custom-block pagination-padding">
                                <div className="pagination-msg f-16">
                                    {`Showing ${startingIndex} to ${Math.min(
                                        startingIndex + pageLimit - 1,
                                        totalClientCount
                                    )} of ${totalClientCount} entries`}
                                </div>
                                <CustomPagination isSingleScreen={true} setPreviousItemActiveValue={setPreviousItemActiveValue} activeItem={activeItem} setActiveItem={setActiveItem} totalPages={totalPages} setStartingIndex={setStartingIndex} />
                            </div>
                        </div>
                    }
                </>
            }
            {/* <Toaster position="top-right" reverseOrder={false} /> */}
            {/* <Toaster position="top-right" reverseOrder={false} toastOptions={{
                style: {
                    display: isToastVisible ? 'auto' : 'none'
                },
            }} /> */}
        </Fragment >
    )
    //#endregion
}
export default ClientListTable;

