import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import { useSelector } from "react-redux";
import enJson from './locale/en.json';
import hiJson from './locale/hi.json';
import frJson from './locale/fr.json';
import deJson from './locale/de.json';
import teluguJson from './locale/telugu.json';
import assameseJson from './locale/assamese.json';
import gujaratiJson from './locale/gujarati.json';
import kannadaJson from './locale/kannada.json';
import malayalamJson from './locale/malayalam.json';
import marathiJson from './locale/marathi.json';
import mizoJson from './locale/mizo.json';
import odiaJson from './locale/odia.json';
import punjabiJson from './locale/punjabi.json';

import tamilJson from './locale/tamil.json';
import banglaJson from './locale/bangla.json';
import dogriJson from './locale/dogri.json';

// const defaultLanguage = useSelector(state => state.globalModule.defaultLanguage);
i18n.use(initReactI18next).init({
  resources: {
    English: { ...enJson },
    हिन्दी: { ...hiJson },
    Français: { ...frJson },
    Deutsch: { ...deJson },
    తెలుగు: { ...teluguJson },
    অসমীয়া: { ...assameseJson },
    ગુજરાતી: { ...gujaratiJson },
    ಕನ್ನಡ: { ...kannadaJson },

    മലയാളം: { ...malayalamJson },
    मराठी: { ...marathiJson },
    Mizo: { ...mizoJson },
    ଓଡିଆ: { ...odiaJson },
    ਪੰਜਾਬੀ: { ...punjabiJson },
    தமிழ்: { ...tamilJson },
    বাংলা: { ...banglaJson },
    डोगरी: { ...dogriJson },
  }, // Where we're gonna put translations' files
  fallbackLng: {
    'default': ['English']
  },
  lng: 'English',     // Set the initial language of the App
});