// Library import
import { Button } from "@themesberg/react-bootstrap";

// Local import
import CommonServices from "../../common-components/CommonServices"
import ProfileIconsModal from "../../components/air-migrate/ProfileIconsModal";

// Chat Case List Component (Left Side)
const ChatCasesListComponent = (props) => {
    const { chatUsersArray, setCaseId, setCaseName, updateMessageSeenInUsersChatListArray, isMiniScreen, userRoleId, history, chatListLoader } = props

    // handle click on case
    const handleClickOnList = (item) => {

        setCaseId(item.case_id)
        const fullCaseName = `${CommonServices.getUserFullName(item)} (${item.case_name})`
        setCaseName(fullCaseName)
        // Update Array
        updateMessageSeenInUsersChatListArray(item.case_id)
    }

    // When no client list
    const onClickNoClient = () => {
        history.push(userRoleId == 3 ? 'myprofile' : 'admin/clients')
    }

    // main list component
    const ListComponent = () => (
        chatUsersArray.map((item, index) => {
            return (
                <div key={index} onClick={() => { handleClickOnList(item) }} className="chat-user-info-block">
                    {/* Chat Time & Unseen Message Count / Pending status button */}
                    <div className='chat-user-time-notify'>
                        {item.chat_cases_status == 0 && !item.last_message ?
                            <Button variant='quaternary' className="chat-list-pending-button ">
                                Pending
                            </Button>
                            :
                            <>
                                <p className='side-time-stamp'>{CommonServices.getDateTimeByTimestampForList(item.last_chat_timestamp)}</p>
                                {item.unseen_message_count > 0 && <div className='chat-unread'>{item.unseen_message_count}</div>}
                            </>
                        }
                    </div>

                    <div className='chat-users-details-block'>
                        {/* Profile Icon */}
                        <div className='chat-list-name-profile-width'>
                            <ProfileIconsModal userDetails={item} />
                        </div>
                        {/* Case Name & Last Message */}
                        <div className="chat-content-text-block">
                            <div className='profile-content-chat-info'>
                                <div className='profile-content-info-chat'>
                                    <div className='chat-user-text-block'>
                                        <h6 className='profile-name text-capital-chat'>{CommonServices.getUserFullName(item)} ({item.case_name})</h6>
                                        {/* status(case mapping status) is 1, so user invited for this case */}
                                        {/* item.status == 1 ? 'Please Accept/Reject Case Invitation Request?' */}
                                        <p className='user-text'>{item.last_message}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
    )

    return (
        chatListLoader ? '' :
            chatUsersArray.length ?
                <ListComponent />
                : (isMiniScreen ?
                    <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', marginTop: 50 }}>
                        <div style={{ flexDirection: 'column' }}>
                            <h5 style={{ textAlign: 'center' }}>No Case Found</h5>
                            <Button variant='quaternary' color="default" onClick={onClickNoClient} style={{ borderRadius: 20, borderColor: '#0184FE', borderWidth: 0, width: 166, paddingTop: 5, paddingBottom: 5, backgroundColor: '#0184FE' }}>
                                {userRoleId == 3 ? "Go To Profile" : "Go To Client List"}
                            </Button>
                        </div>
                    </div> :
                    '')
    )
}

export default ChatCasesListComponent;