import { Modal, Button, Form } from "@themesberg/react-bootstrap";

const CheckListSuggetionModal = ({showcheckListSuggetionModal,setShowcheckListSuggetionModal,checkListSuggetions,setCheckListSuggetions,updateCheckListSuggestion,handlecheckListSuggetionModal}) => {
    //#region jsx 
    return (
        <>
            <Modal show={showcheckListSuggetionModal} onHide={handlecheckListSuggetionModal} size="sm" className={'e-sign-model-body-default'} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Suggestions</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <Form.Group controlId="queryText" className='mt-2'>
                        <Form.Control
                            as="textarea"
                            rows={5}
                            value={checkListSuggetions}
                            placeholder=''
                            onChange={(e) => {
                                setCheckListSuggetions(e.target.value)
                            }}
                        />
                    </Form.Group>
                    <Button className='query-modal-button' onClick={updateCheckListSuggestion}>
                        Add Suggetions
                    </Button>
                </Modal.Body>
            </Modal>
        </>
    )
}
//#endregion jsx
export default CheckListSuggetionModal;