import React, { Fragment, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import QuestionAnswerRoundedIcon from "@mui/icons-material/QuestionAnswerRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import CalendarTodayRoundedIcon from "@mui/icons-material/CalendarTodayRounded";
import { Toaster } from "react-hot-toast";
import {
  Col,
  Row,
  Card,
  Image,
  OverlayTrigger,
  Tooltip,
  Table,
  Dropdown,

} from "@themesberg/react-bootstrap";
import { useHistory } from "react-router-dom";
import toast from "react-hot-toast";
import {
  Breadcrumb,
  Form,
  InputGroup,
  ButtonGroup,
  Button,
} from "@themesberg/react-bootstrap";
import { faHome, faSearch, faPlus } from "@fortawesome/free-solid-svg-icons";

// import CaseListTable from "../../../components/air-migrate/CaseListTable";
import styles from "../../../views/agents/agent-list/Index.module.css";

//Local imports, core styles, vendor styles
import { GetCustomerTypeListApi, DeleteCustomerApi, CreateCustomerRequestStatusChangeApi } from "../../../actions/CustomerAction.jsx";
import { DataLoading } from "../../../components/air-migrate/Loader.jsx";
import {
  Capitalize,
  CapitalizeAllWords,
} from "../../../common-components/StringConversions";
import NoDataFound from "../../../components/air-migrate/NoDataFound";
import CustomPagination from "../../../common-components/CustomPagination";
// import styles from "../../../views/cases/immigration-case-list/Index.css";
// import "../../../assets/global-css/Index.css";
import "../../../scss/volt.scss";
import "react-datetime/css/react-datetime.css";
import { profilePath } from "../../../config.tsx";
import CommonServices from "../../../common-components/CommonServices.jsx";
import Trash from '../../../assets/img/icons/Airmigrate icons/16px/Trash.png';
import Eye from '../../../assets/img/icons/Airmigrate icons/16px/View.png';
import Group from '../../../assets/img/icons/Airmigrate icons/16px/Group.png';
import Accept from '../../../assets/img/icons/Airmigrate icons/16px/Accept.png';
import Reject from '../../../assets/img/icons/Airmigrate icons/16px/Reject.png';
import ProfileIconsModal from "../../../components/air-migrate/ProfileIconsModal.jsx";
import CommonAlert from "../../../common-components/CommonAlert.jsx";
import DocumentViewModal from "../../../components/air-migrate/DocumentViewModal.jsx";
import { HomepageUrl } from "../../../utilities/helpers/Constant.jsx";
import CommonBreadcrumb from "../../../common-components/CommonBreadcrumb.jsx";

const CustomerTypeRequest = ({ search, isSearchChange }) => {
  //#region States and variables
  const history = useHistory();
  const [customerList, setCustomerList] = useState([]);
  const [copiedClientList, setCopiedClientList] = useState([]);
  const [startingIndex, setStartingIndex] = useState(1);
  const [role, setRole] = useState("");
  const [pageLimit, setPageLimit] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [isToastVisible, setIsToastVisible] = useState(false);

  //pagination relates states
  const [activeItem, setActiveItem] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalClientCount, setTotalClientCount] = useState(0);
  const [count, setCount] = useState(0);
  const [previousActiveItemValue, setPreviousItemActiveValue] = useState(1);
  const [consultantName, setConsultantName] = useState(
    Capitalize(localStorage.getItem("userName"))
  );
  const [selectedRole, setSelectedRole] = useState("All");
  // Login user uuid for chat link
  const [loginUserId, setLoginUserId] = useState("");

  //States to manage mobile ui
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const [singleScreen, setSingleScreen] = useState(false);

  /* Function and const for alert */
  //Alert states
  const [alertsArray, setAlertsArray] = useState([]);

  // View Document
  const [showViewDocumentModal, setShowViewDocumentModal] = useState(false)
  const [viewDocumentId, setViewDocumentId] = useState()
  const handleViewDocumentModal = () => setShowViewDocumentModal(!showViewDocumentModal);
  const [selectedUserUuid, setSelectedUserUuid] = useState('');

  //Common breadcrumb array
  const [breadcrumbArray, setBreadcrumbArray] = useState([
    { name: 'Home', url: HomepageUrl },
    { name: 'Customer Requests', url: '' }

  ]);


  // const [count, setCount] = useState(0);
  const handleAlert = (id) => {
    let array = [];
    for (let i = 0; i < alertsArray.length; i++) {
      if ((alertsArray[i].id) == id) {
        array = alertsArray.pop();

      }
    }
    setCount(Math.floor(Math.random() * 1000))
  }
  //#endregion

  //#region Styles
  const filterStyle = {
    marginRight: "2px",
    marginLeft: "3px",
  };
  //#endregion

  //#region useEffect

  useEffect(() => {

    let amUserId = localStorage.getItem("amUserId");
    if (amUserId) {
      setLoginUserId(amUserId);
    }
    GetCustomerList();

    //functionality to set collapsible table 
    window.addEventListener('resize', updateDimension);
    updateDimension();

    // removing eventlistener for no furhter effect after 
    return (() => {
      window.removeEventListener('resize', updateDimension);
    })
  }, [searchText, activeItem, selectedRole, count]);

  //#endregion

  const hideChildTable = {
    display: 'none'
  }



  //#region Functions (all document list table body)
  const updateDimension = () => {
    setScreenSize(getCurrentDimension());
    var value = getCurrentDimension();
    // set a variable true when screen width reaches <768px
    if (value.width <= 767) {
      setSingleScreen(true)
    } else {
      setSingleScreen(false)
    }
  }

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight
    }
  }

  const handleAcceptRejectRequest = async (user_uuid, status) => {

    const requestBody = {
      user_uuid: user_uuid,
      visa_approve: status
    }
    console.log('requestBody', requestBody)
    const response = await CreateCustomerRequestStatusChangeApi(requestBody);
    if (response?.status_code == 1) {
      setAlertsArray([...alertsArray, { id: `customer_type_table${alertsArray.length}`, type: 'success', message: response?.message }]);
      setCount(Math.floor(Math.random() * 1000));
    }
    else {
      setAlertsArray([...alertsArray, { id: `customer_type_table${alertsArray.length}`, type: 'danger', message: response?.message }]);
    }
  }

  const viewVisaDocument = (userUuid, url) => {
    console.log('viewVisaDocument')
    setSelectedUserUuid(userUuid);
    handleViewDocumentModal()
  }


  const handleTableCollapse = (parentId, childId) => {
    const childElement = document.getElementById(childId);
    const parentElement = document.getElementById(parentId);
    console.log("childElement, parentElement and content", parentId, childId, childElement, parentElement);
    if (parentElement.innerHTML === '+') {
      childElement.style.display = 'table-row';
      parentElement.innerHTML = '-';
    }
    else {
      childElement.style.display = 'none';
      parentElement.innerHTML = '+';
    }
  }

  const GetCustomerList = async () => {
    if (((search == '') && (isSearchChange == false)) || (activeItem != previousActiveItemValue)) {
      setIsLoading(true);
    }
    const customer = await GetCustomerTypeListApi(startingIndex, pageLimit, searchText);
    setIsLoading(false);

    const customerData = []
    if (customer?.status_code === 1) {
      const customerData = customer.data[0]?.results
      setCustomerList(customerData)
      setTotalClientCount(customer.data[0]?.count);
      const pages = Math.ceil(customer.data[0]?.count / pageLimit);
      setTotalPages(pages);
    } else {
      setCustomerList(customerData)
    }
  }


  const DeleteCustomer = async (user_uuid) => {
    try {
      setIsLoading(true);
      const response = await DeleteCustomerApi(user_uuid);
      if (response?.status_code == 1) {
        setAlertsArray([...alertsArray, { id: `admin_customer_table${alertsArray.length}`, type: 'success', message: response?.message }]);
      }
      else {
        setAlertsArray([...alertsArray, { id: `admin_customer_table${alertsArray.length}`, type: 'danger', message: response?.message }]);
      }
      setCount(Math.floor(Math.random() * 1000));
      setIsToastVisible(true);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeletecustomer = (user_uuid) => {
    DeleteCustomer(user_uuid);
    GetCustomerList();
  };
  // console.log("selectedRole", selectedRole);
  const TableRow = ({
    first_name,
    last_name,
    profile_path,
    email,
    user_uuid,
    visa_approve,
    visa_option,
    visa_document

  }) => {

    return (
      <tr className="pt-5">
        <td>
          <div className="agent-name-td agent-td">
            <div className="col-auto imageLeftLess custom-user-image">
              <ProfileIconsModal userDetails={{ first_name: first_name, last_name: last_name, profile_path: profile_path }} />
            </div>
            <div className="case-content-block img-case-cursor ms-2 f-16">
              <h4 className="case-name-content c-lg" >
                {(first_name !== null && last_name != null) ? Capitalize(first_name) + " " + Capitalize(last_name) : first_name !== null ? Capitalize(first_name) : 'User'}
              </h4>
            </div>
          </div>
        </td>
        <td>
          <div className="f-16">
            {((email == null) || (email == '') || (email == undefined)) ?
              <p>Not available</p> :
              <p>{email}</p>
            }
          </div>
        </td>

        <td>

          <div className="case-name-content f-16"
          >
            {visa_approve === 0
              ? "Pending"
              : visa_approve === 1
                ? "Accepted"
                : visa_approve === 2
                  ? "Rejected"
                  : ""}
          </div>
          {/* </div> */}
        </td>
        <td>
          <div className={styles.actionbuttongroup}>
            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
                <span className="icon icon-sm">
                  <Image src={Group} style={{ cursor: "pointer" }} />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-action-show">

                <Dropdown.Item onClick={() => viewVisaDocument(user_uuid, visa_document)}>
                  <Image src={Eye} style={{ cursor: "pointer" }} /> View
                </Dropdown.Item>
                {visa_approve == 0 &&
                  <>
                    <Dropdown.Item
                      onClick={() => handleAcceptRejectRequest(user_uuid, 1)}
                    >
                      <Image src={Accept} style={{ cursor: "pointer" }} /> Accept
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => handleAcceptRejectRequest(user_uuid, 2)}
                    >

                      <Image src={Reject} style={{ cursor: "pointer" }} /> Reject
                    </Dropdown.Item>
                  </>
                }

              </Dropdown.Menu>
            </Dropdown>

          </div>
        </td>
      </tr>
    );
  };

  const MobileCustomerList = () => {

    return (
      <table className="table">
        <thead className="mobile-thead">
          <tr>
            <th ></th>
            <th className="f-14">Name</th>
            <th className="mobile-table-action-th f-14" >Action</th>
          </tr>
        </thead>
        {
          customerList.map(props => {
            const { first_name, last_name, profile_path, email, user_uuid, visa_approve, visa_option, visa_document } = props;
            return (
              <tbody key={`client-${user_uuid}`}>
                <tr>
                  <td className=""><span className="plus-icon" id={`expandChildTable-${user_uuid}`}
                    onClick={() => handleTableCollapse(`expandChildTable-${user_uuid}`, `childTableRow-${user_uuid}`)}
                  >+</span></td>
                  <td>
                    <Row className="align-items-center pe-none text-wrap img-block" >
                      <Col className="col-auto imageLeftLess ">

                        <ProfileIconsModal userDetails={props} />
                      </Col>
                      <Col className="ms--2">
                        <h4 className=" mb-0 f-16 c-lg" >
                          {(first_name !== null && last_name != null) ? Capitalize(first_name) + " " + Capitalize(last_name) : first_name !== null ? Capitalize(first_name) : 'User'}
                        </h4>
                        <span className="f-16 c-lg">
                          {email && <>
                            <span className="text-break"
                            >{email}</span><br />
                          </>}
                        </span>

                      </Col>
                    </Row>
                  </td>
                  <td className="mobile-table-action-td">

                    <Dropdown as={ButtonGroup}>
                      <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
                        <span className="icon icon-sm">
                          <Image className="pe-none" src={Group} />
                        </span>
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dropdown-action-show pe-auto">
                        <Dropdown.Item className="f-16" onClick={() => viewVisaDocument(user_uuid, visa_document)}>
                          <Image className="pe-auto pa-r-5" src={Eye} /> View
                        </Dropdown.Item>
                        {visa_approve == 0 &&
                          <>
                            <Dropdown.Item
                              onClick={() => handleAcceptRejectRequest(user_uuid, 1)}
                            >
                              <Image src={Accept} style={{ cursor: "pointer" }} /> Accept
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => handleAcceptRejectRequest(user_uuid, 2)}
                            >

                              <Image src={Reject} style={{ cursor: "pointer" }} /> Reject
                            </Dropdown.Item>
                          </>
                        }
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
                <tr id={`childTableRow-${user_uuid}`} style={hideChildTable}>
                  <td colSpan="3">

                    <div className="text-wrap ">
                      <h6 className="f-16 c-dg">Status : {" "}
                        <span className="f-16 c-lg text-wrap fw-normal">{visa_approve === 0 ? "Pending" : visa_approve === 1 ? "Accepted" : visa_approve === 2 ? "Rejected"
                          : ""}</span>
                      </h6>
                    </div>

                  </td>
                </tr>

              </tbody>
            )
          }
          )
        }

      </table>
    )
  }



  //#endregion
  return (
    <Fragment>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap  pt-0 pb-2">
        <div className="d-block  mb-md-0" >
          {/* <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item onClick={() => history.push(HomepageUrl)}>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Customer Request</Breadcrumb.Item>
          </Breadcrumb> */}

          <CommonBreadcrumb breadcrumbArray={breadcrumbArray} />
        </div>
        <div className={`${singleScreen === false ? 'mt-5' : ''}`}>
          <Button variant="primary" className={`mb-md-4 mb-0 text-wrap ${singleScreen ? 'f-16' : ''}`} onClick={() => history.push("/admin/customers")}>Customers</Button>
        </div>
      </div>

      <div className="table-settings mb-4">
        <Row >
          <Col xs={12} md={12} lg={3} xl={3}>
            <div className="pt-1">
              <h4 className={`${(singleScreen === true) ? 'f-18' : ''}`}>Customer Request</h4>
            </div>
          </Col>

          <Col className="offset-3"></Col>
          <Col xs={12} sm={12} md={6} lg={5} xl={5}>
            <InputGroup>
              <InputGroup.Text className={styles.caselistSearchBox}>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <Form.Control
                type="text"
                placeholder="Search"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                className={[styles.caselistSearchBox, singleScreen && "f-14"]}
              />
            </InputGroup>
          </Col>

        </Row>
        <Row className="p-0 mt-2">
          <Col lg={12} sm={12} md={12} xs={12} xl={12}>
            {(alertsArray.length > 0) &&
              alertsArray.reverse().map((item, index) => {
                return (
                  <CommonAlert key={index} id={item.id} type={item.type} message={item.message} handleAlert={handleAlert} />
                )
              })
            }
          </Col>
        </Row>
      </div>
      {isLoading === true ? (
        <DataLoading />
      ) : customerList.length == 0 ||
        customerList.length == undefined ||
        customerList.length == null ? (
        <NoDataFound />
      ) : (

        <>
          {singleScreen === false ?
            <Card border="light" className="shadow-sm mb-4 ">
              <Card.Body className="pb-0 custom-table-body">
                <Table className="table-centered custom-table-responsive agent-requests-table  rounded mb-0">
                  <thead className="thead-light border-bottom">
                    <tr>
                      <th className="border-0 c-lg f-14" >
                        Name{" "}
                      </th>
                      <th className="border-0 c-lg f-14" >
                        Email
                      </th>

                      <th className="border-0 c-lg f-14" >
                        Status
                      </th>
                      <th
                        className="border-0 action-block-agent-requests c-lg f-14"

                      >
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {customerList.map((customer) => (
                      <TableRow key={`agent-${customer.user_uuid}`} {...customer} />
                    ))}
                  </tbody>
                </Table>
                <div className="pagenation-custom-block">
                  <div className="pagination-msg">
                    {`Showing ${startingIndex} to ${Math.min(startingIndex + pageLimit - 1, totalClientCount)} of ${totalClientCount} entries`}
                  </div>
                  <CustomPagination
                    activeItem={activeItem}
                    setActiveItem={setActiveItem}
                    totalPages={totalPages}
                    setStartingIndex={setStartingIndex}
                    isSingleScreen={false}
                  />
                </div>
              </Card.Body>
            </Card>
            :
            <div className="bg-white">
              <MobileCustomerList />
              <div className="pagenation-custom-block pagination-padding">
                <div className="pagination-msg f-14">
                  {`Showing ${startingIndex} to ${Math.min(startingIndex + pageLimit - 1, totalClientCount)} of ${totalClientCount} entries`}
                </div>
                <CustomPagination isSingleScreen={true} activeItem={activeItem} setActiveItem={setActiveItem} totalPages={totalPages} setStartingIndex={setStartingIndex} />
              </div>

            </div>

          }
        </>

      )}

      {showViewDocumentModal &&
        <DocumentViewModal show={showViewDocumentModal} handleClose={handleViewDocumentModal} documentId='' isCustomerTypeView={true} userUuid={selectedUserUuid} />
      }


    </Fragment>
  );
  //#endregion
};
export default CustomerTypeRequest;
