import styles from "./Index.module.css"
import { Fragment, useState, useEffect } from "react";

import { Breadcrumb, Row, InputGroup, Col, Form, Card, Table, Image, Badge, Dropdown, ButtonGroup, Button } from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faSearch, faUpload } from "@fortawesome/free-solid-svg-icons";

import { useHistory, useLocation } from 'react-router-dom';
import { CapitalizeAllWords } from "../../../../common-components/StringConversions";
import docStyle from '../../../view-all-documents/document-table/Index.module.css';

import { Capitalize } from "../../../../common-components/StringConversions";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import { DataLoading } from "../../../../components/air-migrate/Loader";
import {
    GetCustomerRecievedDocRequestsApi, GetCustomerRecievedInviteUserRequestsApi, GetCustomerRecievedProfileRequestsApi,
    CreateDocStatusChangeApi, CreateProfileStatusChangeApi, CreateInviteUserStatusChangeApi,GetCustomerRecievedDocAccessRequestsApi
} from "../../../../actions/RequestActions";
import { Toaster } from "react-hot-toast";
import NoDataFound from "../../../../components/air-migrate/NoDataFound";
import CustomPagination from "../../../../common-components/CustomPagination";
import { GetRoleNames } from "../../../../common-components/StringConversions";
import { profilePath } from "../../../../config.tsx";
import Select from 'react-select';
import styles2 from '../../../../views/requests/Index.module.css';
import FileUploadModal from "../../../../components/air-migrate/FileUploadModal.jsx";
import CommonServices from "../../../../common-components/CommonServices.jsx";
import Group from '../../../../assets/img/icons/Airmigrate icons/16px/Group.png';
import Accept from '../../../../assets/img/icons/Airmigrate icons/16px/Accept.png';
import Reject from '../../../../assets/img/icons/Airmigrate icons/16px/Reject.png';
import Upload from '../../../../assets/img/icons/Airmigrate icons/16px/Upload document.png';
import Eye from '../../../../assets/img/icons/Airmigrate icons/16px/View.png';
import Trash from '../../../../assets/img/icons/Airmigrate icons/16px/Trash.png';
import { HomepageUrl } from "../../../../utilities/helpers/Constant.jsx";
import '../../../../assets/global-css/Index.css';
import '../../../../assets/mobile-custom-css/Index.css';
import ProfileIconsModal from "../../../../components/air-migrate/ProfileIconsModal.jsx";
import CommonAlert from "../../../../common-components/CommonAlert.jsx";
import CommonBreadcrumb from "../../../../common-components/CommonBreadcrumb.jsx";

const RecievedRequests = () => {
    const [searchText, setSearchText] = useState('');
    const location = useLocation();
    const history = useHistory();
    const [isSearchChange, setIsSearchChange] = useState(false);
    const [userRoleId, setUserRoleId] = useState('roleId');
    const [requestType, setRequestType] = useState({ label: 'Documents', id: 2, value: "Documents" });

    //States to manage mobile ui
    const [screenSize, setScreenSize] = useState(getCurrentDimension());
    const [singleScreen, setSingleScreen] = useState(false);

    //Alert states
    const [modalAlertsArray, setModalAlertsArray] = useState([]);

    //Common breadcrumb array
    const [breadcrumbArray, setBreadcrumbArray] = useState([
        { name: 'Home', url: HomepageUrl },
        { name: 'Pending Actions', url: '' }

    ]);

    useEffect(() => {
        //functionality to set collapsible table 
        window.addEventListener('resize', updateDimension);
        updateDimension();

        // removing eventlistener for no furhter effect after 
        return (() => {
            window.removeEventListener('resize', updateDimension);
        })
    }, [])

    //#region Functions
    const updateDimension = () => {
        setScreenSize(getCurrentDimension());
        var value = getCurrentDimension();
        // set a variable true when screen width reaches <768px
        if (value.width <= 767) {
            setSingleScreen(true)
        } else {
            setSingleScreen(false)
        }
    }

    function getCurrentDimension() {
        return {
            width: window.innerWidth,
            height: window.innerHeight
        }
    }

    /* Function and const for alert */
    //Alert states
    const [alertsArray, setAlertsArray] = useState([]);
    const [count, setCount] = useState(0);
    const handleAlert = (id) => {
        let array = [];
        for (let i = 0; i < alertsArray.length; i++) {
            if ((alertsArray[i].id) == id) {
                array = alertsArray.pop();

            }
        }
        setCount(Math.floor(Math.random() * 1000))
    }

    //#endregion

    return (
        <Fragment>
            <div className="">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap pt-0 pb-2">
                    <div className="d-block mb-4 mb-md-0">
                        {/* <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
                            <Breadcrumb.Item onClick={() => history.push(HomepageUrl)} className="form-range-track-cursor"><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
                            <Breadcrumb.Item active> Pending actions</Breadcrumb.Item>
                        </Breadcrumb> */}

                        <CommonBreadcrumb breadcrumbArray={breadcrumbArray} />
                    </div>
                    {/* Query Requests redirect button */}
                    <div className={`${singleScreen === false ? 'mt-5' : ''}`}>
                        <Button variant="primary" className={`mb-md-4 mb-0 text-wrap ${singleScreen ? 'f-18' : ''}`} onClick={() => history.push("/customer/requests/query-request")}> Query Requests</Button>
                    </div>
                </div>

                <div className="table-settings mb-4">
                    <Row className="justify-content-space-between align-items-center">
                        <Col xs={12} md={4} lg={4} xl={4} className="align-item-end">
                            <h4 className={`${singleScreen ? 'f-18' : ''}`}>Pending Actions</h4>
                        </Col>
                        <Col xs={12} md={4} lg={4} xl={4} className="align-item-end">
                            <Select
                                className={singleScreen && 'f-16'}
                                classNamePrefix="select document-dropdown-block"
                                isDisabled={false}
                                isLoading={false}
                                isClearable={false}
                                isRtl={false}
                                isMulti={false}
                                isSearchable={false}
                                value={requestType}
                                name="filter"
                                id={`filter`}
                                options={[{ label: 'Profile Request', id: 1, value: "Profile" },
                                { label: 'Documents Request', id: 2, value: "Documents" },
                                { label: 'Invitations', id: 3, value: "Invitations" },
                                { label: 'Document Access Request', id: 4, value: "Access" }
                                ]}
                                onChange={(e) => { setRequestType(e); setSearchText('') }}

                            />
                        </Col>
                        <Col xs={12} md={4} lg={4} xl={4} className={`align-item-end ${styles.filterAlign} `} >
                            <InputGroup>
                                <InputGroup.Text>
                                    <FontAwesomeIcon icon={faSearch} />
                                </InputGroup.Text>
                                <Form.Control className={singleScreen ? "f-16 " : " " + styles.customSearchDocument} type="text" placeholder="Search" value={searchText} onChange={(e) => { setIsSearchChange(true); setSearchText(e.target.value) }} />
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row className="p-0 mt-3">
                        <Col lg={12} sm={12} md={12} xs={12} xl={12}>
                            {(alertsArray.length > 0) &&
                                alertsArray.reverse().map((item, index) => {
                                    return (
                                        <CommonAlert key={index} id={item.id} type={item.type} message={item.message} handleAlert={handleAlert} />
                                    )
                                })
                            }
                        </Col>
                    </Row>
                </div>
                <RecievedRequestTable search={searchText}
                    isSearchChange={isSearchChange}
                    requestType={requestType}
                    singleScreen={singleScreen}
                    alertsArray={alertsArray}
                    setAlertsArray={setAlertsArray}
                    count={count}
                    setCount={setCount}
                />
            </div>
        </Fragment>
    )
}
export default RecievedRequests;

export const RecievedRequestTable = ({ search, isSearchChange, requestType, singleScreen, alertsArray, setAlertsArray, count, setCount }) => {
    //#region States and variables
    const history = useHistory();
    const [requestList, setRequestList] = useState([]);
    const [startingIndexForDoc, setStartingIndexForDoc] = useState(1);
    const [startingIndexForProfile, setStartingIndexForProfile] = useState(1);
    const [startingIndexForInvitation, setStartingIndexForInvitation] = useState(1);
    const [startingIndexForAccessRequest, setStartingIndexForAccessRequest] = useState(1);
    const [pageLimit, setPageLimit] = useState(10);
    const [isLoading, setIsLoading] = useState();

    //pagination relates states
    const [activeItemForProfile, setActiveItemForProfile] = useState(1);
    const [activeItemForDoc, setActiveItemForDoc] = useState(1);
    const [activeItemForInvitation, setActiveItemForInvitation] = useState(1);
    const [activeItemForAccessRequest, setActiveItemForAccessRequest] = useState(1);
    const [totalPagesForDoc, setTotalPagesForDoc] = useState(1);
    const [totalPagesForProfile, setTotalPagesForProfile] = useState(1);
    const [totalPagesForInvitation, setTotalPagesForInvitation] = useState(1);
    const [totalPagesForAccessRequest, setTotalPagesForAccessRequest] = useState(1);
    const [totalRequestCountForProfile, setTotalRequestCountForProfile] = useState(0);
    const [totalRequestCountForDoc, setTotalRequestCountForDoc] = useState(0);
    const [totalRequestCountForInvitation, setTotalRequestCountForInvitation] = useState(0);
    const [totalRequestCountForAccessRequest, setTotalRequestCountForAccessRequest] = useState(0);
    // const [count, setCount] = useState(0);
    const [userRoleId, setUserRoleId] = useState(localStorage.getItem('roleId'));
    const [previousActiveItemValue, setPreviousItemActiveValue] = useState(1);
    const [isShowModal, setIsShowModal] = useState(false);
    const [selectedDocumentId, setSelectedDocumentId] = useState()


    //#endregion


    //#region useEffect
    useEffect(() => {
        GetRecievedRequestList(requestType.id);
    }, [search, count, activeItemForProfile, activeItemForDoc, activeItemForInvitation,activeItemForAccessRequest, requestType])

    //#endregion

    //#region style
    const hideChildTable = {
        display: 'none'
    }
    //#endregion

    const handleTableCollapse = (parentId, childId) => {
        const childElement = document.getElementById(childId);
        const parentElement = document.getElementById(parentId);
        console.log("childElement, parentElement and content", parentId, childId, childElement, parentElement);
        if (parentElement.innerHTML === '+') {
            childElement.style.display = 'table-row';
            parentElement.innerHTML = '-';
        }
        else {
            childElement.style.display = 'none';
            parentElement.innerHTML = '+';
        }
    }

    const uploadHandleClose = () => setIsShowModal(false);

    const TableBodyDocRequestList = (props) => {
        const { documents_id, id, email, created_at, case_name, document_option, document_type, first_name,
            profile_path, role, last_name, required, url, status,
            client_first_name,client_profile_path, client_role, client_last_name,client_email
        } = props;

        return (
            <tr className={docStyle.requestListRow}>
                <td className="pl-5" >
                    <div className={[docStyle.commonTypographyForRow]}>
                        <p>{Capitalize(case_name)}</p>
                    </div>
                </td>
                <td className="pl-5">
                    <div className={[docStyle.commonTypographyForRow]}>
                        <p>{Capitalize(document_option)}</p>
                    </div>
                </td>
                <td>
                    <Row className="align-items-center pe-none" >
                        <Col className="col-auto imageLeftLess custom-user-image">
                            <ProfileIconsModal userDetails={props} />
                        </Col>
                        <Col className="ms--2">
                            <h4 className="h6 mb-0 f-16 c-lg" >
                                {Capitalize(first_name?first_name:'User') + " " + Capitalize(last_name?last_name:'')}
                            </h4>

                            {email && <>
                                <span className="text-break f-16"

                                >{email}</span><br />
                            </>}

                            <button
                                className={`pe-none border-0 f-12 text-white rounded-1  ${(role == 4) ? 'bg-c-r' : (role == 3) ? 'bg-c-db' : (role == 2) ? 'bg-c-g' : 'bg-c-lb'}`}
                            >{GetRoleNames(role)}</button>
                        </Col>

                    </Row>
                </td>
                <td>
                    <Row className="align-items-center pe-none" >
                        <Col className="col-auto imageLeftLess custom-user-image">
                            <ProfileIconsModal userDetails={{ profile_path: client_profile_path, first_name: client_first_name,last_name:client_last_name }} />

                        </Col>
                        <Col className="ms--2">
                            <h4 className="h6 mb-0 f-16 c-lg" >
                                {Capitalize(client_first_name?client_first_name:'User') + " " + Capitalize(client_last_name?client_last_name:'')}
                            </h4>
                            {client_email && <>
                                <span className="text-break f-16"
                                >{client_email}</span><br />
                            </>}
                            <button
                                className={`pe-none border-0 f-12 text-white rounded-1  ${(client_role == 4) ? 'bg-c-r' : (client_role == 3) ? 'bg-c-db' : (client_role == 2) ? 'bg-c-g' : 'bg-c-lb'}`}
                            >{GetRoleNames(client_role)}</button>
                        </Col>

                    </Row>

                </td>
                <td>
                    <div className={docStyle.commonTypographyForRow}>
                        <p>{created_at?.split(' ')[0]}</p>
                    </div>
                </td>

                <td>
                    <Dropdown as={ButtonGroup}>
                        <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
                            <span className="icon icon-sm">
                                <Image className="pe-none" src={Group} />
                            </span>
                        </Dropdown.Toggle>
                        {(url == '' || url == null) ?
                            <Dropdown.Menu className="dropdown-action-show">

                                <Dropdown.Item onClick={() => { setIsShowModal(true); setSelectedDocumentId(documents_id) }}>
                                    <Image className="pe-auto pr-2" src={Upload} /> Upload

                                </Dropdown.Item>
                                {/* <Dropdown.Item onClick={() => AcceptRejectDocRequest(documents_id, id, 2)}>
                                    <Image src={Reject} className="pe-auto pr-2" /> Reject
                                </Dropdown.Item> */}
                            </Dropdown.Menu>
                            :
                            (status == 0) && (url == '' || url == null || url == undefined) ?

                                <Dropdown.Menu className="dropdown-action-show">

                                    <Dropdown.Item onClick={() => { setIsShowModal(true); setSelectedDocumentId(documents_id) }}>
                                        <Image src={Upload} className="pe-auto pr-2" onClick={() => { setIsShowModal(true); setSelectedDocumentId(documents_id) }} /> Upload

                                    </Dropdown.Item>
                                    {/* <Dropdown.Item onClick={() => AcceptRejectDocRequest(documents_id, id, 2)}>
                                        <Image src={Reject} className="pe-auto pr-2" /> Reject
                                    </Dropdown.Item> */}

                                </Dropdown.Menu> :
                                (status == 0 || status === null) ?

                                    <Dropdown.Menu className="dropdown-action-show">

                                        {/* <Dropdown.Item onClick={() => AcceptRejectDocRequest(documents_id, id, 1)}>
                                            <Image src={Accept} className="pe-auto pr-2" /> Give access
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={() => AcceptRejectDocRequest(documents_id, id, 2)}>
                                            <Image src={Reject} className="pe-auto pr-2" /> Reject
                                        </Dropdown.Item> */}

                                    </Dropdown.Menu>
                                    :
                                    (status == 1) ?
                                        <Dropdown.Menu className="dropdown-action-show">
                                            {/* <Dropdown.Item >
                                                <Image src={Accept} className="pe-auto pr-2" /> Accepted
                                            </Dropdown.Item> */}
                                        </Dropdown.Menu> :
                                        (status == 2) &&
                                        <Dropdown.Menu className="dropdown-action-show">
                                            {/* <Dropdown.Item >
                                                <Image src={Accept} className="pe-auto pr-2" /> Rejected
                                            </Dropdown.Item> */}
                                        </Dropdown.Menu>
                        }

                    </Dropdown>
                </td>
            </tr>
        );
    };

    const MobileTableDocList = () => {

        return (
            <table className="table">
                <thead className="mobile-thead">
                    <tr>
                        <th ></th>
                        <th className="text-wrap f-14">Requested By</th>
                        <th className="mobile-table-action-th f-14" >Action</th>
                    </tr>
                </thead>
                {
                    requestList.map((props, index) => {
                        const { documents_id, id, email, created_at, case_name, document_option, document_type, first_name,
                            profile_path, role, last_name, required, url, status } = props;
                        return (
                            <tbody key={`client-${id}`}>
                                <tr>
                                    <td className=""><span className="plus-icon" id={`expandChildTable-${id}-${index}`}
                                        onClick={() => handleTableCollapse(`expandChildTable-${id}-${index}`, `childTableRow-${id}-${index}`)}
                                    >+</span></td>
                                    <td>
                                        <Row className="align-items-center pe-none text-wrap img-block" >
                                            <Col className="col-auto imageLeftLess ">
                                                <a className="user-avatar pe-none" >
                                                    {(profile_path == '' || profile_path == undefined || profile_path == null) ?
                                                        <div className={` bg-light rounded-circle d-flex justify-content-center align-items-center img-d-60`} >
                                                            {((first_name != null) && (last_name != null)) ?
                                                                <p className=" fw-bolder pt-2 c-lg f-16" >{CommonServices.getNameCharByFullName(first_name + ' ' + last_name)}</p> :
                                                                (first_name != null) ?
                                                                    <p className=" fw-bolder pt-2 c-lg f-16" >{CommonServices.getNameCharByFullName(first_name + ' ' + last_name)}</p> :
                                                                    <p className=" fw-bolder pt-2 c-lg f-16">U</p>
                                                            }
                                                        </div>
                                                        :
                                                        <Image src={profile_path} className="rounded-circle img-d-60" />
                                                    }
                                                </a>
                                            </Col>
                                            <Col className="ms--2">
                                                <h4 className=" mb-0 f-16 c-lg" >
                                                    {first_name !== null && last_name !== null ?
                                                        Capitalize(first_name) + " " + Capitalize(last_name) :
                                                        (first_name !== null) ? Capitalize(first_name) : 'User'
                                                    }
                                                </h4>
                                                <span className="f-16 c-lg">
                                                    {email && <>
                                                        <span className="text-break"
                                                        >{email}</span><br />
                                                    </>}
                                                </span>
                                                <button
                                                    className={`pe-none border-0 f-14 text-white rounded-1  ${(role == 4) ? 'bg-c-r' : (role == 3) ? 'bg-c-db' : (role == 2) ? 'bg-c-g' : 'bg-c-lb'}`}
                                                >{GetRoleNames(role)}</button>
                                            </Col>

                                        </Row>
                                    </td>
                                    <td className="mobile-table-action-td">

                                        <Dropdown as={ButtonGroup}>
                                            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
                                                <span className="icon icon-sm">
                                                    <Image className="pe-none" src={Group} />
                                                </span>
                                            </Dropdown.Toggle>
                                            {(url == '' || url == null) ?
                                                <Dropdown.Menu className="dropdown-action-show">

                                                    <Dropdown.Item className="f-16" onClick={() => { setIsShowModal(true); setSelectedDocumentId(documents_id) }}>
                                                        <Image className="pe-auto pr-2" src={Upload} /> Upload
                                                    </Dropdown.Item>
                                                    <Dropdown.Item className="f-16" onClick={() => AcceptRejectDocRequest(documents_id, id, 2)}>
                                                        <Image src={Reject} className="pe-auto pr-2" /> Reject
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                                :
                                                (status == 0) && (url == '' || url == null || url == undefined) ?

                                                    <Dropdown.Menu className="dropdown-action-show">

                                                        <Dropdown.Item className="f-16" onClick={() => { setIsShowModal(true); setSelectedDocumentId(documents_id) }}>
                                                            <Image src={Upload} className="pe-auto pr-2" onClick={() => { setIsShowModal(true); setSelectedDocumentId(documents_id) }} /> Upload
                                                        </Dropdown.Item>
                                                        <Dropdown.Item className="f-16" onClick={() => AcceptRejectDocRequest(documents_id, id, 2)}>
                                                            <Image src={Reject} className="pe-auto pr-2" /> Reject
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu> :
                                                    (status == 0 || status === null) ?

                                                        <Dropdown.Menu className="dropdown-action-show">

                                                            <Dropdown.Item className="f-16" onClick={() => AcceptRejectDocRequest(documents_id, id, 1)}>
                                                                <Image src={Accept} className="pe-auto pr-2" /> Give access
                                                            </Dropdown.Item>
                                                            <Dropdown.Item className="f-16" onClick={() => AcceptRejectDocRequest(documents_id, id, 2)}>
                                                                <Image src={Reject} className="pe-auto pr-2" /> Reject
                                                            </Dropdown.Item>

                                                        </Dropdown.Menu>

                                                        :
                                                        (status == 1) ?
                                                            <Dropdown.Menu className="dropdown-action-show">
                                                                <Dropdown.Item className="f-16">
                                                                    <Image src={Accept} className="pe-auto pr-2" /> Accepted
                                                                </Dropdown.Item>
                                                            </Dropdown.Menu> :
                                                            (status == 2) &&
                                                            <Dropdown.Menu className="dropdown-action-show">
                                                                <Dropdown.Item className="f-16">
                                                                    <Image src={Accept} className="pe-auto pr-2" /> Rejected
                                                                </Dropdown.Item>
                                                            </Dropdown.Menu>
                                            }

                                        </Dropdown>
                                    </td>
                                </tr>
                                <tr id={`childTableRow-${id}-${index}`} style={hideChildTable}>
                                    <td colSpan="3">
                                        <div className="text-wrap " >
                                            <h6 className="f-16">Title :
                                                <span className="f-16 c-lg fw-normal">{" "}{document_option ? Capitalize(document_option) : 'Not available'}</span>
                                            </h6>

                                        </div>
                                        <div className="text-wrap ">
                                            <h6 className="f-16">Case name : {" "}
                                                <span className="c-lg f-16 fw-normal">{((case_name == null) || (case_name == '')) ? 'Not available' : Capitalize(case_name)}</span>
                                            </h6>
                                        </div>
                                        <div className="text-wrap ">
                                            <h6 className="f-16">Requested Date : {" "}
                                                <span className="f-16 c-lg fw-normal">{created_at.includes(' ') === true ? created_at.split(' ')[0] : created_at.split('T')[0]}</span></h6>

                                        </div>
                                    </td>
                                </tr>

                            </tbody>
                        )
                    }
                    )
                }

            </table>
        )
    }


    const TableBodyProfileRequestList = (props) => {
        const { access_by_user_uuid, column_name, email, first_name, last_name, profile_path, status, case_name, role, created_at } = props;
        return (
            <tr className={docStyle.requestListRow}>

                <td style={{ paddingLeft: 10 }}>
                    <div className={[docStyle.commonTypographyForRow]}>
                        <p>{Capitalize(column_name?.replaceAll("_", " "))}</p>
                    </div>
                </td>
                <td>
                    <Row className="align-items-center pe-none" >
                        <Col className="col-auto imageLeftLess custom-user-image pe-none">
                            {/* <a href="#" className="user-avatar pe-none" >
                                {(profile_path == '' || profile_path == undefined || profile_path == null) ?

                                    <div className={` bg-light rounded-circle d-flex justify-content-center align-items-center img-d-60`} >
                                        {((first_name != null) && (last_name != null)) ?
                                            <p className=" fw-bolder pt-2" >{CommonServices.getNameCharByFullName(first_name + ' ' + last_name)}</p> :
                                            (first_name != null) ?
                                                <p className=" fw-bolder pt-2 f-14 c-lg" >{CommonServices.getNameCharByFullName(first_name + ' ' + last_name)}</p> :
                                                <p className=" fw-bolder pt-2 f-14 c-lg" >U</p>
                                        }
                                    </div>
                                    :
                                    <Image src={profile_path} className="rounded-circle img-d-60" />
                                }
                            </a> */}
                            <ProfileIconsModal userDetails={props} />
                        </Col>
                        <Col className="ms--2">
                            <h4 className="h6 mb-0 f-16 c-lg" >

                                {Capitalize(first_name) + " " + Capitalize(last_name)}
                            </h4>

                            {email && <>
                                <span className="f-16"

                                >{email}</span><br />
                            </>}

                            <button
                                className={`pe-none border-0 f-12 text-white rounded-1  ${(role == 4) ? 'bg-c-r' : (role == 3) ? 'bg-c-db' : (role == 2) ? 'bg-c-g' : 'bg-c-lb'}`}
                            >{GetRoleNames(role)}</button>
                        </Col>

                    </Row>
                </td>
                <td>
                    <div className={docStyle.commonTypographyForRow}>
                        <p>{created_at?.split(' ')[0]}</p>
                    </div>
                </td>

                <td>
                    <Dropdown as={ButtonGroup}>
                        <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
                            <span className="icon icon-sm">
                                <Image className="pe-auto" src={Group} />
                            </span>
                        </Dropdown.Toggle>
                        {

                            (status === 0 || status === null) ?

                                <Dropdown.Menu className="dropdown-action-show">

                                    <Dropdown.Item onClick={() => AcceptRejectProfileRequest(column_name, true, access_by_user_uuid)}>
                                        <Image src={Accept} className="pe-auto pr-2" /> Give access
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={() => AcceptRejectProfileRequest(column_name, false, access_by_user_uuid)}>
                                        <Image src={Reject} className="pe-auto pr-2" /> Reject
                                    </Dropdown.Item>

                                </Dropdown.Menu> :
                                (status === true) ?
                                    <Dropdown.Menu className="dropdown-action-show">
                                        <Dropdown.Item >
                                            <Image src={Accept} className="pe-auto pr-2" /> Accepted
                                        </Dropdown.Item>
                                    </Dropdown.Menu> :
                                    (status === false) ?
                                        <Dropdown.Menu className="dropdown-action-show">
                                            <Dropdown.Item >
                                                <Image src={Accept} className="pe-auto pr-2" /> Rejected
                                            </Dropdown.Item>
                                        </Dropdown.Menu> : ''
                        }

                    </Dropdown>
                </td>
            </tr>
        );
    };

    const MobileTableProfileList = () => {
        return (
            <table className="table">
                <thead className="mobile-thead">
                    <tr>
                        <th ></th>
                        <th className="f-14">Requested By</th>
                        <th className="mobile-table-action-th f-14" >Action</th>
                    </tr>
                </thead>
                {
                    requestList.map((props, index) => {
                        const { access_by_user_uuid, column_name, email, first_name, last_name, profile_path, status, case_name, role, created_at } = props;
                        return (
                            <tbody key={`profile-${access_by_user_uuid}-${index}`}>
                                <tr>
                                    <td className=""><span className="plus-icon" id={`expandChildTable-${access_by_user_uuid}-${index}`}
                                        onClick={() => handleTableCollapse(`expandChildTable-${access_by_user_uuid}-${index}`, `childTableRow-${access_by_user_uuid}-${index}`)}
                                    >+</span></td>

                                    <td>
                                        <Row className="align-items-center pe-none text-wrap img-block" >
                                            <Col className="col-auto imageLeftLess ">
                                                <a className="user-avatar pe-none" >
                                                    {(profile_path == '' || profile_path == undefined || profile_path == null) ?
                                                        <div className={` bg-light rounded-circle d-flex justify-content-center align-items-center img-d-60`} >
                                                            {((first_name != null) && (last_name != null)) ?
                                                                <p className=" fw-bolder pt-2 c-lg f-16" >{CommonServices.getNameCharByFullName(first_name + ' ' + last_name)}</p> :
                                                                (first_name != null) ?
                                                                    <p className=" fw-bolder pt-2 c-lg f-16" >{CommonServices.getNameCharByFullName(first_name + ' ' + last_name)}</p> :
                                                                    <p className=" fw-bolder pt-2 c-lg f-16">U</p>
                                                            }
                                                        </div>
                                                        :
                                                        <Image src={profile_path} className="rounded-circle img-d-60" />
                                                    }
                                                </a>
                                            </Col>
                                            <Col className="ms--2">
                                                <h4 className=" mb-0 f-16 c-lg" >
                                                    {first_name !== null && last_name !== null ?
                                                        Capitalize(first_name) + " " + Capitalize(last_name) :
                                                        (first_name !== null) ? Capitalize(first_name) : 'User'
                                                    }
                                                </h4>
                                                <span className="f-16 c-lg">
                                                    {email && <>
                                                        <span className="text-break"
                                                        >{email}</span><br />
                                                    </>}
                                                </span>
                                                <button
                                                    className={`pe-none border-0 f-14 text-white rounded-1  ${(role == 4) ? 'bg-c-r' : (role == 3) ? 'bg-c-db' : (role == 2) ? 'bg-c-g' : 'bg-c-lb'}`}
                                                >{GetRoleNames(role)}</button>
                                            </Col>

                                        </Row>
                                    </td>
                                    <td className="mobile-table-action-td">
                                        <Dropdown as={ButtonGroup}>
                                            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
                                                <span className="icon icon-sm">
                                                    <Image className="pe-auto" src={Group} />
                                                </span>
                                            </Dropdown.Toggle>
                                            {

                                                (status === 0 || status === null) ?

                                                    <Dropdown.Menu className="dropdown-action-show">

                                                        <Dropdown.Item className="f-16" onClick={() => AcceptRejectProfileRequest(column_name, true, access_by_user_uuid)}>
                                                            <Image src={Accept} className="pe-auto pr-2" /> Give access
                                                        </Dropdown.Item>
                                                        <Dropdown.Item className="f-16" onClick={() => AcceptRejectProfileRequest(column_name, false, access_by_user_uuid)}>
                                                            <Image src={Reject} className="pe-auto pr-2" /> Reject
                                                        </Dropdown.Item>

                                                    </Dropdown.Menu> :
                                                    (status === true) ?
                                                        <Dropdown.Menu className="dropdown-action-show">
                                                            <Dropdown.Item className="f-16">
                                                                <Image src={Accept} className="pe-auto pr-2" /> Accepted
                                                            </Dropdown.Item>
                                                        </Dropdown.Menu> :
                                                        (status === false) ?
                                                            <Dropdown.Menu className="dropdown-action-show">
                                                                <Dropdown.Item className="f-16">
                                                                    <Image src={Accept} className="pe-auto pr-2" /> Rejected
                                                                </Dropdown.Item>
                                                            </Dropdown.Menu> : ''
                                            }

                                        </Dropdown>
                                    </td>
                                </tr>
                                <tr id={`childTableRow-${access_by_user_uuid}-${index}`} style={hideChildTable}>
                                    <td colSpan="3">
                                        <div className="text-wrap">
                                            <h6 className="f-16 c-dg">Title : {" "}
                                                <span className="c-lg fw-normal">
                                                    {Capitalize(column_name?.replaceAll("_", " "))}
                                                </span>
                                            </h6>
                                            {/* <p>{Capitalize(column_name?.replaceAll("_", " "))}</p> */}

                                        </div>
                                        <div className="text-wrap">
                                            <h6 className="f-16 text-wrap c-dg">Created At : {" "}
                                                <span className="f-16 c-lg text-wrap fw-normal">
                                                    {created_at.includes(' ') === true ? created_at.split(' ')[0] : created_at.split('T')[0]}
                                                </span>
                                            </h6>
                                            {/* <p className="f-14">{created_at.includes(' ') === true ? created_at.split(' ')[0] : created_at.split('T')[0]}</p> */}
                                        </div>

                                    </td>
                                </tr>
                            </tbody>
                        )
                    }
                    )
                }

            </table>

        )
    }

    const TableBodyInvitationsRequestList = (props) => {
        const { id, case_name, case_status, created_at, email, first_name, profile_path, role, last_name, status } = props;

        return (
            <tr className={docStyle.requestListRow}>
                <td style={{ paddingLeft: 10 }}>
                    <div className={[docStyle.commonTypographyForRow]}>
                        <p>{Capitalize(case_name)}</p>
                    </div>
                </td>
                <td>
                    <Row className="align-items-center pe-none" >
                        <Col className="col-auto imageLeftLess custom-user-image">
                            {/* <a href="#" className="user-avatar pe-none" >
                                {(profile_path == '' || profile_path == undefined || profile_path == null) ?

                                    <div className={` bg-light rounded-circle d-flex justify-content-center align-items-center img-d-60`} style={{ minHeight: 60, minWidth: 60, width: 60, height: 60 }} >
                                        {((first_name != null) && (last_name != null)) ?
                                            <p className=" fw-bolder pt-2 f-14 c-lg" >{CommonServices.getNameCharByFullName(first_name + ' ' + last_name)}</p> :
                                            (first_name != null) ?
                                                <p className=" fw-bolder pt-2 f-14 c-lg" >{CommonServices.getNameCharByFullName(first_name + ' ' + last_name)}</p> :
                                                <p className=" fw-bolder pt-2 f-14 c-lg" >U</p>
                                        }
                                    </div>
                                    :
                                    <Image src={profile_path} className="rounded-circle img-d-60" />
                                }
                            </a> */}
                            <ProfileIconsModal userDetails={props} />
                        </Col>
                        <Col className="ms--2">
                            <h4 className="h6 mb-0 c-lg" >

                                {Capitalize(first_name) + " " + Capitalize(last_name)}
                            </h4>

                            {email && <>
                                <span className="f-16 ">{email}</span><br />
                            </>}

                            <button
                                className={`pe-none border-0 f-12 text-white rounded-1  ${(role == 4) ? 'bg-c-r' : (role == 3) ? 'bg-c-db' : (role == 2) ? 'bg-c-g' : ''}`}
                            >{GetRoleNames(role)}</button>
                        </Col>

                    </Row>
                </td>
                <td>
                    <div className={docStyle.commonTypographyForRow}>
                        <p>{created_at?.split(' ')[0]}</p>
                    </div>
                </td>

                <td>
                    <td>
                        <Dropdown as={ButtonGroup}>
                            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
                                <span className="icon icon-sm">
                                    <Image src={Group} className="pe-auto" />
                                </span>
                            </Dropdown.Toggle>
                            {

                                (status == 1) ?

                                    <Dropdown.Menu className="dropdown-action-show">

                                        <Dropdown.Item onClick={() => AcceptRejectInvitationRequest(id, 2)}>
                                            <Image src={Accept} className="pe-auto pr-2" /> Give access
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={() => AcceptRejectInvitationRequest(id, 3)}>
                                            <Image src={Reject} className="pe-auto pr-2" /> Reject
                                        </Dropdown.Item>

                                    </Dropdown.Menu> :
                                    (status == 2) ?
                                        <Dropdown.Menu className="dropdown-action-show">
                                            <Dropdown.Item >
                                                <Image src={Accept} className="pe-auto pr-2" /> Accepted
                                            </Dropdown.Item>
                                        </Dropdown.Menu> :
                                        (status == 3) ?
                                            <Dropdown.Menu className="dropdown-action-show">
                                                <Dropdown.Item >
                                                    <Image src={Accept} className="pe-auto pr-2" /> Rejected
                                                </Dropdown.Item>
                                            </Dropdown.Menu> : ''
                            }

                        </Dropdown>

                    </td>
                </td>
            </tr>
        );
    };

    const MobileTableInvitationList = () => {

        return (
            <table className="table">
                <thead className="mobile-thead">
                    <tr>
                        <th ></th>
                        <th className="f-14">Invited By</th>
                        <th className="mobile-table-action-th f-14" >Action</th>
                    </tr>
                </thead>
                {
                    requestList.map((props, index) => {
                        const { id, case_name, case_status, created_at, email, first_name, profile_path, role, last_name, status } = props;
                        return (
                            <tbody key={`client-${id}`}>
                                <tr>
                                    <td className=""><span className="plus-icon" id={`expandChildTable-${id}-${index}`}
                                        onClick={() => handleTableCollapse(`expandChildTable-${id}-${index}`, `childTableRow-${id}-${index}`)}
                                    >+</span></td>
                                    {/* <td>
                                        <Row className="align-items-center pe-none" >
                                            <Col className="col-auto imageLeftLess custom-user-image">
                                                <a href="#" className="user-avatar pe-none" >
                                                    {(profile_path == '' || profile_path == undefined || profile_path == null) ?

                                                        <div className={` bg-light rounded-circle d-flex justify-content-center align-items-center img-d-60`} style={{ minHeight: 60, minWidth: 60, width: 60, height: 60 }} >
                                                            {((first_name != null) && (last_name != null)) ?
                                                                <p className=" fw-bolder pt-2 f-14 c-lg" >{CommonServices.getNameCharByFullName(first_name + ' ' + last_name)}</p> :
                                                                (first_name != null) ?
                                                                    <p className=" fw-bolder pt-2 f-14 c-lg" >{CommonServices.getNameCharByFullName(first_name + ' ' + last_name)}</p> :
                                                                    <p className=" fw-bolder pt-2 f-14 c-lg" >U</p>
                                                            }
                                                        </div>
                                                        :
                                                        <Image src={profile_path} className="rounded-circle img-d-60" />
                                                    }
                                                </a>
                                            </Col>
                                            <Col className="ms--2">
                                                <h4 className="h6 mb-0" >
                                                    {Capitalize(first_name) + " " + Capitalize(last_name)}
                                                </h4>

                                                {email && <>
                                                    <span >{email}</span><br />
                                                </>}

                                                <button
                                                    className={`pe-none border-0 f-12 text-white rounded-1  ${(role == 4) ? 'bg-c-r' : (role == 3) ? 'bg-c-db' : (role == 2) ? 'bg-c-g' : ''}`}
                                                >{GetRoleNames(role)}</button>
                                            </Col>

                                        </Row>
                                    </td> */}

                                    <td>
                                        <Row className="align-items-center pe-none text-wrap img-block" >
                                            <Col className="col-auto imageLeftLess ">
                                                <a className="user-avatar pe-none" >
                                                    {(profile_path == '' || profile_path == undefined || profile_path == null) ?
                                                        <div className={` bg-light rounded-circle d-flex justify-content-center align-items-center img-d-60`} >
                                                            {((first_name != null) && (last_name != null)) ?
                                                                <p className=" fw-bolder pt-2 c-lg f-16" >{CommonServices.getNameCharByFullName(first_name + ' ' + last_name)}</p> :
                                                                (first_name != null) ?
                                                                    <p className=" fw-bolder pt-2 c-lg f-16" >{CommonServices.getNameCharByFullName(first_name + ' ' + last_name)}</p> :
                                                                    <p className=" fw-bolder pt-2 c-lg f-16">U</p>
                                                            }
                                                        </div>
                                                        :
                                                        <Image src={profile_path} className="rounded-circle img-d-60" />
                                                    }
                                                </a>
                                            </Col>
                                            <Col className="ms--2">
                                                <h4 className=" mb-0 c-lg f-16" >
                                                    {first_name !== null && last_name !== null ?
                                                        Capitalize(first_name) + " " + Capitalize(last_name) :
                                                        (first_name !== null) ? Capitalize(first_name) : 'User'
                                                    }
                                                </h4>
                                                <span className="f-16 c-lg">
                                                    {email && <>
                                                        <span className="text-break"
                                                        >{email}</span><br />
                                                    </>}
                                                </span>
                                                <button
                                                    className={`pe-none border-0 f-14 text-white rounded-1  ${(role == 4) ? 'bg-c-r' : (role == 3) ? 'bg-c-db' : (role == 2) ? 'bg-c-g' : 'bg-c-lb'}`}
                                                >{GetRoleNames(role)}</button>
                                            </Col>

                                        </Row>
                                    </td>
                                    <td className="mobile-table-action-td">
                                        <Dropdown as={ButtonGroup}>
                                            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
                                                <span className="icon icon-sm">
                                                    <Image src={Group} className="pe-auto" />
                                                </span>
                                            </Dropdown.Toggle>
                                            {

                                                (status == 1) ?

                                                    <Dropdown.Menu className="dropdown-action-show">

                                                        <Dropdown.Item className="f-16" onClick={() => AcceptRejectInvitationRequest(id, 2)}>
                                                            <Image src={Accept} className="pe-auto pr-2" /> Give access
                                                        </Dropdown.Item>
                                                        <Dropdown.Item className="f-16" onClick={() => AcceptRejectInvitationRequest(id, 3)}>
                                                            <Image src={Reject} className="pe-auto pr-2" /> Reject
                                                        </Dropdown.Item>

                                                    </Dropdown.Menu> :
                                                    (status == 2) ?
                                                        <Dropdown.Menu className="dropdown-action-show">
                                                            <Dropdown.Item className="f-16">
                                                                <Image src={Accept} className="pe-auto pr-2" /> Accepted
                                                            </Dropdown.Item>
                                                        </Dropdown.Menu> :
                                                        (status == 3) ?
                                                            <Dropdown.Menu className="dropdown-action-show">
                                                                <Dropdown.Item className="f-16">
                                                                    <Image src={Accept} className="pe-auto pr-2" /> Rejected
                                                                </Dropdown.Item>
                                                            </Dropdown.Menu> : ''
                                            }

                                        </Dropdown>
                                    </td>

                                </tr>
                                <tr id={`childTableRow-${id}-${index}`} style={hideChildTable}>
                                    <td colSpan="3">
                                        <div >
                                            <h6 className="f-16 c-dg">Case name : {" "}
                                                <span className="c-lg f-16 fw-normal">{case_name ? Capitalize(case_name) : 'Not available'}</span>
                                            </h6>
                                            {/* <p>{case_name ? Capitalize(case_name) : 'Not available'}</p> */}

                                        </div>
                                        <div >
                                            <h6 className="f-16 c-dg">Invitation Date : {" "}
                                                <span className="f-16 c-lg fw-normal">{created_at.includes(' ') === true ? created_at.split(' ')[0] : created_at.split('T')[0]}</span>
                                            </h6>

                                        </div>

                                    </td>
                                </tr>
                            </tbody>
                        )
                    }
                    )
                }

            </table>
        )
    }

    const TableBodyDocAccessRequestList = (props) => {
        const { documents_id, id, email, created_at, case_name, document_option, document_type, first_name,
            profile_path, role, last_name, required, url, status,
            client_first_name,client_profile_path, client_role, client_last_name,client_email
        } = props;

        return (
            <tr className={docStyle.requestListRow}>
                {/* <td className="pl-5" >
                    <div className={[docStyle.commonTypographyForRow]}>
                        <p>{Capitalize(case_name)}</p>
                    </div>
                </td> */}
                <td className="pl-5">
                    <div className={[docStyle.commonTypographyForRow]}>
                        <p>{Capitalize(document_option)}</p>
                        <p>Type: {Capitalize(document_type)}</p>
                    </div>
                </td>
                <td>
                    <Row className="align-items-center pe-none" >
                        <Col className="col-auto imageLeftLess custom-user-image">
                            <ProfileIconsModal userDetails={props} />
                        </Col>
                        <Col className="ms--2">
                            <h4 className="h6 mb-0 f-16 c-lg" >
                                {Capitalize(first_name?first_name:'User') + " " + Capitalize(last_name?last_name:'')}
                            </h4>

                            {email && <>
                                <span className="text-break f-16"

                                >{email}</span><br />
                            </>}

                            <button
                                className={`pe-none border-0 f-12 text-white rounded-1  ${(role == 4) ? 'bg-c-r' : (role == 3) ? 'bg-c-db' : (role == 2) ? 'bg-c-g' : 'bg-c-lb'}`}
                            >{GetRoleNames(role)}</button>
                        </Col>

                    </Row>
                </td>
                {/* <td>
                    <Row className="align-items-center pe-none" >
                        <Col className="col-auto imageLeftLess custom-user-image">
                            <ProfileIconsModal userDetails={{ profile_path: client_profile_path, first_name: client_first_name,last_name:client_last_name }} />

                        </Col>
                        <Col className="ms--2">
                            <h4 className="h6 mb-0 f-16 c-lg" >
                                {Capitalize(client_first_name?client_first_name:'User') + " " + Capitalize(client_last_name?client_last_name:'')}
                            </h4>
                            {client_email && <>
                                <span className="text-break f-16"
                                >{client_email}</span><br />
                            </>}
                            <button
                                className={`pe-none border-0 f-12 text-white rounded-1  ${(client_role == 4) ? 'bg-c-r' : (client_role == 3) ? 'bg-c-db' : (client_role == 2) ? 'bg-c-g' : 'bg-c-lb'}`}
                            >{GetRoleNames(client_role)}</button>
                        </Col>

                    </Row>

                </td> */}
                <td>
                    <div className={docStyle.commonTypographyForRow}>
                        <p>{created_at?.split(' ')[0]}</p>
                    </div>
                </td>

                <td>
                    <Dropdown as={ButtonGroup}>
                        <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
                            <span className="icon icon-sm">
                                <Image className="pe-none" src={Group} />
                            </span>
                        </Dropdown.Toggle>
                        {(url == '' || url == null) ?
                            <Dropdown.Menu className="dropdown-action-show">

                                    {/* <Dropdown.Item onClick={() => { setIsShowModal(true); setSelectedDocumentId(documents_id) }}>
                                        <Image className="pe-auto pr-2" src={Upload} /> Upload

                                    </Dropdown.Item> */}
                                <Dropdown.Item onClick={() => AcceptRejectDocRequest(documents_id, id, 2)}>
                                    <Image src={Reject} className="pe-auto pr-2" /> Reject
                                </Dropdown.Item>
                            </Dropdown.Menu>
                            :
                            (status == 0) && (url == '' || url == null || url == undefined) ?

                                <Dropdown.Menu className="dropdown-action-show">

                                    {/* <Dropdown.Item onClick={() => { setIsShowModal(true); setSelectedDocumentId(documents_id) }}>
                                        <Image src={Upload} className="pe-auto pr-2" onClick={() => { setIsShowModal(true); setSelectedDocumentId(documents_id) }} /> Upload

                                    </Dropdown.Item> */}
                                    <Dropdown.Item onClick={() => AcceptRejectDocRequest(documents_id, id, 2)}>
                                        <Image src={Reject} className="pe-auto pr-2" /> Reject
                                    </Dropdown.Item>

                                </Dropdown.Menu> :
                                (status == 0 || status === null) ?

                                    <Dropdown.Menu className="dropdown-action-show">

                                        <Dropdown.Item onClick={() => AcceptRejectDocRequest(documents_id, id, 1)}>
                                            <Image src={Accept} className="pe-auto pr-2" /> Give access
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={() => AcceptRejectDocRequest(documents_id, id, 2)}>
                                            <Image src={Reject} className="pe-auto pr-2" /> Reject
                                        </Dropdown.Item>

                                    </Dropdown.Menu>
                                    :
                                    (status == 1) ?
                                        <Dropdown.Menu className="dropdown-action-show">
                                            <Dropdown.Item >
                                                <Image src={Accept} className="pe-auto pr-2" /> Accepted
                                            </Dropdown.Item>
                                        </Dropdown.Menu> :
                                        (status == 2) &&
                                        <Dropdown.Menu className="dropdown-action-show">
                                            <Dropdown.Item >
                                                <Image src={Accept} className="pe-auto pr-2" /> Rejected
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                        }

                    </Dropdown>
                </td>
            </tr>
        );
    };

    const MobileTableDocAccessList = () => {
        return (
            <table className="table">
                <thead className="mobile-thead">
                    <tr>
                        <th ></th>
                        <th className="text-wrap f-14">Requested By</th>
                        <th className="mobile-table-action-th f-14" >Action</th>
                    </tr>
                </thead>
                {
                    requestList.map((props, index) => {
                        const { documents_id, id, email, created_at, case_name, document_option, document_type, first_name,
                            profile_path, role, last_name, required, url, status } = props;
                        return (
                            <tbody key={`client-${id}`}>
                                <tr>
                                    <td className=""><span className="plus-icon" id={`expandChildTable-${id}-${index}`}
                                        onClick={() => handleTableCollapse(`expandChildTable-${id}-${index}`, `childTableRow-${id}-${index}`)}
                                    >+</span></td>
                                    <td>
                                        <Row className="align-items-center pe-none text-wrap img-block" >
                                            <Col className="col-auto imageLeftLess ">
                                                <a className="user-avatar pe-none" >
                                                    {(profile_path == '' || profile_path == undefined || profile_path == null) ?
                                                        <div className={` bg-light rounded-circle d-flex justify-content-center align-items-center img-d-60`} >
                                                            {((first_name != null) && (last_name != null)) ?
                                                                <p className=" fw-bolder pt-2 c-lg f-16" >{CommonServices.getNameCharByFullName(first_name + ' ' + last_name)}</p> :
                                                                (first_name != null) ?
                                                                    <p className=" fw-bolder pt-2 c-lg f-16" >{CommonServices.getNameCharByFullName(first_name + ' ' + last_name)}</p> :
                                                                    <p className=" fw-bolder pt-2 c-lg f-16">U</p>
                                                            }
                                                        </div>
                                                        :
                                                        <Image src={profile_path} className="rounded-circle img-d-60" />
                                                    }
                                                </a>
                                            </Col>
                                            <Col className="ms--2">
                                                <h4 className=" mb-0 f-16 c-lg" >
                                                    {first_name !== null && last_name !== null ?
                                                        Capitalize(first_name) + " " + Capitalize(last_name) :
                                                        (first_name !== null) ? Capitalize(first_name) : 'User'
                                                    }
                                                </h4>
                                                <span className="f-16 c-lg">
                                                    {email && <>
                                                        <span className="text-break"
                                                        >{email}</span><br />
                                                    </>}
                                                </span>
                                                <button
                                                    className={`pe-none border-0 f-14 text-white rounded-1  ${(role == 4) ? 'bg-c-r' : (role == 3) ? 'bg-c-db' : (role == 2) ? 'bg-c-g' : 'bg-c-lb'}`}
                                                >{GetRoleNames(role)}</button>
                                            </Col>

                                        </Row>
                                    </td>
                                    <td className="mobile-table-action-td">

                                        <Dropdown as={ButtonGroup}>
                                            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
                                                <span className="icon icon-sm">
                                                    <Image className="pe-none" src={Group} />
                                                </span>
                                            </Dropdown.Toggle>
                                            {(url == '' || url == null) ?
                                                <Dropdown.Menu className="dropdown-action-show">

                                                    {/* <Dropdown.Item className="f-16" onClick={() => { setIsShowModal(true); setSelectedDocumentId(documents_id) }}>
                                                        <Image className="pe-auto pr-2" src={Upload} /> Upload
                                                    </Dropdown.Item> */}
                                                    <Dropdown.Item className="f-16" onClick={() => AcceptRejectDocRequest(documents_id, id, 2)}>
                                                        <Image src={Reject} className="pe-auto pr-2" /> Reject
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                                :
                                                (status == 0) && (url == '' || url == null || url == undefined) ?

                                                    <Dropdown.Menu className="dropdown-action-show">

                                                        {/* <Dropdown.Item className="f-16" onClick={() => { setIsShowModal(true); setSelectedDocumentId(documents_id) }}>
                                                            <Image src={Upload} className="pe-auto pr-2" onClick={() => { setIsShowModal(true); setSelectedDocumentId(documents_id) }} /> Upload
                                                        </Dropdown.Item> */}
                                                        <Dropdown.Item className="f-16" onClick={() => AcceptRejectDocRequest(documents_id, id, 2)}>
                                                            <Image src={Reject} className="pe-auto pr-2" /> Reject
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu> :
                                                    (status == 0 || status === null) ?

                                                        <Dropdown.Menu className="dropdown-action-show">

                                                            <Dropdown.Item className="f-16" onClick={() => AcceptRejectDocRequest(documents_id, id, 1)}>
                                                                <Image src={Accept} className="pe-auto pr-2" /> Give access
                                                            </Dropdown.Item>
                                                            <Dropdown.Item className="f-16" onClick={() => AcceptRejectDocRequest(documents_id, id, 2)}>
                                                                <Image src={Reject} className="pe-auto pr-2" /> Reject
                                                            </Dropdown.Item>

                                                        </Dropdown.Menu>

                                                        :
                                                        (status == 1) ?
                                                            <Dropdown.Menu className="dropdown-action-show">
                                                                <Dropdown.Item className="f-16">
                                                                    <Image src={Accept} className="pe-auto pr-2" /> Accepted
                                                                </Dropdown.Item>
                                                            </Dropdown.Menu> :
                                                            (status == 2) &&
                                                            <Dropdown.Menu className="dropdown-action-show">
                                                                <Dropdown.Item className="f-16">
                                                                    <Image src={Accept} className="pe-auto pr-2" /> Rejected
                                                                </Dropdown.Item>
                                                            </Dropdown.Menu>
                                            }

                                        </Dropdown>
                                    </td>
                                </tr>
                                <tr id={`childTableRow-${id}-${index}`} style={hideChildTable}>
                                    <td colSpan="3">
                                        <div className="text-wrap " >
                                            <h6 className="f-16">Title :
                                                <span className="f-16 c-lg fw-normal">{" "}{document_option ? Capitalize(document_option) : 'Not available'}</span>
                                            </h6>
                                            <h6 className="f-16">Type :
                                                <span className="f-16 c-lg fw-normal">{" "}{document_type ? Capitalize(document_type) : 'Not available'}</span>
                                            </h6>

                                        </div>
                                        {/* <div className="text-wrap ">
                                            <h6 className="f-16">Case name : {" "}
                                                <span className="c-lg f-16 fw-normal">{((case_name == null) || (case_name == '')) ? 'Not available' : Capitalize(case_name)}</span>
                                            </h6>
                                        </div> */}
                                        <div className="text-wrap ">
                                            <h6 className="f-16">Requested Date : {" "}
                                                <span className="f-16 c-lg fw-normal">{created_at.includes(' ') === true ? created_at.split(' ')[0] : created_at.split('T')[0]}</span></h6>

                                        </div>
                                    </td>
                                </tr>

                            </tbody>
                        )
                    }
                    )
                }

            </table>
        )
    }

    const AcceptRejectDocRequest = async (documents_id, id, no) => {
        const requestBody = {
            status: no
        }
        const response = await CreateDocStatusChangeApi(requestBody, documents_id, id);
        if (response?.status_code == 1) {
            setAlertsArray([...alertsArray, { id: `document_request${alertsArray.length}`, type: 'success', message: response?.message }]);
        }
        else {
            setAlertsArray([...alertsArray, { id: `document_request${alertsArray.length}`, type: 'danger', message: response?.message }]);
        }
        setCount(Math.floor(Math.random() * 1000));
    }


    const AcceptRejectProfileRequest = async (columnName, requestStatus, id) => {
        const requestBody = {
            column_name: columnName,
            status: requestStatus,
            access_by_user_uuid: id
        }
        const response = await CreateProfileStatusChangeApi(requestBody);
        if (response?.status_code == 1) {
            setAlertsArray([...alertsArray, { id: `profile_request${alertsArray.length}`, type: 'success', message: response?.message }]);
        }
        else {
            setAlertsArray([...alertsArray, { id: `profile_request${alertsArray.length}`, type: 'danger', message: response?.message }]);
        }
        setCount(Math.floor(Math.random() * 1000));
    }


    const AcceptRejectInvitationRequest = async (id, status) => {
        const requestBody = {
            id: id,
            status: status
        }
        const response = await CreateInviteUserStatusChangeApi(requestBody);
        if (response?.status_code == 1) {
            setAlertsArray([...alertsArray, { id: `invitation_request${alertsArray.length}`, type: 'success', message: response?.message }]);
        }
        else {
            setAlertsArray([...alertsArray, { id: `invitation_request${alertsArray.length}`, type: 'danger', message: response?.message }]);
        }
        setCount(Math.floor(Math.random() * 1000));
    }


    const GetRecievedRequestList = async (requestType) => {
        if (((search == '') && (isSearchChange == false)) || (activeItemForProfile != previousActiveItemValue) || (activeItemForDoc != previousActiveItemValue) ||
            (activeItemForInvitation != previousActiveItemValue) || (activeItemForAccessRequest != previousActiveItemValue)) {
            setIsLoading(true);
        }
        var requestList;
        if (requestType == 1) {
            requestList = await GetCustomerRecievedProfileRequestsApi(startingIndexForProfile, pageLimit, search);
            setTotalRequestCountForProfile(requestList[0]?.count);
            const pages = Math.ceil(requestList[0]?.count / pageLimit);

            setTotalPagesForProfile(pages);
        }
        else if (requestType == 2) {
            requestList = await GetCustomerRecievedDocRequestsApi(startingIndexForDoc, pageLimit, search);
            setTotalRequestCountForDoc(requestList[0]?.count);
            const pages = Math.ceil(requestList[0]?.count / pageLimit);

            setTotalPagesForDoc(pages);
        }
        else if (requestType == 3) {
            requestList = await GetCustomerRecievedInviteUserRequestsApi(startingIndexForInvitation, pageLimit, search);
            setTotalRequestCountForInvitation(requestList[0]?.count);
            const pages = Math.ceil(requestList[0]?.count / pageLimit);

            setTotalPagesForInvitation(pages);
        }
        else if (requestType == 4) {
            requestList = await GetCustomerRecievedDocAccessRequestsApi(startingIndexForAccessRequest, pageLimit, search);
            setTotalRequestCountForAccessRequest(requestList[0]?.count);
            const pages = Math.ceil(requestList[0]?.count / pageLimit);

            setTotalPagesForAccessRequest(pages);
        }

        // console.log("documentsList", requestList, " count", requestList[0]?.count, " pageLimit", pageLimit);
        setRequestList(requestList && requestList[0] && requestList[0]?.results);
        setIsLoading(false);
    }


    //#endregion

    //#region JSX
    return (
        <Fragment>
            {isLoading === true ? <DataLoading /> :
                ((requestList?.length == null) || (requestList?.length == undefined) || (requestList?.length == 0)) ?
                    <NoDataFound /> :

                    <>
                        {(singleScreen === false) ?
                            <Card border="light" className="shadow-sm mb-4 ">
                                <Card.Body className="pb-0 customer-requests-table-block">
                                    <Table responsive className="customer-requests-table table-centered custom-table-responsive table-nowrap rounded mb-0">
                                        <thead className="thead-light border-bottom custom-table-heading">
                                            <tr>
                                                {requestType?.id != 1 && requestType?.id != 4 &&
                                                    <th className="border-0  c-lg f-14" >Case Name</th>
                                                }
                                                {requestType?.id != 3 &&
                                                    <th className="border-0  c-lg f-14" >Title</th>
                                                }
                                                <th className="border-0  c-lg f-14" >{requestType.id != 3 ? "Requested By" : "Invited By"}</th>
                                                {requestType?.id != 3 && requestType?.id != 4 && requestType?.id != 1 &&

                                                     <th className="border-0  c-lg f-14" >Requesting</th>
                                                }
                                                <th className="border-0  c-lg f-14" >{requestType.id != 3 ? "Requested Date" : "Invitation Date"}</th>
                                                <th className="border-0  c-lg f-14" >Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {requestList?.map(item => {
                                                if (requestType.id == 1) {
                                                    return (
                                                        <TableBodyProfileRequestList key={item.id} {...item} />
                                                    )
                                                }
                                                else if (requestType.id == 2) {
                                                    return (
                                                        <TableBodyDocRequestList key={item.id} {...item} />
                                                    )
                                                }
                                                else if (requestType.id == 3) {
                                                    return (
                                                        <TableBodyInvitationsRequestList key={item.id} {...item} />
                                                    )
                                                }
                                                else if (requestType.id == 4) {
                                                    return (
                                                        <TableBodyDocAccessRequestList key={item.id} {...item} />
                                                    )
                                                }
                                            }

                                            )
                                            }
                                        </tbody>
                                    </Table>
                                    <div className="pagenation-custom-block">

                                        {(requestType.id == 1) ?
                                            <>
                                                <div className="pagination-msg">
                                                    {`Showing ${startingIndexForProfile} to ${Math.min(
                                                        startingIndexForProfile + pageLimit - 1,
                                                        totalRequestCountForProfile
                                                    )} of ${totalRequestCountForProfile} entries`}
                                                </div>
                                                <CustomPagination isSingleScreen={false} activeItem={activeItemForProfile} setActiveItem={setActiveItemForProfile} totalPages={totalPagesForProfile} setStartingIndex={setStartingIndexForProfile} />
                                            </>
                                            : (requestType.id == 2) ?
                                                <>
                                                    <div className="pagination-msg">
                                                        {`Showing ${startingIndexForDoc} to ${Math.min(
                                                            startingIndexForDoc + pageLimit - 1,
                                                            totalRequestCountForDoc
                                                        )} of ${totalRequestCountForDoc} entries`}
                                                    </div>
                                                    <CustomPagination isSingleScreen={false} activeItem={activeItemForDoc} setActiveItem={setActiveItemForDoc} totalPages={totalPagesForDoc} setStartingIndex={setStartingIndexForDoc} />
                                                </>
                                                : (requestType.id == 3) ?
                                                <>
                                                    <div className="pagination-msg">
                                                        {`Showing ${startingIndexForInvitation} to ${Math.min(
                                                            startingIndexForInvitation + pageLimit - 1,
                                                            totalRequestCountForInvitation
                                                        )} of ${totalRequestCountForInvitation} entries`}
                                                    </div>
                                                    <CustomPagination isSingleScreen={false} activeItem={activeItemForInvitation} setActiveItem={setActiveItemForInvitation} totalPages={totalPagesForInvitation} setStartingIndex={setStartingIndexForInvitation} />
                                                </>
                                                : (requestType.id == 4) &&
                                                <>
                                                    <div className="pagination-msg">
                                                        {`Showing ${startingIndexForAccessRequest} to ${Math.min(
                                                            startingIndexForAccessRequest + pageLimit - 1,
                                                            totalRequestCountForAccessRequest
                                                        )} of ${totalRequestCountForAccessRequest} entries`}
                                                    </div>
                                                    <CustomPagination isSingleScreen={false} activeItem={activeItemForAccessRequest} setActiveItem={setActiveItemForAccessRequest} totalPages={totalPagesForAccessRequest} setStartingIndex={setStartingIndexForAccessRequest} />
                                                </>

                                        }
                                    </div>
                                </Card.Body>
                            </Card>
                            :

                            //Mobile Table
                            <div className="bg-white">
                                {
                                    (requestType.id == 1) ?
                                        <MobileTableProfileList /> :
                                        (requestType.id == 2) ?
                                            <MobileTableDocList /> :
                                            (requestType.id == 3) ?
                                            <MobileTableInvitationList />:
                                            (requestType.id == 4) &&
                                            <MobileTableDocAccessList />
                                }

                                {/* Pagination block */}
                                <div className="pagenation-custom-block pagination-padding">

                                    {(requestType.id == 1) ?
                                        <>
                                            <div className="pagination-msg f-16">
                                                {`Showing ${startingIndexForProfile} to ${Math.min(
                                                    startingIndexForProfile + pageLimit - 1,
                                                    totalRequestCountForProfile
                                                )} of ${totalRequestCountForProfile} entries`}
                                            </div>
                                            <CustomPagination isSingleScreen={true} activeItem={activeItemForProfile} setActiveItem={setActiveItemForProfile} totalPages={totalPagesForProfile} setStartingIndex={setStartingIndexForProfile} />
                                        </>
                                        : (requestType.id == 2) ?
                                            <>
                                                <div className="pagination-msg f-16">
                                                    {`Showing ${startingIndexForDoc} to ${Math.min(
                                                        startingIndexForDoc + pageLimit - 1,
                                                        totalRequestCountForDoc
                                                    )} of ${totalRequestCountForDoc} entries`}
                                                </div>
                                                <CustomPagination isSingleScreen={true} activeItem={activeItemForDoc} setActiveItem={setActiveItemForDoc} totalPages={totalPagesForDoc} setStartingIndex={setStartingIndexForDoc} />
                                            </>
                                            : (requestType.id == 3) &&
                                            <>
                                                <div className="pagination-msg fs-16 page-text">
                                                    {`Showing ${startingIndexForInvitation} to ${Math.min(
                                                        startingIndexForInvitation + pageLimit - 1,
                                                        totalRequestCountForInvitation
                                                    )} of ${totalRequestCountForInvitation} entries`}
                                                </div>
                                                <CustomPagination isSingleScreen={true} activeItem={activeItemForInvitation} setActiveItem={setActiveItemForInvitation} totalPages={totalPagesForInvitation} setStartingIndex={setStartingIndexForInvitation} />
                                            </>
                                    }
                                </div>
                            </div>

                        }
                    </>
            }
            {/* <Toaster position="top-right" reverseOrder={false} /> */}
            <FileUploadModal setCount={setCount} show={isShowModal} documentId={selectedDocumentId} handleClose={uploadHandleClose} isReceivedRequest={true} FunctionToRefreshAfterUpload={GetRecievedRequestList} setModalAlertsArray={setAlertsArray} modalAlertsArray={alertsArray} />
        </Fragment>
    )
    //#endregion


}