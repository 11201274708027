import React, { Fragment, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Col,
  Row,
  Card,
  Image,
  OverlayTrigger,
  Tooltip,
  Table,
} from "@themesberg/react-bootstrap";
import { useHistory } from "react-router-dom";
import { toast } from "react-hot-toast";
import { Breadcrumb, Form, InputGroup, Dropdown, ButtonGroup, Button } from "@themesberg/react-bootstrap";
import { faHome, faSearch } from "@fortawesome/free-solid-svg-icons";
import styles from "../../../views/cases/Index.module.css";

//Local imports, core styles, vendor styles
import { GetBrokerRequestListApi } from "../../../actions/RequestActions.jsx";
import { DataLoading } from "../../../components/air-migrate/Loader.jsx";
import {
  Capitalize,
  CapitalizeAllWords,
} from "../../../common-components/StringConversions.jsx";
import NoDataFound from "../../../components/air-migrate/NoDataFound.jsx";
import CustomPagination from "../../../common-components/CustomPagination.jsx";
// import styles from "../../../views/cases/immigration-case-list/Index.css";
// import "../../../assets/global-css/Index.css";
import "../../../scss/volt.scss";
import "react-datetime/css/react-datetime.css";
import { profilePath } from "../../../config.tsx";
import CommonServices from "../../../common-components/CommonServices.jsx";
import Trash from '../../../assets/img/icons/Airmigrate icons/16px/Trash.png';
import Eye from '../../../assets/img/icons/Airmigrate icons/16px/View.png';
import Group from '../../../assets/img/icons/Airmigrate icons/16px/Group.png';
import Chat from '../../../assets/img/icons/Airmigrate icons/16px/Chat.png';
import { HomepageUrl } from "../../../utilities/helpers/Constant.jsx";
import ProfileIconsModal from "../../../components/air-migrate/ProfileIconsModal.jsx";
import CommonBreadcrumb from "../../../common-components/CommonBreadcrumb.jsx";

const BrokerRequestList = ({ search, isSearchChange }) => {
  //#region States and variables
  const history = useHistory();
  const [brokerRequestList, setBrokerRequestList] = useState([]);
  const [copiedbrokerRequestList, setCopiedbrokerRequestList] = useState([]);
  const [startingIndex, setStartingIndex] = useState(1);
  const [roleId, setRoleId] = useState("");
  const [pageLimit, setPageLimit] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [previousActiveItemValue, setPreviousItemActiveValue] = useState(1);

  //pagination relates states
  const [activeItem, setActiveItem] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalClientCount, setTotalClientCount] = useState(0);
  const [count, setCount] = useState(0);
  const [consultantName, setConsultantName] = useState(
    Capitalize(localStorage.getItem("userName"))
  );

  // Login user uuid for chat link
  const [loginUserId, setLoginUserId] = useState("");

  //States to manage mobile ui
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const [singleScreen, setSingleScreen] = useState(false);

   //Breadcrumb state
   const [breadcrumbArray, setBreadcrumbArray] = useState([
    { name: 'Home', url: HomepageUrl },
    { name: 'Case Requests', url: '' }
  ]);

  //#endregion

  //#region Styles
  const filterStyle = {
    marginRight: "2px",
    marginLeft: "3px",
    // color: '#0184fe'
  };
  //#endregion

  //#region useEffect

  useEffect(() => {
    const amUserId = localStorage.getItem("amUserId");
    if (amUserId) {
      setLoginUserId(amUserId);
    }
    GetBrokerRequestList();

    //functionality to set collapsible table 
    window.addEventListener('resize', updateDimension);
    updateDimension();

    // removing eventlistener for no furhter effect after 
    return (() => {
      window.removeEventListener('resize', updateDimension);
    })
  }, [searchText, activeItem]);
  //#endregion

  //#region Functions

  const hideChildTable = {
    display: 'none'
  }


  //#region Functions (all document list table body)
  const updateDimension = () => {
    setScreenSize(getCurrentDimension());
    var value = getCurrentDimension();
    // set a variable true when screen width reaches <768px
    if (value.width <= 767) {
      setSingleScreen(true)
    } else {
      setSingleScreen(false)
    }
  }

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight
    }
  }

  const handleTableCollapse = (parentId, childId) => {
    const childElement = document.getElementById(childId);
    const parentElement = document.getElementById(parentId);
    console.log("childElement, parentElement and content", parentId, childId, childElement, parentElement);
    if (parentElement.innerHTML === '+') {
      childElement.style.display = 'table-row';
      parentElement.innerHTML = '-';
    }
    else {
      childElement.style.display = 'none';
      parentElement.innerHTML = '+';
    }
  }

  const TableRow = ({
    case_name,
    first_name,
    last_name,
    status,
    case_id,
    case_status,
    profile_path,
  }) => {
    return (
      <tr>
        <td>
          <div className="case-name-content f-16">
            {CapitalizeAllWords(case_name || "")}
          </div>
        </td>
        <td>
          <div className="agent-name-td agent-td">
          {/* <div className="col-auto imageLeftLess custom-user-image"> */}
          <div className="me-2">
              {/* <a className="user-avatar img-case-cursor">
                {profile_path == "" ||
                  profile_path == undefined ||
                  profile_path == null ? (

                  // <Image src={profilePath} className="rounded-circle" />
                  <div className={`bg-light rounded-circle d-flex justify-content-center align-items-center p-3`} style={{ minHeight: 28, minWidth: 28, width: 28, height: 28 }} >
                    {first_name !== null && last_name !== null ?
                      <small className=" fw-bolder m-0 text-black">{CommonServices.getNameCharByFullName(first_name + ' ' + last_name)}</small> :
                      (first_name !== null) ?
                        <small className=" fw-bolder m-0 text-black">{CommonServices.getNameCharByFullName(first_name)}</small>
                        :
                        <small className=" fw-bolder m-0 text-black">U</small>
                    }
                  </div>
                ) : (
                  <Image src={profile_path} className="rounded-circle" style={{ minHeight: 28, minWidth: 28, width: 28, height: 28 }} />

                )}
              </a> */}
              <ProfileIconsModal userDetails={{profile_path:profile_path, first_name: first_name, last_name: last_name}}/>
            </div>
            <div className="case-content-block img-case-cursor ">
              <h4 className="case-name-content f-16">
                {/* {first_name || ""} {last_name || ""}{" "} */}
                {first_name !== null && last_name !== null ?

                  Capitalize(first_name) + " " + Capitalize(last_name) :
                  first_name !== null ? Capitalize(first_name) : 'User'
                }
              </h4>
            </div>
          </div>
        </td>

        <td>
          <p className="f-16">{status === false
            ? case_status === 0
              ? "Pending"
              : case_status === 2
                ? "Rejected"
                : ""
            : ""}</p>
          {/* <div className={styles.statusbutton}>

            <button
              style={{
                backgroundColor:
                  status === false
                    ? case_status === 0
                      ? "#FFB136"
                      : case_status === 2
                        ? "#FA5252"
                        : ""
                    : "",
              }}
            >
              {status === false
                ? case_status === 0
                  ? "Pending"
                  : case_status === 2
                    ? "Rejected"
                    : ""
                : ""}
            </button>

          </div> */}
        </td>
        <td>
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <span className="icon icon-sm">
                <Image src={Group} style={{ cursor: "pointer" }} />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-action-show">
              <Dropdown.Item className="f-16"
                onClick={() => {
                  history.push(`/chat`, {
                    userId: loginUserId,
                    caseId: case_id,
                    caseName: case_name,
                  });
                }}
              >
                <Image  src={Eye} style={{ cursor: "pointer" }} /> View
              </Dropdown.Item>

            </Dropdown.Menu>
          </Dropdown>
          {/* <div className={styles.actionbuttongroup}>
            <div className={styles.viewbtn}>
              <button
                onClick={() => {
                  history.push(`/chat`, {
                    userId: loginUserId,
                    caseId: case_id,
                    caseName: case_name,
                  });
                }}
              >
                View Requests
              </button>
            </div>
          </div> */}
        </td>
      </tr>
    );
  };

  const MobileBrokerRequestList = () => {

    return (
      <table className="table">
        <thead className="mobile-thead">
          <tr>
            <th ></th>
            <th className="f-14">Requested By</th>
            <th className="mobile-table-action-th f-14" >Action</th>
          </tr>
        </thead>
        {
          brokerRequestList.map(props => {
            const { case_name, first_name, last_name, status, case_id, case_status, profile_path, } = props;
            return (
              <tbody key={`client-${case_id}`}>
                <tr>
                  <td ><span className="plus-icon" id={`expandChildTable-${case_id}`}
                    onClick={() => handleTableCollapse(`expandChildTable-${case_id}`, `childTableRow-${case_id}`)}
                  >+</span></td>
                  <td>
                    <Row className="align-items-center pe-none text-wrap img-block" >
                      <Col className="col-auto imageLeftLess ">
                        {/* <a className="user-avatar pe-none" >
                          {(profile_path == '' || profile_path == undefined || profile_path == null) ?
                            <div className={` bg-light  d-flex justify-content-center align-items-center img-d-35 pt-2`} >
                              {((first_name != null) && (last_name != null)) ?
                                <p className=" fw-bolder pt-2 c-lg f-16" >{CommonServices.getNameCharByFullName(first_name + ' ' + last_name)}</p> :
                                (first_name != null) ?
                                  <p className=" fw-bolder pt-2 c-lg f-16" >{CommonServices.getNameCharByFullName(first_name + ' ' + last_name)}</p> :
                                  <p className=" fw-bolder pt-2 c-lg f-16">U</p>
                              }
                            </div>
                             :
                            <Image src={profile_path} className=" img-d-35" />
                          } 
                        </a> */}
                        <ProfileIconsModal userDetails={props}/>
                      </Col>
                      <Col className="ms--2">
                        <h4 className=" mb-0 f-16 c-lg" >
                          {first_name !== null && last_name !== null ?

                            Capitalize(first_name) + " " + Capitalize(last_name) :
                            first_name !== null ? Capitalize(first_name) : 'User'
                          }
                        </h4>

                      </Col>

                    </Row>
                  </td>
                  <td className="mobile-table-action-td">

                    <Dropdown as={ButtonGroup}>
                      <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
                        <span className="icon icon-sm">
                          <Image className="pe-none" src={Group} />
                        </span>
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dropdown-action-show pe-auto">
                        <Dropdown.Item className="f-16"
                          onClick={() => {
                            history.push(`/chat`, {
                              userId: loginUserId,
                              caseId: case_id,
                              caseName: case_name,
                            });
                          }}>
                          <Image className="pe-auto pa-r-5" src={Eye} /> View
                        </Dropdown.Item>

                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
                <tr id={`childTableRow-${case_id}`} style={hideChildTable}>
                  <td colSpan="3">
                    <div className="text-wrap wb">
                      <h6 className="f-16 c-dg  text-wrap">Case name : {" "}
                        <span className="c-lg f-16 text-wrap fw-normal">{case_name ? Capitalize(case_name) : 'Not available'}</span>
                      </h6>
                    </div>
                    <div className="text-wrap ">
                      <h6 className="f-16 c-dg">Status : {" "}
                        <span className="c-lg f-16 fw-normal">{status === false ? case_status === 0 ? "Pending" : case_status === 2
                          ? "Rejected" : "" : ""}</span>
                      </h6>
                    </div>
                  </td>
                </tr>
              </tbody>
            )
          }
          )
        }

      </table>
    )
  }

  const GetBrokerRequestList = async () => {
    if (
      (search == "" && isSearchChange == false) ||
      activeItem != previousActiveItemValue
    ) {
      setIsLoading(true);
    }
    const broker = await GetBrokerRequestListApi(
      startingIndex,
      pageLimit,
      searchText
    );
    console.log("broker.....", broker);
    // localStorage.setItem("role",4);
    if (broker?.status_code === 1) {
      const brokerData = broker.data[0].results;
      console.log("brokerData", brokerData);
      // const copiedData = { ...clientData };
      setBrokerRequestList(brokerData);
      // setCopiedbrokerRequestList([copiedData]);

      setTotalClientCount(broker.data[0]?.count);
      const pages = Math.ceil(broker.data[0]?.count / pageLimit);
      console.log(" total pages", pages);
      setTotalPages(pages);
    } else {
      toast.error(broker.message, { duration: 4000 });
    }
    setIsLoading(false);
    // console.log("broker list", broker);
  };






  //#endregion

  return (
    <Fragment>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-0 pb-2">
        <div className="d-block  mb-md-0">
          {/* <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item style={{ curosr: "pointer" }} onClick={() => history.push(HomepageUrl)}>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Case Requests</Breadcrumb.Item>
          </Breadcrumb> */}

          <CommonBreadcrumb breadcrumbArray={breadcrumbArray}/>
        </div>
      </div>

      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">
          <Col xs={12} sm={6} md={6} lg={4} xl={4}>
            <div>
              <h4 className={`${singleScreen ? 'f-18' : ''}`}>Case Requests</h4>
            </div>
          </Col>

          <Col xs={12} sm={6} md={6} lg={4} xl={4}>
            <InputGroup>
              <InputGroup.Text className={styles.caselistSearchBox}>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <Form.Control
                type="text"
                placeholder="Search"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                className={[styles.caselistSearchBox, singleScreen && "f-16"]}
              />
            </InputGroup>
          </Col>
        </Row>
      </div>
      {isLoading === true ? (
        <DataLoading />
      ) : brokerRequestList.length === 0 ? (
        <NoDataFound />
      ) : <>
        {singleScreen === false ?
          <Card border="light" className="shadow-sm mb-4 ">
            <Card.Body className="pb-0 custom-table-body">
              <Table
                responsive
                className="table-centered custom-table-responsive table-nowrap rounded mb-0"
              >
                <thead className="thead-light border-bottom">
                  <tr>
                    <th className="border-0 c-lg f-14" >
                      Case Name{" "}
                    </th>
                    <th className="border-0 c-lg f-14" >
                      Requested By
                    </th>
                    <th className="border-0 c-lg f-14" >
                      Status
                    </th>
                    <th
                      className="border-0 action-block-custom-width c-lg f-14"
                     
                    >
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {brokerRequestList.map((broker) => (
                    <TableRow key={`broker-${broker.id}`} {...broker} />
                  ))}
                </tbody>
              </Table>
              <div className="pagenation-custom-block">
                <div className="pagination-msg">
                  {`Showing ${startingIndex} to ${Math.min(
                    startingIndex + pageLimit - 1,
                    totalClientCount
                  )} of ${totalClientCount} entries`}
                </div>
                <CustomPagination
                  activeItem={activeItem}
                  setActiveItem={setActiveItem}
                  totalPages={totalPages}
                  setStartingIndex={setStartingIndex}
                  isSingleScreen={false}
                />
              </div>
            </Card.Body>
          </Card>
          :
          <div className="bg-white">
            <MobileBrokerRequestList />
            <div className="pagenation-custom-block pagination-padding">
              <div className="pagination-msg f-16">
                {`Showing ${startingIndex} to ${Math.min(
                  startingIndex + pageLimit - 1,
                  totalClientCount
                )} of ${totalClientCount} entries`}
              </div>
              <CustomPagination isSingleScreen={true} activeItem={activeItem} setActiveItem={setActiveItem} totalPages={totalPages} setStartingIndex={setStartingIndex} />
            </div>

          </div>

        }
      </>

      }
    </Fragment>
  );
  //#endregion
};
export default BrokerRequestList;
