import React from "react";
import { useHistory } from "react-router-dom";
import { PROFILE_PATH } from "../../config.tsx";

const AgentCard = ({isUserLogin, profilePath, firstName, lastName, description, userUuid,isConsultant}) => {
    //#region consts  start
    const history = useHistory();
    //#endregion consts end

    //#region event handlers start
    const handleClick = (userUuid) => {
        if(!isConsultant){
            if (isUserLogin) {
                history.push(`agent-details/${userUuid}`); 
            } else {
                history.push("/login");
            }
        }
      };
      //#endregion event handlers end

    //#region render start
    return (  
            <div className="card-section">
                <img
                    src={profilePath ? profilePath : PROFILE_PATH}
                    alt={firstName}
                    className="consultant-images"
                    onClick={() => handleClick(userUuid)}
                />
                <div className="consultant-details">
                    <div className="inner-details">
                        <h4 className={`consultant-name ${!isUserLogin ? 'blurred-name' : ''}`}
                        onClick={() => handleClick(userUuid)}>{firstName} {lastName}</h4>
                        <p className="consultant-designation" dangerouslySetInnerHTML={{__html:description}}>{}</p>
                    </div>
                    <button className="image-button">
                        <i className="fa-solid fa-star starclass"></i>4.5
                    </button>
                </div>
            </div>
    );
};
//#endregion render end
export default AgentCard;
