import React, { useState, useEffect } from "react";
import { Card} from "@themesberg/react-bootstrap";
import styles from "../Index.module.css";
import { Default_Country } from "../../../config.tsx";
import PhoneInput from "react-phone-input-2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { GetAgentOtherDetailsApi, UpdateAgentOtherDetailsApi } from "../../../actions/MyProfileActions.jsx";
import CommonServices from "../../../common-components/CommonServices.jsx";

const OtherContactDetails = ({ isEditProfile, UserId, alertsArray, setAlertsArray }) => {
    // user Id stete here
    const [amUserId, setAmUserId] = useState(UserId ? UserId : localStorage.getItem('amUserId'));
    // clicked field name const
    const [clickedField, setClickedField] = useState('');
    // form fields usestate
    const [firstname, setFirstName] = useState();
    const [lastname, setLastName] = useState();
    const [email, setEmail] = useState();
    const [street, setStreet] = useState();
    const [city, setCity] = useState();
    const [province, setProvince] = useState();
    const [postal, setPostal] = useState();
    const [country, setCountry] = useState();
    // mobile phone usestae here
    const [sCode, setSCode] = useState();
    const [sPhone, setSPhone] = useState();
    //#region useEffect start
    useEffect(() => {
        GetAgentOtherDetails();
    }, [])
    //#endregion useEffect end
    // getting agent other details  here 
    const GetAgentOtherDetails = async () => {
        const response = await GetAgentOtherDetailsApi(amUserId);
        if (response?.status_code == 1) {
            getAndUpdateFormField(response?.data?.other_details)
        } else {
            console.error(response?.message)
        }
    }

    // function to handle phone input
    const HandleInputChange = (value, country) => {
        if (country.dialCode) {
            setSCode(country.dialCode);
        }
        var phone = value.slice(country.dialCode.length);
        if (phone) {
            setSPhone(phone);
        }
    }

    // updating form fields
    const UpdateFormField = async () => {
        const submitData = {
            "other_details": {
                "other_first_name": firstname,
                "other_last_name": lastname,
                "other_email": email,
                "other_phone": sPhone,
                "other_phone_country_code": sCode,
                "other_city": city,
                "other_province": province,
                "other_address": street,
                "other_postal_code": postal,
                "other_country": country
            }
        }
        const response = await UpdateAgentOtherDetailsApi(submitData);
        if (response.status_code == 1) {
            setClickedField('')
            GetAgentOtherDetails();
            setAlertsArray([{ id: `alert${alertsArray.length}`, type: 'success', message: response?.message }, ...alertsArray]);
        } else {
            setAlertsArray([{ id: `alert${alertsArray.length}`, type: 'danger', message: response?.message }, ...alertsArray]);
        }
    }
    // function to update the data into formField
    const getAndUpdateFormField = (data) => {
        if (data) {
            setFirstName(data?.other_first_name ? data?.other_first_name : "");
            setLastName(data?.other_last_name ? data?.other_last_name : "");
            setEmail(data?.other_email ? data?.other_email : "");
            setSCode(data?.other_phone_country_code ? data?.other_phone_country_code : "");
            setSPhone(data?.other_phone ? data?.other_phone : "");
            setStreet(data?.other_address ? data?.other_address : "");
            setCity(data?.other_city ? data?.other_city : "");
            setPostal(data?.other_postal_code ? data?.other_postal_code : "");
            setProvince(data?.other_province ? data?.other_province : "");
            setCountry(data?.other_country ? data?.other_country : "")
        }
    }
//#region  render start
    return (
        <>
            <Card>
                <Card.Header>
                    <div className={styles.documentCardHeader}>
                        <h3 className="c-lg">Other Contact Details</h3>
                    </div>
                </Card.Header>
                <Card.Body className="p-3">
                    <div className={styles.mainFormContainer}>
                        {clickedField == 'fname' ? (
                            <div>
                                <label className="mt-2">First Name</label>
                                <input type="text" className="form-control mt-1" placeholder="Enter First Name" value={firstname} onChange={(e) => { setFirstName(e.target.value) }} onBlur={UpdateFormField} />
                            </div>
                        ) : (
                            <div className="w-100 d-flex justify-content-between mt-3">
                                <p className={styles.profileText}><strong className={styles.innerAddressHeading}>First Name :</strong> {firstname ? firstname : 'Not Available'}</p>
                                {isEditProfile &&
                                    <div className=" align-self-start">
                                        <FontAwesomeIcon icon={faPen} className={styles.editIconStyle} onClick={() => { setClickedField('fname'); }} title="Edit first name" />
                                    </div>
                                }
                            </div>
                        )}
                        <hr className="m-0" />
                        {clickedField == 'lname' ? (
                            <div>
                                <label className="mt-2">Last Name</label>
                                <input type="text" className="form-control mt-1" placeholder="Enter Last Name" value={lastname} onChange={(e) => { setLastName(e.target.value) }} onBlur={UpdateFormField} />
                            </div>
                        ) : (
                            <div className="w-100 d-flex justify-content-between mt-3">
                                <p className={styles.profileText}><strong className={styles.innerAddressHeading}>Last Name :</strong>{lastname ? lastname : 'Not Available'}</p>
                                {isEditProfile &&
                                    <div className=" align-self-start">
                                        <FontAwesomeIcon icon={faPen} className={styles.editIconStyle} onClick={() => { setClickedField('lname'); }} title="Edit last name" />
                                    </div>
                                }
                            </div>
                        )}
                        <hr className="m-0" />
                        {clickedField == 'email' ? (
                            <div>
                                <label className="mt-2">Email</label>
                                <input type='email' className="form-control mt-1" placeholder="Enter Email" value={email} onChange={(e) => { setEmail(e.target.value) }} onBlur={()=>{CommonServices.isValidEmail(email)?UpdateFormField():void(0)}} />
                                <small className=" text-danger">{( !email?'':( CommonServices.isValidEmail(email))?'':'Invalid email format')}</small>
                            </div>
                        ) : (
                            <div className="w-100 d-flex justify-content-between mt-3">
                                <p className={styles.profileText}><strong className={styles.innerAddressHeading}> Email :</strong>{email ? email : 'Not Available'}</p>

                                {isEditProfile &&
                                    <div className=" align-self-start">
                                        <FontAwesomeIcon icon={faPen} className={styles.editIconStyle} onClick={() => { setClickedField('email'); }} title="Edit email" />
                                    </div>
                                }
                            </div>
                        )}
                        <hr className="m-0" />
                        {clickedField == 'phone' ? (
                            <div>
                                <label className="mt-2">Phone Number</label>
                                <PhoneInput
                                    country={Default_Country}
                                    preferredCountries={['in', 'ca', 'us']}
                                    autoFormat
                                    containerClass={`${styles.editProfilePhoneContainer} `}
                                    inputClass={`${styles.editProfilePhoneInput} `}
                                    inputStyle={{}}
                                    buttonClass={styles.editProfileCountryDropDown}
                                    dropdownClass={'country-dropdown-container'}
                                    enableSearch
                                    countryCodeEditable={false}
                                    style={{ paddingRight: '0px' }}
                                    value={"+" + sCode + sPhone}
                                    onBlur={() => { UpdateFormField() }}
                                    onChange={(value, country) => {
                                        HandleInputChange(value, country)
                                    }}
                                    placeholder="Phone Number"
                                    autocompleteSearch={true}
                                    inputProps={{
                                        name: 'primary_phone_temp',
                                    }}
                                />
                            </div>
                        ) : (
                            <div className="w-100 d-flex justify-content-between mt-3">
                                <p className={styles.profileText}><strong className={styles.innerAddressHeading}>Phone :</strong> {sCode ? "+" + sCode + sPhone : "Not Available"}</p>

                                {isEditProfile &&
                                    <div className=" align-self-start">
                                        <FontAwesomeIcon icon={faPen} className={styles.editIconStyle} onClick={() => { setClickedField('phone'); }} title="Edit phone" />
                                    </div>
                                }
                            </div>
                        )}
                        <hr className="m-0" style={{ height: '0.062rem' }} />
                        <div className={styles.oherAddressInputs}>
                            <div className="d-flex justify-content-center my-3">
                                <div className={styles.addressHeading}>
                                <label>Address</label>

                                </div>
                            </div>
                            <div className="ms-2">
                                {clickedField == 'street' ? (
                                    <div>
                                        <label className="mt-2">Street/Building/Area</label>
                                        <input type="text" className="form-control mt-1" placeholder="Enter Street/Building/Area" value={street} onChange={(e) => { setStreet(e.target.value) }} onBlur={UpdateFormField} />
                                    </div>
                                ) : (
                                    <div className="w-100 d-flex justify-content-between mt-1">
                                        <p className={styles.profileText}><strong className={styles.innerAddressHeading}>Street/Building/Area :</strong> {street ? street : 'Not Available'} </p>
                                        {isEditProfile &&
                                            <div className=" align-self-start">
                                                <FontAwesomeIcon icon={faPen} className={styles.editIconStyle} onClick={() => { setClickedField('street'); }} title="Edit last name" />
                                            </div>
                                        }
                                    </div>
                                )}
                                {clickedField == 'city' ? (
                                    <div>
                                        <label className="mt-2">City</label>
                                        <input type="text" className="form-control mt-1" placeholder="Enter City" value={city} onChange={(e) => { setCity(e.target.value) }} onBlur={UpdateFormField} />
                                    </div>
                                ) : (
                                    <div className="w-100 d-flex justify-content-between mt-1">
                                        <p className={styles.profileText}><strong className={styles.innerAddressHeading}>City :</strong> {city ? city : 'Not Available'}</p>
                                        {isEditProfile &&
                                            <div className=" align-self-start">
                                                <FontAwesomeIcon icon={faPen} className={styles.editIconStyle} onClick={() => { setClickedField('city'); }} title="Edit city" />
                                            </div>
                                        }
                                    </div>
                                )}
                                {clickedField == 'province' ? (
                                    <div>
                                        <label className="mt-2">Province</label>
                                        <input type="text" className="form-control mt-1" placeholder="Enter Last Name" value={province} onChange={(e) => { setProvince(e.target.value) }} onBlur={UpdateFormField} />
                                    </div>
                                ) : (
                                    <div className="w-100 d-flex justify-content-between mt-1">
                                        <p className={styles.profileText}><strong className={styles.innerAddressHeading}>Province :</strong> {province ? province : 'Not Available'} </p>
                                        {isEditProfile &&
                                            <div className=" align-self-start">
                                                <FontAwesomeIcon icon={faPen} className={styles.editIconStyle} onClick={() => { setClickedField('province'); }} title="Edit Province" />
                                            </div>
                                        }
                                    </div>
                                )}
                                {clickedField == 'postal' ? (
                                    <div>
                                        <label className="mt-2">Postal/Zip Code</label>
                                        <input type="text" className="form-control mt-1" placeholder="Enter Last Name" value={postal} onChange={(e) => { setPostal(e.target.value) }} onBlur={UpdateFormField} />
                                    </div>
                                ) : (
                                    <div className="w-100 d-flex justify-content-between mt-1">
                                        <p className={styles.profileText}><strong className={styles.innerAddressHeading}>Postal/Zip Code :</strong> {postal ? postal : 'Not Available'} </p>
                                        {isEditProfile &&
                                            <div className=" align-self-start">
                                                <FontAwesomeIcon icon={faPen} className={styles.editIconStyle} onClick={() => { setClickedField('postal'); }} title="Edit postal code" />
                                            </div>
                                        }
                                    </div>
                                )}
                                {clickedField == 'country' ? (
                                    <div>
                                        <label className="mt-2">Country</label>
                                        <input type="text" className="form-control mt-1" placeholder="Enter Last Name" value={country} onChange={(e) => { setCountry(e.target.value) }} onBlur={UpdateFormField} />
                                    </div>
                                ) : (
                                    <div className="w-100 d-flex justify-content-between mt-1">
                                        <p className={styles.profileText}><strong className={styles.innerAddressHeading}>Country :</strong> {country ? country : 'Not Available'} </p>

                                        {isEditProfile &&
                                            <div className=" align-self-start">
                                                <FontAwesomeIcon icon={faPen} className={styles.editIconStyle} onClick={() => { setClickedField('country'); }} title="Edit country" />
                                            </div>
                                        }
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </>
    );
};
//#endregion render end
export default OtherContactDetails;