import React, { useEffect, useState } from 'react';
import { Modal } from '@themesberg/react-bootstrap';
import styles from './Index.module.css';
import { DataLoading } from '../../../components/air-migrate/Loader';
import { useHistory } from 'react-router-dom';
import { useFormik } from "formik";
import { toast, Toaster } from "react-hot-toast";
import PhoneInput from 'react-phone-input-2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faHome } from '@fortawesome/free-solid-svg-icons';
import * as Yup from "yup";
import Select from 'react-select';
import { Col, Row, Card, Form, Button, InputGroup, Breadcrumb, Alert } from "@themesberg/react-bootstrap";
import { GetCountriesApi } from '../../../actions/LoginAction';
import { CreateClientForCaseApi } from '../../../actions/CaseActions';
import '../../clients/add-client/Index.css';
import '../../../assets/global-css/Index.css';
import ORImage from '../../../assets/img/templates/ORImage.png';
import { Default_Country, Default_Country_Code } from '../../../config.tsx';
import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import CommonAlert from '../../../common-components/CommonAlert.jsx';
import { ALERT_SHOW_TIME } from '../../../config.tsx';
import { InviteClientFormAutoCompleteApi } from '../../../actions/CommonActions.jsx';
const AddClientModal = ({ toggle, isModalOpen, setIsModalOpen, caseId, setCount }) => {

    //#region State and Variable

    const [isLoading, setIsLoading] = useState(false);
    const [isEmailEntered, setIsEmailEntered] = useState(false);
    const [isPhoneEntered, setIsPhoneEntered] = useState(false);
    const [isCountryCodeEntered, setIsCountryCodeEntered] = useState(false);
    const [isInviteNewUserClicked, setIsInviteNewUserClicked] = useState(false);
    const history = useHistory();

    //Alert states
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    const [alertsArray, setAlertsArray] = useState([]);
    const [modalCount, setModalCount] = useState(1);
    const [contact, setContact] = useState('email');
    const [searchContact, setSearchContact] = useState('searchemail');
    const [phone, setPhone] = useState();
    const [countryCode, setcountryCode] = useState(Default_Country_Code);
    const [searchValue, setSearchValue] = useState();
    //#endregion

    //#region useEffect
    useEffect(() => {

    }, [modalCount])
    //#endregion

    //#region Functions

    //#region Alerts
    const handleAlert = (id) => {
        let array = [];
        for (let i = 0; i < alertsArray.length; i++) {
            if ((alertsArray[i].id) == id) {
                array = alertsArray.pop();
            }
        }
        setModalCount(Math.floor(Math.random() * 1000))
    }

    const [hiddenAlerts, setHiddenAlerts] = React.useState([]);

    const onClose = (alertId) => {
        const hiddenAlertsUpdated = [...hiddenAlerts, alertId];
        setHiddenAlerts(hiddenAlertsUpdated);
    };

    const shouldShowAlert = (alertId) => (
        hiddenAlerts.indexOf(alertId) === -1
    );
    //#endregion
    const AddClientValidationSchema = Yup.object().shape({
        first_name: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('First name is required'),
        // last_name: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Last name is required'),
        phone: Yup.string().required("Please enter phone number")
            .matches(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/, "Please enter valid phone number")
        ,
        email: Yup.string().email('Invalid email').required('Email is required'),
        primary_country_code: Yup.string().required("Please select country code "),
        invite_person: Yup.string().required('Please select invite person')
    });

    const AddClientValidationSchemaForEmail = Yup.object().shape({
        first_name: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('First name is required'),
        // last_name: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Last name is required'),
        phone: Yup.string()
            .matches(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/, "Please enter valid phone number")
        ,
        email: Yup.string().email('Invalid email'),
        // primary_country_code: Yup.string().required("Please select country code "),
        invite_person: Yup.string().required('Please select invite person')
    });

    const AddClientValidationSchemaForPhone = Yup.object().shape({
        first_name: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('First name is required'),
        // last_name: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Last name is required'),
        phone: Yup.string()
            .matches(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/, "Please enter valid phone number")
        ,
        email: Yup.string().matches(/^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/, 'Invalid email'),
        primary_country_code: Yup.string().required("Please select country code "),
        invite_person: Yup.string().required('Please select invite person')
    });

    const AddClientValidationSchemaForSearch = Yup.object().shape({
        search_value: searchContact === "searchphone" ? Yup.string().required('Mobile number is required'): Yup.string().required('Email is required').matches(/^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/, 'Invalid email'),
    });

    
    const SelectValidationSchema = () => {
        if (isInviteNewUserClicked == false) {
            return AddClientValidationSchemaForSearch

        }
        else if (isInviteNewUserClicked == true) {
            if (isPhoneEntered == false && isEmailEntered == false) {
                return AddClientValidationSchema;
            }
            else if (isPhoneEntered == true && isEmailEntered == false) {
                return AddClientValidationSchemaForPhone;
            }
            else if (isPhoneEntered == false && isEmailEntered == true) {
                return AddClientValidationSchemaForEmail;
            }
            else {
                return AddClientValidationSchema;
            }
        }


    }

    //define formik flow
    const formik = useFormik({
        initialValues: {
            first_name: '',
            last_name: '',
            primary_country_code: Default_Country_Code,
            phone: '',
            email: '',
            invite_person: '',
            search_value: ''
        },
        enableReinitialize: true,
        validationSchema: SelectValidationSchema()

        ,
        onSubmit: (values) => {
            onSubmitCall(values);
        }
    })

    //on submit function
    const onSubmitCall = async (data) => {
        if (searchContact == "searchphone") {
            var FinalSearchValue = Number(phone)
        }
        if (searchContact == "searchemail") {
            var FinalSearchValue = data.search_value
        }
        var addClientRequestModel =
        {
            first_name: data.first_name,
            last_name: data.last_name,
            primary_country_code: Number(data.primary_country_code ? data.primary_country_code : Default_Country_Code),

            phone: Number(data.phone),
            email: data.email,
            invite_person: data.invite_person,
            search_case: !isInviteNewUserClicked,
            search_value: FinalSearchValue
        }

        // console.log("data", data, " addClientdata", addClientRequestModel);
        setIsLoading(true);
        const response = await CreateClientForCaseApi(caseId, addClientRequestModel);
        setIsLoading(false);
        if (response.status_code === 1) {
            // console.log("success", response);
            setAlertsArray([...alertsArray, { id: `invite-user${alertsArray.length}`, type: 'success', message: response.message }]);
            setTimeout(() => {
                toggle();
                setIsInviteNewUserClicked(false);
                setIsPhoneEntered(false);
                setIsEmailEntered(false);
                setCount(Math.floor(Math.random() * 100));
            }, ALERT_SHOW_TIME);

            formik.handleReset();
        }
        else {
            setAlertsArray([...alertsArray, { id: `invite-user${alertsArray.length}`, type: 'danger', message: response.message }]);
           
        }

    }
    const HandlePhoneInputChange = (value, country) => {
        if (country.dialCode == '') {
            setcountryCode(country.dialCode)
            setIsCountryCodeEntered(false);
        }
        else {
            setcountryCode(country.dialCode)
            formik.setFieldValue("primary_country_code", country.dialCode);
            setIsCountryCodeEntered(true);
        }
        var phone = value.slice(country.dialCode.length);
        if (phone == '') {
            setPhone(phone);
            formik.setFieldValue("phone", phone);
            setIsPhoneEntered(false);
        }
        else {
            setPhone(phone);
            formik.setFieldValue("phone", phone);
            setIsPhoneEntered(true);
            setIsEmailEntered(false)
        }
    }
    const HandlePhoneInputChangeforSearch = (value, country) => {
        if (country.dialCode == '') {
            setcountryCode(country.dialCode)
            setIsCountryCodeEntered(false);
        }
        else {
            setcountryCode(country.dialCode)
            setIsCountryCodeEntered(true);
        }
        var phone = value.slice(country.dialCode.length);
        if (phone == '') {
            setPhone(phone);
            setIsPhoneEntered(false);
        }
        else {
            setPhone(phone);
            getAutoCompleteresult(phone);
            setSearchValue(phone);
            formik.setFieldValue("search_value", String(countryCode).concat(phone));
            setIsPhoneEntered(true);
            setIsEmailEntered(false)
        }
    }



    /* function for radio button of email and phone */
    const onContactChange = (value, field) => {
        
        if (field === 1) {
            setContact(value);
            if (value == "phone") {
                formik.setFieldValue("email", '');
                formik.setFieldTouched("email", false, false)

            } else if (value == "email") {
                formik.setFieldValue("primary_country_code", '');
                formik.setFieldValue("phone", '');
                setPhone('');
                setcountryCode('');
                formik.setFieldTouched("phone", false, false)
                formik.setFieldTouched("primary_country_code", false, false);
            }
        }
        if (field === 2) {
            formik.setFieldValue("search_value", '');
            formik.setErrors({});
            formik.setTouched({});
            setSearchContact(value);
        }

    };
    /* setting autocomplete select value in form field */
    const [searchBoxShow, setSearchBoxShow] = useState(false);
    const setSuggestions = (value) => {
        if (searchContact == 'searchemail') {
            formik.setFieldValue("search_value", value)
            // setIsPhoneEntered(false);
            // setIsCountryCodeEntered(false);
            // setIsEmailEntered(true);
        } else if (searchContact == 'searchphone') {
            setPhone(value)
            let searchValue = countryCode.concat(value);
            formik.setFieldValue("search_value", searchValue)
            formik.setFieldTouched("phone", false, false)
            formik.setFieldTouched("primary_country_code", false, false)
            // setIsPhoneEntered(true);
            // setIsCountryCodeEntered(true);
            // setIsEmailEntered(false)
        }
        setSearchBoxShow(false)
    }

    /* get autocomplete suggetions api call here*/
    const [tempArray, setTempArray] = useState([]);
    const [autoSearchArray, setAutoSearchArray] = useState([]);
    const getAutoCompleteresult = async (searchValue = '') => {
        var searchFor = '';
        var sendData = {}
        if (searchContact == 'searchemail') {
            searchFor = 1;
            sendData = {
                search: searchValue
            }
        } else if (searchContact == 'searchphone') {
            searchFor = 2;
            sendData = {
                search: searchValue,
                primary_code: countryCode
            }
        }
        const response = await InviteClientFormAutoCompleteApi(sendData, searchFor);
        if (response.status_code == 1) {
            setTempArray(response.data);
            if (searchValue = '' || null) {
                setSearchBoxShow(false)
            } else {
                setSearchBoxShow(true)
            }
            // if(response.data.length>0){
            //     // setSearchBoxShow(true)
            // }else{
            //     setSearchBoxShow(false)
            // }
        } else {
            setSearchBoxShow(false)
            console.error(response.message)
        }
    }
    useEffect(() => {
        setAutoSearchArray(tempArray);
    }, [tempArray])

    //#endregion
    //#region JSX
    return (
        <Row lg={12} md={12} sm={12}>
            <Modal as={Modal.Dialog} centered show={isModalOpen} onHide={toggle}>
                <Modal.Body>

                    <Form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                        <Row>

                            <Col sm={12}>
                                <div className={styles.createUserBtnDiv}>
                                <Button
                                    className={`mb-2 ${styles.createUserBtn}`}
                                    onClick={() => {
                                        if (isInviteNewUserClicked == false) {
                                            formik.setFieldValue("search_value", '');
                                            formik.setErrors({})
                                            formik.setTouched({})
                                        }
                                        setIsInviteNewUserClicked(isInviteNewUserClicked => !isInviteNewUserClicked);
                                    }}>{(isInviteNewUserClicked == true) ? 'Invite Existing User' : 'Create New User'}
                                </Button>
                                </div>
                            </Col>
                        </Row>
                        <Row className='mt-2 mb-3'>
                            {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <Modal.Title className="h6">Add a new participant</Modal.Title>
                                <div className="d-flex document-card-inner active-card justify-content-center" >
                                    <h5 className="mb-0 mt-0 text-center c-lg f-18 fw-bolder">Add a new participant</h5>

                                </div>
                                <Button variant="close" aria-label="Close" onClick={() => {
                                    toggle(); formik.handleReset(); setIsInviteNewUserClicked(false); setIsPhoneEntered(false);
                                    setIsEmailEntered(false);
                                }} type="reset" />
                            </div> */}
                            <Col><div className="d-flex document-card-inner active-card justify-content-center" >
                                <h5 className="mb-0 mt-0 text-center c-lg f-18 fw-bolder">{(isInviteNewUserClicked == true) ? 'Create New User' : 'Invite Existing User'}</h5>
                            </div></Col>
                        </Row>
                        <Row>

                            <Col lg={12} md={12} sm={12}>
                                {(alertsArray.length > 0) &&
                                    alertsArray.reverse().map((item, index) => {
                                        return (
                                            <CommonAlert key={index} id={item.id} type={item.type} message={item.message} handleAlert={handleAlert} isInInviteForm={true}/>
                                        )
                                    })
                                }
                                {(isInviteNewUserClicked == false) &&
                                    <Row className='d-flex'>
                                        <Col lg={12} sm={6}>
                                            <Form.Group >
                                                <Form.Label>Invite By Phone Or Email <span className="c-r">*</span></Form.Label>
                                                <Row className='mb-2'>
                                                    <div className="d-flex">
                                                        <Form.Check
                                                            inline
                                                            label="Email"
                                                            name="search"
                                                            type="radio"
                                                            id={`inline-radio-11`}
                                                            value={toString("email")}
                                                            checked={searchContact === "searchemail"}
                                                            onChange={() => { onContactChange("searchemail", 2) }}

                                                        />
                                                        <Form.Check
                                                            inline
                                                            label="Mobile Number"
                                                            name="search"
                                                            type="radio"
                                                            id={`inline-radio-21`}
                                                            value={toString("phone")}
                                                            checked={searchContact === "searchphone"}
                                                            onChange={() => { onContactChange("searchphone", 2) }}

                                                        />
                                                    </div>
                                                </Row>
                                                {searchContact == "searchemail" &&
                                                    <Row>
                                                        <Col sm={12} md={12} lg={12} className="mb-3">
                                                            <Form.Group >
                                                                <Form.Label>Search By Email<span className="c-r mt-3">*</span></Form.Label>
                                                                <Form.Control
                                                                    id="search_value"
                                                                    name="search_value"
                                                                    value={formik.values.search_value}
                                                                    type="text"
                                                                    placeholder="Search email"
                                                                    onChange={(e) => {
                                                                        // console.log('search_value', e.target.value);
                                                                        getAutoCompleteresult(e.target.value);
                                                                        setSearchValue(e.target.value)
                                                                        formik.setFieldValue("search_value", e.target.value);
                                                                        if (e.target.value == '') {
                                                                            setIsEmailEntered(false);
                                                                        }
                                                                        else setIsEmailEntered(true);
                                                                    }}
                                                                    onBlur={(e) => {
                                                                        formik.handleBlur(e);
                                                                        setTimeout(() => {
                                                                            setSearchBoxShow(false)
                                                                        }, 3000);
                                                                    }}
                                                                />
                                                                {searchBoxShow &&
                                                                    <div className={styles.autoCompleteBox}>
                                                                        {autoSearchArray.length && searchValue && (
                                                                            autoSearchArray.map((result, index) => {
                                                                                return (
                                                                                    <p key={index} className={styles.suggestions} onClick={() => { setSuggestions(result.email) }}>{result.email}</p>
                                                                                )
                                                                            })
                                                                        ) /* : (<p className={styles.suggestions}>No Data Found</p>) */}
                                                                    </div>
                                                                }
                                                                {formik.touched.search_value && formik.errors.search_value ? (
                                                                    <small className="text-danger">{formik.errors.search_value}</small>
                                                                ) : null}
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                }
                                                {/* <Row >
                                                <Col sm={12} xl={12} lg={12} xs={12} md={12} >
                                                    <div className="or-image-block">
                                                        <img src={ORImage} alt="or-image" className=' mt-0 mb-0' />
                                                    </div>
                                                </Col>
                                            </Row> */}
                                                {searchContact == "searchphone" &&
                                                    <Row>
                                                        <Col sm={12} md={12} lg={12} className="mb-3">
                                                            <div className="inputRow">
                                                                <Form.Label className="mobile-number-text">Search By Mobile Number <span className="c-r">*</span></Form.Label>
                                                                <PhoneInput
                                                                    country={Default_Country}
                                                                    preferredCountries={['in', 'ca', 'us']}
                                                                    autoFormat
                                                                    // containerClass={` ${formValue?.phone?.error ? "is-invalid" : ""}`}
                                                                    inputClass={`PhoneInputInput`}
                                                                    buttonClass={'country-dropdown'}
                                                                    dropdownClass={'country-dropdown-container'}
                                                                    enableSearch
                                                                    countryCodeEditable={false}
                                                                    style={{}}
                                                                    onChange={(value, country) => {
                                                                        HandlePhoneInputChangeforSearch(value, country)
                                                                    }}
                                                                    placeholder="Search Number"
                                                                    autocompleteSearch={true}
                                                                    inputProps={{
                                                                        name: 'search_value',
                                                                        required: false,

                                                                    }}
                                                                    value={formik.values.search_value}
                                                                    onBlur={(e) => {
                                                                        formik.handleBlur(e);
                                                                        setTimeout(() => {
                                                                            setSearchBoxShow(false)
                                                                        }, 3000);
                                                                    }}
                                                                />
                                                                {searchBoxShow &&
                                                                    <div className={styles.autoCompleteBox}>
                                                                        {autoSearchArray.length && searchValue && (
                                                                            autoSearchArray.map((result, index) => {
                                                                                return (
                                                                                    <p key={index} className={styles.suggestions} onClick={() => { setSuggestions(result.primary_phone) }}>{result.primary_phone}</p>
                                                                                )
                                                                            })
                                                                        ) /* : (<p className={styles.suggestions}>No Data Found</p>) */}
                                                                    </div>
                                                                }
                                                                {formik.touched.search_value && formik.errors.search_value ? (
                                                                    <small className="text-danger">{formik.errors.search_value}</small>
                                                                ) : null}
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                }

                                                {/* <Form.Control
                                                id="search_value"
                                                name="search_value"
                                                value={formik.values.search_value}
                                                type="text"
                                                placeholder="Name"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.search_value && formik.errors.search_value ? (
                                                <small className="text-danger">{formik.errors.search_value}</small>
                                            ) : null} */}



                                            </Form.Group>
                                        </Col>
                                    </Row>
                                }
                                {/* <div className="or-image-block">
                                    <img src={ORImage} alt="or-image" className='mt-3 mb-3' />
                                </div> */}
                                {/* <Row>
                                    <Col lg={12}>
                                        <Button
                                            className='mb-2 '
                                            style={{ backgroundColor: '#0184fe', border: 'none' }}
                                            onClick={() => {
                                                if (isInviteNewUserClicked == false) {
                                                    formik.setFieldValue("search_value", '');
                                                }
                                                setIsInviteNewUserClicked(isInviteNewUserClicked => !isInviteNewUserClicked);
                                            }}>Create New User</Button>
                                    </Col>
                                </Row> */}
                            </Col>
                        </Row>

                        {/* Conditionaly render invite new user form */}
                        {(isInviteNewUserClicked == true) &&
                            <Row>
                                <Col xs={12} lg={12} md={12} sm={12}>
                                    <Card className="add-client-popup bg-white shadow-sm ">
                                        <Card.Body className='border-none p-0'>
                                            <Row>
                                                <Col sm={12} md={12} lg={12} className="mb-3">
                                                    <Form.Group >
                                                        <Form.Label>First Name <span className="c-r">*</span></Form.Label>
                                                        <Form.Control
                                                            id="first_name"
                                                            name="first_name"
                                                            value={formik.values.first_name}
                                                            type="text"
                                                            placeholder="Enter first name"
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                        {formik.touched.first_name && formik.errors.first_name ? (
                                                            <small className="text-danger">{formik.errors.first_name}</small>
                                                        ) : null}
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={12} md={12} lg={12} className="mb-3">
                                                    <Form.Group >
                                                        <Form.Label>Last Name</Form.Label>
                                                        <Form.Control
                                                            id="last_name"
                                                            name="last_name"
                                                            value={formik.values.last_name}
                                                            type="text"
                                                            placeholder="Enter last name"
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                        {formik.touched.last_name && formik.errors.last_name ? (
                                                            <small className="text-danger">{formik.errors.last_name}</small>
                                                        ) : null}
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={12} md={12} lg={12} className="mb-3">
                                                    <Form.Group >
                                                        <Form.Label>Select Invite Person <span className="c-r">*</span></Form.Label>
                                                        <Select
                                                            classNamePrefix="select"
                                                            isDisabled={false}
                                                            isLoading={false}
                                                            isClearable={false}
                                                            isRtl={false}
                                                            isMulti={false}
                                                            isSearchable={true}
                                                            name="invite_person"
                                                            id={`invite_person`}
                                                            options={[{ label: 'Applicant', id: 1, value: "Applicant" },
                                                            { label: 'Sponsor', id: 2, value: "Sponsor" }
                                                            ]}
                                                            onChange={(e) => { formik.setFieldValue("invite_person", e.id) }}
                                                        />
                                                        {formik.touched.invite_person && formik.errors.invite_person ? (
                                                            <small className="text-danger">{formik.errors.invite_person}</small>
                                                        ) : null}
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={12} sm={7} className="mb-2 d-flex justify-content-between">
                                                    <Form.Group>
                                                        <div className="d-flex justify-content-between">
                                                            <Form.Check
                                                                inline
                                                                label="Email"
                                                                name="contact"
                                                                type="radio"
                                                                id={`inline-radio-1`}
                                                                value={toString("email")}
                                                                checked={contact === "email"}
                                                                onChange={() => { onContactChange("email", 1) }}

                                                            />
                                                            <Form.Check
                                                                inline
                                                                label="Mobile Number"
                                                                name="contact"
                                                                type="radio"
                                                                id={`inline-radio-2`}
                                                                value={toString("phone")}
                                                                checked={contact === "phone"}
                                                                onChange={() => { onContactChange("phone", 1) }}

                                                            />
                                                        </div>

                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            {contact == "email" &&
                                                <Row>
                                                    <Col sm={12} md={12} lg={12} className="mb-3">
                                                        <Form.Group >
                                                            <Form.Label>Email<span className="c-r">*</span></Form.Label>
                                                            <Form.Control
                                                                id="email"
                                                                name="email"
                                                                value={formik.values.email}
                                                                type="text"
                                                                placeholder="Enter email"
                                                                onChange={(e) => {
                                                                    // console.log('email value', e.target.value);
                                                                    formik.setFieldValue("email", e.target.value);
                                                                    if (e.target.value == '') {
                                                                        setIsEmailEntered(false);
                                                                    }
                                                                    else setIsEmailEntered(true);
                                                                }}
                                                                onBlur={formik.handleBlur}
                                                            />

                                                            {formik.touched.email && formik.errors.email ? (
                                                                <small className="text-danger">{formik.errors.email}</small>
                                                            ) : null}
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            }
                                            {/* <Row >
                                                <Col sm={12} xl={12} lg={12} xs={12} md={12} >
                                                    <div className="or-image-block">
                                                        <img src={ORImage} alt="or-image" className=' mt-0 mb-0' />
                                                    </div>
                                                </Col>
                                            </Row> */}
                                            {contact == "phone" &&
                                                <Row>
                                                    <Col sm={12} md={12} lg={12} className="mb-3">
                                                        <div className="inputRow">
                                                            <Form.Label className="mobile-number-text">Mobile Number <span className="c-r">*</span></Form.Label>
                                                            <PhoneInput
                                                                country={Default_Country}
                                                                preferredCountries={['in', 'ca', 'us']}
                                                                autoFormat
                                                                // containerClass={` ${formValue?.phone?.error ? "is-invalid" : ""}`}
                                                                inputClass={`PhoneInputInput`}
                                                                buttonClass={'country-dropdown'}
                                                                dropdownClass={'country-dropdown-container'}
                                                                enableSearch
                                                                countryCodeEditable={false}
                                                                style={{}}
                                                                onChange={(value, country) => {
                                                                    HandlePhoneInputChange(value, country)
                                                                }}
                                                                placeholder="Phone Number"
                                                                autocompleteSearch={true}
                                                                inputProps={{
                                                                    name: 'phone',
                                                                    required: false,

                                                                }}
                                                                onBlur={formik.handleBlur}

                                                            />
                                                            {formik.touched.phone && formik.errors.phone ? (
                                                                <small className="text-danger">{formik.errors.phone}</small>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            }
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        }
                        <Row className="mt-3">
                            <Col className='invite-btn-block' sm={12} md={12} lg={12}>
                                <div className='d-flex justify-content-between'>
                                    <Button type="reset" onClick={() => setIsModalOpen(false)} className="text-white invite-btn" variant="danger"> Cancel </Button>
                                    <Button type="submit" className="text-white invite-btn me-2" variant="success"> Invite </Button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                {isLoading === true && <DataLoading />}
            </Modal>
        </Row>
    )
    //#endregion
}
export default AddClientModal;
