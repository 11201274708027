import React, { Fragment, useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
//Local components
import '../../assets/global-css/Index.css';
import Header from "../../layout/Header";
import Footer from "../../layout/Footer";
import RightIcon from '../../assets/img/landing-page/right-icon.png';
import LifeInsurance from '../../assets/img/landing-page/Life.jpg';
import HomeInsurance from '../../assets/img/landing-page/Home.jpg';
import HealthInsurance from '../../assets/img/landing-page/Health.jpg';
import MotorInsurance from '../../assets/img/landing-page/Motor.jpg';
import BankingImage from '../../assets/img/landing-page/banking.png';
import RealEstateVideoReplacement from '../../assets/img/landing-page/real-estate.png';
import ImmigConsultantVideoReplacement from '../../assets/img/landing-page/real-estate-section-3.png';
import TelecomVideoReplacement from '../../assets/img/landing-page/telecom-section.png';

const LandingPage = () => {
    //#region states
    const history = useHistory();
    const [isUserLogin, setIsUserLogin] = useState(false);
    const landingPageMarginTop = isUserLogin ? 'landing-page-margin-top-logged-in' : 'landing-page-margin-top-logout';
    //#endregion states

    //#region useEffect
    useEffect(() => {
        setIsUserLogin(false)
        var userDetails = localStorage.getItem("accessToken");
        if (userDetails) {
            setIsUserLogin(true)
        }
    }, [])
    //#endregion useEffect

    //#region render
    return (
        <Fragment>
            <Header isUserLogin={isUserLogin} setIsUserLogin={setIsUserLogin} />
            {/*Main Block*/}
            <div className={`main-image-landing-page ${landingPageMarginTop}`}>
                <div className="landing-page">
                    <div className="overlay">
                        <h3 className="landing-heading-text2">Exclusive Offers and Trusted partners for</h3>
                        <h4 className="landing-heading-text3" > a Seamless Immigration Journey</h4>
                    </div>
                </div>
            </div>
            <div className="main-page-content-block">
                <section className="consultants-block-area all-section-top-margin">
                    <div className="consultants-section-block all-section-padding">
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-6 video-mainblock">
                                    <div className="container-video-block">
                                        <div className="video-inner-block">
                                            <img src={ImmigConsultantVideoReplacement} alt='hello' className="video-file"></img>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-6">
                                    <div className="video-content-text">
                                        <div className="section-text-block-heading">
                                            <h4><span className="heading-blue-text">AirMigrate Immigration Consultants</span> Certified, Expert, and Trustworthy</h4>
                                        </div>
                                        <p>Avoid scams with our certified consultants, simplify complex immigration steps with our experts, and save time as we handle all the vetting and details for you. Trust AirMigrate to secure your new future efficiently.</p>
                                        <div className="learn-more-btn">
                                            <a onClick={() => { history.push('/quick-chat') }}>Learn More <span className="right-icon-block"><img src={RightIcon}
                                                alt="right-icon" /></span></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*Real Estate Section*/}
                <section className="real-estate-block-area all-section-top-margin">
                    <div className="real-estate-section-block all-section-padding">
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-6">
                                    <div className="video-content-text no-padding">
                                        <div className="section-text-block-heading">
                                            <h4><span className="heading-blue-text">AirMigrate Real Estate</span> Find Your Home Abroad</h4>
                                        </div>
                                        <div>
                                            <p>New country, new home. Secure your place to stay swiftly with AirMigrate.</p>
                                            <p> Our real estate experts specialize in helping immigrants like you navigate complex markets, making your move as smooth as possible. Trust us to understand your needs and deliver results fast.</p>
                                            <p>Welcome home.</p>
                                        </div>
                                        <div className="learn-more-btn">
                                            <a onClick={() => history.push('/real-estate')}>Learn More <span className="right-icon-block"><img src={RightIcon}
                                                alt="right-icon" /></span></a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-6 video-mainblock">
                                    <div className="container-video-block">
                                        <div className="video-inner-block">
                                            <img src={RealEstateVideoReplacement} alt='hello' className="video-file"></img>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Services Section */}
                <section className="telecom-services-block-area all-section-top-margin">
                    <div className="telecom-services-section-block all-section-padding">
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-6 video-mainblock">
                                    <div className="container-video-block ">
                                        <div className="video-inner-block telecom-services-video">
                                            <img src={TelecomVideoReplacement} alt='telecom' className="video-file"></img>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-6">
                                    <div className="telecom-services-content-block">
                                        <div className="section-text-block-heading">
                                            <h4><span className="heading-blue-text">AirMigrate Telecom Services</span> Connect Easily, Wherever You Go</h4>
                                        </div>
                                        <div>
                                            <p>New to a country? Set up your internet, mobile, and landline services effortlessly with AirMigrate.</p>

                                            <p>Our platform simplifies finding the best telecom deals, tailored specifically for newcomers—ensuring you stay connected with top, flexible plans from trusted providers.</p>

                                            <p>Start your new life connected to your old roots.</p>
                                        </div>
                                        <div className="learn-more-btn">
                                            <a onClick={() => history.push('/telecom')}>Learn More <span className="right-icon-block">
                                                <img src={RightIcon} alt="right-icon" /></span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*Banking Section*/}
                <section className="banking-services-block-area all-section-top-margin">
                    <div className="banking-services-section-block all-section-padding">
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-6">
                                    <div className="banking-services-content-block">
                                        <div>
                                            <div className="section-text-block-heading">
                                                <h4><span className="heading-blue-text">AirMigrate Banking Services</span> Optimize Your Financial Start</h4>
                                            </div>
                                            <div>

                                                <p>Finding the best banking deals is an uphill task? You're not alone—95% of immigrants feel the same.</p>

                                                <p>AirMigrate simplifies this by offering a user-friendly platform to compare and select banking products tailored for newcomers, with exclusive deals from leading financial institutions.</p>
                                            </div>
                                            <div className="learn-more-btn">
                                                <a onClick={() => history.push('/banking')}>Learn More <span className="right-icon-block"><img src={RightIcon}
                                                    alt="right-icon" /></span></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-6">
                                    <div className="banking-services-image-block">
                                        <img src={BankingImage} alt="banking image" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Insurance Section */}
                <section className="insurance-agents-block-area all-section-top-margin">
                    <div className="insurance-agents-section-block">
                        <div className="container">
                            <div className="text-center">
                                <div className="section-text-block-heading text-start  ">
                                    <h4><span className="heading-blue-text">AirMigrate Insurance Solutions &nbsp;</span>Comprehensive Coverage for Every Newcomer</h4>
                                </div>

                                <div className="insurance-text-info">
                                    <div className="">
                                        <p>Secure your peace of mind with AirMigrate. We understand the insurance needs of those on visitor visas, including medical protection and travel insurance, as well as comprehensive plans for parents and grandparents.</p>
                                        <p>Settle in with confidence knowing you're in good company - our insurance agents bring the best deals to you.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 col-md-6 col-lg-3">
                                    <div className="insurance-type-detail-block">
                                        <div className="insurance-type-block-inner">
                                            <div className="insurance-type-img-block">
                                                <img src={HealthInsurance} alt="health-insurance" />
                                            </div>
                                            <div className="insurance-type-content-block">
                                                <h5>Health Insurance</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-3">
                                    <div className="insurance-type-detail-block">
                                        <div className="insurance-type-block-inner">
                                            <div className="insurance-type-img-block">
                                                <img src={LifeInsurance} alt="life-insurance" />
                                            </div>
                                            <div className="insurance-type-content-block">
                                                <h5>Life Insurance</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-3">
                                    <div className="insurance-type-detail-block">
                                        <div className="insurance-type-block-inner">
                                            <div className="insurance-type-img-block">
                                                <img src={HomeInsurance} alt="life-insurance" />
                                            </div>
                                            <div className="insurance-type-content-block">
                                                <h5>Home Insurance</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-3">
                                    <div className="insurance-type-detail-block">
                                        <div className="insurance-type-block-inner">
                                            <div className="insurance-type-img-block">
                                                <img src={MotorInsurance} alt="life-insurance" />
                                            </div>
                                            <div className="insurance-type-content-block">
                                                <h5>Motor Insurance</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            {/*Footer*/}
            <Footer isUserLogin={isUserLogin} />
        </Fragment >
    )
}
//#endregion render 
export default LandingPage;