// Library import
import React from 'react';
import { Button, Modal } from "@themesberg/react-bootstrap";

// Local import
import messageHelper from '../utilities/helpers/messageHelper';

// Common Confirm Box(Yes/No)
const CommonConfirmBox = (props) => {
    const { openConfirmBox, handleConfirmBox, setConfirmBoxValue, title = messageHelper('mark-as-completed') } = props

    return (
        <Modal show={openConfirmBox} onHide={handleConfirmBox} size="sm" className={'e-sign-model-body-default'} centered>
            <Modal.Body>
                <div className="app" >
                    <div>
                        {title}
                    </div>
                    <div className='my-confirm-box'>
                        <Button
                            onClick={() => { setConfirmBoxValue(true) }}
                            style={{ borderRadius: 20, borderColor: '#0184FE', borderWidth: 0, backgroundColor: '#0184FE', color: '#fff', marginRight: 10 }}>
                            Yes
                        </Button>
                        <Button
                            onClick={handleConfirmBox}
                            style={{ borderRadius: 20, borderColor: '#0184FE', borderWidth: 2, backgroundColor: '#fff ', color: '#0184FE' }}>
                            No
                        </Button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}
export default CommonConfirmBox;