/*
A common agent actions file , which contains all the api's which will be used in agents module.
*/

import { authApi } from '../utilities/helpers/ApiHelper';
import { toast } from 'react-hot-toast';

// Sign In Api
async function SignInApi(userDetails) {
    try {
        const response = await authApi.postAPI('signin', userDetails);
        // console.log("response from login", response);
        return response;

        // if (response.status_code === 1) {
        //     toast.success(response.message);
        //     localStorage.setItem("userDetails", JSON.stringify(response.data));
        //     return response.data
        // }
        // else toast.error("Something went wrong!");
    }
    catch (e) {
        toast.error("Something went wrong!");
    }
}

//Get Countries Api
async function GetCountriesApi() {
    let url = 'country-codes';
    const response = await authApi.getAPI(url);
    // console.log("response at country code", response);
    // if (response.status_code === 1) {
        // return response.data;
    // } else {
    //     toast.error(response.message);
    // }
    return response;
}

//Otp Verify Api
async function OtpVerifyApi(otp) {

    try {
        const response = await authApi.postAPI('signin-verify', otp);

        // if (response.status_code === 1) {
        //     toast.success(response.message);
        //     return response;
        // } else {
        //     toast.error(response.message);
        // }
        return response;
    }
    catch (e) {
        toast.error("Something went wrong!");
    }
}

//Click by customer
async function ClientSelectOptionAPI(data) {

    try {
        const response = await authApi.postAPI('client-select-visa-option', data);
        return response;
    }
    catch (e) {
        toast.error("Something went wrong!");
    }
}


export { OtpVerifyApi, GetCountriesApi, SignInApi, ClientSelectOptionAPI }