//Library import
import React, { Fragment, useEffect, useState } from "react";
import {  Card, } from '@themesberg/react-bootstrap';
import { useHistory } from 'react-router-dom';
import { toast, Toaster } from 'react-hot-toast';
import "../../scss/volt.scss";
import "react-datetime/css/react-datetime.css";
import io from 'socket.io-client'

//Local import
import { GetNotificationsApi, MarkNotificationAsReadByIdApi } from "../../actions/CommonActions.jsx";
import { DataLoading } from "./Loader.jsx";
import { CapitalizeAllWords } from "../../common-components/StringConversions.jsx";
import { SOCKET_SERVER_URL } from "../../config.tsx";
import NoDataFound from './NoDataFound.jsx';
import '../../assets/global-css/Index.css';
import CommonServices from "../../common-components/CommonServices.jsx";
import styles from '../../views/mobile-notifications/Index.module.css'
import '../../assets/mobile-custom-css/Index.css';
import ProfileIconsModal from "./ProfileIconsModal.jsx";
import messageHelper from "../../utilities/helpers/messageHelper.js";


// Notification for mobile view only
const MobileNotificationsTable = () => {

    const history = useHistory();

    const [notificationArray, setNotificationArray] = useState([]);
    const [isLoading, setIsLoading] = useState();
    const [loggedInUserId, setLoggedInUserId] = useState();
    const [newNotification, setNewNotification] = useState({})
    const [socket, setSocket] = useState('')

    //useEffect
    useEffect(() => {
        let amUserId = localStorage.getItem('amUserId')
        if (amUserId) {
            setLoggedInUserId(amUserId)
            GetAllNotifications()
        }

        // Create Socket
        const newSocket = io(SOCKET_SERVER_URL)
        setSocket(newSocket)

        newSocket.on('connect', () => {
            newSocket.emit('connect_on_notification', amUserId)
        })

        newSocket.on('new_notification', (response) => {
            // setNewNotification(response)
            setNotificationArray(response)
        })

    }, [])

    // Update New Notification on Top
    useEffect(() => {
        // If notification data exist
        if (notificationArray.length) {
            let indexOfUserId = null
            let newMessageKeys = Object.keys(newNotification)
            if (newMessageKeys.length) {
                const updatedUsers = notificationArray.map((item, index) => {
                    if (item.redirct_link == newNotification.redirct_link) {
                        if (indexOfUserId) {
                            return item
                        }
                        indexOfUserId = index
                        return { ...item, ...newNotification }
                    }
                    return item
                })
                if (indexOfUserId) {
                    // Store the user object
                    const objectToMove = updatedUsers[indexOfUserId];

                    // Remove the object
                    updatedUsers.splice(indexOfUserId, 1);

                    // Insert the object at the 1st index
                    updatedUsers.splice(0, 0, objectToMove);
                }
                setNotificationArray(updatedUsers)
            } else {
                setNotificationArray([newNotification])
            }
        } else {
            // if no notifications
            setNotificationArray([newNotification])
        }
    }, [newNotification])

    // get all notifications
    const GetAllNotifications = async () => {
        setIsLoading(true)
        setNotificationArray([])
        const response = await GetNotificationsApi();
        setIsLoading(false)
        if (response?.status_code == 1) {
            setNotificationArray(response.data)
        } else {
            const message = response?.message ? response.message : messageHelper('error')
            toast.error(message, { duration: 4000 });
        }
    }

    // handle on click event
    const handleClickOnNotification = async (props) => {
        const { id, notification_type, redirct_link, users_json, from_user_uuid } = props;

        const redirectLink = redirct_link.split('/');

        if (notification_type === "chat") {
            history.push(`/chat`, { userId: redirectLink[2], caseId: redirectLink[3], caseName: users_json.case_name });
        }
        else if (notification_type === "agent-requested-access-profile") {
            history.push('/admin/clients/edit-client', { userId: from_user_uuid });
        }
        else if (notification_type === "agent-request-profile-column-name") {
            history.push('/admin/clients/edit-client', { userId: from_user_uuid });
        }
        else if (notification_type === "agent-invite-request") {
            history.push('/customer/requests/received');
        }
        else if (notification_type === "cleint-requested-documents") {
            history.push('/customer/requests/recieved');
        }
        else if (notification_type === "client-uploaded-document") {
            history.push('/cases/case-details', { userId: loggedInUserId, caseId: users_json.case_id, caseName: CapitalizeAllWords(users_json.case_name) });
        }
        else if (["agent-uploaded-document", 'agent-registered-request', 'create-agent', "agent-request"].includes(notification_type)) {
            history.push('/admin/agents');
        }
        else if (notification_type === "view-myprofile") {
            history.push('/myprofile');
        }
        else {
            history.push('/');
        }
        // call mark as read notification API
        const response = await MarkNotificationAsReadByIdApi(id);
        GetAllNotifications();
    };

    // table row
    const TableRow = (props) => {
        const { id, users_json, notification, notification_timestamp, redirct_link } = props;
        return (
            <div onClick={() => handleClickOnNotification(props)} className={styles.notificationTableClass}>
                <div className={styles.userinfodetails}>
                    <div>
                        <ProfileIconsModal userDetails={users_json}/>
                    </div>
                    <div className={styles.notificationTextblock}>
                        <div className={styles.notificationText}>{notification}</div>
                    </div>
                </div>
                <div className={styles.dateAndTime}>{CommonServices.getDateTimeByTimestampForList(notification_timestamp)}</div>
            </div>
        )
    };

    //#region JSX
    return (
        <Fragment>
            
            {isLoading ? <DataLoading />
            :
            notificationArray.length ?
                (<Card border="light" className="notification-table-card shadow-sm mb-4 ">
                    <Card.Body className="pb-0">
                        {notificationArray.map((item, index) => <TableRow key={`client-${index}`} {...item} />)}
                    </Card.Body>
                </Card>)
                :
                <NoDataFound />
            }
            <Toaster position="top-right" reverseOrder={false} />
        </Fragment >
    )
    //#endregion
}
export default MobileNotificationsTable;

