import React, { Fragment, useEffect, useState } from "react";
import { Container } from "@themesberg/react-bootstrap";
import { useHistory } from "react-router-dom";
import AirMigrateLogo from "../assets/img/landing-page/logo-img.png"
import { DataLoading } from "../components/air-migrate/Loader";
const UnleashMessage = () => {

    const history = useHistory();
    const [screenSize, setScreenSize] = useState(getCurrentDimension());
    const [singleScreen, setSingleScreen] = useState(false);
    const [isloader, setisLoader] = useState(true)
    useEffect(() => {
        const timer = setTimeout(() => {
            setisLoader(false)
        }, 100000);

        //functionality to check dimensions of screen 
        window.addEventListener('resize', updateDimension);
        updateDimension();

        // removing eventlistener for no furhter effect after 
        return (() => {
            window.removeEventListener('resize', updateDimension);
            clearTimeout(timer);
        })
    }, []);

    const updateDimension = () => {
        setScreenSize(getCurrentDimension());
        var value = getCurrentDimension();
        // set a variable true when screen width reaches <768px
        if (value.width <= 767) {
            setSingleScreen(true)
        } else {
            setSingleScreen(false)
        }
    }

    function getCurrentDimension() {
        return {
            width: window.innerWidth,
            height: window.innerHeight
        }
    }

    return (
        <Fragment>
            {isloader ?(<DataLoading/>) :(
            <Container className='d-flex justify-content-center align-items-center' fluid={true} style={{ height: getCurrentDimension().height / 1.8 }} >
                <div className="text-center no-data-found-block">
                    <img src={AirMigrateLogo} alt="logo image" onClick={() => history.push('/')} className='cursor-pointer' />
                    <h1 className={`c-lg mt-5  ${singleScreen === true ? 'f-16' : 'f-18'}`}>Sorry this page is not available right now.</h1>
                </div>
            </Container>)}
        </Fragment>
    )

};
export default UnleashMessage;