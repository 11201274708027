import { Modal } from "@themesberg/react-bootstrap";
import messageHelper from "../../utilities/helpers/messageHelper";

const CaseCompeteInfoModal = ({showCaseCompleteInfoModal,setshowCaseCompleteInfoModal}) => {
    const handleCaseCompeteInfoModal = () => setshowCaseCompleteInfoModal(!showCaseCompleteInfoModal);
//#region jsx
    return (
        <Modal show={showCaseCompleteInfoModal} onHide={handleCaseCompeteInfoModal} size="sm" className={'e-sign-model-body-default case-complete-modal'} centered>
            <Modal.Body>
                <div className="app p-4" >
                    <div>
                        <h4>{messageHelper("case-completed")}</h4>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}
export default CaseCompeteInfoModal;