import React from 'react';
import { Fragment, useEffect, useState } from 'react';
import "react-datetime/css/react-datetime.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCog, faHome, faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Form, Button, ButtonGroup, Breadcrumb, InputGroup, Dropdown } from '@themesberg/react-bootstrap';

//Local imports
import ClientListTable from '../../components/air-migrate/ClientListTable';
import "../../scss/volt.scss";
import '../../assets/global-css/Index.css';
import { useHistory } from 'react-router-dom';
import CommonServices from '../../common-components/CommonServices';
import { HomepageUrl } from '../../utilities/helpers/Constant';
import CommonBreadcrumb from '../../common-components/CommonBreadcrumb';



const ClientList = () => {

    //#region States and variables
    const history = useHistory();
    const [searchText, setSearchText] = useState('');
    const [userRoleId, setUserRoleId] = useState(localStorage.getItem('roleId'));
    const [isSearchChange, setIsSearchChange] = useState(false);
    const [breadcrumbArray, setBreadcrumbArray] = useState([
        { name: 'Home', url: HomepageUrl },
        { name: 'Clients', url: '' },
    ]);

    //States to manage mobile ui
    const [screenSize, setScreenSize] = useState(getCurrentDimension());
    const [singleScreen, setSingleScreen] = useState(false);
    //#endregion

    //#region useEffect
    useEffect(() => {
        //functionality to set collapsible table 
        window.addEventListener('resize', updateDimension);
        updateDimension();

        // removing eventlistener for no furhter effect after 
        return (() => {
            window.removeEventListener('resize', updateDimension);
        })
    }, [searchText])
    //#endregion

    //#region Functions
    const updateDimension = () => {
        setScreenSize(getCurrentDimension());
        var value = getCurrentDimension();
        // set a variable true when screen width reaches <768px
        if (value.width <= 767) {
            setSingleScreen(true)
        } else {
            setSingleScreen(false)
        }
    }

    function getCurrentDimension() {
        return {
            width: window.innerWidth,
            height: window.innerHeight
        }
    }
    //#endregion

    //#region Styles
    //#endregion

    //#region JSX
    /* Check Unleash feature is enabled or not to show/hide client list and add client button*/
    let clientListFlag = CommonServices.GetUnleashFeatureFlag('Client-list-and-search');
    // console.log("clientListFlag", clientListFlag)
    let addClientFlag = CommonServices.GetUnleashFeatureFlag('new-client-add');
    // console.log("addClientFlag",addClientFlag)
    /* end */

    return (
        <Fragment>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-0 pb-2">
                <div className="d-block  mb-md-0">
                    {/* <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
                        <Breadcrumb.Item className='pe-auto' onClick={() => history.push(HomepageUrl)}><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
                        <Breadcrumb.Item active>Clients</Breadcrumb.Item>
                    </Breadcrumb> */}
                    <CommonBreadcrumb breadcrumbArray={breadcrumbArray} />
                </div>

            </div>

            <div className={`table-settings  ${(singleScreen === true) ? '' : 'mb-4'}`}>
                <div>    <h4 className={`${(singleScreen === true) ? 'f-18' : 'mt-2 '}`}>Client list</h4></div>
                {clientListFlag &&
                    <Row className={`justify-content-between align-items-center ${(singleScreen === true) ? 'mb-2' : ''}`}>
                        <Col xs={12} sm={6} md={6} lg={8} xl={8} >

                            {addClientFlag &&
                                <div className={`btn-toolbar mb-2 mb-md-0 ${(singleScreen === true) ? 'mt-2' : 'mt-2'}`}>
                                    {userRoleId == 2 &&
                                        <ButtonGroup>
                                            <Button className={`border-0 bg-c-dg ${(singleScreen === true) ? 'f-18' : ''}`}
                                                onClick={() => history.push("/admin/clients/add-client")}
                                            >
                                                <FontAwesomeIcon className={`${(singleScreen === true) ? 'f-10' : ''}`} icon={faPlus} /> Add Client</Button>
                                        </ButtonGroup>
                                    }
                                </div>
                            }
                        </Col>

                        {/* <Col xs={12} sm={6} md={6} lg={4} xl={4} ></Col> */}
                        <Col xs={12} sm={6} md={6} lg={4} xl={4} >
                            <InputGroup className={`${(singleScreen === true) ? 'mt-2 mb-2' : ''}`}>
                                <InputGroup.Text>
                                    <FontAwesomeIcon icon={faSearch} />
                                </InputGroup.Text>
                                <Form.Control className={`${(singleScreen === true) ? 'f-18 ' : 'f-16'}`} type="text" placeholder="Search" value={searchText} onChange={(e) => { setIsSearchChange(true); setSearchText(e.target.value) }} />
                            </InputGroup>
                        </Col>
                    </Row>
                }
            </div>

            {clientListFlag &&
                <ClientListTable search={searchText} isSearchChange={isSearchChange} singleScreen={singleScreen} />
            }

        </Fragment>
    )
    //#endregion
}

export default ClientList;