import React, { Fragment, useEffect, useState } from 'react'
import './Index.css'

import Mobileslider from '../../assets/img/home/mobile-slider.png'
import Mobileshadow from '../../assets/img/home/mobile-shadow-slider.png'
import Shaplogo from '../../assets/img/home/shap-logo.png'
import Header from '../../layout/Header'
import AirMigrateLogo from '../../assets/img/landing-page/logo-img.png'
import { useHistory } from 'react-router-dom'
import AOS from 'aos'
import 'aos/dist/aos.css'
// import Carousel from '../../components/air-migrate/Carousal'
import CommonServices from '../../common-components/CommonServices'
import Footer from '../../layout/Footer'
const Home = () => {
  const history = useHistory()
  // const location = useLocation();
  const [userRoleId, setUserRole] = useState(localStorage.getItem('roleId'))
  const [userId, setUserId] = useState(localStorage.getItem('amUserId'))
  const [isUserLogin, setIsUserLogin] = useState(false)
  const landingPageMarginTop = isUserLogin
    ? 'landing-page-margin-top-logged-in'
    : 'landing-page-margin-top-logout'
  const [currentStatus, setCurrentStatus] = useState(
    localStorage.getItem('status')
  )
  const [visaOption, setVisaOption] = useState(
    localStorage.getItem('visaOption')
  )

  const [isVisaApproved, setIsVisaApproved] = useState(
    localStorage.getItem('isVisaApproved')
  )

  const handleHover = () => {
    // function () {
    //     $(".airmigrate-helps-block").onmouseover(function () {
    //         alert("You entered p1!");
    //     });
    // };
  }

  useEffect(() => {
    setIsUserLogin(false)
    var userDetails = localStorage.getItem('accessToken')
    if (userDetails) {
      setIsUserLogin(true)
      if (userRoleId == 1) {
        history.push('/admin/agents')
      } else if (userRoleId == 3 && visaOption == 2 && (!isVisaApproved)) {
        // home
        // history.push('./')
      } else if (currentStatus != 1) {
        // home
        // history.push('./')
      } else {
        history.push('/chat', { userId: userId })
      }
    }
    AOS.init({
      duration: 800,
      once: true
    })
  }, [])

  /* unleash fetaure flag for chat now button */
  const chatNowButtonFeatureFlag =
    CommonServices.GetUnleashFeatureFlag('frontend-chat-now')

  return (
    <Fragment>
      <Header isUserLogin={isUserLogin} setIsUserLogin={setIsUserLogin} />
      <section className='landing-page-area-block'>
        <div className='home-slider-block'>
          <div className='home-slider-block-inner'></div>

          <div className='custom-container'>
            {/* <div className='main-header'> */}
            {/* <Header /> */}

            {/* </div> */}

            <div className='home-slider-content-blcok'>
              <div className='row'>
                <div className='col-sm-12 col-md-6'>
                  <div className='slider-mobile-image-section'>
                    <div className='slider-mobile-image'>
                      <img
                        src={Mobileslider}
                        alt='slider mobile image'
                        width='600px'
                        height='auto'
                      />
                    </div>
                    <div className='slider-mobile-shadow-image'>
                      <img
                        src={Mobileshadow}
                        alt='mobile shadow image'
                        width='1px'
                        height='1px'
                      />
                    </div>
                  </div>
                </div>

                <div className='col-sm-12 col-md-6'>
                  <div className='slider-text-block-area'>
                    <div className='slide-shap-logo-image'>
                      <img
                        src={Shaplogo}
                        alt='shap logo image'
                        width='1px'
                        height='1px'
                      />
                      <span className='welcome-text'>Welcome to</span>
                      <div className='airmigrate-text slider-logo-block'>
                        {/* <a href='/'> */}
                        <img
                          src={AirMigrateLogo}
                          alt='logo image'
                          onClick={() => history.push('/')}
                          width='1px'
                          height='1px'
                        />
                        {/* </a> */}
                      </div>
                      <div className='slider-text'>
                        <p>
                          Your one stop shop for all pre and post immigration
                          requirements.{' '}
                        </p>
                      </div>
                      {chatNowButtonFeatureFlag && (
                        <div className='chat-now-button'>
                          <button
                            className='chat-now-btn'
                            onClick={() => {
                              history.push('/quick-chat')
                            }}
                          >
                            Chat Now
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* custom-container */}
        </div>
        {/* home-two-transparent-box-block */}
        <section className='immigrant-block-area'>
          <div className='home-two-box-block'>
            <div className='container'>
              <div className='immigrant-section-content-block'>
                <div className='immigrant-section-block-inner h-100 py-4 justify-content-center '>
                  <div
                    onClick={() => {
                      history.push('/quick-chat')
                    }}
                    className='immigrant-content-block stop-text-animation cursor-pointer'
                  >
                    <span className='immigrant-heading mb-0 '>
                      Apply for a{' '}
                      <span className='heading-blue-text'>Visa</span>
                    </span>
                    <p className='immigrant-text-info'>
                      Get assistance with your visa application and find the
                      right immigration consultant for your needs.
                    </p>
                  </div>
                </div>

                <div className='immigrant-section-block-inner h-100 py-4 justify-content-center'>
                  <div
                    onClick={() => {
                      history.push('/explore')
                    }}
                    className='immigrant-content-block stop-text-animation cursor-pointer'
                  >
                    <span className='immigrant-heading mb-0 '>
                      Already Have a{' '}
                      <span className='heading-blue-text'>Visa</span>
                    </span>
                    <p className='immigrant-text-info'>
                      Discover great deals and get support to help you settle
                      down smoothly in Canada.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <div className='home-image-gallery-section'>
                    <div className='custom-container'>
                        <div className='home-image-gallery-inner'>
                        </div>
                    </div>
                </div> */}
        {/* home-image-gallery-section */}

        <div className='airmigrate-helps-block'>
          <div className='airmigrate-helps-content-block'>
            <div className='airmigrate-helps-block-heading'>
              How <span className='custom-color-text'>AirMigrate</span> Helps
              You ?
            </div>
            <p className='airmigrate-helps-content-text'>
              Welcome to AirMigrate, your bridge to a new beginning. We
              streamline your move by linking you with top immigration
              specialists and providing the resources you need to settle into
              your new country seamlessly.
            </p>

            <div
              className='airmigrate-mobile-slider'
              onMouseOver={() => {
                handleHover()
              }}
            >
              <div className='airmigrate-helps-info-type-block'>
                <div className='airmigrate-helps-info-inner'>
                  <div
                    className='helps-info-type-text-block1 helps-anim1'
                    data-aos='fade-right'
                    data-aos-duration='800'
                  >
                    <h3>
                      <span className='custom-color-text'>Use AI </span>to Ask
                      Questions
                    </h3>
                    <p>
                      Engage with our intelligent AI, designed to understand and
                      address your immigration queries instantly. It's like
                      having an advisor at your fingertips, anytime you need.
                    </p>
                  </div>
                  <div
                    className='helps-info-type-border1 helps-info-anim-border helps-anim-border1'
                    data-aos='fade-left'
                  ></div>
                </div>

                <div className='airmigrate-helps-info-inner'>
                  <div
                    className='helps-info-type-text-block1 helps-anim2'
                    data-aos='fade-right'
                    data-aos-duration='1000'
                  >
                    <h3>
                      Settlement{' '}
                      <span className='custom-color-text'>Support</span>
                    </h3>
                    <p>
                      Transition smoothly with our settlement assistance. From
                      insurance to housing, we connect you with advisors ready
                      to turn your new place into a home.
                    </p>
                  </div>
                  <div
                    className='helps-info-type-border1 helps-info-anim-border helps-anim-border2'
                    data-aos='fade-left'
                  ></div>
                </div>

                <div className='airmigrate-helps-info-inner'>
                  <div
                    className='helps-info-type-text-block1 helps-anim3'
                    data-aos='fade-right'
                    data-aos-duration='1200'
                  >
                    <h3>
                      <span className='custom-color-text'> Secure </span>
                      Conversational Tech
                    </h3>
                    <p>
                      Experience peace of mind with our secure platform,
                      offering bank-grade security for all your documents and
                      conversations.
                    </p>
                  </div>
                  <div
                    className='helps-info-type-border1 helps-info-anim-border helps-anim-border3'
                    data-aos='fade-left'
                  ></div>
                </div>
              </div>
              <div className='airmigrate-mobile-slider-inner'>
                <div className='airmigrate-mobile-slide-content'></div>
              </div>
              <div className='airmigrate-helps-info-type-block helps-mobile-info-right-content'>
                <div className='airmigrate-helps-info-inner'>
                  <div
                    className='helps-info-type-border1 helps-info-anim-border right-anim  helps-anim-border4'
                    data-aos='fade-right'
                  ></div>
                  <div
                    className='helps-info-type-text-block1 helps-anim4'
                    data-aos='fade-left'
                    data-aos-duration='900'
                  >
                    <h3>
                      {' '}
                      Find{' '}
                      <span className='custom-color-text'>
                        Immigration Advisors
                      </span>
                    </h3>
                    <p>
                      With AirMigrate, discover a network of accredited
                      immigration advisors who can offer tailored guidance for
                      your unique journey.
                    </p>
                  </div>
                </div>
                <div className='airmigrate-helps-info-inner'>
                  <div
                    className='helps-info-type-border1 helps-info-anim-border right-anim helps-anim-border5'
                    data-aos='fade-right'
                  ></div>
                  <div
                    className='helps-info-type-text-block1 helps-anim5'
                    data-aos='fade-left'
                    data-aos-duration='1000'
                  >
                    <h3>
                      <span className='custom-color-text'> Best Deals </span>
                      Just for You
                    </h3>
                    <p>
                      We don't just move you; we set you up. Enjoy exclusive
                      deals on banking, telecom, and more, handpicked for
                      AirMigrate members.
                    </p>
                  </div>
                </div>
              </div>
              {/* airmigrate-helps-info-type-block */}
            </div>

            {/* <div className='slider-text-mobile'>
              <Carousel className='crousel-slider-text-mobile'>
                <div className='helps-info-slide-block'>
                  <div
                    className='helps-info-type-text-block1 helps-anim1'
                    data-aos='fade-right'
                    data-aos-duration='800'
                  >
                    <h3>
                      Settlement{' '}
                      <span className='custom-color-text'>Support</span>
                    </h3>
                    <p>
                      Transition smoothly with our settlement assistance. From
                      insurance to housing, we connect you with advisors ready
                      to turn your new place into a home.
                    </p>
                  </div>
                </div>
                <div className='helps-info-slide-block'>
                  <div
                    className='helps-info-type-text-block1 helps-anim2'
                    data-aos='fade-right'
                    data-aos-duration='1000'
                  >
                    <h3>
                      <span className='custom-color-text'>Use AI </span>to Ask
                      Questions
                    </h3>
                    <p>
                      Engage with our intelligent AI, designed to understand and
                      address your immigration queries instantly. It's like
                      having an advisor at your fingertips, anytime you need.
                    </p>
                  </div>
                </div>
                <div className='helps-info-slide-block'>
                  <div className='helps-info-type-text-block1 helps-anim3'>
                    <h3>
                      <span className='custom-color-text'> Secure </span>
                      Conversational Tech
                    </h3>
                    <p>
                      Experience peace of mind with our secure platform,
                      offering bank-grade security for all your documents and
                      conversations.
                    </p>
                  </div>
                </div>
                <div className='helps-info-slide-block'>
                  <div className='helps-info-type-text-block1 helps-anim4'>
                    <h3>
                      {' '}
                      Find{' '}
                      <span className='custom-color-text'>
                        Immigration Advisors
                      </span>
                    </h3>
                    <p>
                      With AirMigrate, discover a network of accredited
                      immigration advisors who can offer tailored guidance for
                      your unique journey.
                    </p>
                  </div>
                </div>
                <div className='helps-info-slide-block'>
                  <div className='helps-info-type-text-block1 helps-anim4'>
                    <h3>
                      {' '}
                      Find{' '}
                      <span className='custom-color-text'>
                        Immigration Advisors
                      </span>
                    </h3>
                    <p>
                      With AirMigrate, discover a network of accredited
                      immigration advisors who can offer tailored guidance for
                      your unique journey.
                    </p>
                  </div>
                </div>
              </Carousel>
            </div> */}
          </div>
        </div>
        {/* airmigrate-helps-block */}

        {/* <section className='footer-section'>
                    <footer className='footer-block'>
                        <div className='custom-container'>
                            <div className='footer-logo-text'>
                                <img src={Footerlogo} alt='footer logo' />
                            </div>

                            <div className='row'>

                                <div className='col-sm-12 col-md-12 col-lg-4' >
                                    <div className='footer-logo'>
                                        <img src={Logo} alt='footer logo' />
                                    </div>
                                    <div className='footer-button-block'>
                                        <a><img src={Appstore} alt='Appstore button image' /></a>
                                        <a><img src={Googleplay} alt='googleplay button image' /></a>
                                    </div>
                                </div>

                                <div className='col-sm-12 col-md-12 col-lg-4'>
                                    <div className='footer-menu'>
                                        <div className='footer-menu-inner'>
                                            <h4>Quick Links</h4>
                                            <ul>
                                                <li><a onClick={() => history.push('/about-us')}>About Us</a></li>
                                                <li><a onClick={() => { }}>Services</a></li>
                                                <li><a onClick={() => { history.push('/review') }}>Review</a></li>
                                                <li><a onClick={() => { history.push('/explore') }}>Explore</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>


                                <div className='col-sm-12 col-md-12 col-lg-4'>
                                    <div className='subscribe-block'>
                                        <h4>Subscribe</h4>
                                        <p>Sign up for our monthly blogletter to stay informed about our services.</p>
                                        <div className='email-send-block'>
                                            <input type='text' placeholder='Email Address' />
                                            <button type='submit'>Send</button>
                                        </div>
                                    </div>
                                </div>

                                <div className='copyright-section'>
                                    <h5>Copyright © Airmigrate 2023</h5>
                                </div>

                            </div>
                        </div>
                    </footer>
                </section> */}

        {/*Footer*/}
        <Footer isUserLogin={isUserLogin} />
      </section>
    </Fragment>
  )
}
export default Home
