
const API_BASE_URL = 'https://api.airmigrate.com/'
// const API_BASE_URL = 'http://localhost:6002/'
export const SOCKET_SERVER_URL = API_BASE_URL
export const BASE_URL = API_BASE_URL + "api/";
export const PROFILE_PATH  = 'https://air-migrate-public-bucket.s3.ca-central-1.amazonaws.com/default-broker-image.png';
export const UNLEASH_FLAG = true;
export const UNLEASH_BASE_URL = 'https://unleash.airmigrate.com/api/frontend'
export const UNLEASH_CLIENT_KEY = 'default:development.unleash-insecure-frontend-api-token'
export const UNLEASH_APP_NAME = 'default'
// Google's map conts
export const GOOGLE_MAP_API_KEY = 'AIzaSyClUg2NbT5X8DEKfbAhy2yeBdY3I1QXw8E';
export const GOOGLE_MAP_CIRCLE_RANGE = 20000; // range in meters
// Phoneinput consts for  country code and area code
export const Default_Country_Code = 1;  // default country code is US (USA)
export const Default_Country = 'us';  // default country  US (USA)
// minimum and maximum limits here
export const RCIC_NUMBER_MINIMUM_LIMIT = 4;
export const LICENCE_NUMBER_MINIMUM_LIMIT = 4;
export const RCIC_NUMBER_MAXIMUM_LIMIT = 15;
export const LICENCE_NUMBER_MAXIMUM_LIMIT = 15;
export const PROFILE_UPLOAD_LIMIT = 5242880;   // 5MB
export const DOCUMENT_UPLOAD_LIMIT = 10485760;   // 10MB
// time delay to show alert befor redirecting to other page
export const ALERT_SHOW_TIME = 2000; //2 SECONDS
export const ALERT_HIDE_TIMER = 10000 // 10 SECONDS
// ChatGPT Variable For Consultant api and Selection for agents 
export const CHATGPT_CONSULTANT_LIMIT = 5;
export const CHATGPT_CONSULTANT_SELECT = 3;
export const CHATGPT_QUESTIONS = 5;
export const DEFAULT_MAP_CENTER ={lat:49.2827291,long:-123.1207375} //vancouver
// const for Case name 
export const CASE_NAME_PREFIX ='AIR-CASENAME';
// Static ID
export const DIRECT_LOGIN_ID = '9d655970-1ccc-4958-bfd5-c54d3a910ce5';

export const GEMINI_API_KEY= 'AIzaSyAVgvJdDYqkFVUrBFa2zv8YWoBuc1gJ0Mo'
// / Docuseal base URL for ESign
export const DOCUSEAL_BASE_URL = 'https://docuseal.co/d'