import { useFlag } from '@unleash/proxy-client-react';
import { UNLEASH_FLAG } from '../config.tsx';
import { GetLinkToDownloadDocumentApi } from '../actions/FileUploadActions.jsx';
import { Capitalize } from './StringConversions.jsx';
/* common unleash functinality here*/
const GetUnleashFeatureFlag = (key) => {
    // const unleashFeatureFlag = useFlag(key)
    return UNLEASH_FLAG ? GetUnleashTrueFalse(key) : true;
    // return true
}

const GetUnleashTrueFalse = (key) => {
    const unleashFeatureFlag = useFlag(key)
    return unleashFeatureFlag;

}

// Timestamp to date time
// const getDateTimeByTimestamp = (timestamp) => {

//     if (!timestamp) {
//         return ''
//     }

//     const messageDate = new Date(timestamp * 1000);
//     const today = new Date();

//     const diffInDays = Math.floor((today - messageDate) / (1000 * 60 * 60 * 24));

// if (diffInDays === 0 || diffInDays == -1) {
//     return messageDate.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' });
// } else if (diffInDays === 1) {
//         return 'Yesterday';
//     } else if (diffInDays < 7) {
//         return messageDate.toLocaleDateString([], { weekday: 'long' });
//     } else {
//         return messageDate.toLocaleDateString([], { year: 'numeric', month: 'short', day: 'numeric' });
//     }
// }
const getDateTimeByTimestampForChat = (timestamp) => {
    if (!timestamp) {
        return ''
    }
    const messageDate = new Date(timestamp * 1000);
    const today = new Date();

    const diffInDays = Math.floor((today - messageDate) / (1000 * 60 * 60 * 24));
    let timeString = messageDate.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' })
    let weekOrDate = ''

    if (diffInDays === 0 || diffInDays == -1) {

    } else if (diffInDays === 1) {
        weekOrDate = 'Yesterday';
    } else if (diffInDays < 7) {
        weekOrDate = messageDate.toLocaleDateString([], { weekday: 'long' });
    } else {
        weekOrDate = messageDate.toLocaleDateString('en-GB');
    }
    if (weekOrDate) {
        return `${weekOrDate}, ${timeString}`
    }

    return `${timeString}`
}

// Without time
const getDateTimeByTimestampForList = (timestamp) => {
    if (!timestamp) {
        return ''
    }
    const messageDate = new Date(timestamp * 1000);
    const today = new Date();

    const diffInDays = Math.floor((today - messageDate) / (1000 * 60 * 60 * 24));
    let response = messageDate.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' })
    if (diffInDays === 0 || diffInDays == -1) {

    } else if (diffInDays === 1) {
        response = 'Yesterday';
    } else if (diffInDays < 7) {
        response = messageDate.toLocaleDateString([], { weekday: 'short' });
    } else {
        response = messageDate.toLocaleDateString('en-GB');
    }

    return response
}

// Full name to two Char
const getNameCharByFullName = (fullName) => {
    if (!fullName) {
        return fullName
    }
    let nameArray = fullName.split(" ")
    let firstChar = 'UN'
    let secondChar = ''

    if (nameArray.length) {
        let firstCharA = nameArray[0].split("")
        let secondCharA = []

        if (nameArray.length > 1) {
            secondCharA = nameArray[1].split("")
        }

        if (firstCharA.length) {
            firstChar = firstCharA[0]
        }

        // if (firstCharA.length > 1) {
        //     secondChar = firstCharA[1]
        // }

        if (secondCharA.length) {
            secondChar = secondCharA[0]
        }
    }
    return firstChar.toLocaleUpperCase() + secondChar.toLocaleUpperCase()
}

const downloadFileService = (docURL, fileName = "document", fileExtention = "pdf") => {
    // using Java Script method to get PDF file
    fetch(docURL).then((response) => {
        response.blob().then((blob) => {
            // Creating new object of PDF file
            const fileURL = window.URL.createObjectURL(blob);

            // Setting various property values
            let alink = document.createElement("a");
            alink.href = fileURL;
            alink.download = `${fileName}.${fileExtention}`;
            alink.click();
        });
    });
}

const getCurrentTimestamp = () => {
    return Math.floor((new Date()).getTime() / 1000)
}

// Document download function for all document lists
const callDocumentDownload = async (docId) => {
    let url = `view-document`;
    const downloadUrl = await GetLinkToDownloadDocumentApi(url, docId)
    let alink = document.createElement("a");
    alink.href = downloadUrl;
    // alink.download = `sakshi-client-business-aadhaar-card.pdf`;
    alink.click();

}

const getUserFullName = (userData) => {
    let firstName = userData?.first_name ? Capitalize(userData?.first_name) : 'User';
    let lastName = userData?.last_name ? Capitalize(userData?.last_name) : '';
    return firstName + ' ' + lastName
}

/* Function to check validate JSON */
function isJSON(str) {
    try {
        JSON.parse(str);
        return true;
    } catch (e) {
        return false;
    }
}
/* function for auto top scroll window */
function  AutoScrollToTopWindow() {
    window.scrollTo({
        top: 0,
        behavior: "smooth"
    });
}
/* function to check if user is logged in or not  */
function isUserLogin (){
    let user=localStorage.getItem('accessToken')
   if(user){
        return true;
    }else{
        return false;
    }
}

function isValidEmail(email) {
    // Define a regular expression pattern for email validation
    const pattern = /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/;
    return pattern.test(email);
  }

const CommonServices = {
    getDateTimeByTimestampForChat,
    getDateTimeByTimestampForList,
    getNameCharByFullName,
    GetUnleashFeatureFlag,
    downloadFileService, 
    getCurrentTimestamp,
    callDocumentDownload,
    getUserFullName,
    isJSON,
    AutoScrollToTopWindow,
    isUserLogin,
    isValidEmail
}

export default CommonServices
