import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const EsignedDocumentViewModal = ({ show, handleClose, docUrl }) => {
    return (
        // Render the modal with an iframe to display the document content.
        <Modal show={show} onHide={handleClose} size="lg">
                <>
                    <Modal.Header closeButton>
                        <Modal.Title>View Document</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <iframe
                            src={docUrl}
                            style={{ width: '100%', height: '65vh' }}
                            title="Document"
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </>
        </Modal>
    );
};
export default EsignedDocumentViewModal;