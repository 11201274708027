import styles from "./Index.module.css"
import { Fragment, useState, useEffect } from "react";
import RequestListTable from "../../components/air-migrate/RequestListTable";
import { Breadcrumb, Row, InputGroup, Col, Form, Button } from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faSearch } from "@fortawesome/free-solid-svg-icons";
// import DocumentTable from "./document-table/Index";
import { useHistory, useLocation } from 'react-router-dom';
import { CapitalizeAllWords } from "../../common-components/StringConversions";
import UserDocmentTableTest from "./user-document-table/Index";
import { HomepageUrl } from "../../utilities/helpers/Constant";
import { uploadFilesApi } from "../../actions/FileUploadActions";



const AllDocumentListDemo = () => {
    const [searchText, setSearchText] = useState('');
    const location = useLocation();
    const history = useHistory();
    const [isSearchChange, setIsSearchChange] = useState(false);
    const [userRoleId, setUserRoleId] = useState('roleId');
    const HeadingsWitoutAction = ['Document Type', 'Document Title', 'File Size', 'Date']
    const HeadingsWithAction = ['Document Type', 'Document Title', 'File Size', 'Date', 'Action']

    //States to manage mobile ui
    const [screenSize, setScreenSize] = useState(getCurrentDimension());
    const [singleScreen, setSingleScreen] = useState(false);

    //Alert states
    const [alertsArray, setAlertsArray] = useState([]);
    const [count, setCount] = useState(0);

    //Breadcrumb state
    const [breadcrumbArray, setBreadcrumbArray] = useState([
        { name: 'Home', url: HomepageUrl },
        { name: 'All Documents', url: '' }

    ]);

    //#region useEffect 
    useEffect(() => {
        //functionality to set collapsible table 
        window.addEventListener('resize', updateDimension);
        updateDimension();

        // removing eventlistener for no furhter effect after 
        return (() => {
            window.removeEventListener('resize', updateDimension);
        })
    }, [count])
    //#endregion

    //#region Functions
    const handleAlert = (id) => {
        let array = [];
        for (let i = 0; i < alertsArray.length; i++) {
            if ((alertsArray[i].id) == id) {
                array = alertsArray.pop();

            }
        }
        setCount(Math.floor(Math.random() * 1000))
    }

    const updateDimension = () => {
        setScreenSize(getCurrentDimension());
        var value = getCurrentDimension();
        // set a variable true when screen width reaches <768px
        if (value.width <= 767) {
            setSingleScreen(true)
        } else {
            setSingleScreen(false)
        }
    }

    function getCurrentDimension() {
        return {
            width: window.innerWidth,
            height: window.innerHeight
        }
    }

    // Function to handel file upload  progress
    const handleFileChange = (e) => {
        const selectedFiles = Array.from(e.target.files);
        if (selectedFiles) {
            handleFile(selectedFiles);    /*  Calling the handleFile function */
        }
    };
    const [filesObjectsArray, setFilesObjectsArray] = useState([]);
    // function to handle selected file  and display it on screen
    const handleFile = (selectedFiles) => {
        const newObjectsArray = selectedFiles.map(file => {
            var newFileArray = { 'myFileData': file }
            return newFileArray
        });
        setFilesObjectsArray([...newObjectsArray])
    }
    console.log("filesObjectsArray", filesObjectsArray)
    //#endregion
    const handleUpload = async () => {
        if (!filesObjectsArray) return;
        // Data to send with file    
        let formData = new FormData();
        filesObjectsArray.forEach((file) => {
            if (!file.error) {
                formData.append('docs', file.myFileData);
            }
        });

        let URL = 'upload-document-demo';
        try {
            const response = await uploadFilesApi(URL, formData);
            if (response.status_code === 1) {
                alert("File Uploaded Successfully")
                setFilesObjectsArray([]);
                document.getElementById("fileInput").value = "";
                console.log("response", response)
                const downloadUrl = response?.data[0].download;
                let alink = document.createElement("a");
                alink.href = downloadUrl;
                alink.click();
            }
        } catch (error) {
            alert('Upload failed:', error)
            console.error('Upload failed:', error);
        }
    };

    //#endregion

    return (
        <Fragment>
            <div className="">
                <div className="table-settings mb-4">
                    <Row className="border border-info-subtle">
                        <h1 style={{ textAlign: 'center', margin: '4px' }}>Document Compress Demo Page</h1>
                        <div style={{ height: '80vh', display: "flex", justifyContent: "center" }}>
                            <div style={{ display: "flex", justifyContent: "center", margin: 'auto' }}>
                                <input id="fileInput" type="file" multiple={true} accept="image/png, image/jpeg, image/jpg , application/pdf" onChange={handleFileChange} />
                                <Button className="" style={{ width: "auto", margin: 'auto' }} onClick={handleUpload}>Upload Document</Button>
                            </div>
                        </div>
                    </Row>
                </div>
            </div>
        </Fragment>
    )
}
export default AllDocumentListDemo;