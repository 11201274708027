import React, { useState } from 'react';
import { Modal, Button, Form, Row, Col } from '@themesberg/react-bootstrap';
import { DataLoading } from './Loader';
import queryImage from "../../assets/img/real-state/queryModalBanner.png"
import CommonAlert from '../../common-components/CommonAlert';
import { CreateQueryApi } from '../../actions/RealEstateActions';

const QueryModal = ({ errorMessage, setErrorMessage, show, handleClose, isLoading, setIsLoading, queryText, title, setTitle, setQueryText, userUuid, getQueryResponse }) => {
  //Alert states and functions
  const [alertsArray, setAlertsArray] = useState([]);
  const [alertCount, setAlertCount] = useState(0);
  const handleAlert = (id) => {
    let array = [];
    for (let i = 0; i < alertsArray.length; i++) {
      if ((alertsArray[i].id) == id) {
        array = alertsArray.pop();
      }
    }
    setAlertCount(Math.floor(Math.random() * 1000))
  }
  // function to handel send button click 
  const handleSend = () => {
    if (queryText.trim() === '' && title.trim() === '') {
      setErrorMessage({ ...errorMessage, isShowQueryError: true, isShowTitleError: true })
    }

    else if (queryText.trim() === '') {

      setErrorMessage({ ...errorMessage, isShowQueryError: true })
      return;
    }
    else if (title.trim() === '') {

      setErrorMessage({ ...errorMessage, isShowTitleError: true })
    }
    else {
      handleSendQuery(queryText, title);
      setQueryText('');
      setTitle('');
    }
  };

  // Api call to create new query request 
  const handleSendQuery = async (queryText, title) => {
    const requestBody = {
      notification: queryText,
      title: title
    }
    setIsLoading(true);
    const response = await CreateQueryApi(requestBody, userUuid);
    if (response.status_code === 1) {
      setIsLoading(false);
      getQueryResponse(response);
      handleClose();
    } else {
      setIsLoading(false);
      setAlertsArray([...alertsArray, { id: `${'send_query'}${alertsArray.length}`, type: 'danger', message: response?.message }]);
    }
  };
//#region render start
  return (
    <Modal as={Modal.Dialog} centered show={show} onHide={handleClose} size='lg' className='p-3'>
      <Modal.Body className='p-0'>
        <Row>
          {(alertsArray.length > 0) &&
            alertsArray.reverse().map((item, index) => {
              return (
                <div className='mb-0 pb-0'>
                  <CommonAlert key={index} id={item.id} type={item.type} message={item.message} handleAlert={handleAlert} />
                </div>
              )
            })
          }
          <Col sm={12} md={6} lg={6} className='query-modal-img'>
            <img src={queryImage} alt="" height={'100%'} width={'100%'} />
          </Col>
          <Col sm={12} md={6} lg={6} className='query-side-form-box'>
            <Row className='mt-2 mb-3 px-3'>
              <div className='d-flex justify-content-between query-modal-border'>
                <Modal.Title className="h4 mb-2">Send Query</Modal.Title>
                <Button variant="close" aria-label="Close" onClick={handleClose} />
              </div>
            </Row>
            <Form className='d-flex justify-content-between flex-column px-3'>
              <Row className='p-0'>
                <Form.Group >
                  <Form.Label>Give A Title</Form.Label>
                  <Form.Control
                    className='bg-white'
                    style={{ fontSize: '15px', color: '#66799e' }}
                    id="title"
                    name="title"
                    value={title}
                    type="text"
                    placeholder="Please enter title"
                    onChange={(e) => {
                      setTitle(e.target.value);
                      if (e.target.value !== '') {
                        setErrorMessage({ ...errorMessage, isShowTitleError: false })
                      }
                      else {
                        setErrorMessage({ ...errorMessage, isShowTitleError: true })
                      }
                    }}
                  />
                  {errorMessage.isShowTitleError === true &&
                    <p className='c-r f-14 mb-0 pb-0'>Please enter title</p>
                  }
                </Form.Group>
              </Row>
              <Row className='p-0'>
                <Form.Group controlId="queryText" className='mt-2'>
                  <Form.Label>Write Your Query</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={5}
                    value={queryText}
                    placeholder='Enter Query'
                    onChange={(e) => {
                      setQueryText(e.target.value)
                      if (e.target.value !== '') {
                        setErrorMessage({ ...errorMessage, isShowQueryError: false })
                      }
                      else {
                        setErrorMessage({ ...errorMessage, isShowQueryError: true })
                      }
                    }}
                  />
                  {errorMessage.isShowQueryError === true &&
                    <p className='c-r f-14 mb-0 pb-0'>Please enter query</p>
                  }
                </Form.Group>
              </Row>
            </Form>
            <Button onClick={handleSend} className='query-modal-button'>
              Send
            </Button>
          </Col>
        </Row>
      </Modal.Body>
      {isLoading === true && <DataLoading />}
    </Modal>
  );
};
//#endregion render end
export default QueryModal;
