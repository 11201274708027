import { configureStore } from '@reduxjs/toolkit'
import LoginReducer from './reducers/LoginReducer';
import ProfileImageReducer from './reducers/ProfileImageReducer';
import PaymentReducer from './reducers/PaymentReducer';
import GlobalReducer from './reducers/GlobalReducer';
import VisaUploadReducer from './reducers/VisaUploadReducer';

export const store = configureStore({
  reducer: {
    loginModule:LoginReducer,
    myProfileModule:ProfileImageReducer,
    myPaymentModule: PaymentReducer,
    globalModule:GlobalReducer,
    visaUploadModule:VisaUploadReducer
  },
})