import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { PROFILE_PATH } from "../../config.tsx";
import CommonServices from "../../common-components/CommonServices.jsx";
import QueryModal from "./QueryModal.jsx";
import { Button, Modal} from "@themesberg/react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setIsVisaUploaded, setVisaOption } from "../../reducers/VisaUploadReducer.jsx";
import FileUploadModal from "./FileUploadModal.jsx";
import { ClientSelectOptionAPI } from "../../actions/LoginAction.jsx";

const NewAgentCard = (props) => {
    const { isLoading, setIsLoading, profilePath, firstName, lastName, userUuid, isFavorite, handleFavoriteClick, getResponseForAlert, alertsArray, setAlertsArray } = props;
    const history = useHistory();
    const dispatch = useDispatch();
    // query modal consts
    const [showQueryModal, setShowQueryModal] = useState(false);
    const [queryText, setQueryText] = useState('');
    const [title, setTitle] = useState('');
    const [errorMessage, setErrorMessage] = useState({
        isShowTitleError: false,
        isShowQueryError: false
    });
    const [showLoginPopup, setShowLoginPopup] = useState(false);
    const handleLoginPopup = () => setShowLoginPopup(!showLoginPopup);

    const [showVisaUploadPopup, setShowVisaUploadPopup] = useState(false);
    const handleVisaUploadPopup = () => setShowVisaUploadPopup(!showVisaUploadPopup);

    // File Upload Popup
    const [showUploadPopup, setShowUploadPopup] = useState(false)
    const handleUploadPopup = () => setShowUploadPopup(!showUploadPopup);

    /* Check Unleash feature is enabled or not for favourite button */
    let selectUnselectFavouriteFlag = CommonServices.GetUnleashFeatureFlag('Select-Unselect-Favorite-Broker');
    const visaUploadModule = useSelector(state => state.visaUploadModule)

    // on click image
    const handleClick = (userUuid = null) => {
        if (!['1','2','4'].includes(localStorage.getItem('roleId'))) {
            if (!visaUploadModule.isUserLogin) {
                handleLoginPopup()
            }  // Uploaded & not Approved
            else if (visaUploadModule.isVisaUploaded && (!visaUploadModule.isVisaApproved)) {
                history.push('/status-pending-page')
            } // not Uploaded 
            else if (!visaUploadModule.isVisaUploaded) {
                handleVisaUploadPopup()
            }
            else {
                // when click on name of agent
                if (userUuid) {
                    history.push('agent-details', { userId: userUuid });
                } else{
                    setShowQueryModal(true);
                }
            }
        }
    };

    const clickOnLoginButton = () => {
        localStorage.setItem('toLink', '/real-estate')
        history.push("/login")
    }

    const closeQueryModal = () => {
        setShowQueryModal(false);
        setErrorMessage({
            isShowQueryError: false,
            isShowTitleError: false
        })
    };

    /* function to get response from query modal for alert */
    const getQueryResponse = (data) => {
        getResponseForAlert(data);
    }

    // Login Popup
    const LoginPopupModal = () => {
        return (
            <Modal show={showLoginPopup} onHide={handleLoginPopup} size="sm" className={'e-sign-model-body-default'} centered>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body >
                    <div className="app" style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ margin: 10, alignSelf: 'center', fontSize: 16 }}>
                            Please login to connect with Broker
                        </div>
                        <Button onClick={() => { clickOnLoginButton() }} style={{ margin: 20, alignSelf: 'center', borderRadius: 20, borderColor: '#0184FE', borderWidth: 0, backgroundColor: '#0184FE', width: 100, paddingTop: 5, paddingBottom: 5 }}>
                            Login
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
    // function to upload visa 
    const onClickVisaUploadButton = () => {
        handleUploadPopup()
        handleVisaUploadPopup()

        const visaStatus = 2
        localStorage.setItem("visaOption", visaStatus);
        dispatch(setVisaOption(visaStatus))

        const data = { 'visa_status': visaStatus }
        const response = ClientSelectOptionAPI(data)
        console.log('response', response)
    }

    // Visa Upload Popup Modal
    const VisaUploadPopupModal = () => {
        return (
            <Modal show={showVisaUploadPopup} onHide={handleVisaUploadPopup} size="sm" className={'e-sign-model-body-default'} centered>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body >
                    <div className="app" style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ margin: 10, alignSelf: 'center', fontSize: 16 }}>
                            Please upload your <a onClick={onClickVisaUploadButton} className="upload-visa-class">VISA</a> or <a onClick={onClickVisaUploadButton} className="upload-visa-class">DL</a> to connect with the Broker
                        </div>
                        <Button onClick={onClickVisaUploadButton} style={{ margin: 20, alignSelf: 'center', borderRadius: 20, borderColor: '#0184FE', borderWidth: 0, backgroundColor: '#0184FE', paddingTop: 5, paddingBottom: 5 }}>
                            Upload Visa/DL
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }

    // After visa upload
    const afterUploadSuccess = () => {
        localStorage.setItem("isVisaUploaded", true);
        dispatch(setIsVisaUploaded(true))
    }
//#region render start
    return (
        <>
            <div className="newcard-section mt-3">
                {selectUnselectFavouriteFlag && visaUploadModule.isUserLogin &&
                    <div className="new-heart-icon">
                        <span className="new-heart-fav-icon-block" onClick={handleFavoriteClick}>
                            <i className={`fa-solid fa-heart small ${isFavorite ? 'red' : ''}`}></i>
                        </span>
                    </div>
                }
                <img
                    src={profilePath ? profilePath : PROFILE_PATH}
                    alt={firstName}
                    className="new-consultant-images"
                    onClick={() => handleClick(userUuid)}
                />
                <div className="new-consultant-details">
                    <div className="inner-details">
                        <p className={`consultant-name cursor-pointer fw-bolder ${(!visaUploadModule.isUserLogin || !visaUploadModule.isVisaApproved) ? 'blurred-name' : ''}`}
                            onClick={() => handleClick(userUuid)}>{firstName ? firstName : "Not Available"} {lastName ? lastName : ''}</p>
                    </div>
                    <button type="button" className="query-btn-fav-new" onClick={() => handleClick()}>
                        Send a Query<i className="fa fa-paper-plane ms-2"></i>
                    </button>
                </div>
            </div>
            {/* query modal here */}
            <QueryModal
                errorMessage={errorMessage}
                setErrorMessage={setErrorMessage}
                queryText={queryText}
                setQueryText={setQueryText}
                title={title}
                setTitle={setTitle}
                show={showQueryModal}
                handleClose={closeQueryModal}
                setIsLoading={setIsLoading}
                isLoading={isLoading}
                userUuid={userUuid}
                getQueryResponse={getQueryResponse} />

            <LoginPopupModal />
            <VisaUploadPopupModal />
            {/* Document upload popup */}
            <FileUploadModal show={showUploadPopup} handleClose={handleUploadPopup} visaUpload={true} FunctionToRefreshAfterUpload={afterUploadSuccess} modalAlertsArray={alertsArray} setModalAlertsArray={setAlertsArray} popupTitle='Visa Upload' />

        </>
    );

};
//#endregion render end
export default NewAgentCard;
