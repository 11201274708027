import React, { Fragment, useEffect, useState } from 'react';
import { Alert, Button } from 'react-bootstrap';
import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import { ALERT_HIDE_TIMER } from '../config.tsx';

const CommonAlert = ({ id, type, message, handleAlert,isInInviteForm }) => {

    useEffect(()=>{
        const timer = setTimeout(()=>handleAlert(id),isInInviteForm?1500: ALERT_HIDE_TIMER);
        // return a cleanup function to clear the timer when the component unmounts
        return () => clearTimeout(timer)
    }, [handleAlert, id]);
    //#region Functions
    const [hiddenAlerts, setHiddenAlerts] = React.useState([]);

    const onClose = (alertId) => {
        const hiddenAlertsUpdated = [...hiddenAlerts, alertId];
        setHiddenAlerts(hiddenAlertsUpdated);
    };

    const shouldShowAlert = (alertId) => (
       
        hiddenAlerts.indexOf(alertId) === -1
        // console.log('hiddenAlerts', hiddenAlerts);
    );
    //#endregion

    return (
        <Fragment>
            <Alert
                variant={type == 'success' ? 'success' : 'danger'}
                // show={()=> shouldShowAlert(id)}
                // onClose={() => onClose(id)}
            >
                <div className="d-flex justify-content-between">
                    <div>
                        {type == 'success' ?
                            <TaskAltRoundedIcon fontSize='small' /> :
                            <ErrorOutlineRoundedIcon fontSize='small' />
                        }
                        {"  "} {message}
                    </div>
                    <Button className='mt-1' variant="close" size="xs" onClick={() => handleAlert(id)
                        // onClose(id)
                        } />
                </div>
            </Alert>
        </Fragment>
    )
}
export default CommonAlert;