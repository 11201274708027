export const Routes = {
    //air migrate routes
    DirectLogin:{path: '/admin/login/direct-login'},
    ChatUsingGemini :{path:"/chat-gemini"},
    NewHomePage: { path: '/home' },
    LandingPage: { path: "/" },
    ChatGPTLandingPage: { path: "/quick-chat" },
    TemplateList: { path: "/admin/settings/template" },
    Login: { path: "/login" },
    Registration: { path: "/registration" },
    VerifyOtp: { path: "/verify-otp" },
    // Customer Type 
    CustomerType: { path: "/customer-type" },
    RegistrationVerifyOtp: { path: "/registration-verify-otp" },
    AddTemplate: { path: "/admin/settings/template/add-template" },
    EditTemplate: { path: "/admin/settings/template/edit-template" },
    Banking: { path: "/banking" },
    Telecom: { path: "/telecom" },
    // Admin Routes
    AgentList: { path: "/admin/agents" },
    AgentRequestList: { path: "/admin/agent-requests" },
    
    ClientList: { path: "/admin/clients" },
    AddClient: { path: "/admin/clients/add-client" },
    EditClient: { path: "/admin/clients/edit-client" },
    EditPayment: { path: "/admin/clients/edit-payment" },
    CustomerList: { path: "/admin/customers" },
    CustomerTypeRequest: { path: "/admin/customer-requests" },

    DashboardOverview: { path: "/dashboard" },
    // RealState: { path: "/real-estate" },
    RealEstate:{path:"/real-estate"},
    Explore: { path: "/explore" },
    SearchByLocation: { path: "/real-estate/search-by-location" },
    SearchByName: { path: "/real-estate/search-by-keyword" },
    SearchByFavourites: { path: "/real-estate/search-by-favourite" },
    AgentDetails: { path: "/agent-details" },
    Review: { path: "/review" },
    AboutUs: { path: "/about-us" },
    /* terms and condition rout */
    TermsCondition:{path:"/terms-condition"},
    /* privacy policy page  */
    PrivacyPolicy:{path:"/privacy-policy"},
    CustomerQueryRequest: { path: "/customer/requests/query-request" },
    CustomerRecievedRequest: { path: "/customer/requests/recieved" },
    BrokerRequestList: { path: "/broker-request-list" },
    CaseList: { path: "/case-list" },
    ImmigrationCaseList: { path: "/cases/immigration-cases" },
    BrokerCaseList: { path: "/cases/broker-cases" },
    CaseDetails: { path: "/cases/case-details" },
    DefaultMessagePage: { path: "/status-pending-page" },
    RequestList: { path: "/request-list" },
    AllDocumentList: { path: "/all-document-list" },
    MyProfile: { path: '/myprofile' },/* normal route for self view */
    AgentProfile: {path:'/agent-profile'},/* route to agent for admin view */
    CustomerProfile: {path:'/customer-profile'},/* route to customer for admin view */
    EditMyProfile: { path: '/myprofile/edit' },
    InviteCaseVerify: { path: "/cases/invite-user-in-case/:userId/:caseId/:by_user_uuid" },
    AllDocumentListDemo: { path: "/pdfdemo" },


    // Chat Routes
    ChatVeirfy: { path: "/chat-process/:userId/:caseId" },
    MyChatComponents: { path: "/chat" },
    ESign: { path: '/e-sign' },

    // Phone Notification
    MobileNotification: { path: '/mobile-notifications' },

    // pages
    Presentation: { path: "/presentation" },
    // DashboardOverview: { path: "/dashboard/overview" },
    Transactions: { path: "/transactions" },
    Settings: { path: "/settings" },
    Upgrade: { path: "/upgrade" },
    BootstrapTables: { path: "/tables/bootstrap-tables" },
    Billing: { path: "/examples/billing" },
    Invoice: { path: "/examples/invoice" },
    Signin: { path: "/examples/sign-in" },
    Signup: { path: "/examples/sign-up" },
    ForgotPassword: { path: "/examples/forgot-password" },
    ResetPassword: { path: "/examples/reset-password" },
    Lock: { path: "/examples/lock" },
    NotFound: { path: "/examples/404" },
    ServerError: { path: "/examples/500" },

    // docs
    DocsOverview: { path: "/documentation/overview" },
    DocsDownload: { path: "/documentation/download" },
    DocsQuickStart: { path: "/documentation/quick-start" },
    DocsLicense: { path: "/documentation/license" },
    DocsFolderStructure: { path: "/documentation/folder-structure" },
    DocsBuild: { path: "/documentation/build-tools" },
    DocsChangelog: { path: "/documentation/changelog" },

    // components
    Accordions: { path: "/components/accordions" },
    UserNotes: { path: "/components/userNotes" },
    Alerts: { path: "/components/alerts" },
    Badges: { path: "/components/badges" },
    Widgets: { path: "/widgets" },
    Breadcrumbs: { path: "/components/breadcrumbs" },
    Buttons: { path: "/components/buttons" },
    Forms: { path: "/components/forms" },
    Modals: { path: "/components/modals" },
    Navs: { path: "/components/navs" },
    Navbars: { path: "/components/navbars" },
    Pagination: { path: "/components/pagination" },
    Popovers: { path: "/components/popovers" },
    Progress: { path: "/components/progress" },
    Tables: { path: "/components/tables" },
    Tabs: { path: "/components/tabs" },
    Tooltips: { path: "/components/tooltips" },
    Toasts: { path: "/components/toasts" },
    WidgetsComponent: { path: "/components/widgets" }
};