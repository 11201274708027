import React, { useState, useRef, useEffect } from 'react';
import { Modal, Button, ProgressBar } from 'react-bootstrap';
import uploadIcon from '../../../assets/img/allDocumentList/uploadIcon.png'
import styles from '../Index.module.css';
import { uploadMyprofileImagesApi } from '../../../actions/FileUploadActions';
import { useDispatch } from 'react-redux';
import { setMyProfile } from '../../../reducers/ProfileImageReducer';
import { PROFILE_UPLOAD_LIMIT } from '../../../config.tsx';
import CommonAlert from '../../../common-components/CommonAlert.jsx';
import 'react-image-crop/dist/ReactCrop.css';
import ImageCrop from '../../../components/air-migrate/ImageCrop.jsx';

const MyProfileUploadModal = (props) => {
    const { show, handleClose, GetMyProfileData, uploadFor, getFile } = props;
    const [file, setFile] = useState(null);
    const [fineName, setFileName] = useState(null);
    const [showPreview, setShowPreview] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [errorMessage, setErrorMessage] = useState('');
    const dropRef = useRef(null);
    // Create a reference to the hidden file input element
    const hiddenFileInput = useRef(null);
    const dispatch = useDispatch();
    //Alert states and functions
    const [alertsArray, setAlertsArray] = useState([]);
    const [count, setCount] = useState(0);
    const [isUploading, setIsuploading] = useState(false);
    const handleAlert = (id) => {
        let array = [];
        for (let i = 0; i < alertsArray.length; i++) {
            if ((alertsArray[i].id) == id) {
                array = alertsArray.pop();
            }
        }
        setCount(Math.floor(Math.random() * 1000))
    }
    // useEffect to update the profile image when the file changes
    useEffect(() => { }, [count, file]);
    // function to call when file is  selected in the UI
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            handleFile(file);    /*  Calling the handleFile function */
        }
    };

    // when the Button component is clicked to open file input
    const handleClick = event => {
        setFile(null)
        setErrorMessage('')
        setFileName(null);
        setShowPreview(false);
        hiddenFileInput.current.click();
    };

    // function to handle selected file  and display it on screen
    const handleFile = (file) => {
        // Validate file type and size here
        if (!['image/jpeg', 'image/png', 'image/jpg'].includes(file.type)) {
            setErrorMessage('Invalid file type. Please upload a JPEG,JPG or PNG.');
            return;
        }
        if (file.size > PROFILE_UPLOAD_LIMIT) { // 5MB limit
            setErrorMessage('File size exceeds the limit(5MB). Please upload a smaller file(less then 5MB).');
            return;
        }
        setErrorMessage('');
        setFile(file);
        setFileName(file.name);
    };


    // Function to handel drag and drop of the file  onto the Dropzone area
    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        if (files.length > 0) {
            handleFile(files[0]);
        }
    };

    // Function to handel file upload  progress
    const handleUpload = async () => {
        setIsuploading(true);
        if (!file) return;
        // data to send with file    
        let formData = new FormData();
        formData.append('profile', file);
        formData.append('about_image', uploadFor);

        // Initialize the upload progress
        setUploadProgress(0);

        // Progress bar update settings
        const targetTime = 60; // total time in seconds (1 minutes)
        const intervalTime = 200; // interval in milliseconds (2 second)
        const totalIntervals = targetTime * (1000 / intervalTime);
        const progressIncrement = 100 / totalIntervals;

        // Interval to update the progress bar
        const interval = setInterval(() => {
            setUploadProgress((oldProgress) => {
                if (oldProgress >= 100) {
                    clearInterval(interval);
                    return 100;
                }
                return Math.min(oldProgress + progressIncrement, 10);
            });
        }, intervalTime);
        const response = await uploadMyprofileImagesApi(formData);
        if (response.status_code == 1) {
            setIsuploading(false);
            clearInterval(interval);
            getFile(response.data[0], uploadFor, response);
            if (!uploadFor.includes('about_image')) {
                dispatch(setMyProfile(response.data[0].url));
                localStorage.setItem("profilePath", JSON.stringify(response.data[0].url));
            }
            handleCancel();
            GetMyProfileData();
        } else {
             setIsuploading(false);
             setAlertsArray([...alertsArray, { id: `${'image_upload'}${alertsArray.length}`, type: 'danger', message: response?.message }]);
        }
    };
    // function to cancel the process
    const handleCancel = () => {
        setFile(null);
        setFileName(null);
        setUploadProgress(0);
        setErrorMessage('');
        setShowPreview(false);
        handleClose();
    };
    // function to get the file from crop component
    const getFileToUpload = (file, previewShow) => {
        setFile(file);
        setShowPreview(previewShow);
    }
    //#region start render
    return (
        <Modal show={show} onHide={handleClose} className={styles.uploadFileModalBody} centered>
            <Modal.Header closeButton className='m-0 p-2'>
                <Modal.Title className='file-upload-title c-lg'>Image Upload</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {(alertsArray.length > 0) &&
                    alertsArray.reverse().map((item, index) => {
                        return (
                            <CommonAlert key={index} id={item.id} type={item.type} message={item.message} handleAlert={handleAlert} />
                        )
                    })
                }
                {errorMessage &&
                    <div className={`mt-3 ${styles.filePreviewBlock}`}>
                        <p className="text-danger">{errorMessage}</p>
                    </div>
                }
                {file ? (
                    <>
                        <ImageCrop imgSrc={file} sendFile={getFileToUpload} isProfileUpload={true} />
                        {showPreview &&
                            <>
                                <div className={` ${styles.filePreviewBlock}`}>
                                    <div className='text-center'>
                                        {file.type.startsWith('image/') ? (
                                            <img src={URL.createObjectURL(file)} alt={file.name} className={styles.filePreviewImg} />
                                        ) : (

                                            <p>Preview not available for this file type.</p>
                                        )}
                                        <div className={styles.uploadedFileInfo}>
                                            <div>
                                                <p> File Name: {fineName}</p>
                                                <p> File Size:{(file.size / 1024 / 1024).toFixed(2)} MB</p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <ProgressBar now={uploadProgress} label={`${uploadProgress}%`} animated variant='success' visuallyHidden className='mb-0' />
                                <div className={styles.fileUploadButtonBlock}>
                                    <Button disabled={isUploading} variant='danger' onClick={handleCancel}>Cancel</Button>
                                    <Button disabled={isUploading} variant='success' onClick={handleUpload}>Upload</Button>
                                </div>
                            </>
                        }
                    </>
                ) :
                    (
                        <div ref={dropRef} onDragOver={handleDragOver} onDrop={handleDrop} className={styles.fileDragDropArea}>
                            <img src={uploadIcon} alt="" className='file-upload-img-icon' />
                        </div>
                    )}
                <div className={styles.fileUploadButton} hidden={isUploading}>
                    <p onClick={handleClick} style={{ color: "#47C7F4", cursor: 'pointer' }}>Drag and drop a file here, or click here to select a file.</p>
                    <input type="file" onChange={handleFileChange} style={{ display: 'none' }} ref={hiddenFileInput} accept="image/png, image/jpeg, image/jpg" />

                </div>
            </Modal.Body>
        </Modal>
    );
};
//#endregion end render
export default MyProfileUploadModal;
