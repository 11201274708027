import React, { Fragment, useEffect, useState } from "react";
import {
    Col,
    Row,
    Container,
} from "@themesberg/react-bootstrap";
import Logo from "../../assets/img/landing-page/logo-img.png";
import { useHistory } from "react-router-dom";
import { checkMyCurrentStatusAPI } from "../../actions/CustomerAction";
import { setIsVisaUploaded, setIsVisaApproved, setVisaOption, setUserStatus } from "../../reducers/VisaUploadReducer";
import { useDispatch } from "react-redux";
import { DataLoading } from "./Loader";

const DefaultMessagePage = () => {
    const roleId = localStorage.getItem('roleId')
    const userId = localStorage.getItem('amUserId')
    const history = useHistory();
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(false);

    //#region useEffect
    useEffect(() => {
        // Function to go back to chat page
        const goBackFuntion = () => {
            history.push('/chat', { userId })
        }
        // Function to Check User's Current Status
        const checkMyStatus = async () => {
            setIsLoading(true)
            const response = await checkMyCurrentStatusAPI()
            if (response?.status_code == 1) {
                const data = response.data
                // Customer Case
                if (roleId == 3) {
                    const isVisaUploaded = data?.visa_document ? true : false;
                    localStorage.setItem("isVisaUploaded", isVisaUploaded);
                    dispatch(setIsVisaUploaded(isVisaUploaded))
                    const visaOption = data?.visa_option
                    localStorage.setItem("visaOption", visaOption);
                    dispatch(setVisaOption(visaOption))
                    const isVisaApproved = data?.visa_approve == 1 ? true : false;
                    localStorage.setItem("isVisaApproved", isVisaApproved);
                    dispatch(setIsVisaApproved(isVisaApproved))
                    console.log('isVisaApproved', isVisaApproved)
                    if (isVisaApproved) {
                        goBackFuntion()
                    }
                }// Agents Csae 
                else {
                    const myStatus = data.status
                    localStorage.setItem("status", myStatus);
                    dispatch(setUserStatus(myStatus))
                    console.log('My Status', myStatus)
                    // Status is active
                    if (myStatus == 1) {
                        goBackFuntion()
                    }
                }
            }
            setIsLoading(false)
        }
        // Call the function to check user's status
        if (roleId) {
            checkMyStatus();
        }
    }, [roleId, dispatch, history, userId]);
    //#endregion useEffect

    //#region  start render
    return (
        <Fragment>
            {
                isLoading ? <DataLoading /> :
                    <Container className="login-main-container" fluid>
                        <Row className='login-main-row'>
                            <Col xs={12} sm={12} md={6} lg={7} className=" d-none d-md-flex login-side-text-block">
                                <div className="login-side-text">
                                    <h1 className="login-side-head-text">Airmigrate</h1>
                                    <p> Redefining how the world immigrates. </p>
                                    <p>
                                        <span className="fw-bolder">Log In securely - </span>We ensure the utmost privacy and protection of your information as you navigate your new path.
                                    </p>
                                </div>
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={5} className="login-form-block">
                                <div className="main-container status-pending-block py-4 ">
                                    <h2 className="welcomeText mb-4">Welcome to</h2>
                                    <><img src={Logo} alt="" onClick={() => history.push('/')} className="login-logo-img" height={'100%'} width={'85%'} /></>
                                    <div className="inner-container mt-3">
                                        <div className="text-container status-pending-message">
                                            <h2 className="heading status-pending-color">
                                                Thanks for {roleId == 3 ? 'uploading the document' : 'signing up!'}
                                            </h2>
                                            <p className="inner-text status-pending-color">
                                                Our teams are currently reviewing your {roleId == 3 ? 'documents' : 'account'} and will get in touch with your shortly. You are almost there!!
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
            }
        </Fragment>
    )
};
//#endregion end render
export default DefaultMessagePage;