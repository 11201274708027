import { Fragment } from "react"
import CommonServices from "../../common-components/CommonServices"


const ProfileIconsModal = ({userDetails, isNotification}) => {
    
    return (
        <Fragment>
            {userDetails?.profile_path ?
            <div className="new-image-dummy-text">
                <div className="new-image-block-content" 
                style={{ backgroundImage: `url(${userDetails?.profile_path})`}}>
                </div>
            </div>
            :
            <div className="new-image-dummy-text">
                <div className={`new-dummy-text-content ${(isNotification == true) && 'text-center'}`}>
                    <span className="new-image-dummy-text-span">{CommonServices.getNameCharByFullName(CommonServices.getUserFullName(userDetails))}</span>
                </div>
            </div> 
            }
        </Fragment>
    )
}

export default ProfileIconsModal