/*
It's a common api helper file which contains all the four api methods Get, Put, Post, Delete.
Which will be used in actions folder to use api.
*/

//import base url from config file.
import { BASE_URL } from "../../config.tsx";

// Created a header helper component
export const HeaderHelper = () => {
  var accessToken = "";
  const newToken = localStorage.getItem("accessToken");
  if (newToken) {
    accessToken = newToken;
  }
  const header = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + accessToken,
  };
  return header;
};

 // header configuration for file upload api 

  export const FileHeaderHelper = () => {
    var accessToken = "";
    const newToken = localStorage.getItem("accessToken");
    if (newToken) {
      accessToken = newToken;
    }
    const header = {
      // "Content-Type": "multipart/form-data" ,
      Authorization: "Bearer " + accessToken,
    };
    return header;
  };

//Created an api class contains getAPI, postAPI, PutAPI and DeleteAPI.
class AuthApi {

  //get api call
  async getAPI(url) {
    return fetch(BASE_URL + url,
      {
        method: "GET",
        headers: HeaderHelper(),
      })
      .then(res => res.json())
      .then(async data => {
        return data;
      })
      .catch(err => {
        console.log(err);
      });
  }

  //post api call
  async postAPI(url, data) {
    return fetch(BASE_URL + url, {
      method: 'POST',
      headers: HeaderHelper(),
      body: JSON.stringify(data)
    })
      .then(APIResponse => APIResponse.json())
      .then(async response => {
        return response;
      })
      .catch(err => {
        console.log(err);
      });
  }

  //update (put) api call
  async updateAPI(url, data) {
    return fetch(BASE_URL + url, {
      method: "PUT",
      body: JSON.stringify(data),
      headers: HeaderHelper(),
    })
      .then(APIResponse => APIResponse.json())
      .then(async response => {
        return response;
      })
      .catch(err => {
        console.log(err);
      });
  }

  //delete api call
  async deleteAPI(url, data) {
    return fetch(BASE_URL + url, {
      method: "DELETE",
      body: JSON.stringify(data),
      headers: HeaderHelper(),
    })
      .then(APIResponse => APIResponse.json())
      .then(response => response);
  }


  // post api for upload document and  image to server
  async postFileAPI(url,formData) {
    return fetch(BASE_URL + url, {
      method: 'POST',
      headers: FileHeaderHelper(), 
      body: formData, 
    })
      .then(APIResponse => APIResponse.json())
      .then(async response => {
        return response;
      })
      .catch(err => {
        console.log(err);
        return {'status_code' : 2, 'message': err , 'data':[]}
      });
  }


    /* New Get Api For Call ChatGPT Using  */
    async newGetAPI(url) {
      return fetch(BASE_URL + url,
        {
          method: "GET",
          headers: HeaderHelper(),
        })
        .then(res => res.json())
        .then(async data => {
          return data;
        })
        .catch(err => {
          console.log(" newGetAPI : ", err);
          return {data:[],message:JSON.stringify(err),status_code:2}
        });
    }

    /* New POST Api For Call ChatGPT Using  */
    async newPostAPI(url, data) {
      return fetch(BASE_URL + url, {
        method: 'POST',
        headers: HeaderHelper(),
        body: JSON.stringify(data)
      })
        .then(APIResponse => APIResponse.json())
        .then(async response => {
          return response;
        })
        .catch(err => {
          console.log(" newPOSTAPI : ", err);
          return {data:[],message:JSON.stringify(err),status_code:2}
        });
    }


}
export const authApi = new AuthApi();


