import React, { useEffect, useState } from "react";
import {
    Card,
    ListGroup,
    Button,
    Form,
    Row, Col
} from "@themesberg/react-bootstrap";
import PlaceIcon from "@mui/icons-material/Place";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle, faPen } from "@fortawesome/free-solid-svg-icons";
import { useFormik } from "formik";
import * as Yup from "yup";
/* local imports */
import { Capitalize, } from "../../common-components/StringConversions";
import styles from "../../views/my-profile/Index.module.css";
import Camera from "../../assets/img/client/Camera.svg";
import Verify from "../../assets/img/client/verifyIcon.svg";
import OtpVerifyModal from "./VerifyOtpModal";
import { getOtpToVerifyPhoneEmailApi } from "../../actions/MyProfileActions";
import CommonServices from "../../common-components/CommonServices.jsx"
import MyProfileUploadModal from "../../views/my-profile/components/MyProfileUploadModal.jsx";
import PhoneInput from 'react-phone-input-2'
import { updateMyprofileApi } from "../../actions/MyProfileActions";
import cancelIcon from "../../assets/img/icons/Airmigrate icons/512px/Cross512.png";
import saveIcon from "../../assets/img/icons/Airmigrate icons/512px/tick.png";
import LocationSearchBox from "./LocationSearchBox.jsx";
import { Default_Country } from "../../config.tsx";
import ProfileIconsModal from "./ProfileIconsModal.jsx";
import { LICENCE_NUMBER_MINIMUM_LIMIT, LICENCE_NUMBER_MAXIMUM_LIMIT } from "../../config.tsx";
import messageHelper from "../../utilities/helpers/messageHelper.js";

const MyProfile = ({ myProfileDetails, GetMyProfileData, sendDataToParent, isEditProfile }) => {
    //#region  useState conts
    //consts for getting form fields to show
    const [clickedField, setClickedField] = useState('');
    //  mobile phone usestae here 
    const [sCode, setSCode] = useState();
    const [sPhone, setSPhone] = useState();
    const [pCode, setPCode] = useState();
    const [pPhone, setPPhone] = useState();
    // set error message const here
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    //  UseState  for show/hide the Otp Verify modal 
    const [showOtpVerifyModal, setSHowOtpVerifyModal] = useState(false);
    // Get profile details from functions and set them as state variables
    const [primaryAddress, setPrimaryAddress] = useState("");
    const [communcationAddress, setCommuncationAddress] = useState("");
    const [formattedDob, setFormattedDob] = useState("");
    // state to store verify method 
    const [verifyColumn, setVerifyColumn] = useState("");
    // user role  id state
    const [userRoleId, setUserRoleId] = useState();
    const [otherDetails, setOtherDetails] = useState({});
    //  profile pic Uplaod Modal const here 
    const [aboutImage, setAboutImage] = useState('');
    // serach Service location1 states 
    const [latValue, setLatValue] = useState(null)
    const [longValue, setLongValue] = useState(null)
    //  serach Service location2 states 
    const [latValueSecond, setLatValueSecond] = useState(null)
    const [longValueSecond, setLongValueSecond] = useState(null)
    //  serach Service location3 states 
    const [latValueThird, setLatValueThird] = useState(null)
    const [longValue3, setLongValue3] = useState(null)
    // date const for DOB restriction 
    const today = new Date();
    const currentDate = today.toISOString().substring(0, 10);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    // const for service location
    const [serviceLocation, setServiceLocation] = useState();
    // const for service location 2
    const [serviceLocation2, setServiceLocation2] = useState();
    // const for service location 3
    const [serviceLocation3, setServiceLocation3] = useState();
    // const for firm name
    const [firm, setFirm] = useState();
    // state for reset form fields 
    const [resetForm, setResetForm] = useState(false);
    // const for phone error
    const [phoneError, setPhoneError] = useState();
    //#endregion useState conts
    const resetFormHandler = () => {
        setClickedField('');
        genderFormik.resetForm();
        dobFormik.resetForm();
        maritalFormik.resetForm();
        licenceFormik.resetForm();
        serviceTypeFormik.resetForm();
        licenceAuthorityFormik.resetForm();
        nameFormFormik.resetForm();
        emailFormFormik.resetForm();
        serviceLocationFormik.resetForm();
        serviceLocation2Formik.resetForm();
        serviceLocation3Formik.resetForm();
        setTimeout(() => {
            setResetForm(!resetForm);
        }, 1000);
    }
    const handleShow = () => {
        setShow(true);
    }
    // comman cancel button
    const CancelButton = () => (
        <img src={cancelIcon} alt="Cancel" className={styles.canelIconBtn} onClick={resetFormHandler} title="Cancel" />

    );
    // primary address formik  validation and data submit block start
    const [primaryAddressInitialValues, setprimaryAddressInitialValues] = useState(
        {
            p_address: '',
            p_city: '',
            p_province: '',
            p_postal_code: '',
            p_country: '',

        }
    )
    //validation schemas for primaryAddressFormik
    const FormValidationSchemaforPrimaryAddress = Yup.object().shape({});
    // primary addres formik definition
    const primaryAddressFormik = useFormik({
        initialValues: primaryAddressInitialValues,
        enableReinitialize: true,
        validationSchema: FormValidationSchemaforPrimaryAddress,
        onSubmit: (values) => {
            const primary_addresses = {
                address: values.p_address,
                city: values.p_city,
                country: values.p_country,
                postal_code: values.p_postal_code,
                province: values.p_province
            }
            ApiCallForProfileUpdate({ primary_addresses })
        },
    })
    // primary adress formik  validation and data submit block end

    // communication adress formik  validation and data submit blok start
    const [communicationAddressInitialValues, setcommunicationAddressInitialValues] = useState(
        {
            c_address: '',
            c_city: '',
            c_province: '',
            c_postal_code: 0,
            c_country: '',
        }
    )
    //validation schemas for  Formik 
    const FormValidationSchemaforCommunicationAddress = Yup.object().shape({});
    const communicationAddressFormik = useFormik({
        initialValues: communicationAddressInitialValues,
        enableReinitialize: true,
        validationSchema: FormValidationSchemaforCommunicationAddress,
        onSubmit: (values) => {
            const communication_addresses = {
                address: values.c_address,
                city: values.c_city,
                country: values.c_country,
                postal_code: values.c_postal_code,
                province: values.c_province
            }
            ApiCallForProfileUpdate({ communication_addresses })
        },
    })
    // communication address formik  validation and data submit blok end

    //  gender formik validation and data block start
    const [genderInitialValues, setGenderInitialValues] = useState(
        {
            gender: ''
        }
    )
    //validation schemas for  Formik 
    const FormValidationSchemaforGender = Yup.object().shape({
        gender: Yup.string().required(' Gender is required'),
    });
    const genderFormik = useFormik({
        initialValues: genderInitialValues,
        enableReinitialize: true,
        validationSchema: FormValidationSchemaforGender,
        onSubmit: (values) => {
            ApiCallForProfileUpdate({ gender: Number(values.gender) })
        },
    })
    // gender formik validation and data block end

    // dob formik validation and data block start
    const [dobInitialValues, setDobInitialValues] = useState(
        {
            date_of_birth: ''
        }
    )
    //validation schemas for  Formik 
    const FormValidationSchemaforDob = Yup.object().shape({
        date_of_birth: Yup.string().required("Date of birth is required "),
    });
    const dobFormik = useFormik({
        initialValues: dobInitialValues,
        enableReinitialize: true,
        validationSchema: FormValidationSchemaforDob,
        onSubmit: (values) => {
            ApiCallForProfileUpdate(values);
        },
    })
    //  dob formik validation and data block end

    // marital status formik validation and data block start
    const [maritalInitialValues, setMaritalInitialValues] = useState(
        {
            married: ''
        }
    )
    //validation schemas for  Formik 
    const FormValidationSchemaforMarital = Yup.object().shape({
        married: Yup.boolean().required(' Marital status is required'),
    });
    const maritalFormik = useFormik({
        initialValues: maritalInitialValues,
        enableReinitialize: true,
        validationSchema: FormValidationSchemaforMarital,
        onSubmit: (values) => {
            // converting married field values into boolean from string
            let maritalStatus = false;
            if (values.married === 'true') {
                maritalStatus = true;
            } else {
                maritalStatus = false;
            }
            ApiCallForProfileUpdate({ married: maritalStatus })
        },
    })
    // marital status  formik validation and data block end
    // types of services formik validation and data block start
    const [typesOfServiceInitialValues, setTypesOfServiceInitialValues] = useState(
        {
            types_of_service: ''
        }
    )
    //validation schemas for  Formik 
    const FormValidationSchemaforTypesOfService = Yup.object().shape({
        types_of_service: Yup.string().required(' Types of service is required'),
    });
    const serviceTypeFormik = useFormik({
        initialValues: typesOfServiceInitialValues,
        enableReinitialize: true,
        validationSchema: FormValidationSchemaforTypesOfService,
        onSubmit: (values) => {
            let otherDetails = {
                types_of_service: values.types_of_service
            }
            ApiCallForProfileUpdate({ other_details: otherDetails })
        },
    })
    //  types of services  formik validation and data block end
    
    // licence number formik validation and data block start
    const [licenceInitialValues, setLicenceInitialValues] = useState(
        {
            estate_license_number: ''
        }
    )
    //validation schemas for  Formik 
    const FormValidationSchemaforLicence = Yup.object().shape({
        estate_license_number: Yup.string().required('Number is required').min(LICENCE_NUMBER_MINIMUM_LIMIT, `Minimum ${LICENCE_NUMBER_MINIMUM_LIMIT} characters required`).max(LICENCE_NUMBER_MAXIMUM_LIMIT, `Maximum ${LICENCE_NUMBER_MAXIMUM_LIMIT} characters allowed`),
    });
    const licenceFormik = useFormik({
        initialValues: licenceInitialValues,
        enableReinitialize: true,
        validationSchema: FormValidationSchemaforLicence,
        onSubmit: (values) => {
            let otherDetails = {}
            // Other Details (Meta field) object here
            if (userRoleId === '4') {
                otherDetails.estate_license_number = values.estate_license_number
            }
            if (userRoleId === '2') {
                otherDetails.rcic_number = values.estate_license_number
            }
            ApiCallForProfileUpdate({ other_details: otherDetails })
        },
    })
    // licence number of services  formik validation and data block end

    // license_authority formik validation and data block start
    const [licenceAuthorityInitialValues, setlicenceAuthorityInitialValues] = useState(
        {
            license_authority: ''
        }
    )
    //validation schemas for  Formik 
    const FormValidationSchemaforlicenceAuthority = Yup.object().shape({
        license_authority: Yup.string().required(' License Authority is required'),
    });
    const licenceAuthorityFormik = useFormik({
        initialValues: licenceAuthorityInitialValues,
        enableReinitialize: true,
        validationSchema: FormValidationSchemaforlicenceAuthority,
        onSubmit: (values) => {
            let otherDetails = {
                license_authority: values.license_authority
            }
            ApiCallForProfileUpdate({ other_details: otherDetails })
        },
    })
    // license_authority of services  formik validation and data block end

    // client name formik  validation and data submit blok start
    const [nameFormInitialValues, setnameFormInitialValues] = useState(
        {
            first_name: '',
            last_name: '',
        }
    )
    //validation schemas for primaryAddressFormik
    const FormValidationSchemafornameForm = Yup.object().shape({
        first_name: Yup.string().min(2, 'Too Short! First Name should be more than 2 words').max(50, 'Too Long! First Name should\'t be more than 50 words').required('First name is required'),
        last_name: Yup.string().min(2, 'Too Short! Last Name should be more than 2 words').max(50, 'Too Long! Last Name should\'t be more than 50 words').required('Last name is required'),
    });
    // primary addres formik definition
    const nameFormFormik = useFormik({
        initialValues: nameFormInitialValues,
        enableReinitialize: true,
        validationSchema: FormValidationSchemafornameForm,
        onSubmit: (values) => {
            ApiCallForProfileUpdate(values)
        },
    })
    // client name formik  validation and data submit blok end

    // email formik  validation and data submit blok start
    const [emailFormInitialValues, setEmailFormInitialValues] = useState(
        {
            email: '',
        }
    )
    //validation schemas for primaryAddressFormik
    const FormValidationSchemaforemailForm = Yup.object().shape({
        email: Yup.string().email('Invalid email').required('Email is required'),
    });
    // email formik definition
    const emailFormFormik = useFormik({
        initialValues: emailFormInitialValues,
        enableReinitialize: true,
        validationSchema: FormValidationSchemaforemailForm,
        onSubmit: (values) => {
            ApiCallForProfileUpdate(values)
        },
    })
    //    client email formik  validation and data submit blok end

    //    service location1 formik  validation and data submit blok start
    const [serviceLocationInitialValues, setServiceLocationInitialValues] = useState(
        {
            service_location: '',
        }
    )
    //validation schemas for service location1
    const FormValidationSchemaforserviceLocation = Yup.object().shape({
        service_location: Yup.string().required('Loaction is required'),
    });
    // service location formik definition
    const serviceLocationFormik = useFormik({
        initialValues: serviceLocationInitialValues,
        enableReinitialize: true,
        validationSchema: FormValidationSchemaforserviceLocation,
        onSubmit: (values) => {
            let dataToSend = {
                lat: latValue,
                long: longValue,
                other_details: { service_location: JSON.stringify({ service_location: values.service_location, lattitude: latValue, longitude: longValue }) }
            }
            ApiCallForProfileUpdate(dataToSend)
        },
    })
    // service location formik  validation and data submit blok end

    // service locationSecond formik  validation and data submit blok start
    const [serviceLocation2InitialValues, setServiceLocation2InitialValues] = useState(
        {
            service_location2: '',
        }
    )
    //validation schemas for service location2
    const FormValidationSchemaforserviceLocation2 = Yup.object().shape({
        service_location2: Yup.string().required('Loaction is required'),
    });
    // service location2 formik definition
    const serviceLocation2Formik = useFormik({
        initialValues: serviceLocation2InitialValues,
        enableReinitialize: true,
        validationSchema: FormValidationSchemaforserviceLocation2,
        onSubmit: (values) => {
            let dataToSend = {
                other_details: { service_location2: JSON.stringify({ service_location2: values.service_location2, lattitude: latValueSecond, longitude: longValueSecond }) }
            }
            ApiCallForProfileUpdate(dataToSend)
        },
    })
    // service locationSecond formik  validation and data submit blok end

    // service locationThird formik  validation and data submit blok start
    const [serviceLocation3InitialValues, setServiceLocation3InitialValues] = useState(
        {
            service_location3: '',
        }
    )
    //validation schemas for service location3
    const FormValidationSchemaforserviceLocation3 = Yup.object().shape({
        service_location3: Yup.string().required('Loaction is required'),
    });
    // service location3 formik definition
    const serviceLocation3Formik = useFormik({
        initialValues: serviceLocation3InitialValues,
        enableReinitialize: true,
        validationSchema: FormValidationSchemaforserviceLocation3,
        onSubmit: (values) => {
            let dataToSend = {
                other_details: { service_location3: JSON.stringify({ service_location3: values.service_location3, lattitude: latValueThird, longitude: longValue3 }) }
            }
            ApiCallForProfileUpdate(dataToSend)
        },
    })
    // service location3 formik  validation and data submit blok end

    // Call  function to get location data from location  search box 
    const onLocationSearchHandler = (locationData) => {
        if (locationData) {
            serviceLocationFormik.setFieldValue('service_location', locationData.location);
            setLatValue(locationData.latitude);
            setLongValue(locationData.longitude);
            serviceLocationFormik.handleSubmit();
        }
    }
    // Call  function to get location data from locationSecond search box
    const onLocationSearchHandler2 = (locationData) => {
        if (locationData) {
            serviceLocation2Formik.setFieldValue('service_location2', locationData.location);
            setLatValueSecond(locationData.latitude);
            setLongValueSecond(locationData.longitude);
            serviceLocation2Formik.handleSubmit();
        }
    }
    // Call  function to get location data from locationThird  search box
    const onLocationSearchHandler3 = (locationData) => {
        if (locationData) {
            serviceLocation3Formik.setFieldValue('service_location3', locationData.location);
            setLatValueThird(locationData.latitude);
            setLongValue3(locationData.longitude);
            serviceLocation3Formik.handleSubmit();
        }
    }

    // useEffect here
    useEffect(() => {
        setUserRoleId(myProfileDetails?.role);
        // Get formatted Address  from function and save it in state variable 'formatAddress' 
        if (myProfileDetails?.primary_addresses) {
            setPrimaryAddress(formatAddress(myProfileDetails.primary_addresses).formatedAddress);
            setPrimaryAddressToFormField(myProfileDetails.primary_addresses);
        }
        if (myProfileDetails?.communication_addresses) {
            setCommuncationAddress(formatAddress(myProfileDetails.communication_addresses).formatedAddress);
            setCommunAddressToFormField(myProfileDetails.communication_addresses);
        }
        // Get formatted DOB date from function and save it in state variable 'formattedDob'
        if (myProfileDetails?.date_of_birth) {
            let newDate = formatDateOfBirth(myProfileDetails?.date_of_birth);
            setFormattedDob(newDate);
        }
        getOtherDetailsData(myProfileDetails?.other_details);
        setDataIntoFormFields(myProfileDetails)
    }, [myProfileDetails, resetForm]);

    /* Check Unleash mobile login feature is enabled or not */
    let verifyContactFeatureFlag = CommonServices.GetUnleashFeatureFlag('Create-Verify-User-Details');

    // Function to format address into string 
    const formatAddress = (addressObject) => {
        // Defining the order in which we want to concatenate the address parts
        const addressKeys = ['address', 'city', 'country', 'postal_code', 'province'];
        // Initialize an array to hold the address parts
        let addressParts = [];
        let locationParts = [];
        // Iterate over the addressKeys array and push the corresponding values from the addressObject
        for (const key of addressKeys) {
            if (addressObject[key]) {
                addressParts.push(addressObject[key]);
            }
            locationParts.push(`${addressObject['province']}, ${addressObject['country']}`);
        }
        // Join the address parts with commas and spaces to create the final address string
        const formattedAddress = addressParts.join(', ');
        const formattedLocation = locationParts.join(', ');
        return { formatedAddress: formattedAddress, location: formattedLocation };
    }

    // Function to format date of birth
    const formatDateOfBirth = (dateString) => {
        // Parse the date string into a Date object
        const date = new Date(dateString);
        // Get the day, month, and year
        const day = date.getDate();
        const month = date.toLocaleString('default', { month: 'long' });
        const year = date.getFullYear();
        // Format the date as "DD-MonthName-YYYY"
        const formattedDate = `${day}-${month}-${year}`;
        return formattedDate;
    }

    /*Function to call on verify button press and call send otp API*/
    const handleVerifyButtonPress = async (verifyColumn) => {
        let sendingData = { "verify_column_name": verifyColumn }
        try {
            let response = await getOtpToVerifyPhoneEmailApi(sendingData);
            sendDataToParent(response, 'alert');
            if (response.status_code === 1) {
                // toast.success(response.message)
                setTimeout(() => {
                    setSHowOtpVerifyModal(true);
                    setVerifyColumn(verifyColumn);
                }, 2000);
            }
        }
        catch (err) {
            console.error(err.message ? err.message : messageHelper('error'))
        }
    }

    // getting other details from profile details 
    const getOtherDetailsData = (otherData) => {
        // adding other details  in myprofiledetails state variable
        if (otherData) {
            // make a object of all keys and values from otherDetails array
            var metaDetails = otherData.reduce((accumulator, current) => {
                accumulator[current.key] = current.value;
                return accumulator;
            }, {});
            // setting otherDetails object
            setOtherDetails(metaDetails);
            //Setting the meta form fields  
            if (userRoleId === '2') {
                if (metaDetails.rcic_number) {
                    licenceFormik.setFieldValue("estate_license_number", metaDetails.rcic_number);
                }
            }
            if (userRoleId === '4') {
                if (metaDetails.estate_license_number) {
                    licenceFormik.setFieldValue("estate_license_number", metaDetails.estate_license_number);
                }
            }
            /* Getting other 2 service location from meta details here*/
            if (CommonServices.isJSON(metaDetails?.service_location)) {
                let location = JSON.parse(metaDetails.service_location);
                serviceLocationFormik.setFieldValue("service_location", location.service_location ? location.service_location : '');
                setServiceLocation(location.service_location);
            } else {
                serviceLocationFormik.setFieldValue("service_location", metaDetails.service_location ? metaDetails.service_location : '');
                setServiceLocation(metaDetails.service_location);
            }
            if (metaDetails?.service_location2) {
                let location2 = JSON.parse(metaDetails.service_location2);
                serviceLocation2Formik.setFieldValue("service_location2", location2.service_location2 ? location2.service_location2 : '');
                setServiceLocation2(location2.service_location2);
            }
            if (metaDetails?.service_location3) {
                let location3 = JSON.parse(metaDetails.service_location3);
                serviceLocation3Formik.setFieldValue("service_location3", location3.service_location3 ? location3.service_location3 : '');
                setServiceLocation3(location3.service_location3);
            }
            serviceTypeFormik.setFieldValue("types_of_service", metaDetails?.types_of_service ? metaDetails?.types_of_service : '');
            licenceAuthorityFormik.setFieldValue("license_authority", metaDetails?.license_authority ? metaDetails?.license_authority : '');
            setFirm(metaDetails.firm_name ? metaDetails.firm_name : '');
        }
    }

    // function to handle primary phone input
    const HandlePInputChange = (value, country) => {
        if (country.dialCode) {
            setPCode(country.dialCode);
        }
        var phone = value.slice(country.dialCode.length);
        if (phone) {
            setPPhone(phone);
            if (phone.length < 8) {
                setPhoneError("Minimum 8 digits required");
            } else {
                setPhoneError('')
            }
        }
    }

    // function to handle secondary phone input
    const HandleSInputChange = (value, country) => {
        if (country.dialCode) {
            setSCode(country.dialCode);
        }
        var phone = value.slice(country.dialCode.length);
        if (phone) {
            setSPhone(phone);
            if (phone.length < 8) {
                setPhoneError("Minimum 8 digits required");
            } else {
                setPhoneError('')
            }
        }
    }

    // Update form fields on button click 
    const UpdateFormField = async (fieldName) => {
        var submitData = {}
        if (phoneError) {
            return;
        }
        switch (fieldName) {
            case 'primaryPhone':
                if (pPhone && toString(pPhone).length > 8) {
                    setIsError(false);
                    setErrorMessage("")
                    submitData = {
                        primary_phone: pPhone,
                        primary_country_code: pCode,
                    }
                    setTimeout(() => {
                        setClickedField('');
                    }, 1000);
                } else {
                    setIsError(true);
                    setErrorMessage("Please enter a valid phone number");
                    return;
                }
                break;
            case 'secondaryPhone':
                if (sPhone && toString(sPhone).length > 8) {
                    setIsError(false);
                    setErrorMessage("")
                    submitData = {
                        secondry_phone: sPhone,
                        secondry_country_code: sCode,
                    }
                    setTimeout(() => {
                        setClickedField('');
                    }, 1000);
                } else {
                    setIsError(true);
                    setErrorMessage("Please enter a valid phone number");
                    return;
                }
                break;
            default:
                break;
        }
        if (!phoneError || !isError) {
            ApiCallForProfileUpdate(submitData)
        }
    }

    // Function to update profile fields(API call)
    const ApiCallForProfileUpdate = async (submitData) => {
        let response = await updateMyprofileApi(submitData);
        sendDataToParent(response, 'alert');
        GetMyProfileData();
        setTimeout(() => {
            setClickedField('');
        }, 1000);
    }

    // set profile photos directly after uploaded 
    const getUploadedFile = (filePath, uploadFor, response) => {
        if (response) {
            sendDataToParent(response, 'alert');
        }
        myProfileDetails.profile_path = filePath;
        Object.assign(myProfileDetails, { 'profile_path': filePath });
    }

    //function to set primary address form fields
    const setPrimaryAddressToFormField = (primaryAddress) => {
        primaryAddressFormik.setFieldValue("p_address", primaryAddress.address ? primaryAddress.address : '');
        primaryAddressFormik.setFieldValue("p_city", primaryAddress.city ? primaryAddress.city : '');
        primaryAddressFormik.setFieldValue("p_province", primaryAddress.province ? primaryAddress.province : '');
        primaryAddressFormik.setFieldValue("p_postal_code", primaryAddress.postal_code ? primaryAddress.postal_code : '');
        primaryAddressFormik.setFieldValue("p_country", primaryAddress.country ? primaryAddress.country : '');
    }
    //function to set communication address form fields
    const setCommunAddressToFormField = (communiAddress) => {
        communicationAddressFormik.setFieldValue("c_address", communiAddress.address ? communiAddress.address : '');
        communicationAddressFormik.setFieldValue("c_city", communiAddress.city ? communiAddress.city : '');
        communicationAddressFormik.setFieldValue("c_province", communiAddress.province ? communiAddress.province : '');
        communicationAddressFormik.setFieldValue("c_postal_code", communiAddress.postal_code ? communiAddress.postal_code : '');
        communicationAddressFormik.setFieldValue("c_country", communiAddress.country ? communiAddress.country : '');
    }
    // function to set for fields 
    const setDataIntoFormFields = (data) => {
        genderFormik.setFieldValue("gender", data?.gender ? data?.gender : '');
        dobFormik.setFieldValue("date_of_birth", data?.date_of_birth ? data?.date_of_birth : '');
        maritalFormik.setFieldValue("married", data?.married);
        nameFormFormik.setFieldValue("first_name", data?.first_name ? data?.first_name : '');
        nameFormFormik.setFieldValue("last_name", data?.last_name ? data?.last_name : '');
        emailFormFormik.setFieldValue("email", data?.email ? data?.email : '');
        setPPhone(data?.primary_phone ? data?.primary_phone : '');
        setPCode(data?.primary_country_code ? data?.primary_country_code : '');
        setSPhone(data?.secondry_phone ? data?.secondry_phone : '');
        setSCode(data?.secondry_country_code ? data?.secondry_country_code : '');
    };

    //#region render start
    return (
        <>
            <div>
                {/* Otp verify Modal */}
                {showOtpVerifyModal &&
                    <OtpVerifyModal showOtpVerifyModal={showOtpVerifyModal} setSHowOtpVerifyModal={setSHowOtpVerifyModal} verifyColumn={verifyColumn} handleVerifyButtonPress={handleVerifyButtonPress} GetMyProfileData={GetMyProfileData} />
                }
                {/* Image Upload Modal */}
                {show &&
                    <MyProfileUploadModal show={show} handleClose={handleClose} uploadFor={aboutImage} GetMyProfileData={GetMyProfileData} getFile={getUploadedFile} />
                }
            </div>
            <div className={styles.mainProfileDetailContainer}>
                <Card className="client-profile-block1">
                    <div className={`client-profile-custom-block`} >
                        <div className="block1-img-container">
                            <div onClick={() => { isEditProfile ? handleShow() : (void (0)); setAboutImage('') }}>
                                <ProfileIconsModal userDetails={myProfileDetails} timeStamp={true} />
                            </div>
                            {isEditProfile &&
                                <img src={Camera} alt="camera" className={styles.cameraIcon} style={{ cursor: 'pointer' }} onClick={() => { handleShow(); setAboutImage('') }} />
                            }
                        </div>
                        <div className={`client-profile-text-block ${styles.profileHeadTextBlock}`}>
                            {clickedField === 'name' ? (
                                <Form onSubmit={nameFormFormik.handleSubmit}>
                                    <Row className={styles.profileNameRow}>
                                        <Col xs={12} sm={12} md={12} lg={12} xl={(userRoleId === '3') ? 4 : 6} className="">
                                            <Form.Group >
                                                <Form.Label>First Name</Form.Label>
                                                <Form.Control
                                                    id="first_name"
                                                    name="first_name"
                                                    value={Capitalize(nameFormFormik.values.first_name)}
                                                    type="text"
                                                    placeholder="Enter first name"
                                                    onChange={nameFormFormik.handleChange}
                                                    onBlur={nameFormFormik.handleBlur}
                                                    className={`${nameFormFormik.touched.first_name && nameFormFormik.errors.first_name ? 'is-invalid' : ''}`}
                                                />
                                                {nameFormFormik.touched.first_name && nameFormFormik.errors.first_name ? (
                                                    <small className="text-danger">{nameFormFormik.errors.first_name}</small>
                                                ) : null}
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} sm={12} md={12} lg={12} xl={(userRoleId === '3') ? 4 : 6} className="mb-3">
                                            <Form.Group >
                                                <Form.Label>Last Name</Form.Label>
                                                <Form.Control
                                                    id="last_name"
                                                    name="last_name"
                                                    value={Capitalize(nameFormFormik.values.last_name)}
                                                    type="text"
                                                    placeholder="Enter last name"
                                                    onChange={nameFormFormik.handleChange}
                                                    onBlur={nameFormFormik.handleBlur}
                                                    className={`${nameFormFormik.touched.last_name && nameFormFormik.errors.last_name ? 'is-invalid' : ''}`}
                                                />
                                                {nameFormFormik.touched.last_name && nameFormFormik.errors.last_name ? (
                                                    <small className="text-danger">{nameFormFormik.errors.last_name}</small>
                                                ) : null}
                                            </Form.Group>
                                        </Col>
                                        <div className={`mb-2 mt-0 pt-0 ${styles.formBtnContainer}`}>
                                            <Button variant="" type="submit" > <img src={saveIcon} className={styles.canelIconBtn} alt="Save" title="Save" /></Button>
                                            <CancelButton />
                                        </div>
                                    </Row>
                                </Form>
                            ) : (
                                <div className={styles.myprofileEditableBlock}>
                                    <h5 className="c-lg f-16" >{CommonServices.getUserFullName(myProfileDetails)}</h5>
                                </div>
                            )}
                            <div className="d-flex justify-content-between">
                                <div className="d-flex">
                                    {clickedField === 'email' ? (
                                        <Form onSubmit={emailFormFormik.handleSubmit}>
                                            <Row>
                                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="my-2">
                                                    <Form.Group >
                                                        <Form.Label>Email</Form.Label>
                                                        <Form.Control
                                                            id="email"
                                                            name="email"
                                                            value={emailFormFormik.values.email}
                                                            placeholder="Enter email"
                                                            onChange={emailFormFormik.handleChange}
                                                            onBlur={(e) => {
                                                                emailFormFormik.handleBlur(e);
                                                                emailFormFormik.handleSubmit()
                                                            }}
                                                            className={`${emailFormFormik.touched.email && emailFormFormik.errors.email ? 'is-invalid' : ''}`}
                                                        />
                                                        {emailFormFormik.touched.email && emailFormFormik.errors.email ? (
                                                            <small className="text-danger">{emailFormFormik.errors.email}</small>
                                                        ) : null}
                                                    </Form.Group>
                                                </Col>
                                                <div className={`mb-2 mt-0 pt-0 ${styles.formBtnContainer}`}>
                                                </div>
                                            </Row>
                                        </Form>
                                    ) : (

                                        <p className={styles.profileEmailText}>{myProfileDetails?.email ? myProfileDetails?.email : "Not Available"}</p>
                                    )}
                                    {isEditProfile && verifyContactFeatureFlag && (!(clickedField === 'email')) &&
                                        <>
                                            {myProfileDetails?.email_verified ? (
                                                <>
                                                    <img src={Verify} alt="verified" className={styles.verifyiconImg} />
                                                </>
                                            ) : (
                                                <>
                                                    <Button variant="" className={'verify-icon'} type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Please Verify email" onClick={() => handleVerifyButtonPress('email')} hidden={!myProfileDetails?.email}>
                                                        <FontAwesomeIcon icon={faExclamationCircle} style={{ color: "orange" }}></FontAwesomeIcon>
                                                    </Button>
                                                </>
                                            )
                                            }
                                        </>
                                    }
                                </div>
                                {isEditProfile && (!(clickedField === 'email')) &&
                                    <div className=" me-sm-0 me-3">
                                        <FontAwesomeIcon icon={faPen} className={styles.editIconStyle} onClick={() => setClickedField('email')} title="Edit Email" />
                                    </div>
                                }
                            </div>
                            <p className="client-footer-text ml-0">
                                <PlaceIcon style={{ marginLeft: -6, paddingBottom: 4, fontSize: 20 }}></PlaceIcon>
                                {myProfileDetails?.location ? Capitalize(myProfileDetails?.location) : 'Not Provided'}
                            </p>
                        </div>
                    </div>
                    <Card.Body className="profile-block1-body">
                        <div className="client-profile-block1-text">
                            <div className="client-profile-block2">
                                <ListGroup className={styles.myProfileListGroup}>
                                    <ListGroup.Item>
                                        <div className="client-block1-details">
                                            <div className="w-100">
                                                <p className={styles.profileDetailHeading}>
                                                    Primary Phone
                                                </p>
                                                {clickedField === 'pPhone' ? (
                                                    <Form.Group >
                                                        <div className={styles.editMyprofilePhoneInputContainer}>
                                                            <div className="inputRow">
                                                                <PhoneInput
                                                                    country={Default_Country}
                                                                    preferredCountries={['in', 'ca', 'us']}
                                                                    autoFormat
                                                                    containerClass={`${styles.editProfilePhoneContainer} `}
                                                                    inputClass={`${styles.editProfilePhoneInput} `}
                                                                    inputStyle={{}}
                                                                    buttonClass={styles.editProfileCountryDropDown}
                                                                    dropdownClass={'country-dropdown-container'}
                                                                    enableSearch
                                                                    countryCodeEditable={false}
                                                                    style={{ paddingRight: '0px' }}
                                                                    value={myProfileDetails?.primary_phone_temp}
                                                                    onBlur={() => { UpdateFormField('primaryPhone') }}
                                                                    onChange={(value, country) => {
                                                                        HandlePInputChange(value, country)
                                                                    }}
                                                                    placeholder="Phone Number"
                                                                    autocompleteSearch={true}
                                                                    inputProps={{
                                                                        name: 'primary_phone_temp',
                                                                    }}
                                                                />
                                                                {isError || phoneError ? (
                                                                    <small className="text-danger">{errorMessage || phoneError}</small>
                                                                ) : null}
                                                            </div>
                                                            <div className={`ms-lg-3 ms-0 mt-lg-0 mt-2 ${styles.formBtnContainer}`}>
                                                            </div>
                                                        </div>
                                                    </Form.Group>
                                                ) : (
                                                    <p className={styles.profileText}> {myProfileDetails?.primary_phone_temp ? (myProfileDetails?.primary_phone_temp === "NoneNone") ? "Not Available" : `+${myProfileDetails?.primary_phone_temp}` : "Not Available"}</p>
                                                )}
                                            </div>
                                            <div className="d-flex">
                                                {isEditProfile && verifyContactFeatureFlag && (!(clickedField === 'pPhone')) &&
                                                    <div className={styles.verifiedIconBlock}>
                                                        {myProfileDetails?.phone_is_verify ? (
                                                            <img src={Verify} alt="verified" className={`me-2`} />
                                                        ) : (
                                                            <Button className={styles.verifyContactBtn} onClick={() => handleVerifyButtonPress('primary_phone')} hidden={!myProfileDetails?.primary_phone}>Verify</Button>
                                                        )
                                                        }
                                                    </div>
                                                }
                                                {isEditProfile && (!(clickedField === 'pPhone')) &&
                                                    <div className="ms-2 mb-1 align-self-end">
                                                        <FontAwesomeIcon icon={faPen} className={styles.editIconStyle} onClick={() => setClickedField('pPhone')} title="Edit Primary Phone" />
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <div className="client-block1-details">
                                            <div className="w-100">
                                                <p className={styles.profileDetailHeading}>
                                                    Secondary Phone
                                                </p>
                                                {clickedField === 'sPhone' ? (
                                                    <Form.Group >
                                                        <div className={styles.editMyprofilePhoneInputContainer}>
                                                            <div className="inputRow">
                                                                <PhoneInput
                                                                    country={Default_Country}
                                                                    preferredCountries={['in', 'ca', 'us']}
                                                                    autoFormat
                                                                    containerClass={`${styles.editProfilePhoneContainer} `}
                                                                    inputClass={`${styles.editProfilePhoneInput} `}
                                                                    inputStyle={{}}
                                                                    buttonClass={styles.editProfileCountryDropDown}
                                                                    dropdownClass={'country-dropdown-container'}
                                                                    enableSearch
                                                                    countryCodeEditable={false}
                                                                    style={{ paddingRight: '0px' }}
                                                                    value={myProfileDetails?.secondry_phone_temp}
                                                                    onBlur={() => { UpdateFormField('secondaryPhone') }}
                                                                    onChange={(value, country) => {
                                                                        HandleSInputChange(value, country)
                                                                    }}
                                                                    placeholder="Phone Number"
                                                                    autocompleteSearch={true}
                                                                    inputProps={{
                                                                        name: 'secondry_phone_temp',

                                                                    }}
                                                                />
                                                                {isError || phoneError ? (
                                                                    <small className="text-danger">{errorMessage || phoneError}</small>
                                                                ) : null}
                                                            </div>
                                                            <div className={`ms-lg-3 ms-0 mt-lg-0 mt-2 ${styles.formBtnContainer}`}>
                                                            </div>
                                                        </div>
                                                    </Form.Group>
                                                ) : (
                                                    <p className={styles.profileText}> {myProfileDetails?.secondry_phone_temp ? myProfileDetails?.secondry_phone_temp === "NoneNone" ? "Not Available" : `+${myProfileDetails?.secondry_phone_temp}` : "Not Available"}</p>
                                                )}
                                            </div>
                                            <div className="d-flex">
                                                {isEditProfile && verifyContactFeatureFlag && (!(clickedField === 'sPhone')) &&
                                                    <div className="my-auto ms-3">
                                                        {myProfileDetails?.secondary_is_verify ? (
                                                            <img src={Verify} alt="verified" className="me-2" />
                                                        ) : (
                                                            <Button className={styles.verifyContactBtn} onClick={() => handleVerifyButtonPress('secondry_phone')} hidden={!myProfileDetails?.secondry_phone}>Verify</Button>
                                                        )
                                                        }
                                                    </div>
                                                }
                                                {isEditProfile && (!(clickedField === 'sPhone')) &&
                                                    <div className="ms-2 mb-1 align-self-end">
                                                        <FontAwesomeIcon icon={faPen} className={styles.editIconStyle} onClick={() => setClickedField('sPhone')} title="Edit Secondary Phone" />
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </ListGroup.Item>
                                    {/* Parmanent address and communication address*/}
                                    {(userRoleId === '3') ?
                                        (
                                            <>
                                                <ListGroup.Item>
                                                    <div className="client-block1-details">
                                                        <div className="w-100">
                                                            <div className="d-flex justify-content-center">
                                                                <div className={styles.addressHeading}>
                                                                    <p
                                                                        className={styles.profileDetailHeading}
                                                                    >
                                                                        Permanent Address
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <>
                                                                <Form onSubmit={primaryAddressFormik.handleSubmit} className={styles.addressFormContainer}>
                                                                    <Row>
                                                                        {clickedField == 'street' ? (
                                                                            <Col xs={12} sm={12} md={12} lg={12} xl={6} className="mb-3">
                                                                                <Form.Group >
                                                                                    <Form.Label> <p
                                                                                        className={styles.profileDetailHeading}
                                                                                    >
                                                                                        Street/Building/Area
                                                                                    </p></Form.Label>
                                                                                    <Form.Control
                                                                                        id="p_address"
                                                                                        name="p_address"
                                                                                        value={primaryAddressFormik.values.p_address}
                                                                                        type="text"
                                                                                        placeholder="Enter Street/Building/Area"
                                                                                        onChange={primaryAddressFormik.handleChange}
                                                                                        onBlur={(e) => { primaryAddressFormik.handleBlur(e); primaryAddressFormik.handleSubmit() }}
                                                                                        className={`${primaryAddressFormik.touched.p_address && primaryAddressFormik.errors.p_address ? 'is-invalid' : ''}`}
                                                                                    />
                                                                                    {primaryAddressFormik.touched.p_address && primaryAddressFormik.errors.p_address ? (
                                                                                        <small className="text-danger">{primaryAddressFormik.errors.p_address}</small>
                                                                                    ) : null}
                                                                                </Form.Group>
                                                                            </Col>
                                                                        ) : (
                                                                            <div className="w-100 d-flex justify-content-between mt-3">
                                                                                <p className={styles.profileText}><strong className={styles.innerAddressHeading}>Street/Building/Area :</strong> {myProfileDetails?.primary_addresses?.address ? myProfileDetails?.primary_addresses?.address : "Not Available"}</p>
                                                                                {isEditProfile &&
                                                                                    <div className=" align-self-start">
                                                                                        <FontAwesomeIcon icon={faPen} className={styles.editIconStyle} onClick={() => { setClickedField('street'); setPrimaryAddressToFormField(myProfileDetails.primary_addresses) }} title="Edit Address" />
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        )}
                                                                        {clickedField == 'city' ? (
                                                                            <Col xs={12} sm={12} md={12} lg={12} xl={6} className="mb-3">
                                                                                <Form.Group >
                                                                                    <Form.Label><p
                                                                                        className={styles.profileDetailHeading}
                                                                                    >
                                                                                        City
                                                                                    </p></Form.Label>
                                                                                    <Form.Control
                                                                                        id="p_city"
                                                                                        name="p_city"
                                                                                        value={primaryAddressFormik.values.p_city}
                                                                                        type="text"
                                                                                        placeholder="Enter City"
                                                                                        onChange={primaryAddressFormik.handleChange}
                                                                                        onBlur={(e) => { primaryAddressFormik.handleBlur(e); primaryAddressFormik.handleSubmit() }}
                                                                                        className={`${primaryAddressFormik.touched.p_city && primaryAddressFormik.errors.p_city ? 'is-invalid' : ''}`}
                                                                                    />
                                                                                    {primaryAddressFormik.touched.p_city && primaryAddressFormik.errors.p_city ? (
                                                                                        <small className="text-danger">{primaryAddressFormik.errors.p_city}</small>
                                                                                    ) : null}
                                                                                </Form.Group>
                                                                            </Col>
                                                                        ) : (
                                                                            <div className="w-100 d-flex justify-content-between mt-3">
                                                                                <p className={styles.profileText}><strong className={styles.innerAddressHeading}>City :</strong> {myProfileDetails?.primary_addresses?.city ? myProfileDetails?.primary_addresses?.city : "Not Available"}</p>

                                                                                {isEditProfile &&
                                                                                    <div className=" align-self-start">
                                                                                        <FontAwesomeIcon icon={faPen} className={styles.editIconStyle} onClick={() => { setClickedField('city'); setPrimaryAddressToFormField(myProfileDetails.primary_addresses) }} title="Edit Address" />
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        )}
                                                                        {clickedField == 'province' ? (
                                                                            <Col xs={12} sm={12} md={12} lg={12} xl={6} className="mb-3">
                                                                                <Form.Group >
                                                                                    <Form.Label><p
                                                                                        className={styles.profileDetailHeading}
                                                                                    >
                                                                                        Province
                                                                                    </p></Form.Label>
                                                                                    <Form.Control
                                                                                        id="p_province"
                                                                                        name="p_province"
                                                                                        value={primaryAddressFormik.values.p_province}
                                                                                        type="text"
                                                                                        placeholder="Enter Province"
                                                                                        onChange={primaryAddressFormik.handleChange}
                                                                                        onBlur={(e) => { primaryAddressFormik.handleBlur(e); primaryAddressFormik.handleSubmit() }}
                                                                                        className={`${primaryAddressFormik.touched.p_province && primaryAddressFormik.errors.p_province ? 'is-invalid' : ''}`}
                                                                                    />
                                                                                    {primaryAddressFormik.touched.p_province && primaryAddressFormik.errors.p_province ? (
                                                                                        <small className="text-danger">{primaryAddressFormik.errors.p_province}</small>
                                                                                    ) : null}
                                                                                </Form.Group>
                                                                            </Col>
                                                                        ) : (
                                                                            <div className="w-100 d-flex justify-content-between mt-3">
                                                                                <p className={styles.profileText}><strong className={styles.innerAddressHeading}>Province :</strong> {myProfileDetails?.primary_addresses?.province ? myProfileDetails?.primary_addresses?.province : "Not Available"}</p>
                                                                                {isEditProfile &&
                                                                                    <div className=" align-self-start">
                                                                                        <FontAwesomeIcon icon={faPen} className={styles.editIconStyle} onClick={() => { setClickedField('province'); setPrimaryAddressToFormField(myProfileDetails.primary_addresses) }} title="Edit Address" />
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        )}
                                                                        {clickedField == 'postal' ? (
                                                                            <Col xs={12} sm={12} md={12} lg={12} xl={6} className="mb-3">
                                                                                <Form.Group >
                                                                                    <Form.Label><p
                                                                                        className={styles.profileDetailHeading}
                                                                                    >
                                                                                        Postal/Zip Code
                                                                                    </p> </Form.Label>
                                                                                    <Form.Control
                                                                                        id="p_postal_code"
                                                                                        name="p_postal_code"
                                                                                        value={primaryAddressFormik.values.p_postal_code}
                                                                                        type="text"
                                                                                        placeholder="Enter Postal/Zip Code"
                                                                                        onChange={primaryAddressFormik.handleChange}
                                                                                        onBlur={(e) => { primaryAddressFormik.handleBlur(e); primaryAddressFormik.handleSubmit() }}
                                                                                        className={`${primaryAddressFormik.touched.p_postal_code && primaryAddressFormik.errors.p_postal_code ? 'is-invalid' : ''}`}
                                                                                    />
                                                                                    {primaryAddressFormik.touched.p_postal_code && primaryAddressFormik.errors.p_postal_code ? (
                                                                                        <small className="text-danger">{primaryAddressFormik.errors.p_postal_code}</small>
                                                                                    ) : null}
                                                                                </Form.Group>
                                                                            </Col>
                                                                        ) : (
                                                                            <div className="w-100 d-flex justify-content-between mt-3">
                                                                                <p className={styles.profileText}><strong className={styles.innerAddressHeading}>Postal/Zip Code :</strong> {myProfileDetails?.primary_addresses?.postal_code ? myProfileDetails?.primary_addresses?.postal_code : "Not Available"}</p>
                                                                                {isEditProfile &&
                                                                                    <div className=" align-self-start">
                                                                                        <FontAwesomeIcon icon={faPen} className={styles.editIconStyle} onClick={() => { setClickedField('postal'); setPrimaryAddressToFormField(myProfileDetails.primary_addresses) }} title="Edit Address" />
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        )}
                                                                        {clickedField == 'country' ? (
                                                                            <Col xs={12} sm={12} md={12} lg={12} xl={6} className="mb-0">
                                                                                <Form.Group >
                                                                                    <Form.Label><p
                                                                                        className={styles.profileDetailHeading}
                                                                                    >
                                                                                        Country
                                                                                    </p></Form.Label>
                                                                                    <Form.Control
                                                                                        id="p_country"
                                                                                        name="p_country"
                                                                                        value={primaryAddressFormik.values.p_country}
                                                                                        type="text"
                                                                                        placeholder="Enter Country"
                                                                                        onChange={primaryAddressFormik.handleChange}
                                                                                        onBlur={(e) => { primaryAddressFormik.handleBlur(e); primaryAddressFormik.handleSubmit() }}
                                                                                        className={`${primaryAddressFormik.touched.p_country && primaryAddressFormik.errors.p_country ? 'is-invalid' : ''}`}
                                                                                    />
                                                                                    {primaryAddressFormik.touched.p_country && primaryAddressFormik.errors.p_country ? (
                                                                                        <small className="text-danger">{primaryAddressFormik.errors.p_country}</small>
                                                                                    ) : null}
                                                                                </Form.Group>
                                                                            </Col>
                                                                        ) : (
                                                                            <div className="w-100 d-flex justify-content-between mt-3">
                                                                                <p className={styles.profileText}><strong className={styles.innerAddressHeading}>Country :</strong>{myProfileDetails?.primary_addresses?.country ? myProfileDetails?.primary_addresses?.country : "Not Available"}</p>
                                                                                {isEditProfile &&
                                                                                    <div className=" align-self-start">
                                                                                        <FontAwesomeIcon icon={faPen} className={styles.editIconStyle} onClick={() => { setClickedField('country'); setPrimaryAddressToFormField(myProfileDetails.primary_addresses) }} title="Edit Address" />
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        )}
                                                                    </Row>
                                                                    <div className={`mt-lg-0 mt-2 ${styles.formBtnContainer}`}>
                                                                    </div>
                                                                </Form>
                                                            </>
                                                        </div>
                                                    </div>
                                                </ListGroup.Item>
                                                <ListGroup.Item>
                                                    <div className="client-block1-details">
                                                        <div className="w-100">
                                                            <div className="d-flex justify-content-center">
                                                                <div className={styles.addressHeading}>
                                                                    <p
                                                                        className={styles.profileDetailHeading}
                                                                    >
                                                                        Communication Address
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <>
                                                                <Form onSubmit={communicationAddressFormik.handleSubmit} className={styles.addressFormContainer}>
                                                                    <Row>
                                                                        {clickedField == 'com_street' ? (
                                                                            <Col xs={12} sm={12} md={12} lg={12} xl={6} className="mb-3">
                                                                                <Form.Group >
                                                                                    <Form.Label><p
                                                                                        className={styles.profileDetailHeading}
                                                                                    >
                                                                                        Address
                                                                                    </p></Form.Label>
                                                                                    <Form.Control
                                                                                        id="c_address"
                                                                                        name="c_address"
                                                                                        value={communicationAddressFormik.values.c_address}
                                                                                        type="text"
                                                                                        placeholder="Enter Address"
                                                                                        onChange={communicationAddressFormik.handleChange}
                                                                                        onBlur={(e) => { communicationAddressFormik.handleBlur(e); communicationAddressFormik.handleSubmit() }}
                                                                                        className={`${communicationAddressFormik.touched.c_address && communicationAddressFormik.errors.c_address ? 'is-invalid' : ''}`}

                                                                                    />
                                                                                    {communicationAddressFormik.touched.c_address && communicationAddressFormik.errors.c_address ? (
                                                                                        <small className="text-danger">{communicationAddressFormik.errors.c_address}</small>
                                                                                    ) : null}
                                                                                </Form.Group>
                                                                            </Col>
                                                                        ) : (
                                                                            <div className="w-100 d-flex justify-content-between mt-3">
                                                                                <p className={styles.profileText}><strong className={styles.innerAddressHeading}>Street/Building/Area :</strong> {myProfileDetails?.communication_addresses?.address ? myProfileDetails?.communication_addresses?.address : "Not Available"}</p>
                                                                                {isEditProfile &&
                                                                                    <div className=" align-self-start">
                                                                                        <FontAwesomeIcon icon={faPen} className={styles.editIconStyle} onClick={() => { setClickedField('com_street'); setCommunAddressToFormField(myProfileDetails.communication_addresses) }} title="Edit Address" />
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        )}
                                                                        {clickedField == 'com_city' ? (
                                                                            <Col xs={12} sm={12} md={12} lg={12} xl={6} className="mb-3">
                                                                                <Form.Group >
                                                                                    <Form.Label><p
                                                                                        className={styles.profileDetailHeading}
                                                                                    >
                                                                                        City
                                                                                    </p></Form.Label>
                                                                                    <Form.Control
                                                                                        id="c_city"
                                                                                        name="c_city"
                                                                                        value={communicationAddressFormik.values.c_city}
                                                                                        type="text"
                                                                                        placeholder="Enter City"
                                                                                        onChange={communicationAddressFormik.handleChange}
                                                                                        onBlur={(e) => { communicationAddressFormik.handleBlur(e); communicationAddressFormik.handleSubmit() }}
                                                                                        className={`${communicationAddressFormik.touched.c_city && communicationAddressFormik.errors.c_city ? 'is-invalid' : ''}`}
                                                                                    />
                                                                                    {communicationAddressFormik.touched.c_city && communicationAddressFormik.errors.c_city ? (
                                                                                        <small className="text-danger">{communicationAddressFormik.errors.c_city}</small>
                                                                                    ) : null}
                                                                                </Form.Group>
                                                                            </Col>
                                                                        ) : (
                                                                            <div className="w-100 d-flex justify-content-between mt-3">
                                                                                <p className={styles.profileText}><strong className={styles.innerAddressHeading}>City :</strong> {myProfileDetails?.communication_addresses?.city ? myProfileDetails?.communication_addresses?.city : "Not Available"}</p>
                                                                                {isEditProfile &&
                                                                                    <div className=" align-self-start">
                                                                                        <FontAwesomeIcon icon={faPen} className={styles.editIconStyle} onClick={() => { setCommunAddressToFormField(myProfileDetails.communication_addresses); setClickedField('com_city') }} title="Edit Address" />
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        )}
                                                                        {clickedField == 'com_province' ? (
                                                                            <Col xs={12} sm={12} md={12} lg={12} xl={6} className="mb-3">
                                                                                <Form.Group >
                                                                                    <Form.Label><p
                                                                                        className={styles.profileDetailHeading}
                                                                                    >
                                                                                        Province
                                                                                    </p></Form.Label>
                                                                                    <Form.Control
                                                                                        id="c_province"
                                                                                        name="c_province"
                                                                                        value={communicationAddressFormik.values.c_province}
                                                                                        type="text"
                                                                                        placeholder="Enter Province"
                                                                                        onChange={communicationAddressFormik.handleChange}
                                                                                        onBlur={(e) => { communicationAddressFormik.handleBlur(e); communicationAddressFormik.handleSubmit() }}
                                                                                        className={`${communicationAddressFormik.touched.c_province && communicationAddressFormik.errors.c_province ? 'is-invalid' : ''}`}
                                                                                    />
                                                                                    {communicationAddressFormik.touched.c_province && communicationAddressFormik.errors.c_province ? (
                                                                                        <small className="text-danger">{communicationAddressFormik.errors.c_province}</small>
                                                                                    ) : null}
                                                                                </Form.Group>
                                                                            </Col>
                                                                        ) : (
                                                                            <div className="w-100 d-flex justify-content-between mt-3">
                                                                                <p className={styles.profileText}><strong className={styles.innerAddressHeading}>Province :</strong> {myProfileDetails?.communication_addresses?.province ? myProfileDetails?.communication_addresses?.province : "Not Available"}</p>
                                                                                {isEditProfile &&
                                                                                    <div className=" align-self-start">
                                                                                        <FontAwesomeIcon icon={faPen} className={styles.editIconStyle} onClick={() => { setCommunAddressToFormField(myProfileDetails.communication_addresses); setClickedField('com_province') }} title="Edit Address" />
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        )}
                                                                        {clickedField == 'com_postal' ? (
                                                                            <Col xs={12} sm={12} md={12} lg={12} xl={6} className="mb-3">
                                                                                <Form.Group >
                                                                                    <Form.Label><p
                                                                                        className={styles.profileDetailHeading}
                                                                                    >
                                                                                        Postal/Zip Code
                                                                                    </p> </Form.Label>
                                                                                    <Form.Control
                                                                                        id="c_postal_code"
                                                                                        name="c_postal_code"
                                                                                        value={communicationAddressFormik.values.c_postal_code}
                                                                                        type="text"
                                                                                        placeholder="Enter Postal/Zip Code"
                                                                                        onChange={communicationAddressFormik.handleChange}
                                                                                        onBlur={(e) => { communicationAddressFormik.handleBlur(e); communicationAddressFormik.handleSubmit() }}
                                                                                        className={`${communicationAddressFormik.touched.c_postal_code && communicationAddressFormik.errors.c_postal_code ? 'is-invalid' : ''}`}
                                                                                    />
                                                                                    {communicationAddressFormik.touched.c_postal_code && communicationAddressFormik.errors.c_postal_code ? (
                                                                                        <small className="text-danger">{communicationAddressFormik.errors.c_postal_code}</small>
                                                                                    ) : null}
                                                                                </Form.Group>
                                                                            </Col>
                                                                        ) : (
                                                                            <div className="w-100 d-flex justify-content-between mt-3">
                                                                                <p className={styles.profileText}><strong className={styles.innerAddressHeading}> Postal/Zip Code :</strong> {myProfileDetails?.communication_addresses?.postal_code ? myProfileDetails?.communication_addresses?.postal_code : "Not Available"}</p>
                                                                                {isEditProfile &&
                                                                                    <div className=" align-self-start">
                                                                                        <FontAwesomeIcon icon={faPen} className={styles.editIconStyle} onClick={() => { setCommunAddressToFormField(myProfileDetails.communication_addresses); setClickedField('com_postal') }} title="Edit Address" />
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        )}
                                                                        {clickedField == 'com_country' ? (
                                                                            <Col xs={12} sm={12} md={12} lg={6} xl={6} className="mb-0">
                                                                                <Form.Group >
                                                                                    <Form.Label><p
                                                                                        className={styles.profileDetailHeading}
                                                                                    >
                                                                                        Country
                                                                                    </p></Form.Label>
                                                                                    <Form.Control
                                                                                        id="c_country"
                                                                                        name="c_country"
                                                                                        value={communicationAddressFormik.values.c_country}
                                                                                        type="text"
                                                                                        placeholder="Enter Country"
                                                                                        onChange={communicationAddressFormik.handleChange}
                                                                                        onBlur={(e) => { communicationAddressFormik.handleBlur(e); communicationAddressFormik.handleSubmit() }}
                                                                                        className={`${communicationAddressFormik.touched.c_country && communicationAddressFormik.errors.c_country ? 'is-invalid' : ''}`}
                                                                                    />
                                                                                    {communicationAddressFormik.touched.c_country && communicationAddressFormik.errors.c_country ? (
                                                                                        <small className="text-danger">{communicationAddressFormik.errors.c_country}</small>
                                                                                    ) : null}
                                                                                </Form.Group>
                                                                            </Col>
                                                                        ) : (
                                                                            <div className="w-100 d-flex justify-content-between mt-3">
                                                                                <p className={styles.profileText}><strong className={styles.innerAddressHeading}> Country :</strong> {myProfileDetails?.communication_addresses?.country ? myProfileDetails?.communication_addresses?.country : "Not Available"}</p>
                                                                                {isEditProfile &&
                                                                                    <div className=" align-self-start">
                                                                                        <FontAwesomeIcon icon={faPen} className={styles.editIconStyle} onClick={() => { setCommunAddressToFormField(myProfileDetails.communication_addresses); setClickedField('com_country') }} title="Edit Address" />
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        )}
                                                                    </Row>
                                                                    <div className={`mt-lg-0 mt-2 ${styles.formBtnContainer}`}>
                                                                    </div>
                                                                </Form>
                                                            </>
                                                        </div>
                                                    </div>
                                                </ListGroup.Item>
                                            </>)
                                        : (
                                            <>
                                                <ListGroup.Item>
                                                    <div className="client-block1-details d-flex">
                                                        <div className="w-100">
                                                            <div className="d-flex justify-content-center">
                                                                <div className={styles.addressHeading}>
                                                                    <p
                                                                        className={styles.profileDetailHeading}
                                                                    >
                                                                        Address
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <>
                                                                <Form onSubmit={primaryAddressFormik.handleSubmit} className={styles.addressFormContainer}>
                                                                    <Row>
                                                                        {clickedField == 'street' ? (
                                                                            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="mb-3">
                                                                                <Form.Group >
                                                                                    <Form.Label> <p
                                                                                        className={styles.profileDetailHeading}
                                                                                    >
                                                                                        Street/Building/Area
                                                                                    </p></Form.Label>
                                                                                    <Form.Control
                                                                                        id="p_address"
                                                                                        name="p_address"
                                                                                        value={primaryAddressFormik.values.p_address}
                                                                                        type="text"
                                                                                        placeholder="Enter Street/Building/Area"
                                                                                        onChange={primaryAddressFormik.handleChange}
                                                                                        onBlur={(e) => { primaryAddressFormik.handleBlur(e); primaryAddressFormik.handleSubmit() }}
                                                                                        className={`${primaryAddressFormik.touched.p_address && primaryAddressFormik.errors.p_address ? 'is-invalid' : ''}`}

                                                                                    />
                                                                                    {primaryAddressFormik.touched.p_address && primaryAddressFormik.errors.p_address ? (
                                                                                        <small className="text-danger">{primaryAddressFormik.errors.p_address}</small>
                                                                                    ) : null}
                                                                                </Form.Group>
                                                                            </Col>
                                                                        ) : (
                                                                            <div className="w-100 d-flex justify-content-between mt-3">
                                                                                <p className={styles.profileText}><strong className={styles.innerAddressHeading}>Street/Building/Area :</strong> {myProfileDetails?.primary_addresses?.address ? myProfileDetails?.primary_addresses?.address : "Not Available"}</p>
                                                                                {isEditProfile &&
                                                                                    <div className=" align-self-start">
                                                                                        <FontAwesomeIcon icon={faPen} className={styles.editIconStyle} onClick={() => { setPrimaryAddressToFormField(myProfileDetails.primary_addresses); setClickedField('street') }} title="Edit Address" />
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        )}
                                                                        {clickedField == 'city' ? (
                                                                            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="mb-3">
                                                                                <Form.Group >
                                                                                    <Form.Label><p
                                                                                        className={styles.profileDetailHeading}
                                                                                    >
                                                                                        City
                                                                                    </p></Form.Label>
                                                                                    <Form.Control
                                                                                        id="p_city"
                                                                                        name="p_city"
                                                                                        value={primaryAddressFormik.values.p_city}
                                                                                        type="text"
                                                                                        placeholder="Enter City"
                                                                                        onChange={primaryAddressFormik.handleChange}
                                                                                        onBlur={(e) => { primaryAddressFormik.handleBlur(e); primaryAddressFormik.handleSubmit() }}
                                                                                        className={`${primaryAddressFormik.touched.p_city && primaryAddressFormik.errors.p_city ? 'is-invalid' : ''}`}
                                                                                    />
                                                                                    {primaryAddressFormik.touched.p_city && primaryAddressFormik.errors.p_city ? (
                                                                                        <small className="text-danger">{primaryAddressFormik.errors.p_city}</small>
                                                                                    ) : null}
                                                                                </Form.Group>
                                                                            </Col>
                                                                        ) : (
                                                                            <div className="w-100 d-flex justify-content-between mt-3">
                                                                                <p className={styles.profileText}><strong className={styles.innerAddressHeading}>City :</strong> {myProfileDetails?.primary_addresses?.city ? myProfileDetails?.primary_addresses?.city : "Not Available"}</p>

                                                                                {isEditProfile &&
                                                                                    <div className=" align-self-start">
                                                                                        <FontAwesomeIcon icon={faPen} className={styles.editIconStyle} onClick={() => { setClickedField('city'); setPrimaryAddressToFormField(myProfileDetails.primary_addresses) }} title="Edit Address" />
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        )}
                                                                        {clickedField == 'province' ? (
                                                                            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="mb-3">
                                                                                <Form.Group >
                                                                                    <Form.Label><p
                                                                                        className={styles.profileDetailHeading}
                                                                                    >
                                                                                        Province
                                                                                    </p></Form.Label>
                                                                                    <Form.Control
                                                                                        id="p_province"
                                                                                        name="p_province"
                                                                                        value={primaryAddressFormik.values.p_province}
                                                                                        type="text"
                                                                                        placeholder="Enter Province"
                                                                                        onChange={primaryAddressFormik.handleChange}
                                                                                        onBlur={(e) => { primaryAddressFormik.handleBlur(e); primaryAddressFormik.handleSubmit() }}
                                                                                        className={`${primaryAddressFormik.touched.p_province && primaryAddressFormik.errors.p_province ? 'is-invalid' : ''}`}
                                                                                    />
                                                                                    {primaryAddressFormik.touched.p_province && primaryAddressFormik.errors.p_province ? (
                                                                                        <small className="text-danger">{primaryAddressFormik.errors.p_province}</small>
                                                                                    ) : null}
                                                                                </Form.Group>
                                                                            </Col>
                                                                        ) : (
                                                                            <div className="w-100 d-flex justify-content-between mt-3">
                                                                                <p className={styles.profileText}><strong className={styles.innerAddressHeading}>Province :</strong> {myProfileDetails?.primary_addresses?.province ? myProfileDetails?.primary_addresses?.province : "Not Available"}</p>
                                                                                {isEditProfile &&
                                                                                    <div className=" align-self-start">
                                                                                        <FontAwesomeIcon icon={faPen} className={styles.editIconStyle} onClick={() => { setClickedField('province'); setPrimaryAddressToFormField(myProfileDetails.primary_addresses) }} title="Edit Address" />
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        )}
                                                                        {clickedField == 'postal' ? (
                                                                            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="mb-3">
                                                                                <Form.Group >
                                                                                    <Form.Label><p
                                                                                        className={styles.profileDetailHeading}
                                                                                    >
                                                                                        Postal/Zip Code
                                                                                    </p> </Form.Label>
                                                                                    <Form.Control
                                                                                        id="p_postal_code"
                                                                                        name="p_postal_code"
                                                                                        value={primaryAddressFormik.values.p_postal_code}
                                                                                        type="text"
                                                                                        placeholder="Enter Postal/Zip Code"
                                                                                        onChange={primaryAddressFormik.handleChange}
                                                                                        onBlur={(e) => { primaryAddressFormik.handleBlur(e); primaryAddressFormik.handleSubmit() }}
                                                                                        className={`${primaryAddressFormik.touched.p_postal_code && primaryAddressFormik.errors.p_postal_code ? 'is-invalid' : ''}`}
                                                                                    />
                                                                                    {primaryAddressFormik.touched.p_postal_code && primaryAddressFormik.errors.p_postal_code ? (
                                                                                        <small className="text-danger">{primaryAddressFormik.errors.p_postal_code}</small>
                                                                                    ) : null}
                                                                                </Form.Group>
                                                                            </Col>
                                                                        ) : (
                                                                            <div className="w-100 d-flex justify-content-between mt-3">
                                                                                <p className={styles.profileText}><strong className={styles.innerAddressHeading}>Postal/Zip Code :</strong> {myProfileDetails?.primary_addresses?.postal_code ? myProfileDetails?.primary_addresses?.postal_code : "Not Available"}</p>
                                                                                {isEditProfile &&
                                                                                    <div className=" align-self-start">
                                                                                        <FontAwesomeIcon icon={faPen} className={styles.editIconStyle} onClick={() => { setClickedField('postal'); setPrimaryAddressToFormField(myProfileDetails.primary_addresses) }} title="Edit Address" />
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        )}
                                                                        {clickedField == 'country' ? (
                                                                            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="mb-0">
                                                                                <Form.Group >
                                                                                    <Form.Label><p
                                                                                        className={styles.profileDetailHeading}
                                                                                    >
                                                                                        Country
                                                                                    </p></Form.Label>
                                                                                    <Form.Control
                                                                                        id="p_country"
                                                                                        name="p_country"
                                                                                        value={primaryAddressFormik.values.p_country}
                                                                                        type="text"
                                                                                        placeholder="Enter Country"
                                                                                        onChange={primaryAddressFormik.handleChange}
                                                                                        onBlur={(e) => { primaryAddressFormik.handleBlur(e); primaryAddressFormik.handleSubmit() }}
                                                                                        className={`${primaryAddressFormik.touched.p_country && primaryAddressFormik.errors.p_country ? 'is-invalid' : ''}`}
                                                                                    />
                                                                                    {primaryAddressFormik.touched.p_country && primaryAddressFormik.errors.p_country ? (
                                                                                        <small className="text-danger">{primaryAddressFormik.errors.p_country}</small>
                                                                                    ) : null}
                                                                                </Form.Group>
                                                                            </Col>
                                                                        ) : (
                                                                            <div className="w-100 d-flex justify-content-between mt-3">
                                                                                <p className={styles.profileText}><strong className={styles.innerAddressHeading}>Country :</strong>{myProfileDetails?.primary_addresses?.country ? myProfileDetails?.primary_addresses?.country : "Not Available"}</p>
                                                                                {isEditProfile &&
                                                                                    <div className=" align-self-start">
                                                                                        <FontAwesomeIcon icon={faPen} className={styles.editIconStyle} onClick={() => { setClickedField('country'); setPrimaryAddressToFormField(myProfileDetails.primary_addresses) }} title="Edit Address" />
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        )}
                                                                    </Row>
                                                                    <div className={`mt-lg-0 mt-2 ${styles.formBtnContainer}`}>
                                                
                                                                    </div>
                                                                </Form>
                                                            </>
                                                        </div>
                                                    </div>
                                                </ListGroup.Item>
                                            </>
                                        )
                                    }
                                    {userRoleId == 3 &&
                                        <>
                                            <ListGroup.Item>
                                                <div className="client-block1-details">
                                                    <div className="w-100">
                                                        <p
                                                            className={styles.profileDetailHeading}
                                                        >
                                                            Gender
                                                        </p>
                                                        {clickedField === 'gender' ? (
                                                            <Form onSubmit={genderFormik.handleSubmit}>
                                                                <Row>
                                                                    <Col xs={12} sm={12} md={12} lg={(userRoleId === '3') ? 6 : 12} xl={(userRoleId === '3') ? 6 : 12} className="mb-0">
                                                                        <Form.Group >
                                                                            <div className={styles.genderRadioBlock}>
                                                                                <Form.Check
                                                                                    inline
                                                                                    label="Male"
                                                                                    name="gender"
                                                                                    type="radio"
                                                                                    id={`inline-radio-1`}
                                                                                    value="1"
                                                                                    checked={genderFormik.values.gender == 1}
                                                                                    onChange={genderFormik.handleChange}
                                                                                    onBlur={(e) => { genderFormik.handleBlur(e); genderFormik.handleSubmit() }}
                                                                                />
                                                                                <Form.Check
                                                                                    inline
                                                                                    label="Female"
                                                                                    name="gender"
                                                                                    type="radio"
                                                                                    id={`inline-radio-2`}
                                                                                    value="2"
                                                                                    checked={genderFormik.values.gender == 2}
                                                                                    onChange={genderFormik.handleChange}
                                                                                    onBlur={(e) => { genderFormik.handleBlur(e); genderFormik.handleSubmit() }}
                                                                                />
                                                                                <Form.Check
                                                                                    inline
                                                                                    name="gender"
                                                                                    label="Other"
                                                                                    type="radio"
                                                                                    id={`inline-radio-3`}
                                                                                    value="3"
                                                                                    checked={genderFormik.values.gender == 3}
                                                                                    onChange={genderFormik.handleChange}
                                                                                    onBlur={(e) => { genderFormik.handleBlur(e); genderFormik.handleSubmit() }}
                                                                                />
                                                                            </div>

                                                                            {genderFormik.touched.gender && genderFormik.errors.gender ? (
                                                                                <small className="text-danger">{genderFormik.errors.gender}</small>
                                                                            ) : null}

                                                                        </Form.Group>
                                                                    </Col>
                                                                </Row>
                                                            </Form>
                                                        ) : (
                                                            <>
                                                                <div className={`w-100 ${styles.myprofileEditableBlock}`}>
                                                                    <p className={styles.profileText}>{myProfileDetails?.gender === 1 ? "Male" : (myProfileDetails?.gender === 2 ? "Female" : (myProfileDetails?.gender === 3 ? "Other" : "Not Available"))}</p>
                                                                    {isEditProfile &&
                                                                        <div className=" align-self-start">
                                                                            <FontAwesomeIcon icon={faPen} className={styles.editIconStyle} onClick={() => setClickedField('gender')} title="Edit Gender" />
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                                <div className="client-block1-details">
                                                    <div className="w-100">
                                                        <p
                                                            className={styles.profileDetailHeading}
                                                        >
                                                            Date of Birth
                                                        </p>
                                                        {clickedField === 'dob' ? (
                                                            <Form onSubmit={dobFormik.handleSubmit}>
                                                                <Row>
                                                                    <Col xs={12} sm={12} md={12} lg={(userRoleId === '3') ? 6 : 12} xl={(userRoleId === '3') ? 6 : 12} className="my-2">
                                                                        <Form.Group >
                                                                            {/* <Form.Label>Date Of Birth</Form.Label> */}
                                                                            <Form.Control
                                                                                id="date_of_birth"
                                                                                name="date_of_birth"
                                                                                value={dobFormik.values.date_of_birth}
                                                                                type="date"
                                                                                placeholder="Date of birth"
                                                                                onChange={dobFormik.handleChange}
                                                                                onBlur={(e) => { dobFormik.handleBlur(e); dobFormik.handleSubmit() }}
                                                                                max={currentDate}
                                                                                className={`${dobFormik.touched.date_of_birth && dobFormik.errors.date_of_birth ? 'is-invalid' : ''}`}

                                                                            />
                                                                            {dobFormik.touched.date_of_birth && dobFormik.errors.date_of_birth ? (
                                                                                <small className="text-danger">{dobFormik.errors.date_of_birth}</small>
                                                                            ) : null}
                                                                        </Form.Group>
                                                                    </Col>
                                                                </Row>
                                                            </Form>
                                                        ) : (
                                                            <>
                                                                <div className={`w-100 ${styles.myprofileEditableBlock}`}>
                                                                    <p className={styles.profileText}>{myProfileDetails?.date_of_birth ? myProfileDetails?.date_of_birth : "Not Available"}</p>
                                                                    {isEditProfile &&
                                                                        <div className=" align-self-start">
                                                                            <FontAwesomeIcon icon={faPen} className={styles.editIconStyle} onClick={() => setClickedField('dob')} title="Edit Date of Birth" />
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                                <div className="client-block1-details d-flex">
                                                    <div className="w-100">
                                                        <p
                                                            className={styles.profileDetailHeading}
                                                        >
                                                            Marital Status
                                                        </p>
                                                        {clickedField === 'married' ? (
                                                            <Form onSubmit={maritalFormik.handleSubmit}>
                                                                <Row>
                                                                    <Col xs={12} sm={12} md={12} lg={(userRoleId === '3') ? 6 : 12} xl={(userRoleId === '3') ? 6 : 12} className="my-2">
                                                                        <Form.Group >
                                                                            <Form.Select aria-label="Marital Status"
                                                                                id="married"
                                                                                name="married"
                                                                                value={maritalFormik.values.married}
                                                                                type="dropdown"
                                                                                placeholder="Select Marital Status"
                                                                                onChange={maritalFormik.handleChange}
                                                                                onBlur={(e) => { maritalFormik.handleBlur(e); maritalFormik.handleSubmit() }}
                                                                                className={`${maritalFormik.touched.married && maritalFormik.errors.married ? 'is-invalid' : ''}`}

                                                                            >
                                                                                <option className="d-none">Select Marital Status</option>
                                                                                <option value={true}>Married</option>
                                                                                <option value={false}>Unmarried</option>
                                                                            </Form.Select>
                                                                            {maritalFormik.touched.married && maritalFormik.errors.married ? (
                                                                                <small className="text-danger">{maritalFormik.errors.married}</small>
                                                                            ) : null}
                                                                        </Form.Group>
                                                                    </Col>
                                                                </Row>
                                                            </Form>
                                                        ) : (
                                                            <>
                                                                <div className={`w-100 ${styles.myprofileEditableBlock}`}>
                                                                    <p className={styles.profileText}>{myProfileDetails?.married === true ? "Married" : (myProfileDetails?.married === false ? "Unmarried" : " Not Available")}</p>
                                                                    {isEditProfile &&
                                                                        <div className=" align-self-start">
                                                                            <FontAwesomeIcon icon={faPen} className={styles.editIconStyle} onClick={() => setClickedField('married')} title="Edit Marital Status" />
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </ListGroup.Item>
                                        </>
                                    }
                                    {userRoleId !== '3' &&
                                        <>
                                            <ListGroup.Item>
                                                <div className="client-block1-details d-flex">
                                                    <div className="w-100">
                                                        <p
                                                            className={styles.profileDetailHeading}
                                                        >
                                                            Types of services
                                                        </p>
                                                        {clickedField === 'serviceType' ? (
                                                            <Form onSubmit={serviceTypeFormik.handleSubmit}>
                                                                <Row>
                                                                    <Col xs={12} sm={12} md={12} lg={12} xl={12} className="my-2">
                                                                        <Form.Group >
                                                                            <Form.Select aria-label="Types of Services"
                                                                                id="types_of_service"
                                                                                name="types_of_service"
                                                                                value={serviceTypeFormik.values.types_of_service}
                                                                                placeholder="Select Types of Services"
                                                                                onChange={serviceTypeFormik.handleChange}
                                                                                onBlur={(e) => { serviceTypeFormik.handleBlur(e); serviceTypeFormik.handleSubmit() }}
                                                                                className={`${serviceTypeFormik.touched.types_of_service && serviceTypeFormik.errors.types_of_service ? 'is-invalid' : ''}`}
                                                                            >
                                                                                <option className="d-none">Select Types of Services</option>
                                                                                <option value="Multiple Service">Multiple Service </option>
                                                                                <option value="Single Service (One license)">  Single Service (One license)</option>
                                                                            </Form.Select>

                                                                            {serviceTypeFormik.touched.types_of_service && serviceTypeFormik.errors.types_of_service ? (
                                                                                <small className="text-danger">{serviceTypeFormik.errors.types_of_service}</small>
                                                                            ) : null}
                                                                        </Form.Group>
                                                                    </Col>
                                                                </Row>
                                                            </Form>
                                                        ) : (
                                                            <>
                                                                <div className={`w-100 ${styles.myprofileEditableBlock}`}>
                                                                    <p className={styles.profileText}>{otherDetails.types_of_service ? otherDetails.types_of_service : 'Not Available'}</p>
                                                                    {isEditProfile &&
                                                                        <div className=" align-self-start">
                                                                            <FontAwesomeIcon icon={faPen} className={styles.editIconStyle} onClick={() => setClickedField('serviceType')} title="Edit Types of services" />
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                                <div className="client-block1-details d-flex">
                                                    <div className="w-100">
                                                        <p
                                                            className={styles.profileDetailHeading}
                                                        >
                                                            License Number
                                                        </p>
                                                        {clickedField === 'licence' ? (
                                                            <Form onSubmit={licenceFormik.handleSubmit}>
                                                                <Row>

                                                                    <Col xs={12} sm={12} md={12} lg={12} xl={12} className="my-2">
                                                                        <Form.Group >
                                                                            <Form.Control
                                                                                id="estate_license_number"
                                                                                name="estate_license_number"
                                                                                value={licenceFormik.values.estate_license_number}
                                                                                type="text"
                                                                                placeholder={`Enter License Number`}
                                                                                onChange={licenceFormik.handleChange}
                                                                                onBlur={(e) => { licenceFormik.handleBlur(e); licenceFormik.handleSubmit() }}
                                                                                className={`${licenceFormik.touched.estate_license_number && licenceFormik.errors.estate_license_number ? 'is-invalid' : ''}`}
                                                                            />
                                                                            {licenceFormik.touched.estate_license_number && licenceFormik.errors.estate_license_number ? (
                                                                                <small className="text-danger">{licenceFormik.errors.estate_license_number}</small>
                                                                            ) : null}
                                                                        </Form.Group>
                                                                    </Col>
                                                                </Row>
                                                            </Form>
                                                        ) : (
                                                            <>
                                                                <div className={`w-100 ${styles.myprofileEditableBlock}`}>
                                                                    <p className={styles.profileText}>{userRoleId === '2' ? (otherDetails?.rcic_number ? otherDetails?.rcic_number : 'Not Available') : (otherDetails?.estate_license_number ? otherDetails?.estate_license_number : 'Not Available')}</p>
                                                                    {isEditProfile &&
                                                                        <div className=" align-self-start">
                                                                            <FontAwesomeIcon icon={faPen} className={styles.editIconStyle} onClick={() => setClickedField('licence')} title={`Edit ${userRoleId === '2' ? 'RCIC ' : (userRoleId === '4' ? 'License ' : '')} Number`} />
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                                <div className="client-block1-details d-flex">
                                                    <div className="w-100">
                                                        <p
                                                            className={styles.profileDetailHeading}
                                                        >
                                                            Firm Name
                                                        </p>
                                                        {clickedField === 'firm' ? (
                                                            <Row>
                                                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="my-2">
                                                                    <Form.Group >
                                                                        <Form.Control
                                                                            id="firm_name"
                                                                            name="firm_name"
                                                                            value={firm}
                                                                            type="text"
                                                                            placeholder='Enter Firm Name'
                                                                            onChange={(e) => { setFirm(e.target.value) }}
                                                                            onBlur={() => { ApiCallForProfileUpdate({ other_details: { firm_name: firm } }) }}
                                                                            className={`${firm && firm.length < 1 ? 'is-invalid' : ''}`}
                                                                        />
                                                                        {firm && firm.length < 1 ? (
                                                                            <small className="text-danger">{'Please Enter Firm Name'}</small>
                                                                        ) : null}
                                                                    </Form.Group>
                                                                </Col>
                                                            </Row>
                                                        ) : (
                                                            <>
                                                                <div className={`w-100 ${styles.myprofileEditableBlock}`}>
                                                                    <p className={styles.profileText}>{firm ? firm : 'Not Available'}</p>
                                                                    {isEditProfile &&
                                                                        <div className=" align-self-start">
                                                                            <FontAwesomeIcon icon={faPen} className={styles.editIconStyle} onClick={() => setClickedField('firm')} title='Edit Firm' />
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                                <div className="client-block1-details d-flex">
                                                    <div className="w-100">
                                                        <p
                                                            className={styles.profileDetailHeading}
                                                        >
                                                            Licensing Authority
                                                        </p>
                                                        {clickedField === 'license_authority' ? (
                                                            <Form onSubmit={licenceAuthorityFormik.handleSubmit}>
                                                                <Row>
                                                                    <Col xs={12} sm={12} md={12} lg={12} xl={12} className="my-2">
                                                                        <Form.Group >
                                                                            <Form.Control aria-label="License Authority"
                                                                                id="license_authority"
                                                                                name="license_authority"
                                                                                value={licenceAuthorityFormik.values.license_authority}
                                                                                placeholder="Select License Authority"
                                                                                onChange={licenceAuthorityFormik.handleChange}
                                                                                onBlur={(e) => { licenceAuthorityFormik.handleBlur(e); licenceAuthorityFormik.handleSubmit() }}
                                                                                className={`${licenceAuthorityFormik.touched.license_authority && licenceAuthorityFormik.errors.license_authority ? 'is-invalid' : ''}`}
                                                                            >
                                                                            </Form.Control>
                                                                            {licenceAuthorityFormik.touched.license_authority && licenceAuthorityFormik.errors.license_authority ? (
                                                                                <small className="text-danger">{licenceAuthorityFormik.errors.license_authority}</small>
                                                                            ) : null}
                                                                        </Form.Group>
                                                                    </Col>
                                                                </Row>
                                                            </Form>
                                                        ) : (
                                                            <>
                                                                <div className={`w-100 ${styles.myprofileEditableBlock}`}>
                                                                    <p className={styles.profileText}>{otherDetails?.license_authority ? otherDetails?.license_authority : 'Not Available'}</p>
                                                                    {isEditProfile &&
                                                                        <div className=" align-self-start">
                                                                            <FontAwesomeIcon icon={faPen} className={styles.editIconStyle} onClick={() => setClickedField('license_authority')} title="Edit Licensing Authority" />
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                                <div className="client-block1-details d-flex">
                                                    <div className="w-100">
                                                        <p
                                                            className={styles.profileDetailHeading}
                                                        >
                                                            Service Location
                                                        </p>
                                                        {clickedField === 'service_location' ? (
                                                            <Form onSubmit={serviceLocationFormik.handleSubmit}>
                                                                <Row>
                                                                    <Col xs={12} sm={12} md={12} lg={12} xl={12} className={styles.serviceLocationBlock}>
                                                                        <Form.Group >
                                                                            <LocationSearchBox
                                                                                onLocationSearchHandler={onLocationSearchHandler}
                                                                                name={'service_location'}
                                                                                value={serviceLocationFormik.values.service_location ? serviceLocationFormik.values.service_location : ''}
                                                                                className={`form-control ${serviceLocationFormik.touched.service_location && serviceLocationFormik.errors.service_location ? 'is-invalid' : ''}`}
                                                                                onChange={serviceLocationFormik.handleChange}
                                                                                onBlur={serviceLocationFormik.handleBlur} />
                                                                            {serviceLocationFormik.touched.service_location && serviceLocationFormik.errors.service_location ? (
                                                                                <small className="text-danger">{serviceLocationFormik.errors.service_location}</small>
                                                                            ) : null}
                                                                        </Form.Group>
                                                                    </Col>
                                                                </Row>
                                                            </Form>
                                                        ) : (
                                                            <>
                                                                <div className={`w-100 ${styles.myprofileEditableBlock}`}>
                                                                    <p className={styles.profileText}>{serviceLocation ? serviceLocation : 'Not Available'}</p>
                                                                    {isEditProfile &&
                                                                        <div className=" align-self-start">
                                                                            <FontAwesomeIcon icon={faPen} className={styles.editIconStyle} onClick={() => setClickedField('service_location')} title="Edit Service Location" />
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                                <div className="client-block1-details d-flex">
                                                    <div className="w-100">
                                                        <p
                                                            className={styles.profileDetailHeading}
                                                        >
                                                            Service Location2
                                                        </p>
                                                        {clickedField === 'service_location2' ? (
                                                            <Form onSubmit={serviceLocation2Formik.handleSubmit}>
                                                                <Row>
                                                                    <Col xs={12} sm={12} md={12} lg={12} xl={12} className={styles.serviceLocationBlock}>
                                                                        <Form.Group >
                                                                            <LocationSearchBox
                                                                                onLocationSearchHandler={onLocationSearchHandler2}
                                                                                name={'service_location2'}
                                                                                value={serviceLocation2Formik.values.service_location2 ? serviceLocation2Formik.values.service_location2 : ''}
                                                                                className={`form-control ${serviceLocation2Formik.touched.service_location2 && serviceLocation2Formik.errors.service_location2 ? 'is-invalid' : ''}`}
                                                                                onChange={serviceLocation2Formik.handleChange}
                                                                                onBlur={serviceLocation2Formik.handleBlur} />
                                                                            {serviceLocation2Formik.touched.service_location2 && serviceLocation2Formik.errors.service_location2 ? (
                                                                                <small className="text-danger">{serviceLocation2Formik.errors.service_location2}</small>
                                                                            ) : null}
                                                                        </Form.Group>
                                                                    </Col>
                                                                </Row>
                                                            </Form>
                                                        ) : (
                                                            <>
                                                                <div className={`w-100 ${styles.myprofileEditableBlock}`}>
                                                                    <p className={styles.profileText}>{serviceLocation2 ? serviceLocation2 : 'Not Available'}</p>
                                                                    {isEditProfile &&
                                                                        <div className=" align-self-start">
                                                                            <FontAwesomeIcon icon={faPen} className={styles.editIconStyle} onClick={() => setClickedField('service_location2')} title="Edit Service Location2" />
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </ListGroup.Item>
                                            <ListGroup.Item>
                                                <div className="client-block1-details d-flex">
                                                    <div className="w-100">
                                                        <p
                                                            className={styles.profileDetailHeading}
                                                        >
                                                            Service Location3
                                                        </p>
                                                        {clickedField === 'service_location3' ? (
                                                            <Form onSubmit={serviceLocation3Formik.handleSubmit}>
                                                                <Row>
                                                                    <Col xs={12} sm={12} md={12} lg={12} xl={12} className={styles.serviceLocationBlock}>
                                                                        <Form.Group >

                                                                            <LocationSearchBox
                                                                                onLocationSearchHandler={onLocationSearchHandler3}
                                                                                name={'service_location3'}
                                                                                value={serviceLocation3Formik.values.service_location3 ? serviceLocation3Formik.values.service_location3 : ''}
                                                                                className={`form-control ${serviceLocation3Formik.touched.service_location3 && serviceLocation3Formik.errors.service_location3 ? 'is-invalid' : ''}`}
                                                                                onChange={serviceLocation3Formik.handleChange}
                                                                                onBlur={serviceLocation3Formik.handleBlur} />
                                                                            {serviceLocation3Formik.touched.service_location3 && serviceLocation3Formik.errors.service_location3 ? (
                                                                                <small className="text-danger">{serviceLocation3Formik.errors.service_location3}</small>
                                                                            ) : null}
                                                                        </Form.Group>
                                                                    </Col>
                                                                </Row>
                                                            </Form>
                                                        ) : (
                                                            <>
                                                                <div className={`w-100 ${styles.myprofileEditableBlock}`}>
                                                                    <p className={styles.profileText}>{serviceLocation3 ? serviceLocation3 : 'Not Available'}</p>
                                                                    {isEditProfile &&
                                                                        <div className=" align-self-start">
                                                                            <FontAwesomeIcon icon={faPen} className={styles.editIconStyle} onClick={() => setClickedField('service_location3')} title="Edit Service Location2" />
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </ListGroup.Item>
                                        </>
                                    }
                                </ListGroup>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            </div>
        </>
    );
};
//#endregion render end
export default MyProfile; 