/*
A common  actions file , which contains all the api's which will be used in agents module.
*/

import { authApi } from '../utilities/helpers/ApiHelper';
import { toast } from 'react-hot-toast';

//Get notifications api call
async function GetNotificationsApi() {
    let url = `Get-my-notifiction`;
    try {
        const response = await authApi.getAPI(url);
        return response;
    }
    catch (e) {
        toast.error("Something went wrong!", { duration: 4000 });
    }
}
//Get notifications counts 
async function GetNotificationsCountApi() {
    let url = `unread-notification-count`;
    try {
        const response = await authApi.getAPI(url);
        return response;
    }
    catch (e) {
        toast.error("Something went wrong!", { duration: 4000 });
    }
}

async function MarkNotificationAsReadByIdApi( id) {
    let url = `read-notifiction/${id}`;
    try {
        const response = await authApi.getAPI(url);
        if (response.status_code === 1) {
            return response.data;
        } else {
            toast.error(response.message, { duration: 4000 });
        }
        return response.data;
    }
    catch (e) {
        toast.error("Something went wrong!", { duration: 4000 });
    }
}

async function MarkAllNotificationAsReadApi(notificationDetails) {
    let url = `read-notifiction`;
    try {
        const response = await authApi.postAPI(notificationDetails,url);
        if (response.status_code === 1) {
            return response.data;
        } else {
            toast.error(response.message, { duration: 4000, },);
        }
        return response.data;
    }
    catch (e) {
        toast.error("Something went wrong!", { duration: 4000 });
    }
}
async function InviteClientFormAutoCompleteApi(searchValue,serachFor) {
    let url =`search-details/${serachFor}`;
    try {
        const response = await authApi.postAPI(url,searchValue);
        return response;
    }
    catch (e) {
        console.error('Something went wrong!',e)
    }
}



export { GetNotificationsApi, MarkNotificationAsReadByIdApi, MarkAllNotificationAsReadApi,GetNotificationsCountApi,InviteClientFormAutoCompleteApi }