/*
A common agent actions file , which contains all the api's which will be used in agents module.
*/

import { authApi } from '../utilities/helpers/ApiHelper';
import { toast } from 'react-hot-toast';

//Create agent form submition api call
async function CreateTemplateApi(templateDetails) {
    try {
        const response = await authApi.postAPI('create-case-documents', templateDetails);
        return response;
    }
    catch (e) {
        toast.error("Something went wrong!", { duration: 4000 });
    }
}

//Update agent form api call
async function UpdateTemplateApi(templateDetails, id) {

    try {
        const response = await authApi.updateAPI(`agent/${id}`, templateDetails);
        if (response.headers.success === 200) {
            toast.success(response.headers.message, { duration: 4000 });
        }
        else toast.error("Something went wrong!", { duration: 4000 });
    }
    catch (e) {
        toast.error("Something went wrong!", { duration: 4000 });
    }

}

//Get agent details by id api call
async function GetTemplateDetailsByIdApi(id) {

    let url = `template?id=${id}`;
    try {
        const response = await authApi.getAPI(url);
        if (response.headers.success === 200) {
            return response.body;
        } else {
            toast.error(response.headers.message, { duration: 4000, },);
        }
        return response.body;
    }
    catch (e) {
        toast.error("Something went wrong!", { duration: 4000 });
    }
}

//Get documents api call
async function GetDocumentApi(countryCode) {

    let url = `get-documents-options/${countryCode}`;
    try {
        const response = await authApi.getAPI(url);
        if (response.status_code === 1) {
            return response.data;
        } else {
            toast.error(response.message, { duration: 4000, },);
        }
        return response.data;
    }
    catch (e) {
        toast.error("Something went wrong!", { duration: 4000 });
    }
}

//Get Template Names
async function GetTemplateNamesApi() {

    let url = `case-template`;
    try {
        const response = await authApi.getAPI(url);
        if (response.status_code === 1) {
            return response.data;
        } else {
            toast.error(response.message, { duration: 4000, },);
        }
        return response.data;
    }
    catch (e) {
        toast.error("Something went wrong!", { duration: 4000 });
    }
}
/* get document check list in Invite client document list */
async function GetCheckListForDocumetApi() {

    let url = `document-reference-option`;
    try {
        const response = await authApi.getAPI(url);
        return response;
    }
    catch (e) {
        console.error("Something went wrong!", e);
    }
}
/* remove document check list item  in Invite client document list */
async function deletCheckListForDocumetApi(id) {

    let url = `document-reference-option`;
    try {
        const response = await authApi.deleteAPI(url, id);
        return response;
    }
    catch (e) {
        console.error("Something went wrong!", e);
    }
}

// update checklist suggetion in case details api here
async function UpdateCheckListSuggetionApi(data) {
    let url = `update-document-text`;
    try {
        const response = await authApi.postAPI(url, data);
        return response;
    }
    catch (e) {
        console.error("Something went wrong!", e);
    }
}

export { CreateTemplateApi, UpdateTemplateApi, GetTemplateDetailsByIdApi, GetDocumentApi, GetTemplateNamesApi, GetCheckListForDocumetApi, deletCheckListForDocumetApi, UpdateCheckListSuggetionApi }