import React, { Fragment, useState, useEffect } from "react";
import "./Index.css";
import Header from "../../layout/Header";
import Footer from "../../layout/Footer";
// imorting images
import TelecomImg1 from '../../assets/img/telecom/telecomimg.png'
import Tag from '../../assets/img/telecom/tag.png'
import Tag2 from '../../assets/img/telecom/tag2.png'
import TelecomImg2 from '../../assets/img//telecom/telecomimg2.png'
import RightIcon from '../../assets/img/landing-page/right-icon.png';

const Telecom = () => {
  const [isUserLogin, setIsUserLogin] = useState(false);
  useEffect(() => {
    setIsUserLogin(false)
    var userDetails = localStorage.getItem("accessToken");
    if (userDetails) {
      setIsUserLogin(true)
    }
  })
  //#region render start
  return (
    <Fragment>
      <Header isUserLogin={isUserLogin} setIsUserLogin={setIsUserLogin} />
      <section className="banking-page container-fluid p-0">
        <section className={`real-estate-banner row ${isUserLogin ? '' : 'logout-banner-margin'}`}>
          <div className="col-12 m-0 p-0">
            <div className="explore-banner-text">
              <div className="real-estate-banner-inner-block">
                <h2 className="banner-head"><span className="heading-blue-text">Airmigrate Telecom Services</span> Connect Easily, Wherever You Go </h2>
                <p>
                  New to a country? Set up your internet, mobile, and landline services effortlessly with Airmigrate.
                  Our platform simplifies finding the best telecom deals, tailored specifically for newcomers—ensuring you stay connected with top, flexible plans from trusted providers.
                  Start your new life connected to your old roots.
                </p>
                <p className="mobile-slider-text-block">We are your one stop online destination for navigating the world of telecommunications in Canada. Tailored specifically for newcomers, our platform aggregates and simplifies the search for the best telecom services across the country, providing you with a seamless transition into your new Canadian life.</p>
              </div>
            </div>
          </div>
        </section>
        <section className="banking-inner-content">
          <div className="row my-4 box-shadow">
            <div className="col-md-7 col-sm-12 cibc-bank-img-block">
              <img
                src={TelecomImg1}
                alt="TelecomImg1"
                className="img-fluid banking-img1"
              />
            </div>
            <div className="col-md-5 col-sm-12 cibc-bank-text-block">
              <div className="tag-image-block">
                <img src={Tag} alt="" className="tag-image" />
              </div>
              <div className="cibc-text">
                <h2 className="cibc-head">
                  <span className="heading-blue-text">Fido</span> Telecom
                </h2>
                <p className="cibc-para my-4">
                  Airmigrate Telecom Services: Connect Easily, Wherever You Go New to a country? Set up your internet, mobile, and landline services effortlessly with Airmigrate. Our platform simplifies finding the best telecom deals, tailored specifically for newcomers—ensuring you stay connected with top, flexible plans from trusted providers. Start your new life connected to your old roots.
                </p>
                <div className="learn-more-btn">
                  <a >Learn More <span className="right-icon-block"><img src={RightIcon}
                    alt="right-icon" /></span></a>
                </div>
              </div>
            </div>
          </div>
          <div className="row my-4 box-shadow">
            <div className="col-md-5 col-sm-12 cibc-bank-text-block">
              <div className="tag-image-block1">
                <img src={Tag2} alt="" className="tag-image" />
              </div>
              <div className="cibc-text">
                <h2 className="cibc-head">
                  <span className="heading-blue-text">Rogers</span> Telecom
                </h2>
                <p className="cibc-para my-4">
                  Airmigrate Telecom Services: Connect Easily, Wherever You Go New to a country? Set up your internet, mobile, and landline services effortlessly with Airmigrate. Our platform simplifies finding the best telecom deals, tailored specifically for newcomers—ensuring you stay connected with top, flexible plans from trusted providers. Start your new life connected to your old roots.
                </p>
                <div className="learn-more-btn">
                  <a >Learn More <span className="right-icon-block"><img src={RightIcon}
                    alt="right-icon" /></span></a>
                </div>
              </div>
            </div>
            <div className="col-md-7 col-sm-12 cibc-bank-img-block">
              <img
                src={TelecomImg2}
                alt="TelecomImg2"
                className="img-fluid banking-img1"
              />
            </div>
          </div>
        </section>
      </section>
      <Footer isUserLogin={isUserLogin} setIsUserLogin={setIsUserLogin} />
    </Fragment>
  );
};
//#endregion render 
export default Telecom;
